
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanDefaultView from '@/components/defaults/PlanDefaultView.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanQuoteStep from '@/modules/planning/newLoad/steps/PlanQuoteStep.vue';
import PlanInputRadio from '@/components/forms/PlanInputRadio.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanDefaultConfirmationModal from '@/components/defaults/PlanDefaultConfirmationModal.vue';
import PlanNewGeneralDomestic from '@/components/loads/new/PlanNewGeneralDomestic.vue';
import PlanNewMxDomestic from '@/components/loads/new/PlanNewMxDomestic.vue';
import PlanNewMxUsaCrossBorder from '@/components/loads/new/PlanNewMxUsaCrossBorder.vue';
import { useStore } from 'vuex';
import LoadQuotes from '@/modules/planning/newLoad/LoadQuotes.vue';
import PlanNewLoadHeaderSticky from '@/modules/planning/newLoad/_components/PlanNewLoadHeaderSticky.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useRouter } from 'vue-router';
import LoadFreightInfo from '@/modules/planning/newLoad/LoadFreightInfo.vue';
import CompanyModal from '@/components/modals/CompanyModal.vue';
import { useI18n } from 'vue-i18n';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import LoadDirectAssign from '@/modules/planning/newLoad/LoadDirectAssign.vue';
import PlanLoadDirectAssignFooter from '@/components/loads/form/domestic/PlanLoadDirectAssignFooter.vue';
import NewLoadTemplatesModal from '@/modules/planning/newLoad/NewLoadTemplatesModal.vue';
import BaseComponent from '@/base/BaseComponent';
export default defineComponent({
  name: 'LoadCreation',
  components: {
    NewLoadTemplatesModal,
    PlanLoadDirectAssignFooter,
    LoadDirectAssign,
    PlanInputNumber,
    PlanInputCheckbox,
    LoadFreightInfo,
    PlanIcon, PlanDefaultModal,
    PlanNewLoadHeaderSticky,
    LoadQuotes,
    PlanNewMxDomestic,
    PlanNewGeneralDomestic,
    PlanDefaultConfirmationModal,
    PlanTextButton,
    PlanInputRadio,
    PlanButton,
    PlanDefaultView,
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      bookNow: 0,
      showBookNow: false,
      loadingPost: false,
      postLoadState: '',
      selectedCompanies: [],
      selectedLoadType: 'REGULAR',
      showCompanyModal: false,
      changeLoadTypeTo: '',
      radioLoadTypeKey: 0,
      currentView: 'shipment',
      isFreightComplete: false,
      isCheckedShowQuotes: false,
      showDirectAssignModal: false,
      showTemplates: false
    });

    const actualWidth = computed(() => {
      return store.getters['UserStore/getActualWidth']
    })

    const additionalStops = computed(() => {
      return store.getters['NewFtlLoad/getAdditionalStops']
    })

    const cancelLoadType = () => {
      data.selectedLoadType = data.selectedLoadType + '';
      data.changeLoadTypeTo = '';
      data.radioLoadTypeKey += 1;
    };

    const changeBookNowEnabled = (newValue: boolean) => {
      data.showBookNow = newValue
    }

    const changeIsCheckedShowQuotes = (newValue: boolean) => {
      data.isCheckedShowQuotes = newValue
    }

    const changeSelectedLoadType = (selectedOption: string) => {
      console.log('QUERY', store.getters['NewFtlLoad/getQuery'])
      const query = store.getters['NewFtlLoad/getQuery']
      const files = store.getters['NewFtlLoad/getFiles']
      const additionalStops = store.getters['NewFtlLoad/getAdditionalStops']
      const defaultCommodities = [{ "index": 0, "commodityDescription": "", "weight": null, "units": null, "type": "3", "removable": false }]

      const isDefaultCommodity = (commodityArray: any[]) => {
        return commodityArray.length === 1 &&
          commodityArray[0].index === defaultCommodities[0].index &&
          commodityArray[0].commodityDescription === defaultCommodities[0].commodityDescription &&
          commodityArray[0].weight === defaultCommodities[0].weight &&
          commodityArray[0].units === defaultCommodities[0].units &&
          commodityArray[0].type === defaultCommodities[0].type &&
          commodityArray[0].removable === defaultCommodities[0].removable;
      }

      if (query.pickup === null &&
          query.delivery === null &&
          query.trailer.id === -1 &&
          isDefaultCommodity(query.commodities) &&
          files === null &&
          additionalStops.length === 0 &&
          query.specialRequirements.bondedPermission === false &&
          query.specialRequirements.hazardousLoad === false &&
          query.specialRequirements.specialPermits === false &&
          query.specialRequirements.specialPermitsValue === '' &&
          query.specialRequirements.teamDriver === false &&
          query.specialRequirements.tirCable === false &&
          query.specialRequirements.tsaCard === false &&
          query.specialRequirements.twicCard === false)
      {
        data.selectedLoadType = selectedOption;
        data.changeLoadTypeTo = '';
        store.commit('NewFtlLoad/clearStore')
        store.commit('NewFtlLoad/setType', selectedOption);
      } else {
        data.changeLoadTypeTo = selectedOption;
        store.commit('NewFtlLoad/clearStore')
        store.commit('NewFtlLoad/setType', selectedOption);
      }
    };

    const changeView = (viewToGo: string) => {
      if (viewToGo === 'shipment') data.currentView = viewToGo
      if (viewToGo === 'freight' && isValidatedForm.value.result) data.currentView = viewToGo
      if (viewToGo === 'quote' && isValidatedForm.value.result) data.currentView = viewToGo
      if (viewToGo === 'directAssign' && isValidatedForm.value.result) {
        data.showDirectAssignModal = false
        data.currentView = viewToGo
      }
    }

    const closeTemplatesModal = async (action: any, templateData: any) => {
      if (action === 'close') data.showTemplates = false

      if (action === 'delete') {
        await store.dispatch('LoadsStore/deleteSaveLoad', templateData).then(() => {
          store.dispatch('LoadsStore/getSaveLoads');
          data.showTemplates = false
        })
      }

      if (action === 'fill') {
        await store.dispatch('LoadsStore/saveLoadId', templateData).then((response) => {
          let pickupCountry = response.load.stops[0]?.location?.state?.country?.iso
          let deliveryCountry = response.load.stops[response.load.stops.length - 1]?.location?.state?.country?.iso
          if (pickupCountry === 'MX' && deliveryCountry === 'MX') {
            store.commit('NewFtlLoad/setType', 'MEX_DOMESTIC')
          } else {
            store.commit('NewFtlLoad/setType', 'REGULAR')
          }
          data.showTemplates = false
          store.commit('NewFtlLoad/setTemplateData', response)
        })
      }
    }

    const ftlOptions = computed(() => {
      return [
        { label: translate.t('load-type-usa'), value: 'REGULAR' },
        // { label: 'MX - USA Cross border', value: 'USA_MEX_CROSS_COUNTRY' },
        { label: translate.t('load-type-mx'), value: 'MEX_DOMESTIC' },
      ];
    });

    const handleChangeStep = () => {
      // check singular commodityMaster master and delete it to check if it fails in RFP Load Details etc etc

      let loadToSend = {
        observations: '',
        stops: store.state.NewFtlLoad.stops,
        specialRequirement: store.state.NewFtlLoad.specialRequirements,
        poNumber: store.state.NewFtlLoad.poNumber,
        trailerType: store.state.NewFtlLoad.trailerData,
        commodityMasters: store.state.NewFtlLoad.commodities,
        commodityMaster: store.state.NewFtlLoad.commodities,
      };

      store.state.CrossBorderStore.defaultLoad = loadToSend;

      if (store.getters['NewFtlLoad/getAdditionalStops'].length > 0) {
        data.currentView = 'freight'
      } else {
        data.currentView = 'quote';
      }
    };

    const changeIsFreightComplete = (newValue: any) => {
      data.isFreightComplete = newValue.isComplete
    }

    const isValidatedForm = computed(() => {
      return store.getters['NewFtlLoad/isValidatedForm']
    })

    const selectLoadType = () => {
      data.selectedLoadType = data.changeLoadTypeTo;
      data.changeLoadTypeTo = '';
    };

    const getCommoditiesForStop = (index: number) => {
      const commodities: Array<any> = []
      const freightInfo = store.getters['NewFtlLoad/getFreightInfo']
      if (freightInfo === undefined) {
        return []
      }

      if (freightInfo === null) {
        store.getters['NewFtlLoad/getQuery'].commodities.forEach((commodity: any) => {
          commodities.push({
            "nPallets": commodity.units,
            "packageType": {
              "id": commodity.type
            },
            "weight": commodity.weight,
            "commodityName": commodity.commodityDescription
          })
        })

      } else {
        freightInfo[index].forEach((commodity: any) => {
          if (commodity.isActive) {
            commodities.push({
              "nPallets": commodity.units,
              "packageType": {
                "id": commodity.type
              },
              "weight": commodity.weight,
              "commodityName": commodity.commodityDescription
            })
          }
        })
      }


      return commodities
    }

    const deliverInForStop = (index: number) => {
      const freightInfo = store.getters['NewFtlLoad/getFreightInfo']
      let result: Array<any> = []
      if (freightInfo !== null) {
        freightInfo[index + 1].forEach((commodity: any) => {
          commodity.deliverIn.forEach((value: number) => {
            if (!result.includes(value)) result.push(value)
          })
        })
      }
      return result
    }

    const newLoadTemplates = computed(() => {
      return store.getters['LoadsStore/getSaveLoads']
    })

    const postFtlLoad = async () => {
      data.loadingPost = true
      data.postLoadState = 'load'
      let loadId = -1
      let shipmentId = -1

      const commodities: Array<any> = []
      const freightInfo = store.getters['NewFtlLoad/getFreightInfo']
      const additionalStops = store.getters['NewFtlLoad/getAdditionalStops']

      store.getters['NewFtlLoad/getQuery'].commodities.forEach((commodity: any) => {
        commodities.push({
          "nPallets": commodity.units,
          "packageType": {
            "id": commodity.type
          },
          "weight": commodity.weight,
          "commodityName": commodity.commodityDescription
        })
      })

      let loadBody = {
        poNumber: store.getters['NewFtlLoad/getPoNumber'],
        observations: store.getters['NewFtlLoad/getCommodityObservations'],
        stops: [
          {
            "type": "PICKUP_INITIAL",
            "executionDate": store.getters['NewFtlLoad/getQuery'].pickup.date,
            "location": {
              "lat": store.getters['NewFtlLoad/getQuery'].pickup.stopData.latitude,
              "long": store.getters['NewFtlLoad/getQuery'].pickup.stopData.longitude,
              "zip": store.getters['NewFtlLoad/getQuery'].pickup.stopData.postal_code,
              "city": store.getters['NewFtlLoad/getQuery'].pickup.stopData.locality.length > 0 ? store.getters['NewFtlLoad/getQuery'].pickup.stopData.locality : store.getters['NewFtlLoad/getQuery'].pickup.stopData.location,
              "googlePlaceId": store.getters['NewFtlLoad/getQuery'].pickup.stopData.placeId
            },
            "commodities": freightInfo === null ? getCommoditiesForStop(0) : commodities,
            "portStop": store.getters['NewFtlLoad/getQuery'].pickup.warehouse.port,
            "airportStop": store.getters['NewFtlLoad/getQuery'].pickup.warehouse.airport,
            "observations": store.getters['NewFtlLoad/getQuery'].pickup.warehouse.observations,
            "poNumber": store.getters['NewFtlLoad/getQuery'].pickup.warehouse.poNumber,
            "executionWindowStartTime": store.getters['NewFtlLoad/getQuery'].pickup.warehouse.startTime,
            "executionWindowEndTime": store.getters['NewFtlLoad/getQuery'].pickup.warehouse.endTime,
            "lumperFeePrice": 0,
            "multiStopIdentifier": "",
            "multiStopIdentifierArray": [] as any
          },
          {
            "type": "DELIVERY_FINAL",
            "executionDate": store.getters['NewFtlLoad/getQuery'].delivery.date,
            "location": {
              "lat": store.getters['NewFtlLoad/getQuery'].delivery.stopData.latitude,
              "long": store.getters['NewFtlLoad/getQuery'].delivery.stopData.longitude,
              "zip": store.getters['NewFtlLoad/getQuery'].delivery.stopData.postal_code,
              "city": store.getters['NewFtlLoad/getQuery'].delivery.stopData.locality.length > 0 ? store.getters['NewFtlLoad/getQuery'].delivery.stopData.locality : store.getters['NewFtlLoad/getQuery'].delivery.stopData.location,
              "googlePlaceId": store.getters['NewFtlLoad/getQuery'].delivery.stopData.placeId
            },
            "commodities": freightInfo === null ? getCommoditiesForStop(1 + additionalStops.length) : commodities,
            "portStop": store.getters['NewFtlLoad/getQuery'].delivery.warehouse.port,
            "airportStop": store.getters['NewFtlLoad/getQuery'].delivery.warehouse.airport,
            "observations": store.getters['NewFtlLoad/getQuery'].delivery.warehouse.observations,
            "poNumber": store.getters['NewFtlLoad/getQuery'].delivery.warehouse.poNumber,
            "executionWindowStartTime": store.getters['NewFtlLoad/getQuery'].delivery.warehouse.startTime,
            "executionWindowEndTime": store.getters['NewFtlLoad/getQuery'].delivery.warehouse.endTime,
            "lumperFeePrice": 0,
            "multiStopIdentifier": additionalStops.length === 0 ? '1' : (additionalStops.length + 1).toString(),
            "multiStopIdentifierArray": [] as any
          }
        ],
        trailerType: {
          id: store.getters['NewFtlLoad/getQuery'].trailer.trailerId
        },
        commodityMaster: commodities[0],
        commodityMasters: commodities,
        minTemp: 0,
        maxTemp: 0,
        specialRequirement: {
          teamDriver: store.getters['NewFtlLoad/getQuery'].specialRequirements.teamDriver,
          bondedPermission: store.getters['NewFtlLoad/getQuery'].specialRequirements.bondedPermission,
          tsa: store.getters['NewFtlLoad/getQuery'].specialRequirements.tsaCard,
          TWICCard: store.getters['NewFtlLoad/getQuery'].specialRequirements.twicCard,
          tir: store.getters['NewFtlLoad/getQuery'].specialRequirements.tirCable,
          hazardousLoad: store.getters['NewFtlLoad/getQuery'].specialRequirements.hazardousLoad,
          specialPermissions: store.getters['NewFtlLoad/getQuery'].specialRequirements.specialPermits,
          specialPermissionsDescription: store.getters['NewFtlLoad/getQuery'].specialRequirements.specialPermitsValue,
        }
      }

      if (freightInfo !== null && additionalStops.length > 0) {
        additionalStops.forEach((additionalStop: any, index: number) => {
          let multiStopIdentifier = ''
          let multiStopIdentifierArray: Array<any> = []
          if (additionalStop.stopType === 'DELIVERY') {
            multiStopIdentifier = (index + 1).toString()
          } else if (additionalStop.stopType === 'PICKUP') {
            multiStopIdentifierArray = deliverInForStop(index + 1)
          }

          loadBody.stops.push({
            "type": additionalStop.stopType,
            "executionDate": additionalStop.date,
            "location": {
              "lat": additionalStop.stopData.latitude,
              "long": additionalStop.stopData.longitude,
              "zip": additionalStop.stopData.postal_code,
              "city": additionalStop.stopData.locality.length > 0 ? additionalStop.stopData.locality : additionalStop.stopData.location,
              "googlePlaceId": additionalStop.stopData.placeId
            },
            "commodities": getCommoditiesForStop(index + 1),
            "portStop": additionalStop.warehouse.port,
            "airportStop": additionalStop.warehouse.airport,
            "observations": additionalStop.warehouse.observations,
            "poNumber": additionalStop.warehouse.poNumber,
            "executionWindowStartTime": additionalStop.warehouse.startTime,
            "executionWindowEndTime": additionalStop.warehouse.endTime,
            "lumperFeePrice": 0,
            "multiStopIdentifier": multiStopIdentifier,
            "multiStopIdentifierArray": multiStopIdentifierArray
          })

          if (additionalStop.warehouse.id !== -1) {
            (loadBody.stops[loadBody.stops.length - 1] as any).warehouseTemplate = { id: additionalStop.warehouse.id };
          }

          if (additionalStop.warehouse.lumperFee) {
            (loadBody.stops[loadBody.stops.length - 1] as any).lumperFeePrice = additionalStop.lumperFeePrice
          }
        })
      }

      if (store.getters['NewFtlLoad/getQuery'].pickup.warehouse.id !== -1) {
        (loadBody.stops[0] as any).warehouseTemplate = { id: store.getters['NewFtlLoad/getQuery'].pickup.warehouse.id };
      }

      if (store.getters['NewFtlLoad/getQuery'].delivery.warehouse.id !== -1) {
        (loadBody.stops[1] as any).warehouseTemplate = { id: store.getters['NewFtlLoad/getQuery'].delivery.warehouse.id };
      }

      if (store.getters['NewFtlLoad/getQuery'].pickup.warehouse.lumperFee) {
        (loadBody.stops[0] as any).lumperFeePrice = store.getters['NewFtlLoad/getQuery'].pickup.warehouse.lumperFeePrice
      }

      if (store.getters['NewFtlLoad/getQuery'].delivery.warehouse.lumperFee) {
        (loadBody.stops[1] as any).lumperFeePrice = store.getters['NewFtlLoad/getQuery'].delivery.warehouse.lumperFeePrice
      }

      if (store.getters['NewFtlLoad/getQuery'].trailer.min !== null || store.getters['NewFtlLoad/getQuery'].trailer.max) {
        loadBody.minTemp = store.getters['NewFtlLoad/getQuery'].trailer.min
        loadBody.maxTemp = store.getters['NewFtlLoad/getQuery'].trailer.max
      }

      await store.dispatch('LoadsStore/createLoad', { load: loadBody, isMultiple: store.getters['NewFtlLoad/getMultipleLoads'] >= 2 })
        .then((load) => {
          loadId = load.id
          shipmentId = load.shipment.id
        })

      if (store.getters['NewFtlLoad/getCustomers'].length > 0) {
        data.postLoadState = 'customers'
        store.getters['NewFtlLoad/getCustomers'].map(async (customer: any) => {
          await store.dispatch('LoadsStore/apiPostLoadCustomerAssign', {
            id: loadId,
            customerId: customer.id,
          })
        })
      }

      if (store.getters['NewFtlLoad/getFiles'] !== null) {
        data.postLoadState = 'files'
        store.getters['NewFtlLoad/getFiles'].map(async (file: any) => {
          await store.dispatch('LoadsStore/postMediaObjectLoad', {
            id: loadId,
            file: file,
            type: 'LOAD',
          })
        })
      }

      if (data.selectedLoadType === 'MEX_DOMESTIC' && store.getters['NewFtlLoad/getLetterOfInstructions'] !== null) {
        data.postLoadState = 'files'
        await store.dispatch('LoadsStore/postMediaObjectLoad', {
          id: loadId,
          file: store.getters['NewFtlLoad/getLetterOfInstructions'][0],
          type: 'LETTER_OF_INSTRUCTIONS',
        })
      }

      let billingCompanyResult = true
      for (const key in store.getters['UserStore/getBillingCompany']) {
        if (store.getters['UserStore/getBillingCompany'][key] === '') billingCompanyResult = false
      }

      if (billingCompanyResult) {
        await store.dispatch('LoadsStore/putBillContactInfo', {
          id: loadId,
          params: {
            billNameMaster: store.getters['UserStore/getBillingCompany'].name,
            billLocationMaster: {
              address: store.getters['UserStore/getBillingCompany'].address,
              city: store.getters['UserStore/getBillingCompany'].city,
              zip: store.getters['UserStore/getBillingCompany'].zip,
              state: { id: store.getters['UserStore/getBillingCompany'] },
            },
            billIdentificationNumberMaster: store.getters['UserStore/getBillingCompany'],
            billContactEmailMaster: store.getters['UserStore/getBillingCompany'],
          },
        })
      }

      if (store.getters['NewFtlLoad/getDirectAssign'] !== null) {
        await store.dispatch('LoadsStore/directBatchAssign', {
          company: { id: store.getters['NewFtlLoad/getDirectAssign'].company.id },
          shipments: [{ id: shipmentId }],
          price: store.getters['NewFtlLoad/getDirectAssign'].price
        })
      } else {
        await store.dispatch('LoadsStore/setBookNowPrice', {
          id: loadId,
          params: { bookNowPrice: data.bookNow, bookNowEnable: data.showBookNow },
        })

        await store.dispatch('UserStore/showQuoteCarrier', {
          id: loadId,
          competitiveQuoting: data.isCheckedShowQuotes,
        })
      }

      const companiesBody: Array<any> = []
      data.selectedCompanies.forEach((company: any) => {
        companiesBody.push({ id: company })
      })

      data.postLoadState = 'quotes'
      await store.dispatch('LoadsStore/shipmentRequestBatch', {
        companies: companiesBody,
        shipments: [{ id: shipmentId }]
      })

      if (store.getters['NewFtlLoad/getMultipleLoads'] > 1) {
        let shipmentObjects: any = null
        data.postLoadState = 'load'
        await store.dispatch('LoadsStore/loadBatchesTemplate', {
          id: loadId,
          amount: store.getters['NewFtlLoad/getMultipleLoads']
        }).then((response) => {
          shipmentObjects = response.batch.map((item: any) => ({ id: item.shipment.id }));
        })
        if (shipmentObjects) {
          await store.dispatch('LoadsStore/shipmentRequestBatch', {
            companies: companiesBody,
            shipments: shipmentObjects,
          })
        }

        data.loadingPost = false
        store.dispatch('UserStore/updateToast', {
          text: translate.t('load-created-correctly'),
          type: 'success',
          visible: true
        })
        router.push(`/planning`)
      }

      data.loadingPost = false
      store.dispatch('UserStore/updateToast', {
        text: translate.t('load-created-correctly'),
        type: 'success',
        visible: true
      })
      router.push(`/planning/service/${loadId}`)
    }

    const updateSelectedCompanies = (selectedCompanies: any) => {
      data.selectedCompanies = selectedCompanies
    }

    watch(() => store.getters['NewFtlLoad/getType'], () => {
      data.selectedLoadType = store.getters['NewFtlLoad/getType']
    })

    return {
      ...toRefs(data),
      actualWidth,
      additionalStops,
      changeBookNowEnabled,
      changeIsCheckedShowQuotes,
      changeView,
      cancelLoadType,
      closeTemplatesModal,
      handleChangeStep,
      changeIsFreightComplete,
      isValidatedForm,
      changeSelectedLoadType,
      ftlOptions,
      newLoadTemplates,
      postFtlLoad,
      router,
      selectLoadType,
      updateSelectedCompanies
    };
  },
});
