import store from '@/services/store';
import i18n from '@/resources/locales';

function formatMiles(miles: string) {
  // if (miles) {
  //   const parts = miles.toString().split('.');
  //   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  //   miles = parts.join('.');
  //   // const text = Number(miles) <= 1 ? ' mile' : ' miles'
  //   const text = ' mi';
  //   return miles + text;
  // } else {
  //   return '';
  // }

  const distanceUnit = store.getters['CountryStore/getMeasurementSystemDistanceUnitIntl'];
  const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
  const options = {
    style: 'unit',
    unit: distanceUnit || 'mile',
    useGrouping: true, // To show thousands separator
  };
  return new Intl.NumberFormat(locale, options).format(Number(miles));
}

function formatNumberPrice(n) {
  if (n && n !== undefined) {
    // format number 1000000 to 1,234,567
    return n
      .toString()
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return n;
}

function formatPrice(number: number) {
  // const currency = number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  // return currency.split('.')[0];
  const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
  const currency = store.getters['CountryStore/getCountryCurrency'];
  const options = {
    style: 'currency',
    currency: currency || 'USD',
    maximumFractionDigits: 0,
    useGrouping: true, // To show thousands separator
  };
  return new Intl.NumberFormat(locale, options).format(number);
}

function formatPriceDecimals(number: number) {
  const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
  const currency = store.getters['CountryStore/getCountryCurrency'];
  const options = {
    style: 'currency',
    currency: currency || 'USD',
    maximumFractionDigits: 2,
    useGrouping: true, // To show thousands separator
  };
  return new Intl.NumberFormat(locale, options).format(number);
}

function formatString(key: any) {
  const result = key.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

function formatTemperature(temperature: number) {
  const temperatureUnit = store.getters['CountryStore/getMeasurementSystemTemperatureUnitIntl'];
  const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
  const options = {
    style: 'unit',
    unit: temperatureUnit || 'fahrenheit',
    useGrouping: true, // To show thousands separator
  };
  return new Intl.NumberFormat(locale, options).format(Number(temperature));
}

function formatValoration(valoration: any) {
  return Math.round((Number(valoration) + Number.EPSILON) * 10) / 10;
}

function formatWeight(weight: string) {
  // const parts = weight.toString().split('.');
  // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // weight = parts.join('.');
  // return weight + ' lbs';

  let weightUnit = store.getters['CountryStore/getMeasurementSystemWeightUnitIntl'];
  const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
  if (store.getters['LoadsStore/currentLoad'].type === 'MEX_DOMESTIC') weightUnit = 'kilogram'
  const options = {
    style: 'unit',
    unit: weightUnit || 'pound',
    useGrouping: true, // To show thousands separator
  };
  return new Intl.NumberFormat(locale, options).format(Number(weight));
}

function formatWeightWithCountry(weight: string, country: string) {
  // const parts = weight.toString().split('.');
  // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // weight = parts.join('.');
  // return weight + ' lbs';

  let weightUnit = store.getters['CountryStore/getMeasurementSystemWeightUnitIntl'];
  if (country === 'mx') weightUnit = 'kilogram'
  const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
  const options = {
    style: 'unit',
    unit: weightUnit || 'pound',
    useGrouping: true, // To show thousands separator
  };
  return new Intl.NumberFormat(locale, options).format(Number(weight));
}

function numberSuffixAndColor(number) {
  if (Number(number)) {
    const colors = ['bg-amber-500', 'bg-gray-300'];
    let localLanguage = i18n.global.locale.value as any;
    if(localLanguage === undefined){
      localLanguage = localStorage.getItem('userLocale') || navigator.language || 'en';
    }

    const suffixesEnglish = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
    const suffixesSpanish = ['ª', 'ª', 'ª', 'ª', 'ª', 'ª', 'ª', 'ª', 'ª', 'ª'];
    const suffixes = localLanguage === 'es-ES'? suffixesSpanish: suffixesEnglish;

    const index = number < 4 ? 0 : 1;
    return { suffix: number + suffixes[number % suffixes.length], color: colors[index] };
  } else {
    return 0;
  }
}

function roundToXDecimals(value, nDecimals = 0) {
  if (!value || isNaN(value)) {
    return 0;
  }

  if (typeof value === 'string') {
    return Number(value).toFixed(nDecimals);
  }
  return value.toFixed(nDecimals);
}

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.slice(0, maxLength) + '...';
  }
}

export {
  formatMiles,
  formatNumberPrice,
  formatPrice,
  formatPriceDecimals,
  formatString,
  formatTemperature,
  formatValoration,
  formatWeight,
  formatWeightWithCountry,
  numberSuffixAndColor,
  roundToXDecimals,
  truncateText,
};
