
import { computed, defineComponent, ref, onMounted, watch } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
// Resources
import i18n from '@/resources/locales';
// Services
import router from '@/router';
import store from '@/services/store';
// Utils
import { calculateDaysDifference } from '@/utils/formatDates';

export default defineComponent({
  name: 'WelcomeTrialModal',
  components: {
    BaseModal,
  },
  mixins: [BaseComponent],
  emits: ['callbackClose', 'callbackSend'],
  setup(props, { emit }) {
    /** Global */
    const $t = i18n.global.t;
    // Variables
    const messages = ref({} as any);

    // Computed

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const daysLeft = computed(() => {
      return calculateDaysDifference(currentUser?.value?.company?.trialEndDate ?? new Date());
    });

    const isWelcomeOpen = computed(() => {
      return store.getters['DemoStore/getIsWelcomeOpen'];
    });

    watch(currentUser, (newValue, oldValue) => {
      updateMessages();
    });

    const updateMessages = () => {
      const date = BaseComponent.methods?.formatDate(currentUser.value?.company?.trialEndDate);
      switch (true) {
        case currentUser.value.company?.accessPlanType === 'SUBSCRIPTION_NOT_PAID':
          setMessages({
            title: $t('your-payment-is-declined'),
            secondTitle: null,
            body: $t('contact-support'),
            button: $t('subscribe'),
          });
          break;
        case currentUser.value.company?.accessPlanType === 'SUBSCRIPTION_TERMINATED':
          setMessages({
            title: $t('your-subscription-period-ended'),
            secondTitle: null,
            body: $t('subscribe-now-keep-working'),
            button: $t('subscribe'),
          });
          break;
        case currentUser.value.company?.accessPlanType === 'TRIAL_EXPIRED':
          setMessages({
            title: $t('your-trial-is-completed'),
            secondTitle: null,
            body: $t('subscribe-now-keep-working'),
            button: $t('subscribe'),
          });
          break;
        case daysLeft.value && daysLeft.value > 10:
          setMessages({
            title: $t('welcome'),
            secondTitle: $t('free-trial-on-progress', {
              count: daysLeft.value ?? '-',
            }),
            body: $t('enjoy-until-n-the-full-version', {
              n: date ?? '-',
            }),
            button: $t('continue'),
          });
          break;
        case daysLeft.value && daysLeft.value <= 10 && daysLeft.value > 0:
          setMessages({
            title: $t('your-trial-is-ending'),
            secondTitle: $t('company-free-trial-end-days', {
              company: currentUser.value.companyName,
              n: daysLeft.value,
            }),
            body: $t('subscribe-now-keep-working'),
            button: $t('subscribe'),
          });
          break;
      }
    };

    // onMounted
    onMounted(() => {
      const date = BaseComponent.methods?.formatDate(currentUser.value?.company?.trialEndDate);
      updateMessages();
    });

    /** Methods */
    const onClickClose = () => {
      if (!daysLeft.value || daysLeft.value === 0 || daysLeft.value <= 10) {
        router.push({ name: 'plans' });
      }
      store.commit('DemoStore/setIsWelcomeOpen', false);
    };

    const setMessages = ({ title, secondTitle, body, button }) => {
      messages.value = { title, secondTitle, body, button };
    };

    return {
      calculateDaysDifference,
      currentUser,
      isWelcomeOpen,
      messages,
      onClickClose,
    };
  },
});
