import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_2 = { class: "relative w-full flex justify-start items-start gap-4" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createElementBlock("h3", {
          class: _normalizeClass(["text-2xl 2xl:text-3xl font-bold flex items-center opacity-30 hover:opacity-70 whitespace-nowrap", { 'font-bold cursor-pointer !opacity-100': _ctx.activeTab === tab.value }]),
          key: index,
          onClick: ($event: any) => (_ctx.onClickTab(tab.value))
        }, [
          _createTextVNode(_toDisplayString(index + 1 + '. ' + tab.label) + " ", 1),
          (tab.isCheck)
            ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
                key: 0,
                class: "h-8 w-8"
              }))
            : _createCommentVNode("", true)
        ], 10, _hoisted_3))
      }), 128))
    ])
  ]))
}