
import { Field } from 'vee-validate';
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  watch,
} from 'vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import AddWarehouseModal from '@/modules/planning/warehouses/_modals/AddWarehouseModal.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanButtonGroup from '@/components/buttons/PlanButtonGroup.vue';
import PlanInputHour from '@/components/forms/PlanInputHour.vue';
import UpdateWarehouseModal from '@/modules/planning/warehouses/_modals/UpdateWarehouseModal.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import { useI18n } from 'vue-i18n';
// Mixins
import CountryMixins from '@/services/country/_mixins';
import PlanInputNumberFormatted from '@/components/forms/PlanInputNumberFormatted.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
interface warehouseInStopData {
  appointments: Array<any>;
  id: string;
  loadingWarehouses: boolean;
  selectedWarehouse: any;
  showAddWarehouse: boolean;
  showSelectItems: boolean;
  showUpdateWarehouse: boolean;
  selectedAppointment: number;
  selectWidth: number;
  warehouseForm: {
    appointment: string;
    airpot: boolean;
    port: boolean;
    lumperFee: boolean;
    lumperFeePrice: number;
    from: string;
    to: string;
    observations: string;
    poNumber: string;
  };
  warehouses: [];
}
export default defineComponent({
  name: 'WarehouseInStopData',
  components: {
    PlanInputText,
    PlanInputNumberFormatted,
    PlanTextButton,
    UpdateWarehouseModal,
    PlanInputHour,
    PlanButtonGroup,
    PlanInputCheckbox,
    PlanButtonIcon,
    AddWarehouseModal,
    PlanIcon,
  },
  mixins: [CountryMixins],
  props: {
    warehouseId: { type: Number, required: false, default: -1 },
    warehouseData: { type: Object, required: false },
    zip: { type: String, required: true, default: '' },
    defaultCountry: { type: String, required: true, default: '' },
    defaultCountryId: { type: String, required: true, default: '' },
    defaultPrefix: { type: String, required: true, default: '' },
    defaultZipcodeId: { type: String, required: true, default: '' },
    index: { type: Number, required: false, default: 0 },
    isConsignee: { type: Boolean, required: false },
    onlyCountry: { type: String, required: false, default: '' },
    excludeCountry: { type: String, required: false, default: '' },
  },
  emits: [
    'portOrAirport',
    'update',
    'updateWarehouse',
    'updateSelectedAppointment',
    'updateSelectedTime',
    'updateObservations',
    'updatePoNumber',
  ],
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const data = reactive({
      id: 'plan-select-warehouse-popper' + Math.random().toString(36).substr(2, 9),
      loadingWarehouses: false,
      selectedWarehouse: null,
      showAddWarehouse: false,
      showSelectItems: false,
      showUpdateWarehouse: false,
      selectedAppointment: 2,
      selectWidth: 0,
      warehouseForm: {
        appointment: 'NONE',
        airport: false,
        port: false,
        lumperFee: false,
        lumperFeePrice: null,
        from: null as any,
        to: null as any,
        observations: '',
        poNumber: '',
      },
      warehouses: [],
    });

    const actualRegion = computed(() => {
      return store.getters['UserStore/getRegion'];
    });

    const appointments = computed(() => {
      return [
        { label: translate.t('appt'), value: 'APPT' },
        { label: translate.t('fcfs'), value: 'FCFS' },
        { label: translate.t('label.none').toUpperCase(), value: 'NONE' },
      ];
    });

    const changeAirportValue = (newValue: boolean) => {
      data.warehouseForm.airport = true;
      data.warehouseForm.port = false;
      emit('portOrAirport', data.warehouseForm.airport ? 'airport' : 'port');
    };

    const changeAppointments = (index: number) => {
      data.warehouseForm.appointment = appointments.value[index].value;
      if (index === 0) {
        data.warehouseForm.from = data.warehouseForm.from ? data.warehouseForm.from : '--:--';
        data.warehouseForm.to = null;
      } else if (index === 1) {
        data.warehouseForm.from = data.warehouseForm.from ? data.warehouseForm.from : '--:--';
        data.warehouseForm.to = data.warehouseForm.to ? data.warehouseForm.to : '--:--';
      } else {
        data.warehouseForm.from = null;
        data.warehouseForm.to = null;
      }
      emit('updateSelectedTime', { mode: 'start', type: data.warehouseForm.from });
      emit('updateSelectedTime', { mode: 'end', type: data.warehouseForm.to });
      emit('updateSelectedAppointment', data.warehouseForm.appointment);
    };

    const changeFromHour = (value: string) => {
      data.warehouseForm.from = value;
      emit('updateSelectedTime', { mode: 'start', type: data.warehouseForm.from });
    };

    const changeLumperFee = (newValue: boolean) => {
      data.warehouseForm.lumperFee = newValue;
      selectWarehouse(data.selectedWarehouse);
    };

    const changePoNumber = (event: any) => {
      emit('updatePoNumber', event);
      selectWarehouse(data.selectedWarehouse);
    };

    const showUpdateWarehouseModal = (warehouse) => {
      store.commit('LoadsStore/setSelectedWarehouse', warehouse);
      data.showUpdateWarehouse = true;
    };

    const unSelectWarehouses = () => {
      data.selectedWarehouse = null;
      emit('updateWarehouse', '', '', false, 0, '', '', '', '', '', null, null, null);
    };

    const changeObservations = (event: any) => {
      emit('updateObservations', event);
      selectWarehouse(data.selectedWarehouse);
    };

    const changePortValue = (newValue: boolean) => {
      data.warehouseForm.airport = false;
      data.warehouseForm.port = true;
      emit('portOrAirport', data.warehouseForm.airport ? 'airport' : 'port');
    };

    const changeToHour = (value: string) => {
      data.warehouseForm.to = value;
      emit('updateSelectedTime', { mode: 'end', type: data.warehouseForm.to });
    };

    const changeWidth = () => {
      const planSelectWarehousePopper = document.getElementById(data.id);
      if (planSelectWarehousePopper) {
        nextTick(() => {
          data.selectWidth = planSelectWarehousePopper.offsetWidth;
        });
      }
    };

    const closePopper = () => {
      data.showSelectItems = false;
    };

    const closeAndAddWarehouse = (response: any) => {
      store.commit('LoadsStore/setSelectedWarehouse', response);
      data.selectedWarehouse = response;
      getWarehouses();
      data.showAddWarehouse = false;
      update(response);

      emit(
        'updateWarehouse',
        response.address,
        response.companyWarehouse,
        data.warehouseForm.lumperFee,
        data.warehouseForm.lumperFeePrice,
        data.warehouseForm.observations,
        data.warehouseForm.poNumber,
        response.phoneNumber,
        response.phonePrefix,
        response.contactName,
        response.id,
        data.warehouseForm.from,
        data.warehouseForm.to,
      );
    };

    const closeAndUpdateWarehouse = (response: any) => {
      store.commit('LoadsStore/setSelectedWarehouse', response);
      data.selectedWarehouse = response;
      getWarehouses();
      data.showUpdateWarehouse = false;
      update(response);

      emit(
        'updateWarehouse',
        response.address,
        response.companyWarehouse,
        data.warehouseForm.lumperFee,
        data.warehouseForm.lumperFeePrice,
        data.warehouseForm.observations,
        data.warehouseForm.poNumber,
        response.phoneNumber,
        response.phonePrefix,
        response.contactName,
        response.id,
        data.warehouseForm.from,
        data.warehouseForm.to,
      );
    };

    const getWarehouses = async () => {
      if (!props.zip && !data.selectedWarehouse) {
        return;
      }
      data.loadingWarehouses = true;

      let params = {
        page: 1,
        zipCode: props.zip,
        sortBy: 'companyWarehouse[asc]',
        maxResults: 20,
        countryId: props.defaultCountryId,
      };

      await store.dispatch('LoadsStore/getWarehousesList', params).then((response) => {
        changeWidth();
        data.warehouses = response.values;
        if (props.warehouseId !== -1) {
          data.warehouses.forEach((warehouse: any) => {
            if (warehouse.id === props.warehouseId) {
              data.selectedWarehouse = warehouse;
              selectWarehouse(warehouse);
            }
          });
        }
        data.loadingWarehouses = false;
      });
    };

    const handleClickOutside = (event) => {
      const popperElement = document.querySelector('#' + data.id);
      if (popperElement && !popperElement.contains(event.target)) closePopper();
    };

    const selectWarehouse = (warehouse: any) => {
      store.commit('LoadsStore/setSelectedWarehouse', warehouse);
      data.selectedWarehouse = warehouse;
      data.showSelectItems = false;
      update(warehouse);

      if (data.selectedWarehouse !== null) {
        emit(
          'updateWarehouse',
          warehouse.address,
          warehouse.companyWarehouse,
          data.warehouseForm.lumperFee,
          data.warehouseForm.lumperFeePrice,
          data.warehouseForm.observations,
          data.warehouseForm.poNumber,
          warehouse.phoneNumber,
          warehouse.phonePrefix,
          warehouse.contactName,
          warehouse.id,
          data.warehouseForm.from,
          data.warehouseForm.to,
        );
      }
    };

    const showAddWarehouseModal = () => {
      data.showSelectItems = false;
      data.showAddWarehouse = true;
    };

    const showWarehouses = () => {
      if (!data.loadingWarehouses) {
        data.showSelectItems = true;
      }
    };

    const update = (warehouse: any) => {
      emit('update', {
        warehouse: warehouse,
        lumperFee: data.warehouseForm.lumperFee,
        lumperFeePrice: data.warehouseForm.lumperFeePrice,
        observations: data.warehouseForm.observations,
        poNumber: data.warehouseForm.poNumber,
        from: data.warehouseForm.from,
        to: data.warehouseForm.to,
      });
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
      changeWidth();
      getWarehouses();
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    watch(
      () => props.zip,
      (newValue, oldValue) => {
        getWarehouses();
      },
    );

    watch(
      () => props.warehouseId,
      (newValue, oldValue) => {
        getWarehouses();
      },
    );

    if (props.zip.length > 0) getWarehouses();

    watch(
      () => props.warehouseData,
      (newValue, oldValue) => {
        data.warehouseForm.airport = newValue?.airport;
        data.warehouseForm.port = newValue?.port;
        if (newValue?.lumperFee) data.warehouseForm.lumperFee = newValue?.lumperFee;
        if (newValue?.lumperFeePrice) data.warehouseForm.lumperFeePrice = newValue?.lumperFeePrice;
        if (newValue?.observations) data.warehouseForm.observations = newValue?.observations;
        if (newValue?.poNumber) data.warehouseForm.poNumber = newValue?.poNumber;
        if (newValue?.lumperFeePrice) data.warehouseForm.lumperFee = true;
        data.warehouseForm.from = newValue?.startTime;
        if (data.warehouseForm.from?.length > 0 && !data.warehouseForm.to) {
          data.selectedAppointment = 0;
          data.warehouseForm.appointment = 'APPT';
        }
        data.warehouseForm.to = newValue?.endTime;
        if (data.warehouseForm.from?.length > 0 && data.warehouseForm.to?.length > 0) {
          data.selectedAppointment = 1;
          data.warehouseForm.appointment = 'FCFS';
        }
      },
    );

    watch(
      () => data.selectedWarehouse,
      (newValue, oldValue) => {
        if (!newValue) {
          data.warehouseForm = {
            appointment: 'NONE',
            airport: false,
            port: false,
            lumperFee: false,
            lumperFeePrice: null,
            from: null,
            to: null,
            observations: '',
            poNumber: '',
          };
        }
      },
    );

    return {
      ...toRefs(data),
      actualRegion,
      appointments,
      changeAirportValue,
      changeAppointments,
      changeFromHour,
      changeLumperFee,
      changeObservations,
      changePoNumber,
      changePortValue,
      changeToHour,
      closeAndAddWarehouse,
      closeAndUpdateWarehouse,
      showUpdateWarehouseModal,
      selectWarehouse,
      showAddWarehouseModal,
      showWarehouses,
      unSelectWarehouses,
    };
  },
});
