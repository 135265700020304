import globalAxios from 'axios';
// Resources
import i18n from '@/resources/locales';
const $t = i18n.global.t;

const acceptBid = async (data: any) => {
  const response = await globalAxios.post('/shipment_bids/' + data.id + '/accept_bid', {});
  return Promise.resolve(response.data);
};

const acceptBookNow = async (id: any) => {
  const response = await globalAxios.post(`/shipment/${id}/acceptBookNow`);
  return Promise.resolve(response.data);
};

const acceptLTLQuote = async (id: number) => {
  const response = await globalAxios.post(`/shipment_bids/${id}/LTL/accept`);
  return Promise.resolve(response.data);
};

const acceptService = async (data: any) => {
  const response = await globalAxios.put('/shipments/' + data.id + '/acceptService/completed', {});
  return Promise.resolve(response.data);
};

const assignDriver = async (data: any) => {
  const response = await globalAxios.put('/shipments/' + data.id + '/assignDriver', {
    driver: data.driver,
  });
  return Promise.resolve(response.data);
};

const assignInsurance = async (data: any) => {
  const response = await globalAxios.put('/shipments/' + data.id + '/assignInsurance', {
    insurances: [{ id: data.cargoInsuranceId }, { id: data.liabilityInsuranceId }],
  });
  return Promise.resolve(response.data);
};

const assignVehicle = async (data: any) => {
  const response = await globalAxios.put('/shipments/' + data.id + '/assignVehicle', {
    vehicle: data.vehicle,
  });
  return Promise.resolve(response.data);
};

const declineCurrentBid = async (data: any) => {
  const response = await globalAxios.put(`shipmentBidRequest/declinedStatusBid`, {
    shipment: data.shipment,
    company: data.company,
  });
  return response.data;
};

const downLoadAllDocuments = async (data: any) => {
  const response = await globalAxios.get(`/loads/${data.id}/downloadAllDocuments`, {
    responseType: 'blob',
  });
  return Promise.resolve(response.data);
};

const getBOLAutogenerated = async (data: any) => {
  const response = await globalAxios.get('/mediaObjects/GetBOLAutogenerated', {
    responseType: 'blob',
    params: data,
  });
  return Promise.resolve(response.data);
};

const getDownloadFile = async (data: any) => {
  const response = await globalAxios.get(
    '/mediaObjects/' + data.id + '/download/file/fromClass/' + data.class,
  );
  return Promise.resolve(response.data);
};

const getDownloadFileBlob = async (data: any) => {
  const response = await globalAxios.get(
    '/mediaObjects/' + data.id + '/download/file/fromClass/' + data.class,
    {
      responseType: 'blob',
    },
  );
  return Promise.resolve(response.data);
};

const getDownloadThumbnailBig = async (data: any) => {
  const response = await globalAxios.get(
    '/mediaObjects/' + data.id + '/download/thumbnailBig/fromClass/' + data.class,
    { responseType: 'blob' },
  );
  return Promise.resolve(response.data);
};

const getDownloadThumbnailSmall = async (data: any) => {
  const response = await globalAxios.get(
    '/mediaObjects/' + data.id + '/download/thumbnailSmall/fromClass/' + data.class,
    { responseType: 'blob' },
  );
  return Promise.resolve(response.data);
};

const getLTLShipmentBid = async (id: any) => {
  const response = await globalAxios.get(`/shipment_bids/${id}`, {});
  return Promise.resolve(response.data);
};

const getActivitySummary = async (notificationType: any) => {
  const params = {
    type: notificationType,
  };
  const response = await globalAxios.get(`/company/activitySummary`, { params });
  return Promise.resolve(response.data);
};

const getSavedShipmentBids = async (id: any) => {
  const params = {
    page: 1,
    maxResults: 100,
  };
  const response = await globalAxios.get(`shipment_bids/loads/${id}/LTL/saved`, { params });
  return Promise.resolve(response.data);
};

const getShipment = async (data: any) => {
  const response = await globalAxios.get('/shipments/' + data.id, {});
  return Promise.resolve(response.data);
};

const notifyPosition = async (data: any) => {
  const response = await globalAxios.post('/trackings/notify/manually', data, {});
  return response.data;
};

const postCargobotUpload = async (id: any) => {
  const response = await globalAxios.post(`shipments/${id}/cargobot/upload`, {});
  return Promise.resolve(response.data);
};

// MediaObjects / Insurances
const postInsurances = async (data: any) => {
  const response = await globalAxios.post('/insurances', data);
  return Promise.resolve(response.data);
};

const postInvoiceFTL = async (data: any) => {
  const response = await globalAxios.post('/invoices/FTL', data);
  return Promise.resolve(response.data);
};

const postMediaObjectInsurance = async (data: any) => {
  const response = await globalAxios.post('/mediaObjects/insurance', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return Promise.resolve(response.data);
};

const postMediaObjectInvoice = async (data: any) => {
  const response = await globalAxios.post('/mediaObjects/invoice', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return Promise.resolve(response.data);
};

const postMediaObjectStops = async (data: any) => {
  const response = await globalAxios.post('/mediaObjects/stop', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return Promise.resolve(response.data);
};

const postUpdateMediaObjectInsurance = async (data: any) => {
  const response = await globalAxios.post(
    '/mediaObjects/' + data.id + '/insurance/update',
    { file: data.file },
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
  return Promise.resolve(response.data);
};

const putInsurances = async (data: any) => {
  const response = await globalAxios.put('/insurances/' + data.id, data.body);
  return Promise.resolve(response.data);
};

const requestBid = async (data: any) => {
  const response = await globalAxios.post('/shipment_bids', data);
  return Promise.resolve(response.data);
};

const requestPosition = async (data: any) => {
  const response = await globalAxios.post(`/email/send-request-location?shipment=${data}`, {});
  return response.data;
};

const reSendRequestBid = async (data: number) => {
  const response = await globalAxios.post('/shipmentBidRequest/' + data + '/sendEmail', {});
  return response.data;
};

const saveShipmentBids = async (data: any) => {
  const response = await globalAxios.post(`/shipment_bids/LTL/save`, data);
  return Promise.resolve(response.data);
};

const signsRateConfirmation = async (data: any) => {
  const allowedTypes = [
    'application/pdf',
    'application/x-pdf',
    'image/jpeg',
    'image/jpg',
    'image/png',
  ];
  const maxSize = 4 * 1024 * 1024;
  const binaryData = atob(data.file.split(',')[1]);
  const array = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    array[i] = binaryData.charCodeAt(i);
  }

  const fileBlob = new Blob([array], { type: data.file.split(',')[0].split(':')[1].split(';')[0] });

  if (fileBlob.size > maxSize) {
    throw new Error($t('message-error-file-exceeded', { maxSize: maxSize }));
  }

  if (!allowedTypes.includes(fileBlob.type)) {
    throw new Error(
      $t('message-error-file-type', { type: fileBlob.type, allowedTypes: allowedTypes.join(', ') }),
    );
  }

  const formData = new FormData();
  formData.append('entityId', data.entityId);
  formData.append('file', fileBlob, 'file_name.png');

  const response = await globalAxios.post('/mediaObjects/signRateConfirmation', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return Promise.resolve(response.data);
};

const thumbnailWithoutSign = async (data: any) => {
  const response = await globalAxios.get('/mediaObjects/GetRateConfirmationWithoutSign', {
    responseType: 'blob',
    params: {
      shipment: data.shipment,
    },
  });
  return Promise.resolve(response.data);
};

const updateBid = async (data: any) => {
  const response = await globalAxios.put('/shipment_bids/' + data.id, { bid: data.bid });
  return Promise.resolve(response.data);
};

const updateInvoiceStatus = async (data: any) => {
  const response = await globalAxios.put(`/invoices/${data.id}/update/status`, data.status);
  return response.data;
};

const updateTrackingSubStatus = async (data: any) => {
  const response = await globalAxios.put(
    `/shipments/${data.id}/update_tracking_sub_status`,
    data.body,
  );
  return response.data;
};

const totalBids = async (data: any) => {
  const response = await globalAxios.get('/shipments/' + data.id + '/bidTotals', {});
  return Promise.resolve(response.data);
};

const sendDocumentToEmail = async (data: any) => {
  const response = await globalAxios.post(`/loads/${data.loadId}/sendDocumentToEmail`, {
    type: data.type,
    emails: data.emails,
    stopId: data.stopID,
    deliveryStopId: data.deliveryStopID,
  });
  return response.data;
};

export default {
  acceptBid,
  acceptBookNow,
  acceptLTLQuote,
  assignInsurance,
  acceptService,
  assignDriver,
  assignVehicle,
  declineCurrentBid,
  downLoadAllDocuments,
  getBOLAutogenerated,
  getDownloadFile,
  getDownloadFileBlob,
  getActivitySummary,
  getDownloadThumbnailBig,
  getDownloadThumbnailSmall,
  getLTLShipmentBid,
  getSavedShipmentBids,
  getShipment,
  notifyPosition,
  postCargobotUpload,
  postInsurances,
  postInvoiceFTL,
  postMediaObjectInsurance,
  postMediaObjectInvoice,
  postMediaObjectStops,
  postUpdateMediaObjectInsurance,
  putInsurances,
  requestBid,
  requestPosition,
  reSendRequestBid,
  saveShipmentBids,
  sendDocumentToEmail,
  signsRateConfirmation,
  thumbnailWithoutSign,
  totalBids,
  updateBid,
  updateInvoiceStatus,
  updateTrackingSubStatus,
};
