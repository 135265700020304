import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-start plan-gap-sm" }
const _hoisted_2 = { class: "font-bold plan-t-h2" }
const _hoisted_3 = { class: "w-full flex flex-col justify-center items-center" }
const _hoisted_4 = {
  key: 0,
  class: "font-bold plan-t-h4"
}
const _hoisted_5 = {
  key: 1,
  class: "font-bold plan-t-h4"
}
const _hoisted_6 = {
  key: 2,
  class: "font-bold plan-t-h4"
}
const _hoisted_7 = {
  key: 3,
  class: "font-bold plan-t-h4"
}
const _hoisted_8 = {
  key: 4,
  class: "font-bold plan-t-h4"
}
const _hoisted_9 = { class: "plan-t-prim-100 plan-t-xs" }
const _hoisted_10 = { class: "w-full flex justify-end items-center plan-gap-sm" }
const _hoisted_11 = {
  key: 2,
  class: "w-full flex justify-between items-center"
}
const _hoisted_12 = { class: "flex flex-col plan-gap-sm items-start justify-start" }
const _hoisted_13 = { class: "flex justify-start items-start plan-gap-sm" }
const _hoisted_14 = { class: "flex justify-center items-center plan-gap-sm" }
const _hoisted_15 = { class: "font-bold" }
const _hoisted_16 = {
  key: 3,
  class: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_plan_input_radio = _resolveComponent("plan-input-radio")!
  const _component_plan_default_confirmation_modal = _resolveComponent("plan-default-confirmation-modal")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_new_load_header_sticky = _resolveComponent("plan-new-load-header-sticky")!
  const _component_new_load_templates_modal = _resolveComponent("new-load-templates-modal")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!
  const _component_plan_new_general_domestic = _resolveComponent("plan-new-general-domestic")!
  const _component_plan_new_mx_domestic = _resolveComponent("plan-new-mx-domestic")!
  const _component_load_direct_assign = _resolveComponent("load-direct-assign")!
  const _component_load_freight_info = _resolveComponent("load-freight-info")!
  const _component_load_quotes = _resolveComponent("load-quotes")!
  const _component_plan_load_direct_assign_footer = _resolveComponent("plan-load-direct-assign-footer")!
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!
  const _component_plan_default_view = _resolveComponent("plan-default-view")!

  return (_openBlock(), _createBlock(_component_plan_default_view, { "show-footer": "" }, {
    "header-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_plan_text_button, {
          onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push('/planning'))),
          label: _ctx.$t('go-back'),
          icon: "chevron-left",
          type: "prim-100"
        }, null, 8, ["label"]),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('plan-new-load')), 1),
        (_ctx.currentView === 'shipment')
          ? (_openBlock(), _createBlock(_component_plan_input_radio, {
              onChange: _ctx.changeSelectedLoadType,
              options: _ctx.ftlOptions,
              "selected-option": _ctx.selectedLoadType,
              key: _ctx.radioLoadTypeKey
            }, null, 8, ["onChange", "options", "selected-option"]))
          : _createCommentVNode("", true),
        (_ctx.changeLoadTypeTo !== '')
          ? (_openBlock(), _createBlock(_component_plan_default_confirmation_modal, {
              key: 1,
              onClose: _ctx.cancelLoadType,
              onAccept: _ctx.selectLoadType,
              title: _ctx.$t('are-you-sure'),
              message: _ctx.$t('are-you-sure-change-load'),
              size: "sm-auto"
            }, null, 8, ["onClose", "onAccept", "title", "message"]))
          : _createCommentVNode("", true)
      ])
    ]),
    "header-buttons": _withCtx(() => [
      _createVNode(_component_plan_button, {
        onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showTemplates = true)),
        type: "primary-white",
        label: _ctx.$t('fill-from-template'),
        "show-icon": "",
        icon: "document",
        "icon-color": "#0071BF"
      }, null, 8, ["label"])
    ]),
    "header-sticky": _withCtx(() => [
      (_ctx.currentView === 'quote' || _ctx.currentView === 'freight' || _ctx.currentView === 'directAssign')
        ? (_openBlock(), _createBlock(_component_plan_new_load_header_sticky, {
            key: 0,
            onUpdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.currentView = 'shipment'))
          }))
        : _createCommentVNode("", true)
    ]),
    "view-content": _withCtx(() => [
      (_ctx.showTemplates)
        ? (_openBlock(), _createBlock(_component_new_load_templates_modal, {
            key: 0,
            onCloseModal: _ctx.closeTemplatesModal,
            templates: _ctx.newLoadTemplates
          }, null, 8, ["onCloseModal", "templates"]))
        : _createCommentVNode("", true),
      (_ctx.loadingPost)
        ? (_openBlock(), _createBlock(_component_plan_default_modal, {
            key: 1,
            size: "sm-auto",
            "only-content": ""
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_plan_icon, {
                  name: "spinner",
                  "icon-color": "#8A8B97",
                  size: "30",
                  rotate: "",
                  "is-svg": ""
                }),
                (_ctx.postLoadState === 'load')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('instant-quote-creating-load')), 1))
                  : _createCommentVNode("", true),
                (_ctx.postLoadState === 'bookNow')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('instant-quote-book-now')), 1))
                  : _createCommentVNode("", true),
                (_ctx.postLoadState === 'files')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('instant-quote-files')), 1))
                  : _createCommentVNode("", true),
                (_ctx.postLoadState === 'quotes')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('instant-quote-requesting-quote')), 1))
                  : _createCommentVNode("", true),
                (_ctx.postLoadState === 'customers')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('instant-quote-customers')), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('process-few-minutes')), 1)
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.showDirectAssignModal)
        ? (_openBlock(), _createBlock(_component_plan_default_confirmation_modal, {
            key: 2,
            onAccept: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeView('directAssign'))),
            onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDirectAssignModal = false)),
            title: _ctx.$t('modal-direct-assign.title'),
            message: _ctx.$t('modal-direct-assign.detail'),
            size: "sm-auto"
          }, null, 8, ["title", "message"]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", null, [
        (_ctx.selectedLoadType === 'REGULAR')
          ? (_openBlock(), _createBlock(_component_plan_new_general_domestic, {
              key: 0,
              onChangeView: _ctx.changeView
            }, null, 8, ["onChangeView"]))
          : _createCommentVNode("", true),
        (_ctx.selectedLoadType === 'MEX_DOMESTIC')
          ? (_openBlock(), _createBlock(_component_plan_new_mx_domestic, { key: 1 }))
          : _createCommentVNode("", true)
      ], 512), [
        [_vShow, _ctx.currentView === 'shipment']
      ]),
      (_ctx.currentView === 'directAssign')
        ? (_openBlock(), _createBlock(_component_load_direct_assign, {
            key: 3,
            onChangeView: _ctx.changeView
          }, null, 8, ["onChangeView"]))
        : _createCommentVNode("", true),
      (_ctx.currentView === 'freight')
        ? (_openBlock(), _createBlock(_component_load_freight_info, {
            key: 4,
            onChangeView: _ctx.changeView,
            onIsFreightComplete: _ctx.changeIsFreightComplete
          }, null, 8, ["onChangeView", "onIsFreightComplete"]))
        : _createCommentVNode("", true),
      (_ctx.currentView === 'quote')
        ? (_openBlock(), _createBlock(_component_load_quotes, {
            key: 5,
            onUpdateSelectedCompanies: _ctx.updateSelectedCompanies,
            onChangeView: _ctx.changeView
          }, null, 8, ["onUpdateSelectedCompanies", "onChangeView"]))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_10, [
        (_ctx.currentView === 'directAssign')
          ? (_openBlock(), _createBlock(_component_plan_load_direct_assign_footer, {
              key: 0,
              onContinue: _ctx.postFtlLoad
            }, null, 8, ["onContinue"]))
          : _createCommentVNode("", true),
        (_ctx.currentView === 'shipment' && _ctx.additionalStops.length === 0)
          ? (_openBlock(), _createBlock(_component_plan_button, {
              key: 1,
              onOnClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showDirectAssignModal = true)),
              disabled: !_ctx.isValidatedForm.result,
              type: "primary-dark-outline",
              label: _ctx.$t('assign-carrier')
            }, null, 8, ["disabled", "label"]))
          : _createCommentVNode("", true),
        (_ctx.currentView === 'quote')
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_plan_input_checkbox, {
                  onChange: _ctx.changeIsCheckedShowQuotes,
                  label: _ctx.$t('show-bids-carrier')
                }, null, 8, ["onChange", "label"]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_plan_input_checkbox, {
                    onChange: _ctx.changeBookNowEnabled,
                    label: _ctx.$t('book-now'),
                    style: {"min-width":"150px"}
                  }, null, 8, ["onChange", "label"]),
                  _withDirectives(_createVNode(_component_plan_input_number, {
                    "model-value": _ctx.bookNow,
                    "onUpdate:model-value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.bookNow) = $event)),
                    "hide-validations": "",
                    currency: "",
                    style: {"min-width":"200px"}
                  }, null, 8, ["model-value"]), [
                    [_vShow, _ctx.showBookNow]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('request-quotation-from-x-contacts', { quantity: _ctx.selectedCompanies.length })), 1),
                (_ctx.currentView === 'quote')
                  ? (_openBlock(), _createBlock(_component_plan_button, {
                      key: 0,
                      onOnClick: _ctx.postFtlLoad,
                      loading: _ctx.loadingPost,
                      type: "success",
                      label: _ctx.$t('continue')
                    }, null, 8, ["onOnClick", "loading", "label"]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentView === 'shipment')
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              (_ctx.selectedLoadType === 'REGULAR')
                ? (_openBlock(), _createBlock(_component_plan_button, {
                    key: 0,
                    onOnClick: _ctx.handleChangeStep,
                    disabled: !_ctx.isValidatedForm.result,
                    type: "success",
                    label: _ctx.$t('continue')
                  }, null, 8, ["onOnClick", "disabled", "label"]))
                : _createCommentVNode("", true),
              (_ctx.selectedLoadType === 'MEX_DOMESTIC')
                ? (_openBlock(), _createBlock(_component_plan_button, {
                    key: 1,
                    onOnClick: _ctx.handleChangeStep,
                    disabled: !_ctx.isValidatedForm.resultMx,
                    type: "success",
                    label: _ctx.$t('continue')
                  }, null, 8, ["onOnClick", "disabled", "label"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentView === 'freight')
          ? (_openBlock(), _createBlock(_component_plan_button, {
              key: 4,
              onOnClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.changeView('quote'))),
              disabled: !_ctx.isFreightComplete,
              type: "success",
              label: _ctx.$t('continue')
            }, null, 8, ["disabled", "label"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}