
import { defineComponent, reactive, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierLoadingFullScreen from '@/components/carrier/PlanCarrierLoadingFullScreen.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'CustomAgentCCP',
  components: {
    PlanCarrierLoadingFullScreen,
    PlanDefaultCarrierView,
    PlanDefaultModal,
    PlanIcon,
  },
  props: {
    id: { type: String, required: false, default: null },
    hash: { type: String, required: false, default: null },
    code: { type: String, required: false, default: null },
  },
  setup(props) {
    const store = useStore();
    const t = useI18n().t;

    const data = reactive({
      load: null as any,
      loadingLoad: false,
      uploadingDoc: false,
      loadingDocs: false,
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const docsList = computed(() => {
      let response = [] as any;
      const docs = store.getters['LoadsStore/getDocsList'];
      for (const key in docs) {
        if (Object.hasOwnProperty.call(docs, key)) {
          response.push({
            documents: docs[key],
            key: key,
            title: t(key),
          });
        }
      }
      return response;
    });

    const invoices = computed(() => {
      return docsList.value.find((section) => section.key === 'invoice_docs');
    });

    const loadDocs = computed(() => {
      return docsList.value.find((section) => section.key === 'load_docs');
    });

    const stopDocs = computed(() => {
      return docsList.value.find((section) => section.key === 'stop_docs');
    });

    const changeFiles = async (event) => {
      const newFiles = event?.target?.files as HTMLInputElement;
      if (typeof newFiles === 'number' || data.uploadingDoc) {
        return;
      }
      const files: any = newFiles;
      const filesArray: File[] = Array.from(files);
      const acceptedTypes = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/png',
      ];
      const maxSize = 100 * 1024 * 1024;

      const validFiles = filesArray.filter((file) => {
        if (!acceptedTypes.includes(file.type)) {
          store.dispatch('UserStore/updateToast', {
            text: t('file-not-valid-type', { file: file.name }),
            type: 'error',
            visible: true,
          });
          return false;
        }

        if (file.size > maxSize) {
          store.dispatch('UserStore/updateToast', {
            text: t('file-not-valid-size', { file: file.name }),
            type: 'error',
            visible: true,
          });
          return false;
        }

        return true;
      });
      if (validFiles.length > 0) {
        validFiles.forEach((file) => {
          data.uploadingDoc = true;
          store
            .dispatch('LoadsStore/postMediaObjectLoad', {
              id: loadData.value.id,
              file: file,
              type: 'PEDIMENTO',
            })
            .then(() => store.dispatch('UserStore/showToast', t('Pedimnento uploaded')))
            .finally(() => (data.uploadingDoc = false));
        });
      }
    };

    const download = async (type) => {
      const docs =
        type === 'PACKAGING_LIST'
          ? loadDocs.value
          : type === 'BOL'
          ? stopDocs.value
          : invoices.value;
      data.loadingDocs = type;
      const allDocs = docs.documents.filter((doc) => doc.type === type);
      if (allDocs.length > 0) {
        const doc = allDocs[allDocs.length - 1];
        const response = await store
          .dispatch('ShipmentStore/getDownloadFileBlob', {
            id: doc.id,
            class: doc.entityClass,
          })
          .finally(() => (data.loadingDocs = false));
        const fileName = `${doc.displayName}#${doc.id}`;
        let file = document.createElement('a');
        file.href = URL.createObjectURL(response);
        file.download = `documents-${fileName}`;
        file.click();
      } else {
        data.loadingDocs = false;
      }
    };

    const getData = () => {
      data.loadingLoad = true;
      store.dispatch('CarrierStore/getLoadInfoForCarrier', props.id).then((response) => {
        data.loadingLoad = false;
        console.log(loadData.value);

        store.dispatch('LoadsStore/getDocsList', loadData.value.id);
      });
    };

    const loginUser = () => {
      store
        .dispatch('UserStore/hashValidation', {
          hashId: props.hash,
          secureCode: props.code,
        })
        .then(() => {
          getData();
        });
    };

    loginUser();

    return {
      ...toRefs(data),
      changeFiles,
      download,
      loadData,
    };
  },
});
