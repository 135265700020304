import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded border plan-border-prim-500 flex flex-col plan-gap-lg plan-p-md w-full bg-white transition-all duration-300" }
const _hoisted_2 = { class: "flex flex-col justify-start items-start" }
const _hoisted_3 = { class: "plan-t-prim-100 plan-t-xs" }
const _hoisted_4 = { class: "flex justify-start plan-gap-sm" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "flex flex-col plan-t-xs" }
const _hoisted_7 = { class: "font-bold plan-t-prim-100 m-0" }
const _hoisted_8 = { class: "font-bold plan-t-prim-100 m-0" }
const _hoisted_9 = { class: "plan-t-prim-100 m-0" }
const _hoisted_10 = { class: "flex justify-start items-center plan-gap-sm flex-wrap plan-t-prim-100" }
const _hoisted_11 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_12 = { class: "flex justify-start items-start plan-gap-xs" }
const _hoisted_13 = { class: "flex justify-start items-start plan-gap-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_avatar = _resolveComponent("plan-avatar")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_formatted_number = _resolveComponent("plan-formatted-number")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('upload-your-invoice-missing-data-1')) + " " + _toDisplayString(_ctx.$t('upload-your-invoice-missing-data-2')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_plan_avatar, {
          "user-name": _ctx.shipperOwner.user,
          description: _ctx.shipperOwner.company
        }, null, 8, ["user-name", "description"]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_plan_button_icon, {
            onOnClick: _ctx.openChat,
            icon: "chat",
            type: "transparent-gray-800"
          }, null, 8, ["onOnClick"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", _hoisted_7, "#" + _toDisplayString(_ctx.loadDetails.identifier), 1),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.loadDetails.pickupLocation) + " - " + _toDisplayString(_ctx.loadDetails.deliveryLocation), 1),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.loadDetails.pickupDate) + " - " + _toDisplayString(_ctx.loadDetails.deliveryDate), 1),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_plan_icon, {
            name: "distance",
            "icon-color": "#8A8B97",
            "is-svg": "",
            size: "13"
          }),
          _createVNode(_component_plan_formatted_number, {
            number: _ctx.loadDetails.distance,
            onlyCountry: _ctx.loadData?.type === 'MEX_DOMESTIC' ? 'mx' : '',
            unit: "distance"
          }, null, 8, ["number", "onlyCountry"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_plan_icon, {
            name: "icInRoute",
            "icon-color": "#8A8B97",
            "is-svg": "",
            size: "11",
            class: "plan-pt-xs"
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.loadDetails.trailer), 1)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_plan_icon, {
            name: "package",
            "icon-color": "#8A8B97",
            "is-svg": "",
            size: "11",
            class: "plan-pt-xs"
          }),
          _createElementVNode("p", null, [
            _createTextVNode(_toDisplayString(_ctx.loadDetails.commodities.label) + " ", 1),
            _createVNode(_component_plan_formatted_number, {
              onlyCountry: _ctx.loadData?.type === 'MEX_DOMESTIC' ? 'mx' : '',
              number: _ctx.loadDetails.commodities.value,
              unit: "weight"
            }, null, 8, ["onlyCountry", "number"])
          ])
        ])
      ])
    ])
  ]))
}