import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"background-color":"rgb(240 240 240 / var(--tw-bg-opacity))"},
  class: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!

  return (_ctx.showShareButton)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_plan_button, {
          icon: "share",
          type: "primary-outline",
          "full-rounded": "",
          "icon-color": _ctx.iconColor,
          iconType: 'svg',
          label: _ctx.$t('share-accounting'),
          "show-icon": true,
          onOnClick: _ctx.handleOpenShareModal,
          onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.iconColor = '#ffffff')),
          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.iconColor = '#0097FF'))
        }, null, 8, ["icon-color", "label", "onOnClick"])
      ]))
    : _createCommentVNode("", true)
}