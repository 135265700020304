import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-bold md:text-2xl" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "mt-4 px-8" }
const _hoisted_4 = { class: "plan-mt-xl w-full flex justify-between items-center plan-gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    show: _ctx.showModal && _ctx.localStorageTZModal !== 'no',
    modalSize: "small",
    onCallbackClose: _ctx.close
  }, {
    title: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('timezone-settings')), 1)
    ]),
    main: _withCtx(() => [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('change-timezone-to', { timezone: _ctx.tz })), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.accept,
          type: "primary",
          label: _ctx.$t('yes-update-my-settings'),
          loading: _ctx.isLoading
        }, null, 8, ["onOnClick", "label", "loading"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", {
          class: "underline decoration-dotted cursor-pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dontShowAgain && _ctx.dontShowAgain(...args)))
        }, _toDisplayString(_ctx.$t('dont-update-my-settings')), 1),
        _createElementVNode("p", {
          class: "underline decoration-dotted cursor-pointer",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dontShowAgain && _ctx.dontShowAgain(...args)))
        }, _toDisplayString(_ctx.$t('dont-show-me-this-again')), 1)
      ])
    ]),
    _: 1
  }, 8, ["show", "onCallbackClose"]))
}