import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col items-start justify-start w-full mr-1" }
const _hoisted_2 = {
  key: 0,
  class: "plan-form"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "w-full flex justify-center items-center" }
const _hoisted_5 = {
  key: 0,
  style: {"max-width":"40px"},
  class: "flex justify-center items-center plan-div-form plan-div-form-hide-right plan-div-form-show-right-line plan-t-prim-100"
}
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  key: 1,
  style: {"max-width":"40px"},
  class: "flex justify-center items-center plan-div-form plan-div-form-hide-left plan-div-form-show-left-line plan-t-prim-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label.length > 0)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.mandatory)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.leftLabel.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.leftLabel), 1))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
        placeholder: _ctx.placeholder,
        type: "number",
        class: _normalizeClass(["plan-form w-full", {'plan-form-hide-left': _ctx.leftLabel.length > 0, 'plan-form-hide-right': _ctx.rightLabel.length > 0}])
      }, null, 10, _hoisted_6), [
        [_vModelText, _ctx.internalValue]
      ]),
      (_ctx.rightLabel.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.rightLabel), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}