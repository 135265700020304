
import { computed, defineComponent } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanThumbnail from '@/components/forms/PlanThumbnail.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'PlanCarrierDocumentsTab',
  components: { PlanThumbnail, PlanButton, PlanIcon },
  setup() {
    const store = useStore();
    const translate = useI18n();
    let fetchedImageOriginal = null as any;

    const actualRegion = computed(() => {
      return store.getters['UserStore/getRegion']
    })

    const documents = computed(() => {
      let rateConfirmation = {
        date: '',
        id: '',
        entityClass: '',
      };

      store.getters['CarrierStore/getLoadData'].shipment.mediaObjects.forEach(
        (mediaObject: any) => {
          if (mediaObject.type === 'RATE_CONFIRMATION') {
            rateConfirmation = {
              date: mediaObject.createdAt,
              id: mediaObject.id,
              entityClass: mediaObject.entityClass,
            };
          }
        },
      );

      return {
        rateConfirmation: rateConfirmation,
      };
    });

    const downloadAllDocs = async () => {
      let response = await store.dispatch('ShipmentStore/downLoadAllDocuments', {
        id: loadData.value.id,
      });

      fetchedImageOriginal = document.createElement('a');
      fetchedImageOriginal.href = URL.createObjectURL(response);
      fetchedImageOriginal.download = `documents-${''}`;
      fetchedImageOriginal.click();
    };

    const getFormattedDate = (date: string) => {
      moment.locale(translate.locale.value);
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? moment(date).format('MMM DD, YYYY')
        : moment(date).format('DD MMM, YYYY');
    };

    const getFormattedDateHour = (date: string) => {
      moment.locale(translate.locale.value);
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? moment(date).format('MMM DD, YYYY HH:MM')
        : moment(date).format('DD MMM, YYYY HH:MM');
    };

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    return {
      actualRegion,
      documents,
      downloadAllDocs,
      getFormattedDate,
      getFormattedDateHour,
      loadData,
    };
  },
});
