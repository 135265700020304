
import { defineComponent, ref, computed, watch, reactive, toRefs } from 'vue';
import PlanCalendar from '@/components/forms/PlanCalendar.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import i18n from '@/resources/locales';
import store from '@/services/store';
import moment from 'moment';

interface PlanInputCalendar {
  showPopper: boolean;
  isHover: boolean;
}

export default defineComponent({
  name: 'PlanInputCalendar',
  components: { PlanIcon, PlanCalendar, PlanButtonIcon },
  props: {
    allowFormatDate: { type: Boolean, required: false, default: false },
    mandatory: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: '' },
    simple: { type: Boolean, required: false, default: false },
    min: { type: String, required: false, default: '' },
    max: { type: String, required: false, default: '' },
    defaultDateLabel: { type: String, required: false, default: 'mm-dd-yyyy' },
    hideValidation: { type: Boolean, required: false, default: false },
    hideClose: { type: Boolean, required: false, default: false },
    selectedInBold: { type: Boolean, required: false, default: true },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const data = reactive<PlanInputCalendar>({
      showPopper: false,
      isHover: false,
    });
    let selectedDate = ref(props.defaultDateLabel);

    const isComplete = computed(() => {
      return (
        selectedDate.value !== 'mm-dd-yyyy' &&
        selectedDate.value !== 'dd-mm-yyyy' &&
        selectedDate.value !== 'mm-dd-aaaa' &&
        selectedDate.value !== 'dd-mm-aaaa' &&
        selectedDate.value !== ''
      );
    });

    const clearDate = () => {
      data.showPopper = false;
      selectedDate.value = selectedDateTranslated('');
      changeDate(selectedDate.value);
    };

    const changeDate = (newDate: string) => {
      selectedDate.value = newDate;
      emit('change', newDate);
      data.showPopper = false;
    };
    const selectedDateTranslated = (selectedDate: string) => {
      const date = `${selectedDate}`;

      if (
        date !== 'mm-dd-yyyy' &&
        date !== 'dd-mm-yyyy' &&
        date !== 'mm-dd-aaaa' &&
        date !== 'dd-mm-aaaa' &&
        date !== ''
      ) {
        if (store.getters['UserStore/getRegion'] === 'NORTH_AMERICA') {
          return moment(date, 'DD-MM-YYYY').format('MMM DD, YYYY');
        } else {
          return moment(date, 'DD-MM-YYYY').format('D MMMM YYYY');
        }
      } else {
        return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
          ? 'mm-dd-yyyy'
          : 'dd-mm-aaaa';
      }
    };

    watch(
      () => data.isHover,
      (newValue, oldValue) => {
        if (!data.isHover) data.showPopper = false;
      },
    );

    watch(
      () => props.defaultDateLabel,
      (newValue) => {
        if (
          selectedDate.value === 'mm-dd-aaaa' ||
          selectedDate.value === 'dd-mm-aaaa' ||
          selectedDate.value === 'mm-dd-yyyy' ||
          selectedDate.value === 'dd-mm-yyyy'
        )
          selectedDate.value = newValue;
      },
    );

    return {
      ...toRefs(data),
      isComplete,
      changeDate,
      clearDate,
      selectedDate,
      selectedDateTranslated,
      i18n,
    };
  },
});
