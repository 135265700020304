import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "text-center"
}
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "text-sm" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = {
  key: 3,
  class: "text-center"
}
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "text-sm font-semibold" }
const _hoisted_8 = { class: "w-full flex flex-col justify-start items-center plan-gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_load_header = _resolveComponent("plan-carrier-load-header")!
  const _component_plan_carrier_load_sub_header = _resolveComponent("plan-carrier-load-sub-header")!
  const _component_CarrierGeneralNotification = _resolveComponent("CarrierGeneralNotification")!
  const _component_CarrierRFPConfirmation = _resolveComponent("CarrierRFPConfirmation")!
  const _component_plan_carrier_load_header_notification = _resolveComponent("plan-carrier-load-header-notification")!
  const _component_plan_carrier_load_details = _resolveComponent("plan-carrier-load-details")!
  const _component_plan_carrier_load_stop_data = _resolveComponent("plan-carrier-load-stop-data")!
  const _component_ImpersonatingBarCarrier = _resolveComponent("ImpersonatingBarCarrier")!
  const _component_plan_carrier_load_bid = _resolveComponent("plan-carrier-load-bid")!
  const _component_plan_default_carrier_view = _resolveComponent("plan-default-carrier-view")!

  return (_ctx.loadData !== null)
    ? (_openBlock(), _createBlock(_component_plan_default_carrier_view, {
        key: 0,
        "show-footer": 
      (_ctx.loadData.status === 'QUOTING' || !_ctx.isCarrierAssignedToThisLoad) &&
      _ctx.getConfirmationRFP?.isPendingConfirm === false &&
      _ctx.currentStatus === ''
    
      }, {
        "header-content": _withCtx(() => [
          _createVNode(_component_plan_carrier_load_header)
        ]),
        "subheader-content": _withCtx(() => [
          _createVNode(_component_plan_carrier_load_sub_header),
          (_ctx.getConfirmationRFP?.isPendingConfirm || _ctx.shipperHasSelectedYouNotification)
            ? (_openBlock(), _createBlock(_component_plan_carrier_load_header_notification, {
                key: 0,
                type: _ctx.currentTypeHeaderNotifycation,
                class: "plan-mt-md"
              }, {
                container: _withCtx(() => [
                  (_ctx.shipperHasSelectedYouNotification && _ctx.loadData.status === 'CONFIRMED')
                    ? (_openBlock(), _createBlock(_component_CarrierGeneralNotification, {
                        key: 0,
                        text: _ctx.$t('confirm-rfp-load'),
                        buttonText: _ctx.$t('accept'),
                        onClickhandler: _ctx.goToLoadAcceptation
                      }, null, 8, ["text", "buttonText", "onClickhandler"]))
                    : _createCommentVNode("", true),
                  (_ctx.getConfirmationRFP?.isPendingConfirm && _ctx.currentStatus === 'WAITING')
                    ? (_openBlock(), _createBlock(_component_CarrierRFPConfirmation, {
                        key: 1,
                        currentStatus: _ctx.currentStatus,
                        deadlineText: _ctx.$t('acceptance-limit'),
                        deadlineTime: _ctx.formattingDate(_ctx.currentAssignConfirmation.expirationDate),
                        headerText: _ctx.$t('confirm-rfp-load'),
                        currentAssignConfirmation: _ctx.currentAssignConfirmation,
                        onFetch: _ctx.fetch,
                        onFetchConfirmationRFP: _ctx.fetchConfirmationRFP
                      }, null, 8, ["currentStatus", "deadlineText", "deadlineTime", "headerText", "currentAssignConfirmation", "onFetch", "onFetchConfirmationRFP"]))
                    : _createCommentVNode("", true),
                  (_ctx.getConfirmationRFP?.isPendingConfirm && _ctx.currentStatus === 'EXPIRED')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('quotation-expired')), 1),
                        _createElementVNode("p", _hoisted_3, [
                          _createTextVNode(_toDisplayString(_ctx.$t('acceptance-limit')) + ": ", 1),
                          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formattingDate(_ctx.currentAssignConfirmation.expirationDate)), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.getConfirmationRFP?.isPendingConfirm && _ctx.currentStatus === 'DECLINE')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('refused-offer')), 1),
                        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('refused-offer-body')), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["type"]))
            : _createCommentVNode("", true)
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_plan_carrier_load_details),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stopsData, (stopData) => {
              return (_openBlock(), _createBlock(_component_plan_carrier_load_stop_data, {
                key: stopData.stop,
                "to-city": stopData.toCity,
                stop: stopData.stop
              }, null, 8, ["to-city", "stop"]))
            }), 128))
          ]),
          _createVNode(_component_ImpersonatingBarCarrier)
        ]),
        footer: _withCtx(() => [
          (
          (_ctx.loadData.status === 'QUOTING' || !_ctx.isCarrierAssignedToThisLoad) &&
          !_ctx.getConfirmationRFP?.isPendingConfirm &&
          _ctx.currentStatus === ''
        )
            ? (_openBlock(), _createBlock(_component_plan_carrier_load_bid, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["show-footer"]))
    : _createCommentVNode("", true)
}