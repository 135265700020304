import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center plan-gap-md" }
const _hoisted_2 = { class: "new-load-button-popper-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanButtonGroup = _resolveComponent("PlanButtonGroup")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PlanButtonGroup, {
      onChange: _ctx.changeDisplayMode,
      type: "primary-dark",
      "active-button-index": _ctx.activeButton,
      buttons: [
        { label: '', icon: 'planning-cards' },
        { label: '', icon: 'calendar' },
      ]
    }, null, 8, ["onChange", "active-button-index"]),
    (_ctx.isEuropeCompany())
      ? (_openBlock(), _createBlock(_component_plan_button, {
          key: 0,
          type: "primary",
          label: _ctx.$t('add-load'),
          onOnClick: _ctx.openNewFtlLoad
        }, null, 8, ["label", "onOnClick"]))
      : _createCommentVNode("", true),
    (!_ctx.isEuropeCompany())
      ? (_openBlock(), _createBlock(_component_popper, {
          key: 1,
          hover: "",
          offsetDistance: "-6",
          class: "new-load-button-popper"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_plan_button, {
                id: "addFtlLoad",
                onOnClick: _ctx.openNewFtlLoad,
                type: "sec-600",
                label: _ctx.$t('ftl-load')
              }, null, 8, ["onOnClick", "label"]),
              (_ctx.userRegion === 'NORTH_AMERICA')
                ? (_openBlock(), _createBlock(_component_plan_button, {
                    key: 0,
                    id: "addLtlLoad",
                    onOnClick: _ctx.Routing.openNewLTLload,
                    type: "sec-600",
                    label: _ctx.$t('ltl-load')
                  }, null, 8, ["onOnClick", "label"]))
                : _createCommentVNode("", true),
              (!_ctx.isPROD && !_ctx.isPRE)
                ? (_openBlock(), _createBlock(_component_plan_button, {
                    key: 1,
                    id: "newFtlLoad",
                    onOnClick: _ctx.goToNewFtlLoad,
                    type: "sec-600",
                    label: "New Load"
                  }, null, 8, ["onOnClick"]))
                : _createCommentVNode("", true),
              (!_ctx.isPROD && !_ctx.isPRE)
                ? (_openBlock(), _createBlock(_component_plan_button, {
                    key: 2,
                    id: "crossBorder",
                    onOnClick: _ctx.goToCrossBorder,
                    type: "sec-600",
                    label: "MX Load"
                  }, null, 8, ["onOnClick"]))
                : _createCommentVNode("", true)
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_plan_button, {
              id: "addLoadUsa",
              type: "primary",
              label: _ctx.$t('add-load')
            }, null, 8, ["label"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}