
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import moment from 'moment/moment';
import { useI18n } from 'vue-i18n';
import PlanLoadFormSelectDelivery from '@/components/loads/form/domestic/PlanLoadFormSelectDelivery.vue';
import PlanLoadFormSelectCommodity from '@/components/loads/form/domestic/PLanLoadFormSelectCommodity.vue';

interface loadFreightInfo {
  commodities: Array<any>,
  cleanCommodities: Array<any>,
  deliveries: number
}

export default defineComponent({
  name: 'LoadFreightInfo',
  components: { PlanLoadFormSelectCommodity, PlanIcon },
  emits: ['changeView', 'isFreightComplete'],
  setup (props, {emit}) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive<loadFreightInfo>({
      commodities: [],
      cleanCommodities: [],
      deliveries: 0
    })

    const changeView = (component: string) => {
      emit('changeView', component)
    }

    const getCommoditiesForStop = (stopType: string, clean: boolean) => {
      let commoditiesToAdd: Array<any> = []
      store.getters['NewFtlLoad/getCommodities'].forEach((commodity: any) => {
        commoditiesToAdd.push({
          commodityDescription: commodity.commodityDescription,
          weight: clean ? 0 : commodity.weight,
          units: clean ? 0 : commodity.units,
          type: commodity.type,
          stopType: stopType,
          deliverIn: [],
          isActive: false
        })
      })

      return commoditiesToAdd
    }

    const getFormattedDate = (date: string) => {
      moment.locale(translate.locale.value);
      const parsedDate = moment(date, 'DD-MM-YYYY', true)

      if (!parsedDate.isValid()) {
        return date
      }

      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? parsedDate.format('MMM DD, YYYY')
        : parsedDate.format('DD MMM, YYYY');
    }

    const getPackageType = (packageValue: number) => {
      const searchedPackage = packageTypes.value.find((packageType: any) => packageType.value === packageValue)
      return searchedPackage.label
    }

    const loadData = computed(() => {
      let pickups = 0
      let deliveries = 0
      let result: Array<any> = []
      let index = 0

      result.push({
        index: index,
        type: 'PICKUP_INITIAL',
        date: getFormattedDate(store.getters['NewFtlLoad/getQuery'].pickup.date),
        location: store.getters['NewFtlLoad/getQuery'].pickup.stopData.location,
        stopData: store.getters['NewFtlLoad/getQuery'].pickup.stopData
      })

      store.getters['NewFtlLoad/getAdditionalStops'].forEach((additionalStop: any) => {
        index = index + 1
        if (additionalStop.type === 'PICKUP') pickups += pickups + 1
        if (additionalStop.type === 'DELIVERY') deliveries += deliveries + 1
        result.push({
          index: index,
          type: additionalStop.stopType,
          date: getFormattedDate(additionalStop.date),
          location: additionalStop.stopData.location,
          stopData: additionalStop.stopData
        })
      })

      index = index + 1
      result.push({
        index: index,
        type: 'DELIVERY_FINAL',
        date: getFormattedDate(store.getters['NewFtlLoad/getQuery'].delivery.date),
        location: store.getters['NewFtlLoad/getQuery'].delivery.stopData.location,
        stopData: store.getters['NewFtlLoad/getQuery'].delivery.stopData
      })
      return {
        loads: result,
        pickups: pickups,
        deliveries: deliveries
      }
    })

    const possibleDeliveries = computed(() => {
      let result: Array<any> = []

      loadData.value.loads.forEach((stop: any, index: number) => {
        if (stop.type === 'DELIVERY_FINAL' || stop.type === 'DELIVERY') {
          result.push({
            index: stop.index,
            location: stop.location,
            date: stop.date,
            stopData: stop.stopData
          })
        }
      })

      return result
    })

    const prepareCommodities = () => {
      data.deliveries = 0
      data.commodities = []
      data.commodities.push(getCommoditiesForStop('PICKUP_INITIAL', true))
      store.getters['NewFtlLoad/getAdditionalStops'].forEach((additionalStop: any) => {
        if (additionalStop.stopType === 'DELIVERY') {
          data.deliveries = data.deliveries + 1
        }
        data.commodities.push(getCommoditiesForStop(additionalStop.stopType, true))
      })
      data.commodities.push(getCommoditiesForStop('DELIVERY_FINAL', data.deliveries !== 0))

      if (data.deliveries === 0) {
        data.commodities[data.commodities.length - 1].forEach((commodity: any) => {
          commodity.isActive = true
        })
      }

      data.cleanCommodities = [...data.commodities]
      store.commit('NewFtlLoad/setFreightInfo', data.commodities)
    }

    const packageTypes = computed(() => {
      let result: Array<any> = [];
      store.getters['LoadsStore/packageTypes'].FTL?.forEach((packageType: any) => {
        result.push({
          label: packageType.fullName,
          value: packageType.id.toString(),
        });
      });

      return result;
    })

    const totals = computed(() => {
      const defaultCommodities = getCommoditiesForStop('STOP', false)
      let cleanCommodities = getCommoditiesForStop('STOP', true)
      let isAllBlank = true
      let emptyDestinies = true

      data.commodities.forEach((stop: any) => {
        let stopIsAllBlank = 0
        stop.forEach((commodity: any, index: number) => {
          if (commodity.isActive && commodity.weight !== 0 && commodity.units !== 0) stopIsAllBlank = stopIsAllBlank + 1

          if (commodity.stopType === 'PICKUP' || commodity.stopType === 'PICKUP_INITIAL') {
            if (commodity.isActive && commodity.deliverIn.length === 0) emptyDestinies = false
            cleanCommodities[index].weight += commodity.weight
            cleanCommodities[index].units += commodity.units
          }
          if (commodity.stopType === 'DELIVERY' || commodity.stopType === 'DELIVERY_FINAL') {
            cleanCommodities[index].weight -= commodity.weight
            cleanCommodities[index].units -= commodity.units
          }
        })
        if (stopIsAllBlank === 0) isAllBlank = false
      })

      let result = ''
      let isComplete = true
      cleanCommodities.forEach((commodity: any) => {
        if (commodity.weight !== 0 || commodity.units !== 0) isComplete = false
        result += `${commodity.commodityDescription} - Weight: ${commodity.weight} - Units ${commodity.units} \n`
      })

      emit('isFreightComplete', {
        text: result,
        isComplete: (!isAllBlank || !emptyDestinies) ? false : isComplete
      })

      return {
        text: result,
        isComplete: (!isAllBlank || !emptyDestinies) ? false : isComplete
      }
    })

    const updateStopCommodities = (stopCommoditiesData: any) => {
      data.commodities[stopCommoditiesData.index] = stopCommoditiesData.commodities
      store.commit('NewFtlLoad/setFreightInfo', data.commodities)
    }

    const weightType = computed(() => {
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'lbs':'kgs'
    })

    prepareCommodities()

    return {
      ...toRefs(data),
      changeView,
      getPackageType,
      loadData,
      possibleDeliveries,
      prepareCommodities,
      totals,
      updateStopCommodities,
      weightType
    }
  }
})
