
import { defineComponent, reactive, toRefs, watch } from 'vue';

interface superadminUpdateShipperPricingInfo {
  value: number | null
}
export default defineComponent({
  name: 'SuperadminUpdateShipperPricingInfo',
  props: {
    pricingInfoMargin: { type: Number, required: true, default: null }
  },
  emits: ['updatePricingInfoMargin'],
  setup (props, { emit }) {
    const data = reactive<superadminUpdateShipperPricingInfo>({
      value: props.pricingInfoMargin ?? 0
    })

    const changeValue = () => {
      if (data.value === null) {
        return
      } else if (data.value < 0) {
        data.value = 0
      } else if (data.value > 100) {
        data.value = 100
      }

      emit('updatePricingInfoMargin', data.value)
    }

    watch(() => props.pricingInfoMargin, (newVal) => {
      if (newVal !== null) data.value = newVal
    })

    return {
      ...toRefs(data),
      changeValue
    }
  }
})
