
import PlanButton from '@/components/buttons/PlanButton.vue';
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';
import PlanTabs from '@/components/tabs/PlanTabs.vue';
import PlanDefaultView from '@/components/defaults/PlanDefaultView.vue';
import PlanHeaderSearch from '@/components/filters/PlanHeaderSearch.vue';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import { defineComponent, reactive, toRefs, watch } from 'vue';
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import SendBlastEmailModal from '@/modules/planning/contacts/BlastEmail/SendBlastEmailModal.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import PlanDefaultLoadingModal from '@/components/defaults/PlanDefaultLoadingModal.vue';
import PlanDefaultStatusModal from '@/components/defaults/PlanDefaultStatusModal.vue';
import UpdateContactModal from '@/modules/planning/contacts/_modals/UpdateContactModal.vue';

import { debounce } from 'lodash';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import SuccessBlastEmailModal from '@/modules/planning/contacts/BlastEmail/SuccessBlastEmailModal.vue';
import AddTemplateModal from '@/modules/planning/contacts/BlastEmail/AddTemplateModal.vue';
import PlanImportContactsLoading from '@/modules/planning/contacts/ContactsImport/PlanImportContactsLoading.vue';
import PlanContactTags from '@/components/labels/PlanContactTags.vue';
import PlanFilter from '@/components/filters/PlanFilter.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanFilterContactTag from '@/components/filters/PlanFilterContactTag.vue';
interface searchCompanyQuery {
  type: string;
  name: string;
  page: number;
  sortBy: string;
  tags: Array<string>;
}

interface contactList {
  currentTab: number;
  loadingContacts: boolean;
  page: number;
  showAddWarehouse: boolean;
  showBlastEmail: boolean;
  showEditModal: boolean;
  selectedUser: object;
  searchCompanyQuery: searchCompanyQuery;
  selectedItems: Array<any>;
  showDropdown: boolean;
  showModalCarrier: boolean;
  iconColorDelete: string;
  iconColorSend: string;
  isDeletingContacs: boolean;
  modalSuccess: boolean;
  order: {
    value: string;
    direction: string;
  };
  showAddTemplate: boolean;
  selectedTag: string;
}

export default defineComponent({
  name: 'contactList',
  components: {
    PlanFilterContactTag,
    PlanContactTags,
    PlanImportContactsLoading,
    AddTemplateModal,
    SuccessBlastEmailModal,
    AddCarrierModal,
    PlanDefaultView,
    PlanTabs,
    PlanDefaultTable,
    PlanButtonIcon,
    PlanHeaderSearch,
    PlanButton,
    PlanLoading,
    SendBlastEmailModal,
    PlanDefaultLoadingModal,
    PlanDefaultStatusModal,
    UpdateContactModal,
  },
  setup() {
    /** Global */

    const store = useStore();
    const translate = useI18n();
    const router = useRouter();

    const data = reactive<contactList>({
      showModalCarrier: false,
      showDropdown: false,
      selectedUser: {},
      searchCompanyQuery: {
        type: 'CARRIER|BROKER',
        name: '',
        page: 1,
        sortBy: '',
        tags: [],
      },
      currentTab: 0,

      loadingContacts: false,
      page: 1,
      showAddWarehouse: false,
      showEditModal: false,
      showBlastEmail: false,
      selectedItems: [],
      iconColorDelete: 'red',
      iconColorSend: 'blue',
      isDeletingContacs: false,
      modalSuccess: false,
      order: {
        value: 'companyName',
        direction: 'asc',
      },
      showAddTemplate: false,
      selectedTag: '',
    });

    const closeAndUpdateContactModal = () => {
      data.showEditModal = false;
      getContactList();
    };

    const selectedTabTotal = computed(() => {
      const tabTotalsMap = { 0: 'ALL', 1: 'CARRIER', 2: 'BROKER' };
      const selectedTotalKey = tabTotalsMap[data.currentTab];
      return contacts.value?.totals_by_type?.[selectedTotalKey];
    });

    const headers = computed(() => {
      return [
        { label: translate.t('company'), value: 'companyName', sortable: true },
        { label: translate.t('name'), value: 'name', sortable: false },
        { label: translate.t('rating'), value: 'rating', sortable: true },
        { label: translate.t('rol'), value: 'rol', sortable: false },
        { label: translate.t('loads'), value: 'loadsQuantity', sortable: true },
        { label: translate.t('tags'), value: 'tags', sortable: false },
        { label: '', value: 'actions' },
      ];
    });

    /** Methods */

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const deleteSelected = () => {
      let promises = [] as any;
      if (selectedContacts.value.some((contact) => contact.source === 'CARGOBOT')) {
        store.dispatch('UserStore/updateToast', {
          text: translate.t('cant-delete-planimatik'),
          type: 'error',
          visible: true,
        });
        return;
      }

      data.isDeletingContacs = true;
      for (const item of selectedContacts.value) {
        promises.push(store.dispatch('CompanyStore/deleteOnNetwork', item.id));
      }
      Promise.all(promises)
        .then(() => {
          refresh();
          getContactList();
          store.dispatch('UserStore/updateToast', {
            text: translate.t('contact-deleted'),
            type: 'success',
            visible: true,
          });
        })
        .catch((err) => {
          store.dispatch('UserStore/updateToast', {
            text: err.response?.data.title,
            type: 'error',
            visible: true,
          });
        })
        .finally(() => {
          data.isDeletingContacs = false;
        });
    };

    const updateSearchType = () => {
      const tabTypes = ['CARRIER|BROKER', 'CARRIER', 'BROKER'];
      data.searchCompanyQuery.type = tabTypes[data.currentTab] || tabTypes[0];
      data.searchCompanyQuery.page = 1;
    };

    const loadMoreContacts = () => {
      data.searchCompanyQuery.page = data.searchCompanyQuery.page + 1;
      getContactList();
    };

    const fetchOrder = (orderObject) => {
      data.order = orderObject;
      data.searchCompanyQuery.sortBy = `${
        orderObject.value
      }[${orderObject.direction.toUpperCase()}]`;
      getContactList();
    };

    const getContactList = () => {
      data.loadingContacts = true;
      store
        .dispatch('UserStore/carrierContactList', data.searchCompanyQuery)
        .then(() => {
          //
        })

        .finally(() => {
          data.loadingContacts = false;
        });
    };

    const debouncedGetContactList = debounce(getContactList, 500);

    const changeSelectedContacts = (items) => {
      store.commit('CompanyStore/setContactsBlastEmail', items);
    };

    const changeTab = (currentTab: number) => {
      store.commit('CompanyStore/setContactsBlastEmail', []);
      data.currentTab = currentTab;
      updateSearchType();
      getContactList();
    };

    const deleteContact = (_id: number) => {
      store
        .dispatch('CompanyStore/deleteOnNetwork', _id)
        .then(() => {
          store.dispatch('UserStore/updateToast', {
            text: translate.t('contact-deleted'),
            type: 'success',
            visible: true,
          });
          refresh();
          getContactList();
          store.dispatch('UserStore/carrierNetworkTotal');
        })
        .catch((err) => {
          store.dispatch('UserStore/updateToast', {
            text: err.response?.data.title,
            type: 'error',
            visible: true,
          });
        })
        .finally(() => {
          // this.showDelete = false;
          // this.openMenu = -1;
        });
    };

    const refresh = () => {
      store.commit('CompanyStore/setContactsBlastEmail', []);
    };

    const handleEditItem = (item) => {
      if (item.source !== 'CARGOBOT' && item.source !== 'CARGOBOT_EUROPE' && item.source === null) {
        refresh();
        router.push(`/planning/contacts/${item?.owner_id}`);
      }
    };

    const showImportContactsLoading = computed(() => {
      return store.getters['UserStore/getImportingContacts'];
    });

    const handleInputChange = (event) => {
      const inputText = event.value ? event.value.trim() : event.trim();
      store.commit('CompanyStore/searchCompany', inputText);
      data.searchCompanyQuery.name = inputText;
      data.searchCompanyQuery.page = 1;
      debouncedGetContactList();
    };

    const handleShowEditModal = async (user) => {
      await store.dispatch('UserStore/getUser', { id: user.owner_id }).then((response) => {
        data.selectedUser = response;
        data.showEditModal = true;
      });
    };

    /** Computed */
    const contacts = computed(() => {
      return store.getters['UserStore/carrierContactList'];
    });

    const selectedContacts = computed(() => {
      return store.getters['CompanyStore/getContactsBlastEmail'];
    });

    const showAddTemplateOnClick = () => {
      data.showAddTemplate = true;
      data.modalSuccess = false;
    };

    const updateSelectedTag = (tag: []) => {
      data.searchCompanyQuery.tags = tag;
      getContactList();
    };

    watch(
      () => store.getters['CompanyStore/getSearchCompany'],
      (newValue, oldValue) => {
        data.searchCompanyQuery.name = store.getters['CompanyStore/getSearchCompany'];
      },
    );

    onMounted(async () => {
      await getContactList();
    });

    return {
      ...toRefs(data),
      closeAndUpdateContactModal,
      changeTab,
      changeSelectedContacts,
      contacts,
      deleteSelected,
      deleteContact,
      fetchOrder,
      handleInputChange,
      headers,
      isSuplanting,
      getContactList,
      loadMoreContacts,
      selectedTabTotal,
      selectedContacts,
      showAddTemplateOnClick,
      showImportContactsLoading,
      refresh,
      handleEditItem,
      handleShowEditModal,
      updateSelectedTag,
    };
  },
});
