import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-start items-start" }
const _hoisted_2 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_3 = { class: "plan-t-h2 font-bold" }
const _hoisted_4 = { class: "flex justify-center items-center" }
const _hoisted_5 = { class: "plan-mt-md plan-t-h4 plan-t-prim-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_plan_instant_quote_form = _resolveComponent("plan-instant-quote-form")!
  const _component_plan_default_view = _resolveComponent("plan-default-view")!

  return (_openBlock(), _createBlock(_component_plan_default_view, null, {
    "header-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_plan_icon, {
            name: "chart-mixed-up-circle-dollar",
            "icon-color": "black",
            "is-svg": ""
          }),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('get-instant-quote')), 1)
        ])
      ])
    ]),
    "header-sticky": _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_plan_loading, {
            key: 0,
            class: "plan-pb-sm plan-pt-sm"
          }))
        : (_openBlock(), _createBlock(_component_plan_instant_quote_form, { key: 1 }))
    ]),
    "view-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('fill-fields-best-quote')), 1)
      ])
    ]),
    _: 1
  }))
}