
import { computed, defineComponent, reactive, ref, toRefs, watch } from 'vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';

interface planCarrierBrokerAddDriverModal {
  showModal: boolean,
  loadingPost: boolean,
  fistName: string,
  lastName: string,
  driverLicense: string,
  email: string,
  prefix: string,
  phoneNumber: string,
  emitId: null | number,
  showContactInformation: boolean
}
export default defineComponent({
  mixins: [CountryMixins],
  name: 'PlanCarrierBrokerAddDriverModal',
  components: { PlanInputCheckbox, PlanSelect, PlanInputFile, PlanInputText, PlanButton },
  emit: ['selectDriver'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const driverFrontFile = ref<File[]>([]);
    const driverBackFile = ref<File[]>([]);

    const data = reactive({
      showModal: false,
      loadingPost: false,
      fistName: '',
      lastName: '',
      driverLicense: '',
      email: '',
      prefix: '',
      phoneNumber: '',
      emitId: null,
      showContactInformation: true
    })

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const assignDriver = async (driverId: any) => {
      await store.dispatch('ShipmentStore/assignDriver', {
        id: store.getters['CarrierStore/getLoadData'].shipment.id,
        driver: { id: driverId }
      }).then(() => {
        store
          .dispatch('UserStore/updateToast', {
            text: translate.t('success-carrier-driver-selected'),
            type: 'success',
            visible: true
          })
      })
    }

    const changeDriverLicenseFront = (files: File[]) => {
      driverFrontFile.value = files
    }

    const changeDriverLicenseBack = (files: File[]) => {
      driverBackFile.value = files
    }

    const changePrefix = (newPrefix: any) => {
      data.prefix = newPrefix
    }

    const changeShowContactInformation = (newValue: boolean) => {
      data.showContactInformation = newValue
    }

    const close = () => {
      data.loadingPost = false
      data.showModal = false
      data.fistName = ''
      data.lastName = ''
      data.driverLicense = ''
      data.email = ''
      data.prefix = ''
      data.phoneNumber = ''
    }

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentCountry = computed(() => {
      return store.getters['CountryStore/getCurrentCountry'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const getDrivers = async () => {
      await store.dispatch('CompanyStore/driversList', currentUser.value.company.id)
        .then((response) => {
          store.commit('CarrierStore/setDrivers', response)
        }).finally(() => {
          emit('selectDriver', data.emitId)
          data.loadingPost = false
        })
    }

    const orderPrefixAsc = (prefixes: any) => {
      return prefixes.sort((a, b) => {
        let prefixA = parseInt(a.value, 10);
        let prefixB = parseInt(b.value, 10);

        if (prefixA < prefixB) {
          return -1;
        }
        if (prefixA > prefixB) {
          return 1;
        }
        return 0; // Si son iguales
      });
    };

    const getLoad = async () => {
      await store.dispatch('CarrierStore/getLoadInfoForCarrier', store.getters['CarrierStore/getLoadData'].id)
    }

    const postDriver = async () => {
      if (!validateDriver.value) return

      data.loadingPost = true
      await store.dispatch('CompanyStore/postDriver', {
        name: data.fistName,
        surname: data.lastName,
        drivingLicense: data.driverLicense,
        company: { id: currentUser.value.company.id },
        email: data.email,
        phonePrefix: data.prefix,
        phone: data.phoneNumber,
        showContactInformation: data.showContactInformation
      }).then((response) => {
        data.emitId = response.id

      })

      if (driverFrontFile.value.length > 0 && data.emitId) await postDriverMediaObject(data.emitId, 'DRIVER_LICENSE_FRONT')
      if (driverBackFile.value.length > 0 && data.emitId) await postDriverMediaObject(data.emitId, 'DRIVER_LICENSE_BACK')
      await assignDriver(data.emitId)
      await getLoad()
      await getDrivers()
    }

    const postDriverMediaObject = async (driverId: number, type: string) => {
      await store
        .dispatch('CompanyStore/postMediaObjectDriver', {
          entityId: driverId,
          type: type,
          file: type === 'DRIVER_LICENSE_FRONT' ? driverFrontFile.value[0] : driverBackFile.value[0],
        })
    }

    const prefixes = computed(() => {
      const prefixes = store.getters['CarrierStore/getRegionPrefixes'];
      return orderPrefixAsc(prefixes);
    })

    const validateDriver = computed(() => {
      return data.fistName.length > 0 && data.lastName.length > 0 && data.driverLicense.length > 0
    })

    watch(currentCountry, (newCountry) => {
      if (newCountry?.phonePrefix && !data.prefix) {
        data.prefix = newCountry.phonePrefix;
      }
    }, { immediate: true });

    watch(
      () => data.prefix,
      (newValue) => {
        if (currentCountry.value?.phonePrefix && !newValue) {
          data.prefix = currentCountry.value?.phonePrefix;
        }
      }
    );

    return {
      ...toRefs(data),
      actualWidth,
      changeDriverLicenseFront,
      changeDriverLicenseBack,
      changePrefix,
      changeShowContactInformation,
      close,
      postDriver,
      prefixes,
      validateDriver
    }
  }
})
