
import BaseComponent from '@/base/BaseComponent';
import RankingRFPModal from '@/modules/rfp/_components/modals/RankingRFPModal.vue';

//Components
import PlanIcon from '@/components/icons/PlanIcon.vue';
import SetMaxResponseTimeModal from '@/modules/rfp/_components/modals/SetMaxResponseTimeModal.vue';

import { defineComponent, onBeforeUnmount, onMounted, reactive, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';

export default defineComponent({
  name: 'HeaderRFPLoadInfo',
  components: { PlanIcon, SetMaxResponseTimeModal, RankingRFPModal },
  mixins: [BaseComponent, CountryMixins],
  props: {
    currentView: { type: String, required: false },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const translate = useI18n();

    const data = reactive({
      showRankingModal: false,
      showResponseMaxTimeModal: false,
    });

    /** Methods  */
    const translateTrailer = (trailerTypeName) => {
      const clearName = trailerTypeName.replace(/[^\wáéíóú]/g, '');
      return translate.t(`trailer-types-eu.${clearName}`)
    };

    const toggleOpenRanking = () => {
      data.showRankingModal = !data.showRankingModal;
    };

    const toggleOpenResponseTimeModal = () => {
      data.showResponseMaxTimeModal = !data.showResponseMaxTimeModal;
    };

    /** Computed */

    const currentLane = computed(() => {
      return store.getters['RFPStore/getCurrentLane'];
    });

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];
    });

    const pickupInitialStop = computed(() => {
      return currentLane.value?.loadTemplate?.stops?.find((stop) => stop.type === 'PICKUP_INITIAL');
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const userRegion = computed(() => {
      return currentUser.value.company?.country?.region?.name;
    });

    const deliveryFinalStop = computed(() => {
      return currentLane.value?.loadTemplate?.stops?.find((stop) => stop.type === 'DELIVERY_FINAL');
    });
    const nPickUps = computed(() => {
      return currentLane?.value.loadTemplate?.stops?.filter(
        (item) => item.type === 'PICKUP_INITIAL' || item.type === 'PICKUP',
      ).length;
    });

    const nDeliveries = computed(() => {
      return currentLane?.value.loadTemplate?.stops?.filter(
        (item) => item.type === 'DELIVERY_FINAL' || item.type === 'DELIVERY',
      ).length;
    });

    const specialRequirements = computed(() => {
      if (
        currentLane?.value.loadTemplate?.specialRequirement &&
        Object.keys(currentLane?.value.loadTemplate?.specialRequirement).length !== 0
      ) {
        const requirements = Object.keys(
          currentLane?.value.loadTemplate?.specialRequirement,
        ).filter((key) => currentLane?.value.loadTemplate?.specialRequirement[key] === true);
        requirements.forEach((req, index) => {
          if (req === 'specialPermissions') {
            requirements[index] =
              translate.t(req) +
              ': ' +
              currentLane?.value.loadTemplate?.specialRequirement.specialPermissionsDescription;
          } else {
            requirements[index] = translate.t(req);
          }
        });
        return requirements.join(', ');
      }
      return null;
    });

    return {
      ...toRefs(data),
      currentProject,
      currentLane,
      deliveryFinalStop,
      nPickUps,
      nDeliveries,
      pickupInitialStop,
      specialRequirements,
      translateTrailer,
      toggleOpenRanking,
      toggleOpenResponseTimeModal,
      userRegion,
    };
  },
});
