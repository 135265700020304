import { initialState } from '@/modules/rfp/_store/index';

export default {
  clearRFPStore(state: any) {
    Object.assign(state, initialState);
  },
  setAsyncResultLanes(state: any, payload: any) {
    state.asyncResultLanes = payload;
  },
  setClaimProjectInfo(state: any, payload: object) {
    state.claimProjectInfo = payload;
  },
  setCurrentQuotesPlanned(state: any, payload: object) {
    state.currentQuotesPlanned = payload;
  },
  setCurrentCarrierRFPQuotes(state: any, payload: object) {
    state.currentCarrierRFPQuotes = payload;
  },

  setCalendarIntervals(state: any, payload: object) {
    state.calendarIntervals = payload;
  },

  setLoadsInfo(state: any, payload: object) {
    state.loadsInfo = payload;
  },

  setCurrentLoadsToAdd(state: any, payload: object) {
    state.currentLoadsToAdd = payload;
  },

  setCurrentLoadsToAddAssigment(state: any, payload: object) {
    state.currentLoadsToAddAssigment = payload;
  },
  setCarrierQuotetionList(state: any, payload: any) {
    if (payload.page === 1) {
      state.carrierQuotetionList = payload.response;
    } else {
      state.carrierQuotetionList.push(...payload.response);
    }
  },
  setCurrentEditingLanes(state: any, payload: object) {
    state.currentEditingLanes = payload;
  },
  setIndexLoadToRemove(state: any, payload: any) {
    state.indexLoadToRemove = payload;
  },
  setLanesClaimsInfo(state: any, payload: object) {
    state.lanesClaimsInfo = payload;
  },
  setCurrentLane(state: any, payload: object) {
    state.currentLane = payload;
  },
  setCurrentProject(state: any, payload: object) {
    state.currentProject = payload;
  },
  setLanesAndQuotesForCarrier(state: any, payload: object) {
    state.lanesAndQuotesForCarrier = payload;
  },
  setCurrentProjectCarrier(state: any, payload: object) {
    state.currentProject = { ...state.currentProject, ...payload };
  },
  setLanes(state: any, payload: any) {
    if (payload.page === 1) {
      state.lanes = payload.response;
    } else {
      state.lanes.push(...payload.response);
    }
  },
  setLanesInformationPerWeek(state: any, payload: object) {
    state.lanesArrayPerWeek = payload;
  },
  setNewProjectModalOptions(state: any, payload: any) {
    state.newProjectModalOptions = payload;
  },
  setPreviousStatusLanes(state: any, payload: any) {
    state.previousStatusLanes = payload;
  },
  setPreviousStatusProject(state: any, payload: any) {
    state.previousStatusProject = payload;
  },
  setProjectsRFPList(state: any, payload: any) {
    state.projectsRFPList = payload;
  },
  setProjectsTotalsStatus(state: any, payload: string) {
    state.projectsTotalsStatus = payload;
  },
  setLanesListFilter(state: any, payload: any) {
    state.lanesListFilter = payload;
  },
  setProjectToDuplicate(state: any, payload: string) {
    state.projectToduplicate = payload;
  },
  setProjectRFPList(state: any, payload: any) {
    state.projectsRFPList.map((item) => {
      if (item.project.id === payload.id) {
        item.project.name = payload.data.name;
        item.project.periodEndDate = payload.data.periodEndDate;
        item.project.periodStartDate = payload.data.periodStartDate;
      }
    });
  },
  setQuoteForCarrier(state: any, payload: any) {
    state.quoteForCarrier = payload;
  },
  setRankingList(state: any, payload: string) {
    state.rankingList = payload;
  },
  setShowWaitingLanes(state: any, payload: boolean) {
    state.showWaitingLanes = payload;
  },
  setUnCompletedTootipInfo(state: any, payload: string) {
    state.unCompletedTootipInfo = payload;
  },

  // updateProjectFromList(state: any, projectId: any) {
  //   state.projectsRFPList.map((item) => {
  //     if (item.project.id === projectId) {
  // item['isClonedFinished'] = true;
  //       item.project.isCreatingLanes = false;
  //     }
  //   });
  // },
};
