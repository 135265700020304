import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-center" }
const _hoisted_2 = { class: "font-bold plan-t-h2 plan-t-prim-500" }
const _hoisted_3 = { class: "font-bold plan-t-h2 plan-t-prim-200" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_carrier_sign = _resolveComponent("plan-carrier-sign")!
  const _component_plan_default_carrier_view = _resolveComponent("plan-default-carrier-view")!

  return (_ctx.loadData !== null)
    ? (_openBlock(), _createBlock(_component_plan_default_carrier_view, {
        key: 0,
        "show-header": false,
        "show-subheader": false,
        "show-footer": true,
        "default-content-width": _ctx.documentWidth
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex", _ctx.actualWidth <= 640 ? 'w-full justify-start items-start':'absolute top-[16px] left-[64px] plan-b-gray-50 rounded'])
            }, [
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.goToLoadAccepted,
                type: "cancel",
                label: _ctx.$t('exit'),
                "show-icon": "",
                icon: "chevron-left",
                "icon-color": "#8A8B97"
              }, null, 8, ["onOnClick", "label"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["w-full flex flex-col justify-start items-center plan-gap-lg", {'plan-mt-sm': _ctx.actualWidth > 640}])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex plan-gap-sm justify-center items-center", {'flex-col': _ctx.actualWidth <= 640 }])
              }, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.actualRegion === 'NORTH_AMERICA' ? 'complete-rc-usa':'complete-rc-eu')), 1),
                _createElementVNode("span", _hoisted_3, "#" + _toDisplayString(_ctx.loadData.identifier), 1)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["flex plan-gap-sm justify-center items-center", {'flex-col': _ctx.actualWidth <= 640 }])
              }, null, 2),
              (_ctx.loadingData)
                ? (_openBlock(), _createBlock(_component_plan_icon, {
                    key: 0,
                    name: "spinner",
                    size: "40",
                    "icon-color": "#151830",
                    "is-svg": "",
                    rotate: ""
                  }))
                : (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: _ctx.rateConfirmationUrl,
                    target: "_blank",
                    class: "w-full"
                  }, [
                    _createElementVNode("img", {
                      src: _ctx.rateConfirmationUrl,
                      alt: "",
                      class: "w-full rounded cursor-pointer",
                      style: {"margin-bottom":"250px"}
                    }, null, 8, _hoisted_5)
                  ], 8, _hoisted_4))
            ], 2)
          ])
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_plan_carrier_sign, { onCancel: _ctx.goToLoadAccepted }, null, 8, ["onCancel"])
        ]),
        _: 1
      }, 8, ["default-content-width"]))
    : _createCommentVNode("", true)
}