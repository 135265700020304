<template>
  <div>
    <DirectAssignModal
      v-if="showDirectAssignModal"
      :type="type"
      @accept="accept"
      @close="showDirectAssignModal = !showDirectAssignModal"
    />
    <Form
      ref="form"
      @submit="null"
      @keypress.enter.prevent
    >
      <!-- Commodities list -->
      <div class="w-full flex inline-flex justify-center items-center gap-[38px] p-6">
        <div
          class="flex text-slate-900 text-md text-center font-semibold font-['Nunito']"
          v-html="$t('freight-info-description', { commodities: getCommodityListAsString() })"
        />
        <div
          v-show="false"
          v-for="(item, i) in dataCommodities"
          class="flex text-slate-900 text-md font-semibold font-['Nunito']"
          :key="i"
        >
          {{
            `${item.commodityName} ${item.weight}${currentCountryWeightUnit}
          (${item.nPallets} ${getPackageType(item.commodityName)})`
          }}
        </div>
      </div>

      <div
        v-for="(stop, i) in dataStopsLocal"
        class="flex min-h-[162px] w-full justify-between items-start inline-flex rounded border border-neutral-400 p-6"
        :class="{
          'mb-0': i === dataStopsLocal?.length - 1,
          'mb-6': i < dataStopsLocal?.length - 1,
        }"
        :key="i"
      >
        <!-- Left side -->
        <div class="w-1/3 flex-col justify-start items-start inline-flex gap-[7px] pr-4">
          <div class="inline-flex justify-start items-start gap-[17px]">
            <div class="text-slate-900 text-lg font-semibold font-['Nunito']">
              {{ `${getStopType(stop.type)} - #${i + 1} ${stop.location?.city}` }}
            </div>
          </div>
          <div class="flex-col justify-start items-start gap-[7px] flex">
            <div class="text-slate-900 text-[15px] font-normal font-['Nunito']">
              {{ stop.companyName }}
            </div>
            <div class="text-slate-900 text-[15px] font-normal font-['Nunito']">
              {{ formatDate(stop.executionDate) }}
            </div>
          </div>
        </div>

        <!-- Right side -->
        <div class="w-2/3 flex flex-col inline-flex justify-start items-start gap-[18px]">
          <div
            v-for="(itemCommodity, j) in stop.commodityMaster"
            class="w-full flex flex-col"
            :key="j"
          >
            <div class="flex w-full justify-start items-start gap-4 inline-flex">
              <!-- Commodity -->
              <div
                class="min-h-[74px] flex flex-col grow shrink basis-0 justify-start items-start gap-2"
              >
                <label
                  class="h-[18px] text-center text-slate-900 text-[15px] font-normal font-['Nunito']"
                  :ref="'commodityName' + i + j"
                  >{{ `${$t('commodity')}*` }}</label
                >
                <div
                  v-if="dataCommodities?.length === 1"
                  class="h-full w-full min-h-[48px] inline-flex bg-gray-50 rounded border border-gray-300 justify-start items-center gap-2 px-3 py-2"
                  disabled="true"
                >
                  <div class="text-gray-900 text-sm">{{ dataCommodities[0].commodityName }}</div>
                </div>
                <Field
                  v-else
                  as="select"
                  class="h-full w-full min-h-[48px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500"
                  rules="required"
                  v-model="itemCommodity.commodityName"
                  :id="'commodityName' + i + j"
                  :name="'commodityName' + i + j"
                  @change="isValidCommodityWeight(itemCommodity, stop.type, i, j)"
                  :disabled="
                    (stop.type === StopStatus.DELIVERY_FINAL && getDeliveries().length === 1) ||
                    (stop.type === StopStatus.PICKUP_INITIAL && getPickUps().length === 1)
                  "
                >
                  <option
                    value
                    disabled
                  >
                    {{ $t('select') }}
                  </option>
                  <option
                    v-for="(option, idx) in dataCommodities"
                    :id="'commodityNameOption' + i + idx"
                    :value="option.commodityName"
                    :key="idx"
                  >
                    {{ option.commodityName }}
                  </option>
                </Field>
                <ErrorMessage
                  class="text-sm text-rose-500"
                  :name="'commodityName' + i + j"
                />
              </div>

              <!-- Weight, Pallets & PackageType -->
              <div class="min-h-[74px] justify-start items-start gap-4 flex">
                <!-- Weight -->
                <div
                  class="h-full w-[130px] flex flex-col grow shrink basis-0 justify-start items-start gap-2"
                >
                  <label
                    class="h-[18px] text-center text-slate-900 text-[15px] font-normal font-['Nunito']"
                    :ref="'weight' + i + j"
                    >{{ `${$t('weight')}*` }}</label
                  >
                  <div class="h-full w-[130px] min-h-[48px] flex text-gray-900 text-sm">
                    <Field
                      rules="required|integer|min_value:1|max_value:2147483647"
                      v-model="itemCommodity.weight"
                      :id="'weight' + i + j"
                      :name="'weight' + i + j"
                    >
                      <InputAmericanFormat
                        :input-id="'weight' + i + j"
                        class="flex"
                        v-model="itemCommodity.weight"
                        :class-custom="getClassCustomWeight()"
                        :symbol="currentCountryWeightUnit"
                        :hidden="true"
                        :placeholder="'0'"
                        :disabled="
                          (stop.type === StopStatus.DELIVERY_FINAL &&
                            getDeliveries().length === 1) ||
                          (stop.type === StopStatus.PICKUP_INITIAL && getPickUps().length === 1)
                        "
                        @change="
                          itemCommodity.commodityName
                            ? isValidCommodityWeight(itemCommodity, stop.type, i, j)
                            : null
                        "
                      />
                    </Field>
                  </div>
                  <ErrorMessage
                    class="text-sm text-rose-500"
                    :name="'weight' + i + j"
                  />
                </div>

                <!-- Pallets -->
                <div
                  class="h-full w-[50px] flex flex-col grow shrink basis-0 justify-start items-start gap-2"
                >
                  <label
                    class="h-[18px] w-[50px] text-center text-slate-900 text-[15px] font-normal font-['Nunito'] truncate"
                    :ref="'nPallets' + i + j"
                    >{{ `${$t('units')}*` }}</label
                  >
                  <Field
                    class="h-full w-[50px] min-h-[48px] flex bg-gray-50 border border-gray-300 text-gray-900 text-sm text-center rounded focus:ring-blue-500 focus:border-blue-500"
                    rules="required_short|numeric|min_value:1|max_value:2147483647"
                    min="1"
                    v-model="itemCommodity.nPallets"
                    :id="'nPallets' + i + j"
                    :name="'nPallets' + i + j"
                  >
                    <InputAmericanFormat
                      :input-id="'nPallets' + i + j"
                      class="flex"
                      v-model="itemCommodity.nPallets"
                      :hidden="true"
                      :classicInput="true"
                      :placeholder="'0'"
                      :disabled="
                        (stop.type === StopStatus.DELIVERY_FINAL && getDeliveries().length === 1) ||
                        (stop.type === StopStatus.PICKUP_INITIAL && getPickUps().length === 1)
                      "
                      @change="
                        itemCommodity.commodityName
                          ? isValidCommodityPallets(
                              itemCommodity,
                              stop.type,
                              getPackageType(itemCommodity.commodityName, i, j),
                            )
                          : null
                      "
                    />
                  </Field>
                  <ErrorMessage
                    class="text-sm text-rose-500"
                    :name="'nPallets' + i + j"
                  />
                </div>

                <!-- Package type -->
                <div
                  class="h-full min-w-[50px] flex flex-col justify-start items-start gap-2 grow shrink"
                >
                  <label class="h-[18px]">{{ '&nbsp;' }}</label>
                  <div class="h-full w-full min-h-[48px] flex items-center">
                    {{ getPackageType(itemCommodity.commodityName, i, j) }}
                  </div>
                </div>

                <!-- Delete row -->
                <div class="h-full w-10 flex flex-col gap-2">
                  <label class="h-[18px]" />
                  <div
                    v-if="
                      (j >= 1 &&
                        stop.type !== StopStatus.DELIVERY_FINAL &&
                        stop.type !== StopStatus.PICKUP_INITIAL) ||
                      (j >= 1 &&
                        stop.type === StopStatus.DELIVERY_FINAL &&
                        getDeliveries().length > 1) ||
                      (j >= 1 && stop.type === StopStatus.PICKUP_INITIAL && getPickUps().length > 1)
                    "
                    class="min-h-[46px] flex justify-center items-center cursor-pointer hover:opacity-40"
                    @click="onClickDeleteCommodity(i, j)"
                  >
                    <img
                      class="h-5 w-5 m-auto"
                      :src="getAsset.icTrashRed"
                    />
                  </div>
                  <div
                    v-else
                    class="min-h-[46px] flex justify-center items-center"
                  />
                </div>
              </div>
            </div>

            <!-- Stops -->

            <div
              v-if="
                (stop.type === 'PICKUP' || stop.type === 'PICKUP_INITIAL') &&
                pickups.length !== 1 &&
                deliveries.length !== 1
              "
              class="flex flex-col mt-6 w-10/12"
            >
              <label
                for
                class="mb-1 text-bold"
                :ref="'deliveryStop' + i"
                >{{ $t('delivery-in') }}</label
              >

              <Field
                :id="'deliveryStop' + i"
                as="select"
                :name="'deliveryStop' + i"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 h-10"
                rules="required"
                v-model="stop.deliveryStop"
                @input="setStops(i, stop, null)"
              >
                <option
                  value
                  disabled
                >
                  {{ $t('select') }}
                </option>
                <option
                  v-for="(del, index) in deliveriesMulti"
                  :id="'deliveryStopOption' + index"
                  :key="del.originalIndex"
                  :value="del.originalIndex"
                >
                  {{ del.location.city + ' - ' + formatDate(del.executionDate) }}
                </option>
              </Field>
              <ErrorMessage
                v-if="stop?.multiStopIdentifierArray?.length === 0"
                :name="'deliveryStop' + i"
                class="text-rose-500"
              />
            </div>
          </div>

          <div
            v-if="pickups.length !== 1 && deliveries.length !== 1"
            class="flex flex-row space-x-2"
          >
            <div
              v-for="(multi, z) in stop.multiStopIdentifierArray"
              :key="z"
            >
              <button
                class="bg-btnaction flex px-2 md:px-4 py-2 rounded-md text-white border border-btnaction text-center font-bold hover:opacity-70"
              >
                {{ dataStopsLocal[multi].location.city }}
                <XCircleIcon
                  @click="deleteElement(z, stop)"
                  class="h-6 w-6 ml-2"
                />
              </button>
            </div>
          </div>
          <!--        // <XCircleIcon class="h-6 w-6 ml-2" />-->

          <!-- Button add more freight -->
          <div
            v-if="
              dataCommodities?.length > 1 &&
              stop?.commodityMaster?.length <= dataCommodities?.length - 1
            "
            class="inline-flex self-stretch justify-start items-end gap-2.5"
          >
            <button
              id="FreightInfoAddMoreFreight"
              class="text-sky-500 text-[15px] font-semibold font-['Nunito'] hover:opacity-40"
              @click="onClickAddMoreFreight(i)"
            >
              {{ $t('add-more-freight') }}
            </button>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useStore } from 'vuex';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DirectAssignModal from '@/components/modals/DirectAssignModal.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
// Resources
import i18n from '@/resources/locales';
// Types
import { StopStatus } from '@/modules/rfp/RFPTypes';

export default defineComponent({
  name: 'FreightInfoStep',
  components: {
    DirectAssignModal,
    ErrorMessage,
    Field,
    Form,
    InputAmericanFormat,
  },
  mixins: [BaseComponent],
  emits: ['changeCurrentView, onUpdateLoad'],
  props: {
    dataCommodities: { type: Array, default: () => [] },
    dataStops: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    /** Global */
    const store = useStore();
    const $t = i18n.global.t;
    const currentFormType = ref('');
    const dataCommodityMaster = ref(props.dataCommodities);
    const errorPallet = ref(false);
    const errorWeight = ref(false);
    const dataStopsLocal = ref(props.dataStops);
    const isValidForm = ref(false);
    const showDirectAssignModal = ref(false);

    /** Lifecycle */
    onMounted(() => {
      window.scrollTo({ top: 0, behavior: 'instant' });
      initializeDataStops();

      if (pickups.value.length === 1 || deliveries.value.length === 1) {
        let index = 0;

        pickups.value.forEach((stop) => {
          stop.multiStopIdentifierArray = [`${index}`];
        });

        deliveries.value.forEach((stop) => {
          stop.multiStopIdentifier = `${index}`;
        });
      }
    });

    onBeforeUnmount(() => {
      updateStops();
    });

    const pickups = computed(() => {
      return dataStopsLocal?.value.filter(
        (stop) => stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP',
      );
    });

    const deliveries = computed(() => {
      return dataStopsLocal?.value.filter(
        (stop) => stop.type === 'DELIVERY_FINAL' || stop.type === 'DELIVERY',
      );
    });

    /** Computed */
    const dataCommodityUnitTypes = computed(() => {
      return store.getters['LoadsStore/packageTypes'] &&
        store.getters['LoadsStore/packageTypes'].FTL
        ? store.getters['LoadsStore/packageTypes'].FTL.map((type) => {
            return { id: type.id, fullName: $t(`types.${type.fullName}`), sequence: type.sequence };
          })
        : '';
    });

    const deliveriesMulti = computed(() => {
      const filtered = dataStopsLocal?.value.map((stop, index) => {
        const originalIndex = index;
        return { ...stop, originalIndex };
      });

      return filtered?.filter((stop) => stop.type === 'DELIVERY' || stop.type === 'DELIVERY_FINAL');
    });

    /** Methods */

    const setStops = (i, stop, variable) => {
      let index = `${stop.deliveryStop}`;

      if (!stop.multiStopIdentifierArray.includes(index)) {
        stop.multiStopIdentifierArray.push(`${index}`);
        dataStopsLocal.value[stop.deliveryStop].multiStopIdentifier = `${index}`;
      }
    };

    const getClassCustomWeight = () => {
      if (errorWeight.value) {
        return '!rounded';
        // return '!rounded border-red-500';
      }
      return '!rounded border-gray-300';
    };

    const deleteElement = (index, stop) => {
      stop.deliveryStop = '';
      stop.multiStopIdentifierArray.splice(index);
    };

    const getCommodityByName = (commodityName) => {
      return props.dataCommodities.find((item) => item.commodityName === commodityName);
    };

    const getCommodityListAsString = () => {
      const weightUnit = BaseComponent?.computed?.currentCountryWeightUnit();
      let commodity = '';
      let result = '';

      props.dataCommodities?.map((item, i) => {
        commodity = `${item.commodityName} ${item.weight}${weightUnit}
          ${item.nPallets} ${getPackageType(item.commodityName)}`;
        result = i === 0 ? commodity : `${result} - ${commodity}`;
      });
      return result;
    };

    const getDeliveries = () => {
      return dataStopsLocal?.value.filter(
        (stop) => stop.type === StopStatus.DELIVERY || stop.type === StopStatus.DELIVERY_FINAL,
      );
    };
    const getPackageType = (commodityName, indexStop, indexCommodityMaster) => {
      if (!commodityName) {
        return;
      }

      const commodity = dataCommodityMaster?.value?.find(
        (item) => item.commodityName === commodityName,
      );
      const packageType = dataCommodityUnitTypes?.value?.find(
        (item) => item.id === commodity?.packageType?.id,
      );

      // Update packageType id into commodity master when commodity name is selected
      if (indexStop && indexCommodityMaster && indexStop !== -1 && indexCommodityMaster !== -1) {
        dataStopsLocal.value[indexStop].commodityMaster[indexCommodityMaster].packageType.id =
          packageType?.id;
      }

      return packageType?.fullName;
    };

    const getPickUps = () => {
      return dataStopsLocal?.value.filter(
        (stop) => stop.type === StopStatus.PICKUP || stop.type === StopStatus.PICKUP_INITIAL,
      );
    };

    const getStopType = (type) => {
      switch (type) {
        case StopStatus.PICKUP_INITIAL:
          return $t('label.pickup-initial');
        case StopStatus.PICKUP:
          return $t('pick-up');
        case StopStatus.DELIVERY:
          return $t('delivery');
        case StopStatus.DELIVERY_FINAL:
          return $t('label.delivery-final');
        default:
          return '';
      }
    };

    const initializeDataStops = () => {
      dataStopsLocal?.value.forEach((stop, index) => {
        if (!stop.hasOwnProperty('originalIndex')) {
          Object.assign(dataStopsLocal?.value[index], { originalIndex: index });
        } else {
          dataStopsLocal.value[index].originalIndex = index;
        }
      });

      const commodityName =
        props.dataCommodities?.length === 1 ? props.dataCommodities[0]?.commodityName : '';
      const nPallets = props.dataCommodities?.length === 1 ? props.dataCommodities[0]?.nPallets : 0;
      const weight = props.dataCommodities?.length === 1 ? props.dataCommodities[0]?.weight : 0;
      const packageType =
        props.dataCommodities?.length === 1 ? props.dataCommodities[0]?.packageType?.id : -1;
      const initialPickup = dataStopsLocal?.value.find(
        (stop) => stop.type === StopStatus.PICKUP_INITIAL,
      );
      const pickups = dataStopsLocal?.value.filter((stop) => stop.type === StopStatus.PICKUP);
      const deliveries = dataStopsLocal?.value.filter((stop) => stop.type === StopStatus.DELIVERY);
      const finalDelivery = dataStopsLocal?.value.find(
        (stop) => stop.type === StopStatus.DELIVERY_FINAL,
      );

      dataStopsLocal.value = [initialPickup, ...pickups, ...deliveries, finalDelivery];
      dataStopsLocal.value = dataStopsLocal?.value.map((item) => {
        return item?.commodityMaster && item?.commodityMaster?.length > 0
          ? { ...item }
          : {
              ...item,
              commodityMaster: [
                {
                  id: null,
                  nPallets: null,
                  packageType: { id: -1 },
                  weight: null,
                  commodityName: commodityName,
                },
              ],
            };
      });

      // If only exist one pickup -> Show all the commodities directly
      const dataPickUps = [initialPickup, ...pickups];

      if (dataPickUps.length === 1) {
        // Only one pickup
        dataStopsLocal.value[0].commodityMaster = props.dataCommodities;
        // } else if (dataPickUps.length !== 1 && dataStopsLocal?.value[0]?.commodityMaster?.length >= 1) {
        //   dataStopsLocal.value[0].commodityMaster = [...dataStopsLocal.value[0].commodityMaster];
      } else {
        // Multiples pickups
        dataStopsLocal?.value.map((item) => {
          if (item.type === StopStatus.PICKUP_INITIAL || item.type === StopStatus.PICKUP) {
            if (item.commodityMaster.length > 0) {
              // Exists data
              item.commodityMaster.map((commodity) => {
                return {
                  id: commodity?.id,
                  nPallets: commodity?.nPallets,
                  packageType: commodity?.packageType,
                  weight: commodity?.weight,
                  commodityName: commodity?.commodityName,
                };
              });
            } else {
              // Initialize commodityMaster
              item.commodityMaster = [
                {
                  id: null,
                  nPallets: null,
                  packageType: { id: packageType },
                  weight: null,
                  commodityName: commodityName,
                },
              ];
            }
          }
        });
      }

      // If only exists one delivery -> Show all the commodities directly
      const dataDeliveries = [...deliveries, finalDelivery];
      const lastIndex = [dataStopsLocal.value.length - 1];

      if (dataDeliveries.length === 1) {
        // Only one delivery
        dataStopsLocal.value[lastIndex].commodityMaster = props.dataCommodities;
        // } else if (dataDeliveries.length !== 1 && dataStopsLocal?.value[lastIndex]?.commodityMaster?.length >= 1) {
        //   dataStopsLocal.value[lastIndex].commodityMaster = [...dataStopsLocal.value[lastIndex].commodityMaster];
      } else {
        // Multiples deliveries
        dataStopsLocal?.value.map((item) => {
          if (item.type === StopStatus.DELIVERY || item.type === StopStatus.DELIVERY_FINAL) {
            if (item.commodityMaster.length > 0) {
              // Exists data
              item.commodityMaster.map((commodity) => {
                return {
                  id: commodity?.id,
                  nPallets: commodity?.nPallets,
                  packageType: commodity?.packageType,
                  weight: commodity?.weight,
                  commodityName: commodity?.commodityName,
                };
              });
            } else {
              // Initialize commodityMaster
              item.commodityMaster = [
                {
                  id: null,
                  nPallets: null,
                  packageType: { id: packageType },
                  weight: null,
                  commodityName: commodityName,
                },
              ];
            }
          }
        });
      }
    };

    const isValidCommodityPallets = (itemRow, type, packageType) => {
      let sumPallets = 0;

      dataStopsLocal.value.forEach((stop) => {
        if (stop?.type?.includes(type)) {
          stop?.commodityMaster?.forEach((commodity) => {
            sumPallets =
              commodity.commodityName === itemRow.commodityName
                ? Number(sumPallets) + Number(commodity.nPallets)
                : Number(sumPallets);
          });
        }
      });

      const commodityTotal = getCommodityByName(itemRow.commodityName);
      const totalPallets = commodityTotal ? commodityTotal.nPallets : 0;

      if (sumPallets > totalPallets) {
        errorPallet.value = true;
        BaseComponent?.methods.showToastError(
          $t('message-error-pallets', {
            commodityName: itemRow.commodityName,
            packageType: packageType,
            totalPallets: totalPallets,
          }),
        );
        return;
      }

      errorPallet.value = false;
    };

    const isValidCommodityWeight = (itemRow, type, indexStop, indexCommodity) => {
      let sumWeight = 0;

      // Assign packageType
      const commodity = dataCommodityMaster?.value?.find(
        (item) => item.commodityName === itemRow.commodityName,
      );
      const packageType = dataCommodityUnitTypes?.value?.find(
        (item) => item.id === commodity?.packageType?.id,
      );
      dataStopsLocal.value[indexStop].commodityMaster[indexCommodity].packageType.id =
        packageType?.id;

      dataStopsLocal.value.forEach((stop) => {
        if (stop?.type?.includes(type)) {
          stop?.commodityMaster?.forEach((commodity) => {
            commodity.packageType.id = packageType?.id;
            sumWeight =
              commodity.commodityName === itemRow.commodityName
                ? sumWeight + commodity.weight
                : sumWeight;
          });
        }
      });

      const commodityTotal = getCommodityByName(itemRow.commodityName);
      const totalWeight = commodityTotal ? commodityTotal.weight : 0;

      if (sumWeight > totalWeight) {
        errorWeight.value = true;
        BaseComponent?.methods.showToastError(
          $t('message-error-weight', {
            commodityName: itemRow.commodityName,
            totalWeight: totalWeight,
            weightUnit: BaseComponent?.computed?.currentCountryWeightUnit(),
          }),
        );
        return;
      }

      errorWeight.value = false;
    };

    const onClickAddMoreFreight = (index) => {
      dataStopsLocal?.value[index]?.commodityMaster.push({
        id: null,
        nPallets: null,
        packageType: { id: -1 },
        weight: 0,
        commodityName: '',
      });
    };

    const onClickDeleteCommodity = (indexStop, indexCommodity) => {
      dataStopsLocal.value[indexStop].commodityMaster.splice(indexCommodity, 1);
    };

    const updateStops = () => {
      dataStopsLocal.value.forEach((stop) => {
        stop.commodityMaster.forEach((commodity) => {
          commodity.nPallets = Number(commodity.nPallets);
        });
      });
      emit('onUpdateStops', dataStopsLocal.value);
    };

    return {
      pickups,
      deliveries,
      setStops,
      StopStatus,
      currentFormType,
      dataCommodityMaster,
      dataStopsLocal,
      errorPallet,
      errorWeight,
      isValidForm,
      getClassCustomWeight,
      getCommodityListAsString,
      getDeliveries,
      getPackageType,
      getPickUps,
      getStopType,
      deleteElement,
      isValidCommodityPallets,
      isValidCommodityWeight,
      onClickAddMoreFreight,
      onClickDeleteCommodity,
      updateStops,
      showDirectAssignModal,
      deliveriesMulti,
    };
  },
  methods: {
    accept() {
      this.$emit('changeCurrentView', 'CARRIER');
    },
    async formSubmit(type) {
      this.isValidForm = await this.$refs.form.validate();
      this.currentFormtype = type;

      if (!this.isOverloadWeight(StopStatus.PICKUP)) {
        BaseComponent.methods?.showToastError(this.$t('message-error-weight-pickup'));
        return;
      }
      if (!this.isOverloadPallets(StopStatus.PICKUP)) {
        BaseComponent.methods?.showToastError(this.$t('message-error-pallets-pickup'));
        return;
      }
      if (!this.isOverloadWeight(StopStatus.DELIVERY)) {
        BaseComponent.methods?.showToastError(this.$t('message-error-weight-delivery'));
        return;
      }
      if (!this.isOverloadPallets(StopStatus.DELIVERY)) {
        BaseComponent.methods?.showToastError(this.$t('message-error-pallets-delivery'));
        return;
      }

      if (this.isValidForm.valid) {
        this.updateStops();
        if (type === 'CARRIER') {
          this.showDirectAssignModal = true;
        } else {
          this.$emit('changeCurrentView', type);
        }
        return true;
      }

      window.scrollTo({ top: 0, behavior: 'instant' });
      return false;
    },
    isOverloadPallets(type) {
      const currentDeliveries = this.dataStopsLocal?.filter((stop) => stop.type.includes(type));

      const totalPallets = currentDeliveries.reduce((acc, curr) => {
        const commodityWeights = curr?.commodityMaster?.map(
          (commodity) => commodity?.nPallets || 0,
        );
        return acc + commodityWeights.reduce((sum, nPallets) => Number(sum) + Number(nPallets), 0);
      }, 0);

      return this.totalCommodityPallets() === Number(totalPallets);
    },
    isOverloadWeight(type) {
      const currentDeliveries = this.dataStopsLocal?.filter((stop) => stop.type.includes(type));

      const totalWeight = currentDeliveries.reduce((acc, curr) => {
        const commodityWeights = curr?.commodityMaster?.map((commodity) => commodity?.weight || 0);
        return acc + commodityWeights.reduce((sum, weight) => sum + weight, 0);
      }, 0);

      return this.totalCommodityWeight() === Number(totalWeight);
    },
    totalCommodityPallets() {
      let result = 0;
      this.dataCommodities.forEach((commodity) => {
        result += Number(commodity.nPallets);
      });

      return Number(result);
    },
    totalCommodityWeight() {
      let result = 0;
      this.dataCommodities.forEach((commodity) => {
        result += Number(commodity.weight);
      });

      return Number(result);
    },
  },
});
</script>

<style scoped></style>
