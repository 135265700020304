
import { defineComponent, reactive, toRefs, computed, onBeforeMount } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierLoadHeader from '@/components/carrier/PlanCarrierLoadHeader.vue';
import PlanCarrierLoadSubHeader from '@/components/carrier/PlanCarrierLoadSubHeader.vue';
import PlanCarrierLoadHeaderNotification from '@/components/carrier/PlanCarrierLoadHeaderNotification.vue';
import PlanCarrierLoadDetails from '@/components/carrier/loadDetail/PlanCarrierLoadDetails.vue';
import PlanCarrierLoadStopData from '@/components/carrier/loadDetail/PlanCarrierLoadStopData.vue';
import PlanCarrierLoadBid from '@/components/carrier/_quoting/PlanCarrierLoadBid.vue';

import CarrierGeneralNotification from '@/modules/carrier/_components/CarrierGeneralNotification.vue';
import CarrierRFPConfirmation from '@/modules/carrier/_components/CarrierRFPConfirmation.vue';
import { useStore } from 'vuex';

import ImpersonatingBarCarrier from '@/components/sidebar/ImpersonatingBarCarrier.vue';
import { useRoute, useRouter } from 'vue-router';
import { LoadStatus } from '@/services/loads/LoadsTypes';
import moment from 'moment';

interface carrierBase {
  load: any;
  loadingLoad: boolean;
}

export default defineComponent({
  name: 'CarrierLoadDetails',
  components: {
    CarrierGeneralNotification,
    CarrierRFPConfirmation,
    PlanCarrierLoadBid,
    ImpersonatingBarCarrier,
    PlanCarrierLoadHeaderNotification,
    PlanCarrierLoadStopData,
    PlanCarrierLoadDetails,
    PlanCarrierLoadSubHeader,
    PlanCarrierLoadHeader,
    PlanDefaultCarrierView,
  },
  props: {
    id: { type: String, required: false, default: null },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const data = reactive({
      currentAssignConfirmation: {} as any,
      currentStatus: '',
    });

    store.dispatch('PusherStore/initializePusher');

    const goToLoadAcceptation = () => {
      router.push({ name: 'CarrierLoadAcceptation', params: { id: props.id } });
    };

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const getConfirmationRFP = computed(() => {
      return store.getters['CarrierStore/getConfirmationRFP'];
    });

    const stopsData = computed(() => {
      let result: Array<any> = [];

      loadData.value.stops.forEach((stop: any, index: number) => {
        let toCity = stop.location.city;
        if (index < loadData.value.stops.length - 1) {
          toCity = loadData.value.stops[index + 1].location.city;
        }

        result.push({
          stop: stop,
          toCity: toCity,
        });
      });

      return result;
    });

    const currentTypeHeaderNotifycation = computed(() => {
      switch (data.currentStatus) {
        case LoadStatus.WAITING:
          return 'warning';
        case LoadStatus.EXPIRED:
          return 'error';
        case LoadStatus.DECLINE:
          return 'error';
        case LoadStatus.CONFIRMED:
          return 'warning';
        case LoadStatus.ASSIGNING:
          return 'prim';
        default:
          return 'warning';
      }
    });

    const formattingDate = (date) => {
      if (currentUser?.value.company?.country?.region?.name === 'EUROPE') {
        return moment(date).format('DD MMM HH:mm');
      } else {
        return moment(date).format('MMM DD HH:mm');
      }
    };

    const handleCurrentStatus = (status, shipment) => {
      switch (status) {
        case LoadStatus.WAITING:
          data.currentStatus = status;
          break;
        case LoadStatus.EXPIRED:
          data.currentStatus = status;
          break;
        case LoadStatus.DECLINE:
          data.currentStatus = status;
          break;
        case LoadStatus.CONFIRMED:
          data.currentStatus = status;
          break;
        default:
          data.currentStatus = '';
          break;
      }
    };

    onBeforeMount(() => {
      fetchConfirmationRFP();
    });

    const fetchConfirmationRFP = () => {
      if (getConfirmationRFP.value?.isPendingConfirm) {
        store
          .dispatch('RFPStore/getAssignConfirmation', getConfirmationRFP.value.id)
          .then((response) => {
            data.currentAssignConfirmation = response;
            handleCurrentStatus(
              data.currentAssignConfirmation?.status,
              data.currentAssignConfirmation?.shipment?.id,
            );

            store.dispatch('RFPStore/getLaneByIdCarrier', {
              projectId: data.currentAssignConfirmation?.quote?.lane?.id,
              page: 1,
            });
          });
      }
    };

    const fetch = () => {
      store.commit('CarrierStore/setConfirmationRFP', {
        isPendingConfirm: false,
        id: null,
      });
      store.dispatch('CarrierStore/getLoadInfoForCarrier', props.id);
    };

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const isCarrierAssignedToThisLoad = computed(() => {
      return (
        currentUser.value.company?.id === loadData.value.shipment?.assignedCompany?.company?.id
      );
    });

    const shipperHasSelectedYouNotification = computed(() => {
      return (
        currentUser.value.company?.id === loadData.value.shipment?.assignedCompany?.company?.id &&
        loadData.value.status === 'CONFIRMED'
      );
    });

    return {
      ...toRefs(data),
      goToLoadAcceptation,
      formattingDate,
      loadData,
      fetch,
      fetchConfirmationRFP,
      stopsData,
      currentTypeHeaderNotifycation,
      shipperHasSelectedYouNotification,
      isCarrierAssignedToThisLoad,
      getConfirmationRFP,
      currentUser,
    };
  },
});
