import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "w-full flex justify-between items-center plan-rounded-sm border plan-border-sec-100 plan-b-sec-50 plan-p-sm" }
const _hoisted_3 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_4 = { class: "font-bold plan-t-h5" }
const _hoisted_5 = {
  key: 1,
  class: "bg-blue-100 bg-opacity-25 p-6 md:px-10 justify-between rounded-xl border border-blue-100 flex flex-col"
}
const _hoisted_6 = { class: "flex justify-between md:text-center md:space-x-2" }
const _hoisted_7 = { class: "flex flex-row mt-4 items-center" }
const _hoisted_8 = { class: "text-xl font-bold opacity-70 ml-6" }
const _hoisted_9 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!

  return (_ctx.newInterface)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_plan_icon, {
              name: "contacts-bell",
              "icon-color": "#004C80",
              size: "22",
              "is-svg": ""
            }),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.currentContacts.length) + " " + _toDisplayString(_ctx.$t('address-book-info')), 1)
          ]),
          _createVNode(_component_plan_button, {
            onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('modalVisible'))),
            type: "primary",
            label: _ctx.$t('new-contact'),
            "ç": "",
            "show-icon": "",
            icon: "plus",
            "icon-size": "10",
            "icon-color": "white"
          }, null, 8, ["label"])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_plan_icon, {
              name: "contacts-bell",
              "icon-color": "gray",
              size: "22",
              "is-svg": ""
            }),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.currentContacts.length) + " " + _toDisplayString(_ctx.$t('address-book-info')), 1)
          ]),
          _createElementVNode("a", {
            class: "border mt-2 border-corp text-center px-3 py-2 rounded-md flex justify-center items-center space-x-2 opacity-70 transition duration-500 hover:opacity-100 cursor-pointer",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('modalVisible')))
          }, [
            _createVNode(_component_plan_icon, {
              name: "plus",
              "icon-color": "black",
              size: "16",
              "is-svg": ""
            }),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('new-contact')), 1)
          ])
        ])
      ]))
}