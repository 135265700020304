
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanDefaultView from '@/components/defaults/PlanDefaultView.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanInstantQuoteForm from '@/components/instantQuote/PlanInstantQuoteForm.vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import { Bubble, Line } from 'vue-chartjs'
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PlanFormattedNumber from '@/components/labels/PlanFormattedNumber.vue';
import moment from 'moment';
import PlanButtonGroup from '@/components/buttons/PlanButtonGroup.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';

import {
  Chart as ChartJS,
  CategoryScale,
  Tooltip,
  Legend,
  PointElement,
  LinearScale,
  LineElement,
  Title
} from 'chart.js';
import SpecialRequirementsFilter from '@/components/filters/SpecialRequirementsFilter.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import router from '@/router';
import { useRouter } from 'vue-router';
import Spin from '@/components/spin/AnimateSpin.vue';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend, LineElement, Title, CategoryScale);

interface instantQuoteDetails {
  bookNow: number,
  bookNowEnabled: boolean,
  contacts: {
    activeButton: number,
    brokers: boolean,
    carriers: boolean,
    items: Array<any>,
    loading: boolean,
    page: number,
    permits: Array<any>,
    trailer: boolean
  },
  loadingPost: boolean,
  loadingPricingInfo: boolean,
  loadingRecentShortThemRate: boolean,
  postLoadState: string,
  recentShortThemRateActiveButton: number,
  selectedItems: Array<any>,
  targetPurchaseRate: {
    value: number,
    min: number,
    minPlanimatik: number,
    max: number,
    maxPlanimatik: number,
    fuel: number,
    mileage: number,
    historicalPrices: Array<any>
  },
  typePricingInfo: string
}

export default defineComponent({
  name: 'InstantQuoteDetails',
  components: { Spin, PlanDefaultModal, SpecialRequirementsFilter, PlanLoading, PlanButtonGroup, PlanFormattedNumber, PlanAvatar, PlanButtonIcon, Line, PlanTextButton, Bubble, PlanDefaultTable, PlanButton, PlanInputNumber, PlanInputCheckbox, PlanInstantQuoteForm, PlanIcon, PlanDefaultView },
  setup () {
    const router = useRouter()
    const store = useStore()
    const translate = useI18n()

    const data = reactive<instantQuoteDetails>({
      bookNow: 0,
      bookNowEnabled: false,
      contacts: {
        activeButton: 0,
        brokers: true,
        carriers: true,
        items: [],
        loading: false,
        page: 1,
        permits: [],
        trailer: true
      },
      loadingPost: false,
      loadingPricingInfo: false,
      loadingRecentShortThemRate: false,
      postLoadState: '',
      recentShortThemRateActiveButton: 0,
      selectedItems: [],
      targetPurchaseRate: {
        value: 0,
        min: 0,
        minPlanimatik: 0,
        max: 0,
        maxPlanimatik: 0,
        fuel: 0,
        mileage: 0,
        historicalPrices: []
      },
      typePricingInfo: 'EQUAL'
    })

    const activeForm = computed(() => {
      const region = store.getters['UserStore/getRegion']
      const activeFormData = store.getters['InstantQuoteStore/getActiveForm']
      let pickUpData = activeFormData.pickup.locality
      let deliverData = activeFormData.delivery.locality
      const trailerObject = vehicles.value.find(type => type.value.toString() === store.getters['InstantQuoteStore/getActiveForm'].trailerId)

      return {
        pickup: pickUpData,
        pickupDate: activeFormData.pickupDate,
        delivery: deliverData,
        deliveryDate: activeFormData.deliveryDate,
        trailerLabel: trailerObject.label,
        trailerId: trailerObject.value
      }
    })

    const averageStats = computed(() => {
      return store.getters['InstantQuoteStore/getAverageStats']
    })

    const contactHeaders = computed(() => {
      return [
        { label: translate.t('carrier-broker'), value: 'carrierBrooker' },
        { label: translate.t('rating'), value: 'rate' },
        { label: translate.t('completed-loads'), value: 'completedLoads' },
        { label: '30d / 15d / 07d ' + translate.t('rate'), value: 'rates' },
        { label: '', value: 'checks' },
      ]
    })

    const datePriceStats = computed(() => {
      if (store.getters['InstantQuoteStore/getDatePriceStats'] === null) {
        return {
          datasets: [
            {
              label: translate.t('loads'),
              backgroundColor: '#0097FF',
              data: []
            }
          ]
        }
      } else {
        let result: Array<any> = []
        store.getters['InstantQuoteStore/getDatePriceStats'].forEach((item: any) => {
          const itemDate = moment(item.finished_date)
          result.push({ x: itemDate.format('DD'), y: item.price, r: 5 },)
        })

        return {
          datasets: [
            {
              label: translate.t('loads'),
              backgroundColor: '#0097FF',
              data: result
            }
          ]
        }
      }
    })

    const dataPriceOptions = computed(() => {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: function(context: any) {
                let label = '';
                if (context.parsed.y !== null) {
                  if (store.getters['UserStore/getRegion'] === 'NORTH_AMERICA') {
                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y)
                  } else {
                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(context.parsed.y)
                  }
                }
                return label
              }
            }
          }
        }
      }
    })

    const changeBookNow = (newValue: boolean) => {
      data.bookNowEnabled = newValue
    }

    const changeContactActiveButton = async (newIndex: number) => {
      data.contacts.activeButton = newIndex
      await getCompaniesForInstantQuote()
    }

    const changeContactsBrokersValue = (newValue: boolean) => {
      data.contacts.brokers = newValue
      getCompaniesForInstantQuote()
    }

    const changeContactsCarriersValue = (newValue: boolean) => {
      data.contacts.carriers = newValue
      getCompaniesForInstantQuote()
    }

    const changePricingInfoType = (newValue: number) => {
      data.typePricingInfo = newValue == 0 ? 'EQUAL':'3ZIP'
      getPricingInfo()
    }

    const changePermits = (newPermitsSelected: any) => {
      data.contacts.permits = newPermitsSelected
      getCompaniesForInstantQuote()
    }

    const changeTrailerValue = (newValue: boolean) => {
      data.contacts.trailer = newValue
      getCompaniesForInstantQuote()
    }

    const changeRecentShortThemRateActiveButton = async (newIndex: number) => {
      data.loadingRecentShortThemRate = true
      data.recentShortThemRateActiveButton = newIndex
      await getDatePriceStats()
      await getShipmentAverageStats()
      data.loadingRecentShortThemRate = false
    }

    const changeSelectedItemStatus = (contact: any) => {
      if (!data.selectedItems.includes(contact)) {
        data.selectedItems.push(contact)
      } else {
        data.selectedItems.splice(data.selectedItems.indexOf(contact), 1)
      }
    }

    const formatNumberOrNull = (value: number | null) => {
      if (value === null || value === 0) return '-'
      return value
    }

    const formatContactRating = (value: number | null) => {
      if (value === null) return '-'
      if (value % 1 === 0) {
        return value;
      } else {
        return parseFloat(value.toFixed(1));
      }

    }

    const getCompaniesForInstantQuote = async () => {
      data.contacts.page = 1;
      let companyType = 'CARRIER%7CBROKER'
      if (data.contacts.brokers && !data.contacts.carriers) companyType = 'BROKER'
      if (!data.contacts.brokers && data.contacts.carriers) companyType = 'CARRIER'
      if (!data.contacts.brokers && !data.contacts.carriers) companyType = ''

      data.contacts.loading = true
      await store.dispatch('InstantQuoteStore/getCompaniesForInstantQuote', {
        page: data.contacts.page,
        origin: store.getters['InstantQuoteStore/getActiveForm'].pickup.postal_code,
        destiny: store.getters['InstantQuoteStore/getActiveForm'].delivery.postal_code,
        trailer: data.contacts.trailer ? store.getters['InstantQuoteStore/getActiveForm'].trailerId : '-1',
        trailerStats: store.getters['InstantQuoteStore/getActiveForm'].trailerId,
        companyType: companyType,
        permits: data.contacts.permits,
        lane: data.contacts.activeButton === 0
      }).then((response) => {
        data.contacts.items = response
      })
      data.contacts.loading = false
    }

    const loadMoreCompaniesForInstantQuote = async () => {
      data.contacts.page++;

      let companyType = 'CARRIER%7CBROKER'
      if (data.contacts.brokers && !data.contacts.carriers) companyType = 'BROKER'
      if (!data.contacts.brokers && data.contacts.carriers) companyType = 'CARRIER'
      if (!data.contacts.brokers && !data.contacts.carriers) companyType = ''

      data.contacts.loading = true
      await store.dispatch('InstantQuoteStore/getCompaniesForInstantQuote', {
        page: data.contacts.page,
        origin: store.getters['InstantQuoteStore/getActiveForm'].pickup.postal_code,
        destiny: store.getters['InstantQuoteStore/getActiveForm'].delivery.postal_code,
        trailer: data.contacts.trailer ? store.getters['InstantQuoteStore/getActiveForm'].trailerId : '-1',
        trailerStats: store.getters['InstantQuoteStore/getActiveForm'].trailerId,
        companyType: companyType,
        permits: data.contacts.permits,
        lane: data.contacts.activeButton === 0
      }).then((response) => {
        data.contacts.items = data.contacts.items.concat(response);
      })
      data.contacts.loading = false
    }

    const getDatePriceStats = async () => {
      await store.dispatch('InstantQuoteStore/getDatePriceStats', {
        origin: store.getters['InstantQuoteStore/getActiveForm'].pickup.postal_code,
        destiny: store.getters['InstantQuoteStore/getActiveForm'].delivery.postal_code,
        trailer: store.getters['InstantQuoteStore/getActiveForm'].trailerId,
        period: 'LAST_MONTH',
        lane: data.recentShortThemRateActiveButton === 0
      }).then((response) => {
        store.commit('InstantQuoteStore/setDatePriceStats', response)
      })
    }

    const getPricingInfo = async () => {
      data.loadingPricingInfo = true
      await store.dispatch('InstantQuoteStore/getPricingInfo', {
        origin: store.getters['InstantQuoteStore/getActiveForm'].pickup.postal_code,
        originCity: store.getters['InstantQuoteStore/getActiveForm'].pickup.locality,
        originPlaceId: store.getters['InstantQuoteStore/getActiveForm'].pickup['place_id'],
        destiny: store.getters['InstantQuoteStore/getActiveForm'].delivery.postal_code,
        destinyCity: store.getters['InstantQuoteStore/getActiveForm'].delivery.locality,
        destinyPlaceId: store.getters['InstantQuoteStore/getActiveForm'].delivery['place_id'],
        type: data.typePricingInfo

      }).then((response) => {
        data.bookNow = Math.round(response.targetRate)
        if (data.bookNow > 0) data.bookNowEnabled = true
        data.targetPurchaseRate.value = response.targetRate
        data.targetPurchaseRate.min = response.minPriceNetwork
        data.targetPurchaseRate.minPlanimatik = response.minPricePlanimatik
        data.targetPurchaseRate.max = response.maxPriceNetwork
        data.targetPurchaseRate.maxPlanimatik = response.maxPricePlanimatik
        data.targetPurchaseRate.fuel = response.fuelRate
        data.targetPurchaseRate.mileage = response.miles
        data.targetPurchaseRate.historicalPrices = response.historicalPrices
      })
      data.loadingPricingInfo = false
    }

    const getShipmentAverageStats = async () => {
      await store.dispatch('InstantQuoteStore/getShipmentAverageStats', {
        origin: store.getters['InstantQuoteStore/getActiveForm'].pickup.postal_code,
        destiny: store.getters['InstantQuoteStore/getActiveForm'].delivery.postal_code,
        trailer: store.getters['InstantQuoteStore/getActiveForm'].trailerId,
        period: 'LAST_MONTH',
        lane: data.recentShortThemRateActiveButton === 0
      }).then((response) => {
        store.commit('InstantQuoteStore/setAverageStats', response)
      })
    }

    const historicalPricesLine = computed(() => {
      const currentMonth = moment().month() + 1
      const monthsArray: string[] = []
      const monthKeys = [
        'month-display.january', 'month-display.february', 'month-display.march',
        'month-display.april', 'month-display.may', 'month-display.june',
        'month-display.july', 'month-display.august', 'month-display.september',
        'month-display.october', 'month-display.november', 'month-display.december'
      ]

      for (let i = 0; i < 12; i++) {
        const monthIndex = (currentMonth + i) % 12;
        const translatedMonth = translate.t(monthKeys[monthIndex]);
        monthsArray.push(translatedMonth);
      }

      let minPrice: Array<any> = []
      let maxPrice: Array<any> = []
      let volume: Array<any> = []
      let average: Array<any> = []

      const currentYearData = data.targetPurchaseRate.historicalPrices.filter((item: any) => item.month <= currentMonth);
      const previousYearData = data.targetPurchaseRate.historicalPrices.filter((item: any) => item.month > currentMonth);
      const orderedHistoricalPrices = previousYearData.concat(currentYearData);

      orderedHistoricalPrices.forEach((item: any) => {
        minPrice.push(item.minPrice)
        maxPrice.push(item.maxPrice)
        volume.push(item.volume)
        average.push(item.average)
      })

      return {
        labels: monthsArray,
        datasets: [
          {
            label: translate.t('min-price'),
            borderColor: '#0097FF',
            backgroundColor: '#0097FF',
            data: minPrice,
            fill: false
          }, {
            label: translate.t('max-price'),
            borderColor: '#F1AD02',
            backgroundColor: '#F1AD02',
            data: maxPrice,
            fill: false
          }, {
            label: translate.t('average'),
            borderColor: '#00FFFF',
            backgroundColor: '#00FFFF',
            data: average,
            fill: false
          }
        ]
      }
    })

    const historialPricesLineOptions = computed(() => {
      return {
        scales: {
          y: {  // Cambiado de yAxes a y en versiones modernas de Chart.js
            beginAtZero: true,  // Asegura que el eje Y comience desde 0
            display: true
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true
          }
        }
      }
    })

    const onSelectItems = (newItems: any) => {
      data.selectedItems = newItems
    }

    const postLoad = async () => {
      data.loadingPost = true
      data.postLoadState = 'load'
      let loadId = -1
      let shipmentId = -1
      const loadBody = {
        "stops": [
          {
            "type": "PICKUP_INITIAL",
            "executionDate": store.getters['InstantQuoteStore/getActiveForm'].pickupDate,
            "location": {
              "lat": store.getters['InstantQuoteStore/getActiveForm'].pickup.latitude,
              "long": store.getters['InstantQuoteStore/getActiveForm'].pickup.longitude,
              "zip": store.getters['InstantQuoteStore/getActiveForm'].pickup.postal_code,
              "city": store.getters['InstantQuoteStore/getActiveForm'].pickup.locality,
              "googlePlaceId": store.getters['InstantQuoteStore/getActiveForm'].pickup.placeId
            },
            "commodities": [
              {
                "nPallets": Number(store.getters['InstantQuoteStore/getActiveForm'].units),
                "packageType": {
                  "id": store.getters['InstantQuoteStore/getActiveForm'].packageType
                },
                "weight": store.getters['InstantQuoteStore/getActiveForm'].weight,
                "commodityName": store.getters['InstantQuoteStore/getActiveForm'].commodity
              }
            ]
          },
          {
            "type": "DELIVERY_FINAL",
            "executionDate": store.getters['InstantQuoteStore/getActiveForm'].deliveryDate,
            "location": {
              "lat": store.getters['InstantQuoteStore/getActiveForm'].delivery.latitude,
              "long": store.getters['InstantQuoteStore/getActiveForm'].delivery.longitude,
              "zip": store.getters['InstantQuoteStore/getActiveForm'].delivery.postal_code,
              "city": store.getters['InstantQuoteStore/getActiveForm'].delivery.locality,
              "googlePlaceId": store.getters['InstantQuoteStore/getActiveForm'].delivery.placeId
            },
            "commodities": [
              {
                "nPallets": Number(store.getters['InstantQuoteStore/getActiveForm'].units),
                "packageType": {
                  "id": store.getters['InstantQuoteStore/getActiveForm'].packageType
                },
                "weight": store.getters['InstantQuoteStore/getActiveForm'].weight,
                "commodityName": store.getters['InstantQuoteStore/getActiveForm'].commodity
              }
            ]
          }
        ],
        "commodityMaster": {
          "nPallets": Number(store.getters['InstantQuoteStore/getActiveForm'].units),
          "packageType": {
            "id": store.getters['InstantQuoteStore/getActiveForm'].packageType
          },
          "weight": store.getters['InstantQuoteStore/getActiveForm'].weight,
          "commodityName": store.getters['InstantQuoteStore/getActiveForm'].commodity
        },
        "trailerType": {
          "id": store.getters['InstantQuoteStore/getActiveForm'].trailerId
        },
        "commodityMasters": [
          {
            "nPallets": Number(store.getters['InstantQuoteStore/getActiveForm'].units),
            "packageType": {
              "id": store.getters['InstantQuoteStore/getActiveForm'].packageType
          },
            "weight": store.getters['InstantQuoteStore/getActiveForm'].weight,
            "commodityName": store.getters['InstantQuoteStore/getActiveForm'].commodity
          }
        ]
      }
      let companiesBody: Array<any> = []
      data.selectedItems.forEach((company: any) => {
        companiesBody.push({ id: company.id })
      })

      await store.dispatch('LoadsStore/createLoad', { load: loadBody, isMultiple: false })
        .then((load) => {
          loadId = load.id
          shipmentId = load.shipment.id
        })

      if (data.bookNowEnabled) {
        data.postLoadState = 'bookNow'
        await store.dispatch('LoadsStore/setBookNowPrice', {
          id: loadId,
          params: { bookNowPrice: data.bookNow, bookNowEnable: true },
        })
      }


      data.postLoadState = 'quotes'
      await store.dispatch('LoadsStore/shipmentRequestBatch', {
        companies: companiesBody,
        shipments: [{ id: shipmentId }]
      })

      store.dispatch('UserStore/updateToast', {
        text: translate.t('success-load-instant-quote-generated'),
        type: 'success',
        visible: true
      })

      store.commit('InstantQuoteStore/cleanStore')
      router.push('/planning')
      data.loadingPost = false
    }

    const updateQuote = () => {
      getDatePriceStats()
      getShipmentAverageStats()
      getCompaniesForInstantQuote()
      getPricingInfo()
    }

    const vehicles = computed(() => {
      let result: Array<any> = []
      store.getters['LoadsStore/getTrailerTypes'].forEach((vehicle: any) => {
        result.push({
          label: vehicle.description,
          value: vehicle.id
        })
      })
      return result
    })

    getCompaniesForInstantQuote()
    getShipmentAverageStats()
    getPricingInfo()

    return {
      ...toRefs(data),
      activeForm,
      averageStats,
      contactHeaders,
      datePriceStats,
      dataPriceOptions,
      changeBookNow,
      changeContactsBrokersValue,
      changeContactsCarriersValue,
      changeContactActiveButton,
      changePricingInfoType,
      changePermits,
      changeTrailerValue,
      changeRecentShortThemRateActiveButton,
      changeSelectedItemStatus,
      formatContactRating,
      formatNumberOrNull,
      historicalPricesLine,
      historialPricesLineOptions,
      loadMoreCompaniesForInstantQuote,
      onSelectItems,
      postLoad,
      updateQuote
    }
  }
})
