
import { computed, defineComponent, reactive, toRefs, PropType, ref, nextTick, watch } from 'vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PlanInputNumberFormatted',
  props: {
    mandatory: { type: Boolean, required: false, default: false },
    modelValue: { type: [Number, null] as PropType<number | null>, required: true },
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    unit: { type: String, required: false, default: 'currency' },
    onlyCountry: { type: String, required: false, default: '' }
    // Units: currency, weight, temperature, distance
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const data = reactive({
      currency: store.getters['CountryStore/getCurrencySymbol'],
      thereAreChanges: false,
      isInputOnFocus: false,
    });

    const actualRegion = computed(() => {
      const region = store.getters['UserStore/getRegion']

      return {
        region: region,
        currency: region === 'NORTH_AMERICA' ? '$' : '€',
        weight: region === 'NORTH_AMERICA' ? 'lb' : 'kg',
        distance: region === 'NORTH_AMERICA' ? 'mi' : 'km',
        temperature: region === 'NORTH_AMERICA' ? 'ºF' : 'ºC'
      }
    })

    const internalValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const leftLabel = computed(() => {
      let result = ''
      if (props.unit === 'currency' && actualRegion.value.region === 'NORTH_AMERICA') result = actualRegion.value.currency
      return result
    })

    const rightLabel = computed(() => {
      let result = ''
      if (props.unit === 'currency' && actualRegion.value.region === 'EUROPE') result = actualRegion.value.currency
      if (props.unit === 'weight') {
        result = actualRegion.value.weight
        if (props.onlyCountry === 'mx') result = 'kg'
      }
      if (props.unit === 'distance') {
        result = actualRegion.value.distance
        if (props.onlyCountry === 'mx') result = 'km'
      }
      if (props.unit === 'temperature') {
        result = actualRegion.value.temperature
        if (props.onlyCountry === 'mx') result = 'ºC'
      }
      return result
    })

    return {
      ...toRefs(data),
      actualRegion,
      internalValue,
      leftLabel,
      rightLabel
    };
  },
});
