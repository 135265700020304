
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';

import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import { useI18n } from 'vue-i18n';
import { debounce } from 'lodash';
export default defineComponent({
  name: 'PlanCarrierUpdateVehicleModal',
  components: { PlanSelect, PlanInputText },
  emits: ['selectVehicle'],
  props: {
    vehicle: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const data = reactive({
      loadingPut: false,
      showModal: false,
      selectedTrailer: '',
      licensePlate: '',
      trailerPlate: '',
      emitId: null,
    });

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const changeSelectedTrailer = (newTrailerValue: any) => {
      data.selectedTrailer = newTrailerValue;
      debouncedUpdateDriver();
    };

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const countriesList = computed(() => {
      return store.getters['CountryStore/getCountries'];
    });

    const company = computed((): any => {
      return store.getters['CompanyStore/getCompany'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const getVehicles = async () => {
      await store
        .dispatch('CompanyStore/vehiclesList', currentUser.value.company.id)
        .then((response) => {
          store.commit('CarrierStore/setVehicles', response);
        })
        .finally(() => {
          emit('selectVehicle', data.emitId);
          data.showModal = false;
          data.loadingPut = false;
        });
    };

    const handleUpdateVehicle = async () => {
      if (!validateVehicle.value) {
        await store.dispatch('UserStore/updateToast', {
          text: translate.t('all-fields-required-notification'),
          type: 'error',
          visible: true,
        });
        return;
      }

      data.loadingPut = true;
      await store
        .dispatch('CompanyStore/putVehicle', {
          id: props.vehicle.id,
          trailerType: { id: data.selectedTrailer },
          licensePlate: data.licensePlate,
          trailerPlate: data.trailerPlate,
        })
        .then((response) => {
          store.dispatch('UserStore/showToast', translate.t('updated-correctly'));
          data.emitId = response.id;
        })
        .finally(() => {
          getVehicles();
        });
    };

    const isEuropeCompany = computed((): any => {
      const country = countriesList.value.find(
        (country) => company.value?.country?.id === country?.id,
      );

      return country?.region?.name === 'EUROPE';
    });

    const debouncedUpdateDriver = debounce(handleUpdateVehicle, 500);

    const trailerTypes = computed(() => {
      let result: Array<any> = [];
      store.getters['LoadsStore/getTrailerTypes'].forEach((trailer: any) => {
        result.push({
          label: !isEuropeCompany.value ? trailer.description : translateTrailer(trailer.description),
          value: trailer.id.toString(),
        });
      });
      return result;
    });

    const translateTrailer = (trailerTypeName: any) => {
      const clearName = trailerTypeName.replace(/[^\wáéíóú]/g, '');
      return translate.t(`trailer-types-eu.${clearName}`)
    };

    const validateVehicle = computed(() => {
      return (
        data.selectedTrailer !== '' &&
        data.trailerPlate?.length > 0 &&
        data.licensePlate?.length > 0
      );
    });

    data.selectedTrailer = props.vehicle.trailerType.id.toString();
    data.licensePlate = props.vehicle.licensePlate + '';
    data.trailerPlate = props.vehicle.trailerPlate + '';

    return {
      ...toRefs(data),
      actualWidth,
      changeSelectedTrailer,
      debouncedUpdateDriver,
      handleUpdateVehicle,
      trailerTypes,
      validateVehicle,
    };
  },
});
