
import { defineComponent } from 'vue';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { StarIcon } from '@heroicons/vue/24/solid';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import CountryMixins from '@/services/country/_mixins/index';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';
import SortIcon from '@/components/filters/SortIcon.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
import PlanInputMultiSelect from '@/components/forms/PlanInputMultiSelect.vue';
import { debounce } from 'lodash';

import PlanFlag from '@/components/icons/PlanFlag.vue';

import PlanHeaderSearch from '@/components/filters/PlanHeaderSearch.vue';
import ContactsTabFilter from '@/components/filters/ContactsTabFilter.vue';

// Styles
import HorizontalScroll from '@/resources/assets/styles/animation/HorizontalScroll.vue';
import PlanFilterContactTag from '@/components/filters/PlanFilterContactTag.vue';
import PlanFilterZipType from '@/components/filters/PlanFilterZipType.vue';
import PlanContactTags from '@/components/labels/PlanContactTags.vue';

interface IQuote {
  useContactPage: number;
  netWorkContactPage: number;
  modalVisible: boolean;
  showSpinner: boolean;
  allowed: boolean;
  isSending: boolean;
  searchQuery: string;
  userType: string;
  selectedContacts: Array<number>;
  searchByCompanyName: string;
  searchByRating: string;
  searchByLoadsQuantity: string;
  searchByAverageBid: string;
  isSortByName: boolean;
  isSortByRating: boolean;
  responseStops: Array<any>;
  isSortByQuantity: boolean;
  isSortByAverage: boolean;
  carrierAdded: User;
  trailerTypeIdToSearch: number;
  isTrailerFiltered: boolean;
  isCheckedShowQuotes: boolean;
  isBookNow: boolean;
  fetchContactListDebounced: any;
  bookNow: any;
  searchByUserName: string;
  historicalRange: string;
  selectedRequeriments: Array<any>;
  showFilters: boolean;
  showPlansModal: boolean;
  permits: Array<any>;
}

interface User {
  id: number;
  name: string;
  company: string;
  owner_name: string;
  stats_amountvalorations: string;
  stats_shipmentamount: number;
  stats_averageshipmentprice: number;
  owner_surname: string;

  stats: {
    amountValorations: number;
    shipmentAmount: number;
    averageShipmentPrice: number;
  };
  source: string;
  LaneAssignedShipmentsFinishedCount: number;
  LaneAverageShipmentPrice: number;
}

export default defineComponent({
  name: 'QuoteStep',
  components: {
    PlanContactTags,
    PlanFilterContactTag,
    PlanFilterZipType,
    AddCarrierModal,
    PlusCircleIcon,
    SortIcon,
    Spin,
    StarIcon,
    PlanFlag,
    ContactsTabFilter,
    PlanHeaderSearch,
  },
  mixins: [BaseComponent, HorizontalScroll, CountryMixins],
  props: {
    warehousesAllowed: {
      type: Boolean,
      required: true,
    },
    load: {
      type: Object,
      required: true,
    },
    allowMultipleLoad: {
      type: Boolean,
      default: false,
    },
    totalLoadsMultiple: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isCheckedShowQuotes: false,
      isSending: false,
      allowed: false,
      selectedContacts: [] as any,
      selectedRequeriments: [],
      countries: [] as any,
      countriesString: '',
      showSpinner: false,
      useContactPage: 1,
      netWorkContactPage: 1,
      modalVisible: false,
      searchQuery: '',
      userType: 'CARRIER|BROKER',
      responseStops: [],
      debouncedFetchContactList: null as any,
      searchByUserName: '',
      searchByCompanyName: '',
      searchByRating: '',
      searchByLoadsQuantity: '',
      searchByAverageBid: '',
      zipType: '',
      showPlansModal: false,
      isSortByName: false,
      isSortByRating: false,
      isSortByQuantity: false,
      isSortByAverage: false,
      carrierAdded: {} as User,
      trailerTypeIdToSearch: 0,
      isTrailerFiltered: true,
      isBookNow: false,
      permits: [
        {
          value: 'hasTransportAndDeliveryOfAlcoholicBeveragesPermit',
          translation: 'permits-contact.hasTransportAndDeliveryOfAlcoholicBeveragesPermit',
        },
        {
          value: 'hasHazardousMaterialPermit',
          translation: 'permits-contact.hasHazardousMaterialPermit',
        },
        { value: 'hasTWICCard', translation: 'permits-contact.hasTWICCard' },
        { value: 'hasTSA', translation: 'permits-contact.hasTSA' },
        {
          value: 'hasOverweightLoadPermit',
          translation: 'permits-contact.hasOverweightLoadPermit',
        },
        { value: 'hasTeamDriverPermit', translation: 'permits-contact.hasTeamDriverPermit' },
        { value: 'hasBondPermit', translation: 'permits-contact.hasBondPermit' },
        { value: 'hasTirPermit', translation: 'permits-contact.hasTirPermit' },
        {
          value: 'hasMoveFullTruckPermit',
          translation: 'permits-contact.hasMoveFullTruckPermit',
        },
        { value: 'hasMovePartialPermit', translation: 'permits-contact.hasMovePartialPermit' },
        {
          value: 'hasOversizeLoadPermit',
          translation: 'permits-contact.hasMovePartialPermit',
        },
      ],
      bookNow: undefined,
      historicalRange: 'LAST_3_MONTHS',
      showFilters: false,
      contactTag: [],
    };
  },
  created() {
    if (this.$route.name === 'newLoadView') {
      let unitedStateId = this.$store.getters['CountryStore/getCountries'].find((country) => {
        return country.name === 'United States';
      });

      let mexicoId = this.$store.getters['CountryStore/getCountries'].find((country) => {
        return country.name === 'Mexico';
      });

      if (unitedStateId && mexicoId) {
        this.countries.push(unitedStateId.id);
        this.countries.push(mexicoId.id);
        this.countriesString = `${unitedStateId.id}|${mexicoId.id}`;
      }
    }

    this.debouncedFetchContactList = debounce(this.fetchContactList, 500);
    if (!this.currentUser) {
      this.$store.dispatch('UserStore/currentUser');
    }
    this.trailerTypeIdToSearch = this.load?.trailerType?.id;

    this.debouncedFetchContactList();
    // this.setCarrierContactListStatsByLoad();
  },
  computed: {
    types() {
      return [
        { label: this.$t('last-average-stats.LAST_MONTH'), value: 'LAST_MONTH' },
        { label: this.$t('last-average-stats.LAST_3_MONTHS'), value: 'LAST_3_MONTHS' },
        { label: this.$t('last-average-stats.LAST_6_MONTHS'), value: 'LAST_6_MONTHS' },
        { label: this.$t('last-average-stats.LAST_YEAR'), value: 'LAST_YEAR' },
      ];
    },
    additionalDocuments() {
      return this.$store.getters['LoadsStore/getLoadAditionalDocuments'];
    },
    isBillingCompanyEmpty() {
      for (const key in this.billingCompanyData) {
        if (this.billingCompanyData[key] !== '') {
          return false;
        }
      }
      return true;
    },
    sourceCarrier() {
      let source: any = this.currentContacts.filter(
        (item: any) => item.source === 'CARGOBOT' || item.source === 'CARGOBOT_EU',
      );
      return source;
    },
    currentContacts(): User[] {
      if (this.carrierAdded.id !== undefined) {
        let current = this.$store.getters['UserStore/carrierContactListStatsByLoad'];
        current.unshift(this.carrierAdded);
        if(this.isEuropeCompany()){
          current = current.filter(user => user.id !== 4);
        }
        return current;
      } else {
        let current = this.$store.getters['UserStore/carrierContactListStatsByLoad'];
        if(this.isEuropeCompany()){
          current = current.filter(user => user.id !== 4);
        }
        return current;
      }
    },
    currentEditingLoad(): any {
      return this.$store.getters['LoadsStore/currentLoadEditing'];
    },
    deliveries(): any {
      return this.responseStops.filter(
        (stop) => stop.type === 'DELIVERY' || stop.type === 'DELIVERY_FINAL',
      );
    },
    destinyZip(): any {
      return this.load?.stops.filter((stop) => stop.type === 'DELIVERY_FINAL')[0].location.zip;
    },
    getDataCustomersSelected(): any {
      return this.$store.getters['CustomerStore/getCustomersSelectedData'];
    },
    getTrailerSelected(): any {
      let Id = this.load?.trailerType?.id;
      return this.trailerTypes.find((trailer) => trailer.id === Id);
    },
    maxBidsRequest(): number {
      return +this.$store.getters['GlobalStore/getGlobalConfig']
        .LIMIT_AMOUNT_OF_COMPANIES_TO_SEND_BID_REQUEST;
    },
    originZip(): any {
      return this.load?.stops.filter((stop) => stop.type === 'PICKUP_INITIAL')[0].location.zip;
    },
    paginationMaxItems(): number {
      return (
        +this.$store.getters['GlobalStore/getGlobalConfig']?.PAGINATION_DEFAULT_MAX_RESULTS ?? 0
      );
    },
    pickups(): any {
      return this.load.stops.filter(
        (stop) => stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP',
      );
    },
    shipmentId(): number {
      return this.$store.getters['LoadsStore/loadShipmentId'];
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
    billingCompanyData(): any {
      return this.$store.state.UserStore.billingCompany;
    },
    userForChat(): any {
      return this.$store.state.ChatsStore.userForChat;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
  },
  methods: {
    changeContactTag(tag) {
      this.contactTag = tag;
      if (tag === '') this.contactTag = [];
      this.debouncedFetchContactList();
    },
    filterByCountry(countryName) {
      let countriesArray = this.$store.getters['CountryStore/getCountries'];

      let country = countriesArray.find((country) => {
        return country.name === countryName;
      });

      if (country) {
        if (!this.countries.includes(country.id)) {
          this.countries.push(country.id);
        } else {
          this.countries = this.countries.filter((id) => id !== country.id);
        }
      }

      this.countriesString = this.countries.join('|');
      this.debouncedFetchContactList();
    },
    changePeriod(newType) {
      this.historicalRange = newType;
      this.debouncedFetchContactList();
    },
    changeSelectedPermits(permitsSelected) {
      this.selectedRequeriments = permitsSelected;
      this.debouncedFetchContactList();
    },
    handleInputChange(event) {
      this.searchByUserName = event;
      this.debouncedFetchContactList();
    },
    handleZipType(searchParameter){
      this.zipType =  this.zipType === searchParameter ? '': searchParameter;
      this.debouncedFetchContactList();
    },
    fetchContactList() {
      this.$store
        .dispatch('UserStore/carrierContactListStatsByLoad', {
          page: this.useContactPage,
          name: this.searchByUserName === '' ? undefined : this.searchByUserName,
          type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
          trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.trailerTypeIdToSearch ? this.trailerTypeIdToSearch : null,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
          permits: this.selectedRequeriments.length === 0 ? undefined : this.selectedRequeriments,
          country: this.countriesString === '' ? undefined : this.countriesString,
          tags: this.contactTag.length === 0 ? undefined : `[${this.contactTag}]`,
          zipType: this.zipType === '' ? undefined : this.zipType,
        })
        .then(() => {
          if (this.sourceCarrier && this.sourceCarrier.length > 0) {
            if (!this.selectedContacts.some((item) => item === this.sourceCarrier[0].id)) {
              this.selectedContacts.push(this.sourceCarrier[0].id);
            }
          }
          this.$emit('updateSelectedContacts', this.selectedContacts);
          this.allowed = true;
        });
    },
    addSelectedContact(id: number) {
      let index = this.selectedContacts.indexOf(id);
      if (index > -1) {
        this.selectedContacts.splice(index, 1);
      } else {
        if (this.selectedContacts.length >= this.maxBidsRequest) {
        }
        this.selectedContacts.push(id);
      }
      this.$emit('updateSelectedContacts', this.selectedContacts);
    },
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    getDefaultDelivery(stop: any) {
      if (
        (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') &&
        this.deliveries.length === 1
      ) {
        return '/api/stops/' + this.deliveries[0].id;
      }
      return null;
    },
    getDefaultDeliveryObject(stop: any) {
      if (
        (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') &&
        this.deliveries.length === 1
      ) {
        return [this.deliveries[0]];
      }
      return [];
    },
    getStops(stops, response): any {
      let stopsArray: any[] = [];
      if (!stops) return;

      stops.forEach((stop) => {
        let obj = {
          id: stop.id,
          type: stop.type,
          portStop: stop?.portStop,
          airportStop: stop?.airportStop,
          lumperFeePrice: stop?.lumperFeePrice ? stop.lumperFeePrice : null,
          executionDate: stop.executionDate,
          displayDate: stop.executionDate ? new Date(stop.executionDate) : '',
          executionWindowStartTime: stop.executionWindowStartTime,
          multiStop:
            stop?.multiStop.length > 0 ? stop.multiStop : this.getDefaultDeliveryObject(stop),
          executionWindowEndTime: stop.executionWindowEndTime,
          companyName: stop.companyName ? stop.companyName : '',
          observations: stop.observations ? stop.observations : '',
          phoneNumber: stop.phoneNumber ? stop.phoneNumber : '',
          phonePrefix: stop.phonePrefix ? stop.phonePrefix : '1',
          appointmentType:
            stop.executionWindowStartTime === undefined
              ? 'NONE'
              : stop.executionWindowEndTime === undefined
              ? 'APPT'
              : 'FCFS',
          location: {
            ...stop.location,
          },
          deliveryStop: this.getDefaultDelivery(stop),
          poNumber: stop.poNumber ? stop.poNumber : '',
        };

        stopsArray.push(obj);
      });
      return stopsArray;
    },
    hideModal(carrier: any) {
      if (carrier === 0) {
        this.modalVisible = !this.modalVisible;
      } else {
        this.selectedContacts.push(carrier.company.id);

        this.modalVisible = !this.modalVisible;
      }
    },
    historicalRangeFilter() {
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.trailerTypeIdToSearch ? this.trailerTypeIdToSearch : null,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
        country: this.countriesString,
        tags: this.contactTag.length === 0 ? undefined : `[${this.contactTag}]`,
      });
    },
    isSelected(id) {
      return this.selectedContacts.includes(id);
    },
    loadMoreContacts() {
      this.showSpinner = true;
      this.useContactPage += 1;
      this.$store
        .dispatch('UserStore/carrierContactListStatsByLoad', {
          page: this.useContactPage,
          name: null,
          type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
          trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.trailerTypeIdToSearch ? this.trailerTypeIdToSearch : null,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
          country: this.countriesString,
          tags: this.contactTag.length === 0 ? undefined : `[${this.contactTag}]`,
        })
        .then(() => {
          this.showSpinner = false;
        });
    },
    nextRoute(route: string) {
      this.$router.push(route);
    },
    openChat(event, user) {
      event.stopPropagation();
      if (JSON.stringify(this.userForChat) !== JSON.stringify(user)) {
        this.$store.commit('GlobalStore/setOpenChat', false);
        setTimeout(() => {
          this.$store.commit('ChatsStore/setUserForChat', user);
          this.$store.commit('ChatsStore/setChatId', user.chat_id ? user.chat_id : null);
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    setCarrierContactListStatsByLoad() {
      this.$store
        .dispatch('UserStore/carrierContactListStatsByLoad', {
          page: this.useContactPage,
          name: null,
          type: 'CARRIER|BROKER',
          sortBy: null,
          trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.trailerTypeIdToSearch ? this.trailerTypeIdToSearch : null,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
          country: this.countriesString,
          tags: this.contactTag.length === 0 ? undefined : `[${this.contactTag}]`,
        })
        .then(() => {
          // Verifica que Cargobot esté en la lista de contactos (para el caso de los Broker)
          // Añade Cargobot a la lista de contactos seleccionados a mandar quoting

          if (this.sourceCarrier && this.sourceCarrier.length > 0) {
            if (!this.selectedContacts.some((item) => item === this.sourceCarrier[0].id)) {
              this.selectedContacts.push(this.sourceCarrier[0].id);
            }
          }
          this.$emit('updateSelectedContacts', this.selectedContacts);
          this.allowed = true;
        });
    },
    selectAll(isChecked: boolean) {
      if (isChecked) {
        let newIds = this.currentContacts
          .map((item) => {
            if (!this.isSelected(item.id)) return item.id;
          })
          .filter((id) => id !== undefined);
        this.selectedContacts = [...this.selectedContacts, ...newIds] as Array<number>;
      } else if (this.selectedContacts.length) {
        this.selectedContacts = [];
        if (this.sourceCarrier && this.sourceCarrier.length > 0) {
          if (!this.selectedContacts.some((item) => item === this.sourceCarrier[0].id)) {
            this.selectedContacts.push(this.sourceCarrier[0].id);
          }
        }
      }
      this.$emit('updateSelectedContacts', this.selectedContacts);
    },
    trailerTypeSearch(data: boolean) {
      if (data) {
        this.trailerTypeIdToSearch = this.load?.trailerType?.id;
      } else {
        this.trailerTypeIdToSearch = 0;
      }
    },
    togglePlansModal(bool: boolean) {
      this.showPlansModal = bool;
    },
    updateSearchByAverageBid() {
      this.carrierAdded = {} as User;
      let sortBy = '';

      this.isSortByName = false;
      this.isSortByRating = false;
      this.isSortByQuantity = false;
      this.isSortByAverage = true;

      if (this.searchByAverageBid === '') {
        this.searchByAverageBid = 'DESC';
        sortBy = 'averageBid[DESC]';
      } else {
        if (this.searchByAverageBid === 'DESC') {
          this.searchByAverageBid = 'ASC';
          sortBy = 'averageBid[ASC]';
        } else {
          this.searchByAverageBid = '';
          sortBy = '';
        }
      }
      // this.isSortBy();
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        sortBy: sortBy,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.trailerTypeIdToSearch ? this.trailerTypeIdToSearch : null,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
        country: this.countriesString,
        tags: this.contactTag.length === 0 ? undefined : `[${this.contactTag}]`,
      });
    },
    updateSearchByCompanyName() {
      this.carrierAdded = {} as User;
      let sortBy = '';

      this.isSortByName = true;
      this.isSortByRating = false;
      this.isSortByQuantity = false;
      this.isSortByAverage = false;

      if (this.searchByCompanyName === '') {
        this.searchByCompanyName = 'DESC';
        sortBy = 'companyName[DESC]';
      } else {
        if (this.searchByCompanyName === 'DESC') {
          this.searchByCompanyName = 'ASC';
          sortBy = 'companyName[ASC]';
        } else {
          this.searchByCompanyName = '';
          sortBy = '';
        }
      }
      // this.isSortBy();
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        sortBy: sortBy,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.trailerTypeIdToSearch ? this.trailerTypeIdToSearch : null,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
        country: this.countriesString,
        tags: this.contactTag.length === 0 ? undefined : `[${this.contactTag}]`,
      });
    },
    updateSearchByLoadsQuantity() {
      this.carrierAdded = {} as User;
      let sortBy = '';

      this.isSortByName = false;
      this.isSortByRating = false;
      this.isSortByQuantity = true;
      this.isSortByAverage = false;

      if (this.searchByLoadsQuantity === '') {
        this.searchByLoadsQuantity = 'DESC';
        sortBy = 'loadsQuantity[DESC]';
      } else {
        if (this.searchByLoadsQuantity === 'DESC') {
          this.searchByLoadsQuantity = 'ASC';
          sortBy = 'loadsQuantity[ASC]';
        } else {
          this.searchByLoadsQuantity = '';
          sortBy = '';
        }
      }
      // this.isSortBy();
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        sortBy: sortBy,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.trailerTypeIdToSearch ? this.trailerTypeIdToSearch : null,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
        country: this.countriesString,
        tags: this.contactTag.length === 0 ? undefined : `[${this.contactTag}]`,
      });
    },
    updateSearchByRating() {
      this.carrierAdded = {} as User;
      let sortBy = '';

      this.isSortByName = false;
      this.isSortByRating = true;
      this.isSortByQuantity = false;
      this.isSortByAverage = false;

      if (this.searchByRating === '') {
        this.searchByRating = 'DESC';
        sortBy = 'rating[DESC]';
      } else {
        if (this.searchByRating === 'DESC') {
          this.searchByRating = 'ASC';
          sortBy = 'rating[ASC]';
        } else {
          this.searchByRating = '';
          sortBy = '';
        }
      }
      // this.isSortBy();
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        sortBy: sortBy,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.trailerTypeIdToSearch ? this.trailerTypeIdToSearch : null,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
        country: this.countriesString,
        tags: this.contactTag.length === 0 ? undefined : `[${this.contactTag}]`,
      });
    },
    updateSearchTypeUser(userType: string) {
      this.carrierAdded = {} as User;

      if (userType === 'TRAILER') {
        this.isTrailerFiltered = !this.isTrailerFiltered;

        this.$store
          .dispatch('UserStore/carrierContactListStatsByLoad', {
            page: this.useContactPage,
            name: null,
            type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
            trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
            trailerTypeStats: this.trailerTypeIdToSearch ? this.trailerTypeIdToSearch : null,
            originZip: this.originZip,
            destinyZip: this.destinyZip,
            period: this.historicalRange,
            country: this.countriesString,
            tags: this.contactTag.length === 0 ? undefined : `[${this.contactTag}]`,
          })
          .then(() => {
            // Verifica que Cargobot esté en la lista de contactos (para el caso de los Broker)
            if (this.sourceCarrier && this.sourceCarrier.length > 0) {
              // Añade Cargobot a la lista de contactos seleccionados a mandar quoting
              if (!this.selectedContacts.some((item) => item === this.sourceCarrier[0].id)) {
                this.selectedContacts.push(this.sourceCarrier[0].id);
              }
            }
          });
      } else {
        let searchArray = this.userType.split('|');
        if (searchArray.includes(userType)) {
          searchArray = searchArray.filter((el) => el !== userType);
          this.userType = searchArray.join('|');
        } else {
          if (this.userType === '') {
            this.userType = userType;
          } else {
            this.userType += '|' + userType;
          }
        }
        this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
          page: this.useContactPage,
          name: null,
          type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
          trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.trailerTypeIdToSearch ? this.trailerTypeIdToSearch : null,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
          country: this.countriesString,
          tags: this.contactTag.length === 0 ? undefined : `[${this.contactTag}]`,
        });
      }
    },
    async validateForm(bookNow, isBookNow, isCheckedShowQuotes) {
      this.bookNow = bookNow;
      this.isBookNow = isBookNow;
      this.isCheckedShowQuotes = isCheckedShowQuotes;
      window.scrollTo({ top: 0, behavior: 'smooth' });

      let baseObject = {
        companies: this.selectedContacts.map((item) => ({ id: item })),
      };
      this.isSending = true;
      this.allowed = false;

      //CREATION OF THE TEMPLATE

      let copyload = this.deepClone(this.load);

      if (copyload.stops.length > 2) {
        copyload.stops.map((load) => {
          load.commodities = load.commodityMaster;
        });
      } else {
        copyload.stops.map((load) => {
          load.commodities = copyload.commodityMasters;
        });
      }

      copyload.commodityMasters.map((commodity) => {
        if (commodity.id) {
          delete commodity.id;
        }
      });
      copyload.commodityMaster = copyload.commodityMasters[0];

      await this.$store
        .dispatch('LoadsStore/createLoad', { load: copyload, isMultiple: this.allowMultipleLoad })
        .then(async (response) => {
          if (this.additionalDocuments.length > 0) {
            await Promise.all(
              this.additionalDocuments.map(async (file) => {
                await this.$store.dispatch('LoadsStore/postMediaObjectLoad', {
                  id: response.id,
                  file: file,
                  type: 'LOAD',
                });
              }),
            );
          }

          if (!this.isBillingCompanyEmpty) {
            let params: any = {
              billNameMaster: this.billingCompanyData.name,
              billLocationMaster: {
                address: this.billingCompanyData.address,
                city: this.billingCompanyData.city,
                zip: this.billingCompanyData.zip,
                state: { id: this.billingCompanyData.state },
              },
              billIdentificationNumberMaster: this.billingCompanyData.taxId,
              billContactEmailMaster: this.billingCompanyData.email,
            };

            await this.$store
              .dispatch('LoadsStore/putBillContactInfo', {
                id: response.id,
                params: params,
              })
              .then(() => {
                this.getUserInfo();
                BaseComponent.methods?.showToastSuccess(this.$t('message-consignee-validated'));
              })
              .catch((err) => {
                BaseComponent.methods?.showToastError(err?.response?.data?.detail);
              });
          }

          //CREATING THE BATCH OF THE TEMPLATE ONLY IF ITS MULTIPLE
          // DOING COMPETITIVE QUOTING AND BOOKNOW

          // Assign all the customers selected to the load
          const dataCustomersSelected = this.getDataCustomersSelected;
          if (dataCustomersSelected.length > 0) {
            let promises = [] as any;
            dataCustomersSelected.forEach((item) => {
              promises.push(
                this.$store.dispatch('LoadsStore/apiPostLoadCustomerAssign', {
                  id: response.id,
                  customerId: item.id,
                }),
              );
            });
            Promise.all(promises);
          }

          this.$store
            .dispatch('LoadsStore/setBookNowPrice', {
              id: response.id,
              params: { bookNowPrice: this.bookNow, bookNowEnable: this.isBookNow },
            })
            .catch((err) => {
              BaseComponent.methods?.showToastError(err?.response?.data?.title);
            });

          this.$store.dispatch('UserStore/showQuoteCarrier', {
            id: response.id,
            competitiveQuoting: this.isCheckedShowQuotes,
          });

          // CHECK IF ITS MULTIPLE LOAD

          if (this.allowMultipleLoad) {
            await this.$store
              .dispatch('LoadsStore/loadBatchesTemplate', {
                id: response.id,
                amount:
                  this.allowMultipleLoad && parseInt(this.totalLoadsMultiple) > 0
                    ? parseInt(this.totalLoadsMultiple)
                    : 1,
              })
              .then((response) => {
                // NOW CREATED A NEW ARRAY WITH ALL SHIPMENTS IDS

                const shipmentObjects = response.batch.map((item) => ({ id: item.shipment.id }));

                if (shipmentObjects) {
                  this.$store
                    .dispatch('LoadsStore/shipmentRequestBatch', {
                      companies: baseObject.companies,
                      shipments: shipmentObjects,
                    })
                    .then(async () => {
                      BaseComponent.methods?.showToastSuccess(
                        this.$t('multiple-loads-created', {
                          count: this.totalLoadsMultiple,
                        }),
                      );
                      this.$router.push('/planning');
                    })
                    .finally(() => {
                      this.$store.dispatch('LoadsStore/currentLoadEditing', null);
                      this.isSending = false;
                      this.allowed = true;
                      this.$emit('close', 'DATA');
                    });
                }
              });
          } else {
            this.$store
              .dispatch('LoadsStore/shipmentRequestBatch', {
                companies: baseObject.companies,
                shipments: [{ id: response.shipment.id }],
              })
              .then(async () => {
                this.$store.dispatch('LoadsStore/loadId', response.id);
                BaseComponent.methods?.showToastSuccess(this.$t('quote-send-correctly'));
                this.$store.dispatch('LoadsStore/showModalQuoteSended', true);

                this.$router.push('/planning');
              })
              .finally(() => {
                this.$store.dispatch('LoadsStore/currentLoadEditing', null);
                this.isSending = false;
                this.allowed = true;
                this.$emit('close', 'DATA');
              });
          }
        })
        .catch((err) => {
          if (err?.response?.status === 422) {
            BaseComponent.methods?.showToastError(this.$t('message-error-zip-code'));
          } else {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
          }
        });
    },
    getUserInfo() {
      this.$store.dispatch('UserStore/getUser', { id: this.currentUser.id }).then((response) => {
        this.$store.commit('UserStore/setBillingCompany', {
          name: '',
          email: '',
          taxId: '',
          country: '',
          state: '',
          city: '',
          address: '',
          zip: '',
        });
      });
    },
  },
});
