import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col items-center justify-center plan-gap-sm max-width-carrier-content" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center plan-gap-sm"
}
const _hoisted_3 = { class: "flex gap-2" }
const _hoisted_4 = {
  key: 1,
  class: "font-bold"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 1,
  class: "w-full flex flex-col items-center justify-center plan-gap-sm"
}
const _hoisted_8 = { class: "flex flex-col justify-center items-center plan-gap-sm" }
const _hoisted_9 = {
  key: 0,
  class: "flex justify-center items-center plan-gap-sm"
}
const _hoisted_10 = { class: "font-bold plan-t-h4" }
const _hoisted_11 = {
  key: 1,
  class: "flex justify-center items-center plan-gap-sm"
}
const _hoisted_12 = { class: "font-bold" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "w-full flex justify-center items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_formatted_number = _resolveComponent("plan-formatted-number")!
  const _component_plan_carrier_submit_bid_modal = _resolveComponent("plan-carrier-submit-bid-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.userBid === null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.loadData.competitiveQuoting && _ctx.loadData.shipment.bestBid)
              ? (_openBlock(), _createBlock(_component_plan_icon, {
                  key: 0,
                  name: "flame",
                  "icon-color": "#151830",
                  "is-svg": ""
                }))
              : _createCommentVNode("", true),
            (_ctx.loadData.competitiveQuoting)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                  (_ctx.loadData.shipment.bestBid)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                        _createTextVNode(_toDisplayString(_ctx.$t('best-quote')) + ": ", 1),
                        _createVNode(_component_plan_formatted_number, {
                          number: _ctx.loadData.shipment.bestBid,
                          unit: "currency"
                        }, null, 8, ["number"])
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('be-the-first')), 1))
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_plan_carrier_submit_bid_modal)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_ctx.userBid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("p", _hoisted_10, [
                    _createTextVNode(_toDisplayString(_ctx.$t('your-bid')) + ": ", 1),
                    _createVNode(_component_plan_formatted_number, {
                      number: _ctx.userBid.bid,
                      unit: "currency"
                    }, null, 8, ["number"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.loadData.competitiveQuoting)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_ctx.loadData.shipment.bestBid)
                    ? (_openBlock(), _createBlock(_component_plan_icon, {
                        key: 0,
                        name: "flame",
                        "icon-color": "#151830",
                        "is-svg": ""
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", _hoisted_12, [
                    (_ctx.loadData.shipment.bestBid)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                          _createTextVNode(_toDisplayString(_ctx.$t('best-quote')) + ": ", 1),
                          _createVNode(_component_plan_formatted_number, {
                            number: _ctx.loadData.shipment.bestBid,
                            unit: "currency"
                          }, null, 8, ["number"])
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t('be-the-first')), 1))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_plan_carrier_submit_bid_modal, {
              "button-type": "primary-outline",
              update: ""
            })
          ])
        ]))
  ]))
}