
import { defineComponent } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'PlanDefaultConfirmationModal',
  components: { PlanButton, PlanDefaultModal },
  props: {
    title: { type: String, required: true, default: '' },
    message: { type: String, required: false, default: '' },
    acceptKey: { type: String, required: false, default: 'accept' },
    loading: { type: Boolean, required: false, default: false },
    size: { type: String, required: false, default: 'xs-auto' }
  },
  emits: ['close', 'accept'],
  setup (props, { emit }) {
    const accept = () => { if (!props.loading) emit('accept') }

    const close = () => { if (!props.loading) emit('close') }

    return {
      accept,
      close
    }
  }
})
