
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'PlanSelectSimple',
  components: { PlanButton, PlanIcon },
  props: {
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: true, default: '' },
    align: { type: String, required: false, default: 'items-end' },
    items: { type: Array, required: true },
    value: { type: String, required: true },
    placement: { type: String, required: false, default: 'bottom' },
    lightStyle: { type: Boolean, required: false, default: false },
    defaultClassesOnly: { type: Boolean, required: false, default: false },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const data = reactive({
      selectedValue: '',
      showPopper: false,
      isHover: false,
    });

    if (props.value.length > 0 || props.value.toString().length > 0) {
      data.selectedValue = props.value + '';
    }

    const selectedLabel = computed(() => {
      let result = '';
      props.items.forEach(function (item: any) {
        if (data.selectedValue === item.value) result = item.label;
      });
      return result;
    });

    const changeValue = (newValue) => {
      data.selectedValue = newValue;
      emit('change', newValue);
    };

    watch(
      () => data.isHover,
      (newValue, oldValue) => {
        if (!data.isHover) data.showPopper = false;
      },
    );

    watch(
      () => props.value,
      (newFilters, oldFilters) => {
        data.selectedValue = props.value + '';
      },
    );

    return {
      ...toRefs(data),
      selectedLabel,
      changeValue,
    };
  },
});
