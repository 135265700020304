import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DriverContent = _resolveComponent("DriverContent")!
  const _component_DriverFooter = _resolveComponent("DriverFooter")!
  const _component_plan_default_carrier_view = _resolveComponent("plan-default-carrier-view")!

  return (_openBlock(), _createBlock(_component_plan_default_carrier_view, {
    "show-header": false,
    "show-subheader": false,
    "show-footer": true
  }, {
    content: _withCtx(() => [
      _createVNode(_component_DriverContent, {
        status: _ctx.status,
        file: _ctx.file,
        incidenceObject: _ctx.incidenceObject,
        onUpdateFile: _ctx.updateFile,
        onUpdateIncidence: _ctx.updateIncidence
      }, null, 8, ["status", "file", "incidenceObject", "onUpdateFile", "onUpdateIncidence"])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_DriverFooter, {
        status: _ctx.status,
        file: _ctx.file,
        incidenceObject: _ctx.incidenceObject,
        onUpdateStatus: _ctx.updateStatus
      }, null, 8, ["status", "file", "incidenceObject", "onUpdateStatus"])
    ]),
    _: 1
  }))
}