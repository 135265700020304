
import { defineComponent } from 'vue';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import CustomAgentBanner from '@/modules/planning/loadDetail/_components/CustomAgentBanner.vue';
import CustomAgentDocumentsModal from '@/modules/planning/loadDetail/_components/CustomAgentDocumentsModal.vue';
import LoadLogs from '@/components/loads/LoadLogs.vue';
import GoogleMap from '@/components/map/GoogleMap.vue';
import LoadDetails from '@/modules/planning/loadDetail/_components/LoadDetails.vue';
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import ModalLocationUpdate from './LocationUpdateModal.vue';
import ModalMailToCarrier from './MailToCarrierModal.vue';
import ShowIncidenceModal from '@/modules/planning/userProfile/ShowIncidenceModal.vue';
import PlanDefaultCityInformation from '@/components/defaults/PlanDefaultCityInformation.vue';
import UploadDocuments from '@/modules/carrier/modals/UploadDocuments.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanTrafficLight from '@/components/icons/PlanTrafficLight.vue';
import PlanRfpHistoryTracking from '@/components/rfp/PlanRfpHistoryTracking.vue';
import UpdatePositionModal from '@/modules/carrier/quotationRequest/_componentes/UpdatePositionModal.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
// Icons
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid';

interface ICourse {
  showUploadModal: boolean;
  // showModalSendLocation: boolean;
  loadSelectedForDocuments: number;
  truckLocation: string;
  showModalUpdate: boolean;
  showModalUpdateMail: boolean;
  showModalRequest: boolean;
  textRequestPosition: string;
  fetchedImageOriginal: any;
  showUploadDocumentsModal: boolean;
  loadSelected: any;
  showMap: boolean;
  stopSelected: any;
  isReSendingEmail: number;
  file: any;
  isUploadingFile: any;
  needUpdateTrackingHistory: boolean;
  showCustomAgentDocuments: boolean;
  legActionsLoading: boolean;
  notifyCrossborderPickupLoading: boolean;
}

export default defineComponent({
  name: 'ShipperLoadDetailCourse',
  components: {
    CustomAgentBanner,
    CustomAgentDocumentsModal,
    UpdatePositionModal,
    PlanAvatar,
    PlanButton,
    PlanButtonIcon,
    PlanTrafficLight,
    PlanRfpHistoryTracking,
    PlanDefaultCityInformation,
    GoogleMap,
    LoadDetails,
    ModalConfirm,
    ModalLocationUpdate,
    ModalMailToCarrier,
    ShowIncidenceModal,
    UploadDocuments,
    LoadLogs,
    ExclamationCircleIcon,
  },
  mixins: [BaseComponent, CountryMixins],
  data(): ICourse {
    return {
      showModalUpdateMail: false,
      showUploadModal: false,
      loadSelectedForDocuments: 0,
      truckLocation: '',
      showModalUpdate: false,
      showModalRequest: false,
      fetchedImageOriginal: null,
      textRequestPosition: this.$t('request-location-text'),
      showMap: true,
      stopSelected: null,
      showUploadDocumentsModal: false,
      loadSelected: null as any,
      isReSendingEmail: 0,
      file: null as any,
      isUploadingFile: false,
      needUpdateTrackingHistory: false,
      showCustomAgentDocuments: false,
      legActionsLoading: false,
      notifyCrossborderPickupLoading: false,
    };
  },
  computed: {
    isEurope(): boolean {
      const country = this.dataCountries?.find(
        (country) => this.currentUser?.company?.country?.id === country?.id,
      );
      return country?.region?.name === 'EUROPE';
    },
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    truncatedObservations() {
      const maxLength = 50;
      const observations = this.currentLoad.shipment.currentTracking.observations;
      return observations;
    },
    isCanceled(): boolean {
      return this.currentLoad.status === 'CANCELED';
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
    isCrossBorderLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad']?.legs?.length > 0;
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    orderedStops(): any {
      let load = this.currentLoad;
      if (load?.legs?.length > 0) {
        let stops = [] as any;
        load.legs
          .sort((a, b) => a.legOrder - b.legOrder)
          .forEach((leg) => {
            if (leg.legOrder === 2) {
              stops.push(leg);
            } else {
              stops.push(...leg.stops);
            }
          });
        return stops;
      }
      return load.stops;
    },
    userForChat(): any {
      return this.$store.state.ChatsStore.userForChat;
    },
    carrierRequestedBid(): any {
      return this.$store.getters['UserStore/requestBidStatsByLoad'];
    },
  },
  watch: {
    currentLoad() {
      if (this.currentLoad?.shipment?.currentTracking.id !== undefined) {
        this.showMap = false;
        setTimeout(() => {
          this.showMap = true;
        }, 5);
      }
    },
  },
  methods: {
    getTrafficLight(lightStatus) {
      let light = '';
      switch (lightStatus) {
        case 'CLEARED':
          light = 'green';
          break;
        case 'CROSSING_BORDER':
          light = '';
          break;
        case 'TRAILER_INSPECTION':
          light = 'yellow';
          break;
        case 'TRAILER_STOPPED':
          light = 'red';
          break;
      }
      return light;
    },
    clearTransfer(leg) {
      this.notifyCrossborderPickupLoading = true;
      this.$store
        .dispatch('LoadsStore/putStopLightStatus', {
          id: leg.id,
          status: 'CLEARED',
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('Transfer cleared'));
          this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
        })
        .finally(() => (this.notifyCrossborderPickupLoading = false));
    },
    setPaperWorkAsReady(stop) {
      this.legActionsLoading = true;
      this.$store
        .dispatch('CrossBorderStore/setPaperWorkAsReady', stop.id)
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('PaperWork setted as ready'));
          this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
        })
        .catch((err) => {
          // err.response.data.title
          BaseComponent.methods?.showToastError(err.response.data.title);
        })
        .finally(() => (this.legActionsLoading = false));
    },
    askForUpdate(stop) {
      this.legActionsLoading = true;
      this.$store
        .dispatch('CrossBorderStore/resendEmailRequestUpdateTransferStatus', stop.id)
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('notification-sended'));
          this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
        })
        .finally(() => (this.legActionsLoading = false));
    },
    remindCarrier(stop) {
      this.legActionsLoading = true;
      this.$store
        .dispatch('CrossBorderStore/resendEmailRequestCrossBorderNotifyPickup', stop.id)
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('notification-sended'));
          this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
        })
        .finally(() => (this.legActionsLoading = false));
    },
    notifyCrossBorderLoadPickedUp(stop) {
      this.notifyCrossborderPickupLoading = true;
      this.$store
        .dispatch('CrossBorderStore/notifyCrossBorderLoadPickedUp', stop.id)
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('notification-sended'));
          this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
        })
        .finally(() => (this.notifyCrossborderPickupLoading = false));
    },
    toggleCustomAgentDocumentsModal(bool: boolean) {
      this.showCustomAgentDocuments = bool;
    },
    truncateString(string) {
      if (string && string.length > 10) {
        return string.substring(0, 11) + '...';
      } else {
        return string;
      }
    },
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    fetch() {
      this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
    },
    getLegFromStop(stopInitial) {
      const objectToClone = this.currentLoad?.legs ? this.currentLoad?.legs : [];
      const defaultLoad = this.deepClone(objectToClone);
      if (defaultLoad.length > 0) {
        for (const leg of defaultLoad) {
          const load = leg.stops.some((stop) => stop.id === stopInitial.id);
          if (load) {
            return leg;
          }
        }
      }

      console.log('this.currentLoad.type: ', this.currentLoad.type);

      if (this.currentLoad.type !== 'USA_MEX_CROSS_COUNTRY') {
        return { shipment: { assignedCompany: true } };
      }
      return null;
    },
    handleUploadDocumentsModal(load) {
      this.loadSelected = load;
      this.showUploadDocumentsModal = true;
    },
    showAccessDenied() {
      BaseComponent.methods?.showToastError(this.$t('access-denied-no-action'));
    },
    resendEmails(id) {
      if (this.isSuperAdminRoute) {
        return;
      }
      this.isReSendingEmail = Number(id);
      this.$store
        .dispatch('UserStore/resendEmail', {
          id: id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('email-sent-correctly'));
          this.isReSendingEmail = 0;
          this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
        });
    },
    openModalIncidence(stop) {
      this.$store.dispatch('LoadsStore/stopSelected', stop);
      setTimeout(() => {
        this.$store.dispatch('LoadsStore/showIncidenceModal', true);
      }, 100);
    },
    getLastDocumentUploaded(index): any {
      const biggestObject = this.orderedStops[index].mediaObjects.reduce((acc, cur) => {
        return cur.id > acc.id ? cur : acc;
      });

      return biggestObject;
    },
    async download(stop, mediaObjectId, mediaObjectType) {
      this.handleUploadDocumentsModal(stop);
    },
    requestLocation() {
      if (this.isSuperAdminRoute) {
        return;
      }
      this.showModalRequest = true;
    },
    submitRequest(data: number) {
      if (!data) {
        this.showModalRequest = false;
      } else {
        this.$store
          .dispatch('ShipmentStore/requestPosition', this.currentLoad.shipment.id)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('requested-location'));
            this.showModalRequest = false;
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
            this.showModalRequest = false;
          });
      }
    },
    showIncidentsModal(load) {
      const result = this.currentLoad.stops.find((obj) => obj.sequence === +load.sequence);
      this.stopSelected = result;
      this.showUploadModal = true;
    },
    locationUpdate(location: any) {
      if (location !== null) {
        this.showMap = false;
        this.$store
          .dispatch('ShipmentStore/notifyPosition', location)
          .then(() => {
            this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id).then(() => {
              BaseComponent.methods?.showToastSuccess(
                this.$t('toast-new-location', { location: location.location.city }),
              );
              this.showModalUpdate = false;
              this.showMap = true;
            });
          })
          .catch((err) => {
            if (err?.response?.status === 422) {
              BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            } else {
              BaseComponent.methods?.showToastError(this.$t('message-location-not-correct'));
            }
            this.showModalUpdate = false;
          });
      } else {
        this.showModalUpdate = false;
      }
    },
    async addMemberToChatLoad(chatId) {
      await this.$store.dispatch('ChatsStore/addMemberToChatLoad', chatId);
    },
    checkCompanyId(currentBids, contact) {
      return currentBids.some((item) => item.company.id === contact);
    },
    async openChat(event, load) {
      const initialLoad = this.$store.getters['LoadsStore/currentLoad'];
      const member = load?.chat?.chatMembers.find(
        (member) => member.user.id === this.currentUser?.id,
      );
      if (member) {
        member.readed = true;
      } else {
        if (load.chat?.id) {
          await this.addMemberToChatLoad(load.chat.id);
        }
      }
      this.$store.commit('GlobalStore/setOpenChat', true);
      this.$store.commit('LoadsStore/loadId', initialLoad.id);
      this.$store.commit('ChatsStore/setChatId', load.chat ? load.chat.id : null);
    },
    isRead(load): any {
      const chat = load?.chat;
      const isTheUser = chat?.chatMembers?.some((member) => member.user.id === this.currentUser.id);
      const hasUnreadMessages = chat?.chatMembers?.some(
        (member) => member.readed === false && member.user.id === this.currentUser.id,
      );
      return hasUnreadMessages && isTheUser;
    },
  },
});
