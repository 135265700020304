import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_2 = { class: "w-full flex plan-gap-sm" }
const _hoisted_3 = { class: "w-full flex plan-gap-sm" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.close,
    title: _ctx.$t('include-custom-agent'),
    size: "md-auto",
    withoutSidebar: ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_plan_input_text, {
            "model-value": _ctx.name,
            "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            label: _ctx.$t('name'),
            mandatory: ""
          }, null, 8, ["model-value", "label"]),
          _createVNode(_component_plan_input_text, {
            "model-value": _ctx.surname,
            "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.surname) = $event)),
            label: _ctx.$t('surname'),
            mandatory: ""
          }, null, 8, ["model-value", "label"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_plan_select, {
              onChange: _ctx.changePrefix,
              value: _ctx.phonePrefix,
              items: _ctx.prefixes,
              label: _ctx.$t('prefix'),
              placeholder: "+1",
              "hide-right": "",
              mandatory: "",
              style: {"width":"115px"}
            }, null, 8, ["onChange", "value", "items", "label"]),
            _createVNode(_component_plan_input_text, {
              "model-value": _ctx.phone,
              "onUpdate:model-value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.phone) = $event)),
              label: _ctx.$t('phone'),
              mandatory: ""
            }, null, 8, ["model-value", "label"])
          ]),
          _createVNode(_component_plan_input_text, {
            "model-value": _ctx.email,
            "onUpdate:model-value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.email) = $event)),
            label: _ctx.$t('email'),
            mandatory: ""
          }, null, 8, ["model-value", "label"])
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.close,
          disabled: _ctx.loadingPost,
          type: "cancel",
          label: _ctx.$t('cancel')
        }, null, 8, ["onOnClick", "disabled", "label"]),
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.postAgent,
          disabled: !_ctx.validateAgent,
          loading: _ctx.loadingPost,
          type: "primary",
          label: _ctx.$t('save'),
          style: {"min-width":"100px"}
        }, null, 8, ["onOnClick", "disabled", "loading", "label"])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}