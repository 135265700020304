import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col bg-white rounded-xl p-5 xl:p-8 plan-gap-lg" }
const _hoisted_2 = { class: "w-[600px] flex" }
const _hoisted_3 = { class: "cursor-pointer truncate max-w-xs" }
const _hoisted_4 = { class: "flex items-center space-x-3" }
const _hoisted_5 = { class: "flex flex-col pr-4 w-32" }
const _hoisted_6 = { class: "font-bold text-corp truncate" }
const _hoisted_7 = { class: "text-sm text-gray-600 truncate max-w-[300px]" }
const _hoisted_8 = { class: "cursor-pointer" }
const _hoisted_9 = { class: "cursor-pointer" }
const _hoisted_10 = { class: "cursor-pointer" }
const _hoisted_11 = { class: "flex justify-end p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_carrier_modal = _resolveComponent("add-carrier-modal")!
  const _component_PlanLegsSelector = _resolveComponent("PlanLegsSelector")!
  const _component_PlanContactBanner = _resolveComponent("PlanContactBanner")!
  const _component_plan_header_search = _resolveComponent("plan-header-search")!
  const _component_plan_filter_contact_tag = _resolveComponent("plan-filter-contact-tag")!
  const _component_ContactsTabFilter = _resolveComponent("ContactsTabFilter")!
  const _component_plan_contact_tags = _resolveComponent("plan-contact-tags")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_flag = _resolveComponent("plan-flag")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_table = _resolveComponent("plan-default-table")!
  const _component_plan_loading = _resolveComponent("plan-loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.contactModalVisible)
      ? (_openBlock(), _createBlock(_component_add_carrier_modal, {
          key: 0,
          onFetch: _ctx.fetch,
          onHideModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.contactModalVisible = !_ctx.contactModalVisible))
        }, null, 8, ["onFetch"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isCrossBorderLoad)
        ? (_openBlock(), _createBlock(_component_PlanLegsSelector, {
            key: 0,
            loadTemplate: _ctx.getLoadToSend,
            onHandleChangeLeg: _ctx.handleChangeLeg
          }, null, 8, ["loadTemplate", "onHandleChangeLeg"]))
        : _createCommentVNode("", true),
      _createVNode(_component_PlanContactBanner, {
        onModalVisible: _cache[1] || (_cache[1] = ($event: any) => (_ctx.contactModalVisible = !_ctx.contactModalVisible))
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_plan_header_search, {
          "old-query": '',
          placeholder: _ctx.$t('search-company'),
          onChangeQuery: _ctx.handleChangeSearchByName
        }, null, 8, ["placeholder", "onChangeQuery"]),
        _createVNode(_component_plan_filter_contact_tag, { onChange: _ctx.changeContactTag }, null, 8, ["onChange"])
      ]),
      _createVNode(_component_ContactsTabFilter, {
        onUpdateSearchTypeUser: _ctx.updateSearchTypeUser,
        onChangeSelectedPermits: _ctx.changeSelectedPermits,
        onChangePeriod: _ctx.changePeriod,
        onFilterByCountryTypeUser: _ctx.filterByCountryTypeUser,
        load: _ctx.loadSelected,
        "trailer-selected": _ctx.trailerSelected,
        showHistoricalRange: true,
        isCrossBorderLoad: _ctx.isCrossBorderLoad
      }, null, 8, ["onUpdateSearchTypeUser", "onChangeSelectedPermits", "onChangePeriod", "onFilterByCountryTypeUser", "load", "trailer-selected", "isCrossBorderLoad"]),
      _createVNode(_component_plan_default_table, {
        order: _ctx.order.value,
        "order-direction": _ctx.order.direction,
        items: _ctx.contacts,
        onChangeOrder: _ctx.fetchOrder,
        headers: _ctx.headers,
        style: {"background":"white"}
      }, {
        default: _withCtx((props) => [
          _createElementVNode("td", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", {
                class: _normalizeClass(["w-10 h-10 p-2.5 flex-col justify-center items-center rounded-[100px] text-center text-white border border-white gap-2.5 inline-flex", _ctx.getUserDefaultImage(props.item?.name)?.color])
              }, _toDisplayString(_ctx.getUserDefaultImage(props.item?.name)?.name), 3),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(props.item?.name), 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(props.item?.owner_name) + " " + _toDisplayString(props.item?.owner_surname), 1),
                _createVNode(_component_plan_contact_tags, {
                  "company-id": props.item.id,
                  "actual-tags": props.item.tags,
                  "only-label": ""
                }, null, 8, ["company-id", "actual-tags"])
              ]),
              _createVNode(_component_plan_button_icon, {
                onOnClick: _ctx.openChat,
                icon: "chat",
                type: "transparent-gray-800"
              }, null, 8, ["onOnClick"]),
              (props?.item?.country_iso)
                ? (_openBlock(), _createBlock(_component_plan_flag, {
                    key: 0,
                    country: props?.item?.country_iso?.toLowerCase(),
                    width: 41,
                    height: 23
                  }, null, 8, ["country"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("td", _hoisted_8, _toDisplayString(props.item?.stats_amountvalorations), 1),
          _createElementVNode("td", _hoisted_9, _toDisplayString(props.item?.type == 'CARRIER' || props.item?.type == 'BROKER'
              ? _ctx.$t(`${props.item?.type.toLowerCase()}`).toUpperCase()
              : props.item?.type), 1),
          _createElementVNode("td", _hoisted_10, _toDisplayString(props.item?.stats_shipmentamount), 1),
          _createElementVNode("td", null, [
            _createElementVNode("div", _hoisted_11, [
              (_ctx.checkContactSelected(props.item.id))
                ? (_openBlock(), _createBlock(_component_plan_button, {
                    key: 0,
                    "show-icon": "",
                    class: "w-full",
                    icon: "circle-check",
                    iconColor: "#00DB50",
                    "icon-type": "svg",
                    type: "success-outline",
                    label: _ctx.$t('selected'),
                    onOnClick: ($event: any) => (_ctx.unselectContact(props.item))
                  }, null, 8, ["label", "onOnClick"]))
                : (_openBlock(), _createBlock(_component_plan_button, {
                    key: 1,
                    class: "w-full",
                    type: "primary",
                    label: _ctx.$t('select'),
                    onOnClick: ($event: any) => (_ctx.changeSelectContact(props.item))
                  }, null, 8, ["label", "onOnClick"]))
            ])
          ])
        ]),
        _: 1
      }, 8, ["order", "order-direction", "items", "onChangeOrder", "headers"]),
      (!_ctx.loadingContacts && _ctx.contacts.length > 0)
        ? (_openBlock(), _createBlock(_component_plan_button, {
            key: 1,
            onOnClick: _ctx.loadMoreContacts,
            loading: _ctx.loadingContacts,
            type: "primary-outline",
            "full-rounded": "",
            label: _ctx.$t('show-more-contacts'),
            style: {"width":"328px"}
          }, null, 8, ["onOnClick", "loading", "label"]))
        : _createCommentVNode("", true),
      (_ctx.loadingContacts)
        ? (_openBlock(), _createBlock(_component_plan_loading, {
            key: 2,
            class: "plan-t-prim-100"
          }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}