
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

interface planLoadFormSelectDelivery {
  selectedDelivery: string,
  selectedDeliveries: Array<any>
}

export default defineComponent({
  name: 'PlanLoadFormSelectDelivery',
  components: { PlanIcon, PlanSelect },
  props: {
    index: { type: Number, required: true, default: -1 },
    possibleDeliveries: { type: Array, required: true }
  },
  emits: ['change'],
  setup (props, {emit}) {
    const data = reactive<planLoadFormSelectDelivery>({
      selectedDelivery: '',
      selectedDeliveries: []
    })

    const addDelivery = (deliveryToAdd: any) => {
      if (!data.selectedDeliveries.includes(deliveryToAdd)) data.selectedDeliveries.push(deliveryToAdd)
      data.selectedDelivery = ''
      update()
    }

    const deliveries = computed(() => {
      let result: Array<any> = []
      props.possibleDeliveries.forEach((delivery: any) => {
        result.push({
          label: `${delivery.stopData.location} (${delivery.date})`,
          value: delivery.index
        })
      })
      return result
    })

    const removeSelected = (item: any) => {
      data.selectedDeliveries.splice(data.selectedDeliveries.indexOf(item.index), 1)
      update()
    }

    const selectedDeliveriesToShow = computed(() => {
      let result: Array<any> = []
      data.selectedDeliveries.forEach((item: any) => {
        props.possibleDeliveries.forEach((delivery: any) => {
          if (delivery.index == item) {
            result.push({
              location: delivery.stopData.location,
              date: delivery.date,
              index: delivery.index
            })
          }
        })
      })
      return result
    })

    const update = () => {
      emit('change', {
        index: props.index,
        selectedDeliveries: data.selectedDeliveries
      })
    }

    return {
      ...toRefs(data),
      addDelivery,
      deliveries,
      removeSelected,
      selectedDeliveriesToShow,
      update
    }
  }
})
