
import { computed, defineComponent, onBeforeMount, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'PlanContactBanner',
  emit: ['modalVisible'],
  components: { PlanButton, PlanIcon },
  props: {
    newInterface: { type: Boolean, required: false, default: false }
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();
    const router = useRouter();
    const data = reactive({});

    const currentContacts = computed(() => {
      return store.getters['UserStore/carrierContactListStatsByLoad'];
    });

    return {
      ...toRefs(data),
      currentContacts,
    };
  },
});
