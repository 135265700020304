
import { defineComponent } from 'vue';
import SidebarUser from '@/components/sidebar/SidebarUser.vue';
import SidebarButton from '@/components/sidebar/SidebarButton.vue';
import * as Routing from '@/router/Routing';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'SidebarContentInMobile',
  components: { PlanButton, SidebarButton, SidebarUser },
  props: {
    isDevPreEnv: { type: Boolean, required: true, default: false },
    isTrialPremUser: { type: Boolean, required: true, default: false },
    totalUnreadChats: { type: Boolean, required: true, default: false },
    isSuperAdmin: { type: Boolean, required: true, default: false },
  },
  emits: ['closeSidebarContentMobile'],
  setup(props, { emit }) {
    const router = useRouter()
    const goTo = (routeName: string) => {
      if (routeName === 'planning') Routing.openPlanning();
      if (routeName === 'lanesRfp') Routing.openLanesRfp();
      if (routeName === 'contacts') Routing.openContacts();
      if (routeName === 'messages') Routing.openUnreadMessages();
      if (routeName === 'shippersList') Routing.openShippersList();
      if (routeName === 'loadsList') Routing.openLoadsList();
      if (routeName === 'requestDemoList') Routing.openRequestDemoList();
      if (routeName === 'performance') Routing.openShipperDashboard();
      if (routeName === 'superAdminDashboard') Routing.openSuperAdminDashboard();
      if (routeName === 'warehouses') Routing.openWarehousesList();
      if (routeName === 'InstantQuote') router.push({ name: 'InstantQuote' })
      emit('closeSidebarContentMobile');
    };

    return {
      goTo,
      Routing,
    };
  },
});
