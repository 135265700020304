import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-lg plan-rounded-xs plan-p-md plan-gap-md bg-white" }
const _hoisted_2 = { class: "w-full flex flex-col plan-gap-md" }
const _hoisted_3 = { class: "flex justify-start items-center plan-gap-md" }
const _hoisted_4 = { class: "font-bold plan-t-h2 plan-t-prim-100" }
const _hoisted_5 = { class: "font-bold plan-t-h2 plan-t-prim-100" }
const _hoisted_6 = { class: "font-bold plan-t-h2" }
const _hoisted_7 = { class: "w-full overflow-x-auto" }
const _hoisted_8 = { class: "w-full flex flex-col plan-gap-md" }
const _hoisted_9 = {
  key: 1,
  class: "w-full flex flex-col plan-gap-sm plan-rounded-sm border plan-border-prim-50 plan-p-sm"
}
const _hoisted_10 = { class: "flex flex-col" }
const _hoisted_11 = { class: "font-bold plan-t-prim-200" }
const _hoisted_12 = { class: "plan-t-xxs plan-t-prim-100" }
const _hoisted_13 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_14 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_15 = { class: "w-full flex justify-between items-center plan-gap-sm" }
const _hoisted_16 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_17 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_18 = {
  class: "flex flex-col",
  style: {"min-width":"210px","max-width":"210px"}
}
const _hoisted_19 = { class: "flex justify-end items-end" }
const _hoisted_20 = { class: "flex justify-center items-center plan-mt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_add_carrier_modal = _resolveComponent("add-carrier-modal")!
  const _component_plan_contact_banner = _resolveComponent("plan-contact-banner")!
  const _component_plan_header_search = _resolveComponent("plan-header-search")!
  const _component_plan_filter_contact_tag = _resolveComponent("plan-filter-contact-tag")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_contacts_tab_filter = _resolveComponent("contacts-tab-filter")!
  const _component_plan_avatar = _resolveComponent("plan-avatar")!
  const _component_plan_contact_tags = _resolveComponent("plan-contact-tags")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_flag = _resolveComponent("plan-flag")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_table = _resolveComponent("plan-default-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeView('shipment'))),
          class: "flex justify-center items-center plan-gap-sm cursor-pointer"
        }, [
          _createElementVNode("p", _hoisted_4, "1. " + _toDisplayString(_ctx.$t('shipment-data')), 1),
          _createVNode(_component_plan_icon, {
            name: "fill-circle-check",
            "icon-color": "#8A8B97",
            "is-svg": ""
          })
        ]),
        (_ctx.additionalStops.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeView('freight'))),
              class: "flex justify-center items-center plan-gap-sm cursor-pointer"
            }, [
              _createElementVNode("p", _hoisted_5, " 2. " + _toDisplayString(_ctx.$t('freight-info')), 1),
              _createVNode(_component_plan_icon, {
                name: "fill-circle-check",
                "icon-color": "#8A8B97",
                "is-svg": ""
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_6, _toDisplayString(`${_ctx.additionalStops.length > 0 ? 3 : 2}`) + ". " + _toDisplayString(_ctx.$t('quote')), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          (_ctx.showAddContact)
            ? (_openBlock(), _createBlock(_component_add_carrier_modal, {
                key: 0,
                onSetCompanySearch: _ctx.addNewCompany,
                onHideModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAddContact = false))
              }, null, 8, ["onSetCompanySearch"]))
            : _createCommentVNode("", true),
          _createVNode(_component_plan_contact_banner, {
            onModalVisible: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showAddContact = true)),
            newInterface: ""
          }),
          (_ctx.newCompanies.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('new-contacts')), 1),
                  _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('new-contacts-selected-in-load')), 1)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newCompanies, (company) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: company,
                      class: "plan-t-xxs font-semibold rounded-full border plan-border-sec-100 plan-b-sec-100 plan-pl-sm plan-pr-sm plan-pt-xs plan-pb-xs"
                    }, _toDisplayString(company.name), 1))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_plan_header_search, {
                  onChangeQuery: _ctx.changeSearchName,
                  "old-query": '',
                  placeholder: _ctx.$t('search-company')
                }, null, 8, ["onChangeQuery", "placeholder"]),
                _createVNode(_component_plan_filter_contact_tag, { onChange: _ctx.changeContactTag }, null, 8, ["onChange"])
              ]),
              _createElementVNode("div", null, [
                (_ctx.loadingContacts)
                  ? (_openBlock(), _createBlock(_component_plan_loading, {
                      key: 0,
                      small: ""
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createVNode(_component_contacts_tab_filter, {
              onUpdateSearchTypeUser: _ctx.changeSearchTypeUser,
              onChangeSelectedPermits: _ctx.changeSelectedPermits,
              onChangePeriod: _ctx.changePeriod,
              load: _ctx.loadSelected,
              "trailer-selected": {id: _ctx.searchQuery.trailerType, description: _ctx.trailerLabel },
              showHistoricalRange: true,
              isCrossBorderLoad: false
            }, null, 8, ["onUpdateSearchTypeUser", "onChangeSelectedPermits", "onChangePeriod", "load", "trailer-selected"])
          ])
        ]),
        _createVNode(_component_plan_default_table, {
          onChangeOrder: _ctx.changeContactsOrder,
          items: _ctx.contacts,
          headers: _ctx.headers,
          order: _ctx.order.value,
          "order-direction": _ctx.order.direction
        }, {
          default: _withCtx((props) => [
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_plan_avatar, {
                    "max-characters": 23,
                    "user-name": props.item?.name,
                    description: `${props.item?.owner_name} ${props.item?.owner_surname}`
                  }, null, 8, ["user-name", "description"]),
                  _createVNode(_component_plan_contact_tags, {
                    "company-id": props.item.id,
                    "actual-tags": props.item.tags,
                    "only-label": ""
                  }, null, 8, ["company-id", "actual-tags"])
                ]),
                _createVNode(_component_plan_button_icon, {
                  onOnClick: ($event: any) => (_ctx.openChat(props.item)),
                  icon: "chat",
                  type: "transparent-gray-800"
                }, null, 8, ["onOnClick"]),
                (props?.item?.country_iso)
                  ? (_openBlock(), _createBlock(_component_plan_flag, {
                      key: 0,
                      country: props?.item?.country_iso?.toLowerCase(),
                      width: 30,
                      height: 17
                    }, null, 8, ["country"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("td", null, _toDisplayString(props.item?.stats_amountvalorations), 1),
            _createElementVNode("td", null, _toDisplayString(props.item?.type == 'CARRIER' || props.item?.type == 'BROKER'
                  ? _ctx.$t(`${props.item?.type.toLowerCase()}`).toUpperCase()
                  : props.item?.type), 1),
            _createElementVNode("td", null, _toDisplayString(props.item?.stats_shipmentamount), 1),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_19, [
                (_ctx.selectedCompanies.includes(props.item.id))
                  ? (_openBlock(), _createBlock(_component_plan_button, {
                      key: 0,
                      onOnClick: ($event: any) => (_ctx.removeCompany(props.item.id)),
                      "show-icon": "",
                      icon: "circle-check",
                      iconColor: "#00DB50",
                      "icon-type": "svg",
                      type: "success-outline",
                      label: _ctx.$t('selected'),
                      style: {"width":"175px"}
                    }, null, 8, ["onOnClick", "label"]))
                  : (_openBlock(), _createBlock(_component_plan_button, {
                      key: 1,
                      onOnClick: ($event: any) => (_ctx.addCompany(props.item.id)),
                      type: "primary",
                      label: _ctx.$t('select'),
                      style: {"width":"175px"}
                    }, null, 8, ["onOnClick", "label"]))
              ])
            ])
          ]),
          _: 1
        }, 8, ["onChangeOrder", "items", "headers", "order", "order-direction"]),
        _createElementVNode("div", _hoisted_20, [
          (_ctx.contacts.length > 0 && _ctx.showMoreContactsButtons)
            ? (_openBlock(), _createBlock(_component_plan_button, {
                key: 0,
                onOnClick: _ctx.loadMoreContacts,
                loading: _ctx.loadingContacts,
                type: "primary-outline",
                "full-rounded": "",
                label: _ctx.$t('show-more-contacts'),
                style: {"width":"328px"},
                "icon-color": "#151830"
              }, null, 8, ["onOnClick", "loading", "label"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}