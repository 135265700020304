
import { computed, defineComponent, nextTick, reactive, ref, toRefs } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import { useStore } from 'vuex';
import store from '@/services/store';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanFormattedNumber from '@/components/labels/PlanFormattedNumber.vue';
import { useI18n } from 'vue-i18n';

interface planCarrierSubmitBidModal {
  bid: number | null;
  isInputOnFocus: boolean;
  loadingAcceptBookNow: boolean;
  loadingSendRequest: boolean;
  showSubmitBidModal: boolean;
}

export default defineComponent({
  name: 'PlanCarrierSubmitBidModal',
  components: { PlanFormattedNumber, PlanIcon, PlanCarrierDefaultModal, PlanButton },
  props: {
    buttonType: { type: String, required: false, default: 'primary' },
    update: { type: Boolean, required: false, default: false },
  },
  setup() {
    const store = useStore();
    const translate = useI18n();
    const inputRef = ref<HTMLInputElement | null>(null);

    const data = reactive<planCarrierSubmitBidModal>({
      bid: null,
      isInputOnFocus: false,
      loadingAcceptBookNow: false,
      loadingSendRequest: false,
      showSubmitBidModal: false,
    });

    if (store.getters['CarrierStore/getUserBid']) {
      data.bid = store.getters['CarrierStore/getUserBid'].bid;
    }

    const acceptBookNow = () => {
      data.loadingAcceptBookNow = true;
      store
        .dispatch(
          'ShipmentStore/acceptBookNow',
          store.getters['CarrierStore/getLoadData'].shipment.id,
        )
        .then(() => {
          getLoadData();
          data.loadingAcceptBookNow = false;
          store.dispatch('UserStore/updateToast', {
            text: translate.t('success-bid-sending'),
            type: 'success',
            visible: true,
          });
        });
    };

    const bookNow = computed(() => {
      let status = false;
      if (store.getters['CarrierStore/getLoadData'].bookNowEnable) {
        const locale = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es';
        const currency = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'USD' : 'EUR';

        return {
          enabled: true,
          price: store.getters['CarrierStore/getLoadData']?.bookNowPrice?.toLocaleString(locale, {
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            currency: currency,
          }),
        };
      } else {
        return {
          enabled: false,
          price: '',
        };
      }
    });

    const changeBid = () => {
      data.isInputOnFocus = false;
    };

    const close = () => {
      if (!data.loadingSendRequest) data.showSubmitBidModal = false;
    };

    const focusInput = () => {
      data.isInputOnFocus = true;
      nextTick(() => {
        if (inputRef.value) {
          inputRef.value.focus();
        }
      });
    };

    const getLoadData = async () => {
      await store.dispatch(
        'CarrierStore/getLoadInfoForCarrier',
        store.getters['CarrierStore/getLoadData'].id,
      );
    };

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const openModal = () => {
      data.showSubmitBidModal = true;
      nextTick(() => {
        focusInput();
      });
    };

    const getFormattedValue = computed(() => {
      const locale = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es';
      const currency = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'USD' : 'EUR';

      let result = '';

      if (data.bid) {
        result = data.bid.toLocaleString(locale, {
          style: 'currency',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          currency: currency,
        });

        return result;
      } else {
        const returnZero = 0;
        return returnZero.toLocaleString(locale, {
          style: 'currency',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          currency: currency,
        });
      }
    });

    const pricePerDistance = computed(() => {
      const locale = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es';
      const currency = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'USD' : 'EUR';
      let distance = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'mi' : 'km';
      if (store.getters['CarrierStore/getLoadData'].type === 'MEX_DOMESTIC') distance = 'km';
      let result = '';

      if (data.bid && data.bid > 0 && loadData.value.totalDistance === 0) {
        const ppdFormatted = data.bid.toLocaleString(locale, {
          style: 'currency',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          currency: currency,
        });
      }

      if (data.bid && data.bid > 0) {
        const ppd = data.bid / loadData.value.totalDistance;
        const ppdFormatted = ppd.toLocaleString(locale, {
          style: 'currency',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          currency: currency,
        });

        return `${ppdFormatted} / ${distance}`;
      }

      return result;
    });

    const sendRequest = () => {
      data.loadingSendRequest = true;
      store
        .dispatch('ShipmentStore/requestBid', {
          shipment: { id: store.getters['CarrierStore/getLoadData'].shipment.id },
          bid: data.bid,
        })
        .then((response) => {
          getLoadData();
          data.showSubmitBidModal = false;
          store.dispatch('UserStore/updateToast', {
            text: translate.t('success-bid-sending'),
            type: 'success',
            visible: true,
          });
        })
        .finally(() => {
          data.loadingSendRequest = false;
        });
    };

    const updateRequest = () => {
      data.loadingSendRequest = true;
      store
        .dispatch('ShipmentStore/updateBid', {
          id: store.getters['CarrierStore/getUserBid'].id,
          bid: data.bid,
        })
        .then((response) => {
          getLoadData();
          data.showSubmitBidModal = false;
          store.dispatch('UserStore/updateToast', {
            text: translate.t('success-bid-sending'),
            type: 'success',
            visible: true,
          });
        })
        .finally(() => {
          data.loadingSendRequest = false;
        });
    };

    const userBid = computed(() => {
      return store.getters['CarrierStore/getUserBid'];
    });

    return {
      ...toRefs(data),
      inputRef,
      acceptBookNow,
      bookNow,
      changeBid,
      close,
      openModal,
      focusInput,
      getFormattedValue,
      loadData,
      pricePerDistance,
      sendRequest,
      updateRequest,
      userBid,
    };
  },
});
