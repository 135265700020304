
import { computed, defineComponent, reactive, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanInputTextArea from '@/components/forms/PlanTextArea.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import { useI18n } from 'vue-i18n';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';

interface planAddAdditionalFee {
  loading: boolean;
  showModal: boolean;
  feeValue: null | number;
  feeExplanation: string;
  observations: string;
}

export default defineComponent({
  name: 'AddAdditionalFeesModalShipper',
  components: { PlanDefaultModal, PlanInputFile, PlanInputTextArea, PlanInputNumber, PlanButton },
  emits: ['close'],
  setup(props, { emit }) {
    const files = ref<File[]>([]);
    const store = useStore();
    const translate = useI18n();

    const getAdditionalFees = async () => {
      await store.dispatch('LoadsStore/getFeeExplanations');
    };

    const data = reactive<planAddAdditionalFee>({
      loading: false,
      showModal: false,
      feeValue: null,
      feeExplanation: '',
      observations: '',
    });

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const changeFiles = (newFiles: File[]) => {
      files.value = newFiles;
    };

    const closeModal = () => {
      if (!data.loading) {
        data.showModal = false;
        emit('close');
      }
    };

    const currentLegIndex = computed(() => {
      return store.getters['LoadDetailStore/getCurrentLegIndex'];
    });

    const currentLoad = computed(() => {
      let load = store.getters['LoadsStore/currentLoad'];
      if (load?.legs) {
        load.legs = load?.legs?.sort((a, b) => a.legOrder - b.legOrder);
      }
      return load?.legs?.length > 0 ? load.legs[currentLegIndex.value] : load;
    });

    const feeExplanationItems = computed(() => {
      let result: Array<any> = [];
      store.getters['LoadsStore/getFeeExplanations'].forEach(function (feeExplanation: any) {
        result.push({
          label: feeExplanation.name,
          value: feeExplanation.id,
        });
      });

      return result;
    });

    if (!feeExplanationItems.value) {
      getAdditionalFees();
    }

    const currentCountry = computed(() => {
      return store.getters['CountryStore/getCurrentCountry'];
    });

    const isEurope = computed(() => {
      return store.getters['UserStore/getRegion'] === 'EUROPE';
    });

    function formatPrice(number: number) {
      const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
      const currency = store.getters['CountryStore/getCountryCurrency'];
      const options = {
        style: 'currency',
        currency: currency || 'USD',
        maximumFractionDigits: 0,
        useGrouping: true,
      };
      return new Intl.NumberFormat(locale, options).format(number);
    }

    const getFeeTranslation = (feeType) => {
      let label = feeType.replace(/[-\s]/g, '').toLowerCase();
      return translate.t(`additional-fees-types.${label}`);
    };

    const openModal = () => {
      data.showModal = true;
      data.feeValue = null;
      data.feeExplanation = '';
      data.observations = '';
      files.value = [];
    };

    const postAdditionalFee = async () => {
      data.loading = true;
      let additionalFeeId = -1;
      await store
        .dispatch('LoadsStore/postCreateAdditionalFee', {
          shipment: currentLoad.value.shipment.id,
          feeExplanation: { id: data.feeExplanation },
          description: data.observations,
          value: data.feeValue,
          status: 'STATUS_APPROVED',
          type: 'TYPE_SHIPPER',
        })
        .then((response) => {
          additionalFeeId = response.id;
        });

      if (files.value.length > 0) await postAdditionalFeeFile(additionalFeeId);
      await store.dispatch('LoadsStore/getShipmentFees', currentLoad.value.shipment.id);
      await store.dispatch('UserStore/updateToast', {
        text: translate.t('success-post-create-additional-fee'),
        type: 'success',
        visible: true,
      });
      data.loading = false;
      closeModal();
    };

    const postAdditionalFeeFile = async (additionalFeeId: any) => {
      for (const file of files.value) {
        const formData = new FormData();
        formData.append('entityId', additionalFeeId.toString());
        formData.append('file', file);
        await store.dispatch('LoadsStore/postCreateAdditionalFeeMedia', formData);
      }
    };

    return {
      ...toRefs(data),
      actualWidth,
      changeFiles,
      closeModal,
      feeExplanationItems,
      formatPrice,
      getFeeTranslation,
      isEurope,
      currentCountry,
      currentLoad,
      openModal,
      postAdditionalFee,
    };
  },
});
