import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  value: null,
  disabled: "",
  selected: ""
}
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_number = _resolveComponent("plan-input-number")!
  const _component_plan_input_text_area = _resolveComponent("plan-input-text-area")!
  const _component_plan_input_file = _resolveComponent("plan-input-file")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.closeModal,
    title: _ctx.$t('report-additional-fee'),
    "hide-close-button": "",
    "without-sidebar": "",
    size: "sm-auto"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_plan_input_number, {
          "model-value": _ctx.feeValue,
          "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.feeValue) = $event)),
          label: _ctx.$t('fee'),
          placeholder: _ctx.formatPrice(0),
          currency: "",
          mandatory: "",
          "hide-validations": ""
        }, null, 8, ["model-value", "label", "placeholder"]),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.feeExplanation) = $event)),
          value: _ctx.feeExplanation,
          class: "ml-1 plan-form"
        }, [
          _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.$t('select-fee-explanation')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feeExplanationItems, (feeExplanationOption) => {
            return (_openBlock(), _createElementBlock("option", {
              key: feeExplanationOption.value,
              value: feeExplanationOption.value
            }, _toDisplayString(_ctx.getFeeTranslation(feeExplanationOption.label)), 9, _hoisted_4))
          }), 128))
        ], 8, _hoisted_2), [
          [_vModelSelect, _ctx.feeExplanation]
        ]),
        _createVNode(_component_plan_input_text_area, {
          "model-value": _ctx.observations,
          "onUpdate:model-value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.observations) = $event)),
          placeholder: _ctx.$t('comments'),
          rows: 4
        }, null, 8, ["model-value", "placeholder"]),
        _createVNode(_component_plan_input_file, {
          onChange: _ctx.changeFiles,
          types: ['application/pdf', 'image/*'],
          maxSize: 10,
          multiple: ""
        }, null, 8, ["onChange"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.closeModal,
          disabled: _ctx.loading,
          type: "cancel",
          label: _ctx.$t('cancel')
        }, null, 8, ["onOnClick", "disabled", "label"]),
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.postAdditionalFee,
          loading: _ctx.loading,
          disabled: _ctx.feeValue <= 0 || _ctx.feeExplanation.length === 0,
          type: "primary",
          label: _ctx.$t('send'),
          style: {"min-width":"100px"}
        }, null, 8, ["onOnClick", "loading", "disabled", "label"])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}