// veelidate define rules (https://vee-validate.logaretm.com/v4/guide/global-validators/)
import { defineRule, configure } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
// Resources
import i18n from '@/resources/locales';
// Utils
import { formatDateStringReverse } from '@/utils/formatDates';

const $t = i18n.global.t;
defineRule('requiredIPackagefNegativeOne', (value) => {
  if (value === -1) {
    return $t('required');
  }
  return true;
});

defineRule('required_short', (value) => {
  if (!value) {
    return $t('required');
  }
  return true;
});

defineRule('required_valid_number', (value) => {
  if (!value && value !== 0) {
    return $t('required');
  }

  if (!/^-?\d*\.?\d+$/.test(value)) {
    return $t('required');
  }

  return true;
});

defineRule('timeRequired', (time) => {
  if (!time || time.length === 0) {
    return $t('required-field');
  }
  // for (const key in time) {
  //   if (Object.hasOwnProperty.call(time, key) && time[key] === '') {
  //     return $t('invalid-time-format');
  //   }
  // }
  return true;
});

// Dates must be passed like strings
defineRule('dateBetween', (value: any, [after, before]) => {
  value = formatDateStringReverse(value);
  const start = Date.parse(after);
  const end = Date.parse(before);
  const d = Date.parse(value);

  if (d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf()) {
    return true;
  } else {
    return $t('invalid-date-range', { after, before });
  }
});

Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

// configure({
//   generateMessage: localize({
//     en,
//     es,
//   }),
// });
configure({
  generateMessage: localize({
    en: {
      messages: {
        alpha_spaces: 'This field may only contain alphabetic characters',
        required: 'This field is required',
        email: 'Enter a valid email',
        min_value: 'This field must be 0:{min} or more',
        max_value: 'This field may not be greater than 0:{length}',
        min: 'This field must be at least 0:{length} characters',
        max: 'This field must be 0:{min} or less characters',
        numeric: 'This field may only contain numeric characters',
        size: 'The file size must be less than 0:{size}KB',
        url: 'Invalid format url',
      },
    },
    es: {
      messages: {
        alpha_spaces: 'Este campo solo debe contener letras',
        required: 'Este campo es requerido',
        email: 'Introduce un correo válido',
        min_value: 'Este campo debe ser 0:{min} o superior',
        max_value: 'Este campo debe ser 0:{min} o inferior',
        min: 'Este campo debe tener al menos 0:{min} caracteres',
        max: 'Este campo debe tener menos de 0:{min} carecteres',
        numeric: 'Este campo debe contener solo caracteres numéricos',
        size: 'El archivo debe pesar menos de 0:{size}KB',
        url: 'Formato inválido de url',
      },
    },
  }),
});

// Get the locale from the user navigator and set
const userLocale = localStorage.getItem('userLocale') || navigator.language || 'en';
setLocale(userLocale);
