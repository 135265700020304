
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import { XCircleIcon } from '@heroicons/vue/24/solid';
import { XCircleIcon as XCircleIconOut } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import ModalTemperature from '@/components/modals/TemperatureModal.vue';
import SidebarStops from '@/components/sidebar/StopsSidebar.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
// Utils
import { locationString, locationObject } from '@/utils/formatLocation';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
export default defineComponent({
  name: 'AddLanes',
  mixins: [BaseComponent, CountryMixins],
  components: {
    DatePickerComponent,
    Field,
    Form,
    GoogleAutocomplete,
    InputAmericanFormat,
    ModalTemperature,
    SidebarStops,
    Spin,
    XCircleIcon,
    XCircleIconOut,
  },
  data() {
    return {
      allowed: true,
      dateFormat: 'MM-dd-yyyy',
      currentDate: new Date(),
      showTemperature: false,
      lanes: [] as any,
      stopsToSidebar: null as any,
      showSidebarStops: false,
      laneAdditionalActive: 0,
      activeLaneTemperature: null as any,
      isValidating: false,
      editedItems: [] as any,
      newItems: [] as any,
    };
  },
  beforeCreate() {
    this.$store.dispatch('LoadsStore/trailers');
  },
  created() {
    if (this.editingLanes.length > 0) {
      this.lanes = this.editingLanes;
      if (this.$route.query.loadCreationMode === 'Editing') {
        const addPlaceProperty = (stop) =>
          `${stop.location.zip}, ${stop.location.city}, ${stop.location.state.isoGoogle}`;

        this.lanes.forEach((data, index) => {
          data.loadTemplate.stops.forEach((stop) => {
            stop.place = addPlaceProperty(stop);
            stop.executionDateField = this.getDayAfter(stop.executionDate);
          });
        });
      }
    } else {
      this.lanes = [
        {
          loadsPerWeek: null as any,
          loadTemplate: {
            stops: [
              {
                type: 'PICKUP_INITIAL',
                executionDate: '',
                executionDateField: '',
                place: '' as any,
                location: {
                  lat: 0,
                  long: 0,
                  zip: 0,
                  city: '',
                  state: { id: 1 },
                },
                portStop: false,
                airportStop: false,
                observations: '',
                lumperFeePrice: null as null | number,
                lumperFee: false,
              },
              {
                type: 'DELIVERY_FINAL',
                executionDate: '',
                executionDateField: '',
                place: '' as any,
                location: {
                  lat: 0,
                  long: 0,
                  zip: 0,
                  city: '',
                  state: { id: 1 },
                },
                portStop: false,
                airportStop: false,
                observations: '',
                lumperFeePrice: null as null | number,
                lumperFee: false,
              },
            ],
            commodityMaster: {
              weight: null as any,
              commodityName: '',
              minTemp: null as any,
              maxTemp: null as any,
            },
            trailerType: { id: '' as any },
          },
        },
      ];
    }
  },
  computed: {
    editingLanes(): any {
      return this.$store.getters['LanesStore/editingLanes'];
    },
    locationObject() {
      return locationObject;
    },
    locationString() {
      return locationString;
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
  },
  methods: {
    addLane() {
      this.lanes.push({
        loadsPerWeek: null as any,
        loadTemplate: {
          stops: [
            {
              type: 'PICKUP_INITIAL',
              executionDate: '',
              place: '' as any,
              location: {
                lat: 0,
                long: 0,
                zip: 0,
                city: '',
                state: { id: 1 },
              },
              portStop: false,
              airportStop: false,
              observations: '',
              lumperFeePrice: null as null | number,
              lumperFee: false,
            },
            {
              type: 'DELIVERY_FINAL',
              executionDate: '',
              place: '' as any,
              location: {
                lat: 0,
                long: 0,
                zip: 0,
                city: '',
                state: { id: 1 },
              },
              portStop: false,
              airportStop: false,
              observations: '',
              lumperFeePrice: null as null | number,
              lumperFee: false,
            },
          ],
          commodityMaster: {
            weight: null as any,
            commodityName: '',
            minTemp: null as any,
            maxTemp: null as any,
          },
          trailerType: { id: '' as any },
        },
      });
    },
    addStops(stops) {
      this.lanes[this.laneAdditionalActive].loadTemplate.stops = [...stops];
    },
    checkDate(index) {
      this.lanes[index].loadTemplate.stops.forEach((stop) => {
        if (
          this.lanes[index].loadTemplate.stops[0].executionDateField > stop.executionDateField &&
          stop.executionDateField
        ) {
          stop.executionDateField = this.lanes[index].loadTemplate.stops[0].executionDateField;
        }
      });
    },
    clearInput(data: any) {
      data.place = '';
      data.location.lat = 0;
      data.location.long = 0;
      data.location.zip = 0;
      data.location.city = '';
      data.location.state.id = 1;
    },
    async createBatchLanes() {
      if (!this.allowed) return;
      this.isValidating = true;
      const validation = await (this.$refs.formLanes as any).validate();
      if (!validation.valid) {
        this.isValidating = false;
        setTimeout(() => {
          for (const key in validation.errors) {
            if (Object.prototype.hasOwnProperty.call(validation.errors, key)) {
              (this.$refs[key] as any)[0].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
              break;
            }
          }
        }, 500);
        return;
      }
      this.allowed = false;

      this.lanes.forEach((lane) => {
        if (this.isVehicleRefrigerated(lane)) {
          if (
            lane.loadTemplate.commodityMaster.minTemp !== undefined &&
            lane.loadTemplate.commodityMaster.minTemp !== null
          ) {
            (lane.loadTemplate.commodityMaster.minTemp as any) = parseFloat(
              lane?.loadTemplate.commodityMaster?.minTemp,
            );
          }
          if (
            lane.loadTemplate.commodityMaster.maxTemp !== undefined &&
            lane.loadTemplate.commodityMaster.maxTemp !== null
          ) {
            (lane.loadTemplate.commodityMaster.maxTemp as any) = parseFloat(
              lane?.loadTemplate.commodityMaster?.maxTemp,
            );
          }
        } else {
          delete lane?.loadTemplate?.commodityMaster?.minTemp;
          delete lane?.loadTemplate?.commodityMaster?.maxTemp;
        }
        lane.loadsPerWeek = Number(lane.loadsPerWeek);
        lane.loadTemplate.stops.forEach((stop: any) => {
          if (stop.type === 'PICKUP_INITIAL' || stop.type === 'DELIVERY_FINAL') {
            stop.executionDate = this.formatDateStringReverse(stop.executionDateField);
          }
          if (stop.appoiment && stop.appoiment !== 'NONE') {
            if (stop.appoiment === 'APPT') {
              stop.executionWindowStartTime = this.formatTime(stop.executionWindowStartTime) as any;
              stop.executionWindowEndTime = null as any;
            } else {
              stop.executionWindowStartTime = this.formatTime(stop.executionWindowStartTime) as any;
              stop.executionWindowEndTime = this.formatTime(stop.executionWindowEndTime) as any;
            }
          } else {
            stop.executionWindowStartTime = null;
            stop.executionWindowEndTime = null;
          }
        });
      });

      if (this.$route.query.loadCreationMode === 'Editing') {
        /// REFACTOR THIS IN A FUTURE ANS JUST USE ONE ENDPOINT
        /// BECAUSE IS A LOT OF CODE JUST FOR EDITING / CREATE
        this.lanes.forEach((item) => {
          item.loadTemplate.stops.forEach((stop) => {
            stop.executionDate = this.formatDate(this.getDayAfter(stop.executionDateField));
          });
          if ('id' in item) {
            this.editedItems.push(item);
          } else {
            this.newItems.push(item);
          }
        });

        if (this.newItems.length > 0) {
          this.$store
            .dispatch('LanesStore/createBatchLanes', {
              id: this.$attrs.id,
              body: {
                lanesRFQ: this.newItems,
              },
            })
            .then(() => {
              this.$store
                .dispatch('LanesStore/updateLanes', {
                  id: +this.$route.params.id,
                  data: this.editedItems,
                })
                .then(() => {
                  BaseComponent.methods?.showToastSuccess(this.$t('edited-successfully'));
                  this.$router.push({ name: 'lanesRFQ' });
                })
                .finally(() => {
                  this.isValidating = false;
                  this.allowed = true;
                });
            })
            .finally(() => {
              this.isValidating = false;
            });
        } else {
          this.$store
            .dispatch('LanesStore/updateLanes', {
              id: +this.$route.params.id,
              data: this.lanes,
            })
            .then(() => {
              BaseComponent.methods?.showToastSuccess(this.$t('edited-successfully'));
              this.$router.push({ name: 'lanesRFQ' });
            })
            .finally(() => {
              this.allowed = true;
              this.isValidating = false;
            });
        }
      } else {
        this.$store
          .dispatch('LanesStore/createBatchLanes', {
            id: this.$attrs.id,
            body: {
              lanesRFQ: this.lanes,
            },
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
            this.$router.push({ name: 'lanesRFQ' });
          })
          .finally(() => {
            this.isValidating = false;
            this.allowed = true;
          });
      }
    },
    deleteCurrentLane(lane, i) {
      this.removeLane(i);
      this.$store.dispatch('LanesStore/deleteLane', lane.id).then(() => {
        BaseComponent.methods?.showToastSuccess(this.$t('lane-deleted'));
      });
    },
    formatDate(inputDate) {
      const dateObj: any = new Date(inputDate);

      // Check if the date is valid
      if (isNaN(dateObj)) {
        return null; // Return null for Invalid Date
      }

      // Format the date in 'YYYY-MM-DD' format
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    getLocation(addressData, address, id, index, lane) {
      if (addressData.postal_code && addressData.locality) {
        if (this.$route.query.loadCreationMode === 'Editing') {
          let locationId = this.lanes[lane].loadTemplate.stops[index].location.id;
          this.lanes[lane].loadTemplate.stops[index].place = this.locationString(
            addressData,
          ) as unknown;
          this.lanes[lane].loadTemplate.stops[index].location = this.locationObject(
            addressData,
          ) as unknown;

          this.lanes[lane].loadTemplate.stops[index].location.id = locationId;
        } else {
          this.lanes[lane].loadTemplate.stops[index].place = this.locationString(
            addressData,
          ) as unknown;
          this.lanes[lane].loadTemplate.stops[index].location = this.locationObject(
            addressData,
          ) as unknown;
        }
      } else {
        (this.$refs[id] as any)[0].clear();
      }
    },
    getTemperatures(lane) {
      const minTemp = lane.loadTemplate.commodityMaster.minTemp;
      const maxTemp = lane.loadTemplate.commodityMaster.maxTemp;
      if (maxTemp) {
        return `(${this.formatTemperature(minTemp)} - ${this.formatTemperature(maxTemp)})`;
      } else {
        return `(${this.formatTemperature(minTemp)})`;
      }
    },
    isVehicleRefrigerated(lane) {
      const trailer = this.trailerTypes.find(
        (trail) => trail.id === lane.loadTemplate?.trailerType?.id,
      );
      const isRefree = trailer?.type === 'REFRIGERATED' || trailer?.type === 'REFRIGERATEDOPTIONAL';
      return isRefree;
    },
    removeLane(index) {
      this.lanes.splice(index, 1);
    },
    removeStop(index) {
      this.lanes[this.laneAdditionalActive].loadTemplate.stops.splice(index, 1);
    },
    removeTrailer(lane) {
      lane.loadTemplate.trailerType.id = '';
    },
    scrollToElement() {
      const scrollableElement: any = document.getElementById('table');
      // Calculate the maximum scroll value
      const maxScroll = scrollableElement.scrollWidth - scrollableElement.clientWidth;

      // Scroll the element to the maximum scroll value (scroll to the right end)
      scrollableElement.scrollTo({
        left: maxScroll,
        behavior: 'smooth',
      });
    },
    setTemperature(minTemp, maxTemp) {
      this.activeLaneTemperature.loadTemplate.commodityMaster.minTemp = minTemp;
      this.activeLaneTemperature.loadTemplate.commodityMaster.maxTemp = maxTemp;
    },
    setVehicleTemp(lane, toggle): any {
      if (
        lane.loadTemplate?.trailerType?.id !== '' &&
        lane.loadTemplate?.trailerType?.id !== undefined
      ) {
        const trailer = this.trailerTypes.find(
          (trail) => trail.id === lane.loadTemplate?.trailerType?.id,
        );
        const isRefree = trailer.type === 'REFRIGERATED' || trailer.type === 'REFRIGERATEDOPTIONAL';

        this.activeLaneTemperature = lane;
        if (isRefree && toggle) {
          this.toggleTemperature(true);
        }
        if (!isRefree && toggle) {
          this.setTemperature(null, null);
        }
        return isRefree;
      } else {
        return false;
      }
    },
    translateTrailer(trailerTypeName){
      const clearName = trailerTypeName.replace(/[^\wáéíóú]/g, '');
      return this.$t(`trailer-types-eu.${clearName}`)
    },
    toggleSidebarStops(toggle, stops, index) {
      if (stops) {
        this.stopsToSidebar = stops;
      }
      this.showSidebarStops = toggle;
      this.laneAdditionalActive = index;
    },
    toggleTemperature(bool) {
      this.showTemperature = bool;
    },
  },
});
