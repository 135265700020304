
import { defineComponent, reactive, toRefs, computed, onMounted, ref } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputTextArea from '@/components/forms/PlanTextArea.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import PlanInputLocation from '@/components/forms/PlanInputLocation.vue';
import PlanCarrierTrackingMap from '@/components/carrier/_assigned/PlanCarrierTrackingMap.vue';
import { locationString } from '@/utils/formatLocation';

import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
interface carrierBase {
  load: any;
  loadingLoad: boolean;
}

export default defineComponent({
  name: 'DriverTrackingNotifyPosition',
  components: {
    PlanButton,
    PlanCarrierTrackingMap,
    PlanDefaultCarrierView,
    PlanInputTextArea,
    PlanInputLocation,
    PlanTextButton,
  },
  props: {
    id: { type: String, required: false, default: null },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();
    const clearValuesRef = ref(null) as any;

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const data = reactive({
      initialLocation: {
        lat: 0,
        lon: 0,
        zip: '',
        country: '',
      },
      placeId: '',
      observations: '',
      currentLocation: '',
      loadingPostNotifyPosition: false,
      loadingPosition: false,
    });

    onMounted(() => {
      if (Object.keys(store.getters['DriverTrackingStore/currentStopTracking']).length === 0) {
        store.dispatch('CarrierStore/getLoadInfoForCarrier', props.id).then(() => {
          const firstStopWithEmptyMediaObjects = getLoadData.value.stops.find(
            (stop) =>
              !stop.mediaObjects || stop.mediaObjects.length === 0 || stop.status !== 'DEPARTED',
          );

          store.commit(
            'DriverTrackingStore/setCurrentStopTracking',
            firstStopWithEmptyMediaObjects,
          );
        });
      }

      getLocation();
    });

    const getLatitudeLongitude = (position: GeolocationPosition) => {
      console.log(GeolocationPosition);

      data.initialLocation.lat = position.coords.latitude;
      data.initialLocation.lon = position.coords.longitude;

      store.commit('CarrierStore/setActualPosition', {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });

      getLocationsByGoogle();
    };

    const getLocationsByGoogle = async () => {
      await store
        .dispatch('LoadsStore/getCoordinatesGoogle', {
          lat: data.initialLocation.lat,
          lng: data.initialLocation.lon,
        })
        .then(async (response) => {
          if (response.data.length > 0) {
            data.placeId = response.data[0]['place_id'];
            response.data[0]['address_components'].forEach((addressComponent: any) => {
              if (addressComponent.types.includes('locality')) {
                data.currentLocation = addressComponent['long_name'];
              }
              if (addressComponent.types.includes('postal_code')) {
                data.initialLocation.zip = addressComponent['short_name'];
              }
              if (addressComponent.types.includes('country')) {
                data.initialLocation.country = addressComponent['short_name'];
              }
            });
          }
        });
      data.loadingPosition = false;
    };

    const showError = (error: GeolocationPositionError) => {
      let errorMessage = '';
      data.loadingPosition = false;
      switch (error.code) {
        case error.PERMISSION_DENIED:
          errorMessage = translate.t('location.permisionDeny');
          break;
        case error.POSITION_UNAVAILABLE:
          errorMessage = translate.t('location.unavaibleLocation');
          break;
        case error.TIMEOUT:
          errorMessage = translate.t('location.timeOut');
          break;
      }
      store.dispatch('UserStore/updateToast', {
        text: errorMessage,
        type: 'error',
        visible: true,
      });
    };
    const currentStopTracking = computed(() => {
      return store.getters['DriverTrackingStore/currentStopTracking'];
    });

    const clearLocation = () => {
      if (clearValuesRef.value) {
        clearValuesRef.value?.setValuesDefault();
      }
    };

    const lastPosition = computed(() => {
      if (store.getters['CarrierStore/getTrackingHistory'].length > 0) {
        return {
          lat: store.getters['CarrierStore/getTrackingHistory'][0].location.lat,
          lon: store.getters['CarrierStore/getTrackingHistory'][0].location.long,
        };
      }
      return null;
    });

    const getLoadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const postNotifyPosition = async () => {
      data.loadingPostNotifyPosition = true;
      const params = {
        shipment: { id: store.getters['CarrierStore/getLoadData'].shipment?.id },
        location: {
          googlePlaceId: data.placeId,
          language: store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es',
        },
        observations: data.observations,
      };

      await store.dispatch('CarrierStore/getLoadInfoForCarrier', props.id);
      await store.dispatch('LoadsStore/postNotifyPosition', params).then((response) => {
        store
          .dispatch(
            'LoadsStore/getTrackingHistory',
            store.getters['CarrierStore/getLoadData'].shipment?.id,
          )
          .then((response) => {
            store.commit('CarrierStore/setTrackingHistory', response);
          });

        // if is near to the warehouse
        if (+response?.shipment?.distanceToNextStop < 25000) {
          router.push({ name: 'DriverRouteStopUpdate', params: { id: props.id } });
        }

        clearLocation();
        data.observations = '';
        data.loadingPostNotifyPosition = false;
      });
    };

    const getLocation = () => {
      if (navigator.geolocation) {
        data.loadingPosition = true;
        navigator.geolocation.getCurrentPosition(getLatitudeLongitude, showError);
      }
    };
    const onClickGetLocation = () => {
      if (navigator.geolocation) {
        data.loadingPosition = true;
        navigator.geolocation.getCurrentPosition(getLatitudeLongitude, showError);
      }
    };

    const currentUser = computed(() => {
      return store.getters['UserStore/getCurrentUser'];
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const isDriver = computed(() => {
      return store.getters['CarrierStore/getIsDriver'];
    });

    const isMobile = computed(() => {
      return true;
    });

    const getLoad = async () => {
      await store.dispatch('LoadsStore/getFeeExplanations');
      await store.dispatch(
        'CarrierStore/getLoadInfoForCarrier',
        store.getters['CarrierStore/getLoadData'].id,
      );
    };

    const getPlaceId = (newPlaceId: string) => {
      store
        .dispatch('LoadsStore/getPlaceDetailsGoogle', {
          placeId: newPlaceId,
        })
        .then((response) => {
          data.placeId = response.data['place_id'];
          data.initialLocation.lat = response.data.geometry.location.lat;
          data.initialLocation.lon = response.data.geometry.location.lng;
        });
    };

    const changeInputLocation = (newData: any) => {
      setTextValue(newData);
      getPlaceId(newData.placeId);
    };

    const setTextValue = (location: any) => {
      data.initialLocation.zip = locationString(location);
    };

    const getUserCountry = async () => {
      await store.dispatch('CountryStore/getCountryById', currentUser.value?.company?.country?.id);
    };

    getLoad();
    getUserCountry();

    return {
      ...toRefs(data),
      actualWidth,
      currentStopTracking,
      changeInputLocation,
      postNotifyPosition,
      clearValuesRef,
      isDriver,
      isMobile,
      loadData,
      lastPosition,
      onClickGetLocation,
    };
  },
});
