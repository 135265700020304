
import { defineComponent, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
export default defineComponent({
  name: 'PlanTextButton',
  components: { PlanIcon },
  props: {
    label: { type: String, required: true, default: '' },
    icon: { type: String, required: false, default: '' },
    iconSize: { type: String, required: false, default: '10' },
    loading: { type: Boolean, required: false, default: false },
    type: { type: String, required: false, default: 'primary' }
  },
  emits: ['onClick'],
  setup (props, { emit }) {
    const data = reactive({
      isHover: false,
      hoverColor: '#0071BF',
      normalColor: '#0097FF'
    })

    if (props.type === 'amber') {
      data.hoverColor = '#CC9202'
      data.normalColor = '#F1AD02'
    }

    if (props.type === 'error') {
      data.hoverColor = '#EC2020'
      data.normalColor = '#EF4444'
    }

    if (props.type === 'prim-100') {
      data.hoverColor = '#4F5264'
      data.normalColor = '#8A8B97'
    }

    if (props.type === 'prim-200') {
      data.hoverColor = '#151830'
      data.normalColor = '#4F5264'
    }

    const onClick = () => { if (!props.loading) emit('onClick') }

    return {
      ...toRefs(data),
      onClick
    }
  }
})
