import { ActionContext } from 'vuex';
import jwt_decode from 'jwt-decode';
// Interfaces
import { IContact, IState, IToast } from '@/services/user/interfaces';
// Services
import users from '@/services/user/_api';
import store from '@/services/store';

export default {
  async apiGetUsersTeam(context: any, params: any) {
    const response = await users.getUsersTeam(params);
    if (params.page === 1) {
      context.commit('setUsersTeamData', response);
    } else {
      context.commit('pushUsersTeamData', response);
    }
  },
  async assignDirect(
    context: ActionContext<IState, any>,
    { name, page, maxResults }: { name: string; page: number; maxResults: number },
  ) {
    return await users.assignDirect(name, page, maxResults);
  },
  async apiPostCreateUserDemoRequests(context: any, data: any) {
    return await users.postUserDemoRequests(data);
  },
  async changePassword(context: any, data: any) {
    return await users.changePassword(data.id, data.oldPassword, data.plainPassword);
  },
  async AdminPostCreateNewPlanSubscription(context: any, data: any) {
    return await users.AdminPostCreateNewPlanSubscription(data);
  },

  async AdminPutCreateNewPlanSubscription(context: any, { body, id }: { body: any; id: number }) {
    return await users.AdminPutCreateNewPlanSubscription(body, id);
  },

  async AdminCancelPlanSubscription(context: any, id: number) {
    return await users.AdminCancelPlanSubscription(id);
  },

  async clearUserStore(context: any) {
    context.commit('clearUserStore');
  },
  async postNewShipper(context: any, data: any) {
    return await users.postNewShipper(data);
  },
  async showQuoteCarrier(context: any, data: any) {
    await users.showQuoteCarrier(data);
  },
  async resendEmail(context: any, data: any) {
    await users.resendEmail(data);
  },
  async registerEmail(context: any, data: any) {
    await users.registerEmail(data);
  },
  async deAssignDriver(context: any, data: any) {
    await users.deAssignDriver(data);
  },
  async deAssignVehicle(context: any, data: any) {
    await users.deAssignVehicle(data);
  },
  async emailResetPassword(context: any, data: any) {
    await users.emailResetPassword(data);
  },
  async resetPassword(context: any, data: any) {
    await users.resetPassword(data);
  },
  async newShipperRegister(context: any, data: any) {
    await users.newShipperRegister(data);
  },

  async getUserConfig(context: any, data: any) {
    const response = await users.getUserConfig(data);
    return response;
  },

  async getUser(context: any, data: any) {
    const response = await users.getUser(data);
    context.commit('setUser', response);
    return response;
  },
  async getCompany(
    context: ActionContext<IState, any>,
    { query, zip }: { query: string; zip: number },
  ) {
    return await users.getCompany(query, zip);
    //context.commit('getCompany', response);
  },
  async putUser(context: ActionContext<IState, any>, data: any) {
    await users.putUser(data);
  },
  async currentUser(context: ActionContext<IState, any>) {
    const token = jwt_decode(localStorage.token) as any;
    const response = await users.getUserConfig(token.id);
    context.commit('setCurrentUser', response);
    if (response.company?.country?.region?.name)
      context.commit('setRegion', response.company?.country?.region?.name);
    // check timezone, commit modal timezone
    const dtf = new Intl.DateTimeFormat();

    // Added for Carries with no Timezone

    if (
      !response.timeZone &&
      (response?.company?.type === 'CARRIER' || response?.company?.type === 'BROKER')
    ) {
      context.dispatch('putUser', {
        id: response.id,
        user: { ...response, timeZone: dtf.resolvedOptions().timeZone },
      });
    }

    if (response.timeZone && response.timeZone !== dtf.resolvedOptions().timeZone) {
      context.commit('setShowModalTimezone', true);
    }
  },
  async carrierContactList(
    context: ActionContext<IState, any>,
    {
      page,
      name,
      type,
      sortBy,
      trailerType,
      tags,
    }: {
      page: number;
      name: string;
      type: string;
      sortBy: string;
      trailerType: number;
      tags: Array<string>;
    },
  ) {
    const response = await users.carrierContactList(name, page, type, sortBy, trailerType, tags);

    if (page > 1) {
      context.commit('addValuesToList', response.values);
    } else {
      context.commit('carrierContactList', response);
    }
  },
  async carrierContactListStatsByLoad(
    context: ActionContext<IState, any>,
    {
      destinyZip,
      lanesRFP,
      name,
      originZip,
      page,
      period,
      sortBy,
      trailerType,
      trailerTypeStats,
      type,
      permits,
      country,
      tags,
      zipType,
    }: {
      destinyZip: number;
      lanesRFP: number;
      originZip: number;
      name: string;
      page: number;
      period: number;
      sortBy: string;
      trailerType: number;
      trailerTypeStats: number;
      type: string;
      permits: any;
      country: any;
      tags: Array<string>;
      zipType: any;
    },
  ) {
    const response = await users.carrierContactListStatsByLoad(
      destinyZip,
      lanesRFP,
      name,
      originZip,
      page,
      period,
      sortBy,
      trailerType,
      trailerTypeStats,
      type,
      permits,
      country,
      tags,
      zipType,
    );
    if (page > 1) {
      context.commit('carrierContactListStatsByLoad', [
        ...context.state.carrierContactListStatsByLoad,
        ...response,
      ]);
    } else {
      context.commit('carrierContactListStatsByLoad', response);
    }
    return response
  },
  async notificationItems(context: any, data: any) {
    context.state.notificationItems.push(data);
    context.commit('notificationItems', context.state.notificationItems);
  },
  async carrierNetwork(
    context: ActionContext<IState, any>,
    { page, name, type }: { page: number; name: string; type: string },
  ) {
    const response = await users.carrierNetwork(name, page, type);
    if (page > 1) {
      context.commit('carrierNetwork', [...context.state.carrierNetwork, ...response]);
    } else {
      context.commit('carrierNetwork', response);
    }
  },

  async getContactsExcel() {
    return await users.getContactsExcel();
  },

  async carrierNetworkTotal(context: any, data: any) {
    const response = await users.carrierNetworkTotal(data);
    context.commit('carrierNetworkTotal', response);
  },
  async addContact(context: ActionContext<IState, any>, data: IContact) {
    return await users.addContact(data);
  },
  async postContactsExcel(context: any, data: any) {
    const response = await users.postContactsExcel(data);
    context.commit('setCurrentContacts', response);
    return response;
  },
  async checkContactsFromJson(context: any, data: any) {
    users.checkContactsFromJson(data).then((response) => {
      context.commit('setCurrentContacts', response);
    });
  },
  async createContactsFromJson(context: any, data: any) {
    const response = await users.createContactsFromJson(data);
    return response;
  },
  async getUserIdentification(context: any, id: number) {
    const response = await users.getUserIdentification(id);
    context.commit('setUserIdentification', response.userIdentifier);
    return response;
  },

  async assignLoad(context: ActionContext<IState, any>, data: any) {
    await users.assignLoad(data);
  },
  async reAssignLoad(context: ActionContext<IState, any>, data: object) {
    return await users.reAssignLoad(data);
  },
  async requestBid(
    context: ActionContext<IState, any>,
    { shippment, object }: { shippment: number; object: object },
  ) {
    await users.requestBid(shippment, object);
  },
  async carrierRequestedBid(
    context: ActionContext<IState, any>,
    { page, shippment, sortBy }: { shippment: number; page: number; sortBy: string },
  ) {
    const response = await users.carrierRequestedBid(page, shippment, sortBy);
    if (page > 1) {
      context.commit('carrierRequestedBid', [...context.state.carrierRequestedBid, ...response]);
    } else {
      context.commit('carrierRequestedBid', response);
    }
  },
  async requestBidStatsByLoad(
    context: ActionContext<IState, any>,
    {
      page,
      shipment,
      sortBy,
      trailerTypeStats,
      originZip,
      destinyZip,
      period,
    }: {
      shipment: number;
      page: number;
      sortBy: string;
      trailerTypeStats: number;
      originZip: number;
      destinyZip: number;
      period;
    },
  ) {
    const response = await users.requestBidStatsByLoad(
      page,
      shipment,
      sortBy,
      trailerTypeStats,
      originZip,
      destinyZip,
      period,
    );
    if (page > 1) {
      context.commit('requestBidStatsByLoad', [
        ...context.state.requestBidStatsByLoad,
        ...response,
      ]);
    } else {
      context.commit('requestBidStatsByLoad', response);
    }
  },
  async postIncidence(context: ActionContext<IState, any>, data: any) {
    return await users.postIncidence(data);
  },
  async postIncidenceFile(context: ActionContext<IState, any>, data: any) {
    return await users.postIncidenceFile(data);
  },

  showToast(context: ActionContext<IState, any>, text: string, type = 'success', visible = true) {
    context.commit('updateToast', { text, type, visible });
  },

  updateToast(context: ActionContext<IState, any>, toast: IToast) {
    context.commit('updateToast', toast);
  },
  updateNotification(context: ActionContext<IState, any>, notification: IToast) {
    context.commit('updateNotification', notification);
  },
  async getUsersCompany(context: any, data: any) {
    const response = await users.getUsersCompany(data);
    context.commit('setUsersCompany', response);
  },
  async getUsersCompanyTotals(context: any, data: any) {
    const response = await users.getUsersCompanyTotals(data);
    context.commit('setUsersCompanyTotals', response);
  },
  async postUser(context: any, data: any) {
    await users.postUser(data);
  },
  async deleteUser(context: any, data: any) {
    await users.deleteUser(data);
  },
  async registrationUpdatePassword(context: any, data: any) {
    await users.registrationUpdatePassword(data);
  },
  async bigTexts(context: any, data: any) {
    const response = await users.defaultTermsAndConditions(data);
    context.commit('bigTexts', response);
    return response;
  },
  async resendInvite(context: any, data: any) {
    return await users.resendInvite(data);
  },
  async getURLInvitation(context: any, data: any) {
    return await users.getURLInvitation(data);
  },
  async requestLoginCode(context: any, data: any) {
    return await users.requestLoginCode(data);
  },
  async hashValidation(context: any, data: any) {
    const response = await users.hashValidation(data);
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('refresh_token', response.data.refresh);
    store.dispatch('GlobalStore/apiGetGlobalConfig');
    store.dispatch('UserStore/currentUser');
    return response;
  },
  async getUserEmailNotificationSetup(context: any) {
    const response = await users.getUserEmailNotificationSetup();
    context.commit('setUserEmailNotificationSetup', response);
  },
  async postUserEmailNotificationSetup() {
    await users.postUserEmailNotificationSetup();
  },
  async putUserEmailNotificationSetup(context: any, data: any) {
    await users.putUserEmailNotificationSetup(data);
  },
  async checkIsShowPlanModal(context: any) {
    const currentUser = context.state.currentUser;
    let showModal = false;
    if (
      (currentUser?.company?.accessPlanType === 'BASIC' &&
        currentUser?.company?.freeBolRemaining <= 0) ||
      ((currentUser?.company?.accessPlanType === 'BASIC_PLUS' ||
        currentUser?.company?.accessPlanType === 'PREMIUM') &&
        !currentUser?.company?.freeBolNotificationAccepted &&
        currentUser?.company?.freeBolRemainingOnUnlimitedAccessPlans === 0)
    ) {
      showModal = true;
    }
    context.commit('setShowModalPlans', showModal);
    return showModal;
  },
};
