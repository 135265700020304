
import { computed, defineComponent, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'DriverTrackingAuth',
  components: { PlanIcon },
  props: {
    id: { type: String, required: false, default: '' },
    hash: { type: String, required: false, default: '' },
    code: { type: String, required: false, default: '' },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const translate = useI18n();

    // router.push({ name: 'CarrierLoadDetails', params: { id: props.id } });

    const getData = () => {
      store.dispatch('CarrierStore/getLoadInfoForCarrier', props.id).then((response) => {
        if (
          getLoadData.value.status === 'ASSIGNED' ||
          getLoadData.value.status === 'IN_PROGRESS' ||
          getLoadData.value.status === 'FINISHED'
        ) {
          const firstStopWithEmptyMediaObjects = getLoadData.value.stops.find(
            (stop) =>
              !stop.mediaObjects || stop.mediaObjects.length === 0 || stop.status !== 'DEPARTED',
          );

          if (firstStopWithEmptyMediaObjects) {
            store.commit(
              'DriverTrackingStore/setCurrentStopTracking',
              firstStopWithEmptyMediaObjects,
            );

            if (
              firstStopWithEmptyMediaObjects.hasTruckNear ||
              firstStopWithEmptyMediaObjects.status === 'PRE-ARRIVAL' ||
              firstStopWithEmptyMediaObjects.status === 'ARRIVED' ||
              firstStopWithEmptyMediaObjects.status === 'STARTED' ||
              firstStopWithEmptyMediaObjects.status === 'FINISHED'
            ) {
              router.push({ name: 'DriverRouteStopUpdate', params: { id: props.id } });
            } else {
              router.push({ name: 'DriverTrackingNotifyPosition', params: { id: props.id } });
            }
          } else {
            router.push({ name: 'CarrierLoadDetails', params: { id: props.id } });
          }
        }
      });
    };

    onBeforeMount(() => {
      store.commit('DriverTrackingStore/setCurrentStopTracking', null);
    });

    const getLoadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const loginUser = () => {
      store
        .dispatch('UserStore/hashValidation', {
          hashId: props.hash,
          secureCode: props.code,
        })
        .then(() => {
          store.dispatch('PusherStore/initializePusher');
          store.commit('CarrierStore/setIsDriver', true);
          getData();
        });
    };

    loginUser();

    return {
      getLoadData,
    };
  },
});
