
import { defineComponent, reactive, toRefs, watch } from 'vue';
export default defineComponent({
  name: 'PlanInputRadio',
  props: {
    options: { type: Array, required: true },
    isVertical: { type: Boolean, required: false, default: false },
    selectedOption: { type: String, required: true, default: '' }
  },
  emit: ['change'],
  setup (props, { emit }) {
    const data = reactive({
      selected: ''
    })

    const changeSelectedOption = (option: any) => {
      if (!option.disabled) {
        data.selected = option.value
        emit('change', data.selected)
      }
    }

    if (props.selectedOption !== '') data.selected = props.selectedOption + ''

    watch(() => props.selectedOption, (newVal, oldVal) => {
      if (newVal !== '') data.selected = newVal + '';
    })

    return {
      ...toRefs(data),
      changeSelectedOption
    }
  }
})
