import driver from '../_api/index';

export default {
    async setStopArrivalInterfaceOpenedByDriver(context: any, id: number) {
        return await driver.setStopArrivalInterfaceOpenedByDriver(id);
    },
    async setStopArrivalByDriver(context: any, id: number) {
        return await driver.setStopArrivalByDriver(id);
    },
    async setStopStartByDriver(context: any, id: number) {
        return await driver.setStopStartByDriver(id);
    },
    async setStopFinishByDriver(context: any, id: number) {
        return await driver.setStopFinishByDriver(id);
    },
    async setStopDepartInterfaceOpened(context: any, id: number) {
        return await driver.setStopDepartInterfaceOpened(id);
    },



    



    

}