
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';

export default defineComponent({
  name: 'CustomAgentModal',
  components: { PlanButton, PlanInputText, PlanDefaultModal, PlanSelect },
  emits: ['close', 'getLocation'],
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      name: '',
      surname: '',
      phone: '',
      phonePrefix: '',
      email: '',
      loadingPost: false,
    });

    const currentLoad = computed(() => store.getters['LoadsStore/currentLoad']);

    const validateAgent = computed(() => {
      return (
        data.name && data.surname && data.phonePrefix && isValidPhone.value && isValidEmail.value
      );
    });

    const isValidEmail = computed(() => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(data.email);
    });

    const isValidPhone = computed(() => {
      const phoneRegex = /^\d{9,16}$/;
      return phoneRegex.test(data.phone);
    });

    const prefixes = computed(() => {
      const prefixes = store.getters['CarrierStore/getRegionPrefixes'];
      return orderPrefixAsc(prefixes);
    });

    const changePrefix = (newPrefix: any) => {
      data.phonePrefix = newPrefix;
    };

    const getCountries = async () => {
      await store.dispatch('CountryStore/getCountries').then((response) => {
        const actualRegion = store.getters['UserStore/getRegion'];
        const result = response.data
          .filter((country: any) => country.region.name === actualRegion && country.iso !== 'CA')
          .map((country: any) => ({
            label: `+${country.phonePrefix}`,
            value: country.phonePrefix,
          }));
        store.commit('CarrierStore/setRegionPrefixes', result);
      });
    };

    const orderPrefixAsc = (prefixes: any) => {
      return prefixes.sort((a, b) => {
        let prefixA = parseInt(a.value, 10);
        let prefixB = parseInt(b.value, 10);

        if (prefixA < prefixB) {
          return -1;
        }
        if (prefixA > prefixB) {
          return 1;
        }
        return 0; // Si son iguales
      });
    };

    const postAgent = () => {



      data.loadingPost = true;
      store
        .dispatch('CrossBorderStore/postCustomAgent', data)
        .then((response) => {
          store.dispatch('CrossBorderStore/addAgentToLoad', {
            loadId: currentLoad.value.id,
            customAgentId: response.id,
          }).then(() => {
            store.dispatch('LoadsStore/loadById', currentLoad.value.id);
          })
            .catch((err) => {
              store.dispatch('UserStore/updateToast', {
                text: err.response.data['hydra:description'],
                type: 'error',
                visible: true,
              })
            })

          close();
        })

        .catch((err) => {
          store.dispatch('UserStore/updateToast', {
            text: err.response.data['hydra:description'],
            type: 'error',
            visible: true,
          })
        })

        .finally(() => {
          data.loadingPost = false;
        });
    };

    const close = () => {
      emit('close', false);
    };

    getCountries();

    return {
      ...toRefs(data),
      changePrefix,
      close,
      postAgent,
      prefixes,
      validateAgent,
      isValidEmail,
      isValidPhone,
    };
  },
});
