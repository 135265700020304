
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import moment from 'moment';
import i18n from '@/resources/locales';

export default defineComponent({
  name: 'PlanRfpSelectorWeek',
  components: { PlanButtonIcon },
  props: {
    currentPeriod: { type: String, required: true },
    currentWeek: { type: Number, required: true, default: 0 },
    weeks: { type: Array, required: true },
    preSelectedWeek: { type: Number, required: true, default: 0 },
  },
  emits: ['changeWeek'],
  setup(props, { emit }) {
    const data = reactive({
      position: 0,
      selectedWeek: props.preSelectedWeek,
      maxWeeksToShow: 6,
    });

    const adjustInitialPosition = () => {
      const totalWeeks = props.weeks.length;
      const halfWindow = Math.floor(data.maxWeeksToShow / 2);
      if (props.preSelectedWeek - halfWindow <= 0) {
        data.position = 0;
      } else if (props.preSelectedWeek + halfWindow >= totalWeeks) {
        data.position = totalWeeks - data.maxWeeksToShow;
      } else {
        data.position = props.preSelectedWeek - halfWindow - 1;
      }
    };

    const disabledNextButton = computed(() => {
      return !(data.selectedWeek + 1 <= props.weeks.length);
    });

    const changeWeek = (week: number) => {
      data.selectedWeek = week;
      emit('changeWeek', week);
    };

    const getFormattedDate = (date: string) => {
      return i18n.global.locale.value === 'es-ES'
        ? moment(date).format('DD MMM, YYYY')
        : moment(date).format('MMM DD, YYYY');
    };

    const getSpanStyle = (weekNumber: number) => {
      return data.selectedWeek !== props.currentWeek && weekNumber === props.currentWeek
        ? 'color: #0097FF;'
        : '';
    };

    watch(
      () => props.preSelectedWeek,
      () => {
        data.selectedWeek = props.preSelectedWeek;
        adjustInitialPosition();
      },
    );

    const goToTheFollowingWeeks = () => {
      data.selectedWeek = data.selectedWeek + 1;
      emit('changeWeek', data.selectedWeek);
      if (data.selectedWeek >= data.maxWeeksToShow && data.selectedWeek < props.weeks.length) {
        data.position += 1;
      }
    };

    const goToThePreviousWeeks = () => {
      data.selectedWeek = data.selectedWeek - 1;
      emit('changeWeek', data.selectedWeek);
      if (data.selectedWeek <= data.maxWeeksToShow) {
        data.position -= 1;
      }
    };

    const weeksToShow = computed(() => {
      if (props.weeks.length <= data.maxWeeksToShow) return props.weeks;

      const end =
        data.position + data.maxWeeksToShow > props.weeks.length
          ? props.weeks.length
          : data.position + data.maxWeeksToShow;

      return props.weeks.slice(data.position, end);
    });

    const updateMaxWeeksToShow = () => {
      const width = window.innerWidth;
      if (width > 2000) {
        data.maxWeeksToShow = 7;
      } else if (width > 1550) {
        data.maxWeeksToShow = 5;
      } else if (width > 992) {
        data.maxWeeksToShow = 4;
      } else if (width > 748) {
        data.maxWeeksToShow = 2;
      } else {
        data.maxWeeksToShow = 1;
      }
      adjustInitialPosition();
    };

    onMounted(() => {
      updateMaxWeeksToShow();
      window.addEventListener('resize', updateMaxWeeksToShow);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateMaxWeeksToShow);
    });

    adjustInitialPosition();

    return {
      ...toRefs(data),
      changeWeek,
      disabledNextButton,
      getFormattedDate,
      getSpanStyle,
      goToTheFollowingWeeks,
      goToThePreviousWeeks,
      weeksToShow,
    };
  },
});
