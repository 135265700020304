
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import {
  CheckCircleIcon,
  InformationCircleIcon,
  MinusSmallIcon,
  PlusSmallIcon,
} from '@heroicons/vue/24/outline';

export default defineComponent({
  name: 'PlanStepsTab',
  components: { CheckCircleIcon },
  props: {
    activeTab: { type: String, required: true, default: '' },
    tabs: { type: Array as any, required: true, default: [] as any },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const activeTab = ref(props.activeTab);
    const store = useStore();

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const onClickTab = (value: string) => {
      activeTab.value = value;
      emit('change', value);
    };

    const indicatorStyle = computed(() => {
      switch (activeTab.value) {
        case 'loadInfo':
          return { transform: 'translateX(0%)' };
        case 'tracking':
          return { transform: 'translateX(100%)' };
        case 'documents':
          return { transform: 'translateX(200%)' };
        default:
          return { transform: 'translateX(0%)' };
      }
    });

    return {
      actualWidth,
      indicatorStyle,
      onClickTab,
    };
  },
});
