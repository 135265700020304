
import { defineComponent, reactive, toRefs, computed, ref } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import moment from 'moment-timezone';
import BaseComponent from '@/base/BaseComponent';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/utils/formatDates';
import { formatMiles, formatWeight } from '@/utils/formatString';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import PlanCarrierLoadDetails from '@/components/carrier/loadDetail/PlanCarrierLoadDetails.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import PlanCarrierSimpleLoadDetails from '@/components/carrier/loadDetail/PlanCarrierSimpleLoadDetails.vue';
import PlanFormattedNumber from '@/components/labels/PlanFormattedNumber.vue';
import planUpdateMultipleLanesAdditionalStopInfo from '@/components/rfp/PlanUpdateMultipleLanesComponents/PlanUpdateMultipleLanesAdditionalStopInfo.vue';

interface uploadInvoice {
  billId: string;
  concept: string;
  files: FileList | null;
  showConfirmation: boolean;
  showLoading: boolean;
  showModal: boolean;
}

export default defineComponent({
  name: 'PlanCarrierUploadInvoice',
  computed: {
    planUpdateMultipleLanesAdditionalStopInfo() {
      return planUpdateMultipleLanesAdditionalStopInfo;
    },
  },
  components: {
    PlanFormattedNumber,
    PlanCarrierSimpleLoadDetails,
    PlanInputText,
    PlanInputFile,
    PlanCarrierDefaultModal,
    PlanButton,
  },
  setup() {
    const files = ref<File[]>([]);
    const store = useStore();
    const translate = useI18n();

    const data = reactive({
      loading: false,
      showModal: false,
      concept: '',
      billNumber: '',
    });

    const changeFiles = (newFiles: File[]) => {
      files.value = newFiles;
    };

    const closeModal = () => {
      if (!data.loading) data.showModal = false;
    };

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const openModal = () => {
      data.showModal = true;
      files.value = [];
      data.concept = '';
      data.billNumber = '';
    };

    const postUploadInvoice = async () => {
      let invoiceId = -1;
      data.loading = true;
      await store
        .dispatch('ShipmentStore/postInvoiceFTL', {
          number: data.billNumber,
          concept: data.concept,
          amount: totals.value.total,
          shipmentId: store.getters['CarrierStore/getLoadData'].shipment.id,
        })
        .then((response) => {
          invoiceId = response.id;
        });

      await store.dispatch('ShipmentStore/postMediaObjectInvoice', {
        entityId: invoiceId,
        file: files.value[0],
      });
      await store.dispatch(
        'CarrierStore/getLoadInfoForCarrier',
        store.getters['CarrierStore/getLoadData'].id,
      );
      await store.dispatch('UserStore/updateToast', {
        text: translate.t('upload-your-invoice-completed-modal-title'),
        type: 'success',
        visible: true,
      });
      data.loading = false;
      closeModal();
    };

    const totals = computed(() => {
      let total = 0;
      const additionalFees: Array<any> = [];

      loadData.value.shipment?.additionalFee.forEach((additionalFee: any) => {
        if (additionalFee.status === 'STATUS_APPROVED') {
          total += additionalFee.value;
          additionalFees.push({
            label: additionalFee.feeExplanation?.name,
            value: additionalFee.value,
          });
        }
      });

      total += loadData.value.shipment?.assignedCompany?.price;

      return {
        total: total,
        price: loadData.value.shipment?.assignedCompany?.price,
        additionalFees: additionalFees,
      };
    });

    return {
      ...toRefs(data),
      changeFiles,
      closeModal,
      loadData,
      files,
      openModal,
      postUploadInvoice,
      totals,
    };
  },
});
