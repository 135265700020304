
import { defineComponent } from 'vue';
import { PaperAirplaneIcon, XCircleIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Skeleton from '@/components/skeletons/SidebarChatSkeleton.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
// Utils
import { stringToShortDate } from '@/utils/formatDates';

export default defineComponent({
  name: 'SidebarChat',
  components: {
    PaperAirplaneIcon,
    Skeleton,
    Spin,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  props: {
    carrier: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingMoreMessage: false,
      message: null as any,
      sendingMessage: false,
      page: 1,
    };
  },
  computed: {
    chatId(): any {
      return this.$store.state.ChatsStore.chatId;
    },
    currentContacts(): any {
      if (this.$store.getters['UserStore/carrierContactList'].length > 0) {
        return this.$store.getters['UserStore/carrierContactList']?.filter(
          (item) => item.source !== 'CARGOBOT',
        );
      }
      return [];
    },
    currentConversation(): any {
      return this.$store.state.ChatsStore.currentConversation;
    },
    isCrossBorderLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'].legs?.length > 0;
    },
    currentLegIndex(): any {
      return this.$store.getters['LoadDetailStore/getCurrentLegIndex'];
    },
    currentLoad(): any {
      const load = this.$store.getters['LoadsStore/currentLoad'];
      return load?.legs?.length > 0 ? load.legs[this.currentLegIndex] : load;
    },
    isShipper(): any {
      return this.currentUser?.roles?.some(
        (role) => role === 'ROLE_SUPER_SHIPPER' || role === 'SHIPPER',
      );
    },
    currentLoads(): any {
      return this.$store.getters['LoadsStore/getLoads'];
    },
    currentMessages(): any {
      let messages: any = this.$store.state.ChatsStore.currentMessages;
      let messagesOrdered = messages.sort(
        (a, b) => new Date(b.creationDate).valueOf() - new Date(a.creationDate).valueOf(),
      );
      return messagesOrdered;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    deliveriesQuantity() {
      let sum = 1;
      this.currentLoad?.stops?.forEach((stop: any) => {
        if (stop.type === 'DELIVERY') {
          sum++;
        }
      });
      return sum;
    },
    destinyLane(): any {
      return this.laneForChat.loadTemplate.stops.find((obj) => obj.type === 'DELIVERY_FINAL');
    },
    finalDeliveryStop(): any {
      let finalStop = null;
      this.currentLoad?.stops?.forEach((stop: any) => {
        if (stop.type === 'DELIVERY_FINAL') {
          finalStop = stop;
        }
      });
      return finalStop;
    },
    hasMoreItems(): any {
      return this.page * this.paginationMaxItems <= this.currentMessages.length;
    },
    initialPickUpStop(): any {
      let initialStop = null;
      this.currentLoad?.stops?.forEach((stop: any) => {
        if (stop.type === 'PICKUP_INITIAL') {
          initialStop = stop;
        }
      });
      return initialStop;
    },
    laneForChat(): any {
      return this.$store.getters['ChatsStore/getLaneForChat'];
    },
    loadId(): any {
      return this.$store.state.LoadsStore.loadId;
    },
    originLane(): any {
      return this.laneForChat.loadTemplate.stops.find((obj) => obj.type === 'PICKUP_INITIAL');
    },
    paginationMaxItems(): number {
      return (
        +this.$store.getters['GlobalStore/getGlobalConfig']?.PAGINATION_DEFAULT_MAX_RESULTS ?? 30
      );
    },
    picksQuantity() {
      let sum = 1;
      this.currentLoad?.stops?.forEach((stop: any) => {
        if (stop.type === 'PICKUP') {
          sum++;
        }
      });
      return sum;
    },
    rankingForChat(): any {
      return this.$store.getters['ChatsStore/getRankingForChat'];
    },
    receiver(): any {
      return (
        this.currentConversation?.chatMembers.find(
          (member) => member.user.id === this.userForChat?.id,
        ) ?? this.userForChat
      );
    },
    stringToShortDate() {
      return stringToShortDate;
    },
    userForChat(): any {
      return this.$store.state.ChatsStore.userForChat;
    },
  },
  beforeUnmount() {
    this.closeChat();
  },
  async mounted() {
    this.isLoading = true;
    await this.createChat();
    this.loadId && this.$store.dispatch('LoadsStore/loadById', this.loadId);
    await this.$store.dispatch('ChatsStore/getConversationById', this.chatId);
    await this.getMessages();
    this.scrollChatBottom();
    // Set chat to readed
    if (this.currentConversation) {
      this.$store.commit('LoadsStore/newMessageLoad', {
        data: {
          ...this.currentConversation,
          loadId: this.loadId,
          type: this.currentConversation.chatType,
        },
        isReaded: true,
        userId: this.currentUser.id,
      });
      this.$store.commit('LoadsStore/newMessageInLoadQuoting', {
        data: {
          ...this.currentConversation,
          loadId: this.loadId,
          type: this.currentConversation.chatType,
        },
        isReaded: true,
        userId: this.currentUser.id,
      });
      this.$store.commit('LoadsStore/newMessageInLoad', {
        data: {
          ...this.currentConversation,
          loadId: this.loadId,
          type: this.currentConversation.chatType,
        },
        isReaded: true,
        userId: this.currentUser.id,
      });
    }
    await this.$store
      .dispatch('ChatsStore/setChatToRead', this.chatId)
      .finally(() => this.$store.dispatch('ChatsStore/getTotalUnreadChats'));
    this.loadId && this.$store.dispatch('LoadsStore/loadById', this.loadId);
  },
  methods: {
    closeChat() {
      this.$store.commit('GlobalStore/setOpenChat', false);
      this.$store.commit('ChatsStore/setUserForChat', null);
      this.$store.commit('ChatsStore/setChatId', null);
      if (this.currentConversation?.load !== undefined) {
        this.$store.commit('LoadsStore/newMessageLoad', {
          data: { ...this.currentConversation, loadId: this.currentConversation.load.id },
          userId: this.currentUser.id,
          isReaded: true,
        });
      }
      // Set chat to readed
      if (this.currentConversation) {
        this.$store.commit('LoadsStore/newMessageLoad', {
          data: {
            ...this.currentConversation,
            loadId: this.loadId,
            type: this.currentConversation.chatType,
          },
          isReaded: true,
          userId: this.currentUser.id,
        });
        this.$store.commit('LoadsStore/newMessageInLoad', {
          data: {
            ...this.currentConversation,
            loadId: this.loadId,
            type: this.currentConversation.chatType,
          },
          isReaded: true,
          userId: this.currentUser.id,
        });
      }
    },
    async createChat() {
      // QuotingChat
      if (!this.chatId && this.loadId && this.userForChat) {
        await this.$store.dispatch('ChatsStore/createQuotingChatForLoad', {
          user: {
            id: this.userForChat.owner_id
              ? this.userForChat.owner_id
              : this.userForChat.owner
              ? this.userForChat.owner.id
              : this.userForChat.user
              ? this.userForChat.user.id
              : this.userForChat.company.owner.id,
          },
          load: { id: this.loadId },
        });
      } else {
        // LoadChat
        if (!this.chatId && this.loadId) {
          await this.$store.dispatch('ChatsStore/createChatForLoad', { load: { id: this.loadId } });
          this.currentLoads.forEach((load) => {
            if (+load.id === +this.loadId) {
              load.chat = this.currentConversation;
            }
          });
        }
        // LaneRFPChat
        if (!this.chatId && this.userForChat && this.laneForChat) {
          await this.$store.dispatch('ChatsStore/createChatForLanesRFP', {
            lanes: { id: this.laneForChat.id },
            user: {
              id:
                this.userForChat?.owner_id ??
                this.userForChat?.owner?.id ??
                this.userForChat?.company?.owner?.id,
            },
          });
        }
        // GeneralChat
        if (!this.chatId && this.userForChat) {
          const ownerId =
            this.userForChat?.owner_id ??
            this.userForChat?.owner?.id ??
            this.userForChat?.company?.owner?.id;
          await this.$store.dispatch('ChatsStore/createChatGeneral', {
            user: {
              id: ownerId,
            },
          });
          this.currentContacts.forEach((user) => {
            if (+user.id === +ownerId) {
              user.generalChatsWithNetworkOwner[0] = this.currentConversation;
            }
          });
        }
      }
    },
    async getMessages() {
      await this.$store
        .dispatch('ChatsStore/getMessages', {
          id: this.chatId,
          params: { page: this.page, maxResults: this.paginationMaxItems },
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    async nextMessages() {
      this.isLoadingMoreMessage = true;
      await this.$store
        .dispatch('ChatsStore/getMessages', {
          id: this.chatId,
          params: { page: ++this.page, maxResults: this.paginationMaxItems },
        })
        .then(() => {
          this.isLoadingMoreMessage = false;
        });
    },
    onClickDetail(id) {
      this.$router.push({ name: 'planificationDetail', params: { id: id } });
      this.closeChat();
    },
    scrollChatBottom() {
      if (this.$refs.lastMessage) {
        (this.$refs.lastMessage as any)[0].scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
    sendMessage(event) {
      if (!this.message.trim() || (event.key === 'Enter' && event.shiftKey)) return;
      this.sendingMessage = true;
      this.$store
        .dispatch('ChatsStore/createNewMessage', {
          id: this.chatId,
          data: { message: this.message.trim() },
        })
        .then(() => {
          this.message = null;
          const target = document.querySelector('[contenteditable]') as HTMLElement;
          target.innerText = '';
        })
        .finally(() => {
          this.sendingMessage = false;
          this.scrollChatBottom();
        });
    },
    updateMessage(event) {
      this.message = event.target.innerText;
      if (event.inputType === 'insertParagraph') {
        // Clear the content of the div when the "Enter" key is pressed
        event.target.innerText = '';
      }
    },
  },
  watch: {
    currentMessages() {
      if (this.currentMessages.length > 0) {
        this.scrollChatBottom();
      }
    },
  },
});
