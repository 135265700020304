
import { defineComponent } from 'vue';
import PlanTextArea from '@/components/forms/PlanTextArea.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import {
  CheckCircleIcon,
  PencilIcon,
  PencilSquareIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/vue/24/outline';
import { saveAs } from 'file-saver';
import cloneDeep from 'lodash/cloneDeep';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import CustomMultiselect from '@/components/forms/CustomMultiselect.vue';
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import LoadsCreditModal from '@/modules/superAdmin/_components/LoadsCreditModal.vue';
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import ModalNewUser from '@/components/modals/NewUserModal.vue';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';
// Utils
import { calculateDaysDifference } from '@/utils/formatDates';
import SuperadminUpdateShipperPricingInfo
  from '@/modules/superAdmin/_components/SuperadminUpdateShipperPricingInfo.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
interface User {
  name: string;
  surname: string;
  phone: string;
  phonePrefix: string;
  email?: string;
}

export default defineComponent({
  name: 'AddShipperModal',
  components: {
    SuperadminUpdateShipperPricingInfo,
    CheckCircleIcon,
    CustomMultiselect,
    DatePickerComponent,
    ErrorMessage,
    Field,
    Form,
    PlanButton,
    InputAmericanFormat,
    LoadsCreditModal,
    ModalConfirm,
    ModalNewUser,
    PlanTextArea,
    PencilIcon,
    PencilSquareIcon,
    PhoneNumberSelector,
    TrashIcon,
    XCircleIcon,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    type: {
      type: String,
      default: 'creation',
    },
    company: {
      type: Object,
    },
  },
  data() {
    return {
      acquisitionSource: 'PLANIMATIK',
      adminAgent: {
        email: '',
        type: [],
      },
      adminAgentsAssigned: [] as any,
      bolDefaultValue: null,
      companyAddress: '',
      companyCountry: '',
      companyForUser: null,
      companyName: '',
      companyState: '',
      companyZip: '',
      contactEmail: null as any,
      contactName: null as any,
      contactPhone: null as any,
      contactPhonePrefix: null as any,
      createEditAgent: 'create',
      creditGranted: null as any,
      defaultCustomPlanChecked: false,
      planTotalCost: null as any,
      planType: 'month',
      planFreeLoads: null as any,
      planExpiration: null as any,
      planExtraInfo: null as any,
      currentIndex: 0,
      email: '',
      identificationNumber: null as any,
      isAcquisitionSourceFocused: false,
      isCompanyFocused: false,
      isCompanyStateFocused: false,
      isCompanyZipFocused: false,
      isCreating: false,
      isEmailFocused: false,
      isLastnameFocused: false,
      // WIP
      isLoadsCreditModal: false,
      isNameFocused: false,
      isCanceling: false,
      isNewLogo: false,
      isPhoneFocused: false,
      isPlanimatikAgentFocused: false,
      isPrefixFocused: false,
      isSending: false,
      lastname: '',
      logo: {} as any,
      logoFile: {} as any,
      logoURL: '',
      name: '',
      newAgentInput: false,
      openNewUser: false,
      page: 1,
      phone: '',
      plan: 'TRIAL',
      planimatikAgent: '',
      prefix: '1',
      resetForm: false,
      searchTimeout: null as any,
      showDelete: false,
      showPlanimatik: true,
      trialEndDateToSend: null as any,
      userToDelete: null,
      userToEdit: null,
      countrySelected: null,
      pricingInfoMargin: null
    };
  },
  created() {
    this.$store.dispatch('LoadsStore/states');
    if (this.type === 'update') {
      this.setForm();
    }
  },
  computed: {
    hasMoreItems(): any {
      return this.page * this.maxResults <= this.companyUsers.length;
    },
    maxResults(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']
        ? parseInt(
            this.$store.getters['GlobalStore/getGlobalConfig'].PAGINATION_DEFAULT_MAX_RESULTS,
          )
        : 30;
    },
    calculateDaysDifference(): any {
      return calculateDaysDifference;
    },
    countriesList(): any {
      return this.$store.getters['CountryStore/getCountries'].filter(
        (item: any) =>
          item.region && item?.region?.id === this.currentUser?.company?.country?.region?.id,
      );
    },
    creationFormFilled(): any {
      return this.adminAgent.email && this.adminAgent.type.length > 0;
    },
    adminAgentType(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig'].AdminAgentType;
    },
    companyAcquisitionSource(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig'].CompanyAcquisitionSource;
    },
    companyAccessPlanTypes(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig'].CompanyAccessPlanTypes;
    },
    adminAgents(): any {
      return this.$store.getters['CompanyStore/getAdminAgents'];
    },
    companyUsers(): any {
      return this.$store.getters['UserStore/getUsersCompany'];
    },
    companyUsersTotals(): any {
      return this.$store.getters['UserStore/getUsersCompanyTotals']?.amount;
    },
    isCompanyValid(): any {
      return this.companyName.length > 0 && (this.isCompanyFocused || this.companyName.length > 0);
    },
    isNameValid(): any {
      return this.name.length > 0 && (this.isNameFocused || this.name.length > 0);
    },
    isLastNameValid(): any {
      return this.lastname.length > 0 && (this.isLastnameFocused || this.lastname.length > 0);
    },
    isValidEmail(): any {
      const emailRegex =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(this.email) && (this.isEmailFocused || this.email.length > 0);
    },
    isValidEmailPlanning(): any {
      const emailRegex =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return (
        emailRegex.test(this.planimatikAgent) &&
        (this.isPlanimatikAgentFocused || this.planimatikAgent.length > 0)
      );
    },
    isValidCompanyZIP(): any {
      return this.companyZip !== '' && (this.isCompanyZipFocused || this.companyZip !== '');
    },
    isValidCompanyState(): any {
      return this.companyState !== '' && (this.isCompanyStateFocused || this.companyState !== '');
    },
    isValidPhone(): any {
      const phoneRegex = /^\d{9,16}$/;
      return phoneRegex.test(this.phone) && (this.isPhoneFocused || this.phone.length > 0);
    },
    isValidPrefix(): any {
      return this.isPrefixFocused || (this.prefix.length > 0 && this.prefix.length < 5);
    },
    isFormValid(): any {
      const requiredFieldsValid = [
        this.isCompanyValid,
        this.isNameValid,
        this.isLastNameValid,
        this.isValidEmail,
        this.isValidCompanyState,
        this.isValidCompanyZIP,
      ];

      if (this.phone.length > 0) {
        requiredFieldsValid.push(this.isValidPhone);
      }

      if (this.planimatikAgent.length > 0) {
        requiredFieldsValid.push(this.isValidEmailPlanning);
      }

      return requiredFieldsValid.every((field) => field);
    },
    states(): any {
      let result = [];
      if (this.countrySelected !== null) {
        result = this.$store.getters['LoadsStore/getStates'].filter(
          (item: any) =>
            item.country && item.country.id && item.country.id === this.countrySelected,
        );
      }
      return result;
    },
    companyUpdate(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    userUpdate(): any {
      return this.$store.getters['UserStore/getUser'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    getLogo(): any {
      if (this.companyUpdate?.mediaObjects !== undefined) {
        return this.companyUpdate.mediaObjects.find((media) => media.type === 'COMPANY_LOGO');
      }
      return undefined;
    },
    defaultCountrySelected(): any {
      let country = '';
      if (this.countriesList.length > 0 && this.countrySelected) {
        country = this.countriesList.find((country) => country.id === this.countrySelected);
      }
      return country;
    },
    companyIdentifierName() {
      const region = this.defaultCountrySelected?.region?.name
        ? this.defaultCountrySelected?.region?.name
        : '';
      let identifierName = '';
      switch (region) {
        case 'EUROPE':
          identifierName = this.$t('cif');
          break;
        case 'NORTH_AMERICA':
          identifierName = this.$t('id');
          break;
        default:
          identifierName = this.$t('id-cif');
          break;
      }
      return identifierName.toUpperCase();
    },
    trialEndDate() {
      return this.companyUpdate?.trialEndDate ?? new Date();
    },
  },
  watch: {
    userUpdate() {
      this.setForm();
    },
    companyUpdate() {
      this.setForm();
      if (Object.keys(this.companyUpdate).length !== 0) {
        this.$store.dispatch('UserStore/getUsersCompany', {
          companyId: this.companyUpdate.id,
          params: {
            page: this.page,
            maxResults: this.maxResults,
          },
        });
        this.$store.dispatch('UserStore/getUsersCompanyTotals', this.companyUpdate.id);
        this.adminAgentsAssigned = this.companyUpdate.adminAgents;
        this.bolDefaultValue = this.companyUpdate.bolCost || null;
        this.showPlanimatik = this.companyUpdate.showPlanimatikCarrier;

        this.plan = this.companyUpdate.accessPlanType ? this.companyUpdate.accessPlanType : 'BASIC';
        if (!this.isSending && this.companyUpdate.acquisitionSource) {
          this.acquisitionSource = this.companyUpdate.acquisitionSource;
        }
      }
    },
  },
  methods: {
    async updatePricingInfoMargin (newValue) {
      this.pricingInfoMargin = newValue
    },
    async cancelSubscription() {
      this.isCanceling = true;
      await this.$store
        .dispatch(
          'UserStore/AdminCancelPlanSubscription',
          this.userUpdate?.company?.subscription?.id,
        )
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('subscription-type-canceled'), {
            subscription: this.$t(`${this.userUpdate?.company.accessPlanType.toLowerCase()}`),
          });
          this.fetch();
        })
        .catch(() => {
          this.isCanceling = false;
        });
    },
    addNewAgent() {
      this.createEditAgent = 'create';
      this.resetAgent();
      this.newAgentInput = true;
    },
    changePhone(type, phone) {
      switch (type) {
        case 'prefix':
          this.prefix = phone.prefix;
          break;
        case 'number':
          this.phone = phone.number;
          break;
        default:
          return;
      }
    },
    changeContactPhone(type, phone) {
      switch (type) {
        case 'prefix':
          this.contactPhonePrefix = phone.prefix;
          break;
        case 'number':
          this.contactPhone = phone.number;
          break;
        default:
          return;
      }
    },
    selectCountry(value) {
      this.countrySelected = value;
    },
    setDate(value) {
      this.trialEndDateToSend = value;
    },
    setIndexFocus(value) {
      this.currentIndex = value;
    },
    async resetAgent() {
      this.createEditAgent = 'create';
      this.adminAgent = { type: [], email: '' };
      this.resetForm = true;
      setTimeout(() => {
        this.resetForm = false;
      }, 1);
      this.newAgentInput = false;
    },
    toggleEditAgent(agent) {
      this.createEditAgent = 'edit';

      if (!Array.isArray(agent.type)) {
        agent.type = [agent.type];
      }

      this.adminAgent = cloneDeep(agent);
      this.createEditAdminAgent();
    },
    fetch() {
      this.$store.dispatch('UserStore/getUser', { id: this.userUpdate?.id });
      this.$store.dispatch('CompanyStore/getCompanyAdmin', this.userUpdate?.company.id);
    },
    setEmailAgent(event) {
      this.adminAgent.email = event.target.value;
    },
    findAdminAgent(event) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        this.$store
          .dispatch('CompanyStore/getAdminAgents', {
            page: 1,
            maxResults: 100,
            email: event.text,
          })
          .finally(() => (this.isSending = false));
      }, 500);
    },
    async createEditAdminAgent() {
      if (this.createEditAgent === 'create') {
        if (this.isSending || !this.creationFormFilled) {
          return;
        }

        const validation = await (this.$refs.agentForm as any).validate();

        if (!validation.valid) {
          BaseComponent.methods?.showToastError(this.$t('agent-create-form-error'));
          return;
        }
      }

      this.isSending = true;
      this.$store
        .dispatch(
          `CompanyStore/${
            this.createEditAgent === 'create' ? 'postAdminAgents' : 'putAdminAgents'
          }`,
          this.adminAgent,
        )
        .then(async (response) => {
          BaseComponent.methods?.showToastSuccess(
            this.$t(this.createEditAgent === 'create' ? 'agent-created' : 'agent-edited'),
          );
          if (this.createEditAgent === 'create') {
            // delete response.id;
            this.isSending = false;
            this.assignAdminAgent({ item: response }, false);
          } else {
            this.resetAgent();
            this.$store.dispatch('UserStore/getUser', { id: this.companyUpdate.owner.id });
            this.$store.dispatch('CompanyStore/getCompanyAdmin', this.companyUpdate.id);
            this.$emit('update');
          }
          this.createEditAgent = 'create';
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.isSending = false;
          this.newAgentInput = false;
        });
    },
    assignAdminAgent(agent, skip) {
      if (this.isSending && !skip) {
        return;
      }
      if (this.type === 'creation' && !skip) {
        this.adminAgentsAssigned.push(agent.item);
      } else {
        this.isSending = true;
        this.$store
          .dispatch('CompanyStore/assignAdminAgents', {
            id: this.companyUpdate.id,
            body: { adminAgentId: agent.item ? agent.item.id.toString() : agent.id.toString() },
          })
          .then(async (response) => {
            this.newAgentInput = false;
            BaseComponent.methods?.showToastSuccess(this.$t('agent-assigned'));
            this.adminAgent = agent.item;
            this.$store.dispatch('UserStore/getUser', { id: this.companyUpdate.owner.id });
            this.$store.dispatch('CompanyStore/getCompanyAdmin', this.companyUpdate.id);
            this.$emit('update');
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          })
          .finally(() => {
            this.createEditAgent = 'create';
            this.isSending = false;
          });
      }
    },
    deassignAdminAgent(agent) {
      if (this.isSending) {
        return;
      }
      if (this.type === 'creation') {
        let i = this.adminAgentsAssigned.map((item) => item.id).indexOf(agent.id); // find index of your object
        this.adminAgentsAssigned.splice(i, 1); // remove it from array
      } else {
        this.isSending = true;
        this.$store
          .dispatch('CompanyStore/deassignAdminAgents', {
            id: this.companyUpdate.id,
            body: { adminAgentId: agent.id.toString() },
          })
          .then(async (response) => {
            BaseComponent.methods?.showToastSuccess(this.$t('agent-unassigned'));
            this.$store.dispatch('UserStore/getUser', { id: this.companyUpdate.owner.id });
            this.$store.dispatch('CompanyStore/getCompanyAdmin', this.companyUpdate.id);
            this.$emit('update');
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          })
          .finally(() => {
            this.isSending = false;
          });
      }
    },
    async getFileCompany(media: any): Promise<File> {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFile', {
        id: media.id,
        class: 'COMPANY',
      });
      return new File([response], media.name, { type: media.mimeType });
    },
    async viewLogo() {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: this.companyUpdate?.mediaObjects[0].id,
        class: this.companyUpdate?.mediaObjects[0].entityClass,
      });
      this.logoURL = URL.createObjectURL(response);
      const newWindow = window.open(this.logoURL, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-`;
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    },
    handleChange(type: string) {
      this.isNewLogo = true;
      this.logo.file = (this.$refs.logoFile as any).files[0];
    },
    async getCompanyLogo() {
      if (this.getLogo) {
        const media = this.getLogo;
        if (media && !this.logo.file) {
          const file = await this.getFileCompany(media);
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file as any);
          (this.$refs.logoFile as any).files = dataTransfer.files;
          if (file !== undefined) {
            this.logo.file = file as any;
            this.logoFile = file as any;
          }
        }
        this.getLogoPreview();
      }
    },
    async getLogoPreview() {
      const promises: Promise<any>[] = [];
      if (
        this.logoURL === '' &&
        this.companyUpdate?.mediaObjects !== undefined &&
        this.companyUpdate?.mediaObjects !== null
      ) {
        const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
          id: this.companyUpdate?.mediaObjects[0]?.id,
          class: this.companyUpdate?.mediaObjects[0]?.entityClass,
        });
        this.logoURL = URL.createObjectURL(result);
      }
      const media = this.companyUpdate.mediaObjects;
      if (media && !this.logo.file) {
        const file = await this.getFileCompany(media);
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        (this.$refs.logoFile as any).files = dataTransfer.files;
        this.logo.file = file;
        this.logoFile = file;
      }

      promises.push(
        this.$store.dispatch('ShipmentStore/thumbnailBig', {
          id: this.companyUpdate?.mediaObjects[0]?.id,
          class: this.companyUpdate?.mediaObject[0]?.entityClass,
        }),
      );
    },
    moreUsers() {
      this.page = this.page + 1;
      this.$store.dispatch('UserStore/getUsersCompany', {
        companyId: this.companyUpdate.id,
        params: {
          page: this.page,
          maxResults: this.maxResults,
        },
      });
    },
    fetchUsers() {
      this.$store.dispatch('UserStore/getUsersCompany', {
        companyId: this.companyUpdate.id,
        params: {
          page: this.page,
          maxResults: this.maxResults,
        },
      });
      this.$store.dispatch('UserStore/getUsersCompanyTotals', this.companyUpdate.id);
    },
    deleteUser(isDelete) {
      if (isDelete) {
        this.$store
          .dispatch('UserStore/deleteUser', this.userToDelete)
          .then(() => {
            this.fetchUsers();
          })
          .catch((err) => {
            if (err?.response?.data?.detail) {
              BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            }
          })
          .finally(() => {
            this.showDelete = false;
          });
      } else {
        this.showDelete = false;
      }
    },
    openOkModal(id) {
      this.showDelete = true;
      this.userToDelete = id;
    },
    toggleNewUser(bool) {
      this.companyForUser = this.userToEdit;
      this.openNewUser = bool;
      if (!bool) {
        this.userToEdit = null;
      }
    },
    openEditUser(user) {
      this.userToEdit = user;
      this.toggleNewUser(true);
    },
    closeModal() {
      this.$store.commit('CompanyStore/setCompany', {});
      this.$store.commit('UserStore/setUser', {});
      this.$emit('hideModal');
    },
    async submit() {
      const validation = await (this.$refs.form as any).validate();

      if (!validation.valid) {
        BaseComponent.methods?.showToastError(this.$t('field-are-required'));
        return;
      }

      if (this.isSending) {
        return;
      }
      this.isSending = true;
      if (this.type === 'creation') {
        let postNewUser = {
          company: {
            accessPlanType: this.plan,
            cargobotAccountManagerEmail: this.planimatikAgent,
            contactName: this.contactName,
            contactEmail: this.contactEmail,
            contactPhone: this.contactPhone,
            contactPhonePrefix: this.contactPhonePrefix,
            identificationNumber: this.identificationNumber,
            bolCost: this.bolDefaultValue ?? 0,
            location: {
              address: this.companyAddress,
              city: this.companyZip,
              state: { id: this.companyState },
            },
            name: this.companyName,
            country: {
              id: this.countrySelected,
            },
          },
          email: this.email,
          name: this.name,
          phone: this.phone,
          phonePrefix: this.prefix,
          showPlanimatikCarrier: this.showPlanimatik,
          surname: this.lastname,
        };

        this.$store
          .dispatch('UserStore/postNewShipper', postNewUser)
          .then(async (response) => {
            this.$store.commit('CompanyStore/setCompany', response.company);

            if (this.defaultCustomPlanChecked) {
              let customPlan = {
                subscriptionPrice: {
                  unitAmount: this.planTotalCost,
                  recurringInterval: this.planType,
                  freeLoads: this.planFreeLoads,
                },
                observations: this.planExtraInfo,
                company: { id: response.company.id },
                endDate: this.planExpiration,
              };

              await this.$store.dispatch(
                'UserStore/AdminPostCreateNewPlanSubscription',
                customPlan,
              );
            }

            BaseComponent.methods?.showToastSuccess(this.$t('shipper-created'));

            this.$store.dispatch('CompanyStore/putAcquisitionSource', {
              id: this.companyUpdate.id,
              body: {
                acquisitionSource: this.acquisitionSource,
              },
            });

            if (this.adminAgentsAssigned && this.adminAgentsAssigned.length > 0) {
              this.adminAgentsAssigned.forEach((agent) => {
                this.assignAdminAgent(agent, true);
              });
            }

            this.$emit('showActivationModal', response.id);
            this.$emit('update');
            this.$emit('hideModal');
          })
          .catch((err) => {
            const error = err?.response?.data?.detail ?? err?.response?.data['hydra:description'];
            BaseComponent.methods?.showToastError(error);
          })
          .finally(() => {
            this.isSending = false;
          });
      } else {
        const { id: userId, email: oldEmail } = this.userUpdate;
        const { id: companyId, accessPlanType } = this.companyUpdate;

        if (
          this.defaultCustomPlanChecked &&
          this.userUpdate?.company?.accessPlanType === 'CUSTOM'
        ) {
          let customPlan = {
            subscriptionPrice: {
              id: this.userUpdate?.company?.subscription?.subscriptionPrice?.id,
              unitAmount: this.planTotalCost,
              recurringInterval: this.planType,
              freeLoads: this.planFreeLoads,
            },
            observations: this.planExtraInfo,
            company: { id: this.companyUpdate.id },
            endDate: this.planExpiration,
          };

          await this.$store.dispatch('UserStore/AdminPutCreateNewPlanSubscription', {
            id: this.userUpdate?.company?.subscription?.id,
            body: customPlan,
          });
        }

        const user: User = {
          name: this.name,
          surname: this.lastname,
          phone: this.phone,
          phonePrefix: this.prefix,
        };

        if (this.email !== oldEmail) {
          user.email = this.email;
        }
        const company = {
          name: this.companyName,
          accessPlanType: this.plan,
          bolCost: this.bolDefaultValue ?? 0,
          contactName: this.contactName,
          contactEmail: this.contactEmail,
          contactPhone: this.contactPhone,
          contactPhonePrefix: this.contactPhonePrefix,
          identificationNumber: this.identificationNumber,
          location: {
            address: this.companyAddress,
            city: this.companyZip,
            state: { id: this.companyState },
          },
          pricingInfoMargin: this.pricingInfoMargin,
          showPlanimatikCarrier: this.showPlanimatik,
          country: {
            id: this.countrySelected,
          },
        };

        try {
          await this.$store.dispatch('UserStore/putUser', { id: userId, user });
          await this.$store.dispatch('CompanyStore/putCompany', { id: companyId, company });
          if (accessPlanType === 'TRIAL' || accessPlanType === 'TRIAL_EXPIRED') {
            await this.$store.dispatch('CompanyStore/putTrialEndDate', {
              id: companyId,
              params: {
                trialEndDate: this.trialEndDateToSend,
              },
            });
          }
          this.$store
            .dispatch('CompanyStore/putAcquisitionSource', {
              id: this.companyUpdate.id,
              body: {
                acquisitionSource: this.acquisitionSource,
              },
            })
            .finally(() => {
              this.isSending = false;
            });
          this.saveLogo();
          await this.savePricingInfo(companyId)
          BaseComponent.methods?.showToastSuccess(this.$t('shipper-updated'));
          this.$emit('update');
          this.$emit('hideModal');
        } catch (err) {
          const { violations } = (err as { response: { data: { violations: any[] } } }).response
            .data;
          const violationsMessages = violations
            .map((v) => `${v.propertyPath}: ${v.message}`)
            .join(', ');
          BaseComponent.methods?.showToastError(violationsMessages);
        }
      }
    },
    async savePricingInfo (companyId) {
      await this.$store.dispatch('CompanyStore/putCompanyPricingMargin', {
        id: companyId,
        pricingInfoMargin: this.pricingInfoMargin
      });
    },
    saveLogo() {
      if (this.logo.file !== this.logoFile) {
        if (this.getLogo !== undefined) {
          if (this.getLogo.id !== undefined) {
            this.postUpdateMediaObjectCompany('COMPANY_LOGO');
          } else {
            this.postMediaObjectCompany('COMPANY_LOGO');
          }
        } else {
          this.postMediaObjectCompany('COMPANY_LOGO');
        }
      }
    },
    postMediaObjectCompany(type: string) {
      // let logo = {} as any;
      // switch (type) {
      //   case 'logo':
      //     // logo = this.logo;
      //     break;
      // }
      // logo = this.logo
      if (this.logo.file) {
        this.$store.dispatch('CompanyStore/postMediaObjectCompany', {
          entityId: this.userUpdate.company.id,
          file: this.logo.file,
          type: type,
        });
      }
    },
    postUpdateMediaObjectCompany(type: string) {
      // let logo = {} as any;
      // let mediaId = 0 as any;
      // switch (type) {
      //   case 'logo':
      //     // logo = this.logo;
      //     // mediaId = this.getLogo !==undefined ? this.getLogo.id : null;
      //     break;
      // }
      if (this.getLogo.id !== undefined && this.logo.file !== undefined) {
        this.$store.dispatch('CompanyStore/postUpdateMediaObjectCompany', {
          id: this.getLogo.id,
          file: this.logo.file,
        });
      }
    },
    setForm() {
      if (Object.keys(this.userUpdate).length !== 0) {
        this.getCompanyLogo();

        const { name, surname: lastname, email, phonePrefix, phone, company } = this.userUpdate;
        const {
          name: companyName,
          location,
          identificationNumber,
          contactName,
          contactEmail,
          contactPhone,
          contactPhonePrefix,
          country,
          pricingInfoMargin
        } = company || {};
        const { city: companyZip, address: companyAddress, state } = location || {};

        if (this.userUpdate?.company?.accessPlanType === 'CUSTOM') {
          this.defaultCustomPlanChecked = true;
          this.planTotalCost =
            this.userUpdate?.company?.subscription?.subscriptionPrice?.unitAmount;
          this.planType =
            this.userUpdate?.company?.subscription?.subscriptionPrice?.recurringInterval;
          this.planFreeLoads = this.userUpdate?.company?.freeBolAssigned;
          this.planExpiration = this.userUpdate?.company?.subscription?.endDate;
          this.planExtraInfo = this.userUpdate?.company?.subscription?.observations;
        }

        this.pricingInfoMargin = this.$store.getters['CompanyStore/getCompany'].pricingInfoMargin ? this.$store.getters['CompanyStore/getCompany'].pricingInfoMargin : null

        this.name = name || '';
        this.lastname = lastname || '';
        this.email = email || '';
        this.prefix = phonePrefix || '1';
        this.phone = phone || '';
        this.companyName = companyName || '';
        this.companyZip = companyZip || '';
        this.companyAddress = companyAddress || '';
        this.companyState = state?.id || null;
        this.countrySelected = country.id || null;
        this.planimatikAgent = this.companyUpdate.cargobotAccountManagerEmail
          ? this.companyUpdate.cargobotAccountManagerEmail
          : '';
        this.identificationNumber = identificationNumber;
        this.contactName = contactName;
        this.contactEmail = contactEmail;
        this.contactPhone = contactPhone;
        this.contactPhonePrefix = contactPhonePrefix;
        this.creditGranted =
          this.companyUpdate.creditGranted === 0 ? null : this.companyUpdate.creditGranted;
        this.trialEndDateToSend = this.companyUpdate.trialEndDate;
      }
    },
    toggleLoadsCreditModal(value) {
      this.isLoadsCreditModal = value;
    },
  },
});
