import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full flex justify-center items-center plan-gap-sm" }
const _hoisted_2 = { class: "flex flex-col justify-center items-center plan-gap-lg" }
const _hoisted_3 = {
  class: "plan-t-base plan-t-prim-100 font-semibold",
  style: {"margin-top":"-36px"}
}
const _hoisted_4 = {
  key: 2,
  class: "flex justify-center items-center plan-gap-sm"
}
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_formatted_number = _resolveComponent("plan-formatted-number")!
  const _component_plan_carrier_default_modal = _resolveComponent("plan-carrier-default-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_button, {
      onOnClick: _ctx.openModal,
      disabled: _ctx.loadingAcceptBookNow,
      type: _ctx.bookNow.enabled ? 'primary-outline' : 'primary',
      label: _ctx.$t('accept-quote'),
      class: "w-full"
    }, null, 8, ["onOnClick", "disabled", "type", "label"]),
    (_ctx.bookNow.enabled && _ctx.bookNow.price)
      ? (_openBlock(), _createBlock(_component_plan_button, {
          key: 0,
          onOnClick: _ctx.acceptBookNow,
          loading: _ctx.loadingAcceptBookNow,
          type: "primary",
          label: _ctx.$t('book-now-price', { price: _ctx.bookNow.price }),
          class: "w-full"
        }, null, 8, ["onOnClick", "loading", "label"]))
      : _createCommentVNode("", true),
    (_ctx.showSubmitBidModal)
      ? (_openBlock(), _createBlock(_component_plan_carrier_default_modal, {
          key: 1,
          onClose: _ctx.close,
          title: _ctx.$t('submit-bid'),
          size: "sm-auto",
          "hide-close-button": ""
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('place-your-bid')), 1),
              (!_ctx.isInputOnFocus)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focusInput && _ctx.focusInput(...args))),
                    class: _normalizeClass(["w-full plan-t-h1 font-bold flex justify-center items-center", _ctx.bid > 0 ? 'plan-t-prim-500' : 'plan-t-prim-50'])
                  }, _toDisplayString(_ctx.getFormattedValue), 3))
                : _withDirectives((_openBlock(), _createElementBlock("input", {
                    key: 1,
                    type: "number",
                    ref: "inputRef",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.bid) = $event)),
                    onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeBid && _ctx.changeBid(...args))),
                    onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.changeBid && _ctx.changeBid(...args))),
                    class: "border-none plan-t-h1 font-bold text-center w-full plan-form-transparent",
                    style: {"font-size":"36px !important","height":"54px"},
                    placeholder: "0"
                  }, null, 544)), [
                    [_vModelText, _ctx.bid]
                  ]),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.pricePerDistance), 1),
              (_ctx.loadData.competitiveQuoting)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_ctx.loadData.shipment.bestBid)
                      ? (_openBlock(), _createBlock(_component_plan_icon, {
                          key: 0,
                          name: "flame",
                          "icon-color": "#151830",
                          "is-svg": ""
                        }))
                      : _createCommentVNode("", true),
                    _createElementVNode("p", _hoisted_5, [
                      (_ctx.loadData.shipment.bestBid)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                            _createTextVNode(_toDisplayString(_ctx.$t('best-quote')) + ": ", 1),
                            _createVNode(_component_plan_formatted_number, {
                              number: _ctx.loadData.shipment.bestBid,
                              unit: "currency"
                            }, null, 8, ["number"])
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('be-the-first')), 1))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.close,
                disabled: _ctx.loadingSendRequest,
                type: "cancel",
                label: _ctx.$t('cancel')
              }, null, 8, ["onOnClick", "disabled", "label"]),
              (!_ctx.update)
                ? (_openBlock(), _createBlock(_component_plan_button, {
                    key: 0,
                    onOnClick: _ctx.sendRequest,
                    loading: _ctx.loadingSendRequest,
                    disabled: _ctx.bid <= 0,
                    type: "primary",
                    label: _ctx.$t('submit'),
                    style: {"width":"100px"}
                  }, null, 8, ["onOnClick", "loading", "disabled", "label"]))
                : (_openBlock(), _createBlock(_component_plan_button, {
                    key: 1,
                    onOnClick: _ctx.updateRequest,
                    loading: _ctx.loadingSendRequest,
                    disabled: _ctx.bid <= 0,
                    type: "primary",
                    label: _ctx.$t('submit'),
                    style: {"width":"100px"}
                  }, null, 8, ["onOnClick", "loading", "disabled", "label"]))
            ])
          ]),
          _: 1
        }, 8, ["onClose", "title"]))
      : _createCommentVNode("", true)
  ]))
}