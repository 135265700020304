import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  activeForm: null,
  averageStats: null,
  datePriceStats: null,
  pickup: null,
  pickupDate: '',
  delivery: null,
  deliveryDate: '',
  trailerId: null,
  commodity: '',
  weight: null,
  units: null,
  packageType: null,
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
