
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import { locationString, locationObject } from '@/utils/formatLocation';
import { useStore } from 'vuex';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import StopData from '@/modules/planning/newLoad/_components/form/StopData.vue';
import PlanLoadFormMultipleLoads from '@/components/loads/form/domestic/PlanLoadFormMultipleLoads.vue';
import PlanLoadFormSpecialRequirements from '@/components/loads/form/domestic/PlanLoadFormSpecialRequirements.vue';
import PlanLoadFormTrailerAndCommodities from '@/components/loads/form/domestic/PlanLoadFormTrailerAndCommodities.vue';
import PlanLoadFormStop from '@/components/loads/form/domestic/PlanLoadFormStop.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanLoadFormAdditionalStops from '@/components/loads/form/domestic/PlanLoadFormAdditionalStops.vue';
import CompanyModal from '@/components/modals/CompanyModal.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import AddCustomer from '@/modules/planning/newLoad/_components/AddCustomer.vue';
import PlanLoadFormAttachDocuments from '@/components/loads/form/domestic/PlanLoadFormAttachDocuments.vue';
export default defineComponent({
  name: 'PlanNewMxDomestic',
  components: {
    PlanLoadFormAttachDocuments,
    AddCustomer,
    PlanTextButton,
    CompanyModal,
    PlanLoadFormAdditionalStops,
    PlanIcon,
    PlanLoadFormStop,
    PlanLoadFormTrailerAndCommodities,
    PlanLoadFormSpecialRequirements,
    PlanLoadFormMultipleLoads,
    PlanInputText
  },
  emits: ['changeView'],
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      poNumber: '',
      showCompanyModal: false
    });

    const actualWidth = computed(() => {
      return store.getters['UserStore/getActualWidth']
    })

    const actualTitleMaxWidth = computed(() => {
      return actualWidth.value < 700 ? '100px':'250px'
    })

    const additionalStops = computed(() => {
      return store.getters['NewFtlLoad/getAdditionalStops']
    })

    const changeView = (component: string) => {
      emit('changeView', component)
    }

    const changeMultipleLoads = (quantity: number) => {
      store.commit('NewFtlLoad/setMultipleLoads', quantity);
    };

    const changePoNumber = () => {
      store.commit('NewFtlLoad/setPoNumber', data.poNumber);
    };

    const changeSpecialRequirements = (newSpecialRequirements: any) => {
      store.commit('NewFtlLoad/setSpecialRequirements', newSpecialRequirements);
    };

    const currentUser = () => {
      return store.getters['UserStore/getCurrentUser']
    }

    const stops = computed(() => {
      return store.state.NewFtlLoad.stops;
    });

    const finalDate = computed(() => {
      return stops.value[1].executionDate;
    });

    const getPickupDate = computed(() => {
      return store.getters['NewFtlLoad/getPickupDate']
    })

    const hasPortAirPortStopTrue = computed(() => {
      return stops.value.some((item) => item.portStop || item.airportStop) || false;
    });

    const handleStopPortAirPort = (pick, stop) => {
      const isUSA = store.getters['CountryStore/getCountry'] === 'US';

      if (pick === 'port') {
        stop.airportStop = false;
        stop.portStop = !stop.portStop;
      } else if (pick === 'airport') {
        stop.portStop = false;
        stop.airportStop = !stop.airportStop;
      }

      if (isUSA) {
        updateSpecialRequirements();
      }
    };

    const isValidatedForm = computed(() => {
      return store.getters['NewFtlLoad/isValidatedForm']
    })

    const updateSpecialRequirements = () => {
      if (!hasPortAirPortStopTrue.value) {
        store.state.NewFtlLoad.specialRequirements.TWICCard = false;
      } else {
        store.state.NewFtlLoad.specialRequirements.TWICCard = true;
      }
    };

    const setStopLocation = (stop, addressData) => {
      stop.location = locationObject(addressData);
    };

    const updateCustomers = (value: any) => {
      store.commit('NewFtlLoad/setCustomers', value)
    }

    const updateHour = (stop, startTime, endTime, selectedAppointment) => {
      if (selectedAppointment !== 'NONE') {
        if (selectedAppointment === 'APPT') {
          stop.executionWindowStartTime = startTime ? startTime : null;
        } else {
          stop.executionWindowStartTime = startTime ? startTime : null;
          stop.executionWindowEndTime = endTime ? endTime : null;
        }
      } else {
        stop.executionWindowStartTime = null;
        stop.executionWindowEndTime = null;
      }
    };

    const updateLumperFee = (stop, isLumperFee, price) => {
      if (isLumperFee) {
        stop.lumperFeePrice = price;
      } else {
        stop.lumperFeePrice = null;
      }
    };

    const updateObservations = (event, stop) => {
      stop.observations = event.target.innerText;
    };

    const updatePickUp = (stopData: any) => {
      store.commit('NewFtlLoad/setPickup', stopData)
    }

    const updateDelivery = (stopData: any) => {
      store.commit('NewFtlLoad/setDelivery', stopData)
    }

    const updatePoNumber = (event, stop) => {
      stop.poNumber = event.target.innerText;
    };

    const countrySelected = (country, stop) => {
      stop.defaultCountrySelected = country;
    };

    const handleChangeDate = (stop, date) => {
      stop.executionDate = date;
    };

    const updateWarehouse = (
      stop,
      address,
      companyName,
      lumperFee,
      lumperFeePrice,
      observations,
      poNumber,
      phoneNumber,
      phonePrefix,
      contactName,
      warehouseId,
      executionWindowStartTime,
      executionWindowEndTime,
    ) => {
      stop.companyName = companyName;
      stop.location.address = address;
      stop.lumperFee = lumperFee;
      stop.lumperFeePrice = lumperFeePrice;
      stop.observations = observations;
      stop.poNumber = poNumber;
      stop.phoneNumber = phoneNumber;
      stop.phonePrefix = phonePrefix;
      stop.contact = contactName;
      stop.executionWindowStartTime = executionWindowStartTime;
      stop.executionWindowEndTime = executionWindowEndTime;
      warehouseId !== null
        ? (stop.warehouseTemplate = { id: warehouseId })
        : (stop.warehouseTemplate = null);
    };

    const initialDate = computed(() => {
      return stops.value[1].executionDate;
    });

    return {
      ...toRefs(data),
      actualWidth,
      actualTitleMaxWidth,
      additionalStops,
      store,
      changeView,
      changeMultipleLoads,
      changePoNumber,
      changeSpecialRequirements,
      countrySelected,
      currentUser,
      finalDate,
      getPickupDate,
      handleChangeDate,
      initialDate,
      isValidatedForm,
      handleStopPortAirPort,
      setStopLocation,
      stops,
      updateCustomers,
      updateHour,
      updateLumperFee,
      updateObservations,
      updateDelivery,
      updatePickUp,
      updatePoNumber,
      updateWarehouse,
    };
  },
});
