import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "md:text-3xl font-bold" }
const _hoisted_2 = { class: "flex flex-wrap -mx-3 mb-6" }
const _hoisted_3 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-0" }
const _hoisted_4 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "name"
}
const _hoisted_5 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-0" }
const _hoisted_6 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "last-name"
}
const _hoisted_7 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-0" }
const _hoisted_8 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "company"
}
const _hoisted_9 = { class: "w-full md:w-6/12 px-3 mb-6 md:mb-0" }
const _hoisted_10 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "email"
}
const _hoisted_11 = {
  key: 0,
  role: "alert",
  class: "text-rose-500"
}
const _hoisted_12 = { class: "w-full md:w-6/12 px-3 mb-6 md:mb-0" }
const _hoisted_13 = {
  key: 0,
  class: "w-full md:w-1/2 px-3 mb-6 md:mb-3"
}
const _hoisted_14 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "dot-number"
}
const _hoisted_15 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_16 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "mc-number"
}
const _hoisted_17 = {
  key: 1,
  class: "w-full md:w-1/2 px-3 mb-6 md:mb-3"
}
const _hoisted_18 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "cif"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_phone_number_selector = _resolveComponent("phone-number-selector")!
  const _component_plan_new_contact_tags = _resolveComponent("plan-new-contact-tags")!
  const _component_Form = _resolveComponent("Form")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    show: _ctx.modalState,
    modalSize: "medium",
    onCallbackClose: _ctx.onClickClose
  }, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('edit-contact')), 1)
    ]),
    main: _withCtx(() => [
      _createVNode(_component_Form, {
        ref: "form",
        class: "w-full"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(`${_ctx.$t('firstname')} *`), 1),
              _createVNode(_component_Field, {
                class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                type: "text",
                name: "name",
                id: "name",
                modelValue: _ctx.localContact.firstName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localContact.firstName) = $event)),
                rules: "required|alpha_spaces|max:256"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                name: "name",
                class: "text-rose-500"
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(`${_ctx.$t('lastname')}*`), 1),
              _createVNode(_component_Field, {
                class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                id: "last-name",
                type: "text",
                modelValue: _ctx.localContact.lastName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localContact.lastName) = $event)),
                name: "lastname",
                rules: "required|max:256"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                name: "lastname",
                class: "text-rose-500"
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(`${_ctx.$t('company')}*`), 1),
              _createVNode(_component_Field, {
                class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                id: "company",
                type: "text",
                modelValue: _ctx.localContact.companyName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localContact.companyName) = $event)),
                name: "company",
                rules: "required|max:256"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                name: "company",
                class: "text-rose-500"
              })
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(`${_ctx.$t('email')}*`), 1),
              _createVNode(_component_Field, {
                class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                id: "email",
                rules: "email|required",
                type: "text",
                modelValue: _ctx.localContact.email,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localContact.email) = $event)),
                name: "email"
              }, null, 8, ["modelValue"]),
              (
                _ctx.localContact.validation.message.includes('Email has been used in your contact list')
              )
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('contact-email-repeated')), 1))
                : (_openBlock(), _createBlock(_component_ErrorMessage, {
                    key: 1,
                    name: "email",
                    class: "text-rose-500"
                  }))
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_phone_number_selector, {
                "classes-for-labels": 'block uppercase tracking-wide text-gray-700 text-xs font-bold',
                prefix: `${_ctx.localContact.prefix}`,
                number: _ctx.localContact.phone,
                onChangePhone: _ctx.changePhone
              }, null, 8, ["prefix", "number", "onChangePhone"])
            ]),
            (_ctx.localContact.type === 'CARRIER')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("label", _hoisted_14, _toDisplayString(`${_ctx.$t('dot-number')}*`), 1),
                  _createVNode(_component_Field, {
                    class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                    id: "dot-number",
                    type: "text",
                    modelValue: _ctx.localContact.dotNumber,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localContact.dotNumber) = $event)),
                    name: "dotNumber",
                    rules: "required|max:256"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "dotNumber",
                    class: "text-rose-500"
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('mc-number')), 1),
              _createVNode(_component_Field, {
                class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                id: "mc-number",
                type: "text",
                modelValue: _ctx.localContact.mcNumber,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localContact.mcNumber) = $event)),
                name: "mcNumber",
                rules: "max:256"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                name: "mcNumber",
                class: "text-rose-500"
              })
            ]),
            (_ctx.isEuropeCompany())
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('cif-carrier')), 1),
                  _createVNode(_component_Field, {
                    class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                    id: "cif-carrier",
                    type: "text",
                    modelValue: _ctx.localContact.identificationNumber,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localContact.identificationNumber) = $event)),
                    name: "cif-carrier",
                    rules: "max:256"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "cif-carrier",
                    class: "text-rose-500"
                  })
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_plan_new_contact_tags, {
              onChange: _ctx.changeContactTags,
              previousTags: _ctx.localContact.tags ? JSON.stringify(_ctx.localContact.tags) : ''
            }, null, 8, ["onChange", "previousTags"])
          ])
        ]),
        _: 1
      }, 512),
      _createVNode(_component_plan_button, {
        onOnClick: _ctx.saveContactChanges,
        class: "w-1/2 pl-2",
        label: _ctx.$t('update'),
        loading: _ctx.loading,
        size: 'auto'
      }, null, 8, ["onOnClick", "label", "loading"])
    ]),
    _: 1
  }, 8, ["show", "onCallbackClose"]))
}