
import { computed, defineComponent, onMounted, reactive, toRefs, watch } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import PlanHeaderSearch from '@/components/filters/PlanHeaderSearch.vue';
import { debounce } from 'lodash';

export default defineComponent({
  name: 'SelectRecipientsModal',
  components: { PlanHeaderSearch, PlanDefaultTable, PlanButton, PlanDefaultModal },
  emits: ['close', 'update'],
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const data = reactive({
      headers: [
        { label: translate.t('company'), value: 'company', sort: 'asc' },
        { label: translate.t('name'), value: 'name' },
      ],
      loadingContacts: false,
      searchCompanyQuery: {
        type: 'CARRIER|BROKER',
        name: '',
        page: 1,
        sortBy: '',
        tags: '',
      },
    });

    const changeSelectedContacts = (items: any) => {
      store.commit('CompanyStore/setContactsBlastEmail', items);
      emit('update');
    };

    const close = () => {
      emit('close');
    };

    const contacts = computed(() => {
      return store.getters['UserStore/carrierContactList'];
    });

    const getContactList = () => {
      data.loadingContacts = true;
      store
        .dispatch('UserStore/carrierContactList', data.searchCompanyQuery)
        .then(() => {
          //
        })

        .finally(() => {
          data.loadingContacts = false;
        });
    };

    const debouncedGetContactList = debounce(getContactList, 500);

    const handleInputChange = (event: any) => {
      const inputText = event.value ? event.value.trim() : event.trim();
      store.commit('CompanyStore/searchCompany', inputText);
      data.searchCompanyQuery.name = inputText;
      data.searchCompanyQuery.page = 1;
      debouncedGetContactList();
    };

    const loadMoreContacts = () => {
      data.searchCompanyQuery.page = data.searchCompanyQuery.page + 1;
      getContactList();
    };

    const selectedContacts = computed(() => {
      return store.getters['CompanyStore/getContactsBlastEmail'];
    });

    watch(
      () => store.getters['CompanyStore/getSearchCompany'],
      (newValue, oldValue) => {
        data.searchCompanyQuery.name = store.getters['CompanyStore/getSearchCompany'];
      },
    );

    onMounted(() => {
      data.searchCompanyQuery.name = store.getters['CompanyStore/getSearchCompany'];
    });

    return {
      ...toRefs(data),
      changeSelectedContacts,
      close,
      contacts,
      handleInputChange,
      loadMoreContacts,
      selectedContacts,
    };
  },
});
