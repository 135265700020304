import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"background":"white","position":"absolute","bottom":"0","width":"calc(100vw - 64px)","padding":"16px","box-shadow":"0px 4px 16px 0px #00000040","display":"flex","justify-content":"center","align-items":"center"}
}
const _hoisted_2 = { class: "flex justify-center items-center font-bold space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.isSuplanting)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('impersonating-to', { user: $setup.currentUser.email })), 1),
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.finishSuplanting && $setup.finishSuplanting(...args))),
            class: "cursor-pointer"
          }, _toDisplayString(_ctx.$t('impersonating-finish')), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}