
import { defineComponent, reactive, toRefs, computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ActionContext, useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { locationObject } from '@/utils/formatLocation';
import CompanyModal from '@/components/modals/CompanyModal.vue';
import StopData from '@/modules/planning/newLoad/_components/form/StopData.vue';
import SpecialRequirementsModal from '@/modules/rfp/_components/modals/SpecialRequirements.vue';
import { formatDateStringReverse } from '@/utils/formatDates';
import { getIndexAxis } from 'chart.js/dist/core/core.config';

interface leg {
  showCompanyModal: boolean;
  showSpecialRequirements: boolean;
  loadToModal: any;
  load: any;
  minDate: any;
}

export default defineComponent({
  name: 'PlanLegData',
  components: {
    CompanyModal,
    StopData,
    SpecialRequirementsModal,
  },
  props: {
    title: String,
    description: String,
    pickUpCountry: Object as any,
    deliveryCountry: Object as any,
    pickUpIsText: String,
    index: Number as any,
  },
  emits: ['updateDate'],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();
    const stopsRefs = ref([]) as any;

    const data = reactive<leg>({
      showCompanyModal: false,
      showSpecialRequirements: false,
      loadToModal: null as any,
      load: {} as any,
      minDate: null as any,
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentRoute = computed(() => {
      return route.name;
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const getLoadToSend = computed(() => {
      return store.getters['CrossBorderStore/getLoadToSend'];
    });

    const currentLeg = computed(() => {
      const legs = deepClone(getLoadToSend.value?.legs);
      // return legs[props.index as any];
      return legs.sort((a, b) => a.legOrder - b.legOrder)[props.index as any];
    });

    const finalDate = computed(() => {
      return data.load.stops[1].executionDate;
    });
    const initialDate = computed(() => {
      return data.load.stops[0].executionDate;
    });

    const requirements = computed(() => {
      return getLoadToSend.value.specialRequirement;
    });

    const hasPortAirPortStopTrue = computed(() => {
      if (
        getLoadToSend.value &&
        getLoadToSend.value.stops &&
        typeof getLoadToSend.value.stops !== 'undefined'
      ) {
        return getLoadToSend.value.stops.some(
          (item) => item.portStop === true || item.airportStop === true,
        );
      } else {
        return null;
      }
    });

    const nRequirements = computed(() => {
      return data?.loadToModal
        ? Object.values(
            data?.loadToModal?.specialRequirement
              ? data.loadToModal.specialRequirement
              : data?.loadToModal,
          ).filter((value) => typeof value === 'boolean' && value === true).length
        : 0;
    });

    onMounted(() => {
      data.load = currentLeg;
      data.load.stops.forEach((stop, i) => {
        if (currentRoute.value === 'planificationDetail') {
          setTimeout(() => {
            (stopsRefs.value[i] as any).setStopData(stop);
          }, 500);
          setSpecialRequirements();
        } else {
          if (props.pickUpCountry && i === 0) {
            stop.location = { ...stop.loaction, state: { country: props.pickUpCountry } };
            updateLegStop(stop.location, 'location', i);
          }
          if (props.deliveryCountry && i === 1) {
            stop.location = { ...stop.loaction, state: { country: props.deliveryCountry } };
            updateLegStop(stop.location, 'location', i);
          }
        }
      });
    });

    const deepClone = (obj: any) => {
      return JSON.parse(JSON.stringify(obj));
    };

    const updateLegLoad = (value: any, key: string) => {
      store.commit('CrossBorderStore/updateLegFromLoad', {
        key: key,
        value: value,
        index: props.index,
      });
    };

    const updateLegStop = (value: any, key: string, stopIndex: number) => {
      store.commit('CrossBorderStore/updateLegStopFromLoad', {
        key: key,
        value: value,
        legIndex: props.index,
        stopIndex: stopIndex,
      });
    };

    const checkDate = (stop: any, date: any) => {
      stop.executionDate = formatDateStringReverse(date);
      data.load.stops.forEach((stop, index) => {
        if (initialDate.value > stop.executionDate && stop.executionDate) {
          stop.executionDate = initialDate.value;
          (stopsRefs.value[index] as any).updateDate(data.load.stops[0].executionDate);
        }
        updateLegStop(stop.executionDate, 'executionDate', index);
      });
      if (props.index < 2 && stop.executionDate) {
        emit('updateDate', props.index + 1, stop.executionDate);
      }
    };

    const updateMinDate = (date: string) => {
      data.minDate = date;
      // TODO: CHECK THIS SHOULD BE THE NEXT LEG TO UPDATE (log leg index)
      checkDate(data.load.stops[0], date);
    };

    const setSpecialRequirements = () => {
      data.loadToModal = {
        specialRequirement: { ...currentLeg.value.specialRequirement },
      };
    };

    const toggleSpecialRequirements = (bool: boolean, x, y, value: any) => {
      if (!data.loadToModal) {
        data.loadToModal = {
          specialRequirement: {
            id: NaN,
            teamDriver: false,
            bondedPermission: false,
            tsa: false,
            TWICCard: false,
            hazardousLoad: false,
            tir: false,
            oversize: false,
            overweight: false,
            specialPermissions: false,
            specialPermissionsDescription: '',
          },
        };
      }
      if (value) {
        data.loadToModal = value;
        updateLegLoad(value, 'specialRequirement');
      }
      data.showSpecialRequirements = bool;
    };

    const portOrAirport = (pick: string, stop: any, index: number) => {
      const iso =
        stop.type === 'PICKUP_INITIAL' ? props.pickUpCountry.iso : props.deliveryCountry.iso;
      if (pick === 'port') {
        updateLegStop(false, 'airportStop', index);
        updateLegStop(!stop.portStop, 'portStop', index);
        // THIS ONLY FOR USA
        if (iso === 'US') {
          if (!hasPortAirPortStopTrue.value) {
            data.loadToModal['specialRequirement']['TWICCard'] = false;
            updateLegLoad({ ...requirements.value, TWICCard: false }, 'specialRequirement');
          } else {
            data.loadToModal['specialRequirement']['TWICCard'] = true;
            updateLegLoad({ ...requirements.value, TWICCard: true }, 'specialRequirement');
          }
        }
      }
      if (pick === 'airport') {
        updateLegStop(false, 'portStop', index);
        updateLegStop(!stop.airportStop, 'airportStop', index);
        // THIS ONLY FOR USA
        if (iso === 'US') {
          if (!hasPortAirPortStopTrue.value) {
            data.loadToModal['specialRequirement']['TWICCard'] = false;
            updateLegLoad({ ...requirements.value, TWICCard: false }, 'specialRequirement');
          } else {
            data.loadToModal['specialRequirement']['TWICCard'] = true;
            updateLegLoad({ ...requirements.value, TWICCard: true }, 'specialRequirement');
          }
        }
      }
    };

    const setStopLocation = (stop, addressData, index) => {
      stop.location = locationObject(addressData);
      updateLegStop(stop.location, 'location', index);
    };

    const updateHour = (stop, startTime, endTime, selectedAppointment) => {
      if (selectedAppointment !== 'NONE') {
        if (selectedAppointment === 'APPT') {
          stop.executionWindowStartTime = startTime ? startTime : null;
        } else {
          stop.executionWindowStartTime = startTime ? startTime : null;
          stop.executionWindowEndTime = endTime ? endTime : null;
        }
      } else {
        stop.executionWindowStartTime = null;
        stop.executionWindowEndTime = null;
      }
      updateLegLoad(data.load.stops, 'stops');
    };

    const updateLumperFee = (stop, isLumperFee, price) => {
      if (isLumperFee) {
        stop.lumperFeePrice = price;
      } else {
        stop.lumperFeePrice = null;
      }
      updateLegLoad(data.load.stops, 'stops');
    };

    const updateObservations = (event, stop) => {
      stop.observations = event.target.innerText;
      updateLegLoad(data.load.stops, 'stops');
    };

    const updatePoNumber = (event, stop) => {
      stop.poNumber = event.target.innerText;
      updateLegLoad(data.load.stops, 'stops');
    };

    const updateWarehouse = (
      stop,
      address,
      companyName,
      lumperFee,
      lumperFeePrice,
      observations,
      poNumber,
      phoneNumber,
      phonePrefix,
      contactName,
      warehouseId,
      executionWindowStartTime,
      executionWindowEndTime,
    ) => {
      stop.companyName = companyName;
      stop.location.address = address;
      stop.lumperFee = lumperFee;
      stop.lumperFeePrice = lumperFeePrice;
      stop.observations = observations;
      stop.poNumber = poNumber;
      stop.phoneNumber = phoneNumber;
      stop.phonePrefix = phonePrefix;
      stop.contact = contactName;
      stop.executionWindowStartTime = executionWindowStartTime;
      stop.executionWindowEndTime = executionWindowEndTime;
      warehouseId !== null
        ? (stop.warehouseTemplate = { id: warehouseId })
        : (stop.warehouseTemplate = null);
      updateLegLoad(data.load.stops, 'stops');
    };

    const countrySelected = (country: any, stop: any) => {
      stop.defaultCountrySelected = country;
      updateLegLoad(data.load.stops, 'stops');
    };

    return {
      ...toRefs(data),
      currentUser,
      checkDate,
      finalDate,
      initialDate,
      nRequirements,
      portOrAirport,
      setStopLocation,
      stopsRefs,
      updateHour,
      updateLumperFee,
      updateMinDate,
      updateObservations,
      updatePoNumber,
      updateWarehouse,
      countrySelected,
      toggleSpecialRequirements,
    };
  },
});
