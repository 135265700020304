
import { computed, defineComponent, reactive, toRefs, ref, onMounted, onUnmounted } from 'vue';
import SidebarButton from '@/components/sidebar/SidebarButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import SidebarUser from '@/components/sidebar/SidebarUser.vue';
import { useStore } from 'vuex';
import * as Routing from '@/router/Routing';
import SidebarContentInMobile from '@/components/sidebar/SidebarContentInMobile.vue';
import {
  openLoadsList,
  openRequestDemoList,
  openTestDemo,
  openWarehousesList,
} from '@/router/Routing';
import { openShipperDashboard } from '@/router/Routing';
import PlanInstantQuoteButton from '@/components/instantQuote/PlanInstantQuoteButton.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import router from '@/router';
import { useRouter } from 'vue-router';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';

export default defineComponent({
  name: 'SidebarComponent',
  components: {
    PlanButtonIcon,
    PlanButton,
    SidebarContentInMobile,
    SidebarUser,
    PlanIcon,
    SidebarButton,
  },
  props: {
    hidden: { type: Boolean, default: false },
  },
  setup() {
    const router = useRouter();
    const sidebarIsOpenInMobile = ref(false);
    const screenWidth = ref(window.innerWidth);
    const store = useStore();

    const actualRegion = computed(() => {
      return store.getters['UserStore/getRegion'];
    });

    const collapsed = computed(() => {
      return store.getters['UserStore/getCollapsedSidebar'];
    });

    const changeCollapsedSidebarStatus = () => {
      store.commit(
        'UserStore/setCollapsedSidebar',
        !store.getters['UserStore/getCollapsedSidebar'],
      );
    };

    const changeSidebarIsOpenInMobile = () => {
      if (sidebarIsOpenInMobile.value) {
        sidebarIsOpenInMobile.value = false;
        document.body.style.overflow = '';
      } else {
        sidebarIsOpenInMobile.value = true;
        document.body.style.overflow = 'hidden';
      }
    };

    const isSuperAdmin = computed(() => {
      return currentUser.value.roles?.includes('ROLE_SUPER_ADMIN');
    });

    const isDevPreEnv = computed(() => {
      return process.env.VUE_APP_URL.includes('dev') || process.env.VUE_APP_URL.includes('pre');
    });

    const isPROD = computed(() => {
      return process.env.VUE_APP_URL.includes('api.planimatik.com');
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const goToInstantQuote = () => {
      router.push({ name: 'InstantQuote' });
    };

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const isAdminSuplanting = computed((): any => {
      return (
        currentUser.value && currentUser.value.companyType === 'ADMIN' && isSuplanting.value !== ''
      );
    });

    const currentUser = computed((): any => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const isTrialPremUser = computed(() => {
      return (
        currentUser.value?.company?.accessPlanType === 'TRIAL' ||
        currentUser.value?.company?.accessPlanType === 'PREMIUM' ||
        currentUser.value?.company?.accessPlanType === 'CUSTOM'
      );
    });

    const screenComputedWidth = computed(() => {
      if (screenWidth.value < 1280) store.commit('UserStore/setCollapsedSidebar', false);
      return screenWidth.value;
    });

    const showUiComponents = computed(() => {
      return process.env.VUE_APP_SHOW_UI_COMPONENTS === 'true';
    });

    const totalUnreadChats = computed(() => {
      return store.state.ChatsStore.totalUnreadChats;
    });

    const updateScreenWidth = () => {
      screenWidth.value = window.innerWidth;
    };

    const isCollapsed = computed(() => {
      return store.getters['UserStore/getCollapsedSidebar'];
    });

    onMounted(() => {
      window.addEventListener('resize', updateScreenWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateScreenWidth);
    });

    return {
      actualRegion,
      collapsed,
      changeCollapsedSidebarStatus,
      changeSidebarIsOpenInMobile,
      goToInstantQuote,
      isCollapsed,
      isSuperAdmin,
      isDevPreEnv,
      isTrialPremUser,
      isAdminSuplanting,
      isPROD,
      Routing,
      totalUnreadChats,
      screenComputedWidth,
      sidebarIsOpenInMobile,
      showUiComponents,
    };
  },
});
