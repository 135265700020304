
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import { useStore } from 'vuex';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanContactTags from '@/components/labels/PlanContactTags.vue';
import PlanFlag from '@/components/icons/PlanFlag.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useI18n } from 'vue-i18n';
import PlanHeaderSearch from '@/components/filters/PlanHeaderSearch.vue';
import { debounce } from 'lodash';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';
export default defineComponent({
  name: 'LoadDirectAssign',
  components: { AddCarrierModal, PlanIcon, PlanLoading, PlanHeaderSearch, PlanButtonIcon, PlanDefaultTable, PlanButton, PlanFlag, PlanContactTags, PlanAvatar },
  emits: ['update', 'changeView'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      newCompany: null,
      firstLoading: false,
      loadingContacts: false,
      price: null,
      order: {
        value: 'companyName',
        direction: 'asc',
      },
      searchQuery: {
        page: 1,
        name: '',
        type: 'CARRIER|BROKER',
        trailerType: Number(store.getters['NewFtlLoad/getQuery'].trailer.trailerId),
        trailerTypeStats: Number(store.getters['NewFtlLoad/getQuery'].trailer.trailerId),
        originZip: store.getters['NewFtlLoad/getQuery'].pickup.stopData.postal_code,
        destinyZip: store.getters['NewFtlLoad/getQuery'].delivery.stopData.postal_code,
        period: 'LAST_3_MONTHS',
        permits: [],
        country: '',
        tags: [] as any,
        sortBy: 'companyName',
      },
      selectedCompany: null,
      showAddNewContactModal: false,
      showMoreContactsButtons: true
    })

    onMounted( async () => {
      fetchQuery();
      await getContactList();
    })

    const actualRegion = computed(() => {
      return store.getters['UserStore/getRegion']
    })

    const addNewCompany = (newCompany: any) => {
      data.newCompany = newCompany
      data.selectedCompany = newCompany.id
    }

    const changeContactsOrder = (orderObject: any) => {
      data.order = orderObject;
      data.searchQuery.sortBy = `${orderObject.value}[${orderObject.direction.toUpperCase()}]`;
      getContactList();
    }

    const changeSearchName = (newName: string) => {
      store.commit('UserStore/carrierContactListStatsByLoad', [])
      data.searchQuery.name = newName
      debouncedGetContactList()
    }

    const changeView = () => {
      emit('changeView', 'shipment')
    }

    const contacts = computed(() => {
      return store.getters['UserStore/carrierContactListStatsByLoad'];
    })

    const fetchQuery = () => {
      data.searchQuery = {
        page: 1,
        name: '',
        type: 'CARRIER|BROKER',
        trailerType: Number(store.getters['NewFtlLoad/getQuery'].trailer.trailerId),
        trailerTypeStats: Number(store.getters['NewFtlLoad/getQuery'].trailer.trailerId),
        originZip: store.getters['NewFtlLoad/getQuery'].pickup.stopData.postal_code,
        destinyZip: store.getters['NewFtlLoad/getQuery'].delivery.stopData.postal_code,
        period: 'LAST_3_MONTHS',
        permits: [],
        country: '',
        tags: [] as any,
        sortBy: 'companyName',
      }

      if (store.getters['NewFtlLoad/getType'] === 'MEX_DOMESTIC') {
        data.searchQuery.country = MEXICO.value.id
      } else {
        data.searchQuery.country = USA.value.id
      }
    }

    const getContactList = async () => {
      data.loadingContacts = true;

      if (store.getters['NewFtlLoad/getType'] === 'MEX_DOMESTIC') {
        data.searchQuery.country = MEXICO.value.id
      } else {
        data.searchQuery.country = USA.value.id
      }

      if (data.searchQuery.tags && data.searchQuery.tags.length === 0) {
        data.searchQuery.tags = undefined;
      }

      await store.dispatch('UserStore/carrierContactListStatsByLoad', data.searchQuery)
        .then((response) => {
          if (response.length === 0) data.showMoreContactsButtons = false
          if (!data.firstLoading) data.firstLoading = true
        })
        .finally(() => {
          data.loadingContacts = false;
        })
    }

    const headers = computed(() => {
      return [
        { label: translate.t('company'), value: 'companyName', sortable: true },
        { label: translate.t('rating'), value: 'rating', sortable: true },
        { label: translate.t('rol'), value: 'rol', sortable: false },
        { label: translate.t('loads'), value: 'loadsQuantity', sortable: true },
        { label: '', value: 'actions' },
      ];
    })

    const loadMoreContacts = () => {
      data.searchQuery.page = data.searchQuery.page + 1;
      getContactList();
    }

    const MEXICO = computed(() => {
      return store.getters['CountryStore/getCountries'].find((country: any) => country.name === 'Mexico');
    })

    const openChat = (user: any) => {
      store.commit('GlobalStore/setOpenChat', false);
      setTimeout(() => {
        store.commit('ChatsStore/setUserForChat', user);
        store.commit('ChatsStore/setChatId', user.chat_id ? user.chat_id : null);
        store.commit('GlobalStore/setOpenChat', true);
      }, 500);
    }

    const selectCompany = (companyId: any) => {
      data.selectedCompany = companyId
      data.newCompany = null
      update()
    }

    const update = () => {
      emit('update', {
        price: data.price,
        company: { id: data.selectedCompany }
      })
      store.commit('NewFtlLoad/setDirectAssign', {
        price: data.price,
        company: { id: data.selectedCompany }
      })
    }

    const USA = computed(() => {
      return store.getters['CountryStore/getCountries'].find((country: any) => country.name === 'United States')
    })

    const debouncedGetContactList = debounce(getContactList, 500)

    return {
      ...toRefs(data),
      actualRegion,
      addNewCompany,
      changeContactsOrder,
      changeSearchName,
      changeView,
      contacts,
      headers,
      loadMoreContacts,
      openChat,
      selectCompany,
      update
    }
  }
})
