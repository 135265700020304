
import { defineComponent, reactive, toRefs } from 'vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import store from '@/services/store';
import { useStore } from 'vuex';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useI18n } from 'vue-i18n';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
export default defineComponent({
  name: 'PlanCarrierImportQuotingModal',
  components: { PlanLoading, PlanInputFile, PlanButton, PlanCarrierDefaultModal, PlanTextButton },
  emits: ['update'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      loadingDownloadExcel: false,
      loadingPostExcel: false,
      showModal: false
    })

    const changeFile = async (files: File[]) => {
      if (files.length > 0) {
        data.loadingPostExcel = true
        const formData = new FormData();
        formData.append('file', files[0])
        await store.dispatch('RFPStore/postCreateLanesQuotes', formData).then(() => {
          store.dispatch('UserStore/updateToast', {
            text: translate.t('quote-import-correctly-sent'),
            type: 'success',
            visible: true,
          });
        }).catch((error) => {
          store.dispatch('UserStore/updateToast', {
            text: error?.response?.data?.detail,
            type: 'error',
            visible: true
          })
        });
        data.loadingPostExcel = false
        emit('update')
        data.showModal = false
      }
    }

    const close = () => {
      if (!data.loadingPostExcel) data.showModal = false
    }

    const downloadExcel = async () => {
      data.loadingDownloadExcel = true
      let blob = await store.dispatch('RFPStore/getCarrierQuotingExcel', store.getters['RFPStore/getCurrentProject'].project.id);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      let projectName = store.getters['RFPStore/getCurrentProject'].project.name
      projectName.toLowerCase()
        .replace(/[^\w\s]/gi, '')
        .replace(/\s+/g, '_')
      a.download = translate.t('RFP_Quotes') + '_' + projectName + '.xlsx';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      data.loadingDownloadExcel = false
    }

    return {
      ...toRefs(data),
      changeFile,
      close,
      downloadExcel
    }
  }
})
