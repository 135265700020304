import rfpApi from '../_api';
import cloneDeep from 'lodash/cloneDeep';

export default {
  async clearRFPStore(context: any) {
    context.commit('clearRFPStore');
  },
  async cloneProjectWithLanes(context: any, data: any) {
    return await rfpApi.cloneProjectWithLanes(data);
  },
  async cloneTendersFromProject(context: any, data: any) {
    return await rfpApi.cloneTendersFromProject(data.id, data.sourceProject);
  },

  async deleteFromRanking(context: any, data: number) {
    return await rfpApi.deleteFromRanking(data);
  },

  async deleteProject(context: any, data: number) {
    return await rfpApi.deleteProject(data);
  },

  async getCalendar_intervals(context: any, data: number) {
    const response = await rfpApi.getCalendar_intervals(data);
    context.commit('setCalendarIntervals', response);
    return response;
  },

  async getLoadsInfo({ commit, rootGetters }, { body, id }) {
    let accumulatedData = {} as any;
    const response = await rfpApi.getLoadsInfo(body, id);

    if (body.page > 1) {
      accumulatedData = JSON.parse(JSON.stringify(rootGetters['RFPStore/getLoadsInfo']));

      const newData = JSON.parse(JSON.stringify(response));

      for (const key in newData.loads) {
        if (newData.loads.hasOwnProperty(key)) {
          if (!accumulatedData.loads[key]) {
            accumulatedData.loads[key] = [];
          }
          accumulatedData.loads[key] = [...accumulatedData.loads[key], ...newData.loads[key]];
        }
      }

      accumulatedData.totals = newData.totals;
      for (const key in newData.totalLoadsByPeriod) {
        if (newData.totalLoadsByPeriod.hasOwnProperty(key)) {
          if (!accumulatedData.totalLoadsByPeriod[key]) {
            accumulatedData.totalLoadsByPeriod[key] = 0;
          }
          accumulatedData.totalLoadsByPeriod[key] += newData.totalLoadsByPeriod[key];
        }
      }

      commit('setLoadsInfo', accumulatedData);
      return response;
    } else {
      commit('setLoadsInfo', response);
      return response;
    }
  },

  async getRFPQuotesExcel() {
    return await rfpApi.getRFPQuotesExcel();
  },
  async getCarrierQuotingExcel(context: any, projectId: number) {
    return await rfpApi.getCarrierQuotingExcel(projectId);
  },

  async deleteQuoteRFP(context: any, data: number) {
    return await rfpApi.deleteQuoteRFP(data);
  },

  async deleteLane(context: any, data: number) {
    return await rfpApi.deleteLane(data);
  },

  async getAssignConfirmation({ commit }, projectId) {
    const response = await rfpApi.getAssignConfirmation(projectId);
    return response;
  },
  async getAsyncResultLanes({ commit }, projectId) {
    const response = await rfpApi.getAsyncResultLanes(projectId);
    commit('setAsyncResultLanes', response);
    return response;
  },

  async getLaneListFilter({ commit }, { projectId, query }) {
    const response = await rfpApi.getLaneListFilter(projectId, query);
    commit('setLanesListFilter', response);
    return response;
  },

  async getLanesByProjectId({ commit }, { projectId, orderField, orderType, page, maxResults }) {
    const response = await rfpApi.getLanesByProjectId(
      projectId,
      orderField,
      orderType,
      page,
      maxResults,
    );

    commit('setLanes', { response, page });
    return response;
  },

  async getProjectRFPSearcher({ commit }, { name, page, maxResults }) {
    return await rfpApi.getProjectRFPSearcher(name, page, maxResults);
  },

  async getRFPExcel({ commit }) {
    return await rfpApi.getRFPExcel();
  },

  async getProjectInfoByWeek({ commit }, { id, week, byLanes }) {
    const response = await rfpApi.getProjectInfoByWeek(id, week);
    commit('setLanesInformationPerWeek', response);
    return response;
  },

  async getProjectClaims({ commit, rootGetters }, projectId) {
    const response = await rfpApi.getProjectClaims(projectId);
    const claimProjectInfo = rootGetters['RFPStore/getClaimProjectInfo'];
    commit('setClaimProjectInfo', [...claimProjectInfo, { projectId, items: response }]);
    return response;
  },

  async getLanesClaimsInfo({ commit, rootGetters }, { laneId, week }) {
    const response = await rfpApi.getLanesClaimsInfo(laneId, week);
    const claimLanesInfo = rootGetters['RFPStore/getLanesClaimsInfo'];
    commit('setLanesClaimsInfo', [{ laneId, items: response }]);
    return response;
  },

  async getLanesAndQuotesForCarrier({ commit }, { projectId, page, maxResults }) {
    const response = await rfpApi.getLanesAndQuotesForCarrier(projectId, page, maxResults);
    commit('setLanesAndQuotesForCarrier', response);
  },

  async getQuoteRFPCarrier({ commit }, { projectId, page, maxResults }) {
    const response = await rfpApi.getQuoteRFPCarrier(projectId, page, maxResults);
    commit('setCurrentCarrierRFPQuotes', response);
  },

  async getQuoteRFPCarrierById({ commit }, quoteId) {
    const response = await rfpApi.getQuoteRFPCarrierById(quoteId);
    commit('setQuoteForCarrier', response);
  },

  async getCarrierQuotetionList(
    { commit },
    { id, name, orderType, page, maxResults, period, sortBy, type, tags, permits },
  ) {
    const response = await rfpApi.getCarrierQuotetionList(
      id,
      name,
      orderType,
      page,
      maxResults,
      type,
      sortBy,
      period,
      tags,
      permits,
    );
    commit('setCarrierQuotetionList', { page, response });
  },

  async getProjectById({ commit }, { projectId }) {
    const response = await rfpApi.getProjectById(projectId);
    commit('setCurrentProject', response);
  },

  async getLaneByIdCarrier({ commit }, { projectId }) {
    const response = await rfpApi.getLaneByIdCarrier(projectId);
    commit('setCurrentLane', response);
  },

  async getProjectByIdCarrier({ commit }, { projectId }) {
    const response = await rfpApi.getProjectByIdCarrier(projectId);
    commit('setCurrentProjectCarrier', response);
  },

  async getLaneById({ commit }, projectId) {
    const response = await rfpApi.getLaneById(projectId);
    commit('setCurrentLane', response);
  },

  async getProjectsRFPList({ commit, state }, data) {
    const { page } = data;
    const response = await rfpApi.getProjectsRFPList(data);

    if (page > 1) {
      commit('setProjectsRFPList', [...state.projectsRFPList, ...response]);
    } else {
      commit('setProjectsRFPList', response);
    }
  },

  async getPlannedLanesWeeks({ commit }, data) {
    const response = await rfpApi.getPlannedLanesWeeks(data);
    commit('setCurrentQuotesPlanned', response);
  },

  async getProjectsTotalsStatus({ commit }, data) {
    const response = await rfpApi.getProjectsRFPTotalsByStatus(data);
    commit('setProjectsTotalsStatus', response);
  },

  async getRankingList({ commit }, data) {
    const response = await rfpApi.getRankingList(data);
    commit('setRankingList', response);
    return response;
  },

  async getUnCompletedTootipInfo({ commit, rootGetters }, projectId) {
    const response = await rfpApi.getUnCompletedTootipInfo(projectId);
    const claimProjectInfo = rootGetters['RFPStore/getUnCompletedTootipInfo'];
    commit('setUnCompletedTootipInfo', [...claimProjectInfo, { projectId, items: response }]);
    return response;
  },

  async postCarrierConfirm(context: any, data: object) {
    return await rfpApi.postCarrierConfirm(data);
  },

  async postAssignConfirmationRFP(context: any, data: object) {
    return await rfpApi.postAssignConfirmationRFP(data);
  },

  async postAssignDeclineRFP(context: any, data: object) {
    return await rfpApi.postAssignDeclineRFP(data);
  },

  async postCarrierRefuse(context: any, data: object) {
    return await rfpApi.postCarrierRefuse(data);
  },

  async postNewProject(context: any, data: object) {
    return await rfpApi.postNewProject(data);
  },

  async postImportLanesByExcel(context: any, data: object) {
    return await rfpApi.postImportLanesByExcel(data);
  },

  async postImportQuotesByExcel(context: any, data: object) {
    return await rfpApi.postImportQuotesByExcel(data);
  },

  async postCreateLanesQuotes(context: any, data: object) {
    return await rfpApi.postCreateLanesQuotes(data);
  },

  async postNewLanes(context: any, data: object) {
    console.log('POST NEW LANES', data)
    return await rfpApi.postNewLanes(data);
  },

  async postMoveRankingPosition(context: any, data: object) {
    return await rfpApi.postMoveRankingPosition(data);
  },

  async postProjectRFPEndQuotation(context: any, { projectId, body }) {
    return await rfpApi.postProjectRFPEndQuotation(projectId, body);
  },

  async postRFPLoadsPlanningBatch(context: any, { laneId, loads }) {
    return await rfpApi.postRFPLoadsPlanningBatch(laneId, loads);
  },

  async postProjectRFPRequestAllQuotesConfirmation(context: any, { projectId, body }) {
    return await rfpApi.postProjectRFPRequestAllQuotesConfirmation(projectId, body);
  },

  async postQuoteRFP(context: any, data: object) {
    return await rfpApi.postQuoteRFP(data);
  },

  async postAssignedCapacity(context: any, { quoteId, body }) {
    return await rfpApi.postAssignedCapacity(quoteId, body);
  },

  async postRequestQuote(context: any, data: object) {
    return await rfpApi.postRequestQuote(data);
  },

  async postRequestQuoteConfirmation(context: any, { quoteId }) {
    return await rfpApi.postRequestQuoteConfirmation(quoteId);
  },

  async postResendQuote(context: any, { projectId, companyId }) {
    return await rfpApi.postResendQuote(projectId, companyId);
  },

  async postRevokeQuote(context: any, projectId) {
    return await rfpApi.postRevokeQuote(projectId);
  },

  async postResendQuoteEmail(context: any, { quoteId }) {
    return await rfpApi.postResendQuoteEmail(quoteId);
  },

  async updateQuotationDateEnd(context: any, data: object) {
    return await rfpApi.updateQuotationDateEnd(data);
  },

  async putQuotationDateEnd(context: any, data: object) {
    return await rfpApi.putQuotationDateEnd(data);
  },

  async putProjectRFP(context: any, data: object) {
    return await rfpApi.putProjectRFP(data);
  },

  async updatePrice(context: any, data: object) {
    return await rfpApi.updatePrice(data);
  },

  async updateLanes(context: any, data: object) {
    console.log('PUT NEW LANES', data)
    return await rfpApi.updateLanes(data);
  },
};
