
import { defineComponent } from 'vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';

export default defineComponent({
  components: {
    XCircleIcon,
  },
  props: {
    showUploadModal: {
      type: Boolean,
    },
    load: {
      type: Object,
    },
  },
  data() {
    return {
      imagesContainerClass: '',
      deleteButtonClass: 'hidden',
      signImage: [] as any,
      fetchedImageOriginal: null as any,
      imagesLoaded: false,
    };
  },
  computed: {},
  methods: {
    async loadImages() {
      const signImagePromises = (this.load?.incidents ?? []).flatMap((incident) =>
        incident.mediaObjects.map(async (mediaObject) => {
          if (mediaObject) {
            const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
              id: mediaObject.id,
              class: 'INCIDENT',
            });
            return URL.createObjectURL(result);
          } else {
            return 'empty';
          }
        }),
      );
      this.signImage = await Promise.all(signImagePromises);
    },
    async download(incidentId) {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: incidentId,
        class: 'INCIDENT',
      });

      this.fetchedImageOriginal = document.createElement('a');
      this.fetchedImageOriginal.href = URL.createObjectURL(response);
      this.fetchedImageOriginal.download = `documento-${incidentId}`;
      this.fetchedImageOriginal.click();
    },
    hideModal() {
      this.$emit('close');
    },
  },
  watch: {
    load() {
      this.loadImages();
    },
  },
});
