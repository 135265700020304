
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanLoadFormStop from '@/components/loads/form/domestic/PlanLoadFormStop.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

interface planLoadFormAdditionalStops {
  additionalStops: Array<any>
}

export default defineComponent({
  name: 'PlanLoadFormAdditionalStops',
  components: { PlanButtonIcon, PlanLoadFormStop, PlanTextButton },
  props: {
    onlyCountry: { type: String, required: false, default: '' },
    excludeCountry: { type: String, required: false, default: '' }
  },
  setup () {
    const store = useStore()
    const translate = useI18n()

    const data = reactive<planLoadFormAdditionalStops>({
      additionalStops: []
    })

    const addAdditionalStop = () => {
      data.additionalStops.push({
        index: data.additionalStops.length + 2,
        templateDataLocation: '',
        templateWarehouseId: -1,
        stopType: 'PICKUP',
        stopData: null,
        date: null,
        warehouse: null
      })

      updateAdditionalStops()
    }

    const deliveryDate = computed(() => {
      return store.getters['NewFtlLoad/getDeliveryDate']
    })

    const pickupDate = computed(() => {
      return store.getters['NewFtlLoad/getPickupDate']
    })

    const removeAdditionalStop = (index: number) => {
      const searchIndex = data.additionalStops.findIndex((item: any) => item.index === index)

      if (searchIndex !== -1) {
        data.additionalStops.splice(searchIndex, 1)
        data.additionalStops.forEach((item: any, itemIndex: number) => {
          item.index = itemIndex + 2
        })
      }

      updateAdditionalStops()
    }

    const updateAdditionalStops = () => {
      store.commit('NewFtlLoad/setAdditionalStops', data.additionalStops)
    }

    const updateStop = (stop: any) => {
      const searchIndex = data.additionalStops.findIndex((item: any) => item.index === stop.index)
      data.additionalStops[searchIndex].stopType = stop.stopType
      data.additionalStops[searchIndex].stopData = stop.stopData
      data.additionalStops[searchIndex].date = stop.date
      data.additionalStops[searchIndex].warehouse = stop.warehouse
      updateAdditionalStops()
    }

    watch(() => store.getters['NewFtlLoad/getTemplateData'], () => {
      const template = store.getters['NewFtlLoad/getTemplateData']
      if (template) {
        if (template.load.stops.length > 2) {
          template.load.stops.forEach((stop: any, index: number) => {
            if (index !== 0 && index !== template.load.stops.length -1) {
              data.additionalStops.push({
                index: data.additionalStops.length + 2,
                templateDataLocation: stop.location.zip,
                templateWarehouseId: stop.warehouseTemplate?.id ? stop.warehouseTemplate?.id: -1,
                stopType: stop.type,
                stopData: null,
                date: null,
                warehouse: null
              })
            }
          })

          updateAdditionalStops()
        }
      }
    })

    return {
      ...toRefs(data),
      addAdditionalStop,
      deliveryDate,
      pickupDate,
      removeAdditionalStop,
      updateStop
    }
  }
})
