import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex plan-gap-md", _ctx.isVertical ? 'flex-col justify-start items-start':'justify-start items-center'])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        onClick: ($event: any) => (_ctx.changeSelectedOption(option)),
        key: option.value,
        class: _normalizeClass(["plan-input-radio-wrapper", {'plan-input-radio-wrapper-disabled': option.disabled }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["plan-input-radio-default", {'plan-input-radio-default-active': _ctx.selected === option.value }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["plan-input-radio-circle", {'plan-input-radio-circle-active': _ctx.selected === option.value }])
          }, null, 2)
        ], 2),
        _createElementVNode("span", null, _toDisplayString(option.label), 1)
      ], 10, _hoisted_1))
    }), 128))
  ], 2))
}