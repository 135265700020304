
import { defineComponent, reactive, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import PlanButtonGroup from '@/components/buttons/PlanButtonGroup.vue';

import LoadFilterByIdPo from '@/components/filters/LoadFilterByIdPo.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import * as Routing from '@/router/Routing';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'DemoPlanningRFP',
  components: { PlanButton, PlanDefaultModal, PlanIcon, LoadFilterByIdPo, PlanButtonGroup },
  emits: ['changeView'],
  props: {
    currentView: { type: String, required: false },
  },
  setup(props, { emit }) {
    /** Global */
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const translate = useI18n();

    /** Computed */

    const currentLane = computed(() => {
      return store.getters['RFPStore/getCurrentLane'];
    });

    const countNonEmptyExecutionDates = computed(() => {
      return getCurrentLoadsToAdd.value.reduce((count, load) => {
        const allNonEmpty = load.stops.every((stop) => stop.executionDate !== '');
        return count + (allNonEmpty ? 1 : 0);
      }, 0);
    });

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];

    });

    const getCurrentLoadsToAdd = computed(() => {
      return store.getters['RFPStore/getCurrentLoadsToAdd'];
    });

    /** Methods */

    const checkPendingDates = () => {
      if(countNonEmptyExecutionDates.value){
        data.showConfirmExitModal = true;
      } else {
        goPreviusRoute();
      }
    };

    const goPreviusRoute = () => {
      if (currentProject?.value?.project?.id) {
        Routing.openProjectRFP({ projectId: currentProject?.value?.project?.id });
      } else {
        Routing.openPlanning();
      }
    };

    const handleChange = () => {
      emit('changeView', props.currentView);
    };

    const defaultView = computed(() => {
      return store.getters['RFPStore/getPreviousStatusLanes'];
    });

    const data = reactive({
      activeButton: store.getters['RFPStore/getPreviousStatusLanes'] === 'table' ? 0 : 1,
      showConfirmExitModal: false,
    });

    return {
      ...toRefs(data),
      checkPendingDates,
      countNonEmptyExecutionDates,
      currentProject,
      currentLane,
      defaultView,
      getCurrentLoadsToAdd,
      goPreviusRoute,
      handleChange,
      Routing,
    };
  },
});
