
import { ref, computed, watchEffect, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import i18n from '@/resources/locales';
import PlanIcon from '@/components/icons/PlanIcon.vue';
// Base
import BaseComponent from '@/base/BaseComponent';
import PlanAvatar from '@/components/PlanAvatar.vue';
// Components

export default {
  mixins: [BaseComponent],
  components: { PlanAvatar, PlanIcon },
  props: {
    carrier: Object,
    showOwner: Boolean,
    shipperOwner: Object,
    shipperCreated: String,
    showCompanyName: Boolean,
    showUserImage: Boolean,
    load: Object,
  },
  setup(props, { emit }) {
    //* Global /
    const store = useStore();
    const $t = i18n.global.t;
    const router = useRouter();
    const currentRoutePath = router.currentRoute.value.path;
    const isSupplanting = ref(false);

    const truncateName = (name) => {
      if (name && name.length > 15) {
        return name.substring(0, 15) + '...';
      }
      return name;
    };

    const isSuperAdmin = computed(() => {
      return currentUser.value?.roles?.includes('ROLE_SUPER_ADMIN');
    });

    /** Computed */
    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const impersonate = async (id, type, shipment) => {
      const loadId = props?.load?.id;
      isSupplanting.value = true;

      store.dispatch('UserStore/getUserIdentification', id).then(() => {
        isSupplanting.value = false;

        store.dispatch('UserStore/getUserConfig', id).then((response) => {
          store.commit('UserStore/setPreviousRoute', currentRoutePath);

          if (type === 'CARRIER' || type === 'BROKER') {
            store.commit('UserStore/setIsTheAdminImpersonating', true);
            store.commit('UserStore/setCurrentUserImpersonating', response);
            router.push({ name: 'CarrierBalancer', params: { id: loadId } });
          } else {
            store.commit('UserStore/setIsTheAdminImpersonating', true);
            store.commit('UserStore/setCurrentUserImpersonating', response);
            router.push('/planning');
          }
        });
      });
    };

    return { impersonate, isSupplanting, truncateName, currentUser, isSuperAdmin };
  },
};
