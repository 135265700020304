
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { BellIcon, DocumentIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { VueSignaturePad } from 'vue-signature-pad';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import SmallModalSuccess from '@/modules/planning/loadDetail/success/SmallModalSuccess.vue';
import UploadDocuments from '@/modules/carrier/modals/UploadDocuments.vue';
import UploadInvoice from '@/modules/carrier/upload/UploadInvoice.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
export default defineComponent({
  name: 'FixedBottomContainerButton',
  components: {
    UploadInvoice,
    BellIcon,
    DocumentIcon,
    ErrorMessage,
    Field,
    Form,
    InputAmericanFormat,
    SmallModalSuccess,
    VueSignaturePad,
    XCircleIcon,
    UploadDocuments,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    shipment: {
      type: Object,
    },
    lowestBid: {
      type: Object,
    },
    allDocumentsDone: {
      type: Object,
    },
    showRateConfirmation: {
      type: Boolean,
    },
    orderedStops: {
      type: Array,
    },
  },
  data() {
    return {
      allowSign: true,
      signinAction: false,
      allowUploadDocument: true,
      fetchedImageOriginal: null as any,
      showUpdateCarrier: false,
      rateConfirmationId: '',
      signedRate: false,
      isEmptySign: false,
      loadSelected: null as any,
      showQuoteInput: false,
      showCarrierQuoteDone: false,
      showUploadDocumentsModal: false,
      carrierBidRequest: 0,
      signImage: '',
      rcSigned: false,
      price: 0 as any,
      sendingAction: false,
      allowed: true,
      scrollY: 0,
      isRCVisible: true,
    };
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY;
    });
  },
  computed: {
    isAdminSuplanting() {
      return this.IsTheAdminImpersonating && this.isSuplanting !== '';
    },
    isEurope(): boolean {
      const country = this.dataCountries?.find(
        (country) => this.currentUser?.company?.country?.id === country?.id,
      );
      return country?.region?.name === 'EUROPE';
    },
    isDriver(): any {
      return this.$route.path.includes('driver');
    },
    IsTheAdminImpersonating(): any {
      return this.$store.getters['UserStore/getIsTheAdminImpersonating'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    bidAccepted(): any {
      return this.shipment?.assignedCompany?.company?.id;
    },
    carrierBidData(): any {
      return this.$store.getters['LoadsStore/carrierBidData'];
    },
    checkCarrierQuote(): any {
      if (this.carrierBidData) return true;
      else return false;
    },
    checkMediaObjects(): any {
      let array = [] as any;
      array = this.orderedStops;
      const result = array?.find((stop) => {
        return stop.mediaObjects && stop.mediaObjects.length === 0;
      });
      return result ? result.id : null;
    },
    consigneeFilledValue(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.loads[0].isConsigneeFilled !== 'undefined'
      ) {
        return this.shipment.loads[0].isConsigneeFilled;
      } else {
        return null;
      }
    },
    currentBid: {
      get(): any {
        return this.$store.getters['LoadsStore/currentBid'];
      },
      set(value): any {
        this.$store.commit('LoadsStore/currentBid', value);
      },
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    currentLoadId(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.loads[0] !== 'undefined'
      ) {
        return this.shipment.loads[0];
      } else {
        return null;
      }
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    file(): any {
      return this.$store.getters['QuotationRequestStore/getFile'];
    },
    filesToUpload(): any {
      return this.$store.getters['QuotationRequestStore/getFilesToUpload'];
    },
    nextStopDocumentStep(): any {
      if (
        this.shipment &&
        this.shipment.nextStopDocumentStep &&
        this.shipment.nextStopDocumentStep[0] &&
        typeof this.shipment.nextStopDocumentStep[0] !== 'undefined'
      ) {
        return this.shipment.nextStopDocumentStep[0];
      } else {
        return null;
      }
    },
    origin(): any {
      if (this.shipment && this.shipment.loads[0] && this.shipment.loads[0].stops.length > 0) {
        return this.shipment.loads[0].stops.find((obj) => obj.type === 'PICKUP_INITIAL');
      }
      return null;
    },
    returnCurrentLoadForUploadDocs(): any {
      let loadSequence = this.nextStopDocumentStep.nextStepSequence;
      const stop = this.shipment?.loads[0].stops.find((stop) => stop.sequence === loadSequence);
      return stop ? stop : null;
    },
    sendingFile(): any {
      return this.$store.getters['QuotationRequestStore/getSendingFile'];
    },
    userGetBids(): any {
      return this.shipment?.bestBids.find(
        (user) => user.companyId === this.currentUser?.company?.id,
      );
    },
  },
  methods: {
    async fetch() {
      await this.$store.dispatch('ShipmentStore/shipment', { id: this.$route.params.id });
    },
    handleUploadDocumentsModal(load) {
      this.loadSelected = load;
      this.showUploadDocumentsModal = true;
    },
    bookNow() {
      this.allowed = false;
      this.sendingAction = true;
      this.$store
        .dispatch('ShipmentStore/acceptBookNow', this.$route.params.id)
        .then(() => {
          BaseComponent.methods?.showToastSuccess(
            this.$t('quote-correctly', {
              price: this.formatPrice(Number(this.currentLoad.bookNowPrice)),
            }),
          );
          this.$store.dispatch('ShipmentStore/shipment', { id: this.$route.params.id }).then(() => {
            const data = this.shipment?.bestBids.find(
              (obj) => obj.companyId === this.currentUser?.company?.id,
            );
            this.$store.dispatch('LoadsStore/currentBid', data ? data.bid : 0);
            this.$store.dispatch('LoadsStore/carrierBidData', data);
          });
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          this.showQuoteInput = false;
          this.showCarrierQuoteDone = true;
          this.allowed = true;
          this.sendingAction = false;
        });
    },
    clearInputFiles(sequence) {
      this.$store.commit('QuotationRequestStore/removeFile', sequence);
      if (this.filesToUpload.find((file) => file.sequence === sequence)) {
        this.$store.commit('QuotationRequestStore/setFile', null);
      }
    },
    async convertImg() {
      const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
        id: this.rateConfirmationId,
        class: 'SHIPMENT',
      });
      this.signImage = URL.createObjectURL(result);
    },
    declineCurrentBid() {
      this.$store
        .dispatch('ShipmentStore/declineCurrentBid', {
          shipment: this.shipment?.id,
          company: this.carrierBidData.companyId,
        })

        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('quote-declined'));
          this.$store.dispatch('LoadsStore/carrierBidData', null);
          const id = this.$route.params.id;
          this.$store.dispatch('ShipmentStore/shipment', { id: id });
        });
    },
    async downloadAll() {
      let response = await this.$store.dispatch('ShipmentStore/downLoadAllDocuments', {
        id: this.currentLoadId.id,
      });

      this.fetchedImageOriginal = document.createElement('a');
      this.fetchedImageOriginal.href = URL.createObjectURL(response);
      this.fetchedImageOriginal.download = `documents-${''}`;
      this.fetchedImageOriginal.click();
    },
    async fileChange(event, sequence) {
      this.clearInputFiles(sequence);
      let file = {
        sequence,
        name: event.target.files[0].name,
        url: URL.createObjectURL(event.target.files[0]),
      };
      this.$store.commit('QuotationRequestStore/pushFile', file);
      this.$store.commit('QuotationRequestStore/setFile', event.target.files[0]);
    },
    onBegin() {
      this.signedRate = false;
    },
    onClickMaximize() {
      this.isRCVisible = true;
    },
    onClickMinimize() {
      this.isRCVisible = false;
    },
    onEnd() {
      this.signedRate = true;
    },
    async save() {
      const { isEmpty, data } = (this.$refs.signaturePad as any).saveSignature();

      if (isEmpty) {
        this.isEmptySign = true;
        BaseComponent.methods?.showToastError(this.$t('sign-validation'));
      } else {
        if (this.allowSign) {
          this.isEmptySign = false;
          const id = this.$route.params.id;
          this.allowSign = false;
          this.signinAction = true;

          this.$store
            .dispatch('ShipmentStore/signsRateConfirmation', {
              entityId: this.$route.params.id,
              file: data,
            })

            .then((response) => {
              this.rateConfirmationId = response.id;
              this.$store.dispatch('ShipmentStore/shipment', { id: id });
              BaseComponent.methods?.showToastSuccess(this.$t('valid-sign'));
            })
            .catch((err) => {
              //
            })
            .finally(() => {
              this.allowSign = true;
              this.signinAction = false;
              this.rcSigned = true;
            });
        }
      }
    },
    async sendQuote() {
      if (!this.allowed) return;
      const validation = await (this.$refs.form as any).validate();

      if (!validation.valid) {
        return;
      }

      const bestBid = this.shipment?.bestBids.find(
        (obj) => obj.companyId === this.currentUser?.company?.id,
      );

      // TODO THIS ADDED FOR NEW IMPERSONATING THIS FILE NEED A REFACTOR
      if (this.isAdminSuplanting && this.currentUserImpersonating?.company?.bestBid) {
        this.updateBid({ id: this.currentUserImpersonating.company.bestBidId });
        return;
      }

      this.allowed = false;
      this.sendingAction = true;
      if (
        this.shipment?.assignedCompany?.company.id !== this.currentUser?.company?.id &&
        bestBid !== undefined &&
        bestBid !== null
      ) {
        this.updateBid(bestBid);
      } else {
        this.$store
          .dispatch('ShipmentStore/requestBid', {
            shipment: { id: this.$route.params.id },
            bid: Number(this.currentBid),
          })

          .then(() => {
            BaseComponent.methods?.showToastSuccess(
              this.$t('quote-correctly', {
                price: this.formatPrice(Number(this.currentBid)),
              }),
            );
            this.$store
              .dispatch('ShipmentStore/shipment', { id: this.$route.params.id })
              .then(() => {
                const data = this.shipment?.bestBids.find(
                  (obj) => obj.companyId === this.currentUser?.company?.id,
                );
                this.$store.dispatch('LoadsStore/currentBid', data ? data.bid : 0);
                this.$store.dispatch('LoadsStore/carrierBidData', data);
              });
            this.showCarrierQuoteDone = true;

            setTimeout(() => {
              this.showCarrierQuoteDone = false;
            }, 2500);
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(this.$t('quote-creation-error'));
          })
          .finally(() => {
            this.showQuoteInput = false;
            this.allowed = true;
            this.sendingAction = false;
          });
      }
    },
    showFileInput() {
      (this.$refs.multiUploadInput as any).click();
    },
    toggleEmailConfigModal() {
      this.$store.commit('GlobalStore/setEmailConfigModal', true);
    },
    undo() {
      this.signedRate = false;
      (this.$refs.signaturePad as any).clearSignature();
    },
    updateBid(bestBid) {
      this.$store
        .dispatch('ShipmentStore/updateBid', {
          id: bestBid.id,
          bid: Number(this.currentBid),
        })

        .then(() => {
          BaseComponent.methods?.showToastSuccess(
            this.$t('quote-correctly', {
              price: this.formatPrice(Number(this.currentBid)),
            }),
          );
          this.$store.dispatch('ShipmentStore/shipment', { id: this.$route.params.id }).then(() => {
            const data = this.shipment?.bestBids.find(
              (obj) => obj.companyId === this.currentUser?.company?.id,
            );
            this.$store.dispatch('LoadsStore/currentBid', data ? data.bid : 0);
            this.$store.dispatch('LoadsStore/carrierBidData', data);
          });
          this.showCarrierQuoteDone = true;
          setTimeout(() => {
            this.showCarrierQuoteDone = false;
          }, 2500);
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(this.$t('message-error-updating-quote'));
        })
        .finally(() => {
          this.showQuoteInput = false;
          this.allowed = true;
          this.sendingAction = false;
        });
    },
    updateCurrentBid(event) {
      const newBid = event.target.value.replace(/\D/g, '');
      this.$store.commit('LoadsStore/currentBid', newBid);
    },
    async uploadDocument() {
      const validation = await (this.$refs.formBottom as any).validate();
      if (!validation.valid) {
        this.file = null;
        return;
      }

      if (this.file && this.allowUploadDocument) {
        this.allowUploadDocument = false;
        this.$store.commit(
          'QuotationRequestStore/setSendingFile',
          this.returnCurrentLoadForUploadDocs.sequence,
        );
        this.$store
          .dispatch('ShipmentStore/postMediaObjectStops', {
            entityId: this.checkMediaObjects,
            file: this.file,
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('documents-uploaded-done'));
            this.file = null;
            const id = this.$route.params.id;
            this.$store.dispatch('ShipmentStore/shipment', { id: id });
            this.clearInputFiles(this.returnCurrentLoadForUploadDocs.sequence);
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          })
          .finally(() => {
            this.allowUploadDocument = true;
            this.$store.commit('QuotationRequestStore/setSendingFile', -1);
          });
      }
    },
    async uploadFiles(type) {
      if (type === 'incidence') {
        //
      } else {
        this.file = (this.$refs.multiUploadInput as any).files[0];
      }
    },
  },
});
