
import { defineComponent, computed, reactive, toRefs, ref } from 'vue';
import { useStore } from 'vuex';
import PlanCarrierAddDriverModal from '@/components/carrier/_modals/PlanCarrierAddDriverModal.vue';
import PlanCarrierAddVehicleModal from '@/components/carrier/_modals/PlanCarrierAddVehicleModal.vue';
import PlanDefaultDeleteModal from '@/components/defaults/PlanDefaultDeleteModal.vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import { useI18n } from 'vue-i18n';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanCarrierUpdateDriver from '@/components/carrier/_forms/PlanCarrierUpdateDriver.vue';
import PlanCarrierBrokerAddVehicleModal from '@/components/carrier/_modals/PlanCarrierBrokerAddVehicleModal.vue';
import PlanCarrierBrokerUpdateVehicleModal from '@/components/carrier/_modals/PlanCarrierBrokerUpdateVehicleModal.vue';
import PlanCarrierBrokerAddDriverModal from '@/components/carrier/_modals/PlanCarrierBrokerAddDriverModal.vue';
import PlanCarrierBrokerUpdateDriverModal from '@/components/carrier/_modals/PlanCarrierBrokerUpdateDriverModal.vue';
import PlanCarrierUpdateTrailer from '@/components/carrier/_forms/PlanCarrierUpdateTrailer.vue';

import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
export default defineComponent({
  name: 'PlanCarrierLoadAcceptationDriverAndVehicleForm',
  components: {
    PlanCarrierBrokerUpdateDriverModal,
    PlanCarrierBrokerAddDriverModal,
    PlanCarrierBrokerUpdateVehicleModal,
    PlanCarrierBrokerAddVehicleModal,
    PlanCarrierUpdateDriver,
    PlanDefaultDeleteModal,
    PlanTextButton,
    PlanCarrierUpdateTrailer,
    PlanCarrierAddVehicleModal,
    PlanCarrierAddDriverModal,
  },
  setup() {
    const store = useStore();
    const translate = useI18n();
    const driverModalRef = ref(null as any);

    const data = reactive({
      deleteId: null,
      deleteDriverId: null,
      loadingDelete: false,
      loadingDriverDelete: false,
      selectedDriver: null,
      selectedVehicle: null,
      showContactInformation: true,
      emitId: null,
    });

    if (store.getters['CarrierStore/getLoadData'].shipment.driver) {
      data.selectedDriver = store.getters['CarrierStore/getLoadData'].shipment.driver.id;
    }

    if (store.getters['CarrierStore/getLoadData'].shipment.vehicle) {
      data.selectedVehicle = store.getters['CarrierStore/getLoadData'].shipment.vehicle.id;
    }

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const changeDriver = async () => {
      await store
        .dispatch('ShipmentStore/assignDriver', {
          id: store.getters['CarrierStore/getLoadData'].shipment.id,
          driver: { id: data.selectedDriver },
        })
        .then(() => {
          store.dispatch('UserStore/updateToast', {
            text: translate.t('success-carrier-driver-selected'),
            type: 'success',
            visible: true,
          });
        });
    };

    const changeVehicle = async () => {
      await store
        .dispatch('ShipmentStore/assignVehicle', {
          id: store.getters['CarrierStore/getLoadData'].shipment.id,
          vehicle: { id: data.selectedVehicle },
        })
        .then(() => {
          store.dispatch('UserStore/updateToast', {
            text: translate.t('success-carrier-vehicle-selected'),
            type: 'success',
            visible: true,
          });
        });
    };

    const deleteDriver = async () => {
      data.loadingDriverDelete = true;
      await store.dispatch('CompanyStore/deleteDriver', data.deleteDriverId).then(() => {
        if (data.deleteDriverId === data.selectedDriver) data.selectedDriver = null;
        data.deleteDriverId = null;
        store.dispatch('UserStore/updateToast', {
          text: translate.t('general-success-delete'),
          type: 'success',
          visible: true,
        });
        getDrivers();
      });
    };

    const handleChangeDriverSelected = () => {
      data.selectedDriver = null;
    };

    const handleChangeVehicleSelected = () => {
      data.selectedVehicle = null;
    };

    const deleteVehicle = async () => {
      data.loadingDelete = true;
      await store.dispatch('CompanyStore/deleteVehicles', { id: data.deleteId }).then(() => {
        if (data.deleteId === data.selectedVehicle) data.selectedVehicle = null;
        data.deleteId = null;
        store.dispatch('UserStore/updateToast', {
          text: translate.t('general-success-delete'),
          type: 'success',
          visible: true,
        });
        getVehicles();
      });
    };

    const changeShowContactInformation = async (id, value) => {
      console.log('id, value: ', id, value);
      await store.dispatch('CompanyStore/putDriver', {
        id: id,
        params: {
          showContactInformation: value,
        },
      });
    };

    const openDriverModal = async () => {
      driverModalRef.value[0].open();
    };

    const orderPrefixAsc = (prefixes: any) => {
      return prefixes.sort((a, b) => {
        let prefixA = parseInt(a.value, 10);
        let prefixB = parseInt(b.value, 10);

        if (prefixA < prefixB) {
          return -1;
        }
        if (prefixA > prefixB) {
          return 1;
        }
        return 0; // Si son iguales
      });
    };

    const prefixes = computed(() => {
      const prefixes = store.getters['CarrierStore/getRegionPrefixes'];
      return orderPrefixAsc(prefixes);
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const drivers = computed(() => {
      return store.getters['CarrierStore/getDrivers'];
    });

    const getDrivers = async () => {
      await store
        .dispatch('CompanyStore/driversList', currentUser.value.company.id)
        .then((response) => {
          store.commit('CarrierStore/setDrivers', response);
          data.loadingDriverDelete = false;
        });
    };

    const getVehicles = async () => {
      await store
        .dispatch('CompanyStore/vehiclesList', currentUser.value.company.id)
        .then((response) => {
          store.commit('CarrierStore/setVehicles', response);
        })
        .finally(() => {
          data.deleteId = null;
          data.loadingDelete = false;
        });
    };

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const myDataForm = computed(() => {
      return store.getters['CarrierStore/getMyDataForm'];
    });

    const selectVehicle = (vehicleId: any) => {
      data.selectedVehicle = vehicleId;
    };

    const vehicles = computed(() => {
      return store.getters['CarrierStore/getVehicles'];
    });

    return {
      ...toRefs(data),
      actualWidth,
      changeDriver,
      changeShowContactInformation,
      changeVehicle,
      currentUser,
      deleteDriver,
      deleteVehicle,
      drivers,
      driverModalRef,
      handleChangeDriverSelected,
      handleChangeVehicleSelected,
      openDriverModal,
      loadData,
      prefixes,
      myDataForm,
      selectVehicle,
      vehicles,
    };
  },
});
