import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 1,
  class: "text-4xl font-bold"
}
const _hoisted_3 = {
  key: 2,
  class: "text-4xl font-bold"
}
const _hoisted_4 = {
  key: 0,
  class: "w-full flex items-center justify-center"
}
const _hoisted_5 = {
  key: 1,
  class: "w-full"
}
const _hoisted_6 = { class: "w-full flex justify-between items-center plan-gap-sm" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "flex justify-center items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_FileContainer = _resolveComponent("FileContainer")!
  const _component_plan_default_confirmation_modal = _resolveComponent("plan-default-confirmation-modal")!
  const _component_ContactsTable = _resolveComponent("ContactsTable")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_view = _resolveComponent("plan-default-view")!

  return (_openBlock(), _createBlock(_component_plan_default_view, {
    "show-bottom-selection-bar": _ctx.currentContacts.length > 0
  }, {
    "header-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.currentContacts.length > 0)
          ? (_openBlock(), _createBlock(_component_plan_text_button, {
              key: 0,
              onOnClick: _ctx.cleanCurrentContacts,
              type: "prim-200",
              label: _ctx.$t('go-back'),
              icon: "chevron-left"
            }, null, 8, ["onOnClick", "label"]))
          : _createCommentVNode("", true),
        (_ctx.currentContacts.length === 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('import-contacts')), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('import-contacts-review')), 1))
      ])
    ]),
    "view-content": _withCtx(() => [
      (_ctx.currentContacts.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_FileContainer)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.showDiscardModal)
              ? (_openBlock(), _createBlock(_component_plan_default_confirmation_modal, {
                  key: 0,
                  onAccept: _ctx.Routing.openContacts,
                  onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDiscardModal = false)),
                  title: _ctx.$t('are-you-sure'),
                  message: _ctx.$t('import-contact-discard-message'),
                  size: "sm-auto"
                }, null, 8, ["onAccept", "title", "message"]))
              : _createCommentVNode("", true),
            (_ctx.showOmitModal)
              ? (_openBlock(), _createBlock(_component_plan_default_confirmation_modal, {
                  key: 1,
                  onAccept: _ctx.omitInvalidsContacts,
                  onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showOmitModal = false)),
                  title: _ctx.$t('are-you-sure'),
                  message: 
            _ctx.$t('import-contact-import-message', {
              quantity: _ctx.quantities.success + _ctx.quantities.update,
              total: _ctx.quantities.total,
            })
          ,
                  size: "sm-auto"
                }, null, 8, ["onAccept", "title", "message"]))
              : _createCommentVNode("", true),
            _createVNode(_component_ContactsTable, {
              onUpdateQuantities: _ctx.updateQuantities,
              currentContacts: _ctx.currentContacts,
              class: "plan-mb-2xl"
            }, null, 8, ["onUpdateQuantities", "currentContacts"])
          ]))
    ]),
    "bottom-selection-bar": _withCtx(() => [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_ctx.quantities.success > 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('import-contacts-new-contacts', { quantity: _ctx.quantities.success })), 1))
            : _createCommentVNode("", true),
          (_ctx.quantities.update > 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t('import-contacts-update-contacts', { quantity: _ctx.quantities.update })), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_plan_button, {
            onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDiscardModal = true)),
            disabled: _ctx.loadingPostAll || _ctx.loadingImportSuccessContacts,
            type: "error",
            label: _ctx.$t('discard'),
            style: {"min-width":"150px"}
          }, null, 8, ["disabled", "label"]),
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.importContacts,
            loading: _ctx.loadingPostAll || _ctx.loadingImportSuccessContacts,
            disabled: _ctx.quantities.success === 0,
            style: {"min-width":"150px"},
            type: "primary",
            label: _ctx.$t('import')
          }, null, 8, ["onOnClick", "loading", "disabled", "label"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show-bottom-selection-bar"]))
}