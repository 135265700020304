
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierLoadHeader from '@/components/carrier/PlanCarrierLoadHeader.vue';
import PlanCarrierLoadSubHeader from '@/components/carrier/PlanCarrierLoadSubHeader.vue';
import PlanCarrierLoadHeaderNotification from '@/components/carrier/PlanCarrierLoadHeaderNotification.vue';
import PlanCarrierLoadDetails from '@/components/carrier/loadDetail/PlanCarrierLoadDetails.vue';
import PlanCarrierLoadStopData from '@/components/carrier/loadDetail/PlanCarrierLoadStopData.vue';
import PlanCarrierLoadBid from '@/components/carrier/_quoting/PlanCarrierLoadBid.vue';
import { useStore } from 'vuex';
import PlanCarrierLoadingFullScreen from '@/components/carrier/PlanCarrierLoadingFullScreen.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanCarrierLoadAcceptationSteps from '@/components/carrier/PlanCarrierLoadAcceptationSteps.vue';
import PlanCarrierLoadAcceptationMyDataForm from '@/components/carrier/_forms/PlanCarrierLoadAcceptationMyDataForm.vue';
import PlanCarrierLoadAcceptationCarrierPackageForm from '@/components/carrier/_forms/PlanCarrierLoadAcceptationCarrierPackageForm.vue';
import { useRouter } from 'vue-router';
import moment from 'moment';
import PlanCarrierLoadAcceptationDriverAndVehicleForm from '@/components/carrier/_forms/PlanCarrierLoadAcceptationDriverAndVehicleForm.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useI18n } from 'vue-i18n';

interface carrierBase {
  load: any;
  loadingLoad: boolean;
}

export default defineComponent({
  name: 'CarrierLoadAcceptation',
  components: {
    PlanIcon,
    PlanCarrierLoadAcceptationDriverAndVehicleForm,
    PlanCarrierLoadAcceptationCarrierPackageForm,
    PlanCarrierLoadAcceptationMyDataForm,
    PlanCarrierLoadAcceptationSteps,
    PlanButton,
    PlanDefaultCarrierView,
  },
  props: {
    id: { type: String, required: false, default: null },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();

    const data = reactive({
      loadingAcceptService: false,
      loadingData: false,
      loadingMyData: false,
      loadingCarrierPackage: false,
      activeStep: 'driverAndVehicle',
    });

    const acceptService = async () => {
      data.loadingAcceptService = true;
      await store.dispatch('ShipmentStore/acceptService', {
        id: store.getters['CarrierStore/getLoadData'].shipment.id,
      });
      data.loadingAcceptService = true;
      await store.dispatch('ShipmentStore/acceptService', {
        id: store.getters['CarrierStore/getLoadData'].shipment.id,
      });

      await store.dispatch(
        'CarrierStore/getLoadInfoForCarrier',
        store.getters['CarrierStore/getLoadData'].id,
      );
      router.push({
        name: 'CarrierLoadAccepted',
        params: { id: store.getters['CarrierStore/getLoadData'].id },
      });
    };

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const getData = async () => {
      data.loadingData = true;
      await getLoad();
      await getUser();
      await getCountries();
      await getCompany();
      await getDrivers();
      await getVehicles();
      data.loadingData = false;
    };

    const getCountries = async () => {
      await store.dispatch('CountryStore/getCountries').then((response) => {
        const actualRegion = store.getters['UserStore/getRegion'];
        const result = response.data
          .filter((country: any) => country.region.name === actualRegion && country.iso !== 'CA')
          .map((country: any) => ({
            label: `+${country.phonePrefix}`,
            value: country.phonePrefix,
          }));
        store.commit('CarrierStore/setRegionPrefixes', result);
      });
    };

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const getDrivers = async () => {
      await store
        .dispatch('CompanyStore/driversList', currentUser.value.company.id)
        .then((response) => {
          store.commit('CarrierStore/setDrivers', response);
        });
    };

    const getUser = async () => {
      if (isSuplanting.value) {
        store.commit('CarrierStore/setMyDataForm', {
          type: currentUser.value.company.type,
          firstName: currentUser.value.name,
          lastName: currentUser.value.surname,
          email: currentUser.value.email,
          prefix: currentUser.value.phonePrefix,
          phone: currentUser.value.phone,
          cif: currentUser.value.company?.identificationNumber,
          companyName: currentUser.value.company.name,
          companyAddress: currentUser.value.company.location?.address,
          dotNumber: currentUser.value.company.dotNumber,
          mcNumber: currentUser.value.company.mcNumber,
          sctNumber: currentUser.value.company.sctNumber,
        });
      } else {
        await store
          .dispatch('UserStore/getUser', store.getters['UserStore/getCurrentUser'])
          .then((response) => {
            store.commit('CarrierStore/setMyDataForm', {
              type: response.company.type,
              firstName: response.name,
              lastName: response.surname,
              email: response.email,
              prefix: response.phonePrefix,
              phone: response.phone,
              cif: response.company?.identificationNumber,
              companyName: response.company.name,
              companyAddress: response.company.location?.address,
              dotNumber: response.company.dotNumber,
              mcNumber: response.company.mcNumber,
              sctNumber: response.company.sctNumber,
            });
          });
      }
    };

    const getVehicles = async () => {
      await store.dispatch('LoadsStore/trailers');
      await store
        .dispatch('CompanyStore/vehiclesList', currentUser.value.company.id)
        .then((response) => {
          store.commit('CarrierStore/setVehicles', response);
        });
    };

    const getCompany = async () => {
      let carrierPackageForm = {
        cargo: {
          id: -1,
          number: '',
          expirationDate: '',
          limitAmount: null,
          mediaObject: null,
        },
        liabilityInsurance: {
          id: -1,
          number: '',
          expirationDate: '',
          limitAmount: null,
          mediaObject: null,
        },
        cif: {
          number: '',
          mediaObject: null,
        },
        mcNumber: {
          number: '',
          mediaObject: null,
        },
        stc: {
          number: '',
          mediaObject: null,
        },
      };

      await store
        .dispatch('CompanyStore/getCompany', currentUser.value.company.id)
        .then((response) => {
          response.insurances.forEach((insurance: any) => {
            if (insurance.type === 'LIABILITY') {
              carrierPackageForm.liabilityInsurance.id = insurance.id;
              carrierPackageForm.liabilityInsurance.number = insurance.number;
              carrierPackageForm.liabilityInsurance.expirationDate = insurance.expirationDate;
              carrierPackageForm.liabilityInsurance.limitAmount = insurance.limitAmount;
              carrierPackageForm.liabilityInsurance.mediaObject = insurance.mediaObject;
            }
            if (insurance.type === 'CARGO') {
              carrierPackageForm.cargo.id = insurance.id;
              carrierPackageForm.cargo.number = insurance.number;
              carrierPackageForm.cargo.expirationDate = insurance.expirationDate;
              carrierPackageForm.cargo.limitAmount = insurance.limitAmount;
              carrierPackageForm.cargo.mediaObject = insurance.mediaObject;
            }
          });

          response.mediaObjects.forEach((mediaObject: any) => {
            if (mediaObject.type === 'W9') carrierPackageForm.cif.mediaObject = mediaObject;
            if (mediaObject.type === 'DOT_MC_NUMBER')
              carrierPackageForm.mcNumber.mediaObject = mediaObject;

            if (mediaObject.type === 'SCT') carrierPackageForm.stc.mediaObject = mediaObject;
          });

          carrierPackageForm.cif.number = response.identificationNumber;
          carrierPackageForm.mcNumber.number = response.mcNumber;
          // change this when back is done
          carrierPackageForm.stc.number = response.sct;

          store.commit('CarrierStore/setCarrierPackageForm', carrierPackageForm);
        });
    };

    const getLoad = async () => {
      await store.dispatch('CarrierStore/getLoadInfoForCarrier', props.id);
    };

    const goToAssigned = async () => {
      router.push({ name: 'CarrierLoadAssigned', params: { id: loadData.value.id } });
    };

    const goToLoadDetails = () => {
      router.push({ name: 'CarrierLoadDetails', params: { id: loadData.value.id } });
    };
    const goToStep = (step: string) => {
      if (!data.loadingData && !data.loadingMyData && !data.loadingCarrierPackage)
        data.activeStep = step;
    };

    const headerData = computed(() => {
      const formatDate =
        store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'MMM DD, YYYY' : 'DD MMM, YYYY';
      let pickup = '';
      let pickupDate = '';
      let delivery = '';
      let deliveryDate = '';

      loadData.value.stops.forEach((stop: any) => {
        if (stop.type === 'PICKUP_INITIAL') {
          pickupDate = moment(stop.executionDate).format(formatDate);
          pickup = `${stop.location?.city}, ${stop.location?.state?.iso} ${stop.location?.state?.country?.iso}`;
        }

        if (stop.type === 'DELIVERY_FINAL') {
          deliveryDate = moment(stop.executionDate).format(formatDate);
          delivery = `${stop.location?.city}, ${stop.location?.state?.iso} ${stop.location?.state?.country?.iso}`;
        }
      });

      return {
        pickup: pickup,
        pickupDate: pickupDate,
        delivery: delivery,
        deliveryDate: deliveryDate,
      };
    });

    const isAssignedLoad = computed(() => {
      const actualState = store.getters['CarrierStore/getLoadData'].status;
      return (
        actualState === 'ASSIGNED' || actualState === 'IN_PROGRESS' || actualState === 'FINISHED'
      );
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const nextStep = () => {
      if (data.activeStep === 'myData') {
        putMyData();
      } else if (data.activeStep === 'carrierPackage') {
        putCarrierPackage();
      }
    };

    const previousStep = () => {
      if (data.activeStep === 'carrierPackage') {
        putCarrierPackage();
        data.activeStep = 'myData';
      } else if (data.activeStep === 'driverAndVehicle') {
        data.activeStep = 'carrierPackage';
      }
    };

    const putMyData = async () => {
      if (!validateMyData.value) {
        await store.dispatch('UserStore/updateToast', {
          text: translate.t('all-fields-required-notification'),
          type: 'error',
          visible: true,
        });
      } else {
        if (!store.getters['CarrierStore/getMyDataForm'].hasModifications) {
          data.activeStep = 'carrierPackage';
          return;
        }

        data.loadingMyData = true;
        let myData = store.getters['CarrierStore/getMyDataForm'];

        const userParams = {
          id: currentUser.value.id,
          user: {
            name: myData.firstName,
            surname: myData.lastName,
            email: myData.email,
            phone: myData.phone,
            phonePrefix: myData.prefix,
          },
        };

        const companyParams = {
          id: currentUser.value.company.id,
          company: {
            type: myData.type,
            identificationNumber: myData.cif,
            name: myData.companyName,
            location: {
              address: myData.companyAddress,
            },
            mcNumber: myData.mcNumber,
            dotNumber: myData.dotNumber,
            sctNumber: myData.sctNumber,
          },
        };

        await store.dispatch('UserStore/putUser', userParams);
        await store.dispatch('CompanyStore/putCompany', companyParams);
        myData.hasModifications = false;
        store.commit('CarrierStore/setMyDataForm', myData);
        data.loadingMyData = false;
        data.activeStep = 'carrierPackage';
      }
    };

    const putCarrierPackage = async () => {
      data.loadingCarrierPackage = true;
      if (store.getters['CarrierStore/getCarrierPackageForm'].cargo.id !== -1) {
        await store.dispatch('ShipmentStore/putInsurances', {
          id: store.getters['CarrierStore/getCarrierPackageForm'].cargo.id,
          body: {
            number: store.getters['CarrierStore/getCarrierPackageForm'].cargo.number,
            limitAmount: store.getters['CarrierStore/getCarrierPackageForm'].cargo.limitAmount,
            expirationDate:
              store.getters['CarrierStore/getCarrierPackageForm'].cargo.expirationDate,
          },
        });
      } else {
        if (
          store.getters['CarrierStore/getCarrierPackageForm'].cargo.number !== '' &&
          store.getters['CarrierStore/getCarrierPackageForm'].cargo.limitAmount !== null &&
          store.getters['CarrierStore/getCarrierPackageForm'].cargo.expirationDate !== ''
        ) {
          await store
            .dispatch('ShipmentStore/postInsurances', {
              type: 'CARGO',
              number: store.getters['CarrierStore/getCarrierPackageForm'].cargo.number,
              limitAmount: store.getters['CarrierStore/getCarrierPackageForm'].cargo.limitAmount,
              expirationDate:
                store.getters['CarrierStore/getCarrierPackageForm'].cargo.expirationDate,
              company: { id: currentUser.value.company.id },
            })
            .then((response) => {
              if (store.getters['CarrierStore/getFilesToUpload'].cargoInsurance !== null) {
                store
                  .dispatch('ShipmentStore/postMediaObjectInsurance', {
                    entityId: response.id,
                    file: store.getters['CarrierStore/getFilesToUpload'].cargoInsurance,
                  })
                  .then(() => {
                    store.commit('CarrierStore/setCargoInsuranceMediaObject', null);
                  });
              }
            });
        }
      }

      if (store.getters['CarrierStore/getCarrierPackageForm'].liabilityInsurance.id !== -1) {
        await store.dispatch('ShipmentStore/putInsurances', {
          id: store.getters['CarrierStore/getCarrierPackageForm'].liabilityInsurance.id,
          body: {
            number: store.getters['CarrierStore/getCarrierPackageForm'].liabilityInsurance.number,
            limitAmount:
              store.getters['CarrierStore/getCarrierPackageForm'].liabilityInsurance.limitAmount,
            expirationDate:
              store.getters['CarrierStore/getCarrierPackageForm'].liabilityInsurance.expirationDate,
          },
        });
      } else {
        if (
          store.getters['CarrierStore/getCarrierPackageForm'].liabilityInsurance.number !== '' &&
          store.getters['CarrierStore/getCarrierPackageForm'].liabilityInsurance.limitAmount !==
            null &&
          store.getters['CarrierStore/getCarrierPackageForm'].liabilityInsurance.expirationDate !==
            ''
        ) {
          await store
            .dispatch('ShipmentStore/postInsurances', {
              type: 'LIABILITY',
              number: store.getters['CarrierStore/getCarrierPackageForm'].liabilityInsurance.number,
              limitAmount:
                store.getters['CarrierStore/getCarrierPackageForm'].liabilityInsurance.limitAmount,
              expirationDate:
                store.getters['CarrierStore/getCarrierPackageForm'].liabilityInsurance
                  .expirationDate,
              company: { id: currentUser.value.company.id },
            })
            .then((response) => {
              if (store.getters['CarrierStore/getFilesToUpload'].liabilityInsurance !== null) {
                store
                  .dispatch('ShipmentStore/postMediaObjectInsurance', {
                    entityId: response.id,
                    file: store.getters['CarrierStore/getFilesToUpload'].liabilityInsurance,
                  })
                  .then(() => {
                    store.commit('CarrierStore/setLiabilityInsuranceMediaObject', null);
                  });
              }
            });
        }
      }

      data.loadingCarrierPackage = false;
      data.activeStep = 'driverAndVehicle';
    };

    const stopsData = computed(() => {
      let result: Array<any> = [];

      loadData.value.stops.forEach((stop: any, index: number) => {
        let toCity = stop.location.city;
        if (index < loadData.value.stops.length - 1) {
          toCity = loadData.value.stops[index + 1].location.city;
        }

        result.push({
          stop: stop,
          toCity: toCity,
        });
      });

      return result;
    });

    const validateMyData = computed(() => {
      const myDataForm = store.getters['CarrierStore/getMyDataForm'] || {};

      let result = true;

      let fieldsToCheck = ['firstName', 'lastName', 'email', 'prefix', 'phone', 'companyName'];

      if (
        myDataForm.type === 'CARRIER' &&
        (loadData.value.type === 'MEX_DOMESTIC' || loadData.value.legOrder === 1)
      ) {
        fieldsToCheck.push('sctNumber');
      }

      if (
        myDataForm.type === 'CARRIER' &&
        (loadData.value.legOrder === 2 || loadData.value.legOrder === 3)
      ) {
        fieldsToCheck.push('sctNumber');
        fieldsToCheck.push('dotNumber');
        fieldsToCheck.push('mcNumber');
      } else if (
        myDataForm.type === 'BROKER' &&
        (loadData.value.legOrder === 2 || loadData.value.legOrder === 3)
      ) {
        fieldsToCheck.push('mcNumber');
      }

      fieldsToCheck.forEach((field) => {
        if (!myDataForm[field] || myDataForm[field].length === 0) {
          result = false;
        }
      });

      return result;
    });

    getData();

    return {
      ...toRefs(data),
      actualWidth,
      acceptService,
      currentUser,
      goToAssigned,
      goToLoadDetails,
      goToStep,
      headerData,
      isAssignedLoad,
      loadData,
      nextStep,
      previousStep,
      stopsData,
    };
  },
});
