
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { SmartTagz } from 'smart-tagz';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import ContactDetailInsurance from '@/modules/planning/contacts/ContactDetailInsurance.vue';
import ContactDetailLanes from '@/modules/planning/contacts/ContactDetailLanes.vue';
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
// Styles
import 'smart-tagz/dist/smart-tagz.css';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';

export default defineComponent({
  name: 'ContactDetail',
  components: {
    PhoneNumberSelector,
    ContactDetailInsurance,
    ContactDetailLanes,
    ErrorMessage,
    Field,
    Form,
    ModalConfirm,
    SmartTagz,
  },
  mixins: [BaseComponent, CountryMixins],
  data() {
    return {
      emailArray: [] as any,
      emailToAdd: '' as any,
      idToDelete: null,
      showDelete: false,
      userChecked: '',
      currentView: 'user',
      newView: '',
      vehiclesArray: null as any,
      updateCompany: false,
      vehicleSelected: null,
      addDriver: false,
      addVehicle: false,
      driverSelected: null as any,
      showEditModal: false,
      gobackButton: false,
      driver: {
        name: null,
        surname: null,
        drivingLicense: null,
      },
      user: {
        name: null,
        surname: null,
        phonePrefix: '',
        phone: null,
        email: null,
      },
      company: {
        name: null,
        dotNumber: null,
        mcNumber: null,
        identificationNumber: null,
        location: { address: '' },
      },
      specialPermits: {
        hasTransportAndDeliveryOfAlcoholicBeveragesPermit: false,
        hasHazardousMaterialPermit: false,
        hasTWICCard: false,
        hasTSA: false,
        hasOverweightLoadPermit: false,
        hasTeamDriverPermit: false,
        hasBondPermit: false,
        hasTirPermit: false,
        hasMoveFullTruckPermit: false,
        hasMovePartialPermit: false,
        hasOversizeLoadPermit: false,
      },
      trailer: {
        trailerType: { id: null },
        trailerPlate: '',
        licensePlate: null as any,
      },
    };
  },
  beforeCreate() {
    this.$store.commit('CompanyStore/setCompany', {});
    this.$store.dispatch('LoadsStore/trailers');
  },
  async created() {
    window.addEventListener('beforeunload', this.showAlert);
    await this.$store.dispatch('UserStore/getUser', { id: this.$route.params.id });
    await this.$store.dispatch('CompanyStore/getCompany', this.getUser.company.id);
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.showAlert);
  },
  computed: {
    areAllEmailsValid(): any {
      return this.emailArray.every((email) => this.isValidEmail(email));
    },
    companyAddressModified() {
      let modified = false;
      if (this.currentCompany.location?.id) {
        modified = this.currentCompany.location?.address !== this.company.location.address;
      } else {
        modified = this.company?.location?.address?.length > 0;
      }

      return modified;
    },
    shouldUpdateUser(): any {
      return (
        this.getUser.name !== this.user.name ||
        this.getUser.surname !== this.user.surname ||
        this.getUser.phonePrefix !== this.user.phonePrefix ||
        this.getUser.phone !== this.user.phone ||
        this.getUser.email !== this.user.email ||
        (this.userChecked !== '' && this.userChecked !== this.userType)
      );
    },
    shouldUpdateCompany(): any {
      return (
        this.currentCompany.dotNumber !== this.company.dotNumber ||
        this.currentCompany.mcNumber !== this.company.mcNumber ||
        this.currentCompany.name !== this.company.name ||
        this.currentCompany.hasHazardousMaterialPermit !==
          this.specialPermits.hasHazardousMaterialPermit ||
        this.currentCompany.hasTransportAndDeliveryOfAlcoholicBeveragesPermit !==
          this.specialPermits.hasTransportAndDeliveryOfAlcoholicBeveragesPermit ||
        this.currentCompany.hasTWICCard !== this.specialPermits.hasTWICCard ||
        this.currentCompany.hasTSA !== this.specialPermits.hasTSA ||
        this.currentCompany.hasOverweightLoadPermit !==
          this.specialPermits.hasOverweightLoadPermit ||
        this.currentCompany.hasTeamDriverPermit !== this.specialPermits.hasTeamDriverPermit ||
        this.currentCompany.hasBondPermit !== this.specialPermits.hasBondPermit ||
        this.currentCompany.identificationNumber !== this.company.identificationNumber ||
        this.companyAddressModified ||
        this.currentCompany.hasTirPermit !== this.specialPermits.hasTirPermit ||
        this.currentCompany.hasMoveFullTruckPermit !== this.specialPermits.hasMoveFullTruckPermit ||
        this.currentCompany.hasMovePartialPermit !== this.specialPermits.hasMovePartialPermit ||
        this.currentCompany.hasOversizeLoadPermit !== this.specialPermits.hasOversizeLoadPermit
      );
    },
    getUser(): any {
      return this.$store.getters['UserStore/getUser'];
    },
    currentCompany(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    userType(): any {
      return this.getUser.company ? this.getUser.company.type : null;
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
    drivers(): any {
      return this.$store.getters['CompanyStore/getDriversList'];
    },
    vehicles(): any {
      return this.$store.getters['CompanyStore/getVehiclesList'];
    },
  },
  methods: {
    contactType(type) {
      let translation = type;

      if (translation === 'CARRIER') {
        translation = this.$t('carrier-certifications');
      }
      if (translation === 'BROKER') {
        translation = this.$t('broker-operator');
      }

      return translation;
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    sendEmails() {
      if (this.areAllEmailsValid) {
        this.$store
          .dispatch('CompanyStore/updateLoadEmails', {
            id: this.currentCompany.id,
            loadNotificationEmails: this.emailArray,
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('message-updated-successfully'));
            this.$store.dispatch('CompanyStore/getCompany', this.getUser.company.id);
          });
      } else {
        BaseComponent.methods?.showToastError(this.$t('email-not-valid'));
      }
    },
    changePhone(type, phone) {
      switch (type) {
        case 'prefix':
          this.user.phonePrefix = phone.prefix;
          break;
        case 'number':
          this.user.phone = phone.number;
          break;
        default:
          return;
      }
    },
    checkEmail(result) {
      this.emailArray = result;
      this.sendEmails();
    },
    createDriver() {
      this.$store
        .dispatch('CompanyStore/postDriver', {
          ...this.driver,
          company: { id: this.currentCompany.id },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
          this.addDriver = false;
          this.$store.dispatch('CompanyStore/driversList', this.currentCompany.id);
        });
    },
    goback() {
      if (this.shouldUpdateCompany || this.shouldUpdateUser) {
        this.showEditModal = true;
        this.gobackButton = true;
      } else {
        this.$router.go(-1);
      }
    },
    changeCurrentView(view) {
      if (this.shouldUpdateCompany || this.shouldUpdateUser) {
        this.showEditModal = true;
        this.newView = view;
      } else {
        this.currentView = view;
      }
    },
    onConfirmNavigation() {
      this.currentView = this.newView;
      this.showEditModal = false;
      this.shouldUpdateCompany = false;
      this.shouldUpdateUser = false;
    },
    showAlert(event) {
      if (this.shouldUpdateCompany || this.shouldUpdateUser) {
        event.preventDefault();
        event.returnValue = '';
        return this.$t('message-pending-changes');
      }
    },
    editDriver(driver) {
      this.$store
        .dispatch('CompanyStore/putDriver', {
          id: driver.id,
          params: {
            name: driver.name,
            surname: driver.surname,
            drivingLicense: driver.drivingLicense,
          },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('updated-correctly'));
          this.addDriver = false;
          this.$store.dispatch('CompanyStore/driversList', this.currentCompany.id);
        });
    },
    async submit() {
      if (this.userChecked !== '' && this.userChecked !== this.userType) {
        const data = {
          id: this.currentCompany.id,
          type: {
            type: this.userChecked,
          },
        };
        this.$store
          .dispatch('CompanyStore/putCompanyUpdateType', data)
          .then(() => {
            this.$store.dispatch('CompanyStore/getCompany', this.getUser.company.id);
            BaseComponent.methods?.showToastSuccess(this.$t('information-updated'));
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });
      }
      if (this.shouldUpdateUser) {
        await this.$store
          .dispatch('UserStore/putUser', {
            id: this.getUser.id,
            user: this.user,
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('information-updated'));
            this.$store.dispatch('UserStore/getUser', { id: this.$route.params.id });
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });
      }

      if (this.shouldUpdateCompany) {
        this.$store
          .dispatch('CompanyStore/putCompany', {
            id: this.getUser.company.id,
            company: { ...this.company, ...this.specialPermits },
          })
          .then(() => {
            this.$store.dispatch('CompanyStore/getCompany', this.getUser.company.id);
            BaseComponent.methods?.showToastSuccess(this.$t('information-updated'));
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });
      }
    },
    updateData() {
      const vehicle = this.vehicles.find((v) => v.id === this.vehicleSelected);
      const newTrailer = this.trailerTypes.find((v) => v.id === vehicle.trailerType.id);

      this.$store
        .dispatch('CompanyStore/putVehicle', {
          id: this.vehicleSelected,
          trailerType: newTrailer,
          trailerPlate: vehicle.trailerPlate,
          licensePlate: vehicle.licensePlate,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('message-updated-successfully'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    },
    openModalDelete(id) {
      this.idToDelete = id;
      this.showDelete = true;
    },
    deleteDriver(isDelete) {
      if (isDelete) {
        this.$store
          .dispatch('CompanyStore/deleteDriver', this.idToDelete)
          .then(() => {
            this.$store.dispatch('CompanyStore/driversList', this.currentCompany.id);
            this.driverSelected = 0;
          })
          .catch((err) => {
            if (err) {
              BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            }
          })
          .finally(() => {
            this.showDelete = false;
          });
      } else {
        this.showDelete = false;
      }
    },

    deleteVehicle() {
      this.$store
        .dispatch('CompanyStore/deleteVehicles', {
          id: this.vehicleSelected,
        })

        .then(() => {
          this.$store.dispatch('CompanyStore/vehiclesList', this.getUser.company.id);
          BaseComponent.methods?.showToastSuccess(this.$t('message-updated-successfully'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    },

    createVehicle() {
      this.$store
        .dispatch('CompanyStore/postVehicle', {
          ...this.trailer,
          company: { id: this.getUser.company.id },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
          this.addVehicle = false;
          this.$store.dispatch('CompanyStore/vehiclesList', this.getUser.company.id);
          this.vehiclesArray = this.vehicles;
        });
    },
    setPermitsInfo() {
      this.specialPermits.hasTransportAndDeliveryOfAlcoholicBeveragesPermit =
        this.currentCompany.hasTransportAndDeliveryOfAlcoholicBeveragesPermit;
      this.specialPermits.hasHazardousMaterialPermit =
        this.currentCompany.hasHazardousMaterialPermit;
      this.specialPermits.hasTWICCard = this.currentCompany.hasTWICCard;
      this.specialPermits.hasTSA = this.currentCompany.hasTSA;
      this.specialPermits.hasOverweightLoadPermit = this.currentCompany.hasOverweightLoadPermit;
      this.specialPermits.hasTeamDriverPermit = this.currentCompany.hasTeamDriverPermit;
      this.specialPermits.hasBondPermit = this.currentCompany.hasBondPermit;
      this.specialPermits.hasTirPermit = this.currentCompany.hasTirPermit;
      this.specialPermits.hasMoveFullTruckPermit = this.currentCompany.hasMoveFullTruckPermit;
      this.specialPermits.hasMovePartialPermit = this.currentCompany.hasMovePartialPermit;
      this.specialPermits.hasOversizeLoadPermit = this.currentCompany.hasOversizeLoadPermit;
      this.company.identificationNumber = this.currentCompany.identificationNumber;
    },
    setUserInfo() {
      this.user.name = this.getUser.name;
      this.user.surname = this.getUser.surname;
      this.user.email = this.getUser.email;
      this.user.phonePrefix = this.getUser?.phonePrefix;
      this.user.phone = this.getUser.phone;
      this.company.name = this.getUser.company.name;
    },
  },
  watch: {
    async getUser() {
      this.setUserInfo();
      await this.$store.dispatch('CompanyStore/getCompany', this.getUser?.company?.id);
      await this.$store.dispatch('CompanyStore/vehiclesList', this.getUser?.company?.id);
      this.vehiclesArray = this.vehicles;
    },
    currentCompany() {
      if (this.currentCompany?.id === this.getUser?.company?.id) {
        this.setPermitsInfo();
        this.$store.dispatch('CompanyStore/driversList', this.currentCompany.id);
        this.company.dotNumber = this.currentCompany.dotNumber;
        this.company.mcNumber = this.currentCompany.mcNumber;
        if (this.currentCompany?.location?.id) {
          this.company.location = { ...this.currentCompany.location };
        }
      }
    },
  },
});
