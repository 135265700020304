
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import cloneDeep from 'lodash/cloneDeep';
// Base
import BaseComponent from '@/base/BaseComponent';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
// Components
import CarrierHeader from '@/components/headers/CarrierHeader.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import ModalBasic from '@/components/modals/BasicModal.vue';
import ModalHash from '@/components/modals/HashModal.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import SidebarChat from '@/components/sidebar/ChatSidebar.vue';
import SkeletonRequestForQuoteCarrier from '@/components/skeletons/RequestForQuoteCarrierSkeleton.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import { defaults } from 'lodash';
// Utils
import { objectLocationToString } from '@/utils/formatLocation';

export default defineComponent({
  name: 'RequestForQuoteCarrier',
  components: {
    CarrierHeader,
    ErrorMessage,
    Field,
    Form,
    InputAmericanFormat,
    ModalBasic,
    ModalHash,
    PlanIcon,
    SidebarChat,
    PlanButton,
    SkeletonRequestForQuoteCarrier,
    PlanLoading,
  },
  mixins: [BaseComponent, CountryMixins],
  data() {
    return {
      buttonsModalBasic: [
        {
          text: this.$t('continue'),
          type: 'corp',
          callback: () => this.toggleModalSuccess(),
        },
      ],
      dateFormat: 'MM-dd-yyyy',
      isEditing: false,
      isHovered: false,
      isLanesChanged: false,
      loadingLanes: false,
      isSending: false,
      itemsPerPage: 30,
      currentPage: 1,
      laneListCopy: [],
      loading: false,
      showModalSuccess: false,
      textsModalBasic: {
        body: '',
        header: this.$t('message-quotes-sent-correctly'),
        message: '',
      },
      lanesList: this.$store.getters['RFPStore/getLanes'],
    };
  },
  beforeCreate() {
    this.$store.dispatch('PusherStore/initializePusher');
  },
  async created() {
    this.fetchAllInfo();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.currentProject.computed?.lanes?.totalAmount / this.itemsPerPage);
    },
    getLanesAndQuotesForCarrier(): any {
      return this.$store.getters['RFPStore/getLanesAndQuotesForCarrier'].laneAndQuote;
    },
    quotesOnPage(): any {
      return this.$store.getters['RFPStore/getLanesAndQuotesForCarrier']?.computed?.quotesOnPage;
    },
    company(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    companyShipperOwner(): any {
      return this.currentProject ? this.currentProject?.project?.shipperOwner : null;
    },
    currentCarrierRFPQuotes(): any {
      return this.$store.getters['RFPStore/getCurrentCarrierRFPQuotes'];
    },
    currentProject(): any {
      return this.$store.getters['RFPStore/getCurrentProject'];
    },
    currentRouteName(): any {
      return this.$route.name;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    objectLocationToString(): any {
      return objectLocationToString;
    },
    isChatRead(): any {
      if (
        this.getLanesAndQuotesForCarrier[0]?.lane?.chatInformation &&
        this.getLanesAndQuotesForCarrier[0]?.lane?.chatInformation[0] &&
        this.getLanesAndQuotesForCarrier[0]?.lane?.chatInformation[0][0]
      ) {
        return this.getLanesAndQuotesForCarrier[0]?.lane?.chatInformation[0][0].readed;
      }
      return true;
    },
    isEditingQuotes(): any {
      return this.isLanesChanged && this.quotesOnPage > 0;
    },
    isOpenChat(): any {
      return this.$store.state.GlobalStore.isOpenChat;
    },
    isSomeQuote(): any {
      return this.getLanesAndQuotesForCarrier?.some((lane) => lane.bid && lane.coverage);
    },
    lanesRFQRequestBidsGroup(): any {
      return this.$store.getters['LanesStore/getGroupBids'];
    },
    quotationDate(): any {
      if (this.currentCarrierRFPQuotes.length > 0) {
        return (
          this.currentCarrierRFPQuotes.reduce((prev, current) =>
            prev.quotationDate > current.quotationDate ? prev : current,
          ).quotationDate ?? null
        );
      }
      return null;
    },
    shipmentId(): any {
      return this.$route.params.id;
    },
    token(): any {
      return localStorage.getItem('token') ? localStorage.getItem('token') : null;
    },
    userForChat(): any {
      return this.$store.state.ChatsStore.userForChat;
    },
  },
  watch: {
    currentUser() {
      if (this.currentUser?.company) {
        this.checkCountry();
      }
    },
  },
  methods: {
    nextPage() {
      if (this.currentPage < 10) {
        this.currentPage++;
        this.getLanes();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getLanes();
      }
    },
    async getLanes() {
      this.loadingLanes = true;

      await this.$store
        .dispatch('RFPStore/getLanesAndQuotesForCarrier', {
          projectId: this.$route.params.id,
          page: this.currentPage,
        })
        .then(() => {
          this.updatedLaneList();
        })
        .finally(() => {
          this.loadingLanes = false;
        });
    },
    cancelUpdate() {
      if (this.isSending) return;
      this.isEditing = false;
      this.isLanesChanged = false;
    },
    async carrierQuoteLanes() {
      const validation = await (this.$refs.form as any).validate();

      if (!validation?.valid) {
        return;
      }
      if (!this.isSomeQuote) return;

      this.isSending = true;
      // Without the timeout, the validation doesn't work as it should
      setTimeout(async () => {
        let quotes = [] as any;

        this.getLanesAndQuotesForCarrier.map((item) => {
          if (item.bid && item.coverage && !(item?.quote?.quoteRequest?.status === 'CONFIRMED')) {
            quotes.push({
              price: item.bid,
              capacity: item.coverage,
              lane: {
                id: item.lane.id,
              },
            });
          }
        });
        if (quotes.length === 0) {
          this.isSending = false;
          return;
        }

        quotes = { quotes: quotes };

        this.$store
          .dispatch('RFPStore/postQuoteRFP', quotes)
          .then(() => {
            this.fetchAllInfo();
            BaseComponent.methods?.showToastSuccess(this.$t('quote-send-correctly'));
            this.showModalSuccess = true;
          })
          .finally(() => {
            this.isEditing = false;
            this.isSending = false;
          });
      }, 300);
    },
    checkCountry() {
      this.$store
        .dispatch('CountryStore/getCountryById', this.currentUser?.company?.country?.id)
        .then(() => {
          const lang = this.getLocale.substring(0, 2);
          this.$i18n.locale = lang;
          localStorage.setItem(
            'userLocale',
            this.$store.getters['CountryStore/getCountryDefaultLanguageLocale'].substring(0, 2),
          );
          this.$emit('forceUpdate');
        });
    },
    confirmBid(laneId) {
      this.$store
        .dispatch('RFPStore/postCarrierConfirm', laneId)
        .then(async () => {
          await this.$store
            .dispatch('RFPStore/getLanesAndQuotesForCarrier', {
              projectId: this.$route.params.id,
              page: this.currentPage,
            })
            .then(() => {
              this.updatedLaneList();
            });

          BaseComponent.methods?.showToastSuccess(this.$t('bid-confirmed'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.title);
        });
    },
    async fetchAllInfo() {
      this.loading = true;
      const id = this.$route.params.id;
      const hash: any = this.$route.params.hash;
      const code: any = this.$route.params.code;
      if (hash && code) {
        await this.$store
          .dispatch('UserStore/hashValidation', {
            hashId: hash,
            secureCode: code,
          })
          .then(() => {
            this.$store.dispatch('PusherStore/initializePusher');
            switch (this.currentRouteName) {
              case 'RequestForQuoteCarrierCode':
                this.$router.push('/carrier/requestForQuote/' + id);
                break;
              case 'RequestForQuoteCarrierCodeChat':
                this.$router.push('/carrier/requestForQuote/' + id + '/chat');
                break;
            }
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
            this.$store.commit('UserStore/setShowHash', true);
          })
          .finally(() => {
            // this.loading = false;
          });
      }

      await this.$store
        .dispatch('RFPStore/getProjectByIdCarrier', {
          projectId: this.$route.params.id,
          page: 1,
        })
        .catch((error) => {
          console.error('Error fetching lanes:', error);
        });

      await this.$store.dispatch('RFPStore/getLanesAndQuotesForCarrier', {
        projectId: this.$route.params.id,
        page: this.currentPage,
      });

      await this.$store
        .dispatch('RFPStore/getLanesByProjectId', {
          projectId: this.$route.params.id,
          page: this.currentPage,
          maxResults: this.itemsPerPage,
        })
        .then((response) => {
          this.lanesList = response;
          this.$store
            .dispatch('RFPStore/getQuoteRFPCarrier', {
              projectId: this.$route.params.id,
              page: this.currentPage,
            })
            .then(() => {
              this.updatedLaneList();
              if (this.lanesList && this.lanesList.length > 0) {
                (this.laneListCopy as any) = cloneDeep(this.lanesList);
              }
            });
        })
        .catch((error) => {
          console.error('Error fetching lanes:', error);
        });
      if (this.currentRouteName == 'RequestForQuoteCarrierChat') {
        this.openChat();
      }
      this.loading = false;
      this.isLanesChanged = false;
    },
    fillData(firstArray, secondArray) {
      firstArray.forEach((item, index) => {
        const matchingItem = secondArray.find((secItem) => secItem.lanesRFQ.id === item.id);
        if (matchingItem) {
          firstArray[index].bid = matchingItem.bid; // Assuming `matchingItem.bid` holds the bid value
          firstArray[index].coverage = matchingItem.coverage; // Assuming `matchingItem.coverage` holds the coverage value
        }
      });
    },
    getDateString(load) {
      const initialPick = new Date(
        load.stops.find((stop) => stop.type === 'PICKUP_INITIAL').executionDate,
      );
      const finalDelivery = new Date(
        load.stops.find((stop) => stop.type === 'DELIVERY_FINAL').executionDate,
      );

      const firstDayOfWeek = new Date(
        initialPick.getFullYear(),
        initialPick.getMonth(),
        initialPick.getDate(),
      );
      const lastDayOfWeek = new Date(
        finalDelivery.getFullYear(),
        finalDelivery.getMonth(),
        finalDelivery.getDate(),
      );

      const formattedFirstDay = firstDayOfWeek.toLocaleDateString('default', {
        month: 'short',
        day: 'numeric',
      });
      const formattedLastDay = lastDayOfWeek.toLocaleDateString('default', {
        month: 'short',
        day: 'numeric',
      });
      const currentYear = initialPick.getFullYear();
      return `${formattedFirstDay} - ${formattedLastDay}, ${currentYear}`;
    },
    getLocationString(load) {
      const initialPick = load.stops.find((stop) => stop.type === 'PICKUP_INITIAL');
      const finalDelivery = load.stops.find((stop) => stop.type === 'DELIVERY_FINAL');

      const initialPickLocation = [
        initialPick?.location?.city,
        initialPick?.location?.state?.isoGoogle,
        initialPick?.location?.state?.country?.iso,
      ]
        .filter(Boolean)
        .join(', ');

      const finalDeliveryLocation = [
        finalDelivery?.location?.city,
        finalDelivery?.location?.state?.isoGoogle,
        finalDelivery?.location?.state?.country?.iso,
      ]
        .filter(Boolean)
        .join(', ');

      return `${initialPickLocation} - ${finalDeliveryLocation}`;
    },
    getMonthName(monthIndex) {
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      return monthNames[monthIndex];
    },
    getPosition(lane) {
      const pos: any = this.numberSuffixAndColor(lane?.quote?.rankingOrder);
      return pos.suffix;
    },
    getSpecialRequirements(load) {
      if (load.specialRequirement && Object.keys(load.specialRequirement).length !== 0) {
        const requirements = Object.keys(load.specialRequirement).filter(
          (key) => load.specialRequirement[key] === true,
        );
        requirements.forEach((req, index) => {
          if (req === 'specialPermissions') {
            requirements[index] =
              this.$t(req) + ': ' + load.specialRequirement?.specialPermissionsDescription;
          } else {
            requirements[index] = this.$t(req);
          }
        });
        return requirements.join(', ');
      }
      return null;
    },
    getTemperatures(lane) {
      const minTemp = lane.loadTemplate.minTemp;
      const maxTemp = lane.loadTemplate.maxTemp;

      if (!isNaN(maxTemp) && minTemp !== maxTemp) {
        return `(${this.formatTemperature(minTemp)} , ${this.formatTemperature(maxTemp)})`;
      } else {
        return `(${this.formatTemperature(minTemp)})`;
      }
    },
    isAcceptedQuote(lane) {
      const result = (lane?.quote && lane?.quote?.quoteRequest?.status === 'CONFIRMED') || false;

      return result;
    },
    isPreAcceptedQuote(lane) {
      return lane?.quote?.quoteRequest?.status === 'ACCEPTING';
    },
    isRefusedQuote(lane) {
      return lane?.quote?.quoteRequest?.status === 'REFUSED';
    },
    isQuoting(lane) {
      return lane?.quote?.quoteRequest?.status === 'QUOTED';
    },
    isEqualArray(arr1, arr2) {
      // Perform a deep comparison of two arrays of objects
      if (arr1.length !== arr2.length) {
        return false;
      }

      for (let i = 0; i < arr1.length; i++) {
        if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
          return false;
        }
      }

      return true;
    },
    async openChat() {
      if (JSON.stringify(this.userForChat) !== JSON.stringify(this.companyShipperOwner)) {
        this.$store.commit('LoadsStore/loadId', null);
        this.$store.commit('GlobalStore/setOpenChat', false);
        if (
          this.getLanesAndQuotesForCarrier[0]?.lane?.chatInformation &&
          this.getLanesAndQuotesForCarrier[0]?.lane?.chatInformation[0] &&
          this.getLanesAndQuotesForCarrier[0]?.lane?.chatInformation[0][0]
        ) {
          this.getLanesAndQuotesForCarrier[0].lane.chatInformation[0][0].readed = true;
        }
        setTimeout(() => {
          this.$store.commit('ChatsStore/setUserForChat', {
            owner: this.currentProject?.project?.shipperOwner,
          });
          this.$store.commit(
            'ChatsStore/setLaneForChat',
            this.getLanesAndQuotesForCarrier[0]?.lane,
          );
          if (
            this.getLanesAndQuotesForCarrier[0]?.lane?.chatInformation &&
            this.getLanesAndQuotesForCarrier[0]?.lane?.chatInformation[0] &&
            this.getLanesAndQuotesForCarrier[0]?.lane?.chatInformation[0][0]
          ) {
            this.$store.commit(
              'ChatsStore/setChatId',
              this.getLanesAndQuotesForCarrier[0]?.lane?.chatInformation[0][0]?.chat ?? null,
            );
          }
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    orderStops(stops) {
      const initialPickup = stops.find((stop) => stop.type === 'PICKUP_INITIAL');
      const finalDelivery = stops.find((stop) => stop.type === 'DELIVERY_FINAL');
      let order = [
        initialPickup,
        ...stops.filter((stop) => stop.type !== 'PICKUP_INITIAL' && stop.type !== 'DELIVERY_FINAL'),
        finalDelivery,
      ];
      return order.map((stop, i) => {
        return { ...stop, sequence: i + 1 };
      });
    },
    toggleModalSuccess() {
      this.showModalSuccess = !this.showModalSuccess;
    },
    updatedLaneList() {
      let lanesList = null as any;

      lanesList = this.getLanesAndQuotesForCarrier;

      const updatedList = [...lanesList];

      for (const lane of updatedList) {
        if (lane?.quote?.id) {
          lane.bid = lane?.quote?.price;
          lane.coverage = lane?.quote.maxCapacity;
        }
      }
    },
    async withdrawBid(quoteId, index) {
      await this.$store
        .dispatch('RFPStore/deleteQuoteRFP', quoteId)
        .then(async () => {
          await this.$store
            .dispatch('RFPStore/getLanesAndQuotesForCarrier', {
              projectId: this.$route.params.id,
              page: this.currentPage,
            })
            .then(() => {
              this.updatedLaneList();
            });

          BaseComponent.methods?.showToastSuccess(this.$t('withdraw-bid'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.title);
        });
    },
  },
});
