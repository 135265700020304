
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'PlanLoadFormSpecialRequirements',
  components: { PlanInputText, PlanInputCheckbox },
  emits: ['change'],
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive({
      specialRequirements: {
        teamDriver: false,
        bondedPermission: false,
        tsaCard: false,
        twicCard: false,
        hazardousLoad: false,
        specialPermits: false,
        specialPermitsValue: '',
        tirCable: false
      }
    })

    const actualRegion = computed(() => {
      return store.getters['UserStore/getRegion']
    })

    const change = () => {
      emit('change', data.specialRequirements)
    }

    const changeCheckbox = (checkboxData: any) => {
      if (checkboxData.id === 1) data.specialRequirements.teamDriver = checkboxData.value
      if (checkboxData.id === 2) data.specialRequirements.bondedPermission = checkboxData.value
      if (checkboxData.id === 3) data.specialRequirements.tsaCard = checkboxData.value
      if (checkboxData.id === 4) data.specialRequirements.twicCard = checkboxData.value
      if (checkboxData.id === 5) data.specialRequirements.hazardousLoad = checkboxData.value
      if (checkboxData.id === 6) data.specialRequirements.specialPermits = checkboxData.value
      change()
    }

    watch(() => store.getters['NewFtlLoad/getTemplateData'], () => {
      const template = store.getters['NewFtlLoad/getTemplateData']
      if (template !== null) {
        data.specialRequirements.teamDriver = template.load.specialRequirement.teamDriver
        data.specialRequirements.twicCard = template.load.specialRequirement.TWICCard
        data.specialRequirements.bondedPermission = template.load.specialRequirement.bondedPermission
        data.specialRequirements.hazardousLoad = template.load.specialRequirement.hazardousLoad
        data.specialRequirements.specialPermits = template.load.specialRequirement.specialPermissions
        data.specialRequirements.specialPermitsValue = template.load.specialRequirement.specialPermissionsDescription
        data.specialRequirements.tirCable = template.load.specialRequirement.tir
        data.specialRequirements.tsaCard = template.load.specialRequirement.tsa
      }
    })

    return {
      ...toRefs(data),
      actualRegion,
      change,
      changeCheckbox
    }
  }
})
