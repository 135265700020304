import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex justify-center items-center relative",
  style: {"z-index":"9499"}
}
const _hoisted_2 = {
  class: "w-full flex flex-col justify-start items-start plan-gap-sm",
  style: {"min-width":"300px"}
}
const _hoisted_3 = { class: "flex justify-between items-center" }
const _hoisted_4 = { class: "flex justify-end items-center plan-gap-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_mail_notifications_configuration_modal = _resolveComponent("plan-mail-notifications-configuration-modal")!
  const _component_plan_avatar = _resolveComponent("plan-avatar")!
  const _component_sidebar_user_button = _resolveComponent("sidebar-user-button")!
  const _component_PlanSelectSearch = _resolveComponent("PlanSelectSearch")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.actualWidth <= 640 ? 'carrier-sidebar-top' : 'carrier-sidebar'),
    style: {"z-index":"9499"}
  }, [
    _createVNode(_component_plan_icon, {
      name: "planimatick-collapsed",
      size: "32",
      "is-svg": ""
    }),
    (_ctx.showEmailConfiguration)
      ? (_openBlock(), _createBlock(_component_plan_mail_notifications_configuration_modal, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showEmailConfiguration = false)),
          "is-carrier-view": ""
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_popper, {
        hover: "",
        offsetSkid: "0",
        offsetDistance: "10"
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_sidebar_user_button, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showEmailConfiguration = true)),
              label: _ctx.$t('notifications'),
              icon: "bell",
              class: "w-full"
            }, null, 8, ["label"]),
            _createVNode(_component_sidebar_user_button, {
              label: _ctx.$t('timezone'),
              icon: "clock",
              class: "w-full"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_PlanSelectSearch, {
                    title: "find-your-timezone",
                    size: "small",
                    items: _ctx.timezonesFormatted,
                    inputValue: _ctx.timeZone,
                    placeholder: "",
                    onValueChange: _ctx.timezoneOnChange
                  }, null, 8, ["items", "inputValue", "onValueChange"])
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_sidebar_user_button, {
              label: _ctx.$t('language'),
              icon: "language-exchange",
              class: "w-full"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_plan_button, {
                    onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeLanguage('es-ES'))),
                    type: _ctx.selectedLanguage === 'es-ES' ? 'primary' : 'black-gray-200',
                    label: "ES",
                    size: "sm"
                  }, null, 8, ["type"]),
                  _createVNode(_component_plan_button, {
                    onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeLanguage('en'))),
                    type: _ctx.selectedLanguage === 'en' ? 'primary' : 'black-gray-200',
                    label: "EN",
                    size: "sm"
                  }, null, 8, ["type"])
                ])
              ]),
              _: 1
            }, 8, ["label"])
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_plan_avatar, {
            "user-name": _ctx.actualUser.name,
            onlyAvatar: ""
          }, null, 8, ["user-name"])
        ]),
        _: 1
      })
    ])
  ], 2))
}