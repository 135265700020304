import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col plan-gap-sm" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col plan-gap-sm"
}
const _hoisted_3 = { class: "flex justify-start items-end plan-gap-sm" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "plan-form-label" }
const _hoisted_6 = { class: "flex justify-center items-end" }
const _hoisted_7 = ["onUpdate:modelValue"]
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = { class: "plan-form-label" }
const _hoisted_10 = { class: "flex justify-center items-end" }
const _hoisted_11 = ["onUpdate:modelValue", "disabled"]
const _hoisted_12 = { class: "plan-div-form-hide-left plan-t-prim-100 plan-div-form-show-left-line" }
const _hoisted_13 = { class: "flex flex-col" }
const _hoisted_14 = { class: "plan-form-label" }
const _hoisted_15 = { class: "flex justify-center items-end" }
const _hoisted_16 = ["onUpdate:modelValue", "disabled"]
const _hoisted_17 = { class: "plan-div-form-hide-left plan-t-prim-100 plan-div-form-show-left-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_load_form_select_delivery = _resolveComponent("plan-load-form-select-delivery")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.getCommoditiesToAdd.length > 0)
      ? (_openBlock(), _createBlock(_component_plan_select, {
          key: 0,
          onChange: _ctx.addCommodity,
          value: "",
          label: _ctx.$t('add-commodity'),
          items: _ctx.getCommoditiesToAdd,
          placeholder: _ctx.$t('select'),
          "equal-size": ""
        }, null, 8, ["onChange", "label", "items", "placeholder"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commodities, (commodity, commodityIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: commodityIndex,
        class: "flex flex-col plan-gap-sm"
      }, [
        (commodity.isActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('name')), 1),
                  _createElementVNode("div", _hoisted_6, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": ($event: any) => ((commodity.commodityDescription) = $event),
                      type: "text",
                      class: "plan-form plan-b-gray-50 plan-t-prim-100",
                      disabled: ""
                    }, null, 8, _hoisted_7), [
                      [_vModelText, commodity.commodityDescription]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('weight')), 1),
                  _createElementVNode("div", _hoisted_10, [
                    _withDirectives(_createElementVNode("input", {
                      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
                      "onUpdate:modelValue": ($event: any) => ((commodity.weight) = $event),
                      disabled: commodity.stopType === 'DELIVERY_FINAL' && _ctx.deliveries === 0,
                      type: "number",
                      class: "plan-form plan-form-hide-right",
                      style: {"max-width":"75px"}
                    }, null, 40, _hoisted_11), [
                      [_vModelText, commodity.weight]
                    ]),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.weightType), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('units')), 1),
                  _createElementVNode("div", _hoisted_15, [
                    _withDirectives(_createElementVNode("input", {
                      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
                      "onUpdate:modelValue": ($event: any) => ((commodity.units) = $event),
                      disabled: commodity.stopType === 'DELIVERY_FINAL' && _ctx.deliveries === 0,
                      type: "number",
                      class: "plan-form plan-form-hide-right",
                      style: {"max-width":"75px"}
                    }, null, 40, _hoisted_16), [
                      [_vModelText, commodity.units]
                    ]),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.getPackageType(commodity.type)), 1)
                  ])
                ]),
                _createVNode(_component_plan_button_icon, {
                  onOnClick: ($event: any) => (_ctx.removeCommodity(commodityIndex)),
                  disabled: commodity.stopType === 'DELIVERY_FINAL' && _ctx.deliveries === 0,
                  icon: "trash",
                  type: "transparent-gray-800"
                }, null, 8, ["onOnClick", "disabled"])
              ]),
              (_ctx.possibleDeliveries.length > 1 && (commodity.stopType === 'PICKUP' || commodity.stopType === 'PICKUP_INITIAL'))
                ? (_openBlock(), _createBlock(_component_plan_load_form_select_delivery, {
                    key: 0,
                    onChange: _ctx.updateDeliveries,
                    index: commodityIndex,
                    "possible-deliveries": _ctx.possibleDeliveries
                  }, null, 8, ["onChange", "index", "possible-deliveries"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}