import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-lg" }
const _hoisted_2 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_3 = { class: "flex justify-between items-center" }
const _hoisted_4 = { class: "font-semibold plan-t-h5" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col plan-gap-sm"
}
const _hoisted_6 = {
  key: 0,
  class: "flex justify-start items-center plan-gap-sm"
}
const _hoisted_7 = ["value"]
const _hoisted_8 = {
  key: 1,
  class: "flex flex-col plan-gap-sm plan-p-md rounded border plan-b-prim-50 bg-white"
}
const _hoisted_9 = { class: "flex flex-row items-center justify-start plan-gap-sm" }
const _hoisted_10 = {
  key: 1,
  class: "flex w-full"
}
const _hoisted_11 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_12 = { class: "flex justify-between items-start" }
const _hoisted_13 = { class: "font-semibold plan-t-h5" }
const _hoisted_14 = {
  key: 0,
  class: "flex flex-col plan-gap-sm"
}
const _hoisted_15 = {
  key: 0,
  class: "flex justify-start items-center plan-gap-sm"
}
const _hoisted_16 = ["value"]
const _hoisted_17 = {
  key: 1,
  class: "flex flex-col plan-gap-sm plan-p-md rounded border plan-b-prim-50 bg-white"
}
const _hoisted_18 = { class: "flex flex-row items-center justify-start plan-gap-sm" }
const _hoisted_19 = {
  key: 1,
  class: "flex w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_add_driver_modal = _resolveComponent("plan-carrier-add-driver-modal")!
  const _component_plan_default_delete_modal = _resolveComponent("plan-default-delete-modal")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_PlanCarrierUpdateDriver = _resolveComponent("PlanCarrierUpdateDriver")!
  const _component_plan_carrier_broker_add_driver_modal = _resolveComponent("plan-carrier-broker-add-driver-modal")!
  const _component_plan_carrier_broker_update_driver_modal = _resolveComponent("plan-carrier-broker-update-driver-modal")!
  const _component_plan_carrier_add_vehicle_modal = _resolveComponent("plan-carrier-add-vehicle-modal")!
  const _component_PlanCarrierUpdateTrailer = _resolveComponent("PlanCarrierUpdateTrailer")!
  const _component_plan_carrier_broker_add_vehicle_modal = _resolveComponent("plan-carrier-broker-add-vehicle-modal")!
  const _component_plan_carrier_broker_update_vehicle_modal = _resolveComponent("plan-carrier-broker-update-vehicle-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('driver')), 1),
        (_ctx.myDataForm.type === 'CARRIER')
          ? (_openBlock(), _createBlock(_component_plan_carrier_add_driver_modal, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.deleteDriverId !== null)
          ? (_openBlock(), _createBlock(_component_plan_default_delete_modal, {
              key: 1,
              onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteDriverId = null)),
              onDelete: _ctx.deleteDriver,
              title: _ctx.$t('are-you-sure'),
              message: _ctx.$t('are-you-sure-message'),
              loading: _ctx.loadingDriverDelete,
              "hide-icon": ""
            }, null, 8, ["onDelete", "title", "message", "loading"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.myDataForm.type === 'CARRIER')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drivers, (driver) => {
              return (_openBlock(), _createElementBlock("div", {
                key: driver.id,
                class: "flex flex-col plan-gap-md"
              }, [
                (_ctx.selectedDriver === null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _withDirectives(_createElementVNode("input", {
                        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeDriver && _ctx.changeDriver(...args))),
                        type: "radio",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedDriver) = $event)),
                        value: driver.id
                      }, null, 40, _hoisted_7), [
                        [_vModelRadio, _ctx.selectedDriver]
                      ]),
                      _createElementVNode("span", null, _toDisplayString(driver.name) + " " + _toDisplayString(driver.surname), 1)
                    ]))
                  : (_ctx.selectedDriver !== null && _ctx.selectedDriver === driver.id)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("p", null, [
                            _createElementVNode("span", null, _toDisplayString(driver.name) + " " + _toDisplayString(driver.surname), 1)
                          ]),
                          _createVNode(_component_plan_text_button, {
                            onOnClick: _ctx.handleChangeDriverSelected,
                            label: _ctx.$t('change'),
                            icon: "close",
                            "icon-size": "16"
                          }, null, 8, ["onOnClick", "label"])
                        ]),
                        _createVNode(_component_PlanCarrierUpdateDriver, { driver: driver }, null, 8, ["driver"])
                      ]))
                    : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (!_ctx.loadData.shipment.driver)
              ? (_openBlock(), _createBlock(_component_plan_carrier_broker_add_driver_modal, { key: 0 }))
              : (_openBlock(), _createBlock(_component_plan_carrier_broker_update_driver_modal, {
                  key: 1,
                  driver: _ctx.loadData.shipment.driver
                }, null, 8, ["driver"]))
          ]))
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('vehicle')), 1),
        (_ctx.myDataForm.type === 'CARRIER')
          ? (_openBlock(), _createBlock(_component_plan_carrier_add_vehicle_modal, {
              key: 0,
              onSelectVehicle: _ctx.selectVehicle
            }, null, 8, ["onSelectVehicle"]))
          : _createCommentVNode("", true),
        (_ctx.deleteId !== null)
          ? (_openBlock(), _createBlock(_component_plan_default_delete_modal, {
              key: 1,
              onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteId = null)),
              onDelete: _ctx.deleteVehicle,
              title: _ctx.$t('are-you-sure'),
              message: _ctx.$t('are-you-sure-message'),
              loading: _ctx.loadingDelete,
              "hide-icon": ""
            }, null, 8, ["onDelete", "title", "message", "loading"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.myDataForm.type === 'CARRIER')
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vehicles, (vehicle) => {
              return (_openBlock(), _createElementBlock("div", {
                key: vehicle.id,
                class: "flex flex-col plan-gap-md"
              }, [
                (_ctx.selectedVehicle === null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _withDirectives(_createElementVNode("input", {
                        onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.changeVehicle && _ctx.changeVehicle(...args))),
                        type: "radio",
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedVehicle) = $event)),
                        value: vehicle.id
                      }, null, 40, _hoisted_16), [
                        [_vModelRadio, _ctx.selectedVehicle]
                      ]),
                      _createElementVNode("span", null, _toDisplayString(vehicle.trailerType.description) + ", " + _toDisplayString(vehicle.licensePlate) + " " + _toDisplayString(vehicle.trailerPlate), 1)
                    ]))
                  : (_ctx.selectedVehicle !== null && _ctx.selectedVehicle === vehicle.id)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("p", null, [
                            _createElementVNode("span", null, _toDisplayString(vehicle?.trailerType?.description +
                      ' ' +
                      vehicle?.licensePlate +
                      ' ' +
                      vehicle?.trailerPlate || ''), 1)
                          ]),
                          _createVNode(_component_plan_text_button, {
                            onOnClick: _ctx.handleChangeVehicleSelected,
                            label: _ctx.$t('change'),
                            icon: "close",
                            "icon-size": "16"
                          }, null, 8, ["onOnClick", "label"])
                        ]),
                        _createVNode(_component_PlanCarrierUpdateTrailer, { vehicle: vehicle }, null, 8, ["vehicle"])
                      ]))
                    : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_19, [
            (!_ctx.loadData.shipment.vehicle)
              ? (_openBlock(), _createBlock(_component_plan_carrier_broker_add_vehicle_modal, {
                  key: 0,
                  class: "w-full"
                }))
              : (_openBlock(), _createBlock(_component_plan_carrier_broker_update_vehicle_modal, {
                  key: 1,
                  class: "w-full"
                }))
          ]))
    ])
  ]))
}