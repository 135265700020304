
import { computed, defineComponent, onBeforeMount, reactive, toRefs, watch } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'ShareDocuments',

  mixins: [BaseComponent],
  components: {
    PlanButton,
  },

  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();

    const currentLoad = computed(() => {
      return store.getters['LoadsStore/currentLoad'];
    });

    const handleOpenShareModal = () => {
      store.commit('LoadsStore/showModalShareDocuments', true);
    };

    const docsList = computed(() => {
      let response = [] as any;
      const docs = store.getters['LoadsStore/getDocsList'];
      for (const key in docs) {
        if (Object.hasOwnProperty.call(docs, key)) {
          response.push({
            documents: docs[key],
            key: key,
            title: translate.t(key),
          });
        }
      }
      return response;
    });

    onBeforeMount(() => {
      store.dispatch('LoadsStore/getDocsList', currentLoad.value.id);
    });

    const showShareButton = computed(() => {
      const { load_docs, stop_docs, other_docs, invoice_docs } =
        store.getters['LoadsStore/getDocsList'] || {};

      return (
        load_docs?.length > 0 ||
        stop_docs?.length > 0 ||
        other_docs?.length > 0 ||
        invoice_docs?.length > 0
      );
    });

    const data = reactive({
      iconColor: '#0097FF',
      showShareModal: false,
      isSendingEmails: false,
      recipients: '',
      subject: '',
      message: '',
      isValid: false,
    });

    return {
      ...toRefs(data),
      docsList,
      handleOpenShareModal,
      showShareButton,
      translate,
    };
  },
});
