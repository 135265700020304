
import { computed, defineComponent, reactive, toRefs, onBeforeMount, onMounted } from 'vue';
import Modal from '@/components/modals/Modal.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { useStore } from 'vuex';
import PlanLoading from '@/components/spin/PlanLoading.vue';

export default defineComponent({
  name: 'CompanyModal',
  components: {
    Modal,
    PlanButtonIcon,
    ErrorMessage,
    Field,
    Form,
    PlanButton,
    PlanLoading,
  },
  emits: ['close'],
  props: {
    isCrossborder: Boolean,
    index: Number as any,
  },
  setup(props, { emit }) {
    const store = useStore();

    interface companyModal {
      name: string;
      email: string;
      taxId: string;
      country: string;
      zip: string;
      state: string;
      city: string;
      address: string;
      getUserInfoLoading: boolean;
    }

    const data = reactive<companyModal>({
      name: '',
      email: '',
      taxId: '',
      country: '',
      zip: '',
      state: '',
      city: '',
      address: '',
      getUserInfoLoading: false,
    });

    onBeforeMount(() => {
      store.dispatch('UserStore/currentUser');
    });

    onMounted(() => {
      if (currentUser.value.id) {
        getUserInfo();
      }
    });

    const user = computed(() => {
      return store.getters['UserStore/getUser'];
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const company = computed(() => {
      return store.getters['CompanyStore/getCompany'];
    });

    const billingCompanyData = computed(() => {
      const { name, email, taxId, country, state, city, address, zip } = props.isCrossborder
        ? store.state.UserStore.crossBorderBilling[props.index]
        : store.state.UserStore.billingCompany;

      return { name, email, taxId, country, state, city, address, zip };
    });

    const states = computed(() => {
      let result = [];
      result = store.getters['LoadsStore/getStates'].filter(
        (state) => state.country && state.country.id && state.country.id === data.country,
      );
      return result;
    });

    const countriesList = computed(() => {
      return store.getters['CountryStore/getCountries'];
    });

    const isEuropeCompany = computed(() => {
      const country = countriesList.value.find(
        (country) => company.value.country?.id === country?.id,
      );
      return country?.region?.name === 'EUROPE';
    });

    function getUserInfo() {
      data.getUserInfoLoading = true;
      store
        .dispatch('UserStore/getUser', { id: currentUser.value.id })
        .then((response) => {
          data.name =
            billingCompanyData.value.name !== response.company?.name &&
            billingCompanyData.value.name.length > 0
              ? billingCompanyData.value.name
              : response.company?.name;
          data.email =
            billingCompanyData.value.email !== response.company?.email &&
            billingCompanyData.value.email.length > 0
              ? billingCompanyData.value.email
              : response.company?.contactEmail;
          data.taxId =
            billingCompanyData.value.taxId !== response.company?.taxId &&
            billingCompanyData.value.taxId.length > 0
              ? billingCompanyData.value.taxId
              : response.company?.identificationNumber;
          data.country =
            billingCompanyData.value.country !== response.company?.country &&
            Number(billingCompanyData.value.country)
              ? billingCompanyData.value.country
              : response.company?.country.id;
          data.state =
            billingCompanyData.value.state !== response.company?.state &&
            Number(billingCompanyData.value.state)
              ? billingCompanyData.value.state
              : response.company?.location?.state.id;
          data.city =
            billingCompanyData.value.city !== response.company?.city &&
            billingCompanyData.value.city.length > 0
              ? billingCompanyData.value.city
              : response.company?.location?.city;
          data.address =
            billingCompanyData.value.address !== response.company?.address &&
            billingCompanyData.value.address.length > 0
              ? billingCompanyData.value.address
              : response.company?.location?.address;
          data.zip =
            billingCompanyData.value.zip !== response.company?.zip &&
            billingCompanyData.value.zip.length > 0
              ? billingCompanyData.value.zip
              : response.company?.location?.zip;
          data.getUserInfoLoading = false;
        })
        .finally(() => {
          data.getUserInfoLoading = false;
        });
    }

    function cleanForm() {
      closeCompanyModal();
    }

    function setBillingCompany() {
      if (props.isCrossborder) {
        store.state.UserStore.crossBorderBilling[props.index] = {
          name: data.name,
          email: data.email,
          taxId: data.taxId,
          country: data.country,
          state: data.state,
          city: data.city,
          address: data.address,
          zip: data.zip,
        };
      } else {
        store.state.UserStore.billingCompany = {
          name: data.name,
          email: data.email,
          taxId: data.taxId,
          country: data.country,
          state: data.state,
          city: data.city,
          address: data.address,
          zip: data.zip,
        };
      }

      closeCompanyModal();
    }

    function closeCompanyModal() {
      emit('close');
    }

    return {
      ...toRefs(data),
      closeCompanyModal,
      cleanForm,
      user,
      company,
      countriesList,
      isEuropeCompany,
      currentUser,
      // changeCountry,
      states,
      // changeState,
      setBillingCompany,
      billingCompanyData,
    };
  },
});
