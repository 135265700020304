
import { defineComponent, computed, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import PlanInputLoadLocation from '@/components/forms/PlanInputLoadLocation.vue';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import WarehouseInStopData from '@/modules/planning/newLoad/_components/form/WarehouseInStopData.vue';
import stopData from '@/modules/planning/newLoad/_components/form/StopData.vue';
import PlanInputRadio from '@/components/forms/PlanInputRadio.vue';

interface planLoadFormStop {
  planLoadKey: number,
  stopData: any,
  stopDate: string | null,
  warehouseData: any,
  selectedOption: string,
  showValidations: {
    location: boolean,
    date: boolean
  }
}
export default defineComponent({
  name: 'PlanLoadFormStop',
  computed: {
    stopData() {
      return stopData
    }
  },
  components: { PlanInputRadio, WarehouseInStopData, PlanInputCalendar, PlanInputLoadLocation },
  props: {
    type: { type: String, required: true, default: 'PICKUP_INITIAL' },
    index: { type: Number, required: false, default: -1 },
    minDate: { type: String, required: false, default: '' },
    maxDate: { type: String, required: false, default: '' },
    onlyCountry: { type: String, required: false, default: '' },
    excludeCountry: { type: String, required: false, default: '' },
    templateDataLocation: { type: String, required: false, default: '' },
    templateWarehouseId: { type: Number, required: false, default: -1 },
    isAdditionalStop: { type: Boolean, required: false, default: false }
  },
  emits: ['updateStop'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive<planLoadFormStop>({
      planLoadKey: 0,
      stopData: null,
      stopDate: store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'mm-dd-yyyy':'dd-mm-yyyy',
      warehouseData: {
        id: -1,
        airport: false,
        port: false,
        lumperFee: false,
        lumperFeePrice: null,
        observations: '',
        selectedAppointment: null,
        startTime: null,
        endTime: null,
        poNumber: '',
      },
      selectedOption: props.type.toLowerCase(),
      showValidations: {
        location: false,
        date: false
      }
    })

    const actualWidth = computed(() => {
      return store.getters['UserStore/getActualWidth']
    })

    const minWidth = computed(() => {
      if (isCollapsedSidebar.value) {
        return props.isAdditionalStop ? 750 : 1150
      } else {
        return 1370
      }
    })

    const changeOption = (newValue: string) => {
      data.selectedOption = newValue
      updateStop()
    }

    const changeStopData = (stopData: any) => {
      data.stopData = stopData
      updateStop()
    }

    const changeStopDate = (stopDate: any) => {
      data.stopDate = stopDate
      updateStop()
    }

    const countries = computed(() => {
      return store.getters['CountryStore/getCountries']
    })

    const isCollapsedSidebar = computed(() => {
      return store.getters['UserStore/getCollapsedSidebar']
    })

    const isValidatedForm = computed(() => {
      if (props.type === 'PICKUP_INITIAL') {
        return {
          location: store.getters['NewFtlLoad/isValidatedForm'].pickup,
          date: store.getters['NewFtlLoad/isValidatedForm'].pickupDate
        }
      } else if (props.type === 'DELIVERY_FINAL') {
        return {
          location: store.getters['NewFtlLoad/isValidatedForm'].delivery,
          date: store.getters['NewFtlLoad/isValidatedForm'].deliveryDate
        }
      } else {
        return {
          location: true,
          date: true
        }
      }
    })

    const selectedCountryData = computed(() => {
      let selectedCountry = null
      if (data.stopData === null) return null
      store.getters['CountryStore/getCountries'].forEach((country: any) => {
        if (country.iso === data.stopData.country.toUpperCase()) selectedCountry = country
      })
      return selectedCountry
    })

    const stopOptions = computed(() => {
      return [
        { label: translate.t('pickup'), value: 'pickup' },
        { label: translate.t('delivery'), value: 'delivery' }
      ];
    })

    const templateData = computed(() => {
      let locationToShow = props.templateDataLocation
      let dateToShow = ''
      let warehouseId = props.templateWarehouseId
      const template = store.getters['NewFtlLoad/getTemplateData']

      if (props.type === 'PICKUP_INITIAL' && template !== null) {
        locationToShow = template.load.stops[0].location.zip
        dateToShow = template.load.stops[0].executionDate
        if (template.load.stops[0].warehouseTemplate?.id) warehouseId = template.load.stops[0].warehouseTemplate?.id
      }

      if (props.type === 'DELIVERY_FINAL' && template !== null) {
        locationToShow = template.load.stops[template.load.stops.length -1].location.zip
        dateToShow = template.load.stops[template.load.stops.length -1].executionDate
        if (template.load.stops[template.load.stops.length -1].warehouseTemplate?.id) warehouseId = template.load.stops[template.load.stops.length -1].warehouseTemplate?.id
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      data.planLoadKey = data.planLoadKey + 1

      return {
        location: locationToShow,
        date: dateToShow,
        warehouse: warehouseId
      }
    })

    const typeToShow = computed(() => {
      if (props.type === 'PICKUP_INITIAL') return translate.t('pick-up')
      if (props.type === 'DELIVERY_FINAL') return translate.t('delivery')
      return translate.t('stop')
    })

    const updateStop = () => {
      let typeToSend = props.type
      if (props.type === 'PICKUP' || props.type === 'DELIVERY') typeToSend = data.selectedOption.toUpperCase()

      emit('updateStop', {
        index: props.index,
        stopType: typeToSend,
        stopData: data.stopData,
        date: data.stopDate,
        warehouse: data.warehouseData
      })
    }
    const updateObservations = (value: any) => {
      data.warehouseData.observations = value.target.value
      updateStop()
    }

    const updatePortOrAirport = (value: string) => {
      if (value === 'airport') {
        data.warehouseData.airport = true
        data.warehouseData.port = false
      }
      if (value === 'port') {
        data.warehouseData.airport = false
        data.warehouseData.port = true
      }
      updateStop()
    }

    const updateSelectedAppointment = (value: string) => {
      data.warehouseData.selectedAppointment = value
      updateStop()
    }

    const updateSelectedTime = (value: any) => {
      if (value.mode === 'start') data.warehouseData.startTime = value.type
      if (value.mode === 'end') data.warehouseData.endTime = value.type
      updateStop()
    }

    const updateWarehouse = (value: any) => {
      data.warehouseData.id = value.warehouse.id
      data.warehouseData.lumperFee = value.lumperFee
      data.warehouseData.lumperFeePrice = value.lumperFeePrice
      data.warehouseData.poNumber = value.poNumber
      updateStop()
    }

    return {
      ...toRefs(data),
      actualWidth,
      changeOption,
      changeStopData,
      changeStopDate,
      countries,
      isValidatedForm,
      minWidth,
      selectedCountryData,
      stopOptions,
      updateObservations,
      updatePortOrAirport,
      updateSelectedAppointment,
      updateSelectedTime,
      updateWarehouse,
      templateData,
      typeToShow
    }
  }
})
