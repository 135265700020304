
import { defineComponent, reactive, toRefs, computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ActionContext, useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import AddCustomer from '@/modules/planning/newLoad/_components/AddCustomer.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanLegData from '../_components/PlanLegData.vue';
import PlanTrailerAndCommodity from '../_components/PlanTrailerAndCommodity.vue';
import PlanUploadFilesCrossBorder from '../_components/PlanUploadFilesCrossBorder.vue';

interface newLoad {
  load: any;
  poNumber: any;
  loading: boolean;
}

export default defineComponent({
  name: 'CrossBorderData',
  components: {
    AddCustomer,
    PlanInputCheckbox,
    PlanInputText,
    PlanLegData,
    PlanTrailerAndCommodity,
    PlanUploadFilesCrossBorder,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();
    const legRefs = ref([]) as any;
    const data = reactive<newLoad>({
      load: { customers: [] } as any,
      poNumber: '',
      loading: false,
    });

    const currentRoute = computed(() => {
      return route.name;
    });

    const isModify = computed(() => {
      return route.name === 'planificationDetail';
    });

    const currentLoad = computed(() => {
      return store.getters['LoadsStore/currentLoad'];
    });

    const getLoadToSend = computed(() => {
      return store.getters['CrossBorderStore/getLoadToSend'];
    });

    const returnCustomers = computed(() => {
      return data.load.customers;
    });

    const vehicles = computed(() => {
      return store.getters['LoadsStore/getTrailerTypes'];
    });

    const legs = computed(() => {
      return [
        {
          title: t('LEG 1 - MX domestic'),
          description: t('enter-information-from-to-delivery', { country: 'MX' }),
          pickUpCountry: { id: 2, name: 'Mexico', iso: 'MX' },
          deliveryCountry: { id: 2, name: 'Mexico', iso: 'MX' },
        },
        {
          title: t('LEG 2 - Transfer'),
          description: t('enter-information-border-crossing', { delivery: 'USA', pickup: 'MX' }),
          pickUpIsText: t('delivery-point', { leg: 'LEG 1' }),
          deliveryCountry: { id: 1, iso: 'US', name: 'United States' },
        },
        {
          title: t('LEG 3 - USA domestic'),
          description: t('enter-information-from-to-delivery-destination-facility', {
            country: 'USA',
          }),
          pickUpIsText: t('delivery-point', { leg: 'LEG 2' }),
          deliveryCountry: { id: 1, iso: 'US', name: 'United States' },
        },
      ];
    });

    onMounted(() => {
      // TODO: check if is Modify
      if (currentRoute.value !== 'planificationDetail') {
        store.commit('CrossBorderStore/resetLoadToSend');
      }
    });

    const updateDate = (index, value) => {
      if (legRefs.value.length > 0 && value !== undefined) {
        legRefs.value[index].updateMinDate(value);
      }
    };

    const removePopertyFromLoad = (key: string) => {
      store.commit('CrossBorderStore/removePopertyFromLoad', key);
    };

    const updatePopertyFromLoad = (value: any, key: string) => {
      store.commit('CrossBorderStore/updatePopertyFromLoad', {
        key: key,
        value: value,
      });
    };

    const updateCommodityMasters = (data: any) => {
      updatePopertyFromLoad([...data], 'commodityMasters');
    };

    const updateGeneralObservations = (event) => {
      const innerText = event.target.innerText;
      updatePopertyFromLoad(innerText, 'observations');
    };

    const updateVehicle = (newVehicle: any) => {
      vehicles.value.forEach((vehicle: any) => {
        if (vehicle.id === Number(newVehicle.vehicle)) {
          updatePopertyFromLoad(vehicle, 'trailerType');
        }
      });
      if (newVehicle.isRefrigerated.value) {
        updatePopertyFromLoad(newVehicle.isRefrigerated.max, 'maxTemp');
        updatePopertyFromLoad(newVehicle.isRefrigerated.min, 'minTemp');
      } else {
        removePopertyFromLoad('maxTemp');
        removePopertyFromLoad('minTemp');
      }
    };

    watch(
      data,
      (newValue, oldValue) => {
        if (getLoadToSend?.value?.customers) {
          updatePopertyFromLoad(data.load?.customers, 'customers');
        }
      },
      {
        deep: true,
        immediate: true,
      },
    );

    return {
      ...toRefs(data),
      getLoadToSend,
      legs,
      legRefs,
      returnCustomers,
      isModify,
      updateCommodityMasters,
      updateGeneralObservations,
      updateVehicle,
      updateDate,
    };
  },
});
