import CustomAgentCCP from '@/modules/customAgent/_views/CustomAgentCCP.vue';

const CustomAgentRouter = [
  {
    path: '/customAgent/:id/:hash/:code',
    name: 'CustomAgentCCP',
    component: CustomAgentCCP,
    props: true,
  },
];

export default CustomAgentRouter;
