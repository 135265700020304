import globalAxios from 'axios';

const setStopArrivalInterfaceOpenedByDriver = async (id: any) => {
  const response = await globalAxios.put(`stop/${id}/setStopArrivalInterfaceOpenedByDriver`, {});
  return Promise.resolve(response.data);
};

const setStopStartByDriver = async (id: any) => {
  const response = await globalAxios.put(`stop/${id}/setStopStartByDriver`, {});
  return Promise.resolve(response.data);
};


const setStopArrivalByDriver = async (id: any) => {
  const response = await globalAxios.put(`stop/${id}/setStopArrivalByDriver`, {});
  return Promise.resolve(response.data);
};

const setStopFinishByDriver = async (id: any) => {
  const response = await globalAxios.put(`stop/${id}/setStopFinishByDriver`, {});
  return Promise.resolve(response.data);
};

const setStopDepartInterfaceOpened = async (id: any) => {
  const response = await globalAxios.put(`stop/${id}/setStopDepartInterfaceOpened`, {});
  return Promise.resolve(response.data);
};









export default {
  setStopArrivalInterfaceOpenedByDriver,
  setStopArrivalByDriver,
  setStopStartByDriver,
  setStopFinishByDriver,
  setStopDepartInterfaceOpened,
};