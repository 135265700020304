
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanThumbnail from '@/components/forms/PlanThumbnail.vue';

export default defineComponent({
  name: 'CustomAgentDocumentsModal',
  components: { PlanInputFile, PlanDefaultModal, PlanThumbnail },
  emits: ['close', 'getLocation'],
  setup(props, { emit }) {
    const store = useStore();
    const t = useI18n().t;

    const data = reactive({
      name: '',
      surname: '',
      phone: '',
      phonePrefix: '',
      email: '',
      loadingPost: false,
    });

    const currentLoad = computed(() => {
      let load = store.getters['LoadsStore/currentLoad'];
      const leg2 = load?.legs.find((leg) => leg.legOrder === 1);
      return leg2;
    });
    store.dispatch('LoadsStore/getDocsList', currentLoad.value.id);

    const docsList = computed(() => {
      let response = [] as any;
      const docs = store.getters['LoadsStore/getDocsList'];
      for (const key in docs) {
        if (Object.hasOwnProperty.call(docs, key)) {
          response.push({
            documents: docs[key],
            key: key,
            title: t(key),
          });
        }
      }
      return response;
    });

    const CCP = computed(() => {
      return docsList.value
        .find((section) => section.key === 'stop_docs')
        .documents.find((doc) => doc.type === 'BOL');
    });

    const CCPPOD = computed(() => {
      return docsList.value
        .find((section) => section.key === 'stop_docs')
        .documents.find((doc) => doc.type === 'BOL');
    });

    const invoices = computed(() => {
      return docsList.value.find((section) => section.key === 'invoice_docs');
    });

    const loadDocs = computed(() => {
      return docsList.value.find((section) => section.key === 'load_docs');
    });

    const stopDocs = computed(() => {
      return docsList.value.find((section) => section.key === 'stop_docs');
    });

    const packingList = computed(() => {
      let docs: any = [];
      docsList.value.forEach((d) => {
        if (d.key === 'load_docs') {
          const documents = deepClone(d.documents);
          documents.forEach((item, i) => {
            if (item.type === 'PACKAGING_LIST') {
              docs.push({ ...item, name: item.displayName });
            }
          });
        }
      });
      return docs;
    });

    const deepClone = (obj) => {
      return JSON.parse(JSON.stringify(obj));
    };

    const close = () => {
      emit('close', false);
    };

    const changeFiles = async (newFiles, type) => {
      if (typeof newFiles === 'number') {
        return;
      }
      const files = newFiles;
      const filesArray: File[] = Array.from(files);
      const acceptedTypes = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/png',
      ];
      const maxSize = 100 * 1024 * 1024;

      const validFiles = filesArray.filter((file) => {
        if (!acceptedTypes.includes(file.type)) {
          store.dispatch('UserStore/updateToast', {
            text: t('file-not-valid-type', { file: file.name }),
            type: 'error',
            visible: true,
          });
          return false;
        }

        if (file.size > maxSize) {
          store.dispatch('UserStore/updateToast', {
            text: t('file-not-valid-size', { file: file.name }),
            type: 'error',
            visible: true,
          });
          return false;
        }

        return true;
      });
      if (validFiles.length > 0) {
        validFiles.forEach((file) => {
          data.loadingPost = true;
          store
            .dispatch('LoadsStore/postMediaObjectLoad', {
              id: currentLoad.value.id,
              file: file,
              type: type,
            })
            .finally(() => (data.loadingPost = false));
        });
      }
    };

    const removeFile = async (idDeleteItem) => {
      if (idDeleteItem) {
        console.log('removing');
        store
          .dispatch('LoadsStore/deleteLoadMediaObject', idDeleteItem)
          .then(() => store.dispatch('UserStore/showToast', t('document-deleted')));
      }
    };

    return {
      ...toRefs(data),
      CCP,
      CCPPOD,
      changeFiles,
      close,
      invoices,
      loadDocs,
      stopDocs,
      packingList,
      removeFile,
    };
  },
});
