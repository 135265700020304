import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_2 = { class: "flex flex-row space-x-2" }
const _hoisted_3 = {
  class: "new-load-button-popper-items",
  style: {"width":"186px"}
}
const _hoisted_4 = { class: "flex flex-col justify-start items-center plan-gap-md" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "w-full overflow-x-auto" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 0,
  class: "flex justify-end"
}
const _hoisted_14 = {
  key: 1,
  class: "w-[42px] h-[42px]"
}
const _hoisted_15 = { key: 5 }
const _hoisted_16 = { class: "flex flex-row justify-between items-center self-center" }
const _hoisted_17 = { class: "flex flex-row space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddCarrierModal = _resolveComponent("AddCarrierModal")!
  const _component_UpdateContactModal = _resolveComponent("UpdateContactModal")!
  const _component_plan_header_search = _resolveComponent("plan-header-search")!
  const _component_plan_filter_contact_tag = _resolveComponent("plan-filter-contact-tag")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_popper = _resolveComponent("popper")!
  const _component_plan_import_contacts_loading = _resolveComponent("plan-import-contacts-loading")!
  const _component_plan_tabs = _resolveComponent("plan-tabs")!
  const _component_plan_default_loading_modal = _resolveComponent("plan-default-loading-modal")!
  const _component_add_template_modal = _resolveComponent("add-template-modal")!
  const _component_success_blast_email_modal = _resolveComponent("success-blast-email-modal")!
  const _component_plan_default_status_modal = _resolveComponent("plan-default-status-modal")!
  const _component_send_blast_email_modal = _resolveComponent("send-blast-email-modal")!
  const _component_plan_contact_tags = _resolveComponent("plan-contact-tags")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_default_table = _resolveComponent("plan-default-table")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_plan_default_view = _resolveComponent("plan-default-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showModalCarrier)
      ? (_openBlock(), _createBlock(_component_AddCarrierModal, {
          key: 0,
          onHideModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModalCarrier = !_ctx.showModalCarrier))
        }))
      : _createCommentVNode("", true),
    (_ctx.showEditModal)
      ? (_openBlock(), _createBlock(_component_UpdateContactModal, {
          key: 1,
          selectedUser: _ctx.selectedUser,
          onClose: _ctx.closeAndUpdateContactModal,
          onCloseAndUpdate: _ctx.closeAndUpdateContactModal
        }, null, 8, ["selectedUser", "onClose", "onCloseAndUpdate"]))
      : _createCommentVNode("", true),
    _createVNode(_component_plan_default_view, {
      title: `${_ctx.$t('contacts')}`,
      "show-bottom-selection-bar": _ctx.selectedContacts.length > 0
    }, {
      "header-content": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_plan_header_search, {
            "old-query": _ctx.searchCompanyQuery.name,
            placeholder: _ctx.$t('search-company'),
            onChangeQuery: _ctx.handleInputChange
          }, null, 8, ["old-query", "placeholder", "onChangeQuery"]),
          _createVNode(_component_plan_filter_contact_tag, { onChange: _ctx.updateSelectedTag }, null, 8, ["onChange"])
        ])
      ]),
      "header-buttons": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_plan_button, {
            type: "primary-outline",
            label: _ctx.$t('send-email'),
            class: "plan-form",
            onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showBlastEmail = true)),
            style: {"width":"220px"}
          }, null, 8, ["label"]),
          _createVNode(_component_popper, {
            hover: "",
            offsetDistance: "-6",
            class: "new-load-button-popper",
            style: {"width":"220px"}
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_plan_button, {
                  onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModalCarrier = true)),
                  type: "sec-600",
                  label: _ctx.$t('add-a-contact'),
                  class: "plan-form"
                }, null, 8, ["label"]),
                _createVNode(_component_plan_button, {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'importContacts' }))),
                  type: "sec-600",
                  label: _ctx.$t('import-contacts'),
                  multiline: ""
                }, null, 8, ["label"])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_plan_button, {
                type: "primary",
                label: _ctx.$t('add-contact'),
                class: "plan-form"
              }, null, 8, ["label"])
            ]),
            _: 1
          })
        ])
      ]),
      "view-content": _withCtx(() => [
        (_ctx.showImportContactsLoading)
          ? (_openBlock(), _createBlock(_component_plan_import_contacts_loading, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_plan_tabs, {
              onChangeTab: _ctx.changeTab,
              align: 'between',
              startTab: _ctx.currentTab,
              "show-badges": true,
              "tabs-width": 'w-1/3',
              tabs: [
              { label: _ctx.$t('all'), quantity: _ctx.contacts?.totals_by_type?.ALL },
              { label: _ctx.$t('carrier'), quantity: _ctx.contacts?.totals_by_type?.CARRIER },
              { label: _ctx.$t('broker'), quantity: _ctx.contacts?.totals_by_type?.BROKER },
            ],
              size: "plan-text-md"
            }, null, 8, ["onChangeTab", "startTab", "tabs"])
          ]),
          false
            ? (_openBlock(), _createBlock(_component_plan_default_loading_modal, {
                key: 0,
                title: "Adding Warehouse",
                message: "The new warehouse is being added. This process can take a few minutes."
              }))
            : _createCommentVNode("", true),
          (_ctx.showAddTemplate)
            ? (_openBlock(), _createBlock(_component_add_template_modal, {
                key: 1,
                onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showAddTemplate = false)),
                onCloseAndShow: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showAddTemplate = false))
              }))
            : _createCommentVNode("", true),
          (_ctx.modalSuccess)
            ? (_openBlock(), _createBlock(_component_success_blast_email_modal, {
                key: 2,
                onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.modalSuccess = false)),
                onCloseAndShowAdd: _ctx.showAddTemplateOnClick,
                title: _ctx.$t('send-emails-done', { n: _ctx.selectedContacts })
              }, null, 8, ["onCloseAndShowAdd", "title"]))
            : _createCommentVNode("", true),
          false
            ? (_openBlock(), _createBlock(_component_plan_default_status_modal, {
                key: 3,
                title: _ctx.$t('send-emails-done', { n: _ctx.selectedContacts }),
                message: "",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.modalSuccess = false)),
                onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.modalSuccess = false))
              }, null, 8, ["title"]))
            : _createCommentVNode("", true),
          (_ctx.showBlastEmail)
            ? (_openBlock(), _createBlock(_component_send_blast_email_modal, {
                key: 4,
                onRefresh: _ctx.refresh,
                onShowModalSuccess: _cache[9] || (_cache[9] = ($event: any) => (_ctx.modalSuccess = !_ctx.modalSuccess)),
                onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showBlastEmail = false))
              }, null, 8, ["onRefresh"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_plan_default_table, {
              order: _ctx.order.value,
              "order-direction": _ctx.order.direction,
              items: _ctx.contacts.values,
              selectedItems: _ctx.selectedContacts,
              onSelectedItems: _ctx.changeSelectedContacts,
              onChangeOrder: _ctx.fetchOrder,
              selectable: "",
              headers: _ctx.headers,
              style: {"background":"white"}
            }, {
              default: _withCtx((props) => [
                _createElementVNode("td", {
                  class: "cursor-pointer truncate max-w-xs",
                  onClick: ($event: any) => (_ctx.handleShowEditModal(props.item))
                }, _toDisplayString(props.item?.name), 9, _hoisted_7),
                _createElementVNode("td", {
                  class: "cursor-pointer truncate max-w-xs",
                  onClick: ($event: any) => (_ctx.handleShowEditModal(props.item))
                }, [
                  _createTextVNode(_toDisplayString(props.item?.owner_name) + " ", 1),
                  (props.item.source !== 'CARGOBOT' && props.item.source !== 'CARGOBOT_EU')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(props.item?.owner_surname), 1))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_8),
                _createElementVNode("td", {
                  class: "cursor-pointer",
                  onClick: ($event: any) => (_ctx.handleShowEditModal(props.item))
                }, _toDisplayString(props.item?.stats_amountvalorations), 9, _hoisted_10),
                _createElementVNode("td", {
                  class: "cursor-pointer",
                  onClick: ($event: any) => (_ctx.handleShowEditModal(props.item))
                }, _toDisplayString(props.item?.type == 'CARRIER' || props.item?.type == 'BROKER'
                    ? _ctx.$t(`${props.item?.type.toLowerCase()}`).toUpperCase()
                    : props.item?.type), 9, _hoisted_11),
                _createElementVNode("td", {
                  class: "cursor-pointer",
                  onClick: ($event: any) => (_ctx.handleShowEditModal(props.item))
                }, _toDisplayString(props.item?.stats_shipmentamount), 9, _hoisted_12),
                _createElementVNode("td", null, [
                  _createVNode(_component_plan_contact_tags, {
                    onUpdate: _ctx.getContactList,
                    actualTags: props.item.tags,
                    companyId: props.item.id,
                    "loading-contacts": _ctx.loadingContacts
                  }, null, 8, ["onUpdate", "actualTags", "companyId", "loading-contacts"])
                ]),
                _createElementVNode("td", null, [
                  (props.item.source !== 'CARGOBOT' && props.item.source !== 'CARGOBOT_EU')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(_component_plan_button_icon, {
                          onOnClick: ($event: any) => (_ctx.handleShowEditModal(props.item)),
                          icon: "plan-edit",
                          size: "24",
                          color: "#EF4444",
                          type: "transparent-red"
                        }, null, 8, ["onOnClick"]),
                        _createVNode(_component_plan_button_icon, {
                          onOnClick: ($event: any) => (_ctx.deleteContact(props.item?.id)),
                          icon: "plan-trash",
                          size: "24",
                          color: "#EF4444",
                          type: "transparent-red"
                        }, null, 8, ["onOnClick"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_14))
                ])
              ]),
              _: 1
            }, 8, ["order", "order-direction", "items", "selectedItems", "onSelectedItems", "onChangeOrder", "headers"])
          ]),
          (_ctx.contacts.values.length > 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.$t('showing')) + " " + _toDisplayString(_ctx.contacts.values.length) + " " + _toDisplayString(_ctx.$t('of')) + " " + _toDisplayString(_ctx.selectedTabTotal), 1))
            : _createCommentVNode("", true),
          (
            !_ctx.loadingContacts &&
            _ctx.contacts?.values?.length > 0 &&
            _ctx.contacts?.values?.length < _ctx.selectedTabTotal
          )
            ? (_openBlock(), _createBlock(_component_plan_button, {
                key: 6,
                onOnClick: _ctx.loadMoreContacts,
                loading: _ctx.loadingContacts,
                type: "primary-outline",
                "full-rounded": "",
                label: _ctx.$t('show-more-contacts'),
                style: {"width":"328px"}
              }, null, 8, ["onOnClick", "loading", "label"]))
            : _createCommentVNode("", true),
          (_ctx.loadingContacts)
            ? (_openBlock(), _createBlock(_component_plan_loading, {
                key: 7,
                class: "plan-t-prim-100"
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      "bottom-selection-bar": _withCtx(() => [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("span", null, _toDisplayString(_ctx.selectedContacts.length) + " " + _toDisplayString(_ctx.$t('selected')), 1),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_plan_button, {
              icon: "plan-arroba",
              type: "primary-outline",
              "full-rounded": "",
              iconColor: _ctx.iconColorSend,
              style: {"width":"280px"},
              iconType: 'svg',
              label: _ctx.$t('send-email', { n: _ctx.selectedContacts.length }),
              "show-icon": true,
              onOnClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showBlastEmail = true)),
              onMouseenter: _cache[12] || (_cache[12] = ($event: any) => (_ctx.iconColorSend = 'white')),
              onMouseleave: _cache[13] || (_cache[13] = ($event: any) => (_ctx.iconColorSend = '#2a2e43'))
            }, null, 8, ["iconColor", "label"]),
            _createVNode(_component_plan_button, {
              icon: "plan-trash",
              type: "error-outline-500",
              "full-rounded": "",
              style: {"width":"280px"},
              loading: _ctx.isDeletingContacs,
              iconColor: _ctx.iconColorDelete,
              iconType: 'svg',
              label: _ctx.$t('remove'),
              "show-icon": true,
              onOnClick: _ctx.deleteSelected,
              onMouseenter: _cache[14] || (_cache[14] = ($event: any) => (_ctx.iconColorDelete = 'white')),
              onMouseleave: _cache[15] || (_cache[15] = ($event: any) => (_ctx.iconColorDelete = 'red'))
            }, null, 8, ["loading", "iconColor", "label", "onOnClick"])
          ]),
          _createVNode(_component_plan_button_icon, {
            icon: "plan-close",
            size: "12",
            color: "#EF4444",
            type: "transparent-red"
          })
        ])
      ]),
      _: 1
    }, 8, ["title", "show-bottom-selection-bar"])
  ], 64))
}