import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "rounded w-full border plan-border-prim-50 plan-p-md flex flex-col plan-gap-md" }
const _hoisted_2 = { class: "w-full flex justify-between items-center plan-gap-sm" }
const _hoisted_3 = { class: "font-bold plan-t-xs plan-t-prim-200" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_plan_load_form_stop = _resolveComponent("plan-load-form-stop")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('additional-stops')), 1),
      (_ctx.additionalStops.length === 0)
        ? (_openBlock(), _createBlock(_component_plan_text_button, {
            key: 0,
            onOnClick: _ctx.addAdditionalStop,
            label: _ctx.$t('add-stop'),
            icon: "plus",
            "icon-size": "10"
          }, null, 8, ["onOnClick", "label"]))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.additionalStops, (stop) => {
      return (_openBlock(), _createElementBlock("div", {
        key: stop,
        class: "flex justify-between items-start plan-gap-sm"
      }, [
        _createVNode(_component_plan_load_form_stop, {
          onUpdateStop: _ctx.updateStop,
          index: stop.index,
          "template-data-location": stop.templateDataLocation,
          "template-warehouse-id": stop.templateWarehouseId,
          type: stop.stopType,
          "min-date": _ctx.pickupDate,
          "max-date": _ctx.deliveryDate,
          "only-country": _ctx.onlyCountry,
          "exclude-country": _ctx.excludeCountry,
          "is-additional-stop": ""
        }, null, 8, ["onUpdateStop", "index", "template-data-location", "template-warehouse-id", "type", "min-date", "max-date", "only-country", "exclude-country"]),
        _createVNode(_component_plan_button_icon, {
          onOnClick: ($event: any) => (_ctx.removeAdditionalStop(stop.index)),
          icon: "trash",
          tpye: "transparent-gray-800"
        }, null, 8, ["onOnClick"])
      ]))
    }), 128)),
    (_ctx.additionalStops.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_plan_text_button, {
            onOnClick: _ctx.addAdditionalStop,
            label: _ctx.$t('add-stop'),
            icon: "plus",
            "icon-size": "10"
          }, null, 8, ["onOnClick", "label"])
        ]))
      : _createCommentVNode("", true)
  ]))
}