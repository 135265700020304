import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { IState } from '@/services/user/interfaces';

export const initialState = (): IState => ({
  // getUser: {},
  actualWidth: 0,
  region: 'NORTH_AMERICA',
  currentUser: {},
  user: {},
  userIdentification: '',
  previousRouteImpersonating: null,
  currentUserImpersonating: {},
  company: [],
  carrierContactList: [],
  carrierContactListStatsByLoad: [],
  carrierRequestedBid: [],
  currentContacts: [],
  isShipperViewer: false,
  isTheAdminImpersonating: false,
  requestBidStatsByLoad: [],
  carrierNetwork: [],
  carrierNetworkTotal: [],
  dataTeam: [],
  toast: { visible: false, text: '', textList: null, type: '', id: '' },
  notification: { visible: false, text: '', textList: null, type: '', data: {} },
  notificationItems: [],
  usersCompany: [],
  usersCompanyTotals: 0,
  bigTexts: {},
  showHash: false,
  userEmailNotificationSetup: null as any,
  billingCompany: {
    name: '',
    email: '',
    taxId: '',
    country: '',
    state: '',
    city: '',
    address: '',
    zip: '',
  },
  crossBorderBilling: [
    { name: '', email: '', taxId: '', country: '', state: '', city: '', address: '', zip: '' },
    { name: '', email: '', taxId: '', country: '', state: '', city: '', address: '', zip: '' },
    { name: '', email: '', taxId: '', country: '', state: '', city: '', address: '', zip: '' },
  ],
  collapsedSidebar: false,
  planDefaultViewScrollTop: 0,
  showModalTimezone: false,
  showModalPlans: false,
  importingContacts: false,
});

export default {
  namespaced: true,
  state: { ...initialState() },
  actions,
  getters,
  mutations,
};
