
import { defineComponent, computed } from 'vue';
import PlanIcon from './PlanIcon.vue';

export default defineComponent({
  name: 'PlanTrafficLight',
  components: {
    PlanIcon,
  },
  props: {
    light: { type: String, required: true, default: '' },
    withText: { type: Boolean, required: true, default: false },
  },
  setup(props) {
    const getLightStyle = () => {
      switch (props.light) {
        case 'red':
          return 'border-red-700 bg-red-400';
        case 'yellow':
          return 'border-yellow-700 bg-yellow-400';
        case 'green':
          return 'border-green-700 bg-green-400';
      }
    };
    const getText = () => {
      switch (props.light) {
        case 'red':
          return 'Customs stopped the trailer';
        case 'yellow':
          return 'Trailer is held for inspection';
        case 'green':
          return 'Load succesfully cleared';
        default:
          return 'Crossing the border...';
      }
    };
    const getTextStyle = () => {
      switch (props.light) {
        case 'red':
          return 'text-red-500 bg-red-200';
        case 'yellow':
          return 'text-gray-600 bg-yellow-300';
        case 'green':
          return 'bg-green-200';
      }
    };

    return {
      getLightStyle,
      getText,
      getTextStyle,
    };
  },
});
