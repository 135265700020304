import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-lg plan-rounded-xs plan-p-md plan-gap-md bg-white" }
const _hoisted_2 = { class: "flex justify-start items-center plan-gap-md" }
const _hoisted_3 = { class: "font-bold plan-t-h2 plan-t-prim-100" }
const _hoisted_4 = { class: "font-bold plan-t-h2" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "flex justify-start items-center" }
const _hoisted_7 = {
  key: 0,
  class: "plan-div-form plan-div-form-hide-right plan-div-form-show-right-line flex justify-center plan-t-prim-100",
  style: {"width":"30px"}
}
const _hoisted_8 = {
  key: 1,
  class: "plan-div-form plan-div-form-hide-left plan-div-form-show-left-line flex justify-center plan-t-prim-100",
  style: {"width":"30px"}
}
const _hoisted_9 = { class: "flex justify-between items-center plan-gap-sm" }
const _hoisted_10 = { class: "flex justify-end items-center plan-gap-sm" }
const _hoisted_11 = {
  key: 0,
  class: "w-full plan-rounded-sm border plan-border-prim-50 plan-p-sm flex flex-col"
}
const _hoisted_12 = { class: "font-bold plan-t-prim-200" }
const _hoisted_13 = { class: "flex justify-between items-start" }
const _hoisted_14 = { class: "font-bold plan-t-h5" }
const _hoisted_15 = {
  key: 1,
  class: "w-full flex flex-col plan-gap-md"
}
const _hoisted_16 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_17 = {
  class: "flex flex-col",
  style: {"min-width":"210px","max-width":"210px"}
}
const _hoisted_18 = { class: "flex justify-end items-end" }
const _hoisted_19 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_header_search = _resolveComponent("plan-header-search")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_add_carrier_modal = _resolveComponent("add-carrier-modal")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_avatar = _resolveComponent("plan-avatar")!
  const _component_plan_contact_tags = _resolveComponent("plan-contact-tags")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_flag = _resolveComponent("plan-flag")!
  const _component_plan_default_table = _resolveComponent("plan-default-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeView && _ctx.changeView(...args))),
        class: "flex justify-center items-center plan-gap-sm cursor-pointer"
      }, [
        _createElementVNode("p", _hoisted_3, "1. " + _toDisplayString(_ctx.$t('shipment-data')), 1),
        _createVNode(_component_plan_icon, {
          name: "fill-circle-check",
          "icon-color": "#8A8B97",
          "is-svg": ""
        })
      ]),
      _createElementVNode("p", _hoisted_4, "2. " + _toDisplayString(_ctx.$t('direct-assign')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('notification-service-price')), 1),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.actualRegion === 'NORTH_AMERICA')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, "$"))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.price) = $event)),
          placeholder: "0",
          type: "number",
          class: _normalizeClass(["plan-form", _ctx.actualRegion === 'NORTH_AMERICA' ? 'plan-form-hide-left':'plan-form-hide-right']),
          style: {"width":"200px"}
        }, null, 2), [
          [_vModelText, _ctx.price]
        ]),
        (_ctx.actualRegion === 'EUROPE')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, "€"))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_plan_header_search, {
        onChangeQuery: _ctx.changeSearchName,
        "old-query": '',
        placeholder: _ctx.$t('search-company')
      }, null, 8, ["onChangeQuery", "placeholder"]),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.loadingContacts)
          ? (_openBlock(), _createBlock(_component_plan_loading, {
              key: 0,
              small: ""
            }))
          : _createCommentVNode("", true),
        (_ctx.showAddNewContactModal)
          ? (_openBlock(), _createBlock(_component_add_carrier_modal, {
              key: 1,
              onSetCompanySearch: _ctx.addNewCompany,
              onHideModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAddNewContactModal = false))
            }, null, 8, ["onSetCompanySearch"]))
          : _createCommentVNode("", true),
        _createVNode(_component_plan_button, {
          onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showAddNewContactModal = true)),
          type: "primary",
          label: _ctx.$t('new-contact'),
          "ç": "",
          "show-icon": "",
          icon: "plus",
          "icon-size": "10",
          "icon-color": "white"
        }, null, 8, ["label"])
      ])
    ]),
    (_ctx.newCompany !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('new-contact')), 1),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.newCompany.name), 1),
            _createVNode(_component_plan_button, {
              onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectCompany(null))),
              "show-icon": "",
              icon: "circle-check",
              iconColor: "#00DB50",
              "icon-type": "svg",
              type: "success-outline",
              label: _ctx.$t('selected'),
              style: {"width":"175px"}
            }, null, 8, ["label"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_15, [
          (_ctx.searchQuery.name.length > 0)
            ? (_openBlock(), _createBlock(_component_plan_default_table, {
                key: 0,
                onChangeOrder: _ctx.changeContactsOrder,
                items: _ctx.contacts,
                headers: _ctx.headers,
                order: _ctx.order.value,
                "order-direction": _ctx.order.direction
              }, {
                default: _withCtx((props) => [
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_plan_avatar, {
                          "max-characters": 23,
                          "user-name": props.item?.name,
                          description: `${props.item?.owner_name} ${props.item?.owner_surname}`
                        }, null, 8, ["user-name", "description"]),
                        _createVNode(_component_plan_contact_tags, {
                          "company-id": props.item.id,
                          "actual-tags": props.item.tags,
                          "only-label": ""
                        }, null, 8, ["company-id", "actual-tags"])
                      ]),
                      _createVNode(_component_plan_button_icon, {
                        onOnClick: ($event: any) => (_ctx.openChat(props.item)),
                        icon: "chat",
                        type: "transparent-gray-800"
                      }, null, 8, ["onOnClick"]),
                      (props?.item?.country_iso)
                        ? (_openBlock(), _createBlock(_component_plan_flag, {
                            key: 0,
                            country: props?.item?.country_iso?.toLowerCase(),
                            width: 30,
                            height: 17
                          }, null, 8, ["country"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("td", null, _toDisplayString(props.item?.stats_amountvalorations), 1),
                  _createElementVNode("td", null, _toDisplayString(props.item?.type == 'CARRIER' || props.item?.type == 'BROKER'
                ? _ctx.$t(`${props.item?.type.toLowerCase()}`).toUpperCase()
                : props.item?.type), 1),
                  _createElementVNode("td", null, _toDisplayString(props.item?.stats_shipmentamount), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_18, [
                      (_ctx.selectedCompany !== props.item.id)
                        ? (_openBlock(), _createBlock(_component_plan_button, {
                            key: 0,
                            onOnClick: ($event: any) => (_ctx.selectCompany(props.item.id)),
                            type: "primary",
                            label: _ctx.$t('select'),
                            style: {"width":"175px"}
                          }, null, 8, ["onOnClick", "label"]))
                        : _createCommentVNode("", true),
                      (_ctx.selectedCompany === props.item.id)
                        ? (_openBlock(), _createBlock(_component_plan_button, {
                            key: 1,
                            onOnClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectCompany(null))),
                            "show-icon": "",
                            icon: "circle-check",
                            iconColor: "#00DB50",
                            "icon-type": "svg",
                            type: "success-outline",
                            label: _ctx.$t('selected'),
                            style: {"width":"175px"}
                          }, null, 8, ["label"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["onChangeOrder", "items", "headers", "order", "order-direction"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_19, [
            (_ctx.contacts.length > 0 && _ctx.showMoreContactsButtons && _ctx.searchQuery.name.length > 0)
              ? (_openBlock(), _createBlock(_component_plan_button, {
                  key: 0,
                  onOnClick: _ctx.loadMoreContacts,
                  loading: _ctx.loadingContacts,
                  type: "primary-outline",
                  "full-rounded": "",
                  label: _ctx.$t('show-more-contacts'),
                  style: {"width":"328px"},
                  "icon-color": "#151830"
                }, null, 8, ["onOnClick", "loading", "label"]))
              : _createCommentVNode("", true)
          ])
        ]))
  ]))
}