
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import moment from 'moment-timezone';

import PlanTable from '@/components/tables/PlanTable.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanRating from '@/components/badges/PlanRating.vue';
import UserSection from '@/modules/superAdmin/_components/UserSection.vue';
// Base
import BaseComponent from '@/base/BaseComponent';
import { formatDate } from '@/utils/formatDates';

export default defineComponent({
  name: 'LoadDetailQuoteBids',
  mixins: [BaseComponent],
  components: {
    PlanRating,
    PlanButton,
    PlanButtonIcon,
    PlanSelectSimple,
    PlanTable,
    UserSection,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const data = reactive({
      headers: [
        { value: 'age', label: t('age'), align: 'left', showOrder: true },
        { value: 'carrier', label: 'Carrrier/Brooker', align: 'left', showOrder: true },
        { value: 'rating', label: 'Rating', align: 'left', showOrder: true },
        { value: 'completedLoads', label: 'Completed loads', align: 'left', showOrder: true },
        {
          value: 'historicalAveragePrice',
          label: 'Historical average price',
          align: 'left',
          showOrder: true,
        },
        { value: 'rate', label: 'Rate', align: 'left', showOrder: true },
        { label: '', align: 'left', showOrder: false },
      ],
      orderBy: '',
      selectedRange: 'month',
      searchRequestBidQuery: {
        page: 1,
        originZip: 0,
        destinyZip: 0,
        trailerTypeStats: 0,
        id: 0,
        shipment: null as any,
        period: '' as any,
        sortBy: '',
      },
      target: null as any,
      isSending: [] as any,
      isDeassign: false,
    });

    const formatDateLocale = computed(() => {
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? 'MMM DD, YYYY HH:MM'
        : 'DD MMM, YYYY HH:MM';
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const userRegion = computed(() => {
      return currentUser.value.company?.country?.region?.name;
    });

    const load = computed(() => {
      return store.getters['LoadDetailStore/getLoadDataContent'];
    });

    const requestBidsItems = computed(() => {
      return store.getters['UserStore/requestBidStatsByLoad'];
    });

    const dateTime = (date) => {
      const dtf = new Intl.DateTimeFormat();
      const localTimezone = dtf.resolvedOptions().timeZone;
      const timezone = store.getters['UserStore/getCurrentUser']?.timeZone ?? localTimezone;

      const formattedDateTime = moment.utc(date).tz(timezone).format('MMM DD, HH:mm');
      const [datePart, timePart] = formattedDateTime.split(', ');

      return {
        date: datePart,
        time: timePart,
      };
    };

    const pricePerMile = (quote, totalMiles) => {
      return (quote / totalMiles).toFixed(2);
    };

    const fetchOrder = (orderObject) => {
      console.log('load: ', load.value);
      const { shipment, trailerType, stops } = load.value;
      const { id: shipmentId } = shipment;
      const { id: trailerTypeId } = trailerType || {};
      const [originStop, destinyStop] = stops;

      data.searchRequestBidQuery = {
        ...data.searchRequestBidQuery,
        shipment: shipmentId,
        trailerTypeStats: trailerTypeId,
        originZip: originStop.location.zip,
        destinyZip: destinyStop.location.zip,
        period: data.selectedRange,
      };

      data.orderBy = orderObject;
      data.searchRequestBidQuery.sortBy = `${
        orderObject.value
      }[${orderObject.direction.toUpperCase()}]`;
      getRequestBid();
    };

    const getRequestBid = () => {
      store.dispatch('UserStore/requestBidStatsByLoad', data.searchRequestBidQuery);
    };

    const changeOrder = (orderByData) => {
      console.log('changeOrder: ', orderByData);
      data.orderBy = orderByData.value;
    };

    const changeSelectedRange = (newValue) => {
      data.selectedRange = newValue;
    };

    const changeGoal = (newTargetValue) => {
      data.target = newTargetValue;
    };

    const calculateGoal = (bid) => {
      let result = {
        label: '',
        class: '',
      };

      if (bid) {
        if (data.target !== null) {
          data;
          const targetDifference = data.target - bid;
          const localeToShow =
            store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es';
          const formatCurrency = new Intl.NumberFormat(localeToShow, {
            style: 'currency',
            currency: localeToShow === 'en' ? 'USD' : 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });

          if (targetDifference < 0) {
            result.label = t('set-a-goal-over', {
              target: formatCurrency.format(Math.abs(targetDifference)),
            });
            result.class = 'plan-t-prim-100 plan-t-xxs';
          } else if (targetDifference === 0) {
            result.label = t('set-a-goal-target');
            result.class =
              'plan-t-prim-200 plan-b-green-200 plan-t-xxs rounded-full plan-pl-xs plan-pr-xs';
          } else {
            result.label = t('set-a-goal-below', {
              target: formatCurrency.format(Math.abs(targetDifference)),
            });
            result.class =
              'plan-t-prim-200 plan-b-green-200 plan-t-xxs rounded-full plan-pl-xs plan-pr-xs';
          }
        }
      }

      return result;
    };

    const isResendQuote = (item) => {
      return (
        item?.status === 'DECLINED' ||
        (item?.status === 'WAITING' &&
          !(item?.company.id === load?.value.shipment?.assignedCompany?.company.id))
      );
    };

    if (load.value.id) {
      fetchOrder({ value: 'rate', direction: 'asc' });
    }

    return {
      ...toRefs(data),
      calculateGoal,
      changeGoal,
      changeOrder,
      changeSelectedRange,
      dateTime,
      formatDate,
      isResendQuote,
      pricePerMile,
      formatDateLocale,
      requestBidsItems,
      load,
      userRegion,
      moment,
    };
  },
});
