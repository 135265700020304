
import { defineComponent, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'PlanTraffictLightSelector',
  components: { PlanCarrierDefaultModal, PlanIcon },
  emits: ['change'],
  props: {
    light: { type: String, required: true, default: '' },
    withText: { type: Boolean, required: true, default: false },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();

    let loadingChangeSemaphore = ref(false);

    const getLightStyle = () => {
      switch (props.light) {
        case 'red':
          return 'border-red-700 bg-red-400';
        case 'yellow':
          return 'border-yellow-700 bg-yellow-400';
        case 'green':
          return 'border-green-700 bg-green-400';
      }
    };

    const getTextStyle = () => {
      switch (props.light) {
        case 'red':
          return 'text-white bg-red-200';
        case 'yellow':
          return 'text-white bg-yellow-300';
        case 'green':
          return 'text-white bg-green-200';
      }
    };

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const handleChangeStatus = (status) => {
      loadingChangeSemaphore.value = true;
      store
        .dispatch('LoadsStore/putStopLightStatus', {
          id: loadData.value.id,
          status: status,
        })
        .then(() => {
          store.dispatch('CarrierStore/getLoadInfoForCarrier', loadData.value.id);
          emit('change', status);
          loadingChangeSemaphore.value = false;
        })
        .finally(() => {
          loadingChangeSemaphore.value = false;
        });
    };

    return {
      actualWidth,
      getLightStyle,
      getTextStyle,
      loadingChangeSemaphore,
      loadData,
      handleChangeStatus,
    };
  },
});
