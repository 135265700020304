
import { defineComponent, onMounted, onUnmounted, reactive, ref, toRefs, computed } from 'vue';
import LoadTimeline from '@/components/loads/LoadTimeline.vue';
import LoadCardState from '@/components/loads/card/LoadCardState.vue';
import LoadCardInitialAndFinalStop from '@/components/loads/card/LoadCardInitialAndFinalStop.vue';
import LoadCardTag from '@/components/loads/card/LoadCardTag.vue';
import LoadCardFooterData from '@/components/loads/card/LoadCardFooterData.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'LoadCard',
  components: {
    LoadCardFooterData,
    LoadCardTag,
    LoadCardInitialAndFinalStop,
    LoadCardState,
    LoadTimeline,
  },
  props: {
    state: { type: String, required: true, default: '' },
    load: { type: Object, required: true },
    chats: { type: Array, required: true },
    savedBidsLtl: { type: Number, required: false, default: 0 },
  },
  emits: ['onClick', 'onOptionsClick', 'openDetail'],
  setup(props, { emit }) {
    const store = useStore();

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const loadCardBg = require('@/resources/assets/img/card-bg.svg');
    const windowWidth = ref(window.innerWidth);
    const translate = useI18n();

    const loadCardTagData = computed(() => {
      return {
        identifier: props.load.identifier,
        type: props.load.shipment.type,
        po: props.load.poNumber,
        source: props.load.source,
        rfp: {
          projectId: props.load.laneRFP?.project.id,
          info: props.load.laneRFP?.project.name,
          laneId: props.load.laneRFP?.id,
          lane: props.load.laneRFP?.identifier,
        },
        multiStopData: {
          loadBatchAmount: props.load?.loadBatch?.amount,
          loadBatchSequence: props.load?.loadBatchSequence,
        },
        ltl: { proNumber: props.load.proNumber },

        rightItems: [],
      };
    });

    const loadCardPickupAndDelivery = computed(() => {
      return {
        pickup: {
          city: props.load.stops[0].location.city,
          ct: props.load.stops[0].location.state.country.iso,
          isoCode: props.load.stops[0].location.state.iso,
          date: props.load.stops[0].executionDate,
        },
        delivery: {
          city: props.load.stops[props.load.stops.length - 1].location.city,
          ct: props.load.stops[props.load.stops.length - 1].location.state.country.iso,
          isoCode: props.load.stops[props.load.stops.length - 1].location.state.iso,
          date: props.load.stops[props.load.stops.length - 1].executionDate,
        },
        dateTimeEta: props.load.shipment?.dateTimeETA,
      };
    });

    const loadCardStateBackground = computed(() => {
      if (props.state === 'QUOTING') return 'load-card-quoting';
      if (props.state === 'CONFIRMED') return 'load-card-confirmed';
      if (props.state === 'ASSIGNED') return 'load-card-assigned';
      if (props.state === 'IN_PROGRESS') return 'load-card-in-progress';
      if (props.state === 'FINISHED') return 'load-card-finished';
      if (props.state === 'CANCELED') return 'load-card-canceled';
      if (props.state === 'DELETED') return 'load-card-deleted';

      return 'load-card-default';
    });

    const loadCardFooterData = computed(() => {
      let distanceUnits = userRegion.value === 'EUROPE' ? ' km' : ' mi';
      if (props.load.type === 'MEX_DOMESTIC') distanceUnits = 'km'
      let goodsTypeUnits = userRegion.value === 'EUROPE' ? ' kg' : ' lb';
      if (props.load.type === 'MEX_DOMESTIC') goodsTypeUnits = 'kg'

      let goodsTypeResult = '';
      if (props.load.commodityMasters && props.load.commodityMasters.length > 0) {
        props.load.commodityMasters.forEach(function (commodity: any) {
          if (goodsTypeResult === '') {
            goodsTypeResult = commodity.commodityName + ' ' + commodity.weight + goodsTypeUnits;
          } else {
            goodsTypeResult =
              goodsTypeResult +
              ', ' +
              commodity.commodityName +
              ' ' +
              commodity.weight +
              goodsTypeUnits;
          }
        });
      }

      let specialRequirementsResult = '';
      if (props.load.specialRequirement?.TWICCard)
        specialRequirementsResult = specialRequirementsResult + `${translate.t('twic-card')} `;
      if (props.load.specialRequirement?.bondedPermission)
        specialRequirementsResult = specialRequirementsResult + `${translate.t('bondedPermission')} `;
      if (props.load.specialRequirement?.hazardousLoad)
        specialRequirementsResult = specialRequirementsResult + `${translate.t('hazardousLoad')} `;
      if (props.load.specialRequirement?.teamDriver)
        specialRequirementsResult = specialRequirementsResult + `${translate.t('team-driver')} `;
      if (props.load.specialRequirement?.tsa)
        specialRequirementsResult = specialRequirementsResult + `${translate.t(' tsa-card')} `;
      if (props.load.specialRequirement?.tir)
        specialRequirementsResult = specialRequirementsResult + `${translate.t('tir')} `;
      if (props.load.specialRequirement?.oversize)
        specialRequirementsResult = specialRequirementsResult + `${translate.t('oversize')} `;
      if (props.load.specialRequirement?.overweight)
        specialRequirementsResult = specialRequirementsResult + `${translate.t('overweight')} `;
      if (props.load.specialRequirement?.specialPermissions) {
        specialRequirementsResult =
          specialRequirementsResult +
          `${translate.t('special-permits')}: ${props.load.specialRequirement.specialPermissionsDescription} `;
      }

      return {
        distance: props.load.shipment?.totalDistance
          ? props.load.shipment?.totalDistance + distanceUnits
          : '',
        vehicle: props.load.trailerType?.description,
        goodsType: goodsTypeResult,
        specialRequirements: specialRequirementsResult,
      };
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const userRegion = computed(() => {
      return currentUser.value.company?.country?.region?.name;
    });

    const onClick = () => {
      emit('onClick');
    };

    const onOptionsClick = (params: any) => {
      emit('onOptionsClick', params);
    };

    onMounted(() => {
      window.addEventListener('resize', resizeWindow);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeWindow);
    });

    const resizeWindow = () => {
      windowWidth.value = window.innerWidth;
    };

    return {
      loadCardBg,
      loadCardStateBackground,
      loadCardTagData,
      loadCardPickupAndDelivery,
      loadCardFooterData,
      onClick,
      onOptionsClick,
      windowWidth,
    };
  },
});
