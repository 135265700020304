import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded w-full border plan-border-prim-50 plan-p-md flex flex-col plan-gap-md" }
const _hoisted_2 = { class: "font-bold plan-t-xs plan-t-prim-200" }
const _hoisted_3 = {
  key: 0,
  class: "plan-t-xxs plan-t-red-500 text-right"
}
const _hoisted_4 = {
  key: 1,
  class: "flex justify-between items-start plan-gap-sm"
}
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "plan-t-xs" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "plan-t-xs" }
const _hoisted_9 = { class: "flex justify-end items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_load_form_commodity_data = _resolveComponent("plan-load-form-commodity-data")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_plan_input_text_area = _resolveComponent("plan-input-text-area")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('trailer-commodities')), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["w-full flex", _ctx.actualWidth < 900 ? 'flex-col justify-start items-start plan-gap-md':'justify-between items-start plan-gap-sm'])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col plan-gap-sm", _ctx.actualWidth < 900 ? 'w-full':'w-1/3'])
      }, [
        _createVNode(_component_plan_select, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTrailerValidation = true)),
          onChange: _ctx.changeTrailer,
          label: _ctx.$t('trailer-type'),
          value: _ctx.trailerId,
          items: _ctx.vehicles,
          placeholder: _ctx.$t('select-trailer'),
          mandatory: "",
          "equal-size": ""
        }, null, 8, ["onChange", "label", "value", "items", "placeholder"]),
        (_ctx.showTrailerValidation && !_ctx.isValidatedForm)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('required-field')), 1))
          : _createCommentVNode("", true),
        (_ctx.isRefrigerated)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('min-temp', { unit: _ctx.getTemperatureUnit() })), 1),
                _withDirectives(_createElementVNode("input", {
                  onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateTrailerData && _ctx.updateTrailerData(...args))),
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.minTemp) = $event)),
                  placeholder: "0",
                  type: "number",
                  class: "plan-form"
                }, null, 544), [
                  [_vModelText, _ctx.minTemp]
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('max-temp', { unit: _ctx.getTemperatureUnit() })), 1),
                _withDirectives(_createElementVNode("input", {
                  onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateTrailerData && _ctx.updateTrailerData(...args))),
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.maxTemp) = $event)),
                  placeholder: "0",
                  type: "number",
                  class: "plan-form"
                }, null, 544), [
                  [_vModelText, _ctx.maxTemp]
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ], 2),
      (_openBlock(), _createElementBlock("div", {
        class: "w-full flex flex-col plan-gap-sm",
        key: _ctx.commoditiesKey
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commodities, (commodity, index) => {
          return (_openBlock(), _createBlock(_component_plan_load_form_commodity_data, {
            onChange: _ctx.updateCommodity,
            onRemove: _ctx.removeCommodity,
            key: commodity.index,
            index: index,
            commodity: commodity,
            onlyCountry: _ctx.onlyCountry
          }, null, 8, ["onChange", "onRemove", "index", "commodity", "onlyCountry"]))
        }), 128)),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_plan_text_button, {
            onOnClick: _ctx.addCommodity,
            icon: "plus",
            "icon-size": "10",
            label: _ctx.$t('add-more-freight'),
            class: "plan-mt-sm"
          }, null, 8, ["onOnClick", "label"])
        ])
      ]))
    ], 2),
    _createVNode(_component_plan_input_text_area, {
      onChange: _ctx.changeCommodityObservations,
      "model-value": _ctx.observations,
      "onUpdate:model-value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.observations) = $event)),
      label: _ctx.$t('observations')
    }, null, 8, ["onChange", "model-value", "label"])
  ]))
}