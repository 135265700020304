export default {
  isValidatedForm (state: any) {
    let pickupResult = true
    let pickupDateResult = true
    let deliveryResult = true
    let deliveryDateResult = true
    let trailerResult = true
    let commoditiesResult = true
    let additionalStops = true

    if (state.pickup === null) {
      pickupResult = false
      pickupDateResult = false
    } else {
      if (state.pickup.date === 'mm-dd-yyyy' || state.pickup.date === 'dd-mm-yyyy') pickupDateResult = false
      if (state.pickup.stopData === null) pickupResult = false
    }

    if (state.delivery === null) {
      deliveryResult = false
      deliveryDateResult = false
    } else {
      if (state.delivery.date === 'mm-dd-yyyy' || state.delivery.date === 'dd-mm-yyyy') deliveryDateResult = false
      if (state.delivery.stopData === null) deliveryResult = false
    }

    if (state.trailerData.trailerId === -1) trailerResult = false

    if (state.commodities.length === 0) {
      commoditiesResult = false

    } else {
      state.commodities.forEach((commodity: any) => {
        if (commodity.commodityDescription === '' ||
            commodity.units === null ||
            commodity.units === '' ||
            commodity.units === 0 ||
            commodity.weight === 0 ||
            commodity.weight === null ||
            commodity.weight === '') {
          commoditiesResult = false
        }
      })
    }

    if (state.additionalStops.length > 0) {
      state.additionalStops.forEach((additionalStop: any) => {
        if (additionalStop.stopData === null) additionalStops = false
        if (additionalStop.date === 'mm-dd-yyyy' || additionalStop.date === 'dd-mm-yyyy') additionalStops = false
      })
    }

    return {
      additionalStops: additionalStops,
      pickup: pickupResult,
      pickupDate: pickupDateResult,
      delivery: deliveryResult,
      deliveryDate: deliveryDateResult,
      trailer: trailerResult,
      commodities: commoditiesResult,
      letterOfInstructions: state.letterOfInstructions !== null,
      result: pickupResult && pickupDateResult && deliveryResult && deliveryDateResult && trailerResult && commoditiesResult && additionalStops,
      resultMx: pickupResult && pickupDateResult && deliveryResult && deliveryDateResult && trailerResult && commoditiesResult && additionalStops && state.letterOfInstructions !== null,
    }
  },
  getDirectAssign (state: any) {
    return state.directAssign
  },
  getPickupDate (state: any) {
    let result = ''
    if (state.pickup !== null) {
      if (state.pickup.date !== null && state.pickup.date !== undefined) result = state.pickup.date
    }
    return result
  },
  getDeliveryDate (state: any) {
    let result = ''
    if (state.delivery !== null) {
      if (state.delivery.date !== null && state.delivery.date !== undefined) result = state.delivery.date
    }
    return result
  },
  getQuery (state: any) {
    return {
      pickup: state.pickup,
      delivery: state.delivery,
      stops: state.stops,
      trailer: state.trailerData,
      commodities: state.commodities,
      commodityObservations: state.commodityObservations,
      specialRequirements: state.specialRequirements
    }
  },
  getAdditionalStops (state: any) {
    return state.additionalStops
  },
  getFiles (state: any) {
    return state.files
  },
  getMultipleLoads (state: any) {
    return state.multipleLoads
  },
  getPoNumber (state: any) {
    return state.poNumber
  },
  getSpecialRequirements (state: any) {
    return state.specialRequirements
  },
  getTrailerData (state: any) {
    return state.trailer
  },
  getType (state: any) {
    return state.type
  },
  getCommodities (state: any) {
    return state.commodities
  },
  getCommodityObservations (state: any) {
    return state.commodityObservations
  },
  getFreightInfo (state: any) {
    return state.freightInfo
  },
  getCustomers (state: any) {
    return state.customers
  },
  getLetterOfInstructions (state: any) {
    return state.letterOfInstructions
  },
  getTemplateData (state: any) {
    return state.templateData
  }
}