
import { defineComponent, computed, reactive, toRefs } from 'vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import { useStore } from 'vuex';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import { useI18n } from 'vue-i18n';

interface planCarrierLoadAcceptationMyDataForm {
  myDataForm: {
    type: string;
    firstName: string;
    lastName: string;
    email: string;
    prefix: string;
    phone: null | number;
    cif: string;
    companyName: string;
    companyAddress: string;
    dotNumber: string;
    mcNumber: string;
    sctNumber: string;
  };
}
export default defineComponent({
  name: 'PlanCarrierLoadAcceptationMyDataForm',
  components: { PlanSelect, PlanInputText },
  setup() {
    const store = useStore();
    const translate = useI18n();

    const data = reactive<planCarrierLoadAcceptationMyDataForm>({
      myDataForm: {
        type: '',
        firstName: '',
        lastName: '',
        email: '',
        prefix: '1',
        phone: null,
        cif: '',
        companyName: '',
        companyAddress: '',
        dotNumber: '',
        mcNumber: '',
        sctNumber: '',
      },
    });

    store.dispatch('CountryStore/getCountries').then(() => {
      store.dispatch('CountryStore/getCountryById', currentUser.value?.company?.country?.id);
    });

    const actualRegion = computed(() => {
      return store.getters['UserStore/getRegion'];
    });

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const fieldNames = computed(() => {
      const region = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'usa' : 'eu';
      let dotNumber = translate.t(
        region === 'usa' ? 'fields.dot-number-usa' : 'fields.dot-number-eu',
      );
      let mcNumber = translate.t(region === 'usa' ? 'fields.mc-number-usa' : 'fields.mc-number-eu');
      let fein = translate.t(region === 'usa' ? 'fields.fein-usa' : 'fields.fein-eu');

      return {
        dotNumber: dotNumber,
        mcNumber: mcNumber,
        fein: fein,
        sctNumber: translate.t('fields.sct-number'),
      };
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const changeCarrierType = async () => {
      updateMyDataInStore();
      await store.dispatch('CompanyStore/putCompanyUpdateType', {
        id: currentUser.value.company.id,
        type: { type: data.myDataForm.type },
      });
    };

    const changePrefix = (newPrefix: string) => {
      data.myDataForm.prefix = newPrefix;
      updateMyDataInStore();
    };

    const getUser = () => {
      data.myDataForm.type = store.getters['CarrierStore/getMyDataForm'].type;
      data.myDataForm.firstName = store.getters['CarrierStore/getMyDataForm'].firstName;
      data.myDataForm.lastName = store.getters['CarrierStore/getMyDataForm'].lastName;
      data.myDataForm.email = store.getters['CarrierStore/getMyDataForm'].email;
      data.myDataForm.phone = store.getters['CarrierStore/getMyDataForm'].phone;
      data.myDataForm.prefix = store.getters['CarrierStore/getMyDataForm'].prefix
        ? store.getters['CarrierStore/getMyDataForm'].prefix
        : '';
      data.myDataForm.cif = store.getters['CarrierStore/getMyDataForm'].cif;
      data.myDataForm.companyName = store.getters['CarrierStore/getMyDataForm'].companyName;
      data.myDataForm.companyAddress = store.getters['CarrierStore/getMyDataForm'].companyAddress;
      data.myDataForm.dotNumber = store.getters['CarrierStore/getMyDataForm'].dotNumber;
      data.myDataForm.mcNumber = store.getters['CarrierStore/getMyDataForm'].mcNumber;
      data.myDataForm.sctNumber = store.getters['CarrierStore/getMyDataForm'].sctNumber;
    };

    const orderPrefixAsc = (prefixes: any) => {
      return prefixes.sort((a, b) => {
        let prefixA = parseInt(a.value, 10);
        let prefixB = parseInt(b.value, 10);

        if (prefixA < prefixB) {
          return -1;
        }
        if (prefixA > prefixB) {
          return 1;
        }
        return 0; // Si son iguales
      });
    };

    const updateMyDataInStore = () => {
      store.commit('CarrierStore/setMyDataForm', {
        hasModifications: true,
        type: data.myDataForm.type,
        firstName: data.myDataForm.firstName,
        lastName: data.myDataForm.lastName,
        email: data.myDataForm.email,
        prefix: data.myDataForm.prefix,
        phone: data.myDataForm.phone,
        cif: data.myDataForm.cif,
        companyName: data.myDataForm.companyName,
        companyAddress: data.myDataForm.companyAddress,
        dotNumber: data.myDataForm.dotNumber,
        mcNumber: data.myDataForm.mcNumber,
        sctNumber: data.myDataForm.sctNumber,
      });
    };

    const prefixList = computed(() => {
      const prefixes = store.getters['CarrierStore/getRegionPrefixes'];
      return orderPrefixAsc(prefixes);
    });

    getUser();

    return {
      ...toRefs(data),
      actualRegion,
      actualWidth,
      changeCarrierType,
      changePrefix,
      fieldNames,
      updateMyDataInStore,
      loadData,
      prefixList,
    };
  },
});
