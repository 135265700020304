
import { defineComponent, computed, watch } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'PlanCarrierLoadHeaderNotification',
  props: {
    message: { type: String, required: false, default: '' },
    type: { type: String, required: true },
  },
  setup(props) {
    const store = useStore();

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    return {
      actualWidth,
    };
  },
});
