import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "plan-default-view-header"
}
const _hoisted_2 = { class: "flex flex justifty-start items-center" }
const _hoisted_3 = {
  key: 0,
  class: "text-4xl font-bold plan-mr-lg"
}
const _hoisted_4 = { class: "header-buttons" }
const _hoisted_5 = {
  key: 1,
  class: "plan-default-view-header-sticky"
}
const _hoisted_6 = {
  key: 0,
  class: "plan-default-view-header-sticky-top"
}
const _hoisted_7 = {
  key: 1,
  class: "plan-default-view-header-sticky-bottom"
}
const _hoisted_8 = { class: "plan-default-view-bottom-selection-bar-content" }
const _hoisted_9 = { class: "plan-default-view-bottom-selection-bar--toast" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar_component = _resolveComponent("sidebar-component")!
  const _component_plan_shipper_width_calculator = _resolveComponent("plan-shipper-width-calculator")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _directive_scroll = _resolveDirective("scroll")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_sidebar_component),
    _createVNode(_component_plan_shipper_width_calculator),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "plan-default-view bg-plan-gray-100",
      ref: "planDefaultView",
      style: _normalizeStyle(_ctx.planDefaultViewStyle)
    }, [
      (_ctx.$slots['header-content'] || _ctx.$slots['header-buttons'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.title.length > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.title), 1))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "header-content")
            ]),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "header-buttons")
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.$slots['header-sticky'] || _ctx.$slots['header-sub-sticky'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.$slots['header-sticky'])
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "header-sticky")
                ]))
              : _createCommentVNode("", true),
            (_ctx.$slots['header-sub-sticky'])
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _renderSlot(_ctx.$slots, "header-sub-sticky")
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["plan-default-view-content", {'plan-default-view-content-max-width': _ctx.maxWidthContent,  }]),
        style: _normalizeStyle(_ctx.$slots['footer'] ? 'margin-bottom: 100px;':'')
      }, [
        _renderSlot(_ctx.$slots, "view-content")
      ], 6),
      (_ctx.showFooter)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "plan-default-view-footer",
            style: _normalizeStyle(_ctx.planDefaultViewFooterStyle)
          }, [
            _renderSlot(_ctx.$slots, "footer")
          ], 4))
        : _createCommentVNode("", true),
      (_ctx.showBottomSelectionBar)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(["plan-default-view-bottom-selection-bar", _ctx.planBottomSpaceSelectionBar]),
            style: _normalizeStyle(_ctx.planDefaultViewBottomSelectionBarStyle)
          }, [
            _createElementVNode("div", _hoisted_8, [
              _renderSlot(_ctx.$slots, "bottom-selection-bar")
            ])
          ], 6))
        : _createCommentVNode("", true),
      (_ctx.showBottomToast)
        ? (_openBlock(), _createElementBlock("div", {
            key: 4,
            class: _normalizeClass(["plan-default-view-bottom-selection-bar", _ctx.planBottomSpaceSelectionBar]),
            style: _normalizeStyle(_ctx.planDefaultViewBottomSelectionBarStyle)
          }, [
            _createElementVNode("div", _hoisted_9, [
              _renderSlot(_ctx.$slots, "bottom-toast")
            ])
          ], 6))
        : _createCommentVNode("", true),
      (_ctx.showGoTop && _ctx.planDefaultViewScroll > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 5,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resetScrollVertical && _ctx.resetScrollVertical(...args))),
            class: "plan-default-view-go-top"
          }, [
            _createVNode(_component_plan_icon, {
              name: "chevron-up",
              "is-svg": "",
              "icon-color": "black"
            })
          ]))
        : _createCommentVNode("", true)
    ], 4)), [
      [_directive_scroll, _ctx.changePlanDefaultViewScroll]
    ])
  ], 64))
}