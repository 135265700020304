import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f73348dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "plan-t-prim-100"
}
const _hoisted_2 = {
  key: 1,
  class: "flex flex-wrap"
}
const _hoisted_3 = {
  key: 0,
  class: "flex flex-row"
}
const _hoisted_4 = {
  class: "plan-p-sm overflow-y-auto",
  style: {"max-height":"200px"}
}
const _hoisted_5 = { class: "plan-filter-items-title" }
const _hoisted_6 = { class: "font-semibold text-sm" }
const _hoisted_7 = { class: "plan-filter-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createBlock(_component_popper, {
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPopper = false)),
    arrow: "",
    show: _ctx.showPopper,
    offsetDistance: "0",
    style: {"--popper-theme-padding":"0px"}
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('tags')), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
            return (_openBlock(), _createElementBlock("div", {
              key: tag,
              class: _normalizeClass([
              'flex  w-full justify-start items-center plan-gap-xs plan-p-sm cursor-pointer max-w-[220px]',
              { selected: _ctx.isSelected(tag) },
            ]),
              style: {"border-bottom":"1px solid #e5e7eb"}
            }, [
              _createVNode(_component_plan_input_checkbox, {
                onChange: ($event: any) => (_ctx.toggleTag(tag)),
                label: `${tag}`,
                "pre-value": _ctx.isSelected(tag),
                labelClass: 'text-blue-500',
                class: "w-full"
              }, null, 8, ["onChange", "label", "pre-value"])
            ], 2))
          }), 128))
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["plan-filter", { 'plan-filter-focus': _ctx.showPopper, 'plan-filter-active': _ctx.selectedTags.length > 0 }])
      }, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPopper = true)),
          class: "flex justify-start items-center plan-gap-sm"
        }, [
          _createVNode(_component_plan_icon, {
            name: "tags",
            "icon-color": _ctx.selectedTags.length ? '#151830' : '#8A8B97',
            "is-svg": ""
          }, null, 8, ["icon-color"]),
          (_ctx.selectedTags.length === 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('tag')), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.selectedTags.length > 2)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.selectedTags.length) + " " + _toDisplayString(_ctx.$t('contact-tags-selected')), 1),
                      _createVNode(_component_plan_button_icon, {
                        onOnClick: _ctx.removeAllTags,
                        icon: "close",
                        size: "16",
                        type: "transparent-gray-800",
                        style: {"padding":"0.1rem"}
                      }, null, 8, ["onOnClick"])
                    ]))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.selectedTags, (tag, index) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: index,
                        class: "font-bold plan-t-prim-500 mr-2 flex flex-row"
                      }, [
                        _createTextVNode(_toDisplayString(tag) + " ", 1),
                        _createVNode(_component_plan_button_icon, {
                          onOnClick: ($event: any) => (_ctx.removeTag(tag)),
                          icon: "close",
                          size: "16",
                          type: "transparent-gray-800",
                          style: {"padding":"0.1rem"}
                        }, null, 8, ["onOnClick"])
                      ]))
                    }), 128))
              ]))
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["show"]))
}