import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-4"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = {
  key: 4,
  class: "space-y-4"
}
const _hoisted_6 = {
  key: 5,
  class: "space-y-4"
}
const _hoisted_7 = { key: 6 }
const _hoisted_8 = { key: 7 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.status === 'notInWarehouse')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.handleArrivalStatus,
            type: "primary",
            label: _ctx.$t('warehouse-arrival')
          }, null, 8, ["onOnClick", "label"]),
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.updateArrivalLate,
            type: "primary-outline",
            label: _ctx.$t('warehouse-arrival-late')
          }, null, 8, ["onOnClick", "label"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'startMarking')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.handleStartMarking,
            type: "primary",
            label: _ctx.$t('mark-pickup')
          }, null, 8, ["onOnClick", "label"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'endMarking')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.handleEndMarking,
            type: "primary",
            label: _ctx.$t('mark-end-pickup')
          }, null, 8, ["onOnClick", "label"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'uploadDocuments')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.uploadDocuments,
            type: "primary",
            loading: _ctx.loadingDocuments,
            disabled: _ctx.file === null,
            label: _ctx.$t('send')
          }, null, 8, ["onOnClick", "loading", "disabled", "label"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'notifyIncidence')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_plan_button, {
            onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('updateStatus', 'notifyIncidenceForm'))),
            type: "primary",
            label: _ctx.$t('notify-incidence-yes')
          }, null, 8, ["label"]),
          _createVNode(_component_plan_button, {
            onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('updateStatus', 'warehouseOutput'))),
            type: "primary-outline",
            label: _ctx.$t('notify-incidence-no')
          }, null, 8, ["label"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'notifyIncidenceForm')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.sendIncidence,
            type: "primary",
            label: _ctx.$t('send-incidence'),
            loading: _ctx.loadingIncidence,
            disabled: _ctx.incidenceObject.incidence === ''
          }, null, 8, ["onOnClick", "label", "loading", "disabled"]),
          _createVNode(_component_plan_button, {
            onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('updateStatus', 'warehouseOutput'))),
            type: "primary-outline",
            label: _ctx.$t('omit')
          }, null, 8, ["label"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'warehouseOutput')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.handleStopWarehouseDepart,
            type: "primary",
            label: _ctx.$t('warehouse-output'),
            loading: _ctx.loadingDepart
          }, null, 8, ["onOnClick", "label", "loading"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'finished')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_plan_button, {
            onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'CarrierLoadDetails', params: { id: _ctx.$route.params.id } }))),
            type: "primary",
            label: _ctx.$t('ok'),
            loading: _ctx.loadingDepart
          }, null, 8, ["label", "loading"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}