
import { defineComponent, reactive, toRefs } from 'vue';
import PlanDefaultView from '@/components/defaults/PlanDefaultView.vue';
import PlanInputLocation from '@/components/forms/PlanInputLocation.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputDate from '@/components/forms/PlanInputDate.vue';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import PlanInputLoadLocation from '@/components/forms/PlanInputLoadLocation.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanInstantQuoteForm from '@/components/instantQuote/PlanInstantQuoteForm.vue';
import { useStore } from 'vuex';
import PlanLoading from '@/components/spin/PlanLoading.vue';
export default defineComponent({
  name: 'InstantQuote',
  components: { PlanLoading, PlanInstantQuoteForm, PlanIcon, PlanDefaultView },
  setup () {
    const store = useStore()

    const data = reactive({
      loading: false
    })

    store.commit('InstantQuoteStore/cleanStore')

    const getPackageTypes = async () => {
      data.loading = true
      await store.dispatch('LoadsStore/packageTypes')
      data.loading = false
    }

    getPackageTypes()

    return {
      ...toRefs(data)
    }
  }
})
