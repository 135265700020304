import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-5 py-3 justify-between mt-4 lg:flex-row" }
const _hoisted_2 = { class: "w-full flex flex-col md:w-5/12 lg:w-1/5" }
const _hoisted_3 = { class: "w-full flex justify-start items-start lg:w-8/12 lg:justify-end lg:items-end" }
const _hoisted_4 = { class: "flex items-center gap-1 mt-4" }
const _hoisted_5 = { class: "plan-t-prim-50 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_AddCustomer = _resolveComponent("AddCustomer")!
  const _component_PlanLegData = _resolveComponent("PlanLegData")!
  const _component_plan_trailer_and_commodity = _resolveComponent("plan-trailer-and-commodity")!
  const _component_plan_upload_files_cross_border = _resolveComponent("plan-upload-files-cross-border")!
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_plan_input_text, {
          "model-value": _ctx.poNumber,
          "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.poNumber) = $event)),
          label: _ctx.$t('po-number')
        }, null, 8, ["model-value", "label"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AddCustomer, {
          data: _ctx.load?.customers,
          loadId: _ctx.load?.id,
          type: _ctx.type
        }, null, 8, ["data", "loadId", "type"])
      ])
    ]),
    (_ctx.getLoadToSend)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legs, (leg, index) => {
            return (_openBlock(), _createBlock(_component_PlanLegData, {
              ref_for: true,
              ref: (el) => (_ctx.legRefs[index] = el),
              key: index,
              index: index,
              title: leg.title,
              description: leg.description,
              pickUpCountry: leg.pickUpCountry,
              deliveryCountry: leg.deliveryCountry,
              pickUpIsText: leg.pickUpIsText,
              onUpdateDate: _ctx.updateDate
            }, null, 8, ["index", "title", "description", "pickUpCountry", "deliveryCountry", "pickUpIsText", "onUpdateDate"]))
          }), 128))
        ])
      : _createCommentVNode("", true),
    _createVNode(_component_plan_trailer_and_commodity, {
      onChangeCommodityMasters: _ctx.updateCommodityMasters,
      onChangeGeneralObservations: _ctx.updateGeneralObservations,
      onChangeTrailer: _ctx.updateVehicle
    }, null, 8, ["onChangeCommodityMasters", "onChangeGeneralObservations", "onChangeTrailer"]),
    _createVNode(_component_plan_upload_files_cross_border, { isModify: _ctx.isModify }, null, 8, ["isModify"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_plan_input_checkbox, {
        label: _ctx.$t('cargo-insurance-mandatory'),
        preValue: true
      }, null, 8, ["label"]),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('not-nandatory-in-mx')), 1)
    ])
  ], 64))
}