
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import { useI18n } from 'vue-i18n';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanContactTags from '@/components/labels/PlanContactTags.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanFlag from '@/components/icons/PlanFlag.vue';
import ContactsTabFilter from '@/components/filters/ContactsTabFilter.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanHeaderSearch from '@/components/filters/PlanHeaderSearch.vue';
import PlanFilterContactTag from '@/components/filters/PlanFilterContactTag.vue';
import { debounce } from 'lodash';
import PlanContactBanner from '@/components/banner/PlanContactBanner.vue';
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';

interface loadQuotes {
  checkboxes: {
    carrier: boolean,
    broker: boolean,
    trailer: boolean
  },
  firstLoading: boolean,
  loadingContacts: boolean,
  loadSelected: any,
  newCompanies: Array<any>,
  order: {
    value: string,
    direction: string,
  },
  searchQuery: {
    page: number,
    name: string,
    type: string,
    trailerType: number | null,
    trailerTypeStats: number,
    originZip: string,
    destinyZip: string,
    period: string,
    permits: Array<any>,
    country: string,
    tags: any,
    sortBy: string
  },
  selectedCompanies: Array<number | string>,
  showAddContact: boolean,
  showMoreContactsButtons: boolean
}
export default defineComponent({
  name: 'LoadQuotes',
  components: {
    AddCarrierModal,
    PlanContactBanner,
    PlanFilterContactTag,
    PlanHeaderSearch, ContactsTabFilter, PlanIcon, PlanLoading, PlanFlag, PlanButtonIcon, PlanContactTags, PlanAvatar, PlanButton, PlanDefaultTable },
  emits: ['updateSelectedCompanies', 'changeView'],
  setup (props, {emit}) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive<loadQuotes>({
      checkboxes: {
        carrier: true,
        broker: true,
        trailer: false
      },
      firstLoading: false,
      loadingContacts: false,
      loadSelected: {},
      newCompanies: [],
      order: {
        value: 'companyName',
        direction: 'asc',
      },
      searchQuery: {
        page: 1,
        name: '',
        type: 'CARRIER|BROKER',
        trailerType: Number(store.getters['NewFtlLoad/getQuery'].trailer.trailerId),
        trailerTypeStats: Number(store.getters['NewFtlLoad/getQuery'].trailer.trailerId),
        originZip: store.getters['NewFtlLoad/getQuery'].pickup.stopData.postal_code,
        destinyZip: store.getters['NewFtlLoad/getQuery'].delivery.stopData.postal_code,
        period: 'LAST_3_MONTHS',
        permits: [],
        country: '',
        tags: [] as any,
        sortBy: 'companyName',
      },
      selectedCompanies: [] as Array<number | string>,
      showAddContact: false,
      showMoreContactsButtons: true
    })

    onMounted( async () => {
      fetchQuery();
      await getContactList();
    })

    const addCompany = (companyId: number | string) => {
      data.selectedCompanies.push(companyId)
      emit('updateSelectedCompanies', data.selectedCompanies)
    }

    const additionalStops = computed(() => {
      return store.getters['NewFtlLoad/getAdditionalStops']
    })

    const addNewCompany = (newCompany: any) => {
      addCompany(newCompany.id)
      getContactList()
      data.newCompanies.push(newCompany)
    }

    const changeContactsOrder = (orderObject: any) => {
      data.order = orderObject;
      data.searchQuery.sortBy = `${orderObject.value}[${orderObject.direction.toUpperCase()}]`;
      getContactList();
    }

    const changeContactTag = (tag: Array<any>) => {
      data.searchQuery.tags = `[${tag}]`
      getContactList()
    }

    const changePeriod = (newPeriod: any) => {
      data.searchQuery.period = newPeriod
      getContactList()
    }

    const changeSearchName = (newName: string) => {
      data.searchQuery.name = newName
      debouncedGetContactList()
    }

    const changeSearchTypeUser = (userType: string) => {
      if (userType === 'CARRIER') data.checkboxes.carrier = !data.checkboxes.carrier
      if (userType === 'BROKER') data.checkboxes.broker = !data.checkboxes.broker
      if (userType === 'TRAILER') data.checkboxes.trailer = !data.checkboxes.trailer

      let searchQueryType = ''
      if (data.checkboxes.carrier) searchQueryType = searchQueryType + 'CARRIER'
      if (data.checkboxes.broker) searchQueryType = data.checkboxes.carrier ? searchQueryType + '|BROKER' : searchQueryType + 'BROKER'

      data.searchQuery.trailerType = data.checkboxes.trailer ? Number(store.getters['NewFtlLoad/getQuery'].trailer.trailerId) : null
      data.searchQuery.type = searchQueryType

      getContactList();
    }

    const changeSelectedPermits = (permitsSelected: any) => {
      data.searchQuery.permits = permitsSelected;
      getContactList()
    }

    const changeView = (value: string) => {
      emit('changeView', value)
      emit('changeView', 'shipment')
    }

    const contacts = computed(() => {
      return store.getters['UserStore/carrierContactListStatsByLoad'];
    })

    const fetchQuery = () => {
      data.searchQuery = {
        page: 1,
        name: '',
        type: 'CARRIER|BROKER',
        trailerType: Number(store.getters['NewFtlLoad/getQuery'].trailer.trailerId),
        trailerTypeStats: Number(store.getters['NewFtlLoad/getQuery'].trailer.trailerId),
        originZip: store.getters['NewFtlLoad/getQuery'].pickup.stopData.postal_code,
        destinyZip: store.getters['NewFtlLoad/getQuery'].delivery.stopData.postal_code,
        period: 'LAST_3_MONTHS',
        permits: [],
        country: '',
        tags: [] as any,
        sortBy: 'companyName',
      }

      if (store.getters['NewFtlLoad/getType'] === 'MEX_DOMESTIC') {
        data.searchQuery.country = MEXICO.value.id
      } else {
        data.searchQuery.country = USA.value.id
      }
    }

    const getContactList = async () => {
      data.loadingContacts = true;

      if (store.getters['NewFtlLoad/getType'] === 'MEX_DOMESTIC') {
        data.searchQuery.country = MEXICO.value.id
      } else {
        data.searchQuery.country = USA.value.id
      }

      if (data.searchQuery.tags && data.searchQuery.tags.length === 0) {
        data.searchQuery.tags = undefined;
      }

      await store.dispatch('UserStore/carrierContactListStatsByLoad', data.searchQuery)
        .then((response) => {
          if (response.length === 0) data.showMoreContactsButtons = false
          if (!data.firstLoading) {
            if (response.length > 0 && store.getters['NewFtlLoad/getType'] !== 'MEX_DOMESTIC') addCompany(response[0].id)
            data.firstLoading = true
          }
        })
        .finally(() => {
          data.loadingContacts = false;
      })
    }

    const headers = computed(() => {
      return [
        { label: translate.t('company'), value: 'companyName', sortable: true },
        { label: translate.t('rating'), value: 'rating', sortable: true },
        { label: translate.t('rol'), value: 'rol', sortable: false },
        { label: translate.t('loads'), value: 'loadsQuantity', sortable: true },
        { label: '', value: 'actions' },
      ];
    })

    const loadMoreContacts = () => {
      data.searchQuery.page = data.searchQuery.page + 1;
      getContactList();
    }

    const MEXICO = computed(() => {
      return store.getters['CountryStore/getCountries'].find((country: any) => country.name === 'Mexico');
    })

    const openChat = (user: any) => {
      store.commit('GlobalStore/setOpenChat', false);
      setTimeout(() => {
        store.commit('ChatsStore/setUserForChat', user);
        store.commit('ChatsStore/setChatId', user.chat_id ? user.chat_id : null);
        store.commit('GlobalStore/setOpenChat', true);
      }, 500);
    }

    const removeCompany = (companyId: number | string) => {
      const index = data.selectedCompanies.indexOf(companyId);
      if (index !== -1) {
        data.selectedCompanies.splice(index, 1);
      }
      emit('updateSelectedCompanies', data.selectedCompanies);
    }

    const trailerLabel = computed(() => {
      let result = ''
      store.getters['LoadsStore/getTrailerTypes'].forEach((vehicle: any) => {
        if (vehicle.id === data.searchQuery.trailerTypeStats) result = vehicle.description
      })
      return result
    })

    const USA = computed(() => {
      return store.getters['CountryStore/getCountries'].find((country: any) => country.name === 'United States')
    })

    const debouncedGetContactList = debounce(getContactList, 500)

    return {
      ...toRefs(data),
      addCompany,
      additionalStops,
      addNewCompany,
      changeContactsOrder,
      changeContactTag,
      changePeriod,
      changeSearchName,
      changeSearchTypeUser,
      changeSelectedPermits,
      changeView,
      contacts,
      headers,
      loadMoreContacts,
      openChat,
      removeCompany,
      trailerLabel
    }
  }
})
