
import { computed, defineComponent, onBeforeMount, reactive, ref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import PlanIcon from '@/components/icons/PlanIcon.vue';
export default defineComponent({
  name: 'SharedDocuments',

  components: { PlanIcon },
  props: {
    code: { type: String, required: false, default: null },
  },

  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();

    const fetchedImageOriginal = ref(null as any);

    const loginUser = () => {
      store
        .dispatch('LoadsStore/getSharedDocuments', {
          code: props.code,
        })

        .then((response) => {
          fetchedImageOriginal.value = document.createElement('a');
          fetchedImageOriginal.value.href = URL.createObjectURL(response);
          fetchedImageOriginal.value.download = `documents-${''}`;
          fetchedImageOriginal.value.click();
          data.loading = false;
        });
    };

    loginUser();

    const data = reactive({
      loading: true,
    });

    return {
      ...toRefs(data),
      translate,
    };
  },
});
