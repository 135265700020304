import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, vShow as _vShow, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "plan-mt-md w-full flex flex-col" }
const _hoisted_2 = {
  key: 2,
  class: "w-full flex flex-col plan-gap-md"
}
const _hoisted_3 = { class: "w-full flex flex-col rounded border plan-border-prim-50 plan-p-sm" }
const _hoisted_4 = { class: "flex justify-between items-center" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "plan-t-prim-100" }
const _hoisted_7 = { class: "flex justify-between items-end" }
const _hoisted_8 = { class: "plan-t-prim-100" }
const _hoisted_9 = { class: "flex justify-start items-center plan-gap-md" }
const _hoisted_10 = { class: "flex items-center plan-gap-sm pt-8" }
const _hoisted_11 = {
  key: 0,
  class: "flex items-center plan-gap-sm pt-8"
}
const _hoisted_12 = {
  key: 1,
  class: "flex justify-center items-center plan-gap-sm"
}
const _hoisted_13 = { class: "flex flex-col plan-gap-sm" }
const _hoisted_14 = {
  key: 0,
  class: "w-full flex justify-between items-center",
  style: {"height":"42px"}
}
const _hoisted_15 = { class: "w-full flex flex-col py-2 max-w-[200px]" }
const _hoisted_16 = ["id"]
const _hoisted_17 = {
  key: 0,
  class: "plan-t-prim-100"
}
const _hoisted_18 = {
  key: 1,
  class: "plan-t-prim-100"
}
const _hoisted_19 = {
  class: "w-full flex flex-col plan-gap-sm overflow-y-auto",
  style: {"max-height":"250px"}
}
const _hoisted_20 = ["id", "onClick"]
const _hoisted_21 = { class: "font-bold" }
const _hoisted_22 = { class: "plan-t-prim-100" }
const _hoisted_23 = { class: "w-full flex justify-start plan-p-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_warehouse_modal = _resolveComponent("add-warehouse-modal")!
  const _component_update_warehouse_modal = _resolveComponent("update-warehouse-modal")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_plan_button_group = _resolveComponent("plan-button-group")!
  const _component_plan_input_hour = _resolveComponent("plan-input-hour")!
  const _component_plan_input_number_formatted = _resolveComponent("plan-input-number-formatted")!
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showAddWarehouse)
      ? (_openBlock(), _createBlock(_component_add_warehouse_modal, {
          key: 0,
          "default-zip": _ctx.zip,
          "default-country": _ctx.defaultCountry,
          "default-prefix": _ctx.defaultPrefix,
          "default-zipcode-id": _ctx.defaultZipcodeId,
          onlyCountry: _ctx.onlyCountry,
          excludeCountry: _ctx.excludeCountry,
          onCloseAndUpdate: _ctx.closeAndAddWarehouse,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddWarehouse = false))
        }, null, 8, ["default-zip", "default-country", "default-prefix", "default-zipcode-id", "onlyCountry", "excludeCountry", "onCloseAndUpdate"]))
      : _createCommentVNode("", true),
    (_ctx.showUpdateWarehouse)
      ? (_openBlock(), _createBlock(_component_update_warehouse_modal, {
          key: 1,
          onlyCountry: _ctx.onlyCountry,
          excludeCountry: _ctx.excludeCountry,
          onCloseAndUpdate: _ctx.closeAndUpdateWarehouse,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showUpdateWarehouse = false))
        }, null, 8, ["onlyCountry", "excludeCountry", "onCloseAndUpdate"]))
      : _createCommentVNode("", true),
    (_ctx.selectedWarehouse !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.selectedWarehouse.companyWarehouse), 1),
              _createVNode(_component_plan_button_icon, {
                onOnClick: _ctx.unSelectWarehouses,
                icon: "close"
              }, null, 8, ["onOnClick"])
            ]),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.selectedWarehouse.location?.zip) + " " + _toDisplayString(_ctx.selectedWarehouse.location?.city) + " " + _toDisplayString(_ctx.selectedWarehouse.location?.state?.iso) + " " + _toDisplayString(_ctx.selectedWarehouse.location?.state?.country?.iso), 1),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('contact-person')) + ": " + _toDisplayString(_ctx.selectedWarehouse.contactName) + " +" + _toDisplayString(_ctx.selectedWarehouse.phonePrefix) + " " + _toDisplayString(_ctx.selectedWarehouse.phoneNumber), 1),
              _createElementVNode("span", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showUpdateWarehouseModal(_ctx.selectedWarehouse))),
                class: "cursor-pointer plan-text-xs"
              }, _toDisplayString(_ctx.$t('update-info')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_plan_input_checkbox, {
              onChange: _ctx.changeAirportValue,
              "pre-value": _ctx.warehouseForm.airport,
              label: _ctx.$t('airport'),
              icon: "airplane"
            }, null, 8, ["onChange", "pre-value", "label"]),
            _createVNode(_component_plan_input_checkbox, {
              onChange: _ctx.changePortValue,
              "pre-value": _ctx.warehouseForm.port,
              label: _ctx.$t('port'),
              icon: "ship"
            }, null, 8, ["onChange", "pre-value", "label"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_plan_button_group, {
                onChange: _ctx.changeAppointments,
                "active-button-index": _ctx.selectedAppointment,
                type: "primary",
                buttons: _ctx.appointments
              }, null, 8, ["onChange", "active-button-index", "buttons"])
            ]),
            (_ctx.warehouseForm.appointment !== 'NONE')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_ctx.warehouseForm.appointment === 'FCFS' || _ctx.warehouseForm.appointment === 'APPT')
                    ? (_openBlock(), _createBlock(_component_plan_input_hour, {
                        key: 0,
                        onChange: _ctx.changeFromHour,
                        "pre-value": _ctx.warehouseForm.from
                      }, null, 8, ["onChange", "pre-value"]))
                    : _createCommentVNode("", true),
                  (_ctx.warehouseForm.appointment === 'FCFS')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('to')), 1),
                        (_ctx.warehouseForm.appointment === 'FCFS')
                          ? (_openBlock(), _createBlock(_component_plan_input_hour, {
                              key: 0,
                              onChange: _ctx.changeToHour,
                              "min-value": _ctx.warehouseForm.from,
                              "pre-value": _ctx.warehouseForm.to
                            }, null, 8, ["onChange", "min-value", "pre-value"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_13, [
            (!_ctx.isEuropeCompany() && !_ctx.isConsignee)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  (!_ctx.isEuropeCompany())
                    ? (_openBlock(), _createBlock(_component_plan_input_checkbox, {
                        key: 0,
                        onChange: _ctx.changeLumperFee,
                        preValue: _ctx.warehouseForm.lumperFee,
                        label: _ctx.$t('lumper-fee'),
                        style: {"min-width":"150px"}
                      }, null, 8, ["onChange", "preValue", "label"]))
                    : _createCommentVNode("", true),
                  (_ctx.warehouseForm.lumperFee)
                    ? (_openBlock(), _createBlock(_component_plan_input_number_formatted, {
                        key: 1,
                        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectWarehouse(_ctx.selectedWarehouse))),
                        placeholder: "0",
                        style: {"width":"150px"},
                        "model-value": _ctx.warehouseForm.lumperFeePrice,
                        "onUpdate:model-value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.warehouseForm.lumperFeePrice) = $event))
                      }, null, 8, ["model-value"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_plan_input_text, {
                onChange: _ctx.changePoNumber,
                "model-value": _ctx.warehouseForm.poNumber,
                "onUpdate:model-value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.warehouseForm.poNumber) = $event)),
                id: 'poNumber' + _ctx.index,
                name: 'poNumber' + _ctx.index,
                label: _ctx.$t('label.reference') + '#'
              }, null, 8, ["onChange", "model-value", "id", "name", "label"])
            ]),
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.warehouseForm.observations) = $event)),
              onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.changeObservations && _ctx.changeObservations(...args))),
              placeholder: "Observations",
              class: "plan-form-simple",
              rows: "4"
            }, null, 544), [
              [_vModelText, _ctx.warehouseForm.observations]
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loadingWarehouses && _ctx.zip !== '' && _ctx.warehouses.length === 0)
      ? (_openBlock(), _createBlock(_component_plan_text_button, {
          key: 3,
          onOnClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showAddWarehouse = true)),
          label: _ctx.$t('include-warehouse-information')
        }, null, 8, ["label"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_popper, {
      show: _ctx.showSelectItems,
      interactive: "",
      id: _ctx.id,
      offsetSkid: "0",
      offsetDistance: "1",
      placement: "bottom-end",
      class: "plan-select-warehouse-popper"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", {
          class: "flex flex-col",
          style: _normalizeStyle(`min-width: 400px; width: ${_ctx.selectWidth}px; max-width: ${_ctx.selectWidth}px;`)
        }, [
          _createElementVNode("div", _hoisted_19, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warehouses, (warehouse) => {
              return (_openBlock(), _createElementBlock("div", {
                id: 'warehouse' + _ctx.index,
                key: warehouse.id,
                onClick: ($event: any) => (_ctx.selectWarehouse(warehouse)),
                class: "flex justify-start items-center plan-gap-xs plan-p-sm cursor-pointer",
                style: {"border-bottom":"1px solid #e5e7eb"}
              }, [
                _createElementVNode("span", _hoisted_21, _toDisplayString(warehouse.companyWarehouse), 1),
                _createElementVNode("span", _hoisted_22, _toDisplayString(warehouse.location?.zip) + " " + _toDisplayString(warehouse.location?.city), 1)
              ], 8, _hoisted_20))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_plan_text_button, {
              onOnClick: _ctx.showAddWarehouseModal,
              icon: "plus",
              label: _ctx.$t('add-new-warehouse')
            }, null, 8, ["onOnClick", "label"])
          ])
        ], 4)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          id: 'showWarehouses' + _ctx.index,
          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.showWarehouses && _ctx.showWarehouses(...args))),
          class: "plan-div-form w-full flex justify-between items-center plan-p-sm"
        }, [
          (_ctx.loadingWarehouses)
            ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t('loading-warehouses')), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t('select-warehouse')), 1)),
          (_ctx.loadingWarehouses)
            ? (_openBlock(), _createBlock(_component_plan_icon, {
                key: 2,
                name: "spinner",
                "icon-color": "#8A8B97",
                "is-svg": "",
                rotate: ""
              }))
            : (_openBlock(), _createBlock(_component_plan_icon, {
                key: 3,
                name: "chevron-down",
                "is-svg": "",
                "icon-color": "black"
              }))
        ], 8, _hoisted_16)
      ]),
      _: 1
    }, 8, ["show", "id"]), [
      [_vShow, _ctx.selectedWarehouse === null && _ctx.zip !== '' && _ctx.warehouses.length > 0]
    ])
  ]))
}