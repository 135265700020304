
import { defineComponent } from 'vue';
import { Field, ErrorMessage } from 'vee-validate';
import { XCircleIcon } from '@heroicons/vue/24/solid';
// import TimePicker from 'vue3-timepicker';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import PlanCountrySelector from '@/components/forms/PlanCountrySelector.vue';
import PlanInputTime from '@/components/forms/PlanInputTime.vue';
import PlanModalLocation from '@/components/modals/PlanModalLocation.vue';
import WarehouseComponent from '@/components/warehouses/WarehouseComponent.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins';
// Types
import { WarehouseFillModes } from '@/components/ComponentsTypes';
// Utils
import { objectLocationToAddress } from '@/utils/formatLocation';
import { formatTimeToObject, hourRange, minutesRange } from '@/utils/formatTime';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import WarehouseInStopData from '@/modules/planning/newLoad/_components/form/WarehouseInStopData.vue';
import PlanInputLoadLocation from '@/components/forms/PlanInputLoadLocation.vue';

export default defineComponent({
  name: 'StopData',
  components: {
    PlanInputLoadLocation,
    WarehouseInStopData,
    DatePickerComponent,
    ErrorMessage,
    Field,
    GoogleAutocomplete,
    // TimePicker,
    PlanCountrySelector,
    PlanModalLocation,
    XCircleIcon,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    blockCountry: { default: false, type: Boolean },
    finalDate: String as any,
    index: Number,
    initialDate: String as any,
    isConsignee: { default: false, type: Boolean },
    isRFPSidebar: Boolean as any,
    hideInputDates: { default: false, type: Boolean },
    labelLocation: String,
    minDatePickUp: Boolean,
    pickUpIsText: String,
    showTime: { default: true, type: Boolean },
    fillLocation: { default: false, type: Boolean },
    customLabel: { type: String, required: false },
    stop: Object as any,
    warehousesAllowed: {
      type: Boolean,
      required: true,
    },
    onlyCountry: { type: String, required: false, default: '' },
  },
  data() {
    return {
      airportStop: null as any,
      companyName: '',
      countrySelected: null as any,
      showCoordinates: false,
      address: '',
      defaultCountrySelected: null as any,
      phoneNumber: '',
      phonePrefix: '',
      poNumber: '',
      executionDate: null as any,
      executionWindowEndTime: null as any,
      executionWindowStartTime: null as any,
      isPopperVisible: false,
      locationText: '',
      loading: false,
      lumperFee: false,
      lumperFeePrice: null as any,
      observations: '',
      portStop: null as any,
      prefixFilterByIso: '',
      selectedAppointment: 'NONE',
      showModalCoordinates: false,
      warehousesItems: [],
      warehouseFillMode: '',
      zipSelected: '',
      seePlaceHolder: true,
      selectedWarehouse: -1,
      selectedZipcodeId: '',
    };
  },
  beforeMount() {
    this.defaultCountrySelected = this.stop?.location?.state?.country || this.currentCountry;
  },
  mounted() {
    if ((this.$route.name === 'addLanesRFP' && this.isRFPSidebar) || this.fillLocation) {
      if (this.stop?.location?.zip || this.stop?.location?.city) {
        this.defaultCountrySelected = this.stop?.location?.state?.country || this.currentCountry;
        if (this.currentRoute !== 'planificationDetail') {
          this.setStopData(this.stop);
        }
      }
    }
  },
  computed: {
    currentCountryIso(): any {
      if (this.$store.getters['LoadsStore/currentLoad'].type === 'MEX_DOMESTIC') return 'mx';
      return this.defaultCountrySelected
        ? this.defaultCountrySelected?.iso
        : this.countriesList?.find((country) => country?.id === this.currentCountry?.id);
    },
    countriesList(): any {
      return this.$store.getters['CountryStore/getCountries'];
    },
    countriesListCompany(): any {
      return this.countriesList?.filter(
        (country) =>
          country?.region?.name.includes(this.defaultCountrySelected?.region?.name) &&
          this.defaultCountrySelected !== country,
      );
    },
    userGoNextStep(): any {
      return this.$store.getters['LoadsStore/getShipmentNextStep'];
    },
    currentCountry(): any {
      return this.$store.getters['CountryStore/getCurrentCountry'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentRoute(): any {
      return this.$route.name;
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    getCountries(): any {
      return this.$store.getters['CountryStore/getCountries'];
    },
    hourRange(): any {
      return hourRange;
    },
    maxDate(): any {
      if (this.stop.type !== 'DELIVERY_FINAL' && this.stop.type !== 'PICKUP_INITIAL') {
        if (this.currentUser?.company?.country?.region?.name === 'EUROPE') {
          return this.finalDate;
        } else {
          return this.formatDate(this.finalDate);
        }
      }
      return null;
    },
    minDate(): any {
      if (this.stop.type !== 'PICKUP_INITIAL' || this.minDatePickUp) {
        return this.formatDate(this.initialDate) ?? null;
      }
      return null;
    },
    minutesRange(): any {
      return minutesRange;
    },
    seePlaceHolderComputed(): any {
      return this.seePlaceHolder && this.observations === '';
    },
    selectedCountry() {
      return this.countrySelected ? this.countrySelected : this.defaultCountrySelected;
    },
    typeStopObject(): any {
      let objectTypes = null as any;
      switch (this.stop.type) {
        case 'PICKUP_INITIAL':
          objectTypes = {
            label: 'pick-up-zip',
            placehoder: 'placeholder-enter-your-origin-zip',
          };
          break;
        case 'DELIVERY_FINAL':
          objectTypes = {
            label: 'deli-zip',
            placehoder: 'placeholder-enter-your-destination-zip',
          };
          break;
        default:
          objectTypes = {
            label: 'city-zip',
            placehoder: 'placeholder-enter-your-stop-zip',
          };
          break;
      }
      return objectTypes;
    },
    warehouseData(): any {
      return {
        airport: this.airportStop,
        port: this.portStop,
        lumperFee: this.lumperFee,
        lumperFeePrice: this.lumperFeePrice,
        observations: this.observations,
        selectedAppointment: this.selectedAppointment,
        startTime: this.executionWindowStartTime,
        endTime: this.executionWindowEndTime,
        poNumber: this.poNumber,
      };
    },
    warehouseItems(): any {
      return this.$store.getters['LoadsStore/warehousesItems'];
    },
  },
  methods: {
    handleDeleteWarehouseSelected() {
      const refName = `warehouseStopDataRef${this.index}`;
      const warehouseStopDataRef = this.$refs[refName] as any;
      if (warehouseStopDataRef && warehouseStopDataRef.unSelectWarehouses) {
        warehouseStopDataRef.unSelectWarehouses();
      }
    },
    clearInput() {
      this.warehousesItems = [];
      this.zipSelected = '';
      this.warehouseFillMode = '';
      this.locationText = '';
    },
    setLocation(value, latitude, longitude, comesFromCoordenates) {
      this.showCoordinates = comesFromCoordenates;
      let location = (this.$refs['location' + this.index] as any).formatResult(value);
      location.latitude = Number(latitude);
      location.longitude = Number(longitude);
      this.getLocationFormated(location, null, null, null);
      this.findWarehouse(this.stop?.location?.zip, this.stop?.location?.state?.isoGoogle, null);
    },
    openAddFacilityModal() {
      (this.$refs['warehouseStopDataRef' + this.index] as any).showAddWarehouseModal();
    },
    findIsoInCountrys(iso) {
      return this.getCountries.find((country) => country.iso === iso);
    },
    findWarehouse(postalcode, ISO, warehouseTemplateId) {
      this.selectedWarehouse = warehouseTemplateId;
      if (!this.userGoNextStep) {
        this.loading = true;
        this.warehousesItems = [];
        this.zipSelected = '';
        this.warehouseFillMode = '';
        this.prefixFilterByIso = this.findIsoInCountrys(ISO);

        this.$store
          .dispatch('LoadsStore/getWarehouses', { zip: postalcode, name: '' })
          .then((response) => {
            if (response.length > 0) {
              this.warehouseFillMode = WarehouseFillModes.OVER_ONE;
              this.warehousesItems = response;
              this.zipSelected = postalcode;
            }
            if (response.length === 0) {
              this.warehousesItems = response;
              this.zipSelected = postalcode;
              this.warehouseFillMode = WarehouseFillModes.DEFAULT;
            }
            if (response.length === 1 || warehouseTemplateId > 0) {
              this.warehousesItems = response;
              this.zipSelected = postalcode;
              this.warehouseFillMode = WarehouseFillModes.FILLED;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getAppoimentType(stop) {
      let type = 'NONE';
      if (stop.executionWindowStartTime) {
        type = 'APPT';
        if (stop.executionWindowStartTime && stop.executionWindowEndTime) {
          type = 'FCFS';
        }
      }
      return type;
    },
    getLocationFormated(addressData, place = null, id, index = null) {
      if (addressData.country) {
        const countryObject = this.findIsoInCountrys(addressData.country.toUpperCase());
        this.countrySelected = countryObject?.iso ? countryObject : null;
        this.defaultCountrySelected = countryObject?.iso ? countryObject : null;
      }

      if (addressData.place_id) {
        this.$emit('setStopLocation', this.stop, addressData, this.index);
        this.$emit('country', this.defaultCountrySelected, this.stop);
      }
    },
    getTextareaClass() {
      const locale = this.getLocale.substring(0, 2);
      if (locale === 'es') {
        return 'textareaES';
      }
      return 'textarea';
    },
    handleClick(country) {
      this.defaultCountrySelected = country;
      this.prefixFilterByIso = this.findIsoInCountrys(country.iso);
      this.$emit('country', this.defaultCountrySelected, this.stop);
    },
    handleSvgClick(type) {
      if (type === 'port' && this.portStop) {
        this.portStop = false;
      } else if (type === 'airport' && this.airportStop) {
        this.airportStop = false;
      } else {
        if (type === 'port') {
          this.setupAirportorPort('port');
        }
        if (type === 'airport') {
          this.setupAirportorPort('airport');
        }
      }
    },
    objectLocationToAddress,
    onFocus() {
      this.seePlaceHolder = false;
    },
    onBlur() {
      if (this.observations.length === 0) {
        this.seePlaceHolder = true;
      }
    },
    setDate(value) {
      this.$emit('checkDate', this.stop, value);
    },
    setStopData(stop) {
      if (this.currentRoute !== 'planificationDetail' && stop?.location?.state?.id !== undefined) {
        stop.location.state = this.states.find((state) => state.id === stop?.location?.state?.id);
      }
      this.airportStop = stop.airportStop;
      this.executionDate = stop.executionDate;
      this.executionWindowEndTime = stop.executionWindowEndTime
        ? stop.executionWindowEndTime
        : null;
      this.executionWindowStartTime = stop.executionWindowStartTime
        ? stop.executionWindowStartTime
        : null;
      this.locationText = this.objectLocationToAddress(stop.location);
      this.lumperFee = stop.lumperFee;
      this.lumperFeePrice = stop.lumperFeePrice;
      this.observations = stop.observations;
      this.poNumber = stop.poNumber;
      this.portStop = stop.portStop;
      this.selectedAppointment = this.getAppoimentType(stop);
      // Find Warehouses
      this.findWarehouse(
        stop?.location?.zip ? stop?.location?.zip : stop?.location?.city,
        stop?.location?.state?.isoGoogle,
        stop?.warehouseTemplate?.id,
      );
    },
    setupAirportorPort(type) {
      if (type === 'airport') {
        this.airportStop = true;
        this.portStop = false;
        this.$emit('portOrAirport', 'airport', this.stop, this.index);
      } else {
        this.airportStop = false;
        this.portStop = true;
        this.$emit('portOrAirport', 'port', this.stop, this.index);
      }
    },
    setupWarehouse(
      address,
      companyName,
      lumperFee,
      lumperFeePrice,
      observations,
      poNumber,
      phoneNumber,
      phonePrefix,
      contactName,
      warehouseId,
      executionWindowStartTime,
      executionWindowEndTime,
    ) {
      this.$emit(
        'updateWarehouse',
        this.stop,
        address,
        companyName,
        lumperFee,
        lumperFeePrice,
        observations,
        poNumber,
        phoneNumber,
        phonePrefix,
        contactName,
        warehouseId,
        executionWindowStartTime,
        executionWindowEndTime,
      );
    },
    toggleModalCoordinates(bool) {
      this.showModalCoordinates = bool;
    },
    togglePopper() {
      this.isPopperVisible = !this.isPopperVisible;
    },
    updateDate(date) {
      this.executionDate = date;
    },
    updateHour() {
      this.$emit(
        'updateHour',
        this.stop,
        this.executionWindowStartTime,
        this.executionWindowEndTime,
        this.selectedAppointment,
      );
    },
    updateObservations(event) {
      this.$emit('updateObservations', event, this.stop);
      const innerText = event.target.innerText;
      this.observations = innerText;
    },
    updateSelectedAppointment(value) {
      this.selectedAppointment = value;
    },
    updateSelectedTime(item) {
      if (item.mode === 'end') {
        this.executionWindowEndTime = item.type;
      } else {
        this.executionWindowStartTime = item.type;
      }
      this.updateHour();
    },
    updateWarehouse() {
      this.$emit(
        'updateWarehouse',
        this.address,
        this.companyName,
        this.lumperFee,
        this.lumperFeePrice,
        this.observations,
        this.poNumber,
        this.phoneNumber,
        this.stop,
      );
    },
  },
  watch: {
    currentCountry: function (newVal, oldVal) {
      if (this.currentRoute !== 'planificationDetail') {
        this.defaultCountrySelected = this.stop?.location?.state?.country || this.currentCountry;
      }
    },
    stop: function (newVal, oldVal) {
      if (
        this.currentRoute !== 'planificationDetail' &&
        this.stop?.location?.state?.id !== undefined
      ) {
        this.defaultCountrySelected = this.stop?.location?.state?.country || this.currentCountry;
        console.log('?????????');
        this.setStopData(newVal);
      }
    },
  },
});
