
import { computed, defineComponent, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'PlanCarrierTrackingGoogleMap',
  props: {
    lat: { type: Number, required: false, default: -25.363 },
    lon: { type: Number, required: false, default: 131.044 },
    height: { type: Number, required: false, default: 200 },
    centerInPickUp: { type: Boolean, required: false, default: false },
  },

  setup(props) {
    const store = useStore();

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const actualPositionIcon = require('@/resources/assets/icons/marker-origin.svg');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const lastPositionIcon = require('@/resources/assets/icons/marker-truck.png');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    let originIcon = require('@/resources/assets/icons/marker-pickup-initial.svg');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    let deliveryIcon = require('@/resources/assets/icons/marker-delivery-final.svg');

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      preserveViewport: true,
    });

    let map: google.maps.Map | null = null;
    // TODO REFACTOR MARKERS -> AdvancedMarkerElement
    let markers: google.maps.Marker[] = [];

    const currentLoad = computed(() => {
      let latDelivery = 0;
      let lonDelivery = 0;
      let latPickup = 0;
      let lonPickup = 0;

      store.getters['CarrierStore/getLoadData'].stops.forEach((stopData: any) => {
        if (stopData.type === 'PICKUP_INITIAL') {
          latPickup = stopData.location.lat;
          lonPickup = stopData.location.long;
        } else if (stopData.type === 'DELIVERY_FINAL') {
          latDelivery = stopData.location.lat;
          lonDelivery = stopData.location.long;
        }
      });

      return {
        pickup: {
          lat: latPickup,
          lon: lonPickup,
        },
        delivery: {
          lat: latDelivery,
          lon: lonDelivery,
        },
      };
    });
    const styles = [
      { featureType: 'all', elementType: 'labels.text.fill', stylers: [{ color: '#ffffff' }] },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#000000' }, { lightness: 13 }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{ color: '#000000' }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#144b53' }, { lightness: 14 }, { weight: 1.4 }],
      },
      { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#08304b' }] },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#0c4152' }, { lightness: 5 }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{ color: '#000000' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#0b434f' }, { lightness: 25 }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [{ color: '#000000' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#0b3d51' }, { lightness: 16 }],
      },
      { featureType: 'road.local', elementType: 'geometry', stylers: [{ color: '#000000' }] },
      { featureType: 'transit', elementType: 'all', stylers: [{ color: '#146474' }] },
      { featureType: 'water', elementType: 'all', stylers: [{ color: '#021019' }] },
    ];

    // const styles = [
    //   { featureType: 'all', elementType: 'labels.text.fill', stylers: [{ color: '#ffffff' }] },
    //   {
    //     featureType: 'all',
    //     elementType: 'labels.text.stroke',
    //     stylers: [{ color: '#000000' }, { lightness: 13 }],
    //   },
    // ];

    const getTrackingHistory = async () => {
      await store
        .dispatch(
          'LoadsStore/getTrackingHistory',
          store.getters['CarrierStore/getLoadData'].shipment?.id,
        )
        .then((response) => {
          store.commit('CarrierStore/setTrackingHistory', response);
          initMap();
        });
    };

    const actualPosition = computed(() => {
      if (
        store.getters['CarrierStore/getActualPosition'].lat !== null &&
        store.getters['CarrierStore/getActualPosition'].lng !== null
      ) {
        return {
          lat: store.getters['CarrierStore/getActualPosition'].lat,
          lon: store.getters['CarrierStore/getActualPosition'].lng,
        };
      }
      return null;
    });

    const lastPosition = computed(() => {
      if (store.getters['CarrierStore/getTrackingHistory'].length > 0) {
        return {
          lat: store.getters['CarrierStore/getTrackingHistory'][0].location.lat,
          lon: store.getters['CarrierStore/getTrackingHistory'][0].location.long,
        };
      }
      return null;
    });

    getTrackingHistory();

    function initMap() {
      if (map) return;
      let centerPosition = { lat: props.lat, lng: props.lon };

      if (props.centerInPickUp) {
        centerPosition = { lat: currentLoad.value.pickup.lat, lng: currentLoad.value.pickup.lon };
      }

      if (actualPosition.value !== null) {
        centerPosition = { lat: actualPosition.value?.lat, lng: actualPosition.value?.lon };
      }

      if (lastPosition.value !== null) {
        centerPosition = { lat: lastPosition.value?.lat, lng: lastPosition.value?.lon };
      }

      map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        zoom: 7,
        center: centerPosition,
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'cooperative',
        restriction: {
          latLngBounds: {
            north: 85,
            south: -85,
            west: -179.9999,
            east: 179.9999,
          },
          strictBounds: true,
        },
        styles: styles,
      });

      //Added Bound for map zoom depending of the route distance
      map.fitBounds(new google.maps.LatLngBounds());
      directionsRenderer.setMap(map);

      updateMapData();
    }

    function updateMapData() {
      if (!map) return;

      markers.forEach((marker) => marker.setMap(null));
      markers = [];

      const bounds = new google.maps.LatLngBounds();

      const addMarker = (position: google.maps.LatLngLiteral, icon: string) => {
        const marker = new google.maps.Marker({
          position,
          map: map!,
          icon: {
            url: icon,
            scaledSize: new google.maps.Size(30, 30),
          },
        });
        markers.push(marker);
        bounds.extend(position);
      };

      const pickupPosition = {
        lat: currentLoad.value.pickup.lat,
        lng: currentLoad.value.pickup.lon,
      };
      addMarker(pickupPosition, originIcon);

      const deliveryPosition = {
        lat: currentLoad.value.delivery.lat,
        lng: currentLoad.value.delivery.lon,
      };
      addMarker(deliveryPosition, deliveryIcon);

      if (lastPosition.value !== null) {
        const lastPos = { lat: lastPosition.value.lat, lng: lastPosition.value.lon };
        addMarker(lastPos, lastPositionIcon);
      }

      map.fitBounds(bounds);
      directionsRenderer.setMap(map);

      if (currentLoad.value.pickup.lat && currentLoad.value.delivery.lat) {
        const request = {
          origin: new google.maps.LatLng(
            currentLoad.value.pickup.lat,
            currentLoad.value.pickup.lon,
          ),
          destination: new google.maps.LatLng(
            currentLoad.value.delivery.lat,
            currentLoad.value.delivery.lon,
          ),
          waypoints: lastPosition.value
            ? [
                {
                  location: new google.maps.LatLng(lastPosition.value.lat, lastPosition.value.lon),
                  stopover: true,
                },
              ]
            : [],
          travelMode: google.maps.TravelMode.DRIVING,
        };

        directionsService.route(request, (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          }
        });
      }
    }

    onMounted(() => {
      if (typeof google !== 'undefined' && google.maps) {
        initMap();
        updateMapData();
      } else {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCiii4tHhdactsEZuTbFY9C9eY6OsYa8WQ`;
        script.async = true;
        script.onload = () => {
          initMap();
          updateMapData();
        };
        document.head.appendChild(script);
      }
    });

    watch([() => props.lat, () => props.lon], () => {
      initMap();
    });

    watch(() => store.getters['CarrierStore/getTrackingHistory'], updateMapData);
    watch(() => store.getters['CarrierStore/getActualPosition'], updateMapData);

    return {
      currentLoad,
      initMap,
    };
  },
});
