import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center mt-4"
}
const _hoisted_2 = { class: "pl-6" }
const _hoisted_3 = { class: "flex items-center mb-4" }
const _hoisted_4 = {
  for: "teamDriver-checkbox",
  class: "ml-2"
}
const _hoisted_5 = {
  key: 0,
  class: "flex items-center mb-4"
}
const _hoisted_6 = {
  for: "bondedPermission-checkbox",
  class: "ml-2"
}
const _hoisted_7 = {
  key: 1,
  class: "flex items-center mb-4"
}
const _hoisted_8 = {
  for: "tsa-checkbox",
  class: "ml-2"
}
const _hoisted_9 = {
  key: 2,
  class: "flex items-center mb-4"
}
const _hoisted_10 = {
  for: "twic",
  class: "ml-2"
}
const _hoisted_11 = { class: "flex items-center mb-4" }
const _hoisted_12 = {
  for: "hazardousLoad-checkbox",
  class: "ml-2"
}
const _hoisted_13 = {
  key: 3,
  class: "flex items-center mb-4"
}
const _hoisted_14 = {
  for: "tir-checkbox",
  class: "ml-2"
}
const _hoisted_15 = {
  key: 4,
  class: "flex items-center mb-4"
}
const _hoisted_16 = {
  for: "oversize-checkbox",
  class: "ml-2"
}
const _hoisted_17 = {
  key: 5,
  class: "flex items-center mb-4"
}
const _hoisted_18 = {
  for: "overweight-checkbox",
  class: "ml-2"
}
const _hoisted_19 = { class: "flex items-center mb-4" }
const _hoisted_20 = {
  for: "special-permits",
  class: "ml-2"
}
const _hoisted_21 = {
  key: 6,
  class: "flex flex-col w-auto md:w-5/12 ml-6"
}
const _hoisted_22 = { class: "plan-mt-xl w-full flex justify-end items-center plan-gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_PlanDefaultModal = _resolveComponent("PlanDefaultModal")!

  return (_ctx.showModal)
    ? (_openBlock(), _createBlock(_component_PlanDefaultModal, {
        key: 0,
        size: "sm-auto",
        "hide-close-button": "",
        title: _ctx.$t('special-requirements'),
        onClose: _ctx.close
      }, {
        content: _withCtx(() => [
          (_ctx.permisions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.permisions.teamDriver) = $event)),
                      id: "teamDriver-checkbox",
                      type: "checkbox",
                      class: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.permisions.teamDriver]
                    ]),
                    _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('team-driver')), 1)
                  ]),
                  (!_ctx.isEuropeCompany())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.permisions.bondedPermission) = $event)),
                          id: "bondedPermission-checkbox",
                          type: "checkbox",
                          class: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                        }, null, 512), [
                          [_vModelCheckbox, _ctx.permisions.bondedPermission]
                        ]),
                        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('bonded-permission')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.isEuropeCompany())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.permisions.tsa) = $event)),
                          id: "tsa-checkbox",
                          type: "checkbox",
                          class: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                        }, null, 512), [
                          [_vModelCheckbox, _ctx.permisions.tsa]
                        ]),
                        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('tsa')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.isEuropeCompany())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.permisions.TWICCard) = $event)),
                          id: "twic",
                          type: "checkbox",
                          class: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                        }, null, 512), [
                          [_vModelCheckbox, _ctx.permisions.TWICCard]
                        ]),
                        _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('twic-card')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_11, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.permisions.hazardousLoad) = $event)),
                      id: "hazardousLoad-checkbox",
                      type: "checkbox",
                      class: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.permisions.hazardousLoad]
                    ]),
                    _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('hazardous-load')), 1)
                  ]),
                  (_ctx.isEuropeCompany())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.permisions.tir) = $event)),
                          id: "tir-checkbox",
                          type: "checkbox",
                          class: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                        }, null, 512), [
                          [_vModelCheckbox, _ctx.permisions.tir]
                        ]),
                        _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('tir')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.isEuropeCompany())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.permisions.oversize) = $event)),
                          id: "oversize-checkbox",
                          type: "checkbox",
                          class: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                        }, null, 512), [
                          [_vModelCheckbox, _ctx.permisions.oversize]
                        ]),
                        _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('oversize')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.isEuropeCompany())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.permisions.overweight) = $event)),
                          id: "overweight-checkbox",
                          type: "checkbox",
                          class: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                        }, null, 512), [
                          [_vModelCheckbox, _ctx.permisions.overweight]
                        ]),
                        _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('overweight')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_19, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.permisions.specialPermissions) = $event)),
                      id: "special-permits",
                      type: "checkbox",
                      class: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.permisions.specialPermissions]
                    ]),
                    _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('special-permits')), 1)
                  ]),
                  (_ctx.permisions.specialPermissions)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createVNode(_component_Field, {
                          modelValue: _ctx.permisions.specialPermissionsDescription,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.permisions.specialPermissionsDescription) = $event)),
                          type: "text",
                          name: "SpecialPermits",
                          id: "specialPermissionsDescription-permits",
                          class: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex p-2.5",
                          placeholder: _ctx.$t('specify'),
                          rules: "required"
                        }, null, 8, ["modelValue", "placeholder"]),
                        _createVNode(_component_ErrorMessage, {
                          name: "SpecialPermits",
                          class: "text-rose-500"
                        })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("p", {
              class: "text-gray-500 cursor-pointer",
              onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, _toDisplayString(_ctx.$t('cancel')), 1),
            _createVNode(_component_plan_button, {
              onOnClick: _ctx.accept,
              type: "primary",
              label: _ctx.$t('save')
            }, null, 8, ["onOnClick", "label"])
          ])
        ]),
        _: 1
      }, 8, ["title", "onClose"]))
    : _createCommentVNode("", true)
}