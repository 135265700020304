
import { computed, defineComponent, onBeforeMount, reactive, ref, toRefs } from 'vue';
import HeaderLaneDetails from '@/modules/rfp/_components/HeaderLaneDetails.vue';
import LanesList from '@/modules/rfp/lanesDetail/LanesList.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import PlanFilter from '@/components/filters/PlanFilter.vue';
import store from '@/services/store';
import { useRoute, useRouter } from 'vue-router';
import LoadsCalendar from '@/components/loads/LoadsCalendar.vue';
import i18n from '@/resources/locales';

import { useI18n } from 'vue-i18n';
export default defineComponent({
  components: {
    HeaderLaneDetails,
    LanesList,
    LoadsCalendar,
    PlanButton,
    PlanLoading,
    PlanFilter,
  },
  props: {
    projectId: Number,
  },
  setup(props) {
    const page = ref(0);
    const route = useRoute();
    const projectId = ref(props.projectId);
    const loading = ref(true);
    const laneListObtained = ref(false);
    const router = useRouter();
    const translate = useI18n();

    onBeforeMount(async () => {
      data.projectId = +route.params.projectId;
      await fetchLanesDetail();
      await getLoadsInfoCalendar(null);
    });

    const planFilters = computed(() => {
      let traylerTypeMin = i18n.global.locale.value === 'es-ES' ? '60px' : '55px';
      let carrierBrokerMin = i18n.global.locale.value === 'es-ES' ? '160px' : '95px';
      let customerMin = i18n.global.locale.value === 'es-ES' ? '55px' : '70px';
      let warehouseMin = i18n.global.locale.value === 'es-ES' ? '70px' : '80px';

      return [
        {
          id: 'TRAILER_TYPE',
          icon: 'icInRoute',
          title: translate.t('vehicles'),
          placeholder: translate.t('vehicle'),
          min: traylerTypeMin,
        },
        {
          id: 'CARRIER_BROKER',
          icon: 'user-new',
          title: translate.t('carrier-broker'),
          placeholder: translate.t('carrier-broker'),
          min: carrierBrokerMin,
        },
        {
          id: 'CUSTOMER',
          icon: 'user-new',
          title: translate.t('customers'),
          placeholder: translate.t('customer'),
          min: customerMin,
        },
        {
          id: 'WAREHOUSE',
          icon: 'warehouse',
          title: translate.t('warehouses'),
          placeholder: translate.t('warehouse'),
          min: warehouseMin,
        },
      ];
    });

    const data = reactive({
      headers: [] as any,
      loads: [] as any,
      isSearchingFilter: false,
      resetSearch: false,
      isSearchingByLane: false,
      laneSelectedSearching: null,
      loadingLoads: true,
      rfpId: null as any,
      projectId: null as any,
      loadingCalendar: false,
      filters: {
        multisearch: '',
        status: '',
        periodStart: 0,
        periodEnd: 0,
        page: 1,
        maxResults: 4,
      },
      startWeek: 0,
      page: 1,
      maxResults: 4,
      selectedTotalTab: { index: 0, loadParamFilter: '' },
      endweek: 0,
      currentWeek: 1,
      loadingPlanning: false,
      showMessage: true,
      totalDates: [],
      loadsTotals: {
        TOTAL: 0,
        FINISHED: 0,
        QUOTING: 0,
        CONFIRMED: 0,
        IN_PROGRESS: 0,
        ASSIGNED: 0,
        CANCELED: 0,
        UNASSIGNED: 0,
      },
    });

    const fetchCalendarByLane = (lane) => {
      data.laneSelectedSearching = lane;
      data.isSearchingByLane = true;
      data.resetSearch = !data.resetSearch;
      store.commit('LoadsStore/cleanAppliedFilters');
      getLoadsInfoCalendar(lane);
    };

    const getLoadsInfoCalendar = async (lane) => {
      if (lane) {
        data.projectId = null;
        data.rfpId = lane;
      } else {
        data.projectId = +route.params.projectId;
        data.rfpId = null;
      }

      const query = {
        ...store.getters['LoadsStore/getAppliedFiltersQuery'],
        page: data.page,
        maxResults: 10,
        infoType: ['TOTALS_DATES'],
        lanesRFPId: lane ? lane : undefined,
        projectId: lane ? undefined : +route.params.projectId,
      };
      data.loadingCalendar = true;

      await store
        .dispatch('LoadsStore/getLoadsInfo', query)
        .then((response) => {
          data.totalDates = response.data.totalsDates;
        })
        .catch((err) => {
          data.loadingCalendar = false;
        });

      data.loadingCalendar = false;
    };

    /** Computed **/
    const currentLanes = computed(() => {
      return store.getters['RFPStore/getLanes'];
    });

    const currentUserLocale = computed(() => {
      return i18n.global.locale.value;
    });

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];
    });

    const totalLanes = computed(() => {
      return currentProject.value.computed.lanes.totalAmount;
    });

    const cleanFilters = () => {
      data.resetSearch = !data.resetSearch;
      data.isSearchingByLane = false;
      store.commit('LoadsStore/cleanAppliedFilters');
      getLoadsInfoCalendar(null);
    };

    const currentView = computed(() => {
      return store.getters['RFPStore/getPreviousStatusProject'];
    });

    const changeCurrentView = (currentView) => {
      if (currentView === 'table') {
        store.commit('RFPStore/setPreviousStatusProject', 'calendar');
      } else {
        store.commit('RFPStore/setPreviousStatusProject', 'table');
      }
    };

    const changeFilterStatus = (status: any) => {
      store.commit('LoadsStore/applyFilter', status);
      getLoadsInfoCalendar(null);
    };

    const maxDateFilter = computed(() => {
      const maxFromFilter =
        store.getters['LoadsStore/getAppliedFiltersQuery'].pickupInitialDateBefore;
      if (maxFromFilter) {
        return maxFromFilter;
      }
      return null;
    });

    const minDateFilter = computed(() => {
      const minFromFilter =
        store.getters['LoadsStore/getAppliedFiltersQuery'].pickupInitialDateAfter;
      if (minFromFilter) {
        return minFromFilter;
      }
      return null;
    });

    const goToDay = (date, rfpId) => {
      let query = store.getters['LoadsStore/getAppliedFiltersQuery'];
      const formattedDate = `${date.split('-')[2]}-${date.split('-')[1]}-${date.split('-')[0]}`;

      if (data.isSearchingByLane) {
        router.push({
          name: 'RFP-laneDay',
          params: {
            date: formattedDate,
            laneId: data.laneSelectedSearching,
          },
        });
      } else {
        router.push({
          name: 'RFP-laneDay',
          params: {
            date: formattedDate,
            projectId: +currentProject.value.project.id,
          },
        });
      }
    };
    /** Methods **/
    const fetchLanesDetail = async () => {
      try {
        loading.value = true;
        page.value += 1;
        await Promise.all([
          store.dispatch('RFPStore/getProjectById', {
            projectId: projectId.value,
            page: page.value,
          }),
          store.dispatch('RFPStore/getLanesByProjectId', {
            projectId: projectId.value,
            page: page.value,
            maxResults: 20,
          }),
        ]);

        laneListObtained.value = true;
      } catch (error) {
        console.error('Error fetching lanes:', error);
      } finally {
        loading.value = false;
      }
    };

    store.dispatch('LoadsStore/packageTypes')

    return {
      ...toRefs(data),
      currentLanes,
      currentProject,
      currentView,
      currentUserLocale,
      changeCurrentView,
      cleanFilters,
      fetchLanesDetail,
      changeFilterStatus,
      fetchCalendarByLane,
      planFilters,
      goToDay,
      laneListObtained,
      loading,
      minDateFilter,
      maxDateFilter,
      route,
      totalLanes,
    };
  },
});
