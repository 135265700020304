
import { computed, defineComponent } from 'vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'PlanNewLoadHeaderSticky',
  components: { PlanIcon, PlanTextButton },
  emits: ['update'],
  setup (props, {emit}) {
    const store = useStore()
    const translate = useI18n()

    const getFormattedDate = (date: string) => {
      moment.locale(translate.locale.value);
      const parsedDate = moment(date, 'DD-MM-YYYY', true)

      if (!parsedDate.isValid()) {
        return date
      }

      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? parsedDate.format('MMM DD, YYYY')
        : parsedDate.format('DD MMM, YYYY');
    }

    const getAllCommoditiesData = (commodities: any[]) => {
      const resultArray = commodities.map((commodity: any) => getCommodityData(commodity));

      if (resultArray.length === 0) return '';
      if (resultArray.length === 1) return resultArray[0];

      const lastCommodity = resultArray.pop();

      return resultArray.length > 1
        ? `${resultArray.join(', ')}, y ${lastCommodity}`
        : `${resultArray[0]} y ${lastCommodity}`;
    }

    const getCommodityData = (commodity: any) => {
      const weightType = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'lbs':'kgs'
      let result = `${commodity.commodityDescription} ${commodity.weight} ${weightType}`
      packageTypes.value.forEach((item: any) => {
        if (item.value === commodity.type) {
          result += ` ${commodity.units} ${item.label}`
        }
      })
      return result
    }

    const loadData = computed(() => {
      let pickup = store.getters['NewFtlLoad/getQuery'].pickup.stopData.location
      let pickupDate = getFormattedDate(store.getters['NewFtlLoad/getQuery'].pickup.date)
      let delivery = store.getters['NewFtlLoad/getQuery'].delivery.stopData.location
      let deliveryDate = getFormattedDate(store.getters['NewFtlLoad/getQuery'].delivery.date)

      return {
        pickup: pickup,
        pickupDate: pickupDate,
        delivery: delivery,
        deliveryDate: deliveryDate,
        trailer: selectedTrailer.value,
        commodities: getAllCommoditiesData(store.getters['NewFtlLoad/getQuery'].commodities)
      }
    })

    const packageTypes = computed(() => {
      let result: Array<any> = [];
      store.getters['LoadsStore/packageTypes'].FTL?.forEach((packageType: any) => {
        result.push({
          label: packageType.fullName,
          value: packageType.id.toString(),
        })
      })
      return result
    })

    const selectedTrailer = computed(() => {
      let result = ''
      store.getters['LoadsStore/getTrailerTypes'].forEach((vehicle: any) => {
        if (vehicle.id.toString() === store.getters['NewFtlLoad/getQuery'].trailer.trailerId) {
          result = vehicle.description
        }
      })
      return result;
    });

    const update = () => { emit('update') }

    return {
      loadData,
      selectedTrailer,
      update
    }
  }
})
