export default {
  cleanStore (state: any) {
    state.activeForm = null
    state.averageStats = null
    state.datePriceStats = null
    state.pickup = null
    state.pickupDate = null
    state.delivery = null
    state.deliveryDate = null
    state.trailerId = null
    state.commodity = null
    state.weight = null
    state.units = null
    state.packageType = null
  },
  setActiveForm (state: any, newForm: any) {
    state.activeForm = newForm
  },
  setAverageStats (state: any, newValue: any) {
    state.averageStats = newValue
  },
  setCommodity (state: any, commodity: string) {
    state.commodity = commodity
  },
  setDatePriceStats (state: any, newValue: any) {
    state.datePriceStats = newValue
  },
  setDelivery (state: any, newDelivery: any) {
    state.delivery = newDelivery
  },
  setDeliveryDate (state: any, newDate: string) {
    state.deliveryDate = newDate
  },
  setPackageType (state: any, newValue: any) {
    state.packageType = newValue
  },
  setPickup (state: any, newPickup: any) {
    state.pickup = newPickup
  },
  setPickupDate (state: any, newDate: string) {
    state.pickupDate = newDate
  },
  setTrailer (state: any, newValue: any) {
    state.trailerId = newValue
  },
  setUnits (state: any, newValue: any) {
    state.units = newValue
  },
  setWeight (state: any, newValue: any) {
    state.weight = newValue
  }
}