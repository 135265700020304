
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import CustomAgentModal from '@/modules/planning/loadDetail/_components/CustomAgentModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'CustomAgentBanner',
  components: {
    CustomAgentModal,
    PlanButton,
  },
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      showCustomAgentModal: false,
    });

    const currentLoad = computed(() => store.getters['LoadsStore/currentLoad']);

    const toggleCustomAgentModal = (value: boolean) => {
      data.showCustomAgentModal = value;
    };

    return {
      ...toRefs(data),
      currentLoad,
      toggleCustomAgentModal,
    };
  },
});
