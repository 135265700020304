
import { defineComponent, reactive, toRefs } from 'vue';

export default defineComponent({
  name: 'LoadDetailHeaderContentButtons',
  props: {
    buttons: { type: Array, required: true },
    activeButton: { type: String, required: true, default: '' },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const data = reactive({
      selectedButton: '',
    });

    if (props.activeButton !== '') data.selectedButton = props.activeButton + '';

    const selectButton = (buttonToSelect: string) => {
      if (data.selectedButton !== buttonToSelect) {
        data.selectedButton = buttonToSelect;
        emit('change', buttonToSelect);
      }
    };

    return {
      ...toRefs(data),
      selectButton,
    };
  },
});
