
import { defineComponent, reactive, toRefs } from 'vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
export default defineComponent({
  name: 'PlanLoadFormMultipleLoads',
  components: { PlanInputNumber, PlanButtonIcon, PlanInputCheckbox },
  emits: ['change'],
  setup (props, { emit }) {
    const data = reactive({
      isActive: false,
      loads: 2
    })

    const addLoads = () => {
      data.loads++
      emit('change', data.loads)
    }

    const changeLoadsQuantity = () => {
      if (data.loads < 2) data.loads = 2
      emit('change', data.loads)
    }

    const changeMultipleLoads = (newValue: boolean) => {
      data.isActive = newValue
      if (!newValue) emit('change', 1)
      data.loads = 2
      if (newValue) emit('change', 2)
    }

    const subtractLoads = () => {
      data.loads--
      emit('change', data.loads)
    }

    return {
      ...toRefs(data),
      addLoads,
      changeLoadsQuantity,
      changeMultipleLoads,
      subtractLoads
    }
  }
})
