
import { computed, defineComponent, onBeforeMount, onMounted, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { debounce } from 'lodash';

import PlanLegsSelector from '@/components/tabs/PlanLegsSelector.vue';
import PlanContactBanner from '@/components/banner/PlanContactBanner.vue';
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';
import PlanHeaderSearch from '@/components/filters/PlanHeaderSearch.vue';
import ContactsTabFilter from '@/components/filters/ContactsTabFilter.vue';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import BaseComponent from '@/base/BaseComponent';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanFlag from '@/components/icons/PlanFlag.vue';
import PlanFilterContactTag from '@/components/filters/PlanFilterContactTag.vue';
import PlanContactTags from '@/components/labels/PlanContactTags.vue';

export default defineComponent({
  name: 'PlanQuoteStep',
  mixins: [BaseComponent],
  components: {
    AddCarrierModal,
    ContactsTabFilter,
    PlanLegsSelector,
    PlanContactBanner,
    PlanHeaderSearch,
    PlanDefaultTable,
    PlanButton,
    PlanButtonIcon,
    PlanFlag,
    PlanFilterContactTag,
    PlanContactTags,
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();
    const router = useRouter();
    const data = reactive({
      contactModalVisible: false,
      legSelected: 0,
      loadSelected: {} as any,
      loadingView: true,
      isCrossBorderLoad: false,
      order: {
        value: 'companyName',
        direction: 'asc',
      },
      searchQuery: {
        page: 1,
        name: '',
        type: 'CARRIER|BROKER',
        trailerType: 2,
        trailerTypeStats: 2,
        originZip: '',
        destinyZip: '',
        period: 'LAST_3_MONTHS',
        permits: '',
        country: '',
        tags: [] as any,
        sortBy: '',
      },
      loadingContacts: false,
    });

    onMounted(async () => {
      fecthQuery();
      await getContactList();
    });

    const countriesList = computed(() => {
      return store.getters['CountryStore/getCountries'];
    });

    const MEXICO = computed(() => {
      return countriesList.value.find((country) => country.name === 'Mexico');
    });

    const USA = computed(() => {
      return countriesList.value.find((country) => country.name === 'United States');
    });

    const getLoadToSend = computed(() => {
      return store.getters['CrossBorderStore/getLoadToSend'];
    });

    const trailerSelected = computed(() => {
      return getLoadToSend.value.trailerType;
    });

    const initialPickUp = computed(() => {
      return data.loadSelected.stops[0].location;
    });

    const finalDelivery = computed(() => {
      const index = data.loadSelected.stops.length - 1;
      return data.loadSelected.stops[index].location;
    });

    const contacts = computed(() => {
      return store.getters['UserStore/carrierContactListStatsByLoad'];
    });

    const selectedContactsLegs = computed(() => {
      return store.getters['CrossBorderStore/getSelectedContactsLegs'];
    });

    if (getLoadToSend.value?.legs?.length > 0) {
      data.loadSelected = {
        ...getLoadToSend.value.legs[data.legSelected],
        trailerType: getLoadToSend.value.trailerType,
      };
      data.isCrossBorderLoad = true;
    } else {
      data.loadSelected = JSON.stringify(getLoadToSend.value);
    }

    const unselectContact = (contact) => {
      store.commit('CrossBorderStore/removeContactsLegs', {
        index: data.legSelected,
        data: contact,
      });
    };

    const fecthQuery = () => {
      data.searchQuery = {
        page: 1,
        name: '',
        type: 'CARRIER|BROKER',
        trailerType: trailerSelected.value.id,
        trailerTypeStats: trailerSelected.value.id,
        originZip: initialPickUp.value.zip,
        destinyZip: finalDelivery.value.zip,
        period: 'LAST_3_MONTHS',
        permits: undefined as any,
        country: '',
        tags: [],
        sortBy: '' as any,
      };
      // ONLY FOR CROSSBORDER
      if (data.isCrossBorderLoad) {
        data.searchQuery.country = MEXICO.value.id;
      }
    };

    const getContactList = () => {
      data.loadingContacts = true;
      // .dispatch('UserStore/carrierContactList', data.searchQuery)
      // .dispatch('UserStore/carrierContactListStatsByLoad', data.searchQuery)
      if (!data.searchQuery.tags) {
        delete data.searchQuery.tags;
      }
      store.dispatch('UserStore/carrierContactListStatsByLoad', data.searchQuery).finally(() => {
        data.loadingContacts = false;
      });
    };

    const debouncedGetContactList = debounce(getContactList, 500);

    const handleChangeLeg = (leg) => {
      data.legSelected = leg;
      data.loadSelected = getLoadToSend.value.legs[data.legSelected];
      data.searchQuery.country =
        data.loadSelected.legOrder !== 3 ? MEXICO.value.id : `${MEXICO.value.id}|${USA.value.id}`;
      data.searchQuery.originZip = data.loadSelected.stops[0].location.zip;
      data.searchQuery.destinyZip = data.loadSelected.stops[1].location.zip;
      getContactList();
    };

    const checkContactSelected = (id) => {
      let isSelected = false;
      selectedContactsLegs.value[data.legSelected].forEach((contact) => {
        if (contact.id === id) {
          isSelected = true;
        }
      });
      return isSelected;
    };

    const changeSelectContact = (contact) => {
      store.commit('CrossBorderStore/pushContactsLegs', { index: data.legSelected, data: contact });
    };

    const loadMoreContacts = () => {
      data.searchQuery.page = data.searchQuery.page + 1;
      getContactList();
    };

    const openChat = (user) => {
      store.commit('GlobalStore/setOpenChat', false);
      setTimeout(() => {
        store.commit('ChatsStore/setUserForChat', user);
        store.commit('ChatsStore/setChatId', user.chat_id ? user.chat_id : null);
        store.commit('GlobalStore/setOpenChat', true);
      }, 500);
    };

    const handleChangeSearchByName = (name) => {
      data.searchQuery.name = name;
      debouncedGetContactList();
    };

    const changeContactTag = (tag: []) => {
      data.searchQuery.tags = `[${tag}]`;
      getContactList();
    };

    const changeSelectedPermits = (permitsSelected: string) => {
      data.searchQuery.permits = permitsSelected;
      getContactList();
    };

    const changePeriod = (period: any) => {
      data.searchQuery.period = period;
      getContactList();
    };

    const filterByCountryTypeUser = (country: string, userType: string, add: boolean) => {
      if (add) {
        if (data.searchQuery.type === '') {
          data.searchQuery.type = userType;
        }
        if (data.searchQuery.type === 'BROKER' && userType !== 'BROKER') {
          data.searchQuery.type = 'CARRIER|BROKER';
        }
        if (data.searchQuery.type === 'CARRIER' && userType !== 'CARRIER') {
          data.searchQuery.type = 'CARRIER|BROKER';
        }
        if (data.searchQuery.country) {
          if (data.legSelected === 3) {
            if (data.searchQuery.country !== `${MEXICO.value.id}|${USA.value.id}`) {
              data.searchQuery.country = `${MEXICO.value.id}|${USA.value.id}`;
            } else {
              data.searchQuery.country = country;
            }
          } else {
            data.searchQuery.country = country;
          }
        }
      } else {
        if (data.searchQuery.type === 'CARRIER|BROKER') {
          if (userType === 'BROKER') {
            data.searchQuery.type = 'CARRIER';
          } else {
            data.searchQuery.type = 'BROKER';
          }
        } else {
          data.searchQuery.type = 'CARRIER|BROKER';
        }
        data.searchQuery.country = `${MEXICO.value.id}|${USA.value.id}`;
        // if (data.legSelected === 3) {
        //   if (country === USA.value.id) {
        //     data.searchQuery.country = MEXICO.value.id;
        //   } else {
        //     data.searchQuery.country = USA.value.id;
        //   }
        // }
      }
      getContactList();
    };

    const updateSearchTypeUser = (userType: string) => {
      if (userType === 'TRAILER') {
        data.searchQuery.trailerType = data.searchQuery.trailerType
          ? null
          : getLoadToSend.value.trailerType.id;
      } else {
        data.searchQuery.type = userType;
      }
      getContactList();
    };

    const fetch = () => {
      //
    };

    const headers = computed(() => {
      return [
        { label: translate.t('company'), value: 'companyName', sortable: true },
        { label: translate.t('rating'), value: 'rating', sortable: true },
        { label: translate.t('rol'), value: 'rol', sortable: false },
        { label: translate.t('loads'), value: 'loadsQuantity', sortable: true },
        { label: '', value: 'actions' },
      ];
    });

    const fetchOrder = (orderObject) => {
      data.order = orderObject;
      data.searchQuery.sortBy = `${orderObject.value}[${orderObject.direction.toUpperCase()}]`;
      getContactList();
    };

    return {
      ...toRefs(data),
      contacts,
      changeContactTag,
      checkContactSelected,
      fetch,
      fetchOrder,
      getContactList,
      handleChangeLeg,
      handleChangeSearchByName,
      headers,
      loadMoreContacts,
      openChat,
      changeSelectContact,
      getLoadToSend,
      trailerSelected,
      updateSearchTypeUser,
      unselectContact,
      changeSelectedPermits,
      changePeriod,
      filterByCountryTypeUser,
    };
  },
});
