import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col plan-gap-xs" }
const _hoisted_2 = {
  key: 0,
  class: "plan-form"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "plan-t-base font-semibold plan-t-prim-200" }
const _hoisted_5 = { class: "plan-t-xs plan-t-prim-100" }
const _hoisted_6 = { class: "font-bold plan-t-sec-500" }
const _hoisted_7 = ["multiple", "accept"]
const _hoisted_8 = { class: "flex justify-between items-center plan-t-xxs plan-t-prim-100 plan-gap-sm" }
const _hoisted_9 = { class: "truncate" }
const _hoisted_10 = {
  key: 0,
  class: "truncate text-right"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 1,
  class: "plan-input-fileselected-files"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label.length > 0)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.mandatory)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["plan-input-file", { 'drag-over': _ctx.isDragging }]),
      onDragover: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDragOver && _ctx.onDragOver(...args)), ["prevent"])),
      onDragleave: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDragLeave && _ctx.onDragLeave(...args)), ["prevent"])),
      onDrop: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDrop && _ctx.onDrop(...args)), ["prevent"])),
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
      style: _normalizeStyle(_ctx.styles)
    }, [
      _createVNode(_component_plan_icon, {
        name: _ctx.loading ? 'spinner' : _ctx.icon,
        "icon-color": "#8A8B97",
        size: "30",
        "is-svg": "",
        rotate: _ctx.loading
      }, null, 8, ["name", "rotate"]),
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col justify-center items-center", { 'opacity-50': _ctx.loading }])
      }, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('plan-input-file.title')), 1),
        _createElementVNode("span", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.$t('or')) + " ", 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('plan-input-file.browse-file')), 1)
        ])
      ], 2),
      _createElementVNode("input", {
        type: "file",
        ref: "fileInput",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args))),
        multiple: _ctx.multiple,
        accept: _ctx.acceptTypes,
        hidden: ""
      }, null, 40, _hoisted_7)
    ], 38),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('plan-input-file.max-size', { size: _ctx.maxSize })), 1),
      (_ctx.typesToShow.length === 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('plan-input-file.allowed-types')) + _toDisplayString(_ctx.types.join(', ')), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.typesToShow), 1))
    ]),
    (_ctx.files.length > 0 && !_ctx.hideLoadedItems)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "plan-input-file-file-item"
            }, [
              _createElementVNode("span", null, _toDisplayString(file.name), 1),
              _createVNode(_component_plan_button_icon, {
                onOnClick: ($event: any) => (_ctx.removeFile(index)),
                icon: "close",
                type: "transparent-gray-800"
              }, null, 8, ["onOnClick"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}