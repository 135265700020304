import carrierApi from '../_api/index';
export default {
  async getLoadInfoForCarrier(context: any, id: number) {
    await carrierApi
      .getLoadInfoForCarrier(id)
      .then((response) => {
        context.commit('setLoadData', response.load);
        context.commit('setCarrierChat', response.chats);
        context.commit('setUserBid', response.userBid);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
};
