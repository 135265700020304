import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHover = true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isHover = false)),
    class: _normalizeClass(["plan-text-button", {
         'plan-text-button-amber': _ctx.type === 'amber',
         'plan-text-button-red': _ctx.type === 'red',
         'plan-text-button-prim-100': _ctx.type === 'prim-100',
         'plan-text-button-prim-200': _ctx.type === 'prim-200',
       }])
  }, [
    (_ctx.icon !== '' || _ctx.loading)
      ? (_openBlock(), _createBlock(_component_plan_icon, {
          key: 0,
          name: _ctx.loading ? 'spinner' : _ctx.icon,
          size: _ctx.iconSize,
          "icon-color": _ctx.isHover ? _ctx.hoverColor:_ctx.normalColor,
          "is-svg": "",
          rotate: _ctx.loading
        }, null, 8, ["name", "size", "icon-color", "rotate"]))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
  ], 34))
}