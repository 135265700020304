import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  loadToSend: null as any,
  defaultLoad: {
    customers: [],
    mandatoryCargoInsurance: true,
    observations: '',
    poNumber: '',
    stops: [
      {
        type: 'PICKUP_INITIAL',
        executionDate: '',
        location: {
          lat: 0,
          long: 0,
          zip: 0,
          city: '',
          state: { id: 1 },
        },
        portStop: false,
        airportStop: false,
        observations: '',
        deliveryStop: null,
        lumperFeePrice: 0,
        multiStopIdentifierArray: [],
        lumperFee: false,
        multiStopIdentifier: '',
        executionWindowEndTime: null,
        executionWindowStartTime: null,
        companyName: '',
        address: '',
        phoneNumber: '',
        phonePrefix: '',
        poNumber: '',
        contact: '',
        warehouseTemplate: null,
        commodities: [
          {
            id: null,
            nPallets: 4,
            packageType: {
              id: 3,
            },
            weight: 2100,
            commodityName: 'bikes',
          },
        ],
      },
      {
        type: 'DELIVERY_FINAL',
        executionDate: '',
        location: {
          lat: 0,
          long: 0,
          zip: 0,
          city: '',
          state: { id: 1 },
        },
        portStop: false,
        airportStop: false,
        multiStopIdentifier: '',
        observations: '',
        lumperFeePrice: 0,
        multiStopIdentifierArray: [],
        lumperFee: false,
        executionWindowEndTime: null,
        executionWindowStartTime: null,
        companyName: '',
        deliveryStop: null,
        address: '',
        phoneNumber: '',
        phonePrefix: '',
        poNumber: '',
        contact: '',
        warehouseTemplate: null,
        commodities: [],
      },
    ],
    commodityMaster: {
      id: null,
      nPallets: 4,
      packageType: {
        id: 3,
      },
      weight: 2100,
      commodityName: 'bikes',
    },
    status: 'DRAFT',
    trailerType: {
      id: null,
    },
    commodityMasters: [
      {
        id: null,
        nPallets: null,
        packageType: {
          id: 3,
        },
        weight: null,
        commodityName: '',
      },
    ],
    specialRequirement: {
      teamDriver: false,
      bondedPermission: false,
      TWICCard: false,
      tsa: false,
      hazardousLoad: false,
      specialPermissions: false,
      specialPermissionsDescription: '',
      tir: false,
      oversize: false,
      overweight: false,
    },
    legs: [
      {
        observations: '',
        poNumber: '',
        stops: [
          {
            type: 'PICKUP_INITIAL',
            executionDate: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            observations: '',
            deliveryStop: null,
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            multiStopIdentifier: '',
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
          {
            type: 'DELIVERY_FINAL',
            executionDate: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            multiStopIdentifier: '',
            observations: '',
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            deliveryStop: null,
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [],
          },
        ],
        commodityMaster: {
          id: null,
          nPallets: 4,
          packageType: {
            id: 3,
          },
          weight: 2100,
          commodityName: 'bikes',
        },
        status: 'DRAFT',
        trailerType: {
          id: 0,
        },
        commodityMasters: [
          {
            id: null,
            nPallets: 0,
            packageType: {
              id: 3,
            },
            weight: 0,
            commodityName: '',
          },
        ],
        specialRequirement: {
          teamDriver: false,
          bondedPermission: false,
          TWICCard: false,
          tsa: false,
          hazardousLoad: false,
          specialPermissions: false,
          specialPermissionsDescription: '',
          tir: false,
          oversize: false,
          overweight: false,
        },
        legOrder: 1,
      },
      {
        legBorderTransfer: true,
        observations: '',
        poNumber: '',
        stops: [
          {
            type: 'PICKUP_INITIAL',
            executionDate: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            observations: '',
            deliveryStop: null,
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            multiStopIdentifier: '',
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
          {
            type: 'DELIVERY_FINAL',
            executionDate: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            multiStopIdentifier: '',
            observations: '',
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            deliveryStop: null,
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
        ],
        commodityMaster: {
          id: null,
          nPallets: 4,
          packageType: {
            id: 3,
          },
          weight: 2100,
          commodityName: 'bikes',
        },
        status: 'DRAFT',
        trailerType: {
          id: 1,
        },
        commodityMasters: [
          {
            id: null,
            nPallets: 4,
            packageType: {
              id: 3,
            },
            weight: 2100,
            commodityName: 'bikes',
          },
        ],
        specialRequirement: {
          teamDriver: false,
          bondedPermission: false,
          TWICCard: false,
          tsa: false,
          hazardousLoad: false,
          specialPermissions: false,
          specialPermissionsDescription: '',
          tir: false,
          oversize: false,
          overweight: false,
        },
        legOrder: 2,
      },
      {
        observations: '',
        poNumber: '',
        stops: [
          {
            type: 'PICKUP_INITIAL',
            executionDate: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            observations: '',
            deliveryStop: null,
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            multiStopIdentifier: '',
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
          {
            type: 'DELIVERY_FINAL',
            executionDate: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            multiStopIdentifier: '',
            observations: '',
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            deliveryStop: null,
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
        ],
        commodityMaster: {
          id: null,
          nPallets: 4,
          packageType: {
            id: 3,
          },
          weight: 2100,
          commodityName: 'bikes',
        },
        status: 'DRAFT',
        trailerType: {
          id: 1,
        },
        commodityMasters: [
          {
            id: null,
            nPallets: 4,
            packageType: {
              id: 3,
            },
            weight: 2100,
            commodityName: 'bikes',
          },
        ],
        specialRequirement: {
          teamDriver: false,
          bondedPermission: false,
          TWICCard: false,
          tsa: false,
          hazardousLoad: false,
          specialPermissions: false,
          specialPermissionsDescription: '',
          tir: false,
          oversize: false,
          overweight: false,
        },
        legOrder: 3,
      },
    ],
  },
  selectedContactsLegs: [[], [], []] as any,
  dataFilled: false,
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
