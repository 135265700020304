import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-lg" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "flex flex-col" }
const _hoisted_12 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_thumbnail = _resolveComponent("plan-thumbnail")!
  const _component_plan_input_file = _resolveComponent("plan-input-file")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.close,
    title: _ctx.$t('modal-update-documents-paperwork'),
    size: "md-auto",
    withoutSidebar: ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('CCP/BOL')), 1),
          (!_ctx.CCP)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('not-documents-uploaded')), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stopDocs.documents, (doc) => {
            return (_openBlock(), _createElementBlock("div", {
              key: doc.id
            }, [
              (doc.type === 'BOL')
                ? (_openBlock(), _createBlock(_component_plan_thumbnail, {
                    key: 0,
                    id: doc.id,
                    "entity-class": doc.entityClass,
                    "is-min-size": ""
                  }, null, 8, ["id", "entity-class"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('CCP/POD')), 1),
          (!_ctx.CCPPOD)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('not-documents-uploaded')), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stopDocs.documents, (doc) => {
            return (_openBlock(), _createElementBlock("div", {
              key: doc.id
            }, [
              (doc.type === 'POD')
                ? (_openBlock(), _createBlock(_component_plan_thumbnail, {
                    key: 0,
                    id: doc.id,
                    "entity-class": doc.entityClass,
                    "is-min-size": ""
                  }, null, 8, ["id", "entity-class"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('invoices')), 1),
          (_ctx.invoices.documents.length === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('not-documents-uploaded')), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoices.documents, (invoice) => {
            return (_openBlock(), _createElementBlock("div", {
              key: invoice.id
            }, [
              _createVNode(_component_plan_thumbnail, {
                id: invoice.id,
                "entity-class": invoice.entityClass,
                "is-min-size": ""
              }, null, 8, ["id", "entity-class"])
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('packing-list')), 1),
          _createVNode(_component_plan_input_file, {
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeFiles($event, 'PACKAGING_LIST'))),
            onRemove: _ctx.removeFile,
            "default-files": _ctx.packingList,
            types: ['application/pdf', 'image/*'],
            maxSize: 10,
            loading: _ctx.loadingPost
          }, null, 8, ["onRemove", "default-files", "loading"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}