import globalAxios from 'axios';

const acceptFreeBolNotification = async () => {
  const response = await globalAxios.post(`/company/acceptFreeBolNotification`, {});
  return Promise.resolve(response.data);
};

const assignAdminAgents = async (data: any) => {
  const response = await globalAxios.post(`/companies/${data.id}/admin_agent/assign`, data.body);
  return Promise.resolve(response.data);
};

const deassignAdminAgents = async (data: any) => {
  const response = await globalAxios.post(`/companies/${data.id}/admin_agent/de-assign`, data.body);
  return Promise.resolve(response.data);
};

const deleteCompany = async (id: any) => {
  const response = await globalAxios.delete('/company/' + id);
  return Promise.resolve(response.data);
};

const deleteEmailTemplate = async (id: any) => {
  const response = await globalAxios.delete(`/emailTemplate/${id}`);
  return Promise.resolve(response.data);
};

const postBlastEmail = async (params: any) => {
  const obj = {
    recipients: JSON.stringify(params.recipients),
    subject: params.subject,
    message: params.message,
    files: params.files,
    senderIncluded: params.senderIncluded,
  };

  const response = await globalAxios.post('/sendBlastEmail', obj, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return Promise.resolve(response.data);
};

const deleteDriver = async (id: any) => {
  const response = await globalAxios.delete('/drivers/' + id);
  return Promise.resolve(response.data);
};

const deleteOnNetwork = async (id: any) => {
  const response = await globalAxios.delete('/company/' + id + '/deleteOnNetwork');
  return Promise.resolve(response.data);
};

const deleteVehicles = async (data: any) => {
  const response = await globalAxios.delete('/vehicles/' + data.id);
  return Promise.resolve(response.data);
};

const getAdminAgents = async (data: any) => {
  const response = await globalAxios.get(`/admin_agents`, {
    params: data,
  });
  return Promise.resolve(response.data);
};

const getCompanies = async (data: any) => {
  const response = await globalAxios.get('/companies', { params: data });
  return response.data;
};

const getCompaniesTotals = async (data: any) => {
  const response = await globalAxios.get('/companies/totals', { params: data });
  return response.data;
};

const getCompany = async (id: any) => {
  const response = await globalAxios.get('/company/' + id);
  return response.data;
};

const getCompanyAdmin = async (id: any) => {
  const response = await globalAxios.get(`/company/${id}/admin`);
  return Promise.resolve(response.data);
};

const getDriversList = async (companyId: any) => {
  const params = {
    page: 1,
    maxResults: 100,
  };
  const response = await globalAxios.get('/drivers/company/' + companyId, { params });
  return Promise.resolve(response.data);
};

const getDownloadFileDriver = async (data: any) => {
  const response = await globalAxios.get(
    '/mediaObjects/' + data.id + '/download/file/fromClass/' + data.class,
  );
  return Promise.resolve(response.data);
};

const getDownloadThumbnailBigDriver = async (data: any) => {
  const response = await globalAxios.get(
    '/mediaObjects/' + data.id + '/download/thumbnailBig/fromClass/' + data.class,
    {
      responseType: 'blob',
    },
  );
  return Promise.resolve(response.data);
};

const getEmailTemplate = async  (id: any) => {
  const response = await globalAxios.get(`/emailTemplate/${id}`)
  return Promise.resolve(response.data)
}

const getEmailTemplates = async  () => {
  const response = await globalAxios.get('/emailTemplates')
  return Promise.resolve(response.data)
}

const getVehiclesList = async (params: any) => {
  const id = params;
  params = {
    page: 1,
    maxResults: 100,
  };
  const response = await globalAxios.get('/vehicles/company/' + id, { params });
  return Promise.resolve(response.data);
};

const getShippers = async (data: any) => {
  const response = await globalAxios.get('/companies/shippers', { params: data });
  return response.data;
};

const postAdminAgents = async (body: any) => {
  const response = await globalAxios.post(`/admin_agents`, body);
  return Promise.resolve(response.data);
};

const postDriver = async (body: any) => {
  const response = await globalAxios.post('/drivers', body);
  return Promise.resolve(response.data);
};

const postEmailTemplate = async (data: any) => {
  const response = await globalAxios.post('/emailTemplate', data)
  return Promise.resolve(response.data)
}

const postMediaObjectDriver = async (data: any) => {
  const response = await globalAxios.post('/mediaObjects/driver', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return Promise.resolve(response.data);
};

const postUpdateMediaObjectDriver = async (data: any) => {
  const response = await globalAxios.post(
    '/mediaObjects/' + data.id + '/driver/update',
    { file: data.file },
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
  return Promise.resolve(response.data);
};

const postMediaObjectCompany = async (data: any) => {
  const response = await globalAxios.post('/mediaObjects/company', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return Promise.resolve(response.data);
};

const postUpdateMediaObjectCompany = async (data: any) => {
  const response = await globalAxios.post(
    '/mediaObjects/' + data.id + '/company/update',
    { file: data.file },
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
  return Promise.resolve(response.data);
};

const postVehicle = async (body: any) => {
  const response = await globalAxios.post('/vehicles', body);
  return Promise.resolve(response.data);
};

const putAccountManager = async (data: any) => {
  const response = await globalAxios.put('/company/' + data.id + '/update/accountManagerEmail', {
    cargobotAccountManagerEmail: data.email,
  });
  return Promise.resolve(response.data);
};

const putAdminAgents = async (data: any) => {
  const response = await globalAxios.put(`/admin_agents/${data.id}`, data);
  return Promise.resolve(response.data);
};

const putAcquisitionSource = async (data: any) => {
  const response = await globalAxios.put(
    `/companies/${data.id}/update/acquisition_source`,
    data.body,
  );
  return Promise.resolve(response.data);
};

const putCompany = async (data: any) => {
  const response = await globalAxios.put('/company/' + data.id, data.company);
  return Promise.resolve(response.data);
};

const putCompanyCountry = async (data: any) => {
  const response = await globalAxios.put('/companies/' + data.id + '/update/country', data.params);
  return Promise.resolve(response.data);
};

const putCompanyCreditGranted = async (data: any) => {
  const { id, creditGranted } = data;
  const response = await globalAxios.put(`/companies/${id}/update/creditGranted`, {
    creditGranted,
  });
  return Promise.resolve(response.data);
};

const putCompanyPricingInfo = async (data: any) => {
  const params = { pricingInfoMargin: data.pricingInfoMargin }
  const response = await globalAxios.put(`/companies/${data.id}/update/pricingMargin`, params);
  return Promise.resolve(response.data);
};

const putCompanyUpdateType = async (data: any) => {
  const response = await globalAxios.put(`/company/${data.id}/update/type`, data.type);
  return response.data;
};

const putDriver = async (body: any) => {
  const response = await globalAxios.put('/drivers/' + body.id, body.params);
  return Promise.resolve(response.data);
};

const putEmailTemplate = async (id: number, category: number, templateName: string, subject: string, message: string)=> {
  const data = {
    category: { id: category },
    name: templateName,
    subject: subject,
    message: message
  }
  const response = await globalAxios.put(`/emailTemplate/${id}`, data)
  return Promise.resolve(response.data)
}

const putTrialEndDate = async (data: any) => {
  const response = await globalAxios.put(`/companies/${data.id}/update/trialEndDate`, data.params);
  return response.data;
};

const putVehicle = async (params: any) => {
  const { id, trailerType, trailerPlate, licensePlate } = params;

  params = {
    trailerType: trailerType,
    trailerPlate: trailerPlate,
    licensePlate: licensePlate,
  };

  const response = await globalAxios.put('/vehicles/' + id, params);
  return Promise.resolve(response.data);
};

const updateLoadEmails = async (params: any) => {
  const { id, loadNotificationEmails } = params;

  params = {
    loadNotificationEmails,
  };

  const response = await globalAxios.put(`/company/${id}/update/loadEmails`, params);
  return Promise.resolve(response.data);
};

export default {
  acceptFreeBolNotification,
  assignAdminAgents,
  deassignAdminAgents,
  deleteCompany,
  deleteEmailTemplate,
  deleteOnNetwork,
  deleteDriver,
  deleteVehicles,
  getAdminAgents,
  getCompanies,
  getCompaniesTotals,
  getCompany,
  getCompanyAdmin,
  getDriversList,
  getEmailTemplate,
  getEmailTemplates,
  getShippers,
  getVehiclesList,
  getDownloadFileDriver,
  getDownloadThumbnailBigDriver,
  postAdminAgents,
  postDriver,
  postEmailTemplate,
  postBlastEmail,
  postMediaObjectDriver,
  postUpdateMediaObjectDriver,
  postMediaObjectCompany,
  postUpdateMediaObjectCompany,
  postVehicle,
  putAccountManager,
  putAcquisitionSource,
  putAdminAgents,
  putCompany,
  putCompanyCountry,
  putCompanyCreditGranted,
  putCompanyPricingInfo,
  putCompanyUpdateType,
  putDriver,
  putEmailTemplate,
  putTrialEndDate,
  putVehicle,
  updateLoadEmails,
};
