import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-center items-center mt-10" }
const _hoisted_2 = { class: "plan-t-prim-100 plan-t-xs" }
const _hoisted_3 = { class: "flex flex-col w-full border border-gray-300 rounded plan-gap-sm p-4" }
const _hoisted_4 = {
  key: 0,
  class: "text-gray-500"
}
const _hoisted_5 = { class: "flex flex-row plan-gap-sm h-42" }
const _hoisted_6 = { class: "flex flex-col plan-gap-xs h-42" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_PlanCarrierDefaultModal = _resolveComponent("PlanCarrierDefaultModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    false
      ? (_openBlock(), _createBlock(_component_PlanCarrierDefaultModal, {
          key: 0,
          size: "xs",
          "only-content": ""
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_plan_icon, {
                name: "spinner",
                "icon-color": "#8A8B97",
                size: "30",
                rotate: "",
                "is-svg": ""
              }),
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('process-few-minutes')), 1)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col plan-gap-sm", _ctx.actualWidth <= 640 ? 'justify-start' : ''])
      }, [
        (_ctx.loadData.customsAgent)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('transfer-update')) + ": ", 1))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: _normalizeClass(["flex flex-col justify-center items-center h-42 rounded-full border p-1 border-gray-400", _ctx.actualWidth <= 640 ? 'plan-gap-xl' : 'plan-gap-lg'])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["w-8 h-8 rounded-full border", _ctx.light === 'red' ? _ctx.getLightStyle() : 'border-gray-400'])
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass(["w-8 h-8 rounded-full border", _ctx.light === 'yellow' ? _ctx.getLightStyle() : 'border-gray-400'])
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass(["w-8 h-8 rounded-full border", _ctx.light === 'green' ? _ctx.getLightStyle() : 'border-gray-400'])
          }, null, 2)
        ], 2),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleChangeStatus('TRAILER_STOPPED'))),
            class: _normalizeClass(["w-full cursor-pointer p-3 text-red-600 border border-red-600 rounded-md hover:bg-red-50", _ctx.light === 'red' ? _ctx.getTextStyle() : ''])
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('transfer-stop')) + " ", 1),
            (_ctx.loadingChangeSemaphore && _ctx.status )
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_plan_icon, {
                    name: "spinner",
                    "icon-color": "#8A8B97",
                    size: "30",
                    rotate: "",
                    "is-svg": ""
                  })
                ]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleChangeStatus('TRAILER_INSPECTION'))),
            class: _normalizeClass(["w-full cursor-pointer p-3 text-yellow-600 border border-yellow-600 rounded-md hover:bg-yellow-50", _ctx.light === 'yellow' ? _ctx.getTextStyle() : ''])
          }, _toDisplayString(_ctx.$t('transfer-held')), 3),
          _createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleChangeStatus('CLEARED'))),
            class: _normalizeClass(["w-full cursor-pointer p-3 text-green-600 border border-green-600 rounded-md hover:bg-green-50", _ctx.light === 'green' ? _ctx.getTextStyle() : ''])
          }, _toDisplayString(_ctx.$t('transfer-cleared')), 3)
        ])
      ])
    ])
  ], 64))
}