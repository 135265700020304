
import { computed, defineComponent, onBeforeMount, reactive, ref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanThumbnail from '@/components/forms/PlanThumbnail.vue';

import { debounce } from 'lodash';

// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'ShareDocuments',

  mixins: [BaseComponent],
  components: {
    PlanSelect,
    PlanInputCheckbox,
    PlanInputText,
    PlanInputFile,
    PlanThumbnail,
  },
  props: {
    driver: { type: Object, required: true },
  },

  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();

    const driverFrontFile = ref<File[]>([]);
    const driverBackFile = ref<File[]>([]);

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });
    const changeDriverLicenseFront = (files: File[]) => {
      driverFrontFile.value = files;
      debouncedUpdateDriver();
    };

    const changeDriverLicenseBack = (files: File[]) => {
      driverBackFile.value = files;
      debouncedUpdateDriver();
    };

    const handleUpdateDriver = async () => {
      if (!validateDriver.value) return;

      await store
        .dispatch('CompanyStore/putDriver', {
          id: props.driver.id,
          params: {
            name: data.fistName,
            surname: data.lastName,
            drivingLicense: data.driverLicense,
            company: { id: currentUser.value.company.id },
            email: data.email,
            phonePrefix: data.prefix,
            phone: data.phoneNumber,
            showContactInformation: data.showContactInformation,
          },
        })
        .then((response) => {
          data.emitId = response.id;
          store.dispatch('UserStore/showToast', translate.t('updated-correctly'));
        });

      if (driverFrontFile.value.length > 0 && data.emitId)
        await postDriverMediaObject(data.emitId, 'DRIVER_LICENSE_FRONT');
      if (driverBackFile.value.length > 0 && data.emitId)
        await postDriverMediaObject(data.emitId, 'DRIVER_LICENSE_BACK');
      await getDrivers();
    };

    const debouncedUpdateDriver = debounce(handleUpdateDriver, 500);

    const postDriverMediaObject = async (driverId: number, type: string) => {
      const mediaObject = props.driver.mediaObjects.find((obj: any) => obj.type === type);

      const file =
        type === 'DRIVER_LICENSE_FRONT' ? driverFrontFile.value[0] : driverBackFile.value[0];

      if (mediaObject) {
        await store.dispatch('CompanyStore/postUpdateMediaObjectDriver', {
          id: mediaObject.id,
          type: type,
          file: file,
        });
      } else {
        await store.dispatch('CompanyStore/postMediaObjectDriver', {
          entityId: driverId,
          type: type,
          file: file,
        });
      }
    };

    const getDrivers = async () => {
      await store
        .dispatch('CompanyStore/driversList', currentUser.value.company.id)
        .then((response) => {
          store.commit('CarrierStore/setDrivers', response);
        })
        .finally(() => {
          emit('selectDriver', data.emitId);
        });
    };

    const validateDriver = computed(() => {
      return data.fistName.length > 0 && data.lastName.length > 0 && data.driverLicense.length > 0;
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const orderPrefixAsc = (prefixes: any) => {
      return prefixes.sort((a, b) => {
        let prefixA = parseInt(a.value, 10);
        let prefixB = parseInt(b.value, 10);

        if (prefixA < prefixB) {
          return -1;
        }
        if (prefixA > prefixB) {
          return 1;
        }
        return 0;
      });
    };

    const prefixes = computed(() => {
      const prefixes = store.getters['CarrierStore/getRegionPrefixes'];
      return orderPrefixAsc(prefixes);
    });

    const changePrefix = (newPrefix: any) => {
      data.prefix = newPrefix;
    };

    const data = reactive({
      showModal: false,
      loadingPost: false,
      fistName: '',
      lastName: '',
      driverLicense: '',
      email: '',
      prefix: '',
      phoneNumber: '',
      emitId: null,
      showContactInformation: true,
    });

    const changeShowContactInformation = (newValue: boolean) => {
      data.showContactInformation = newValue;
      debouncedUpdateDriver();
    };

    data.fistName = props.driver.name + '';
    data.lastName = props.driver.surname + '';
    data.driverLicense = props.driver.drivingLicense + '';
    data.email = props.driver.email ? props.driver.email + '' : '';

    data.prefix = props.driver.phonePrefix
      ? props.driver.phonePrefix + ''
      : orderPrefixAsc(prefixes.value)[0].value;
    data.phoneNumber = props.driver.phone ? props.driver.phone + '' : '';
    // eslint-disable-next-line vue/no-setup-props-destructure
    data.showContactInformation = props.driver.showContactInformation;

    return {
      ...toRefs(data),
      actualWidth,
      changeDriverLicenseFront,
      changeDriverLicenseBack,
      changePrefix,
      changeShowContactInformation,
      currentUser,
      debouncedUpdateDriver,
      prefixes,
      handleUpdateDriver,
      translate,
    };
  },
});
