import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_button, {
      onOnClick: _ctx.emitContinue,
      disabled: !_ctx.isDirectAssignValid.result,
      type: "success",
      label: _ctx.$t('continue')
    }, null, 8, ["onOnClick", "disabled", "label"])
  ]))
}