import globalAxios, { isAxiosError } from 'axios';
import { AxiosError } from 'axios';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import store from '@/services/store';

const getBidTotalsCrossCountry = async (id: any) => {
  const response = await globalAxios.get(`/shipments/${id}/bidTotalsCrossCountry`);
  return response.data;
};

export default {
  getBidTotalsCrossCountry,
};
