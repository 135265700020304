
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanLoadFormSelectDelivery from '@/components/loads/form/domestic/PlanLoadFormSelectDelivery.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';

interface planLoadFormSelectCommodity {
  commodities: Array<any>
}

export default defineComponent({
  name: 'PlanLoadFormSelectCommodity',
  components: { PlanButtonIcon, PlanSelect, PlanLoadFormSelectDelivery },
  props: {
    index: { type: Number, required: true, default: -1 },
    possibleCommodities: { type: Array, required: true },
    possibleDeliveries: { type: Array, required: true },
    deliveries: { type: Number, required: true, default: 0 }
  },
  emits: ['change'],
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive<planLoadFormSelectCommodity>({
      commodities: []
    })

    data.commodities = [...props.possibleCommodities]

    const addCommodity = (index: string) => {
      data.commodities[Number(index)].isActive = true
      update()
    }

    const getCommoditiesToAdd = computed(() => {
      let result: Array<any> = []
      data.commodities.forEach((commodity: any, index: number) => {
        if (!commodity.isActive) {
          result.push({
            label: commodity.commodityDescription,
            value: index.toString()
          })
        }
      })
      return result
    })

    const getPackageType = (packageValue: number) => {
      const searchedPackage = packageTypes.value.find((packageType: any) => packageType.value === packageValue)
      return searchedPackage.label
    }

    const packageTypes = computed(() => {
      let result: Array<any> = [];
      store.getters['LoadsStore/packageTypes'].FTL?.forEach((packageType: any) => {
        result.push({
          label: packageType.fullName,
          value: packageType.id.toString(),
        });
      });

      return result;
    })

    const removeCommodity = (index: number) => {
      data.commodities[index].isActive = false
      update()
    }

    const storeCommodities = computed(() => {
      return store.getters['NewFtlLoad/getCommodities']
    })

    const update = () => {
      data.commodities.forEach((commodity: any, index: number) => {
        if (store.getters['NewFtlLoad/getCommodities'][index].weight < commodity.weight) {
          commodity.weight = store.getters['NewFtlLoad/getCommodities'][index].weight
        }
        if (store.getters['NewFtlLoad/getCommodities'][index].units < commodity.units) {
          commodity.units = store.getters['NewFtlLoad/getCommodities'][index].units
        }
      })

      emit('change', {
        index: props.index,
        commodities: data.commodities
      })
    }

    const updateDeliveries = (deliveryData: any) => {
      data.commodities[deliveryData.index].deliverIn = deliveryData.selectedDeliveries
      update()
    }

    const weightType = computed(() => {
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'lbs':'kgs'
    })

    return {
      ...toRefs(data),
      addCommodity,
      getCommoditiesToAdd,
      getPackageType,
      packageTypes,
      removeCommodity,
      storeCommodities,
      update,
      updateDeliveries,
      weightType
    }
  }
})
