
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { XCircleIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';

// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';
import PlanCountrySelector from '@/components/forms/PlanCountrySelector.vue';
// Utils
import { formatDateStringReverse } from '@/utils/formatDates';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
// Utils
import { timezones } from '@/services/timezones/index';
import PlanNewContactTags from '@/components/forms/PlanNewContactTags.vue';

interface Carrier {
  name: string;
  lastname: string;
  email: string;
  prefix: string;
  phone: string;
  company: string;
  driverId: string;
  tags: Array<string>;
}

interface Insurance {
  number: string;
  limit: number;
  date: Date;
  file: File;
  mediaObject: any;
}

export default defineComponent({
  name: 'AddCarrierModal',
  components: {
    PlanNewContactTags,
    PlanCountrySelector,
    DatePickerComponent,
    ErrorMessage,
    Field,
    Form,
    GoogleAutocomplete,
    InputAmericanFormat,
    PhoneNumberSelector,
    TrashIcon,
    XCircleIcon,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    notFetchCarriers: {
      type: Boolean,
    },
  },
  data() {
    return {
      allowed: true,
      addDriver: false,
      address: '',
      cargoInsurance: {} as Insurance,
      cargoInsuranceId: null,
      carrierAdded: {},
      company: null,
      companyId: null,
      currentDate: new Date(),
      country: 0,
      dotNumber: '',
      timeZone: '',
      driver: {
        drivingLicense: null,
        name: null,
        surname: null,
      },
      driverSelected: null as any,
      drivers: [] as any,
      email: null,
      fullTruckTransport: false,
      hasTransportAndDeliveryOfAlcoholicBeveragesPermit: false,
      hasBondPermit: false,
      hasHazardousMaterialPermit: false,
      hasOverweightLoadPermit: false,
      hasTeamDriverPermit: false,
      hasTirPermit: false,
      hasTSACard: false,
      hasTWICCard: false,
      isInvalidCargo: false,
      isInvalidInsurance: false,
      hasMoveFullTruckPermit: false,
      hasMovePartialPermit: false,
      hasOversizeLoadPermit: false,
      hasFTLPermit: false,
      hasLTLPermit: false,
      hasPTLPermit: false,
      isSending: false,
      lastname: null,
      lanes: [] as any,
      liabilityInsurance: {} as Insurance,
      liabilityInsuranceId: null,
      mcNumber: '',
      identificationNumber: '',
      name: null,
      partialTransportation: false,
      phone: null,
      prefix: '1',
      selectedOption: 'BROKER',
      validating: false,
      vehicles: [] as any,
      W9CopyMedia: {} as File,
      W9Media: {} as Insurance,
      usaDomestic: false,
      mexDomestic: false,
      transferLoad: false,
      W9MediaId: 0,
      DOTMCCopyMedia: {} as File,
      DOTMCMedia: {} as Insurance,
      DOTMCMediaId: 0,
      carrierTags: [],
    };
  },
  beforeCreate() {
    this.$store.dispatch('LoadsStore/trailers');
    this.$store.dispatch('CountryStore/getCountries');
  },
  computed: {
    isMexico() {
      let findMex = this.$store.getters['CountryStore/getCountries'].find((country) => {
        return country.name === 'Mexico';
      });

      return +findMex?.id === +this.country;
    },
    countryList() {
      let result: Array<any> = [];

      let countries = this.$store.getters['CountryStore/getCountries'];

      if (countries.length === 0) {
        this.$store.dispatch('CountryStore/getCountries');
        countries = this.$store.getters['CountryStore/getCountries'];
      }

      result = countries.filter(
        (item) =>
          item?.region?.name.includes(this.$store.getters['UserStore/getRegion']) &&
          this.country !== item.iso,
      );

      return result;
    },
    timezonesFormated() {
      return timezones.map((time) => {
        return {
          label: time.label,
          value: time.tzCode,
        };
      });
    },
    collapsedSidebar() {
      return this.$store.getters['UserStore/getCollapsedSidebar'];
    },
    countriesList(): any {
      return this.$store.getters['CountryStore/getCountries'];
    },
    currentCompany(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    getCargoInsurance(): any {
      if (this.currentCompany && this.currentCompany.insurances) {
        return this.currentCompany.insurances.find((insurance) => insurance.type === 'CARGO');
      }
      return undefined;
    },
    getLiabilityInsurance(): any {
      if (this.currentCompany && this.currentCompany.insurances) {
        return this.currentCompany.insurances.find((insurance) => insurance.type === 'LIABILITY');
      }
      return undefined;
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
  },
  methods: {
    changeCarrierTags(tags) {
      this.carrierTags = tags;
    },
    changePhone(type, phone) {
      switch (type) {
        case 'prefix':
          this.prefix = phone.prefix;
          break;
        case 'number':
          this.phone = phone.number;
          break;
        default:
          return;
      }
    },
    clearInput(data: any, item: string) {
      if (item === 'origin') {
        data.locationOrigin.city = '';
      }
      if (item === 'destination') {
        data.locationDestiny.city = '';
      }
    },
    timezoneOnChange(event) {
      this.timeZone = event.target.value;
    },

    countryOnchange(event) {
      this.country = event.target.value;
    },

    closeModal() {
      this.$emit('hideModal', 0);
    },
    createDriver(companyId) {
      if (this.drivers.length > 0) {
        this.drivers.forEach((driver) => {
          this.$store.dispatch('CompanyStore/postDriver', {
            ...driver,
            company: { id: companyId },
          });
        });
      }
    },
    async createInsurances() {
      if (
        this.cargoInsurance.number !== undefined &&
        this.cargoInsurance.limit !== undefined &&
        this.cargoInsurance.date !== undefined &&
        this.cargoInsurance.file !== undefined
      ) {
        await this.$store
          .dispatch('ShipmentStore/postInsurances', {
            type: 'CARGO',
            number: this.cargoInsurance.number,
            limitAmount: Number(this.cargoInsurance.limit),
            expirationDate: formatDateStringReverse(this.cargoInsurance.date),
            company: { id: this.companyId },
          })
          .then((response) => {
            this.cargoInsuranceId = response.id;
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });
      }
      if (
        this.liabilityInsurance.number !== undefined &&
        this.liabilityInsurance.limit !== undefined &&
        this.liabilityInsurance.date !== undefined &&
        this.liabilityInsurance.file !== undefined
      ) {
        await this.$store
          .dispatch('ShipmentStore/postInsurances', {
            type: 'LIABILITY',
            number: this.liabilityInsurance.number,
            limitAmount: Number(this.liabilityInsurance.limit),
            expirationDate: formatDateStringReverse(this.liabilityInsurance.date),
            company: { id: this.companyId },
          })
          .then((response) => {
            this.liabilityInsuranceId = response.id;
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });
      }
    },
    createLanes() {
      if (this.lanes.length > 0) {
        this.lanes.forEach((lane) => {
          lane.radiusDistance = +lane.radiusDistance;
          this.$store.dispatch('LoadsStore/postLane', {
            ...lane,
            radiusDistance: Number(lane.radiusDistance),
            company: { id: this.companyId },
          });
        });
      }
    },
    createVehicles() {
      if (this.vehicles.length > 0) {
        this.vehicles.forEach((vehicle) => {
          this.$store.dispatch('CompanyStore/postVehicle', {
            ...vehicle,
            company: { id: this.companyId },
          });
        });
      }
    },
    getCityData(addressData) {
      let location;

      let stateObj = this.states.find(
        (state) => state.iso === addressData.administrative_area_level_1,
      );
      if (!stateObj && addressData.administrative_area_level_2) {
        stateObj = this.states.find(
          (state) => state.name === addressData.administrative_area_level_2,
        );
      }

      let cityString = this.getCityString(addressData);
      if (stateObj?.id) {
        location = {
          lat: addressData.latitude,
          long: addressData.longitude,
          zip: addressData.postal_code,
          city: cityString,
          state: { id: stateObj.id },
          googlePlaceId: addressData.place_id,
        };
      } else {
        location = {
          lat: addressData.latitude,
          long: addressData.longitude,
          zip: addressData.postal_code,
          city: cityString,
          googlePlaceId: addressData.place_id,
        };
      }

      return location;
    },
    getCityString(location) {
      const zip = location?.postal_code || '';
      const city = location?.locality || '';
      const area1 = location?.administrative_area_level_1 || '';
      const area2 =
        location?.administrative_area_level_2 && !area1
          ? location?.administrative_area_level_2
          : '';
      const stateId = location?.state?.id;
      const stateName = stateId
        ? this.states.find((state) => state.id === stateId)?.name || ''
        : '';

      const locationParts = [zip, city, stateName, area1, area2].filter(Boolean);
      return locationParts.join(', ');
    },
    getCountryIsoByLane(lane, type) {
      let countryIso = null;
      switch (type) {
        case 'ORIGIN':
          countryIso = lane.originCountry?.iso;
          break;
        case 'DESTINATION':
          countryIso = lane.destinationCountry?.iso;
          break;
      }

      return countryIso;
    },
    handleClickCountry(country, type, index) {
      switch (type) {
        case 'ORIGIN':
          this.lanes[index].originCountry = country;
          break;
        case 'DESTINATION':
          this.lanes[index].destinationCountry = country;
          break;
      }
    },
    handleChange(type: string) {
      switch (type) {
        case 'cargoInsuranceFile':
          this.cargoInsurance.file = (this.$refs.cargoInsuranceFile as any).files[0];
          break;
        case 'liabilityInsuranceFile':
          this.liabilityInsurance.file = (this.$refs.liabilityInsuranceFile as any).files[0];
          break;
        case 'W9':
          this.W9Media.file = (this.$refs.W9File as any).files[0];
          break;
        case 'DOTMC':
          this.DOTMCMedia.file = (this.$refs.DOTMCFile as any).files[0];
          break;
      }
    },
    onClickAddOther() {
      this.lanes.push({
        originCountry: null,
        locationOrigin: {
          lat: 0,
          long: 0,
          city: '',
          state: { id: null },
        },
        destinationCountry: null,
        locationDestiny: {
          lat: 0,
          long: 0,
          city: '',
          state: { id: null },
        },
        radiusDistance: 0,
      });
    },
    async postMediaObjectCompany(type: string) {
      let insurance = {} as any;
      switch (type) {
        case 'W9':
          insurance = this.W9Media;
          break;
        case 'DOT_MC_NUMBER':
          insurance = this.DOTMCMedia;
          break;
      }
      if (insurance.file) {
        await this.$store
          .dispatch('CompanyStore/postMediaObjectCompany', {
            entityId: this.companyId,
            file: insurance.file,
            type: type,
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });
      }
    },
    async postMediaObjectInsurance(type: string, id: any) {
      const insurance = type === 'CARGO' ? this.cargoInsurance : this.liabilityInsurance;
      if (insurance.file) {
        await this.$store
          .dispatch('ShipmentStore/postMediaObjectInsurance', {
            entityId: id,
            file: insurance.file,
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });
      }
    },
    async validateForm() {
      if (!this.allowed) return;
      this.validating = true;
      const validation = await (this.$refs.form as any).validate();
      this.isInvalidInsurance =
        this.liabilityInsurance.number !== undefined ||
        this.liabilityInsurance.limit !== undefined ||
        this.liabilityInsurance.date !== undefined ||
        this.liabilityInsurance.file !== undefined;
      const isFilledInsurance =
        this.liabilityInsurance.number !== undefined &&
        this.liabilityInsurance.limit !== undefined &&
        this.liabilityInsurance.date !== undefined &&
        this.liabilityInsurance.file !== undefined;

      this.isInvalidCargo =
        this.cargoInsurance.number !== undefined ||
        this.cargoInsurance.limit !== undefined ||
        this.cargoInsurance.date !== undefined ||
        this.cargoInsurance.file !== undefined;
      const isFilledCargo =
        this.cargoInsurance.number !== undefined &&
        this.cargoInsurance.limit !== undefined &&
        this.cargoInsurance.date !== undefined &&
        this.cargoInsurance.file !== undefined;
      if (
        !validation.valid ||
        (this.isInvalidInsurance && !isFilledInsurance) ||
        (this.isInvalidCargo && !isFilledCargo)
      ) {
        return;
      }
      const baseObjectContact = {
        name: this.name,
        email: this.email,
        surname: this.lastname,
        userName: this.email,
        phonePrefix: this.prefix,
        phone: this.phone,
        timeZone: this.timeZone,
        company: {
          transferLoad: this.transferLoad,
          usaDomestic: this.usaDomestic,
          mexDomestic: this.mexDomestic,
          type: this.selectedOption,
          name: this.company,
          dotNumber: this.dotNumber,
          mcNumber: this.mcNumber,
          hasTransportAndDeliveryOfAlcoholicBeveragesPermit:
            this.hasTransportAndDeliveryOfAlcoholicBeveragesPermit,
          hasTirPermit: this.hasTirPermit,
          hasHazardousMaterialPermit: this.hasHazardousMaterialPermit,
          hasTWICCard: this.hasTWICCard,
          hasTSACard: this.hasTSACard,
          hasOversizeLoadPermit: this.hasOversizeLoadPermit,
          hasMovePartialPermit: this.hasMovePartialPermit,
          hasMoveFullTruckPermit: this.hasMoveFullTruckPermit,
          hasFTLPermit: this.hasFTLPermit,
          hasLTLPermit: this.hasLTLPermit,
          hasPTLPermit: this.hasPTLPermit,
          country: { id: this.country },

          hasOverweightLoadPermit: this.hasOverweightLoadPermit,
          hasTeamDriverPermit: this.hasTeamDriverPermit,
          hasBondPermit: this.hasBondPermit,
          identificationNumber: this.identificationNumber,
          tags: this.carrierTags,
          location: {
            address: this.address,
          },
        },
      };

      this.isSending = true;
      this.allowed = false;

      await this.$store
        .dispatch('UserStore/addContact', baseObjectContact)
        .then(async (resp) => {
          this.companyId = resp.company.id;
          this.carrierAdded = resp;
          this.createVehicles();
          this.createDriver(this.companyId);
          await this.createInsurances();
          await this.postMediaObjectCompany('W9');
          await this.postMediaObjectCompany('DOT_MC_NUMBER');
          let company = null;
          await this.$store.dispatch('CompanyStore/getCompany', this.companyId).then((response) => {
            company = response;
            if (response.insurances && response.insurances.length > 0) {
              response.insurances.forEach((insurance) => {
                this.postMediaObjectInsurance(insurance.type, insurance.id);
              });
            }
          });
          this.createLanes();
          BaseComponent.methods?.showToastSuccess(this.$t('message-contact-added'));
          if (!this.notFetchCarriers) {
            await this.$store.dispatch('UserStore/carrierContactList', {
              page: 1,
              name: null,
              type: 'CARRIER|BROKER',
              tags: []
            });
            await this.$store.dispatch('UserStore/carrierNetworkTotal');
          }

          this.$emit('fetch');
          this.$emit('setCompanySearch', company);
          this.$emit('hideModal', this.carrierAdded);
        })
        .catch((err) => {
          const error = err?.response?.data?.detail ?? err?.response?.data['hydra:description'];
          BaseComponent.methods?.showToastError(error);
        })
        .finally(() => {
          this.isSending = false;
          this.allowed = true;
        });
    },
  },
});
