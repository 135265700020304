import moment from 'moment';

export default {
  getDataFilled(state: any) {
    return state.dataFilled;
  },
  getDefaultLoad(state: any) {
    return state.defaultLoad;
  },
  getLoadToSend(state: any) {
    if (state?.loadToSend?.legs) {
      state.loadToSend.legs = state.loadToSend.legs.sort((a, b) => a.legOrder - b.legOrder);
    }
    return state.loadToSend;
  },
  getSelectedContactsLegs(state: any) {
    return state.selectedContactsLegs;
  },
};
