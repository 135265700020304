import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13797353"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "plan-form"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "plan-t-prim-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label.length > 0)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.mandatory)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_popper, {
      show: _ctx.isOpen,
      offsetSkid: "0",
      offsetDistance: "0",
      placement: "bottom-end",
      class: "w-full popper-no-padding"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", {
          class: "flex flex-col overflow-y-auto w-full plan-p-sm plan-gap-xs",
          style: _normalizeStyle({ boxSizing: 'border-box', maxHeight: '200px', width: _ctx.equalSize ? _ctx.selectedWidth + 'px' : '100%', maxWidth: _ctx.equalSize ? _ctx.selectedWidth + 'px' : '100%' })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createBlock(_component_plan_button, {
              onOnClick: ($event: any) => (_ctx.changeValue(item.value)),
              key: item,
              type: "white",
              label: item.label,
              class: "w-full",
              "justify-align": "flex-start"
            }, null, 8, ["onOnClick", "label"]))
          }), 128)),
          (_ctx.addButtonLabel.length > 0)
            ? (_openBlock(), _createBlock(_component_plan_button, {
                key: 0,
                onOnClick: _ctx.addOnClick,
                type: "primary",
                label: _ctx.addButtonLabel,
                class: "w-full"
              }, null, 8, ["onOnClick", "label"]))
            : _createCommentVNode("", true)
        ], 4)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = true)),
          ref: "selectedElement",
          class: _normalizeClass(["plan-div-form flex justify-between items-center plan-gap-xs plan-p-sm w-full", {
            'plan-div-form-hide-left': _ctx.hideLeft,
            'plan-div-form-hide-right': _ctx.hideRight,
            'plan-div-form-show-right-line': _ctx.rightLine
           }])
        }, [
          (_ctx.selectedValue !== '')
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.selectedLabel), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.placeholder), 1)),
          _createVNode(_component_plan_icon, {
            name: "chevron-down",
            "is-svg": "",
            "icon-color": "#4F5264"
          })
        ], 2)
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}