import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border border-gray-300 rounded-xl p-6 mb-10 flex justify-end md:justify-between relative" }
const _hoisted_2 = { class: "menu-filter md:flex flex-col justify-start absolute bg-corp origin-top-right top-[70px] -right-2 p-5 z-10 rounded-xl text-white md:text-black md:relative md:left-0 md:top-0 md:bg-white md:p-0" }
const _hoisted_3 = { class: "flex flex-wrap md:flex-row justify-between" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-row"
}
const _hoisted_5 = { class: "flex items-center gap-4 mr-10" }
const _hoisted_6 = { class: "flex items-center self-center justify-center gap-x-1" }
const _hoisted_7 = { class: "font-bold plan-text-xs plan-t-prim-200" }
const _hoisted_8 = {
  key: 1,
  class: "flex items-center mr-10"
}
const _hoisted_9 = {
  for: "default-checkbox2",
  class: "ml-2 font-medium"
}
const _hoisted_10 = {
  key: 2,
  class: "flex items-center mr-10"
}
const _hoisted_11 = {
  for: "default-checkbox3",
  class: "ml-2 font-medium"
}
const _hoisted_12 = { class: "flex items-center mr-10" }
const _hoisted_13 = ["checked"]
const _hoisted_14 = {
  for: "default-checkbox4",
  class: "ml-2 font-medium"
}
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "flex flex-row gap-x-2 items-center" }
const _hoisted_18 = {
  key: 3,
  class: "flex items-center ml-6 md:mt-2 lg:mt-0 xl:mt-2 2xl:mt-0"
}
const _hoisted_19 = {
  for: "historicalRange",
  class: "block font-medium mr-2"
}
const _hoisted_20 = {
  class: "plan-div-form w-fit flex justify-center items-center plan-pl-sm plan-pr-sm",
  style: {"min-width":"160px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_popper = _resolveComponent("popper")!
  const _component_SpecialRequirementsFilter = _resolveComponent("SpecialRequirementsFilter")!
  const _component_plan_select_simple = _resolveComponent("plan-select-simple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isCrossBorderLoad)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.load.legOrder !== 3)
                  ? (_openBlock(), _createBlock(_component_plan_input_checkbox, {
                      key: 0,
                      label: _ctx.$t('MX Brokers'),
                      preValue: true,
                      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('filterByCountryTypeUser', _ctx.MEXICO.id, 'BROKER', $event)))
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_plan_input_checkbox, {
                    label: _ctx.$t('MX Carriers') + (_ctx.load.legOrder !== 1 ? '(B1)' : ''),
                    preValue: true,
                    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('filterByCountryTypeUser', _ctx.MEXICO.id, 'CARRIER', $event)))
                  }, null, 8, ["label"]),
                  _createVNode(_component_popper, {
                    hover: "",
                    arrow: "",
                    placement: 'bottom',
                    offsetSkid: "0",
                    offsetDistance: "8"
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.load.legOrder === 1
                        ? _ctx.$t('only-mex-carrier')
                        : _ctx.load.legOrder === 2
                        ? _ctx.$t('only-mex-carrier-b1')
                        : _ctx.$t('mex-carrier-b1-usa')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_plan_icon, {
                        name: "info",
                        "is-svg": "",
                        "icon-color": "black",
                        size: "19"
                      })
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.load.legOrder === 3)
                  ? (_openBlock(), _createBlock(_component_plan_input_checkbox, {
                      key: 1,
                      label: _ctx.$t('USA Carriers'),
                      preValue: true,
                      onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('filterByCountryTypeUser', _ctx.USA.id, 'CARRIER', $event)))
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isCrossBorderLoad || _ctx.load.legOrder === 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("input", {
                id: "default-checkbox2",
                type: "checkbox",
                value: "",
                checked: "",
                class: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500",
                onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('updateSearchTypeUser', 'BROKER')))
              }, null, 32),
              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('brokers')), 1)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isCrossBorderLoad)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("input", {
                id: "default-checkbox3",
                type: "checkbox",
                value: "",
                checked: "",
                class: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 cursor-pointer",
                onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('updateSearchTypeUser', 'CARRIER')))
              }, null, 32),
              _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('carriers')), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("input", {
            id: "default-checkbox4",
            type: "checkbox",
            class: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 cursor-pointer",
            onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('updateSearchTypeUser', 'TRAILER'))),
            checked: _ctx.load?.trailerType?.id === _ctx.trailerSelected.id
          }, null, 40, _hoisted_13),
          _createElementVNode("label", _hoisted_14, [
            (!_ctx.isEuropeCompany())
              ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(`${_ctx.$t('trailer-type')}: ${_ctx.trailerSelected?.description}`), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(`${_ctx.$t('trailer-type')}: ${_ctx.translateTrailer(_ctx.trailerSelected?.description)}`), 1))
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_SpecialRequirementsFilter, { onChange: _ctx.changeSelectedPermits }, null, 8, ["onChange"])
        ]),
        (_ctx.showHistoricalRange)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('historical-range')), 1),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_plan_select_simple, {
                  onChange: _ctx.changePeriod,
                  value: "LAST_3_MONTHS",
                  items: _ctx.periodTypes,
                  "default-classes-only": true,
                  placeholder: ""
                }, null, 8, ["onChange", "items"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}