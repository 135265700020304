import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-center items-center plan-rounded-xs plan-p-md bg-white" }
const _hoisted_2 = {
  class: "w-full flex flex-col plan-rounded-xs plan-gap-md bg-white",
  style: {"max-width":"1200px"}
}
const _hoisted_3 = { class: "flex justify-start items-center plan-gap-md" }
const _hoisted_4 = { class: "flex justify-center items-center plan-gap-sm" }
const _hoisted_5 = { class: "w-full flex justify-between items-start plan-gap-sm" }
const _hoisted_6 = { class: "w-full flex justify-end" }
const _hoisted_7 = { class: "flex justify-start items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_company_modal = _resolveComponent("company-modal")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_load_form_multiple_loads = _resolveComponent("plan-load-form-multiple-loads")!
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_add_customer = _resolveComponent("add-customer")!
  const _component_plan_load_form_stop = _resolveComponent("plan-load-form-stop")!
  const _component_plan_load_form_trailer_and_commodities = _resolveComponent("plan-load-form-trailer-and-commodities")!
  const _component_plan_load_form_attach_documents = _resolveComponent("plan-load-form-attach-documents")!
  const _component_plan_load_form_special_requirements = _resolveComponent("plan-load-form-special-requirements")!
  const _component_plan_load_form_additional_stops = _resolveComponent("plan-load-form-additional-stops")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showCompanyModal)
        ? (_openBlock(), _createBlock(_component_company_modal, {
            key: 0,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCompanyModal = false))
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["w-full flex plan-gap-md", _ctx.actualWidth < 700 ? 'flex-col justify-start items-start':'justify-between items-start'])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", {
              style: _normalizeStyle(`max-width: ${_ctx.actualTitleMaxWidth}`),
              class: "font-bold plan-t-h2 truncate"
            }, "1. " + _toDisplayString(_ctx.$t('shipment-data')), 5),
            (_ctx.isValidatedForm.result)
              ? (_openBlock(), _createBlock(_component_plan_icon, {
                  key: 0,
                  name: "fill-circle-check",
                  "icon-color": "#00DB50",
                  "is-svg": ""
                }))
              : _createCommentVNode("", true)
          ]),
          (_ctx.additionalStops.length > 0)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeView('freight'))),
                style: _normalizeStyle(`max-width: ${_ctx.actualTitleMaxWidth}`),
                class: _normalizeClass(["font-bold plan-t-h2 plan-t-prim-100 truncate", _ctx.isValidatedForm.result ? 'cursor-pointer':'cursor-not-allowed'])
              }, " 2. " + _toDisplayString(_ctx.$t('freight-info')), 7))
            : _createCommentVNode("", true),
          _createElementVNode("p", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeView('quote'))),
            style: _normalizeStyle(`max-width: ${_ctx.actualTitleMaxWidth}`),
            class: _normalizeClass(["font-bold plan-t-h2 plan-t-prim-100 truncate", _ctx.isValidatedForm.result ? 'cursor-pointer':'cursor-not-allowed'])
          }, _toDisplayString(`${_ctx.additionalStops.length > 0 ? 3 : 2}`) + ". " + _toDisplayString(_ctx.$t('quote')), 7)
        ]),
        _createVNode(_component_plan_load_form_multiple_loads, { onChange: _ctx.changeMultipleLoads }, null, 8, ["onChange"])
      ], 2),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_plan_input_text, {
          onChange: _ctx.changePoNumber,
          "model-value": _ctx.poNumber,
          "onUpdate:model-value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.poNumber) = $event)),
          label: _ctx.$t('po-number'),
          style: {"width":"250px"}
        }, null, 8, ["onChange", "model-value", "label"]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_add_customer, {
            onUpdate: _ctx.updateCustomers,
            data: [],
            loadId: null,
            type: "create",
            "new-interface": ""
          }, null, 8, ["onUpdate"])
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["flex plan-gap-sm", _ctx.actualWidth < 750 ? 'flex-col justify-start items-start':'justify-between items-start'])
      }, [
        _createVNode(_component_plan_load_form_stop, {
          onUpdateStop: _ctx.updatePickUp,
          type: "PICKUP_INITIAL",
          "only-country": "mx"
        }, null, 8, ["onUpdateStop"]),
        _createVNode(_component_plan_load_form_stop, {
          onUpdateStop: _ctx.updateDelivery,
          type: "DELIVERY_FINAL",
          "only-country": "mx",
          "min-date": _ctx.getPickupDate
        }, null, 8, ["onUpdateStop", "min-date"])
      ], 2),
      _createVNode(_component_plan_load_form_trailer_and_commodities, { "only-country": "mx" }),
      _createVNode(_component_plan_load_form_attach_documents, { onlyCountry: "mx" }),
      _createVNode(_component_plan_load_form_special_requirements, { onChange: _ctx.changeSpecialRequirements }, null, 8, ["onChange"]),
      _createVNode(_component_plan_load_form_additional_stops, { "only-country": "mx" }),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('billing-data', { company: _ctx.currentUser.companyName })), 1),
        _createVNode(_component_plan_text_button, {
          onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCompanyModal = !_ctx.showCompanyModal)),
          label: _ctx.$t('see-modify')
        }, null, 8, ["label"])
      ])
    ])
  ]))
}