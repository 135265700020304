import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full rounded border plan-border-prim-50 plan-p-md plan-gap-md flex flex-col" }
const _hoisted_2 = { class: "font-bold plan-t-xs plan-t-prim-200" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "flex justify-end items-center"
}
const _hoisted_5 = {
  key: 2,
  class: "flex flex-col plan-gap-xs"
}
const _hoisted_6 = { class: "plan-t-prim-100 plan-t-xxs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_file = _resolveComponent("plan-input-file")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('attach-document')), 1),
    (_ctx.onlyCountry === 'mx')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_plan_input_file, {
            onChange: _ctx.changeLetterOfInstructions,
            label: "Letter of instructions",
            message: "This file is mandatory",
            "max-size": 50,
            types: ['application/pdf', 'image/*'],
            typesToShow: ".pdf, jpg, .png",
            mandatory: ""
          }, null, 8, ["onChange"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.showAddAdditionalAttachments)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_plan_text_button, {
            onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddAdditionalAttachments = true)),
            label: _ctx.$t('add-additional-attachments')
          }, null, 8, ["label"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showAddAdditionalAttachments)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('no-mandatory-attached-documents-message')), 1),
          _createVNode(_component_plan_input_file, {
            onChange: _ctx.changeDocuments,
            multiple: "",
            "max-size": 50,
            types: ['application/pdf', 'image/*'],
            typesToShow: ".pdf, jpg, .png"
          }, null, 8, ["onChange"])
        ]))
      : _createCommentVNode("", true)
  ]))
}