import globalAxios, { isAxiosError } from 'axios';

export default {
  async addAgentToLoad(data: any) {
    const response = await globalAxios.post(
      `/loads/${data.loadId}/customsAgent/${data.customAgentId}`,
      {},
    );
    return Promise.resolve(response.data);
  },
  async postCustomAgent(data: any) {
    const response = await globalAxios.post(`/users/createCustomsAgent`, data);
    return Promise.resolve(response.data);
  },
  async setPaperWorkAsReady(loadId: any) {
    const response = await globalAxios.post(`/loads/${loadId}/setPaperWorkAsReady`, {});
    return Promise.resolve(response.data);
  },
  async resendEmailRequestCrossBorderNotifyPickup(loadId: any) {
    const response = await globalAxios.post(
      `/loads/${loadId}/resendEmailRequestCrossBorderNotifyPickup`,
      {},
    );
    return Promise.resolve(response.data);
  },
  async resendEmailRequestUpdateTransferStatus(loadId: any) {
    const response = await globalAxios.post(
      `/loads/${loadId}/resendEmailRequestUpdateTransferStatus`,
      {},
    );
    return Promise.resolve(response.data);
  },
  async notifyCrossBorderLoadPickedUp(loadId: any) {
    const response = await globalAxios.post(`/loads/${loadId}/notify/crossBorderLoadPickup`, {});
    return Promise.resolve(response.data);
  },
};
