export default {
  getActiveForm (state: any) {
    return state.activeForm
  },
  getAverageStats (state: any) {
    return state.averageStats
  },
  getDatePriceStats (state: any) {
    return state.datePriceStats
  },
  getDelivery (state: any) {
    return state.delivery
  },
  getIsInstantQuoteFormValidated (state: any) {
    let result = true
    if (state.pickup === null) result = false
    if (state.pickupDate === '') result = false
    if (state.delivery === null) result = false
    if (state.deliveryDate === '') result = false
    if (state.trailerId === null) result = false
    if (state.packageType === null) result = false
    if (state.commodity === '') result = false
    if (state.weight === null || state.weight <= 0) result = false
    if (state.units === null || state.units <= 0) result = false

    return result
  },
  getInstantQuoteFormValues (state: any) {
    return {
      pickup: state.pickup,
      pickupDate: state.pickupDate,
      delivery: state.delivery,
      deliveryDate: state.deliveryDate,
      trailerId: state.trailerId,
      commodity: state.commodity,
      weight: state.weight,
      units: state.units,
      packageType: state.packageType
    }
  },
  getPickup (state: any) {
    return state.pickup
  }
}