import instantQuote from '@/modules/planning/instantQuote/_api';

export default {
  async getCompaniesForInstantQuote (context: any, data: any) {
    return await instantQuote.getCompaniesForInstantQuote(data.page, data.origin, data.destiny, data.trailer, data.trailerStats, data.companyType, data.permits, data.lane)
  },
  async getDatePriceStats (context: any, data: any) {
    return await instantQuote.getDatePriceStats(data.origin, data.destiny, data.trailer, data.period, data.lane)
  },
  async getPricingInfo (context: any, data: any) {
    return await instantQuote.getPricingInfo(data.origin, data.originCity, data.originPlaceId, data.destiny, data.destinyCity, data.destinyPlaceId, data.type)
  },
  async getShipmentAverageStats (context: any, data: any) {
    return await instantQuote.getShipmentAverageStats(data.origin, data.destiny, data.trailer, data.period, data.lane)
  }
}