
import { defineComponent, ref, computed, watch } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
export default defineComponent({
  name: 'PlanButtonGroup',
  components: { PlanButton },
  props: {
    buttons: { type: Array, required: true },
    activeButtonIndex: { type: Number, required: false, default: 0 },
    type: { type: String, required: false, default: 'primary' },
    isDisableAutoChangeButton: { type: Boolean, required: false, default: false },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const selectedButton = ref(props.activeButtonIndex);

    const buttonColor = (index: number) => {
      if (selectedButton.value !== index) {
        if (props.type === 'primary') return '#0097FF';
        if (props.type === 'primary-dark') return '#151830';
        if (props.type === 'success') return '#00DB50';
        if (props.type === 'warning') return '#E6DB22';
        if (props.type === 'error') return '#DD524C';
      } else {
        return 'white';
      }
    };
    const buttonSize = (index: number) => {
      let result = 'auto-md';

      if (index === 0) {
        result = 'auto-md-first';
      } else if (index === props.buttons.length - 1) {
        result = 'auto-md-last';
      }

      return result;
    };

    const changeActiveButton = (index: number) => {
      selectedButton.value = index;
      emit('change', index);
    };

    watch(
      () => props.activeButtonIndex,
      (newValue) => {
        if (newValue !== null) {
          selectedButton.value = newValue;
        }
      },
    );

    if (!props.isDisableAutoChangeButton) changeActiveButton(props.activeButtonIndex);

    return {
      selectedButton,
      buttonColor,
      buttonSize,
      changeActiveButton,
    };
  },
});
