import { createI18n } from 'vue-i18n';

import * as en from '@/resources/locales/en.json';
import * as esES from '@/resources/locales/es.json';
import * as esMX from '@/resources/locales/esMX.json';

const userLocale = localStorage.getItem('userLocale') || navigator.language || 'en';
localStorage.setItem('userLocale', userLocale);

const i18n = createI18n({
  legacy: false,
  locale: userLocale,
  fallbackLocale: 'en',
  fallbackWarn: false,   
  missingWarn: false, 
  messages: {
    en: en,              
    'es-ES': esES,                    
  },
});

export default i18n;
