export default {
  clearStore (state: any) {
    state.type = 'REGULAR'
    state.pickup = null
    state.delivery = null
    state.specialRequirements = {
      teamDriver: false,
      bondedPermission: false,
      tsaCard: false,
      twicCard: false,
      tirCable: false,
      hazardousLoad: false,
      specialPermits: false,
      specialPermitsValue: '',
    }
    state.commodities = []
    state.stop = [
      {
        type: 'PICKUP_INITIAL',
        executionDate: '',
        location: {},
        portStop: false,
        airportStop: false,
        observations: '',
        deliveryStop: null,
        lumperFeePrice: 0,
        multiStopIdentifierArray: [],
        lumperFee: false,
        multiStopIdentifier: '',
        executionWindowEndTime: null,
        executionWindowStartTime: null,
        companyName: '',
        address: '',
        phoneNumber: '',
        phonePrefix: '',
        defaultCountrySelected: {},
        poNumber: '',
        contact: '',
        warehouseTemplate: null,
      },
      {
        type: 'DELIVERY_FINAL',
        executionDate: '',
        location: {},
        portStop: false,
        airportStop: false,
        multiStopIdentifier: '',
        observations: '',
        lumperFeePrice: 0,
        multiStopIdentifierArray: [],
        lumperFee: false,
        executionWindowEndTime: null,
        executionWindowStartTime: null,
        companyName: '',
        deliveryStop: null,
        address: '',
        phoneNumber: '',
        phonePrefix: '',
        defaultCountrySelected: {},
        poNumber: '',
        contact: '',
        warehouseTemplate: null,
      },
    ]
    state.additionalStops = []
    state.trailerData = {
      id: -1,
      min: null,
      max: null,
      trailerId: -1
    }
    state.freightInfo = null
    state.customers = []
    state.letterOfInstructions = null
    state.directAssign = null
    state.multipleLoads = 1
    state.templateData = null
    state.files = null
  },
  setAdditionalStops (state: any, newValue: any) {
    state.additionalStops = newValue
  },
  setDelivery (state: any, newValue: any) {
    state.delivery = newValue
  },
  setLetterOfInstructions (state: any, newValue: any) {
    state.letterOfInstructions = newValue
  },
  setFiles (state: any, newValue: any) {
    state.files = newValue
  },
  setMultipleLoads(state: any, quantity: number) {
    state.multipleLoads = quantity;
  },
  setPoNumber(state: any, newValue: string) {
    state.poNumber = newValue;
  },
  setPickup (state: any, newValue: any) {
    state.pickup = newValue
  },
  setSpecialRequirements(state: any, newSpecialRequirements: any) {
    state.specialRequirements = newSpecialRequirements;
  },
  setTrailerData(state: any, newTrailerData: any) {
    state.trailerData = newTrailerData;
  },
  setType(state: any, newType: string) {
    state.type = newType;
  },
  setCommodities(state: any, newCommodity: any) {
    state.commodities = newCommodity
  },
  setCommodityObservations (state: any, newValue: string) {
    state.commodityObservations = newValue
  },
  setStops(state: any, stops: any) {
    state.stops = stops;
  },
  setFreightInfo (state: any, newValue: any) {
    state.freightInfo = newValue
  },
  setCustomers (state: any, newValue: any) {
    state.customers = newValue
  },
  setDirectAssign (state: any, newValue: any) {
    state.directAssign = newValue
  },
  setTemplateData (state: any, newValue: any) {
    state.templateData = newValue
  }
};
