
import { defineComponent, reactive, toRefs, computed, onMounted, watch } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import DriverContent from '@/modules/DriverTracking/_views/_components/DriverContent.vue';
import DriverFooter from '@/modules/DriverTracking/_views/_components/DriverFooter.vue';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

interface carrierBase {
  load: any;
  loadingLoad: boolean;
}

export default defineComponent({
  name: 'DriverRouteStopUpdate',
  components: {
    PlanDefaultCarrierView,
    DriverContent,
    DriverFooter,
  },
  props: {
    id: { type: String, required: false, default: null },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();

    onMounted(() => {
      if (Object.keys(store.getters['DriverTrackingStore/currentStopTracking']).length === 0) {
        store.dispatch('CarrierStore/getLoadInfoForCarrier', props.id).then(() => {
          const firstStopWithEmptyMediaObjects = getLoadData.value.stops.find(
            (stop) =>
              !stop.mediaObjects || stop.mediaObjects.length === 0 || stop.status !== 'DEPARTED',
          );

          store.commit(
            'DriverTrackingStore/setCurrentStopTracking',
            firstStopWithEmptyMediaObjects,
          );
        });
      }
    });

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const getLoadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const currentStopTracking = computed(() => {
      return store.getters['DriverTrackingStore/currentStopTracking'];
    });

    const data = reactive({
      status: 'notInWarehouse',
      file: null as any,
      incidenceObject: {
        incidence: '',
        file: null as any,
        incidenceId: null,
      },
      placeId: '',
      observations: '',
      currentLocation: '',
      loadingPostNotifyPosition: false,
      loadingPosition: false,
    });

    const updateFile = (evt) => {
      data.file = evt;
    };

    watch(
      currentStopTracking,
      (newVal) => {
        if (newVal) {
          if (newVal.hasTruckNear && newVal.status === 'PRE-ARRIVAL') {
            data.status = 'notInWarehouse';
            store.dispatch('DriverTrackingStore/setStopArrivalInterfaceOpenedByDriver', props.id);
          } else if (newVal.status === 'ARRIVED') {
            data.status = 'startMarking';
          } else if (newVal.status === 'STARTED') {
            data.status = 'endMarking';
          } else if (newVal.status === 'FINISHED') {
            if (newVal.mediaObjects.length > 0) {
              data.status = 'warehouseOutput';
              store.dispatch('DriverTrackingStore/setStopDepartInterfaceOpened', props.id);
            } else {
              data.status = 'uploadDocuments';
            }
          }
        }
      },
      { immediate: true },
    );

    const currentUser = computed(() => {
      return store.getters['UserStore/getCurrentUser'];
    });

    const updateIncidence = (object) => {
      data.incidenceObject = object;
    };

    const updateStatus = (status) => {
      data.status = status;
    };

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const isDriver = computed(() => {
      return store.getters['CarrierStore/getIsDriver'];
    });

    return {
      ...toRefs(data),
      actualWidth,
      currentStopTracking,
      isDriver,
      loadData,
      updateStatus,
      updateFile,
      updateIncidence,
    };
  },
});
