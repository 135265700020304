import crossBorderApi from '@/services/crossBorder/_api';
// Resources
import i18n from '@/resources/locales';
const $t = i18n.global.t;

export default {
  // CustomAgent
  async addAgentToLoad({ getters, commit, dispatch, rootGetters }, data: any) {
    return await crossBorderApi.addAgentToLoad(data);
  },
  async postCustomAgent({ getters, commit, dispatch, rootGetters }, data: any) {
    return await crossBorderApi.postCustomAgent(data);
  },
  async setPaperWorkAsReady({ getters, commit, dispatch, rootGetters }, data: any) {
    return await crossBorderApi.setPaperWorkAsReady(data);
  },
  async resendEmailRequestCrossBorderNotifyPickup(
    { getters, commit, dispatch, rootGetters },
    data: any,
  ) {
    return await crossBorderApi.resendEmailRequestCrossBorderNotifyPickup(data);
  },
  async resendEmailRequestUpdateTransferStatus(
    { getters, commit, dispatch, rootGetters },
    data: any,
  ) {
    return await crossBorderApi.resendEmailRequestUpdateTransferStatus(data);
  },
  async notifyCrossBorderLoadPickedUp({ getters, commit, dispatch, rootGetters }, data: any) {
    return await crossBorderApi.notifyCrossBorderLoadPickedUp(data);
  },
  // Validations
  async validateLoad({ getters, commit, dispatch, rootGetters }) {
    const load = getters.getLoadToSend;
    const isInvalidLegs = await dispatch('CrossBorderStore/validateLegs', load, { root: true });
    const isInvalidTrailer = await dispatch('CrossBorderStore/validateTrailerType', load, {
      root: true,
    });
    const isInvalidCommodities = await dispatch('CrossBorderStore/validateCommodities', load, {
      root: true,
    });
    if (!isInvalidLegs && !isInvalidTrailer && !isInvalidCommodities) {
      dispatch(
        'UserStore/updateToast',
        {
          text: $t('data-validated'),
          type: 'success',
          visible: true,
        },
        { root: true },
      );
    }
    return !isInvalidLegs && !isInvalidTrailer && !isInvalidCommodities;
  },
  validateLegs({ getters, commit, dispatch, rootGetters }, load) {
    let error = false;
    load.legs.forEach((leg) => {
      leg.stops.forEach((stop) => {
        if (!stop.location.googlePlaceId || stop.location.googlePlaceId === 'undefined') {
          dispatch(
            'UserStore/updateToast',
            {
              text: $t('required-field-x', { field: $t(stop.type.toLowerCase()) }),
              type: 'error',
              visible: true,
            },
            { root: true },
          );
          error = true;
        }
        if (!stop.executionDate || stop.executionDate === '') {
          dispatch(
            'UserStore/updateToast',
            {
              text: $t('required-field-x', { field: $t('date') }),
              type: 'error',
              visible: true,
            },
            { root: true },
          );
          error = true;
        }
      });
    });
    return error;
  },
  validateTrailerType({ getters, commit, dispatch, rootGetters }, load) {
    if (!load.trailerType.id) {
      dispatch(
        'UserStore/updateToast',
        {
          text: $t('required-field-x', { field: $t('trailer') }),
          type: 'error',
          visible: true,
        },
        { root: true },
      );
      return true;
    }
  },
  validateCommodities({ getters, commit, dispatch, rootGetters }, load) {
    load.commodityMasters.forEach((commodity) => {
      if (!commodity.commodityName) {
        dispatch(
          'UserStore/updateToast',
          {
            text: $t('required-field-x', { field: $t('commodity') }),
            type: 'error',
            visible: true,
          },
          { root: true },
        );
        return true;
      }
      if (!commodity.weight) {
        dispatch(
          'UserStore/updateToast',
          {
            text: $t('required-field-x', { field: $t('weight') }),
            type: 'error',
            visible: true,
          },
          { root: true },
        );
        return true;
      }
      if (!commodity.nPallets) {
        dispatch(
          'UserStore/updateToast',
          {
            text: $t('required-field-x', { field: $t('nPallets') }),
            type: 'error',
            visible: true,
          },
          { root: true },
        );
        return true;
      }
      if (!commodity.packageType.id) {
        dispatch(
          'UserStore/updateToast',
          {
            text: $t('required-field-x', { field: $t('package') }),
            type: 'error',
            visible: true,
          },
          { root: true },
        );
        return true;
      }
    });
  },
};
