
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';

export default defineComponent({
  mixins: [BaseComponent, CountryMixins],
  data() {
    return {
      count: 0,
    };
  },
  components: {},
  props: {},
  computed: {},
  methods: {},
  created(): void {
    //
  },
  mounted(): void {
    //
  },
  updated(): void {
    //
  },
  beforeUnmount(): void {
    //
  },
  unmounted(): void {
    //
  },
});
