import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menu-assign border-b border-gray-300 bg-bg" }
const _hoisted_2 = { class: "xl:container mx-auto w-full flex justify-around" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", {
        class: _normalizeClass(_ctx.currentView === 'quoting' ? 'active' : '')
      }, [
        _createElementVNode("button", {
          class: "flex justify-center items-center md:px-6 space-x-2 py-5 text-sm sm:text-xl font-semibold text-center",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', 'quoting')))
        }, [
          (
                        _ctx.showCheck === 'quoting' ||
                        _ctx.showCheck === 'assignment' ||
                        _ctx.showCheck === 'course' ||
                        _ctx.showCheck === 'finished'
                    )
            ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
                key: 0,
                class: "w-8 h-8 text-green-500"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.currentLoad.source === 'RFP' ? _ctx.$t('ranking') : _ctx.$t('quote')), 1)
        ])
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass({
                active: _ctx.currentView === 'assignment',
            })
      }, [
        _createElementVNode("button", {
          class: _normalizeClass([{
                    'disable-button':
                        _ctx.currentLoad.legs[_ctx.currentLegIndex].status === 'QUOTING' ||
                        !_ctx.currentLoad.legs[_ctx.currentLegIndex].shipment.assignedCompany

                }, "flex justify-center items-center md:px-6 space-x-2 py-5 text-sm sm:text-xl font-semibold text-center"]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (
                    (_ctx.currentLoad.legs[_ctx.currentLegIndex].status !== 'QUOTING' && _ctx.currentLoad.legs[_ctx.currentLegIndex].shipment.assignedCompany)
                        ? _ctx.$emit('change', 'assignment')
                        : null
                    ))
        }, [
          (
                        _ctx.showCheck === 'assignment' ||
                        _ctx.showCheck === 'course' ||
                        _ctx.showCheck === 'finished'
                    )
            ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
                key: 0,
                class: "w-8 h-8 text-green-500"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.currentLoad.legs[_ctx.currentLegIndex].status === 'QUOTING' ||
                            _ctx.currentLoad.legs[_ctx.currentLegIndex].status === 'CONFIRMED'
                            ? _ctx.currentLoad.legs[_ctx.currentLegIndex].status === 'CONFIRMED'
                                ? _ctx.$t('confirmed')
                                : _ctx.$t('assignment')
                            : _ctx.$t('assigned')), 1)
        ], 2)
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass(_ctx.currentView === 'course' ? 'active' : '')
      }, [
        _createElementVNode("button", {
          class: _normalizeClass([{
                    'disable-button':
                        _ctx.currentLoad.legs[_ctx.currentLegIndex].status !== 'FINISHED' &&
                        _ctx.currentLoad.legs[_ctx.currentLegIndex].status !== 'IN_PROGRESS' &&
                        _ctx.currentLoad.legs[_ctx.currentLegIndex].status !== 'ASSIGNED' &&
                        !_ctx.rateConfirmation,
                }, "flex justify-center items-center md:px-6 space-x-2 py-5 text-sm sm:text-xl font-semibold text-center"]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (
                    _ctx.currentLoad.legs[_ctx.currentLegIndex].status === 'FINISHED' ||
                        _ctx.currentLoad.legs[_ctx.currentLegIndex].status === 'IN_PROGRESS' ||
                        _ctx.currentLoad.legs[_ctx.currentLegIndex].status === 'ASSIGNED' ||
                        _ctx.rateConfirmation
                        ? _ctx.$emit('change', 'course')
                        : null
                    ))
        }, [
          (_ctx.showCheck === 'course' || _ctx.showCheck === 'finished')
            ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
                key: 0,
                class: "w-8 h-8 text-green-500"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('in-course')), 1)
        ], 2)
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass(_ctx.currentView === 'finished' ? 'active' : '')
      }, [
        _createElementVNode("button", {
          class: _normalizeClass([{ 'disable-button': _ctx.currentLoad.legs[_ctx.currentLegIndex].status !== 'FINISHED' }, "flex justify-center items-center md:px-6 space-x-2 py-5 text-sm sm:text-xl font-semibold text-center"]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (
                    _ctx.currentLoad.legs[_ctx.currentLegIndex].status === 'FINISHED'
                        ? _ctx.$emit('change', 'finished')
                        : null
                    ))
        }, [
          (_ctx.showCheck === 'finished')
            ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
                key: 0,
                class: "w-8 h-8 text-green-500"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('finished')), 1),
          (_ctx.isCrossBorderLoad)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(`${_ctx.currentLoad.legs.filter((item) => item.status === 'FINISHED').length
                        }/${_ctx.currentLoad.legs.length}`), 1))
            : _createCommentVNode("", true)
        ], 2)
      ], 2)
    ])
  ]))
}