
import { defineComponent, reactive, toRefs } from 'vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import { useStore } from 'vuex';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
export default defineComponent({
  name: 'PlanLoadFormAttachDocuments',
  components: { PlanTextButton, PlanInputFile },
  props: {
    onlyCountry: { type: String, required: false, default: '' }
  },
  setup (props, {emit}) {
    const store = useStore()

    const data = reactive({
      showAddAdditionalAttachments: false
    })

    const changeDocuments = (documents: any) => {
      store.commit('NewFtlLoad/setFiles', documents)
    }

    const changeLetterOfInstructions = (documents: any) => {
      store.commit('NewFtlLoad/setLetterOfInstructions', documents)
    }

    return {
      ...toRefs(data),
      changeDocuments,
      changeLetterOfInstructions
    }
  }
})
