
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import CarrierHeader from '@/components/headers/CarrierHeader.vue';
import Success from '@/components/toast/Success.vue';

interface IQuotationRequest {
  file: any;
  files: any;
  incidenceFile: any;
  price: string;
  sendingAction: boolean;
  allowed: boolean;
  showQuoteInput: boolean;
  successTexts: any;
  successButton: object;
  completed: boolean;
  deleteButtonClass: string;
  imagesContainerClass: string;
  stopDocumentsPending: any;
  isChecked: boolean;
  showUploadModal: boolean;
  incidence: string;
}

export default defineComponent({
  name: 'PlanningView',
  components: {
    CarrierHeader,
    CheckCircleIcon,
    ErrorMessage,
    Field,
    Form,
    Success,
  },
  mixins: [BaseComponent],
  data(): IQuotationRequest {
    return {
      incidence: '',
      stopDocumentsPending: null,
      showUploadModal: false,
      imagesContainerClass: '',
      file: null as any,
      incidenceFile: null as any,
      files: [] as any,
      price: '',
      isChecked: false,
      sendingAction: false,
      allowed: true,
      showQuoteInput: false,
      deleteButtonClass: 'hidden',
      successTexts: {
        header: this.$t('completed'),
        body: this.$t('all-is-done'),
      },
      successButton: {
        text: this.$t('close-window'),
        type: 'close',
        callback: () => (window as any).open('', '_self').close(),
      },
      completed: false,
    };
  },
  created() {
    const id = this.$route.params.id;
    this.$store.dispatch('ShipmentStore/shipment', { id: id });
  },
  computed: {
    deliverCount(): any {
      if (!this.shipment.loads) {
        return '';
      }
      return this.shipment.loads[0].stops.filter((stop) => stop.type === 'DELIVERY').length + 1;
    },
    deliverFinalStop(): any {
      if (!this.shipment.loads) {
        return '';
      }
      const initialStop = this.shipment.loads[0].stops.find(
        (stop) => stop.type === 'DELIVERY_FINAL',
      );
      return initialStop ? initialStop : '';
    },
    pickupInitialStop(): any {
      if (!this.shipment.loads) {
        return '';
      }
      const initialStop = this.shipment.loads[0].stops.find(
        (stop) => stop.type === 'PICKUP_INITIAL',
      );
      return initialStop ? initialStop : '';
    },
    pickUpsCount(): any {
      if (!this.shipment.loads) {
        return '';
      }
      return this.shipment.loads[0].stops.filter((stop) => stop.type === 'PICKUP').length + 1;
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
    stops(): any {
      const shipment = this.shipment;
      if (!shipment || !shipment.loads || shipment.loads.length === 0) {
        return [];
      }
      return shipment.loads[0].stops;
    },
    uploadText(): any {
      return this.files.length ? `${this.$t('files-selected', { count: this.files.length })}` : '';
    },
  },
  methods: {
    addMore() {
      this.incidenceFile = null;
    },
    getEmptyMediaObject() {
      const sequence = this.$route.params.sequence;
      const result = this.shipment.loads[0].stops.find((obj) => obj.sequence === +sequence);
      this.stopDocumentsPending = result;
    },
    notifyIncidence() {
      if (!this.allowed) return;

      if (this.incidence !== '') {
        this.allowed = false;
        this.$store
          .dispatch('UserStore/postIncidence', {
            description: this.incidence,
            stop: this.stopDocumentsPending,
          })
          .then((response) => {
            if (this.files.length > 0) {
              let promises = [] as any;
              this.files.forEach((file) => {
                promises.push(
                  this.$store.dispatch('UserStore/postIncidenceFile', {
                    entityId: response.id,
                    file: file,
                  }),
                );
              });
              Promise.all(promises)
                .then(() => {
                  BaseComponent.methods?.showToastSuccess(this.$t('incidence-correctly-sent'));
                  this.files = [];
                })
                .catch((err) => {
                  BaseComponent.methods?.showToastError(err?.response?.data?.detail);
                })
                .finally(() => {
                  this.allowed = true;
                });
            } else {
              BaseComponent.methods?.showToastSuccess(this.$t('incidence-correctly-sent'));
              this.files = [];
            }
          })
          .finally(() => {
            this.allowed = true;
          });
      } else {
        BaseComponent.methods?.showToastError(this.$t('information-required'));
      }
    },
    updateIncidence(event) {
      this.incidence = event.target.value;
    },
    async uploadDocument() {
      if (this.file) {
        if (this.showUploadModal) {
          this.notifyIncidence();
        }

        if (this.showUploadModal && this.incidence === '') {
          return;
        }
      }
    },
    uploadFiles(type) {
      if (type === 'incidence') {
        this.incidenceFile = (this.$refs.incidenceInput as any).files[0];
        if (this.incidenceFile.type.trim() === 'application/pdf') {
          BaseComponent.methods?.showToastError(this.$t('pdf-not-allowed'));
          this.incidenceFile = null;
        } else {
          this.files.push(this.incidenceFile);
        }
      } else {
        this.file = (this.$refs.multiUploadInput as any).files[0];
      }
    },
  },
  watch: {
    shipment() {
      this.getEmptyMediaObject();
    },
  },
});
