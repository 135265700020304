
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';

export default defineComponent({
  name: 'PlanFilterContactTag',
  components: { PlanButtonIcon, PlanIcon, PlanInputCheckbox },
  emit: ['change'],
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      loadingTags: false,
      selectedTags: [] as string[],
      showPopper: false,
      tags: [] as string[],
    });

    const getTags = async () => {
      data.loadingTags = true;
      store.dispatch('LoadsStore/getCompanyTags').then((response) => {
        data.loadingTags = false;
        data.tags = response;
      });
    };

    const removeTag = (tag: string) => {
      data.selectedTags = data.selectedTags.filter((t) => t !== tag);
      emit('change', data.selectedTags);
    };

    const toggleTag = (tag: string) => {
      if (isSelected(tag)) {
        removeTag(tag);
      } else {
        data.selectedTags.push(tag);
        emit('change', data.selectedTags);
      }
    };

    const isSelected = (tag: string) => {
      return data.selectedTags.includes(tag);
    };

    const removeAllTags = () => {
      data.selectedTags = [];
      emit('change', data.selectedTags);
    };

    getTags();

    return {
      ...toRefs(data),
      removeTag,
      removeAllTags,
      toggleTag,
      isSelected,
    };
  },
});
