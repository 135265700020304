import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "w-full flex flex-col"
}
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col plan-gap-sm"
}
const _hoisted_3 = { class: "flex justify-end items-center plan-gap-sm" }
const _hoisted_4 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_plan_toggle_button = _resolveComponent("plan-toggle-button")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_default_modal = _resolveComponent("plan-carrier-default-modal")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_ctx.isCarrierView)
    ? (_openBlock(), _createBlock(_component_plan_carrier_default_modal, {
        key: 0,
        onClose: _ctx.close,
        title: _ctx.$t('email-notifications'),
        size: "md-auto",
        "hide-close-button": ""
      }, {
        content: _withCtx(() => [
          (_ctx.loadingUserEmailNotificationSetup)
            ? (_openBlock(), _createBlock(_component_plan_loading, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.isCarrierView)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carrierEntries, ([key, value]) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: key,
                          class: "flex justify-start items-center plan-gap-sm"
                        }, [
                          _createVNode(_component_plan_toggle_button, {
                            onChangeStatusWithId: _ctx.changeToggle,
                            id: key,
                            "is-active": value
                          }, null, 8, ["onChangeStatusWithId", "id", "is-active"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t(key)), 1)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_plan_button, {
              onOnClick: _ctx.close,
              type: "cancel",
              label: _ctx.$t('cancel')
            }, null, 8, ["onOnClick", "label"]),
            _createVNode(_component_plan_button, {
              onOnClick: _ctx.postEmailNotificationsSetup,
              loading: _ctx.loadingPost,
              type: "primary",
              label: _ctx.$t('save'),
              style: {"min-width":"100px"}
            }, null, 8, ["onOnClick", "loading", "label"])
          ])
        ]),
        _: 1
      }, 8, ["onClose", "title"]))
    : (_openBlock(), _createBlock(_component_plan_default_modal, {
        key: 1,
        onClose: _ctx.close,
        title: _ctx.$t('email-notifications'),
        size: "sm-auto",
        "hide-close-button": ""
      }, {
        content: _withCtx(() => [
          _createVNode(_component_plan_toggle_button, { "is-active": "" })
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_plan_button, {
              onOnClick: _ctx.close,
              type: "cancel",
              label: _ctx.$t('cancel')
            }, null, 8, ["onOnClick", "label"]),
            _createVNode(_component_plan_button, {
              onOnClick: _ctx.postEmailNotificationsSetup,
              loading: _ctx.loadingPost,
              type: "primary",
              label: _ctx.$t('save'),
              style: {"min-width":"100px"}
            }, null, 8, ["onOnClick", "loading", "label"])
          ])
        ]),
        _: 1
      }, 8, ["onClose", "title"]))
}