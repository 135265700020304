
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import WarehouseForm from '@/modules/planning/warehouses/_forms/WarehouseForm.vue';
import store from '@/services/store';
import { useStore } from 'vuex';
import PlanDefaultLoadingModal from '@/components/defaults/PlanDefaultLoadingModal.vue';
import PlanDefaultStatusModal from '@/components/defaults/PlanDefaultStatusModal.vue';
import { useRoute } from 'vue-router';

interface addWarehouseModal {
  loading: boolean,
  showConfirmation: boolean,
  warehouseInfo: {
    name: string,
    country: string,
    zip: string,
    address: string,
    placeId: string,
    prefix: string,
    phone: number | null,
    contactPerson: string
  }
}

export default defineComponent({
  name: 'AddWarehouseModal',
  props: {
    defaultZip: { type: String, required: false, default: '' },
    defaultCountry: { type: String, required: false, default: '' },
    defaultPrefix: { type: String, required: false, default: '' },
    defaultZipcodeId: { type: String, required: false, default: '' },
    onlyCountry: { type: String, required: false, default: '' },
    excludeCountry: { type: String, required: false, default: '' }
  },
  components: { WarehouseForm, PlanButton, PlanDefaultModal },
  emits: ['close', 'closeAndUpdate'],
  setup (props, { emit }) {
    const route = useRoute()
    const store = useStore()

    const data = reactive<addWarehouseModal>({
      loading: false,
      showConfirmation: false,
      warehouseInfo: {
        name: '',
        country: '',
        zip: '',
        address: '',
        placeId: '',
        prefix: '',
        phone: null,
        contactPerson: '',
      }
    })

    const isFormComplete = computed(() => {
      let result = true
      if (data.warehouseInfo.name.length === 0) result = false
      if (data.warehouseInfo.country.length === 0) result = false
      if (data.warehouseInfo.zip.length === 0) result = false
      if (data.warehouseInfo.address.length === 0) result = false
      if (data.warehouseInfo.placeId.length === 0) result = false
      return result
    })

    const withoutSidebar = computed(() => {
      return route.name !== 'warehousesList'
    })

    const close = () => { if (!data.loading) emit('close') }

    const postWarehouse = () => {
      data.loading = true
      store.dispatch('LoadsStore/postCreateWarehouse', {
        companyWarehouse: data.warehouseInfo.name,
        zipCode: data.warehouseInfo.zip,
        address: data.warehouseInfo.address,
        phoneNumber: data.warehouseInfo?.phone?.toString(),
        phonePrefix: data.warehouseInfo.prefix.replace('+', ''),
        lumperFee: 0,
        observations: '',
        portStop: false,
        airportStop: false,
        executionWindowStartTime: null,
        executionWindowEndTime: null,
        contactName: data.warehouseInfo.contactPerson,
        googlePlaceId: data.warehouseInfo.placeId,
        language: store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es'
      }).then((response) => {
        data.loading = false
        emit('closeAndUpdate', response)
      })
    }

    if (props.defaultZip) {
      data.warehouseInfo = {
        name: '',
        country: props.defaultCountry,
        zip: props.defaultZip,
        address: '',
        placeId: props.defaultZipcodeId,
        prefix: props.defaultPrefix,
        phone: null,
        contactPerson: '',
      }
    }

    const updateData = (newData: any) => {
      data.warehouseInfo = newData
    }

    return {
      ...toRefs(data),
      isFormComplete,
      withoutSidebar,
      close,
      postWarehouse,
      updateData
    }
  }
})
