import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-center plan-gap-md" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col plan-gap-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_load_header = _resolveComponent("plan-carrier-load-header")!
  const _component_plan_carrier_load_sub_header = _resolveComponent("plan-carrier-load-sub-header")!
  const _component_plan_carrier_default_tab = _resolveComponent("plan-carrier-default-tab")!
  const _component_plan_carrier_load_details = _resolveComponent("plan-carrier-load-details")!
  const _component_plan_carrier_load_stop_data = _resolveComponent("plan-carrier-load-stop-data")!
  const _component_plan_carrier_tracking_tab = _resolveComponent("plan-carrier-tracking-tab")!
  const _component_plan_carrier_documents_tab = _resolveComponent("plan-carrier-documents-tab")!
  const _component_ImpersonatingBarCarrier = _resolveComponent("ImpersonatingBarCarrier")!
  const _component_plan_default_carrier_view = _resolveComponent("plan-default-carrier-view")!

  return (_ctx.loadData !== null)
    ? (_openBlock(), _createBlock(_component_plan_default_carrier_view, {
        key: 0,
        "show-footer": _ctx.loadData.status === 'QUOTING',
        "show-update-data": "",
        "show-add-location": "",
        "show-add-additional-fees": "",
        "show-notify-incidence": ""
      }, {
        "header-content": _withCtx(() => [
          _createVNode(_component_plan_carrier_load_header)
        ]),
        "subheader-content": _withCtx(() => [
          _createVNode(_component_plan_carrier_load_sub_header)
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_plan_carrier_default_tab, {
              onChange: _ctx.changeTab,
              "active-tab": _ctx.activeTab
            }, null, 8, ["onChange", "active-tab"]),
            (_ctx.activeTab === 'loadInfo')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_plan_carrier_load_details),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stopsData, (stopData) => {
                    return (_openBlock(), _createBlock(_component_plan_carrier_load_stop_data, {
                      key: stopData.stop,
                      "to-city": stopData.toCity,
                      stop: stopData.stop
                    }, null, 8, ["to-city", "stop"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'tracking')
              ? (_openBlock(), _createBlock(_component_plan_carrier_tracking_tab, { key: 1 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'documents')
              ? (_openBlock(), _createBlock(_component_plan_carrier_documents_tab, { key: 2 }))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_ImpersonatingBarCarrier)
        ]),
        _: 1
      }, 8, ["show-footer"]))
    : _createCommentVNode("", true)
}