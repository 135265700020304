import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-3 pb-6 border-b" }
const _hoisted_2 = {
  class: "w-full font-bold text-lg px-3 my-4",
  style: {"font-size":"24px"}
}
const _hoisted_3 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_4 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "grid-first-name"
}
const _hoisted_5 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_6 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "grid-last-name"
}
const _hoisted_7 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_8 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "grid-last-name"
}
const _hoisted_9 = { class: "w-full flex items-center justify-between px-3 pt-4" }
const _hoisted_10 = { class: "inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out ml-1" }
const _hoisted_11 = {
  key: 1,
  class: "flex flex-col mb-4"
}
const _hoisted_12 = { class: "mb-1 flex justify-between" }
const _hoisted_13 = ["for"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_16 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "grid-first-name"
}
const _hoisted_17 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_18 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "grid-last-name"
}
const _hoisted_19 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_20 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "grid-last-name"
}
const _hoisted_21 = { class: "w-full flex items-center justify-end p-3" }
const _hoisted_22 = { class: "inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out ml-1" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "mb-3 pb-6 border-b" }
const _hoisted_25 = {
  class: "w-full font-bold text-lg px-3 my-4",
  style: {"font-size":"24px"}
}
const _hoisted_26 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_27 = {
  for: "trailer-type",
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
}
const _hoisted_28 = {
  value: "",
  disabled: ""
}
const _hoisted_29 = ["value"]
const _hoisted_30 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_31 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "grid-last-name"
}
const _hoisted_32 = { class: "w-full md:w-1/3 px-3 mb-6 md:mb-3" }
const _hoisted_33 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "trailer"
}
const _hoisted_34 = { class: "w-full flex items-center justify-between px-3 pt-4" }
const _hoisted_35 = { class: "inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out ml-1" }
const _hoisted_36 = {
  key: 1,
  class: "flex flex-col mb-4"
}
const _hoisted_37 = { class: "mb-1" }
const _hoisted_38 = ["for"]
const _hoisted_39 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_40 = {
  for: "trailer-type",
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
}
const _hoisted_41 = {
  value: "",
  disabled: ""
}
const _hoisted_42 = ["value"]
const _hoisted_43 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_44 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "grid-last-name"
}
const _hoisted_45 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_46 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "grid-last-name"
}
const _hoisted_47 = { class: "w-full flex items-center justify-end p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!
  const _component_radio = _resolveComponent("radio")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('drivers')), 1),
      (_ctx.drivers.length === 0 || _ctx.addDriver)
        ? (_openBlock(), _createBlock(_component_Form, {
            key: 0,
            onSubmit: _cache[4] || (_cache[4] = ($event: any) => (_ctx.createDriver())),
            class: "flex flex-wrap"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('firstname')), 1),
                _createVNode(_component_Field, {
                  class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white",
                  type: "text",
                  name: "name",
                  id: "name",
                  modelValue: _ctx.driver.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.driver.name) = $event)),
                  rules: "required|alpha_spaces|max:256"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: "name",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('lastname')), 1),
                _createVNode(_component_Field, {
                  class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                  id: "grid-last-name",
                  type: "text",
                  modelValue: _ctx.driver.surname,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.driver.surname) = $event)),
                  name: "lastname",
                  rules: "required|alpha_spaces|max:256"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: "lastname",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('driver-license')), 1),
                _createVNode(_component_Field, {
                  class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                  id: "grid-last-name",
                  type: "text",
                  modelValue: _ctx.driver.drivingLicense,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.driver.drivingLicense) = $event)),
                  name: "driverId",
                  rules: "required|max:256"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: "driverId",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.drivers.length > 0)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out",
                      "data-bs-dismiss": "modal",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addDriver = false))
                    }, _toDisplayString(_ctx.$t('cancel')), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("button", _hoisted_10, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('create')), 1)
                ])
              ])
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drivers, (driver, index) => {
              return (_openBlock(), _createBlock(_component_radio, {
                key: index,
                class: "flex flex-col"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_Field, {
                        id: 'driver-radio-' + index,
                        type: "radio",
                        name: "driverRadio",
                        class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600",
                        modelValue: _ctx.driverSelected,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.driverSelected) = $event)),
                        rules: "required",
                        value: driver.id
                      }, null, 8, ["id", "modelValue", "value"]),
                      _createElementVNode("label", {
                        for: 'driver-radio-' + index,
                        class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      }, _toDisplayString(driver.name) + " " + _toDisplayString(driver.surname), 9, _hoisted_13)
                    ])
                  ]),
                  (_ctx.driverSelected === driver.id)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createVNode(_component_Form, {
                          onSubmit: ($event: any) => (_ctx.editDriver(driver)),
                          class: "flex flex-wrap"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('firstname')), 1),
                              _createVNode(_component_Field, {
                                class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white",
                                type: "text",
                                name: "name",
                                id: "name",
                                modelValue: driver.name,
                                "onUpdate:modelValue": ($event: any) => ((driver.name) = $event),
                                rules: "required|alpha_spaces|max:256"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                              _createVNode(_component_ErrorMessage, {
                                name: "name",
                                class: "text-rose-500"
                              })
                            ]),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('lastname')), 1),
                              _createVNode(_component_Field, {
                                class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                                id: "grid-last-name",
                                type: "text",
                                modelValue: driver.surname,
                                "onUpdate:modelValue": ($event: any) => ((driver.surname) = $event),
                                name: "lastname",
                                rules: "required|alpha_spaces|max:256"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                              _createVNode(_component_ErrorMessage, {
                                name: "lastname",
                                class: "text-rose-500"
                              })
                            ]),
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('driver-license')), 1),
                              _createVNode(_component_Field, {
                                class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                                id: "grid-last-name",
                                type: "text",
                                modelValue: driver.drivingLicense,
                                "onUpdate:modelValue": ($event: any) => ((driver.drivingLicense) = $event),
                                name: "driverId",
                                rules: "required|max:256"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                              _createVNode(_component_ErrorMessage, {
                                name: "driverId",
                                class: "text-rose-500"
                              })
                            ]),
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("button", _hoisted_22, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.$t('update')), 1)
                              ]),
                              _createElementVNode("a", {
                                onClick: ($event: any) => (_ctx.openModalDelete(driver.id)),
                                class: "cursor-pointer ml-6 px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                              }, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.$t('delete')), 1)
                              ], 8, _hoisted_23)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["onSubmit"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024))
            }), 128)),
            _createVNode(_component_ErrorMessage, {
              name: "driverRadio",
              class: "text-rose-500"
            }),
            _createElementVNode("p", {
              class: "border-b w-max border-corp cursor-pointer pt-10",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.addDriver = true))
            }, _toDisplayString(`+ ${_ctx.$t('add-other')}`), 1)
          ]))
    ]),
    _createElementVNode("div", _hoisted_24, [
      _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t('vehicles')), 1),
      (_ctx.vehicles.length === 0 || _ctx.addVehicle)
        ? (_openBlock(), _createBlock(_component_Form, {
            key: 0,
            class: "flex flex-wrap",
            onSubmit: _cache[11] || (_cache[11] = ($event: any) => (_ctx.createVehicle()))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('vehicle-type')) + "* ", 1),
                _createVNode(_component_Field, {
                  as: "select",
                  modelValue: _ctx.trailer.trailerType.id,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.trailer.trailerType.id) = $event)),
                  name: "vehicle",
                  rules: "required",
                  id: "trailer-type",
                  class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("option", _hoisted_28, _toDisplayString(_ctx.$t('placeholder-select-type')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trailerTypes, (trailer) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: trailer.id,
                        key: trailer.id
                      }, _toDisplayString(trailer.description), 9, _hoisted_29))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: "vehicle",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t('license-plate')) + "* ", 1),
                _createVNode(_component_Field, {
                  class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                  id: "grid-last-name",
                  type: "text",
                  modelValue: _ctx.trailer.licensePlate,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.trailer.licensePlate) = $event)),
                  name: "licensePlate",
                  rules: "required|max:256",
                  placeholder: _ctx.$t('placeholder-license-plate')
                }, null, 8, ["modelValue", "placeholder"]),
                _createVNode(_component_ErrorMessage, {
                  name: "licensePlate",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t('trailer')), 1),
                _createVNode(_component_Field, {
                  class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                  id: "grid-last-name",
                  type: "text",
                  modelValue: _ctx.trailer.trailerPlate,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.trailer.trailerPlate) = $event)),
                  name: "trailer",
                  rules: "max:256",
                  placeholder: _ctx.$t('placeholder-trailer')
                }, null, 8, ["modelValue", "placeholder"]),
                _createVNode(_component_ErrorMessage, {
                  name: "trailer",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_34, [
                (_ctx.vehicles.length > 0)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out",
                      "data-bs-dismiss": "modal",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.addVehicle = false))
                    }, _toDisplayString(_ctx.$t('cancel')), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("button", _hoisted_35, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('create')), 1)
                ])
              ])
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_36, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vehicles, (vehicle, index) => {
              return (_openBlock(), _createBlock(_component_radio, {
                key: index,
                class: "flex flex-col"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_37, [
                    _createVNode(_component_Field, {
                      id: 'vehicle-radio-' + index,
                      type: "radio",
                      name: "vehicleRadio",
                      class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600",
                      modelValue: _ctx.vehicleSelected,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.vehicleSelected) = $event)),
                      rules: "required",
                      value: vehicle.id
                    }, null, 8, ["id", "modelValue", "value"]),
                    _createElementVNode("label", {
                      for: 'vehicle-radio-' + index,
                      class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    }, _toDisplayString(_ctx.$t('vehicle')) + " " + _toDisplayString(vehicle.id), 9, _hoisted_38)
                  ]),
                  (_ctx.vehicleSelected === vehicle.id)
                    ? (_openBlock(), _createBlock(_component_Form, {
                        key: 0,
                        class: "flex flex-wrap p-12"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_39, [
                            _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('vehicle-type')) + "* ", 1),
                            _createVNode(_component_Field, {
                              as: "select",
                              modelValue: vehicle.trailerType.id,
                              "onUpdate:modelValue": ($event: any) => ((vehicle.trailerType.id) = $event),
                              name: "vehicle",
                              rules: "required",
                              id: "trailer-type",
                              class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("option", _hoisted_41, _toDisplayString(_ctx.$t('placeholder-select-type')), 1),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trailerTypes, (trailer) => {
                                  return (_openBlock(), _createElementBlock("option", {
                                    value: trailer.id,
                                    key: trailer.id
                                  }, _toDisplayString(trailer.description), 9, _hoisted_42))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_ErrorMessage, {
                              name: "vehicle",
                              class: "text-rose-500"
                            })
                          ]),
                          _createElementVNode("div", _hoisted_43, [
                            _createElementVNode("label", _hoisted_44, _toDisplayString(_ctx.$t('license-plate')) + "* ", 1),
                            _createVNode(_component_Field, {
                              class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                              id: "grid-last-name",
                              type: "text",
                              modelValue: vehicle.licensePlate,
                              "onUpdate:modelValue": ($event: any) => ((vehicle.licensePlate) = $event),
                              name: "licensePlate",
                              rules: "required|max:256",
                              placeholder: _ctx.$t('license-plate')
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]),
                            _createVNode(_component_ErrorMessage, {
                              name: "licensePlate",
                              class: "text-rose-500"
                            })
                          ]),
                          _createElementVNode("div", _hoisted_45, [
                            _createElementVNode("label", _hoisted_46, _toDisplayString(_ctx.$t('trailer')), 1),
                            _createVNode(_component_Field, {
                              class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                              id: "grid-last-name",
                              type: "text",
                              modelValue: vehicle.trailerPlate,
                              "onUpdate:modelValue": ($event: any) => ((vehicle.trailerPlate) = $event),
                              name: "trailerPlate",
                              rules: "max:256",
                              placeholder: _ctx.$t('trailer')
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]),
                            _createVNode(_component_ErrorMessage, {
                              name: "trailerPlate",
                              class: "text-rose-500"
                            })
                          ]),
                          _createElementVNode("div", _hoisted_47, [
                            _createElementVNode("a", {
                              onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.updateData && _ctx.updateData(...args))),
                              class: "cursor-pointer inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                            }, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t('update')), 1)
                            ]),
                            _createElementVNode("a", {
                              onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.deleteVehicle && _ctx.deleteVehicle(...args))),
                              class: "cursor-pointer ml-6 px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                            }, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t('delete')), 1)
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024))
            }), 128)),
            _createVNode(_component_ErrorMessage, {
              name: "vehicleRadio",
              class: "text-rose-500"
            }),
            _createElementVNode("p", {
              class: "border-b w-max border-corp cursor-pointer pt-10",
              onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.addVehicle = true))
            }, _toDisplayString(`+ ${_ctx.$t('add-other')}`), 1)
          ]))
    ])
  ]))
}