<template>
  <div class="container mx-auto">
    <div class="flex flex-row justify-end">
      <button
        @click="$emit('close')"
        type="button"
        class="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
        data-modal-hide="defaultModal"
      >
        <!-- <svg
          aria-hidden="true"
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg> -->
        <XCircleIcon class="w-8 h-8 text-corp" />

        <span class="sr-only">{{ $t('close-modal') }}</span>
      </button>
    </div>

    <template v-if="!showNotifyIncidence">
      <h2 className="font-bold text-center text-3xl max-w-full mb-10">
        {{
          $route.name !== 'planificationDetail'
            ? $t('upload-documents-or-report-incidence')
            : $t('you-can-upload')
        }}
      </h2>
      <div v-if="loadSelected?.mediaObjects.length > 0">
        <h1 class="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
          {{ documentFile === null ? $t('document-uploaded') : $t('new-document-to-upload') }}
        </h1>

        <li
          v-if="documentFile === null"
          @click="viewDocumentUploaded"
          class="block p-1 w-1/2 sm:w-1/3 md:w-2/5 lg:w-2/5 xl:w-2/5 h-24"
        >
          <article
            tabindex="0"
            class="group hasImage w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative text-transparent hover:text-white shadow-sm"
          >
            <img
              v-if="documentUploadedURL"
              class="img-preview w-full h-full sticky object-cover rounded-md bg-fixed"
              :src="documentUploadedURL"
              alt="2.PNG"
            />
            <section
              class="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3"
            >
              <h1 class="flex-1"></h1>
              <div class="flex flex-nowrap">
                <p class="p-1 size text-xs">{{}}</p>
              </div>
            </section>
          </article>
        </li>
        <li
          v-else
          class="block p-1 w-1/2 sm:w-1/3 md:w-2/5 lg:w-2/5 xl:w-2/5 h-24"
        >
          <article
            tabindex="0"
            :class="isImage(documentFile.type) ? 'hasImage text-transparent hover:text-white' : ''"
            class="group w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative shadow-sm"
          >
            <img
              v-if="isImage(documentFile.type)"
              class="img-preview w-full h-full sticky object-cover rounded-md bg-fixed"
              :src="documentFile.url"
              alt="2.PNG"
            />
            <img
              v-else
              alt="upload preview"
              class="img-preview hidden w-full h-full sticky object-cover rounded-md bg-fixed"
            />

            <section
              class="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3"
            >
              <h1 class="flex-1">{{ documentFile.name }}</h1>
              <div class="flex flex-nowrap">
                <p class="p-1 size text-xs">{{ documentFile.size }} kb</p>
              </div>
            </section>
          </article>
        </li>
      </div>
      <div
        class="flex flex-row justify-center self-center items-center"
        v-else
      >
        <li
          v-if="documentFile"
          class="block p-1 w-1/2 sm:w-1/3 md:w-2/5 lg:w-2/5 xl:w-2/5 h-24"
        >
          <article
            tabindex="0"
            :class="isImage(documentFile.type) ? 'hasImage text-transparent hover:text-white' : ''"
            class="group w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative shadow-sm"
          >
            <img
              v-if="isImage(documentFile.type)"
              class="img-preview w-full h-full sticky object-cover rounded-md bg-fixed"
              :src="documentFile.url"
              alt="2.PNG"
            />
            <img
              v-else
              alt="upload preview"
              class="img-preview hidden w-full h-full sticky object-cover rounded-md bg-fixed"
            />

            <section
              class="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3"
            >
              <h1 class="flex-1">{{ documentFile.name }}</h1>
              <div class="flex flex-nowrap">
                <p class="p-1 size text-xs">{{ documentFile.size }} kb</p>
              </div>
            </section>
          </article>
        </li>
        <template v-else>
          <div class="rounded-full flex items-center justify-center w-8 h-8 flex-shrink-0">
            <ExclamationCircleIcon class="h-6 w-6 text-orange-500" />
          </div>
          <h2 className=" pl-1 font-medium text-sm max-w-full">
            {{ $t('not-documents-uploaded') }}
          </h2>
        </template>
      </div>

      <Field
        name="file"
        rules="size:10000"
        v-model="file"
        class="flex justify-center mb-2"
      >
        <input
          ref="multiUploadInput"
          @change="uploadFiles"
          type="file"
          accept="application/pdf,application/xpdf,image/jpeg,image/png,image/jpg"
          id="multi-upload-input"
          class="hidden"
        />
        <div
          id="multi-upload-button"
          @click="showFileInput"
          class="block cursor-pointer text-center mt-6 text-white w-1/2 sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-2/6 text-sm py-2 px-4 rounded-full border-0 font-semibold bg-corp hover:bg-slate-700"
        >
          {{ $t('select-file') }}
          <!-- {{
            loadSelected.type === 'PICKUP_INITIAL' || loadSelected.type === 'PICKUP'
              ? ' BOL'
              : ' POD'
          }} -->
        </div>
      </Field>

      <div
        v-if="$route.name !== 'planificationDetail'"
        class="flex items-center pl-4"
      >
        <input
          id="bordered-checkbox-1"
          type="checkbox"
          value=""
          name="bordered-checkbox"
          @input="showNotifyIncidence = !showNotifyIncidence"
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label class="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {{ $t('notify-new-incidence') }}
        </label>
      </div>
    </template>
    <main
      v-if="showNotifyIncidence"
      class="container mx-auto max-w-screen-lg h-full"
    >
      <h2 className="font-bold text-lg max-w-full">{{ $t('describe-incidence') }}</h2>
      <div>
        <textarea
          v-model="message"
          :maxlength="length"
          required
          rows="3"
          class="w-full p-2 border-2 focus:outline-none focus:border-green-400 text-gray-500 rounded-lg"
          :placeholder="$t('message')"
        ></textarea>
        <small class="text-gray-400 text-xs">
          <span>{{ length - message.length }}</span>
          <span> {{ ' ' + $t('characters-left') }}</span>
        </small>
      </div>

      <!-- file upload modal -->
      <article
        aria-label="File Upload Modal"
        class="relative h-full flex flex-col bg-white shadow-xl rounded-md"
        @dragover.prevent
        @dragenter="dragEnterHandler"
        @dragleave="dragLeaveHandler"
        @drop="dropHandler"
      >
        <!-- overlay -->
        <div
          id="overlay"
          ref="overlay"
          class="w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md"
        >
          <div
            v-if="counter > 0"
            class="draggedover w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md"
          >
            <i>
              <svg
                class="fill-current w-12 h-12 mb-3 text-blue-700"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z"
                />
              </svg>
            </i>
            <p class="text-lg text-blue-700">{{ $t('drop-files-upload') }}</p>
          </div>
        </div>

        <!-- scroll area -->
        <section class="h-full overflow-auto p-8 w-full flex flex-col">
          <header
            class="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center"
          >
            <p class="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
              <span>{{ $t('drag-text-first') }}</span
              >&nbsp;<span>{{ $t('drag-text-second') }}</span>
            </p>
            <input
              type="file"
              accept="image/jpeg,image/png,image/jpg"
              multiple
              ref="hidden"
              @change="fileChanged"
              style="display: none"
            />
            <button
              id="button"
              @click="$refs.hidden.click()"
              class="mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none"
            >
              {{ $t('upload-file') }}
            </button>
          </header>

          <h1 class="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">{{ $t('to-upload') }}</h1>

          <ul
            id="gallery"
            class="flex flex-1 flex-wrap -m-1"
          >
            <li
              v-if="Object.keys(FILES).length === 0"
              id="empty"
              ref="empty"
              class="h-full w-full text-center flex flex-col items-center justify-center"
            >
              <img
                class="mx-auto w-32"
                src="@/resources/assets/img/upload-files.png"
                alt="no data"
              />
              <span class="text-small text-gray-500">{{ $t('no-files-selected') }}</span>
            </li>
            <template v-else>
              <li
                v-for="(file, url) in FILES"
                class="block p-1 w-1/2 sm:w-1/3 md:w-2/5 lg:w-2/5 xl:w-2/5 h-24"
                :key="url"
                :id="url"
              >
                <article
                  tabindex="0"
                  class="group hasImage w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative text-transparent hover:text-white shadow-sm"
                >
                  <img
                    class="img-preview w-full h-full sticky object-cover rounded-md bg-fixed"
                    :src="getUrl(url)"
                    :alt="file.name"
                  />

                  <section
                    class="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3"
                  >
                    <h1 class="flex-1">{{ file.name }}</h1>
                    <div class="flex flex-nowrap">
                      <p class="p-1 size text-xs">{{ formatSize(file.size) }}</p>
                      <button
                        class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md"
                        @click="deleteFile(url)"
                        :data-target="url"
                      >
                        <svg
                          class="pointer-events-none fill-current w-4 h-4 ml-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            class="pointer-events-none"
                            d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </section>
                </article>
              </li>
            </template>
          </ul>
        </section>

        <!-- sticky footer -->
        <footer class="flex justify-end px-8 pb-8 pt-4">
          <button
            id="submit"
            @click="notifyIncidence"
            :class="message === '' ? 'opacity-25 cursor-not-allowed' : ''"
            class="text-lg font-semibold cursor-pointer bg-gray-800 text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black"
          >
            {{ uploadDocumentAction ? $t('upload-documents-action') : $t('upload-now') }}
          </button>
          <button
            id="cancel"
            @click="showNotifyIncidence = !showNotifyIncidence"
            class="ml-3 rounded-sm px-3 py-1 hover:bg-gray-300 focus:shadow-outline focus:outline-none"
          >
            {{ $t('cancel') }}
          </button>
        </footer>
      </article>
    </main>
    <footer
      class="flex justify-end px-8 pb-8 pt-4"
      v-if="!showNotifyIncidence"
    >
      <button
        id="submit"
        @click="uploadDocument"
        :class="documentFile === null ? 'opacity-25 cursor-not-allowed' : ''"
        class="text-lg font-semibold cursor-pointer bg-gray-800 text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black"
      >
        {{ uploadDocumentAction ? $t('upload-documents-action') : $t('upload-now') }}
      </button>
    </footer>
  </div>
</template>

<script>
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';

export default {
  components: {
    ExclamationCircleIcon,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  props: {
    loadSelected: {
      type: Object,
    },
  },
  data() {
    return {
      allowUploadDocument: true,
      uploadDocumentAction: false,
      message: '',
      length: 200,
      showNotifyIncidence: false,
      FILES: {},
      documentUploaded: null,
      documentUploadedURL: null,
      documentFile: null,
      fileTempl: document.getElementById('file-template'),
      imageTempl: document.getElementById('image-template'),
      empty: document.getElementById('empty'),
      counter: 0,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters['UserStore/getCurrentUser'];
    },
    isShipper() {
      return this.currentUser?.roles?.some(
        (role) => role === 'ROLE_SUPER_SHIPPER' || role === 'SHIPPER',
      );
    },
  },
  async created() {
    if (this.loadSelected && this.loadSelected?.mediaObjects?.length > 0) {
      const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
        id: this.loadSelected?.mediaObjects[0]?.id,
        class: this.loadSelected?.mediaObjects[0]?.entityClass,
      });
      this.documentUploaded = result;
      this.documentUploadedURL = URL.createObjectURL(result);
    }
  },
  methods: {
    async viewDocumentUploaded() {
      const result = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: this.loadSelected?.mediaObjects[0]?.id,
        class: this.loadSelected?.mediaObjects[0]?.entityClass,
      });

      const url = URL.createObjectURL(result);
      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-${''}`;
        const blob = new Blob([result], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    },
    async notifyIncidence() {
      if (this.allowUploadDocument) {
        this.allowUploadDocument = false;
        this.uploadDocumentAction = true;
        if (this.documentFile !== null) {
          this.uploadDocument();
        }

        if (this.message !== '') {
          this.allowed = false;
          this.$store
            .dispatch('UserStore/postIncidence', {
              description: this.message,
              stop: this.loadSelected,
            })
            .then((response) => {
              if (Object.keys(this.FILES).length > 0) {
                let promises = [];
                for (const file of Object.values(this.FILES)) {
                  promises.push(
                    this.$store.dispatch('UserStore/postIncidenceFile', {
                      entityId: response.id,
                      file: file,
                    }),
                  );
                }
                Promise.all(promises)
                  .then(() => {
                    BaseComponent.methods?.showToastSuccess(this.$t('incidence-correctly-sent'));
                    this.FILES = {};
                  })
                  .catch((err) => {
                    BaseComponent.methods?.showToastError(err?.response?.data?.detail);
                  })
                  .finally(() => {
                    this.allowed = true;
                    this.allowUploadDocument = true;
                    this.uploadDocumentAction = false;
                  });
              } else {
                BaseComponent.methods?.showToastSuccess(this.$t('incidence-correctly-sent'));
                this.FILES = {};
              }
            })
            .finally(() => {
              this.$emit('close');
            });
        } else {
          BaseComponent.methods?.showToastError(this.$t('information-required'));
        }
      }
    },
    isImage(type) {
      return type.match('image.*');
    },
    uploadFiles() {
      this.documentFile = this.$refs.multiUploadInput.files[0];
      this.documentFile.url = URL.createObjectURL(this.$refs.multiUploadInput.files[0]);
    },
    addFile(file) {
      const objectURL = URL.createObjectURL(file);

      if (Object.keys(this.FILES).length === 0) {
        this.$refs.empty.classList.add('hidden');
      }

      this.FILES[objectURL] = file;
    },
    deleteFile(url) {
      document.getElementById(url).remove();
      delete this.FILES[url];
      if (Object.keys(this.FILES).length === 0) {
        this.empty.classList.remove('hidden');
      }
    },
    fileChanged(event) {
      for (const file of event.target.files) {
        if (file.type !== 'application/pdf') {
          this.addFile(file);
        }
      }
    },
    dragEnterHandler(e) {
      if (this.hasFiles(e)) {
        e.preventDefault();
        this.counter++;
        this.$refs.overlay.classList.add('draggedover');
      }
    },
    showFileInput() {
      this.$refs.multiUploadInput.click();
    },
    dragLeaveHandler(e) {
      if (this.hasFiles(e)) {
        this.counter--;
        if (this.counter === 0) {
          this.$refs.overlay.classList.remove('draggedover');
        }
      }
    },
    dropHandler(e) {
      e.preventDefault();
      this.counter = 0;
      this.$refs.overlay.classList.remove('draggedover');
      for (const file of e.dataTransfer.files) {
        this.addFile(file);
      }
    },
    hasFiles(e) {
      return e.dataTransfer.types.includes('Files');
    },
    formatSize(size) {
      const units = ['B', 'KB', 'MB', 'GB', 'TB'];
      let unitIndex = 0;
      while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
      }
      return size.toFixed(2) + units[unitIndex];
    },
    getUrl(url) {
      return URL.createObjectURL(this.FILES[url]);
    },
    async uploadDocument() {
      const allowedMimeTypes = [
        'application/pdf',
        'application/xpdf',
        'image/jpeg',
        'image/png',
        'image/jpg',
      ];
      const maxSize = 10000;

      if (!this.documentFile) {
        BaseComponent.methods?.showToastError(this.$t('no-document-selected'));
        return;
      }

      if (!allowedMimeTypes.includes(this.documentFile.type)) {
        BaseComponent.methods?.showToastError(this.$t('invalid-file'));
        return;
      }

      if (this.documentFile.size / 1024 > maxSize) {
        BaseComponent.methods?.showToastError(this.$t('file-size-exceeds'));
        return;
      }
      if (this.allowUploadDocument) {
        this.allowUploadDocument = false;
        this.uploadDocumentAction = true;
        this.$store
          .dispatch('ShipmentStore/postMediaObjectStops', {
            entityId: this.loadSelected?.id,
            file: this.documentFile,
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('documents-uploaded-done'));
            const id = this.isShipper ? this.currentLoad.shipment.id : this.$route.params.id;
            this.$store.dispatch('ShipmentStore/shipment', { id: id });
            this.$emit('close');
          })
          .finally(() => {
            this.allowUploadDocument = true;
            this.uploadDocumentAction = false;
          });
      }
    },

    cancel() {
      this.FILES = {};
      const listItems = document.querySelectorAll('#gallery li');
      listItems.forEach((li) => {
        li.remove();
      });
      this.empty.classList.remove('hidden');
    },
  },
};
</script>

<style>
.hasImage:hover section {
  background-color: rgba(5, 5, 5, 0.4);
}

.hasImage:hover button:hover {
  background: rgba(5, 5, 5, 0.45);
}

#overlay p,
i {
  opacity: 0;
}

#overlay.draggedover {
  background-color: rgba(255, 255, 255, 0.7);
}

#overlay.draggedover p,
#overlay.draggedover i {
  opacity: 1;
}

.group:hover .group-hover\:text-blue-800 {
  color: #2b6cb0;
}
</style>
