import { initialState } from '@/services/loads/_store/index';

export default {
  setDataFilled(state: any, payload: any) {
    state.dataFilled = payload;
  },
  setSelectedContactsLegs(state: any, payload: any) {
    state.selectedContactsLegs = payload;
  },
  pushContactsLegs(state: any, payload: any) {
    state.selectedContactsLegs[payload.index].push(payload.data);
  },
  removeContactsLegs(state, payload) {
    const index = state.selectedContactsLegs[payload.index].findIndex(contact => contact.id === payload.data.id);
    if (index !== -1) {
      state.selectedContactsLegs[payload.index].splice(index, 1); 
    }
  },
  removePopertyFromLoad(state: any, payload: any) {
    delete state.loadToSend[payload];
  },
  resetLoadToSend(state: any, payload: any) {
    state.loadToSend = state.defaultLoad;
  },
  updateDefaultLoad(state: any, payload: object) {
    state.defaultLoad = { ...payload };
  },
  updateLoadToSend(state: any, payload: any) {
    state.loadToSend = { ...payload };
  },
  updatePopertyFromLoad(state: any, payload: any) {
    state.loadToSend[payload.key] = payload.value;
  },
  updateLegFromLoad(state: any, payload: any) {
    state.loadToSend.legs[payload.index][payload.key] = payload.value;
  },
  updateLegStopFromLoad(state: any, payload: any) {
    state.loadToSend.legs[payload.legIndex].stops[payload.stopIndex][payload.key] = payload.value;
  },
};
