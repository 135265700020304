import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "rounded border plan-border-prim-500 flex flex-col plan-gap-lg plan-p-md w-full bg-white transition-all duration-300" }
const _hoisted_2 = { class: "font-bold plan-t-h4" }
const _hoisted_3 = { class: "flex justify-between items-start plan-gap-sm" }
const _hoisted_4 = { class: "plan-t-prim-100 plan-t-xs" }
const _hoisted_5 = { class: "flex justify-start plan-gap-sm" }
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { class: "plan-t-prim-100 plan-t-xs" }
const _hoisted_8 = { class: "plan-t-h2 font-bold" }
const _hoisted_9 = { class: "flex justify-between flex-wrap items-start w-full plan-gap-sm" }
const _hoisted_10 = { class: "plan-t-xs plan-t-prim-100" }
const _hoisted_11 = { class: "plan-t-xs plan-t-prim-100" }
const _hoisted_12 = { class: "plan-t-xs plan-t-prim-100" }
const _hoisted_13 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_14 = { class: "plan-t-xs plan-t-prim-100" }
const _hoisted_15 = { class: "flex justify-start items-start plan-gap-xs" }
const _hoisted_16 = { class: "flex flex-col" }
const _hoisted_17 = {
  key: 0,
  class: "flex justify-start items-start plan-gap-xs plan-t-prim-200 plan-t-xxs"
}
const _hoisted_18 = {
  key: 0,
  class: "flex justify-between flex-wrap items-start w-full plan-gap-sm"
}
const _hoisted_19 = { class: "flex flex-col" }
const _hoisted_20 = { class: "plan-t-xs plan-t-prim-100" }
const _hoisted_21 = {
  key: 1,
  class: "flex justify-between flex-wrap items-start w-full plan-gap-sm"
}
const _hoisted_22 = { class: "flex flex-col" }
const _hoisted_23 = { class: "plan-t-xs plan-t-prim-100" }
const _hoisted_24 = {
  key: 2,
  class: "w-full flex flex-col"
}
const _hoisted_25 = { class: "plan-t-prim-100 plan-t-xs" }
const _hoisted_26 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_avatar = _resolveComponent("plan-avatar")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_formatted_number = _resolveComponent("plan-formatted-number")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('load-details')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "flex flex-col justify-start items-start",
        style: _normalizeStyle(_ctx.loadDetailElementWidth)
      }, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('customer')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_plan_avatar, {
            "break-word": true,
            "user-name": _ctx.shipperOwner.company,
            description: _ctx.shipperOwner.user
          }, null, 8, ["user-name", "description"]),
          _createElementVNode("div", _hoisted_6, [
            (!_ctx.isDriver)
              ? (_openBlock(), _createBlock(_component_plan_button_icon, {
                  key: 0,
                  onOnClick: _ctx.openChat,
                  icon: "chat",
                  type: "transparent-gray-800"
                }, null, 8, ["onOnClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ], 4),
      (_ctx.loadDetails.yourRate && !_ctx.isDriver && _ctx.isCarrierAssignedToThisLoad)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "flex flex-col justify-start items-start",
            style: _normalizeStyle(_ctx.loadDetailElementWidth)
          }, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('your-rate')), 1),
            _createElementVNode("p", _hoisted_8, [
              _createVNode(_component_plan_formatted_number, {
                number: _ctx.loadDetails.yourRate,
                unit: "currency"
              }, null, 8, ["number"])
            ])
          ], 4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", {
        class: "flex flex-col",
        style: _normalizeStyle(_ctx.loadDetailElementWidth)
      }, [
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('load-id')), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.loadDetails.identifier), 1)
      ], 4),
      _createElementVNode("div", {
        class: "flex flex-col",
        style: _normalizeStyle(_ctx.loadDetailElementWidth)
      }, [
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('po-number')), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.loadDetails.poNumber), 1)
      ], 4),
      _createElementVNode("div", {
        class: "flex flex-col",
        style: _normalizeStyle(_ctx.loadDetailElementWidth)
      }, [
        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('distance')), 1),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_plan_icon, {
            name: "distance",
            "icon-color": "#8A8B97",
            "is-svg": "",
            size: "15"
          }),
          _createVNode(_component_plan_formatted_number, {
            onlyCountry: _ctx.loadData?.type === 'MEX_DOMESTIC' ? 'mx' : '',
            number: _ctx.loadDetails.distance,
            unit: "distance"
          }, null, 8, ["onlyCountry", "number"])
        ])
      ], 4),
      _createElementVNode("div", {
        class: "flex flex-col",
        style: _normalizeStyle(_ctx.loadDetailElementWidth)
      }, [
        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('equipment')), 1),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_plan_icon, {
            name: "icInRoute",
            "icon-color": "#8A8B97",
            "is-svg": "",
            size: "15",
            class: "plan-pt-xs"
          }),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("span", null, _toDisplayString(!_ctx.europeCompany ? _ctx.loadDetails.trailer : _ctx.translateTrailer(_ctx.loadDetails.trailer)), 1),
            (_ctx.loadDetails.minTemperature)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createVNode(_component_plan_formatted_number, {
                    number: _ctx.loadDetails.minTemperature,
                    onlyCountry: _ctx.loadData?.type === 'MEX_DOMESTIC' ? 'mx' : '',
                    unit: "temperature"
                  }, null, 8, ["number", "onlyCountry"]),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('to')), 1),
                  _createVNode(_component_plan_formatted_number, {
                    number: _ctx.loadDetails.maxTemperature,
                    onlyCountry: _ctx.loadData?.type === 'MEX_DOMESTIC' ? 'mx' : '',
                    unit: "temperature"
                  }, null, 8, ["number", "onlyCountry"])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ], 4)
    ]),
    (_ctx.specialRequirements.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('special-requirements')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.specialRequirements), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loadDetails.specialPermissions.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('specialPermissions')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.loadDetails.specialPermissions), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loadDetails.observations.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t('observations')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.loadDetails.observations), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_26, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.additionalDocuments, (additionalDocument) => {
        return (_openBlock(), _createBlock(_component_plan_text_button, {
          onOnClick: ($event: any) => (_ctx.downloadDocument(additionalDocument)),
          loading: _ctx.documentsForDownload.includes(additionalDocument.id),
          key: additionalDocument.id,
          label: 
          _ctx.documentsForDownload.includes(additionalDocument.id)
            ? _ctx.$t('status-unloading') + '...'
            : additionalDocument.name
        ,
          icon: "paperclip"
        }, null, 8, ["onOnClick", "loading", "label"]))
      }), 128))
    ])
  ]))
}