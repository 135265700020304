
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import i18n from '@/resources/locales';
import * as Routing from '@/router/Routing';

import BaseComponent from '@/base/BaseComponent';
import BaseModal from '@/components/modals/BaseModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'ChangeTimeZoneModal',
  components: { BaseModal, PlanButton },
  mixins: [BaseComponent],
  emits: ['close'],
  props: {
    showModal: { type: Boolean, required: true, default: false },
  },
  setup(props, { emit }) {
    const store = useStore();
    const $t = i18n.global.t;
    const dtf = new Intl.DateTimeFormat();
    const tz = dtf.resolvedOptions().timeZone;
    const localStorageTZModal = localStorage.getItem('showAgainTZModal');

    const isLoading = ref(false);

    const accept = () => {
      isLoading.value = true;
      store
        .dispatch('UserStore/putUser', {
          id: currentUser.value.id,
          user: { timeZone: tz },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess($t('toast-profile-updated'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          isLoading.value = false;
          store.commit('UserStore/setShowModalTimezone', false);
        });
      return;
      Routing.openPlans();
    };

    const close = () => {
      store.commit('UserStore/setShowModalTimezone', false);
    };

    const dontShowAgain = () => {
      console.log('PASA POR AQUI');
      localStorage.setItem('showAgainTZModal', 'no');
      store.commit('UserStore/setShowModalTimezone', false);
    };

    const currentUser = computed(() => {
      return store.getters['UserStore/getCurrentUser'];
    });

    return {
      accept,
      close,
      dontShowAgain,
      isLoading,
      localStorageTZModal,
      currentUser,
      tz,
    };
  },
});
