
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanInputTextArea from '@/components/forms/PlanTextArea.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import { useStore } from 'vuex';
import PlanLoadFormCommodityData from '@/components/loads/form/domestic/PlanLoadFormCommodityData.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
export default defineComponent({
  name: 'PlanLoadFormTrailerAndCommodities',
  components: {
    PlanLoadFormCommodityData,
    PlanTextButton,
    PlanInputTextArea,
    PlanSelect,
  },
  props: {
    onlyCountry: { type: String, required: false, default: '' }
  },
  setup (props) {
    const store = useStore();

    const data = reactive({
      observations: '',
      trailerId: '',
      minTemp: null,
      maxTemp: null,
      commodities: [{ index: 0, commodityDescription: '', weight: null, units: null, type: '-1', removable: false }],
      commoditiesKey: 0,
      showTrailerValidation: false
    });

    const actualRegion = computed(() => {
      return store.getters['UserStore/getRegion'];
    });

    const actualWidth = computed(() => {
      return store.getters['UserStore/getActualWidth']
    })

    const addCommodity = () => {
      data.commodities.push({index: data.commodities.length, commodityDescription: '', weight: null, units: null, type: '-1', removable: true });
      store.commit('NewFtlLoad/setCommodities', data.commodities)
    };

    const changeCommodityObservations = () => {
      store.commit('NewFtlLoad/setCommodityObservations', data.observations)
    }

    const changeTrailer = (newTrailerId: string) => {
      data.trailerId = newTrailerId;
      updateTrailerData()
    };

    const getTemperatureUnit = () => {
      let result = ''
      if (props.onlyCountry.length > 0) {
        if (props.onlyCountry === 'mx') result = 'C'
      } else {
        result = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'F' : 'C'
      }
      return result
    }

    const isRefrigerated = computed(() => {
      let result = false;

      if (data.trailerId !== '' && vehicles.value.length > 0) {
        vehicles.value.forEach((vehicle: any) => {
          if (vehicle.value === data.trailerId) {
            result = vehicle.type === 'REFRIGERATED';
          }
        });
      }

      return result;
    });

    const isValidatedForm = computed(() => {
      return store.getters['NewFtlLoad/isValidatedForm'].trailer
    })

    const removeCommodity = (indexToRemove: number) => {
      data.commodities.splice(indexToRemove, 1)
      store.state.NewFtlLoad.commodities.splice(indexToRemove, 1)
      data.commodities.forEach((commodity: any, newIndex: number) => {
        commodity.index = newIndex;
      })
      store.commit('NewFtlLoad/setCommodities', data.commodities)
    };

    const updateCommodity = (commodity: any) => {
      const foundCommodity: any = data.commodities.find((item: any) => item.index === commodity.index);

      if (foundCommodity) {
        foundCommodity.commodityDescription = commodity.commodityDescription
        foundCommodity.weight = commodity.weight
        foundCommodity.units = commodity.units
        foundCommodity.type = commodity.type
        store.commit('NewFtlLoad/setCommodities', data.commodities);
      }
    };
    const updateTrailerData = () => {
      if (data.minTemp && data.maxTemp && data.minTemp > data.maxTemp) data.maxTemp = data.minTemp
      store.commit('NewFtlLoad/setTrailerData', {
        trailerId: data.trailerId,
        min: data.minTemp,
        max: data.maxTemp,
      })
    }

    const vehicles = computed(() => {
      let result: Array<any> = [];
      store.getters['LoadsStore/getTrailerTypes'].forEach((vehicle: any) => {
        result.push({
          label: vehicle.description,
          value: vehicle.id.toString(),
          type: vehicle.type,
        });
      });
      return result;
    });

    store.dispatch('LoadsStore/packageTypes');

    watch(() => store.getters['NewFtlLoad/getTemplateData'], (template: any) => {
      if (template) {
        data.minTemp = template.load.minTemp
        data.maxTemp = template.load.maxTemp
        changeTrailer(template.load.trailerType.id.toString())
        
        data.commodities = template.load.commodityMasters.map((commodity: any, index: number) => ({
          index,
          commodityDescription: commodity.commodityName,
          weight: commodity.weight,
          units: commodity.nPallets,
          type: commodity.packageType.id,
          removable: index !== 0
        }));
        store.commit('NewFtlLoad/setCommodities', data.commodities)
        data.commoditiesKey += 1
      }
    }, { immediate: true })

    return {
      ...toRefs(data),
      actualWidth,
      addCommodity,
      changeCommodityObservations,
      actualRegion,
      changeTrailer,
      getTemperatureUnit,
      isRefrigerated,
      isValidatedForm,
      removeCommodity,
      updateCommodity,
      updateTrailerData,
      vehicles,
    };
  },
});
