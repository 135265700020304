import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = { class: "text-2xl font-semibold" }
const _hoisted_3 = { class: "flex justify-center items-center" }
const _hoisted_4 = {
  key: 0,
  class: "flex justify-center items-center mr-5"
}
const _hoisted_5 = {
  key: 1,
  class: "mt-8 flex flex-col justify-between items-center p-3"
}
const _hoisted_6 = { class: "w-full px-3" }
const _hoisted_7 = {
  class: "block tracking-wide text-gray-700 text-sm mb-2",
  for: "grid-name"
}
const _hoisted_8 = { class: "w-full px-3 mt-4" }
const _hoisted_9 = {
  class: "block tracking-wide text-gray-700 text-sm mb-2",
  for: "grid-email"
}
const _hoisted_10 = {
  key: 0,
  class: "w-full px-3 mt-4"
}
const _hoisted_11 = {
  class: "block tracking-wide text-gray-700 text-sm mb-2",
  for: "grid-taxId"
}
const _hoisted_12 = { class: "flex w-full mt-4" }
const _hoisted_13 = { class: "w-2/3 px-3" }
const _hoisted_14 = {
  class: "block tracking-wide text-gray-700 text-sm mb-2",
  for: "grid-country"
}
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "w-1/3 px-3 ml-3" }
const _hoisted_18 = {
  class: "block tracking-wide text-gray-700 text-sm mb-2",
  for: "grid-zip"
}
const _hoisted_19 = { class: "w-full px-3 mt-4" }
const _hoisted_20 = {
  class: "block tracking-wide text-gray-700 text-sm mb-2",
  for: "grid-state"
}
const _hoisted_21 = ["placeholder"]
const _hoisted_22 = ["value"]
const _hoisted_23 = { class: "w-full px-3 mt-4" }
const _hoisted_24 = {
  class: "block tracking-wide text-gray-700 text-sm mb-2",
  for: "grid-taxId"
}
const _hoisted_25 = { class: "w-full px-3 mt-4" }
const _hoisted_26 = {
  class: "block tracking-wide text-gray-700 text-sm mb-2",
  for: "grid-address"
}
const _hoisted_27 = { class: "flex w-full px-3 mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_Loading = _resolveComponent("plan-Loading")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_Form = _resolveComponent("Form")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, null, {
    modalContent: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('billing-data-title')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_plan_button_icon, {
            onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeCompanyModal())),
            icon: "close",
            class: "mr-2"
          })
        ])
      ]),
      (_ctx.getUserInfoLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.getUserInfoLoading)
              ? (_openBlock(), _createBlock(_component_plan_Loading, { key: 0 }))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_Form, {
              ref: "form",
              onSubmit: ($event: any) => (null),
              class: "w-full flex-col"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('company')) + " *", 1),
                  _createVNode(_component_Field, {
                    class: "appearance-none block w-full bg-gray-100 text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                    id: "grid-name",
                    type: "text",
                    modelValue: _ctx.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event)),
                    name: "name",
                    rules: "required|max:256",
                    placeholder: _ctx.$t('label.company-name')
                  }, null, 8, ["modelValue", "placeholder"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "name",
                    class: "text-rose-500"
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('email')) + " *", 1),
                  _createVNode(_component_Field, {
                    class: "appearance-none block w-full bg-gray-100 text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                    id: "grid-email",
                    type: "text",
                    modelValue: _ctx.email,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
                    name: "email",
                    rules: "required",
                    placeholder: _ctx.$t('company-email')
                  }, null, 8, ["modelValue", "placeholder"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "email",
                    class: "text-rose-500"
                  })
                ]),
                (_ctx.isEuropeCompany)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('cif')), 1),
                      _createVNode(_component_Field, {
                        class: "appearance-none block w-full bg-gray-100 text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                        id: "grid-taxId",
                        type: "text",
                        modelValue: _ctx.taxId,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.taxId) = $event)),
                        name: "taxId",
                        rules: "",
                        placeholder: _ctx.$t('cif-placeholder')
                      }, null, 8, ["modelValue", "placeholder"]),
                      _createVNode(_component_ErrorMessage, {
                        name: "taxId",
                        class: "text-rose-500"
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('country')) + " *", 1),
                    _createVNode(_component_Field, {
                      name: "country",
                      rules: "required",
                      modelValue: _ctx.country,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.country) = $event))
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("select", {
                          id: "country",
                          class: "appearance-none block w-full bg-gray-100 text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                          rules: "required",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.country) = $event)),
                          placeholder: _ctx.$t('country')
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countriesList, (country, index) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: index,
                              value: country.id
                            }, _toDisplayString(country.name), 9, _hoisted_16))
                          }), 128))
                        ], 8, _hoisted_15), [
                          [_vModelSelect, _ctx.country]
                        ])
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(_component_ErrorMessage, {
                      name: "country",
                      class: "text-rose-500"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('placeholder-zip')) + " *", 1),
                    _createVNode(_component_Field, {
                      class: "appearance-none block w-full bg-gray-100 text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                      id: "grid-zip",
                      type: "text",
                      modelValue: _ctx.zip,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.zip) = $event)),
                      name: "zip",
                      rules: "required",
                      placeholder: _ctx.$t('placeholder-zip')
                    }, null, 8, ["modelValue", "placeholder"]),
                    _createVNode(_component_ErrorMessage, {
                      name: "zip",
                      class: "text-rose-500"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('state')), 1),
                  _createVNode(_component_Field, {
                    modelValue: _ctx.state,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state) = $event)),
                    name: "state",
                    rules: ""
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("select", {
                        id: "state",
                        class: "appearance-none block w-full bg-gray-100 text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                        rules: "required",
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state) = $event)),
                        placeholder: _ctx.$t('state')
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.states, (state, index) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: index,
                            value: state.id
                          }, _toDisplayString(state.name), 9, _hoisted_22))
                        }), 128))
                      ], 8, _hoisted_21), [
                        [_vModelSelect, _ctx.state]
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "state",
                    class: "text-rose-500"
                  })
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t('city')) + " *", 1),
                  _createVNode(_component_Field, {
                    class: "appearance-none block w-full bg-gray-100 text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                    id: "grid-city",
                    type: "text",
                    modelValue: _ctx.city,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.city) = $event)),
                    name: "city",
                    rules: "required",
                    placeholder: _ctx.$t('city')
                  }, null, 8, ["modelValue", "placeholder"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "city",
                    class: "text-rose-500"
                  })
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t('address')) + " *", 1),
                  _createVNode(_component_Field, {
                    class: "appearance-none block w-full bg-gray-100 text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                    id: "grid-address",
                    type: "text",
                    modelValue: _ctx.address,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.address) = $event)),
                    name: "address",
                    rules: "required",
                    placeholder: _ctx.$t('address')
                  }, null, 8, ["modelValue", "placeholder"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "address",
                    class: "text-rose-500"
                  })
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createVNode(_component_plan_button, {
                    onOnClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.cleanForm())),
                    class: "w-1/2 pr-2",
                    label: _ctx.$t('cancel'),
                    size: 'auto',
                    type: 'gray-outline'
                  }, null, 8, ["label"]),
                  _createVNode(_component_plan_button, {
                    onOnClick: _ctx.setBillingCompany,
                    class: "w-1/2 pl-2",
                    label: _ctx.$t('update'),
                    size: 'auto'
                  }, null, 8, ["onOnClick", "label"])
                ])
              ]),
              _: 1
            }, 512)
          ]))
    ]),
    _: 1
  }))
}