
import { computed, defineComponent, onBeforeMount, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import SpecialRequirementsFilter from '@/components/filters/SpecialRequirementsFilter.vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
export default defineComponent({
  name: 'ContactsTabFilter',
  mixins: [CountryMixins],
  components: { PlanSelectSimple, PlanIcon, SpecialRequirementsFilter, PlanInputCheckbox },
  emits: [
    'updateSearchTypeUser',
    'changeSelectedPermits',
    'changePeriod',
    'filterByCountry',
    'filterByCountryTypeUser',
  ],
  props: {
    load: { type: Object, required: true },
    trailerSelected: { type: Object, required: true },
    isCrossBorderLoad: { type: Boolean, required: false, default: false },
    showHistoricalRange: { type: Boolean, required: false, default: false },
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();
    const router = useRouter();

    const changeSelectedPermits = (permitsSelected) => {
      emit('changeSelectedPermits', permitsSelected);
    };

    const changePeriod = (changePeriod) => {
      emit('changePeriod', changePeriod);
    };

    const countriesList = computed(() => {
      return store.getters['CountryStore/getCountries'];
    });

    const USA = computed(() => {
      return countriesList.value.find((country) => country.name === 'United States');
    });

    const MEXICO = computed(() => {
      return countriesList.value.find((country) => country.name === 'Mexico');
    });

    const periodTypes = computed(() => {
      return [
        { label: translate.t('last-average-stats.LAST_MONTH'), value: 'LAST_MONTH' },
        { label: translate.t('last-average-stats.LAST_3_MONTHS'), value: 'LAST_3_MONTHS' },
        { label: translate.t('last-average-stats.LAST_6_MONTHS'), value: 'LAST_6_MONTHS' },
        { label: translate.t('last-average-stats.LAST_YEAR'), value: 'LAST_YEAR' },
      ];
    });


    const translateTrailer = (trailerTypeName) => {
      const clearName = trailerTypeName.replace(/[^\wáéíóú]/g, '');
      return translate.t(`trailer-types-eu.${clearName}`)
    };

    return {
      changeSelectedPermits,
      changePeriod,
      periodTypes,
      USA,
      MEXICO,
      translateTrailer,
    };
  },
});
