import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-white border-2 flex justify-center items-center gap-4 py-4 px-2 mt-2" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomAgentModal = _resolveComponent("CustomAgentModal")!
  const _component_plan_button = _resolveComponent("plan-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showCustomAgentModal)
      ? (_openBlock(), _createBlock(_component_CustomAgentModal, {
          key: 0,
          onClose: _ctx.toggleCustomAgentModal
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.currentLoad?.customsAgent?.id)
        ? (_openBlock(), _createBlock(_component_plan_button, {
            key: 0,
            type: "primary-outline",
            label: _ctx.$t('include-custom-agent'),
            onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleCustomAgentModal(true)))
          }, null, 8, ["label"]))
        : _createCommentVNode("", true),
      (!_ctx.currentLoad?.customsAgent?.id)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('include-your-custom-agent')), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(`${_ctx.$t('custom-agent').toUpperCase()}: `), 1),
            _createTextVNode(" " + _toDisplayString(`${_ctx.currentLoad?.customsAgent?.name} ${_ctx.currentLoad?.customsAgent?.surname} ${_ctx.currentLoad?.customsAgent?.email} ${_ctx.currentLoad?.customsAgent?.phonePrefix}${_ctx.currentLoad?.customsAgent?.phone}`), 1)
          ]))
    ])
  ], 64))
}