
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanToggleButton from '@/components/buttons/PlanToggleButton.vue';
import { useStore } from 'vuex';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';

interface planMailNotificationsConfigurationModal {
  id: number;
  loadingUserEmailNotificationSetup: boolean;
  loadingPost: boolean;
  notificationSetup: Array<any> | null;
  shipperVariables: {
    shipper_WhenCarrierSendQuotes: boolean;
    shipper_WhenCarrierAcceptQuotes: boolean;
    shipper_WhenCarrierSignedRC: boolean;
    shipper_WhenCarrierUploadBolPod: boolean;
    shipper_WhenCarrierUploadInvoice: boolean;
    shipper_WhenCarrierSendNewMessage: boolean;
  };
  carrierVariables: {
    carrier_WhenShipperRequestQuote: boolean;
    carrier_WhenShipperSelectMyQuote: boolean;
    carrier_WhenShipperFinishRequestBids: boolean;
    carrier_WhenShipperSendRememberBolPod: boolean;
    carrier_WhenShipperRequestLocation: boolean;
    carrier_WhenShipperModifyLoad: boolean;
    carrier_WhenShipperCancelLoad: boolean;
    carrier_WhenShipperSendNewMessage: boolean;
    carrier_WhenActivitySummary: boolean;
  };
}

export default defineComponent({
  name: 'PlanMailNotificationsConfigurationModal',
  components: {
    PlanCarrierDefaultModal,
    PlanLoading,
    PlanButton,
    PlanToggleButton,
    PlanDefaultModal,
  },
  emits: ['close'],
  props: {
    isCarrierView: { type: Boolean, required: false, default: false },
  },
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive<planMailNotificationsConfigurationModal>({
      id: -1,
      loadingUserEmailNotificationSetup: false,
      loadingPost: false,
      notificationSetup: null,
      shipperVariables: {
        shipper_WhenCarrierSendQuotes: true,
        shipper_WhenCarrierAcceptQuotes: true,
        shipper_WhenCarrierSignedRC: true,
        shipper_WhenCarrierUploadBolPod: true,
        shipper_WhenCarrierUploadInvoice: true,
        shipper_WhenCarrierSendNewMessage: true,
      },
      carrierVariables: {
        carrier_WhenShipperRequestQuote: true,
        carrier_WhenShipperSelectMyQuote: true,
        carrier_WhenShipperFinishRequestBids: true,
        carrier_WhenShipperSendRememberBolPod: true,
        carrier_WhenShipperRequestLocation: true,
        carrier_WhenShipperModifyLoad: true,
        carrier_WhenShipperCancelLoad: true,
        carrier_WhenShipperSendNewMessage: true,
        carrier_WhenActivitySummary: true,
      },
    });

    const carrierEntries = computed(() => Object.entries(data.carrierVariables));

    const changeToggle = (toggleValue: any) => {
      if (props.isCarrierView) data.carrierVariables[toggleValue.id] = toggleValue.value;
    };

    const close = () => {
      emit('close');
    };

    const getEmailNotificationsSetup = async () => {
      data.loadingUserEmailNotificationSetup = true;
      await store.dispatch('UserStore/getUserEmailNotificationSetup').then(() => {
        if (!userEmailNotificationSetup.value) {
          store.dispatch('UserStore/postUserEmailNotificationSetup').then(() => {
            store.dispatch('UserStore/getUserEmailNotificationSetup');
          });
        }
      });

      const actualSetup = store.getters['UserStore/getUserEmailNotificationSetup'];
      data.id = actualSetup.id;
      data.carrierVariables.carrier_WhenActivitySummary = actualSetup.carrier_WhenActivitySummary;
      data.carrierVariables.carrier_WhenShipperCancelLoad =
        actualSetup.carrier_WhenShipperCancelLoad;
      data.carrierVariables.carrier_WhenShipperFinishRequestBids =
        actualSetup.carrier_WhenShipperFinishRequestBids;
      data.carrierVariables.carrier_WhenShipperModifyLoad =
        actualSetup.carrier_WhenShipperModifyLoad;
      data.carrierVariables.carrier_WhenShipperRequestQuote =
        actualSetup.carrier_WhenShipperRequestQuote;
      data.carrierVariables.carrier_WhenShipperRequestLocation =
        actualSetup.carrier_WhenShipperRequestLocation;
      data.carrierVariables.carrier_WhenShipperFinishRequestBids =
        actualSetup.carrier_WhenShipperFinishRequestBids;
      data.carrierVariables.carrier_WhenShipperSelectMyQuote =
        actualSetup.carrier_WhenShipperSelectMyQuote;
      data.carrierVariables.carrier_WhenShipperSendNewMessage =
        actualSetup.carrier_WhenShipperSendNewMessage;
      data.carrierVariables.carrier_WhenShipperSendRememberBolPod =
        actualSetup.carrier_WhenShipperSendRememberBolPod;

      data.loadingUserEmailNotificationSetup = false;
    };

    const notificationEmailVariables = computed(() => {
      if (store.getters['UserStore/getCurrentUser'].company?.type === 'SHIPPER') {
        return store.getters['GlobalStore/getGlobalConfig']?.NotificationEmailVariables.SHIPPER;
      } else {
        return store.getters['GlobalStore/getGlobalConfig']?.NotificationEmailVariables.CARRIER;
      }
    });

    const postEmailNotificationsSetup = async () => {
      data.loadingPost = true;
      await store.dispatch('UserStore/putUserEmailNotificationSetup', {
        id: data.id,
        body: data.carrierVariables,
      });
      data.loadingPost = false;
    };

    const userEmailNotificationSetup = computed(() => {
      return store.getters['UserStore/getUserEmailNotificationSetup'];
    });

    watch(
      () => userEmailNotificationSetup.value,
      () => {
        if (userEmailNotificationSetup.value) {
          // this.notificationSetup = { ...userEmailNotificationSetup.value };
        }
      },
    );

    getEmailNotificationsSetup();

    return {
      ...toRefs(data),
      carrierEntries,
      changeToggle,
      close,
      notificationEmailVariables,
      postEmailNotificationsSetup,
      userEmailNotificationSetup,
    };
  },
});
