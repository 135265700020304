
import { defineComponent, reactive, toRefs, computed, onMounted, ref, onBeforeUnmount } from 'vue';

import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputTextArea from '@/components/forms/PlanTextArea.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import moment from 'moment';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

interface carrierBase {
  load: any;
  loadingLoad: boolean;
}

export default defineComponent({
  name: 'DriverContent',
  emits: ['updateFile', 'updateIncidence'],
  components: {
    PlanIcon,
    PlanButton,
    PlanInputTextArea,
    PlanInputFile,
  },
  props: {
    status: { type: String, required: true, default: null },
    file: { type: File, required: true, default: null },
    incidenceObject: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const currentStopTracking = computed(() => {
      return store.getters['DriverTrackingStore/currentStopTracking'];
    });

    const changeFiles = (newFiles: any) => {
      data.incidenceFile = newFiles;
      handleUpdateIncidence();
    };

    const currentTime = ref(moment().format('hh:mm:ss A'));

    let timeInterval = null as any;

    const updateTime = () => {
      currentTime.value = moment().format('hh:mm:ss A');
    };

    onMounted(() => {
      timeInterval = setInterval(updateTime, 1000);
    });

    onBeforeUnmount(() => {
      clearInterval(timeInterval);
    });

    const data = reactive({
      imagePreview: null as any,
      incidence: '',
      incidenceFile: null,
      incidenceId: null,
    });

    const currentUser = computed(() => {
      return store.getters['UserStore/getCurrentUser'];
    });

    const onFileChange = (event) => {
      const file = event.target.files[0];

      if (file) {
        emit('updateFile', event.target.files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
          data.imagePreview = e?.target?.result;
        };
        reader.readAsDataURL(file);
      }
    };

    const handleUpdateIncidence = () => {
      let objetToUpdate = {
        incidence: '',
        file: null as any,
        incidenceId: null,
      };

      objetToUpdate.incidence = data.incidence;
      objetToUpdate.file = data.incidenceFile;

      emit('updateIncidence', objetToUpdate);
    };

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const openCamera = () => {
      let getDocumentsCamera = document.getElementById('getDocumentsCamera');
      if (getDocumentsCamera) getDocumentsCamera.click();
    };

    const isDriver = computed(() => {
      return store.getters['CarrierStore/getIsDriver'];
    });

    return {
      ...toRefs(data),
      actualWidth,
      currentTime,
      changeFiles,
      currentStopTracking,
      handleUpdateIncidence,
      isDriver,
      loadData,
      onFileChange,
      openCamera,
    };
  },
});
