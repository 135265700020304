
import { defineComponent, reactive, toRefs } from 'vue';

export default defineComponent({
  name: 'PlanFilterZipType',
  emit: ['change'],
  setup(props, { emit }) {

    const data = reactive({
      zipType: '',
    });

    const handleZipType = (searchParameter) => {
      data.zipType =  data.zipType === searchParameter ? '': searchParameter;
      emit('change', searchParameter);
    };

    return {
      ...toRefs(data),
      handleZipType,
    };
  },
});
