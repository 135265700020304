
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
// Utils
import { formatDateStringReverse } from '@/utils/formatDates';

interface Insurance {
  number: string;
  limit: number;
  date: Date;
  file: File;
  expirationDate: any;
  mediaObject: any;
}

export default defineComponent({
  name: 'ContactDetailInsurance',
  components: {
    CheckCircleIcon,
    DatePickerComponent,
    Field,
    Form,
    InputAmericanFormat,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  props: {
    userType: {
      type: String,
    },
    userChecked: {
      type: String,
    },
  },
  data() {
    return {
      addDriver: false,
      addVehicle: false,
      allowed: true,
      cargoInsurance: {} as any,
      cargoInsuranceFile: {} as File,
      cargoInsuranceId: 0,
      cargoUploadedURL: '',
      carrierPackage: {} as File,
      currentDate: new Date(),
      DOTMCCopyMedia: {} as File,
      DOTMCMedia: {} as any,
      DOTMCMediaId: 0,
      dotUploadURL: '',
      errorOnSubmit: false,
      fetchedImageOriginal: null as any,
      isSending: false,
      liabilityInsurance: {} as any,
      liabilityInsuranceFile: {} as File,
      liabilityInsuranceId: 0,
      liabilityUploadedURL: '',
      validating: false,
      W9CopyMedia: {} as File,
      W9Media: {} as any,
      W9MediaId: 0,
      W9UploadedURL: '',
    };
  },
  created() {
    this.setInsurances(false);
  },
  computed: {
    cargoExpirationDate(): any {
      if (this.getCargoInsurance) {
        return new Date(this.getCargoInsurance.expirationDate);
      }
      return null;
    },
    updatingInsurancesStatus(): any {
      return this.$store.getters['CompanyStore/updatingInsurances'];
    },
    currentCompany(): any {
      return this.$store.getters['CompanyStore/getCompanyById'];
    },
    getUser(): any {
      return this.$store.getters['UserStore/getUser'];
    },
    getCargoInsurance(): any {
      if (this.currentCompany && this.currentCompany.insurances) {
        return this.currentCompany.insurances.find((insurance) => insurance.type === 'CARGO');
      }
      return undefined;
    },
    getDOTMCMedia(): any {
      if (this.currentCompany && this.currentCompany.insurances) {
        return this.currentCompany.mediaObjects.find((media) => media.type === 'DOT_MC_NUMBER');
      }
      return undefined;
    },
    getLiabilityInsurance(): any {
      if (this.currentCompany && this.currentCompany.insurances) {
        return this.currentCompany.insurances.find((insurance) => insurance.type === 'LIABILITY');
      }
      return undefined;
    },
    getW9Media(): any {
      if (this.currentCompany && this.currentCompany.insurances) {
        return this.currentCompany.mediaObjects.find((media) => media.type === 'W9');
      }
      return undefined;
    },
    liabilityExpirationDate(): any {
      if (this.getLiabilityInsurance) {
        return new Date(this.getLiabilityInsurance.expirationDate);
      }
      return null;
    },
    selectedOption(): string {
      return this.currentCompany.type;
    },
    shouldUpdate(): any {
      if (this.getCargoInsurance && this.getLiabilityInsurance) {
        return (
          this.getCargoInsurance.number !== this.cargoInsurance.number ||
          this.getCargoInsurance.expirationDate !== this.cargoInsurance.date ||
          this.getCargoInsurance.limitAmount !== this.cargoInsurance.limit ||
          this.getLiabilityInsurance.number !== this.liabilityInsurance.number ||
          this.getLiabilityInsurance.expirationDate !== this.liabilityInsurance.date ||
          this.getLiabilityInsurance.limitAmount !== this.liabilityInsurance.limit
        );
      }
      return null;
    },
  },
  methods: {
    async download(id) {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: id,
        class: 'INSURANCE',
      });

      const url = URL.createObjectURL(response);
      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-${id}`;
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    },
    async downloadCompany(id) {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: id,
        class: 'COMPANY',
      });

      const url = URL.createObjectURL(response);
      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-${id}`;
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    },
    async getFile(media: any): Promise<File> {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFile', {
        id: media.id,
        class: 'INSURANCE',
      });
      return new File([response], media.name, { type: media.mimeType });
    },
    async getFileCompany(media: any): Promise<File> {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFile', {
        id: media.id,
        class: 'COMPANY',
      });
      return new File([response], media.name, { type: media.mimeType });
    },
    handleChange(type: string) {
      switch (type) {
        case 'cargoInsuranceFile':
          this.cargoInsurance.file = (this.$refs.cargoInsuranceFile as any).files[0];
          break;
        case 'liabilityInsuranceFile':
          this.liabilityInsurance.file = (this.$refs.liabilityInsuranceFile as any).files[0];
          break;
        case 'W9':
          this.W9Media.file = (this.$refs.W9File as any).files[0];
          break;
        case 'DOTMC':
          this.DOTMCMedia.file = (this.$refs.DOTMCFile as any).files[0];
          break;
        case 'carrierPackage':
          this.carrierPackage = (this.$refs.carrierPackage as any).files[0];
          break;
      }
      this.validateForm();
    },
    async postInsurance(type: string) {
      const insurance = type === 'CARGO' ? this.cargoInsurance : this.liabilityInsurance;
      await this.$store
        .dispatch('ShipmentStore/postInsurances', {
          type: type,
          number: insurance.number,
          limitAmount: Number(insurance.limit),
          expirationDate: formatDateStringReverse(insurance.date),
          company: { id: this.currentCompany.id },
        })
        .then((response) => {
          if (type === 'CARGO') {
            this.cargoInsuranceId = response.id;
          } else {
            this.liabilityInsuranceId = response.id;
          }
        })
        .catch((err) => {
          this.errorOnSubmit = true;
        });
    },
    async postMediaObjectCompany(type: string) {
      let insurance = {} as any;
      switch (type) {
        case 'W9':
          insurance = this.W9Media;
          break;
        case 'DOT_MC_NUMBER':
          insurance = this.DOTMCMedia;
          break;
      }
      if (insurance.file) {
        await this.$store
          .dispatch('CompanyStore/postMediaObjectCompany', {
            entityId: this.currentCompany.id,
            file: insurance.file,
            type: type,
          })
          .catch((err) => {
            err.response.data.violations;
            this.errorOnSubmit = true;
          });
      }
    },
    async postMediaObjectInsurance(type: string) {
      const insurance = type === 'CARGO' ? this.cargoInsurance : this.liabilityInsurance;
      let idGetInsurance = null;
      if (type === 'CARGO' && this.getCargoInsurance !== undefined) {
        idGetInsurance = this.getCargoInsurance.id;
      } else if (this.getLiabilityInsurance !== undefined) {
        idGetInsurance = this.getLiabilityInsurance.id;
      }
      const idInsurance = type === 'CARGO' ? this.cargoInsuranceId : this.liabilityInsuranceId;
      await this.$store
        .dispatch('ShipmentStore/postMediaObjectInsurance', {
          entityId: idGetInsurance ? idGetInsurance : idInsurance,
          file: insurance.file,
        })
        .catch((err) => {
          err.response.data.violations;

          this.errorOnSubmit = true;
        });
    },
    async postUpdateMediaObjectCompany(type: string) {
      let insurance = {} as any;
      let mediaId = 0 as any;
      switch (type) {
        case 'W9':
          insurance = this.W9Media;
          mediaId = this.getW9Media.id;
          break;
        case 'DOT_MC_NUMBER':
          insurance = this.DOTMCMedia;
          mediaId = this.getDOTMCMedia.id;
          break;
      }
      if (mediaId) {
        await this.$store
          .dispatch('CompanyStore/postUpdateMediaObjectCompany', {
            id: mediaId,
            file: insurance.file,
          })
          .catch((err) => {
            err.response.data.violations;
            this.errorOnSubmit = true;
          });
      }
    },
    async postUpdateMediaObjectInsurance(type: string) {
      const insurance = type === 'CARGO' ? this.cargoInsurance : this.liabilityInsurance;
      await this.$store
        .dispatch('ShipmentStore/postUpdateMediaObjectInsurance', {
          id:
            type === 'CARGO'
              ? this.getCargoInsurance.mediaObject.id
              : this.getLiabilityInsurance.mediaObject.id,
          file: insurance.file,
        })
        .catch((err) => {
          err.response.data.violations;

          this.errorOnSubmit = true;
        });
    },
    async putInsurance(type: string) {
      const insurance = type === 'CARGO' ? this.cargoInsurance : this.liabilityInsurance;

      const regex = /\//g;
      let matches = insurance.date.toString().match(regex);
      let insuranceExpiration =
        matches !== null ? insurance.date.toString().replace(/\//g, '-') : insurance.date;

      await this.$store
        .dispatch('ShipmentStore/putInsurances', {
          id: type === 'CARGO' ? this.getCargoInsurance.id : this.getLiabilityInsurance.id,
          body: {
            number: insurance.number,
            limitAmount: Number(insurance.limit),
            expirationDate: formatDateStringReverse(new Date(insuranceExpiration)),
          },
        })
        .catch((err) => {
          err.response.data.violations;
          this.errorOnSubmit = true;
        });
    },
    async setInsurances(isUpdating) {
      if (this.getCargoInsurance && this.selectedOption === 'CARRIER') {
        if (
          (this.cargoUploadedURL === '' &&
            this.getCargoInsurance.mediaObject &&
            this.getCargoInsurance !== undefined &&
            this.getCargoInsurance !== null) ||
          isUpdating
        ) {
          const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
            id: this.getCargoInsurance?.mediaObject?.id,
            class: this.getCargoInsurance?.mediaObject?.entityClass,
          });
          this.cargoUploadedURL = URL.createObjectURL(result);
        }
        this.cargoInsurance.number = this.getCargoInsurance.number;
        this.cargoInsurance.limit = this.getCargoInsurance.limitAmount;
        this.cargoInsurance.date = this.getCargoInsurance.expirationDate.replace(/-/g, '/');
        const media = this.getCargoInsurance.mediaObject;
        if (media && !this.cargoInsurance.file) {
          const file = await this.getFile(media);
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          (this.$refs.cargoInsuranceFile as any).files = dataTransfer.files;
          this.cargoInsurance.file = file;
          this.cargoInsuranceFile = file;
        }
      }
      if (this.getLiabilityInsurance) {
        if (
          (this.liabilityUploadedURL === '' &&
            this.getLiabilityInsurance.mediaObject &&
            this.getLiabilityInsurance !== undefined &&
            this.getLiabilityInsurance !== null) ||
          isUpdating
        ) {
          const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
            id: this.getLiabilityInsurance?.mediaObject?.id,
            class: this.getLiabilityInsurance?.mediaObject?.entityClass,
          });
          this.liabilityUploadedURL = URL.createObjectURL(result);
        }
        this.liabilityInsurance.number = this.getLiabilityInsurance.number;
        this.liabilityInsurance.limit = this.getLiabilityInsurance.limitAmount;
        this.liabilityInsurance.date = this.getLiabilityInsurance.expirationDate.replace(/-/g, '/');
        const media = this.getLiabilityInsurance.mediaObject;
        if (media && !this.liabilityInsurance.file) {
          const file = await this.getFile(media);
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          (this.$refs.liabilityInsuranceFile as any).files = dataTransfer.files;
          this.liabilityInsurance.file = file;
          this.liabilityInsuranceFile = file;
        }
      }
      if (this.getW9Media) {
        const media = this.getW9Media;
        if (
          (this.getW9Media && this.getW9Media !== undefined && this.getW9Media !== null) ||
          isUpdating
        ) {
          const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
            id: this.getW9Media?.id,
            class: this.getW9Media?.entityClass,
          });
          this.W9UploadedURL = URL.createObjectURL(result);
        }
        if (media && !this.W9Media.file) {
          const file = await this.getFileCompany(media);
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          (this.$refs.W9File as any).files = dataTransfer.files;
          this.W9Media.file = file;
          this.W9CopyMedia = file;
        }
      }
      if (this.getDOTMCMedia) {
        const media = this.getDOTMCMedia;
        if (
          (this.getDOTMCMedia && this.getDOTMCMedia !== undefined && this.getDOTMCMedia !== null) ||
          isUpdating
        ) {
          const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
            id: this.getDOTMCMedia?.id,
            class: this.getDOTMCMedia?.entityClass,
          });
          this.dotUploadURL = URL.createObjectURL(result);
        }

        if (media && !this.DOTMCMedia.file) {
          const file = await this.getFileCompany(media);
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          (this.$refs.DOTMCFile as any).files = dataTransfer.files;
          this.DOTMCMedia.file = file;
          this.DOTMCCopyMedia = file;
        }
      }

      this.$store.commit('CompanyStore/updatingInsurances', false);
    },
    async validateForm() {
      this.validating = true;
      this.isSending = true;
      const validation = await (this.$refs.form as any).validate();
      let validDates = true;

      let insurances = {} as any;

      if (this.selectedOption === 'BROKER') {
        this.cargoInsurance.date = this.liabilityInsurance.date;
        this.cargoInsurance.file = this.liabilityInsurance.file;
      }

      if (
        !this.cargoInsurance.date ||
        !this.liabilityInsurance.date ||
        !this.cargoInsurance.file ||
        !this.liabilityInsurance.file
      ) {
        validDates = false;
      }

      if (!validation.valid || !validDates) {
        this.isSending = false;
        return;
      }

      insurances = {
        getCargoInsurance: {
          isCreated: this.getCargoInsurance,
          InsuranceFile: this.cargoInsuranceFile,
          ...this.cargoInsurance,
        },
        liabilityInsurance: {
          isCreated: this.getLiabilityInsurance,
          InsuranceFile: this.liabilityInsuranceFile,
          ...this.liabilityInsurance,
        },
        getW9Media: {
          isCreated: this.getW9Media,
          InsuranceFile: this.W9CopyMedia,
          ...this.W9Media,
        },
        DOTMCMedia: {
          isCreated: this.getDOTMCMedia,
          InsuranceFile: this.DOTMCCopyMedia,
          ...this.DOTMCMedia,
        },
      };

      this.$emit('updateInsurances', insurances);
      return;

      if (this.getCargoInsurance && this.selectedOption === 'CARRIER') {
        await this.putInsurance('CARGO');
      } else if (this.selectedOption === 'CARRIER') {
        await this.postInsurance('CARGO');
      }

      if (this.getLiabilityInsurance) {
        await this.putInsurance('LIABILITY');
      } else {
        await this.postInsurance('LIABILITY');
      }

      if (this.selectedOption === 'CARRIER') {
        if (this.cargoInsurance.file !== this.cargoInsuranceFile) {
          if (this.getCargoInsurance !== undefined) {
            if (this.getCargoInsurance.mediaObject) {
              await this.postUpdateMediaObjectInsurance('CARGO');
            }
          } else {
            await this.postMediaObjectInsurance('CARGO');
          }
        }
      }

      if (this.liabilityInsurance.file !== this.liabilityInsuranceFile) {
        if (this.getLiabilityInsurance !== undefined) {
          if (this.getLiabilityInsurance.mediaObject) {
            await this.postUpdateMediaObjectInsurance('LIABILITY');
          }
        } else {
          await this.postMediaObjectInsurance('LIABILITY');
        }
      }

      if (this.W9Media.file !== this.W9CopyMedia) {
        if (this.getW9Media !== undefined) {
          if (this.getW9Media) {
            await this.postUpdateMediaObjectCompany('W9');
          } else {
            await this.postMediaObjectCompany('W9');
          }
        } else {
          await this.postMediaObjectCompany('W9');
        }
      }

      if (this.DOTMCMedia.file !== this.DOTMCCopyMedia) {
        if (this.getDOTMCMedia !== undefined) {
          if (this.getDOTMCMedia) {
            await this.postUpdateMediaObjectCompany('DOT_MC_NUMBER');
          } else {
            await this.postMediaObjectCompany('DOT_MC_NUMBER');
          }
        } else {
          await this.postMediaObjectCompany('DOT_MC_NUMBER');
        }
      }

      this.$store.dispatch('CompanyStore/getCompany', this.getUser.company.id).then(() => {
        this.setInsurances(true);
      });
      BaseComponent.methods?.showToastSuccess(this.$t('updated-correctly'));
      this.isSending = false;
    },
  },
  watch: {
    async getUser() {
      await this.$store.dispatch('CompanyStore/getCompanyById', this.getUser.company.id);
    },
    updatingInsurancesStatus() {
      this.cargoInsurance.file = null;
      this.liabilityInsurance.file = null;
      this.DOTMCMedia.file = null;
      this.W9Media.file = null;

      this.setInsurances(true);
    },
  },
});
