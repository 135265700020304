import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "select-none",
  style: {"font-size":"12px"}
}
const _hoisted_2 = {
  key: 1,
  class: "font-bold"
}
const _hoisted_3 = { class: "flex flex-col plan-gap-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", {
    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHover = true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isHover = false)),
    class: _normalizeClass(["flex", 
      _ctx.labelInLeft ? 'justify-start items-center plan-gap-xs' : 'flex-col justify-end items-end'
    ])
  }, [
    (_ctx.label.length > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_popper, {
      show: _ctx.showPopper,
      offsetDistance: "0",
      placement: _ctx.placement,
      interactive: "",
      class: _normalizeClass(_ctx.defaultClassesOnly ? '' : 'popper-list')
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createBlock(_component_plan_button, {
              onOnClick: ($event: any) => (_ctx.changeValue(item.value)),
              key: item,
              type: _ctx.selectedValue === item.value ? 'primary-light' : 'white',
              label: item.label,
              class: _normalizeClass({ 'font-bold': _ctx.selectedValue === item.value }),
              "justify-align": "justify-start"
            }, null, 8, ["onOnClick", "type", "label", "class"]))
          }), 128))
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPopper = true)),
          class: "flex justify-center items-center plan-gap-xs cursor-pointer select-none"
        }, [
          (_ctx.selectedValue !== '')
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["font-bold", _ctx.lightStyle ? 'text-white' : 'plan-t-prim-500'])
              }, _toDisplayString(_ctx.selectedLabel), 3))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.placeholder), 1)),
          _createVNode(_component_plan_icon, {
            name: "chevron-down",
            "is-svg": "",
            "icon-color": _ctx.lightStyle ? 'white' : '#0D0E10'
          }, null, 8, ["icon-color"])
        ])
      ]),
      _: 1
    }, 8, ["show", "placement", "class"])
  ], 34))
}