
import { defineComponent, ref, computed, reactive, toRefs, watch, PropType } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';

interface Item {
  id: string;
  value: string | number;
  load_filter_param: string | number;
}

interface planFilter {
  inputValue: string;
  isFirstLoadingComplete: boolean;
  isInputActive: boolean;
  items: Array<Item>;
  loadingItems: boolean;
  page: number;
  maxResults: number;
  selectedItem: {
    value: string;
    loadFilterParam: string | number;
  };
  showPopper: boolean;
  totalResults: number;
}

export default defineComponent({
  name: 'PlanFilter',
  components: { PlanButtonIcon, PlanIcon },
  emits: ['status'],
  props: {
    id: { type: String, required: true, default: '' },
    icon: { type: String, required: true, default: '' },
    placeholder: { type: String, required: true, default: '' },
    title: { type: String, required: true, default: '' },
    resetSearch: { type: Boolean, required: false },
    projectId: {
      type: Number as PropType<number | null>,
      required: false,
    },
    rfpId: {
      type: Number as PropType<number | null>,
      required: false,
    },
    minInputWidth: { type: String, required: false, default: '75px' },
    maxInputWidth: { type: String, required: false, default: '200px' },
  },
  setup(props, { emit }) {
    const store = useStore();
    let debounceTimer;

    const data = reactive<planFilter>({
      inputValue: '',
      isFirstLoadingComplete: false,
      isInputActive: false,
      items: [],
      loadingItems: false,
      page: 1,
      maxResults: 100,
      selectedItem: {
        value: '',
        loadFilterParam: 0,
      },
      showPopper: false,
      totalResults: 0,
    });

    const iconColor = computed(() => {
      if (data.inputValue.length > 0) return '#151830';
      return data.isInputActive ? '#0097FF' : '#4F5264';
    });

    const inputStyle = computed(() => {
      if (data.inputValue === '') {
        return {
          width: props.minInputWidth,
        };
      } else {
        const tempInput = document.createElement('span');
        tempInput.style.display = 'inline-block';
        tempInput.style.visibility = 'hidden';
        tempInput.style.font = window.getComputedStyle(document.body).font;
        tempInput.textContent = data.inputValue;

        document.body.appendChild(tempInput);
        const inputWidth = tempInput.offsetWidth;
        document.body.removeChild(tempInput);

        return {
          'min-width': props.minInputWidth,
          width: inputWidth + 'px',
          'max-width': props.maxInputWidth,
        };
      }
    });

    const cleanItem = () => {
      data.inputValue = '';
      data.selectedItem.value = '';
      data.selectedItem.loadFilterParam = '';
      getLoadFieldAutocomplete();
      emitStatus();
    };

    const debounce = (func, delay) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(func, delay);
    };

    const deleteOnKeUp = () => {
      if (data.inputValue === '') {
        cleanItem();
        getLoadFieldAutocomplete();
      }
    };

    const emitStatus = () => {
      store.commit('LoadsStore/applyFilter', {
        id: props.id,
        value: data.selectedItem.value,
        loadFilterParam: data.selectedItem.loadFilterParam,
      });
      emit('status', {
        id: props.id,
        value: data.selectedItem.value,
        loadFilterParam: data.selectedItem.loadFilterParam,
      });
    };

    const getLoadFieldAutocomplete = async () => {
      try {
        data.loadingItems = true;
        let params = {
          page: data.page,
          maxResults: data.maxResults,
          type: props.id,
          search: data.inputValue,
        } as any;

        if (props.rfpId) {
          params.rfp = 'RFP_LANE';
          params.rfpId = props.rfpId;
        } else if (props.projectId) {
          params.rfp = 'RFP_PROJECT';
          params.rfpId = props.projectId;
        }

        const response = await store.dispatch('LoadsStore/getLoadFieldAutocomplete', params);

        data.items = response.values;
        data.totalResults = response.total[0];
      } catch (error) {
      } finally {
        data.loadingItems = false;
      }
    };

    const handleInput = () => {
      debounce(() => {
        getLoadFieldAutocomplete();
      }, 1000);
    };

    const handleInputFocus = () => {
      data.showPopper = true;
      data.isInputActive = true;
    };

    const handleInputBlur = () => {
      data.showPopper = false;
      data.isInputActive = false;
    };

    const onOpenPopper = () => {
      if (!data.isFirstLoadingComplete) {
        data.isFirstLoadingComplete = true;
        getLoadFieldAutocomplete();
      }
    };

    const selItem = (value: string, loadFilterParam: string | number) => {
      data.selectedItem.value = value;
      data.selectedItem.loadFilterParam = loadFilterParam;

      data.inputValue = value;
      data.showPopper = false;
      emitStatus();
    };

    if (store.getters['LoadsStore/getAppliedFilters'][props.id]) {
      data.inputValue = store.getters['LoadsStore/getAppliedFilters'][props.id].value;
      data.selectedItem.value = store.getters['LoadsStore/getAppliedFilters'][props.id].value;
      data.selectedItem.loadFilterParam =
        store.getters['LoadsStore/getAppliedFilters'][props.id].loadFilterParam;
    }

    watch(
      () => store.getters['LoadsStore/getAppliedFilters'],
      (newFilters, oldFilters) => {
        if (Object.keys(newFilters).length === 0 && data.inputValue !== '') {
          cleanItem();
        }
      },
    );

    watch(
      () => props.resetSearch,
      (newSearch, oldSearch) => {

        data.isFirstLoadingComplete = false;
      },
    );

    return {
      ...toRefs(data),
      iconColor,
      inputStyle,
      cleanItem,
      deleteOnKeUp,
      getLoadFieldAutocomplete,
      handleInput,
      handleInputFocus,
      handleInputBlur,
      onOpenPopper,
      selItem,
    };
  },
});
