
import { defineComponent, reactive, computed, toRefs } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import EmailTemplateForm from '@/modules/planning/contacts/BlastEmail/_forms/EmailTemplateForm.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

interface addTemplateModal {
  category: number | null;
  loading: boolean;
  templateName: string;
  subject: string;
  message: string;
}

export default defineComponent({
  name: 'AddTemplateModal',
  components: { EmailTemplateForm, PlanButton, PlanDefaultModal },
  emits: ['close', 'closeAndShow'],
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const data = reactive<addTemplateModal>({
      category: null,
      loading: false,
      templateName: '',
      subject: '',
      message: '',
    });

    const close = () => {
      store.commit('CompanyStore/clearTemplateToClone');
      emit('close');
    };

    const postEmailTemplate = () => {
      data.loading = true;

      const params = {
        category: { id: data.category },
        name: data.templateName,
        subject: data.subject,
        message: data.message,
      };
      store
        .dispatch('CompanyStore/postEmailTemplate', params)
        .then((response) => {
          store.commit('CompanyStore/clearTemplateToClone');
          store.commit('CompanyStore/selectTemplate', response);
          store.dispatch('UserStore/showToast', translate.t('success-template-generated'));

          emit('closeAndShow', response.id);
        })
        .finally(() => {
          data.loading = false;
        });
    };

    const update = (newData: any) => {
      data.category = newData.category;
      data.templateName = newData.templateName;
      data.subject = newData.subject;
      data.message = newData.message;
    };

    const validate = computed(() => {
      return (
        data.category !== null &&
        data.templateName.length > 0 &&
        data.subject.length > 0 &&
        data.message.length > 0
      );
    });

    if (store.getters['CompanyStore/getTemplateToClone'] !== null) {
      data.templateName =
        store.getters['CompanyStore/getTemplateToClone'].name.length > 0
          ? store.getters['CompanyStore/getTemplateToClone'].name + ' ' + translate.t('clone')
          : '';
      data.subject = store.getters['CompanyStore/getTemplateToClone'].subject;
      data.message = store.getters['CompanyStore/getTemplateToClone'].message;
    }

    return {
      ...toRefs(data),
      close,
      postEmailTemplate,
      update,
      validate,
    };
  },
});
