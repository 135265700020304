
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
// Components
import RankingRefusedItemsRight from '@/modules/rfp/_components/ranking/RankingRefusedItemsRight.vue';
import SetMaxResponseTimeModal from '@/modules/rfp/_components/modals/SetMaxResponseTimeModal.vue';
import PreAssignActionModal from '@/modules/rfp/_components/modals/PreAssignActionModal.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import moment from 'moment-timezone';

export default defineComponent({
  name: 'RankingTableRight',
  components: {
    RankingRefusedItemsRight,
    SetMaxResponseTimeModal,
    PlanButton,
    PreAssignActionModal,
    PlanDefaultModal,
    PlanButtonIcon,
    PlanInputNumber,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    currentLane: { type: Object },
    quotes: { type: Array as any },
    finalList: { type: Array as any },
    getRowStyle: { type: Function, required: true },
    isLastInGroup: { type: Function, required: true },
    statusDeclined: { type: Function, required: true },
    rankingQuoteType: { type: Object, required: true },
  },
  data() {
    return {
      currentQuote: null as any,
      firstUserConfirmEmail: 0,
      isFirstTimeConfirming: false,
      isConfirming: [] as any,
      isSending: [] as any,
      showQuotationTimeModal: false,
      showAssignModal: false,
      showModifyModal: false,
      price: null,
      bidIdSelected: null,
      state: '',
    };
  },
  computed: {
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },

    userRegion(): any {
      return this.currentUser.company?.country?.region?.name;
    },

    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    currentProject(): any {
      return this.$store.getters['RFPStore/getCurrentProject'];
    },
    isFirstConfirmQuote(): any {
      return this.quotes?.quotedQuotes.every(
        (quote) => quote.status === 'QUOTED' || quote.status === 'CONFIRM_EXPIRED',
      );
    },
    period(): any {
      return this.$store.getters['RFPStore/getCalendarIntervals'].intervals?.length;
    },
  },
  async created() {
    if (this.currentLane?.id) {
      await this.$store.dispatch('RFPStore/getCalendar_intervals', this.currentLane?.id);
    }
  },
  methods: {
    dateTime(date) {
      const dtf = new Intl.DateTimeFormat();
      const localTimezone = dtf.resolvedOptions().timeZone;
      const timezone = this.$store.getters['UserStore/getCurrentUser']?.timeZone ?? localTimezone;

      const formattedDateTime = moment.utc(date).tz(timezone).format('MMM DD, HH:mm');
      const [datePart, timePart] = formattedDateTime.split(', ');

      return {
        date: datePart,
        time: timePart,
      };
    },
    handleEmitModifyQuote() {
      this.$store
        .dispatch('RFPStore/updatePrice', {
          id: this.bidIdSelected,
          price: this.priceToNumber(this.price),
        })
        .then(() => {
          this.showModifyModal = false;
          this.$emit('fetch');
          this.bidIdSelected = null;
        });
    },
    pricePerMile(quote, totalMiles) {
      return (quote / totalMiles).toFixed(2);
    },
    callbackFetchLanesDetail() {
      const projectId = this.$route.params.projectId;

      this.$store.dispatch('RFPStore/getProjectById', {
        projectId: projectId,
        page: 1,
      });

      this.$store.dispatch('RFPStore/getLanesByProjectId', {
        projectId: projectId,
        page: 1,
      });
    },
    callbackToggleQuotationTimeModal() {
      this.showQuotationTimeModal = !this.showQuotationTimeModal;
    },
    confirmRanking(bidId) {
      // this.isConfirming.push(i);
      // // JUST FOR THE FIRST TIME USER MAIL REQUEST CONFIRMATION
      // if (this.isFirstConfirmQuote) {
      //   this.firstUserConfirmEmail = Number(bidId);
      //   this.isFirstTimeConfirming = true;
      //   this.showQuotationTimeModal = !this.showQuotationTimeModal;
      //   this.isConfirming = this.isConfirming.filter((num) => num !== i);

      //   return;
      // }

      // NORMAL LIFECYCLE
      this.$store
        .dispatch('RFPStore/postRequestQuoteConfirmation', {
          quoteId: bidId,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('ranking-confirm'));
          this.$store.dispatch('RFPStore/getProjectById', {
            projectId: Number(this.currentProject.project.id),
            page: 1,
          }),
            this.$store.dispatch('RFPStore/getLanesByProjectId', {
              projectId: Number(this.currentProject.project.id),
              page: 1,
            }),
            this.$store.dispatch('RFPStore/getRankingList', {
              id: this.currentLane?.id,
            });
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(this.$t('error'));
        })
        .finally(() => this.toggleAssignModal(false, null));
    },
    deleteFromRanking(id) {
      this.$store.dispatch('RFPStore/deleteFromRanking', id).then(() => {
        BaseComponent.methods?.showToastSuccess(this.$t('ranking-quote-delete'));
        this.$store.dispatch('RFPStore/getRankingList', {
          id: this.currentLane?.id,
        });
      });
    },
    determineCursor(element) {
      const assignedCapacity =
        element.quote.assignedCapacity || element.quote.autoSuggestedCapacity;
      if (assignedCapacity <= 1) {
        return false;
      } else {
        return true;
      }
    },
    determineCursorSum(element) {
      const assignedCapacity =
        element.quote.assignedCapacity || element.quote.autoSuggestedCapacity;
      if (assignedCapacity >= element.quote.maxCapacity) {
        return false;
      } else {
        return true;
      }
    },
    getBidButtonInfo(status) {
      let obj = {} as any;
      switch (status) {
        case this.rankingQuoteType.CONFIRM_EXPIRED:
        case this.rankingQuoteType.QUOTED:
        case this.rankingQuoteType.WAITING:
          obj = {
            color: 'primary',
            label: this.$t('accept'),
          };
          break;
        case this.rankingQuoteType.ACCEPTING:
          obj = {
            color: 'primary-outline',
            label: this.$t('pre-assigned'),
          };
          break;
        case this.rankingQuoteType.CONFIRMED:
          obj = {
            color: 'white',
            label: this.$t('assigned'),
          };
          break;
        case this.rankingQuoteType.DECLINED:
          obj = {
            color: 'error',
            label: this.$t('declined'),
          };
          break;
        case this.rankingQuoteType.REFUSED:
          obj = {
            color: 'error',
            label: this.$t('refused'),
          };
          break;
      }
      return obj;
    },
    getLastTDStyle(status) {
      if (
        status === this.rankingQuoteType.CONFIRMED ||
        status === this.rankingQuoteType.WAITING ||
        status === this.rankingQuoteType.ACCEPTING ||
        status === this.rankingQuoteType.QUOTED ||
        status === this.rankingQuoteType.CONFIRM_EXPIRED
      ) {
        return { backgroundColor: '#dbeafe' };
      }
      if (status === this.rankingQuoteType.REFUSED || status === this.rankingQuoteType.DECLINED) {
        return { backgroundColor: '#ffcfcf' };
      }
      return {};
    },
    isFirstInGroup(index) {
      if (index === 0) {
        return true;
      }

      const currentElement = this.finalList[index];
      const previousElement = this.finalList[index - 1];
      return currentElement.quote.rankingOrder !== previousElement.quote.rankingOrder;
    },
    isXtraCappacity(rankingOrder) {
      let totalCapacity = 0;

      for (const item of this.finalList) {
        if (item.quote.rankingOrder === rankingOrder) {
          totalCapacity += item.quote.assignedCapacity || item.quote.autoSuggestedCapacity;
        }
      }

      return totalCapacity;
    },
    isXtraCappacityVisible(index, element) {
      return (
        this.isFirstInGroup(index) &&
        this.isXtraCappacity(element.quote.rankingOrder) > this.currentLane?.loadsFrequency
      );
    },
    postNewCapacity(action, capacity, quote, maxCapacity) {
      if (action === 'sum' && capacity < +maxCapacity) {
        capacity += 1;
        this.$store
          .dispatch('RFPStore/postAssignedCapacity', { quoteId: quote, body: capacity })
          .then(() => {
            this.$store.dispatch('RFPStore/getRankingList', {
              id: this.currentLane?.id,
            });
          });
      } else if (action === 'subtract' && capacity > 1) {
        capacity -= 1;

        this.$store
          .dispatch('RFPStore/postAssignedCapacity', { quoteId: quote, body: capacity })
          .then(() => {
            this.$store.dispatch('RFPStore/getRankingList', {
              id: this.currentLane?.id,
            });
          });
      }
    },
    priceToNumber(price) {
      if (typeof price === 'number') {
        return price;
      }
      return parseFloat(price.replace(/[^\d.-]/g, ''));
    },
    resendQuote(bid, index) {
      this.isSending[index] = true;
      this.$store
        .dispatch('RFPStore/postResendQuoteEmail', {
          quoteId: bid.id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('email-sent'));
          this.$store.dispatch('RFPStore/getRankingList', {
            id: this.currentLane?.id,
          });
          this.isSending[index] = false;
        });
    },
    handleShowModifyModal(ranking) {
      this.price = ranking.quote.price;
      this.bidIdSelected = ranking.quote.id;
      this.showModifyModal = true;
    },
    revoke(lane, quoteId) {
      this.$store
        .dispatch('RFPStore/postRevokeQuote', quoteId)
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('revoked-correctly'));
          this.$store.dispatch('RFPStore/getProjectById', {
            projectId: Number(this.currentProject.project.id),
            page: 1,
          }),
            this.$store.dispatch('RFPStore/getLanesByProjectId', {
              projectId: Number(this.currentProject.project.id),
              page: 1,
            }),
            this.$store.dispatch('RFPStore/getRankingList', {
              id: lane?.id,
            });
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError('error');
        });
    },
    statusDeclinedStyles(status) {
      return status !== this.rankingQuoteType.REFUSED && status !== this.rankingQuoteType.DECLINED;
    },
    toggleAssignModal(open: boolean, ranking) {
      if(ranking?.status){
        switch (ranking?.status) {
          case this.rankingQuoteType.CONFIRM_EXPIRED:
          case this.rankingQuoteType.QUOTED:
          case this.rankingQuoteType.WAITING:
            this.confirmRanking(ranking?.quote?.id);
            this.showAssignModal = false;
            break;
          case this.rankingQuoteType.ACCEPTING:
            this.showAssignModal = open;
            break;
          case this.rankingQuoteType.CONFIRMED:
          case this.rankingQuoteType.DECLINED:
          case this.rankingQuoteType.REFUSED:
            this.showAssignModal = false;
            break;
        }
      } else{
        this.showAssignModal = false;
      }
      this.currentQuote = ranking;
    },
  },
});
