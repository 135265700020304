import ContactDetail from '@/modules/planning/contacts/ContactDetail.vue';
import ContactsListVue from '@/modules/planning/contacts/ContactsList.vue';
import Conversations from '@/modules/planning/messages/Conversations.vue';
import DayDetail from '@/modules/planning/calendar/DayDetail.vue';
import DirectAssignStep from '@/modules/planning/newLoad/steps/DirectAssignStep.vue';
import FreightInfoStep from '@/modules/planning/newLoad/steps/FreightInfoStep.vue';
import NewLoad from '@/modules/planning/newLoad/NewLoad.vue';
import NewLoadCrossBorder from '@/modules/planning/newLoad/NewLoadCrossBorder.vue';
import Messages from '@/modules/planning/messages/MessagesView.vue';
import Planification from '@/modules/planning/plannification/Planification.vue';
import Planning from '@/modules/planning/Planning.vue';
import QuoteStep from '@/modules/planning/newLoad/steps/QuoteStep.vue';
import ShipmentDataLTL from '@/modules/planning/newLoadLTL/ShipmentDataLTL.vue';
import ShipperDashboard from '@/modules/planning/dashboard/ShipperDashboard.vue';
import ShipperProfile from '@/modules/planning/userProfile/ShipperProfile.vue';
import StepsContainer from '@/modules/planning/newLoad/StepsContainer.vue';
import TeamView from '@/modules/planning/userProfile/team/TeamView.vue';
import UnreadMessages from '@/modules/planning/messages/UnreadMessages.vue';
import ImportBase from '@/modules/planning/contacts/ContactsImport/ImportBase.vue';
import WarehousesList from '@/modules/planning/warehouses/WarehousesList.vue';
import PlanificationOld from '@/modules/planning/plannification/PlanificationOld.vue';
import LoadCreation from '@/modules/planning/newLoad/LoadCreation.vue';
// Credit
import CreditRouter from '@/modules/credit/_router';

// RFP
import RFPRouter from '@/modules/rfp/_router';

// RFQ
import RFQRouter from '@/modules/carrier/rfq/_router';
import InstantQuote from '@/modules/planning/instantQuote/InstantQuote.vue';
import InstantQuoteDetails from '@/modules/planning/instantQuote/InstantQuoteDetails.vue';

// Payments
import PaymentsRouter from '@/modules/payments/_router';
import DemoView from '@/modules/demo/DemoView.vue';

import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

const beforeEnterUiComponents = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const showUiComponents = process.env.VUE_APP_SHOW_UI_COMPONENTS;
  if (showUiComponents === 'true') {
    next();
  } else {
    next({ name: 'planification' });
  }
};

const PlanningRouter = [
  {
    path: '/planning',
    name: 'planning',
    component: Planning,
    redirect: { name: 'planification' },
    children: [
      {
        path: 'load/new',
        name: 'LoadCreation',
        component: LoadCreation
      },
      {
        path: 'instant-quote',
        name: 'InstantQuote',
        component: InstantQuote
      },
      {
        path: 'instant-quote/details',
        name: 'InstantQuoteDetails',
        component: InstantQuoteDetails
      },
      {
        path: 'warehouses',
        name: 'warehousesList',
        component: WarehousesList,
        meta: {
          activeButton: 'warehouses',
        },
      },
      {
        path: 'dashboard',
        name: 'shipperDashboard',
        component: ShipperDashboard,
        meta: {
          activeButton: 'shipperDashboard',
        },
      },
      {
        path: '',
        name: 'planification',
        component: Planification,
      },
      {
        path: '',
        name: 'planification',
        component: Planification,
        meta: {
          activeButton: 'planning',
        },
      },
      {
        path: 'testDemo',
        name: 'planningTestDemo',
        component: PlanificationOld,
        beforeEnter: beforeEnterUiComponents,
        meta: {
          activeButton: 'planning',
        },
      },
      ...CreditRouter,
      {
        path: 'email',
        name: 'planificationEmail',
        component: Planification,
      },
      {
        path: ':id/:hash',
        name: 'planificationDetailTokenHash',
        component: Planification,
        props: true,
      },

      ...PaymentsRouter,

      // RFP
      ...RFPRouter,

      // Planning Detail
      {
        path: ':id/:hash/:code',
        name: 'planificationDetailToken',
        component: Planification,
        props: true,
      },
      {
        path: 'service/:id',
        name: 'planificationDetail',
        component: Planification,
        props: true,
        meta: {
          activeButton: 'planning',
        },
      },
      {
        path: 'day/:date',
        name: 'dayDetail',
        component: DayDetail,
        props: true,
        children: [
          {
            path: ':id',
            name: 'dayDetailId',
            component: DayDetail,
            props: true,
          },
        ],
      },

      // Contacts
      {
        path: 'contacts',
        name: 'contacts',
        component: ContactsListVue,
        meta: {
          activeButton: 'contacts',
        },
      },
      {
        path: 'contacts/import',
        name: 'importContacts',
        component: ImportBase,
      },

      {
        path: 'contacts/:id',
        name: 'contactDetail',
        component: ContactDetail,
      },

      // RFQ
      ...RFQRouter,

      // New Load
      {
        path: 'newLoad',
        name: 'newLoad',
        component: NewLoad,
        redirect: { name: 'StepsContainer' },
        meta: {
          activeButton: 'planning',
        },
        children: [
          {
            path: '',
            name: 'StepsContainer',
            component: StepsContainer,
          },
          {
            path: 'shipmentDataLTL',
            name: 'shipmentDataLTL',
            component: ShipmentDataLTL,
          },
          {
            path: 'freightInfo',
            name: 'freightInfo',
            component: FreightInfoStep,
          },
          {
            path: 'quote',
            name: 'quoteStep',
            component: QuoteStep,
          },
          {
            path: 'directAssign',
            name: 'directAssignStep',
            component: DirectAssignStep,
          },
          {
            path: '/:catchAll(.*)',
            redirect: { name: 'StepsContainer' },
          },
        ],
      },
      {
        path: 'newLoadCrossBorder',
        name: 'newLoadCrossBorder',
        component: NewLoadCrossBorder,
        meta: {
          activeButton: 'planning',
        },
      },

      // Messages
      {
        path: 'messages',
        name: 'messages',
        component: Messages,
        redirect: { name: 'unreadMessages' },
        meta: {
          activeButton: 'messages',
        },
        children: [
          {
            path: 'unread',
            name: 'unreadMessages',
            component: UnreadMessages,
          },
          {
            path: 'conversations',
            name: 'conversations',
            component: Conversations,
          },
        ],
      },
      {
        path: 'profile',
        name: 'shipperProfile',
        component: ShipperProfile,
        meta: {
          activeButton: 'planningProfile',
        },
      },
      {
        path: 'team',
        name: 'teamView',
        component: TeamView,
        meta: {
          activeButton: 'planningTeam',
        },
      },
    ],
  },
];

export default PlanningRouter;
