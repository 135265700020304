import {
  IContact,
  ICompany,
  INotification,
  IState,
  IToast,
  IBillingCompany,
} from '@/services/user/interfaces';
import { initialState } from '@/services/user/_store/index';

export default {
  // getUser(state: IState, payload: any) {
  //   state.getUser = payload;
  // },
  bigTexts(state: any, payload: any) {
    state.bigTexts = payload;
  },
  addValuesToList(state, values) {
    state.carrierContactList.values = [...state.carrierContactList.values, ...values];
  },
  carrierContactList(state: IState, payload: IContact[]) {
    state.carrierContactList = payload;
  },
  carrierContactListStatsByLoad(state: IState, payload: IContact[]) {
    state.carrierContactListStatsByLoad = payload;
  },
  carrierNetwork(state: IState, payload: IContact[]) {
    state.carrierNetwork = payload;
  },
  carrierNetworkTotal(state: IState, payload: object[]) {
    state.carrierNetworkTotal = payload;
  },
  carrierRequestedBid(state: IState, payload: IContact[]) {
    state.carrierRequestedBid = payload;
  },
  clearUserStore(state: IState) {
    Object.assign(state, initialState());
  },
  setActualWidth (state: IState, newWidth: number) {
    state.actualWidth = newWidth
  },
  getCompany(state: IState, payload: ICompany[]) {
    state.company = payload;
  },
  notificationItems(state: IState, payload: INotification[]) {
    state.notificationItems = payload;
  },
  pushUsersTeamData(state: any, payload: any) {
    if (Array.isArray(payload)) {
      state.dataTeam.push(...payload);
    } else {
      state.dataTeam.push(payload);
    }
  },
  requestBidStatsByLoad(state: IState, payload: IContact[]) {
    state.requestBidStatsByLoad = payload;
  },
  setCurrentUser(state: IState, payload: any) {
    state.currentUser = payload;
    state.isShipperViewer = payload?.roles?.some((role) => role === 'ROLE_SHIPPER_VIEWER');
  },
  setCollapsedSidebar(state: IState, collapsedSidebar: any) {
    state.collapsedSidebar = collapsedSidebar;
  },
  setImportingContacts(state: IState, newValue: boolean) {
    state.importingContacts = newValue;
  },
  setNewMessageContact(state: IState, payload: any) {
    state.carrierContactList.forEach((user: any, index) => {
      if (
        user.generalChatsWithNetworkOwner.length > 0 &&
        user.generalChatsWithNetworkOwner[0].id === payload.data.id &&
        payload.userId !== payload.data.writer.id
      ) {
        user.generalChatsWithNetworkOwner[0].readed = false;
        state.carrierContactList.splice(index, 1, user);
      }
    });
  },
  setRegion(state: any, newRegion: string) {
    state.region = newRegion;
  },
  setShowHash(state: any, payload: any) {
    state.showHash = payload;
  },
  setUser(state: IState, payload: object) {
    state.user = payload;
  },
  setUserEmailNotificationSetup(state: any, payload: any) {
    state.userEmailNotificationSetup = payload;
  },
  setUsersCompany(state: any, payload: any) {
    state.usersCompany = payload;
  },
  setUsersCompanyTotals(state: any, payload: any) {
    state.usersCompanyTotals = payload;
  },
  setUsersTeamData(state: any, payload: any) {
    state.dataTeam = payload;
  },
  unsiftCarrierContactList(state: IState, payload: any) {
    state.carrierContactList.unshift(payload);
  },
  updateNotification(state: IState, payload: INotification) {
    state.notification = payload;
  },
  updateToast(state: IState, payload: IToast) {
    state.toast = payload;
  },
  setBillingCompany(state: IState, payload: IBillingCompany) {
    state.billingCompany = payload;
  },
  setCurrentUserImpersonating(state: IState, payload: any) {
    state.currentUserImpersonating = payload;
  },
  setIsTheAdminImpersonating(state, payload) {
    state.isTheAdminImpersonating = payload;
  },
  setPreviousRoute(state, route) {
    state.previousRouteImpersonating = route;
  },
  setUserIdentification(state: IState, payload: any) {
    state.userIdentification = payload;
  },
  setCurrentContacts(state: any, payload: any) {
    state.currentContacts = payload;
  },
  setShowModalTimezone(state: any, payload: any) {
    state.showModalTimezone = payload;
  },
  setShowModalPlans(state: any, payload: any) {
    state.showModalPlans = payload;
  },
  updatePlanDefaultViewScrollTop(state: IState, payload: number) {
    state.planDefaultViewScrollTop = payload;
  },
};
