
import { defineComponent, reactive, toRefs, PropType } from 'vue';

export default defineComponent({
  name: 'PlanToggleButton',
  props: {
    isActive: { type: Boolean as PropType<boolean>, default: true },
    id: { type: String, required: false, default: '' },
    minimized: { type: Boolean, required: false, default: false }
  },
  emits: ['changeStatus', 'changeStatusWithId'],
  setup (props, { emit }) {
    const data = reactive({
      isActive: props.isActive
    })

    const changeStatus = () => {
      data.isActive = !data.isActive
      emit('changeStatus', data.isActive)
      emit('changeStatusWithId', { value: data.isActive, id: props.id })
    }

    return {
      ...toRefs(data),
      changeStatus
    }
  }
})
