
import { defineComponent } from 'vue';
import { DocumentArrowDownIcon } from '@heroicons/vue/24/outline';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import CardComponentHeaderRFP from '@/components/card/CardComponentHeaderRFP.vue';
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import ShipperLoadDetailContent from '@/modules/planning/loadDetail/ShipperLoadDetailContent.vue';
import { statusBackground } from '@/components/ComponentsTypes';
import CardTooltipOptions from '@/components/card/CardTooltipOptions.vue';
import UserSection from '@/modules/superAdmin/_components/UserSection.vue';
// Types
import { ShipmentTypeBackground } from '@/services/shipment/ShipmentTypes';
import TemplateNameModal from '@/modules/planning/loadDetail/_components/TemplateNameModal.vue';
// Utils
import { stringToShortDate } from '@/utils/formatDates';
import { truncateText } from '@/utils/formatString';
import { dateStringGetHours } from '@/utils/formatTime';
import PlanDefaultCityInformation from '@/components/defaults/PlanDefaultCityInformation.vue';
import LoadAlertsInfo from '@/components/loads/LoadAlertsInfo.vue';

interface Stop {
  executionDate: string;
  location: {
    city: string;
    state: {
      iso: string;
    };
    zip: string;
  };
}

export default defineComponent({
  name: 'CardComponent',
  components: {
    LoadAlertsInfo,
    PlanDefaultCityInformation,
    CardComponentHeaderRFP,
    DocumentArrowDownIcon,
    ModalConfirm,
    ShipperLoadDetailContent,
    CardTooltipOptions,
    TemplateNameModal,
    UserSection,
  },
  mixins: [BaseComponent],
  props: {
    day: {
      type: String,
    },
    dayDetail: {
      type: Boolean,
    },
    load: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Object,
    },
    allLoads: {
      type: Object,
    },
    index: { type: Number },
  },
  data() {
    return {
      loadTemplateId: null,
      ShipmentTypeBackground,
      showContentPickUp: false,
      showContentDeliverie: false,
      statusBackground,
      modalVisible: false,
      showVisible: false,
      isShowOptions: false,
      isDeleteLoad: false,
      showEdit: false,
      showCancel: false,
      showDelete: false,
      cancelModalText: this.$t('cancel-confirm'),
      deleteModalText: this.$t('delete-confirm'),
      nextRankingUser: '',
      showTemplateNameModal: false,
    };
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    commodityMasters(): any {
      let totalWeight = 0;
      let totalWeightStr = this.$t('total-weight');

      const commodityString = this.load.commodityMasters
        .map((item) => {
          totalWeight += item.weight;
          return `${item.commodityName} ${this.formatWeight(item.weight)}`;
        })
        .join(', ');

      return `${commodityString} - ${totalWeightStr}: ${this.formatWeight(`${totalWeight}`)}`;
    },

    currentStatus(): any {
      return this.load ? this.load.status : null;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    dateStringGetHours() {
      return dateStringGetHours;
    },
    deliveriesQuantity() {
      let sum = 1;
      this.load?.stops?.forEach((stop: any) => {
        if (stop.type === 'DELIVERY') {
          sum++;
        }
      });
      return sum;
    },
    deliveryStops(): any {
      let finalStops: any = [];
      const stops = this.load.stops;
      const stopsOrdered = stops.sort((a, b) => a.sequence - b.sequence);
      stopsOrdered?.forEach((stop: any) => {
        if (stop.type === 'DELIVERY_FINAL' || stop.type === 'DELIVERY') {
          finalStops.push(stop);
        }
      });
      return finalStops;
    },
    finalDeliveryStop(): Stop | null {
      let finalStop = null;
      this.load?.stops?.forEach((stop: any) => {
        if (stop.type === 'DELIVERY_FINAL') {
          finalStop = stop;
        }
      });
      return finalStop;
    },
    initialPickUpStop(): Stop | null {
      let initialStop = null;
      this.load?.stops?.forEach((stop: any) => {
        if (stop.type === 'PICKUP_INITIAL') {
          initialStop = stop;
        }
      });
      return initialStop;
    },
    isCanceled(): boolean {
      return this.load.status === 'CANCELED';
    },
    isLoadConfirmedOrQuotedOrAssigned(): any {
      const loadStatus = this.load.status;
      return loadStatus === 'CONFIRMED' || loadStatus === 'QUOTING' || loadStatus === 'ASSIGNED';
    },
    isModify(): boolean {
      return this.currentStatus === 'CONFIRMED' || this.currentStatus === 'QUOTING';
    },
    isOpenChat(): any {
      return this.$store.state.GlobalStore.isOpenChat;
    },
    isRead(): any {
      const member = this.load?.chat?.chatMembers.find(
        (member) => member.user.id === this.currentUser?.id,
      );
      return member ? member.readed : true;
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
    loadId(): any {
      return this.$store.state.LoadsStore.loadId;
    },
    loads(): any {
      return this.$store.getters['LoadsStore/loads'];
    },
    lowestBid(): number {
      let lowest: undefined | number = undefined;
      if (this.load.shipment.bestBids.length > 0) {
        this.load.shipment.bestBids.forEach((bid) => {
          if (lowest === undefined || lowest > bid.bid) {
            lowest = bid.bid;
          }
        });
      }
      return lowest === undefined ? 0 : lowest;
    },
    nBestBids(): any {
      return this.load?.shipment?.bestBids.length;
    },
    newLoadTemplates(): any {
      return this.$store.getters['LoadsStore/getSaveLoads'];
    },
    orderedStops(): any {
      const stops = this.load.stops;
      return stops;
    },
    pickUpStops(): any {
      let initialStops: any = [];
      const stops = this.load.stops;
      const stopsOrdered = stops.sort((a, b) => a.sequence - b.sequence);
      stopsOrdered.forEach((stop: any) => {
        if (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') {
          initialStops.push(stop);
        }
      });
      return initialStops;
    },
    picksQuantity() {
      let sum = 1;
      this.load?.stops?.forEach((stop: any) => {
        if (stop.type === 'PICKUP') {
          sum++;
        }
      });
      return sum;
    },
    quotes(): any {
      const filteredItems = this.load.shipment.assignConfirmationRFP.filter(
        (item) => item.status === 'WAITING',
      );

      if (filteredItems.length > 0) {
        this.checkNextInRanking(filteredItems[0]);
      }

      return {
        filteredItems,
      };
    },
    quotingChats(): any {
      return this.load?.quotingChats?.filter((chat) => {
        const hasUnreadMessages = chat.chatMembers.some(
          (member) => member.readed === false && member.user.id === this.currentUser.id,
        );
        return hasUnreadMessages;
      });
    },
    specialRequirements(): any {
      if (this.load?.specialRequirement) {
        const requirements = Object.keys(this.load?.specialRequirement).filter(
          (key) => this.load.specialRequirement[key] === true,
        );
        requirements.forEach((req, index) => {
          if (req === 'specialPermissions') {
            requirements[index] =
              this.$t(req) + ': ' + this.load.specialRequirement.specialPermissionsDescription;
          } else {
            requirements[index] = this.$t(req);
          }
        });
        return requirements.join(', ');
      }
      return null;
    },
    statusToCancel(): boolean {
      return (
        this.load.status === 'QUOTING' ||
        this.load.status === 'CONFIRMED' ||
        this.load.status === 'ASSIGNED'
      );
    },
    statusToDelete(): boolean {
      return this.load.status === 'QUOTING' || this.load.status === 'CONFIRMED';
    },
    stringToShortDate() {
      return stringToShortDate;
    },
    truncateText() {
      return truncateText;
    },
  },
  methods: {
    async addMemberToChatLoad(chatId) {
      await this.$store.dispatch('ChatsStore/addMemberToChatLoad', chatId);
    },
    cancelLoad() {
      if (!this.isCanceled && this.statusToCancel) {
        this.showCancel = true;
      }
    },
    checkNextInRanking(user) {
      if (!this.load.shipment.assignedCompany) {
        this.$store
          .dispatch('RFPStore/getRankingList', {
            id: this.load.laneRFP.id,
          })
          .then((response) => {
            const ranking = response.filter((item) => item.status !== 'WAITING');

            const index = ranking.findIndex(
              (item) => item.grouper.company.owner.id === user.quote.company.id,
            );

            if (index !== -1 && index < ranking.length - 1) {
              const nextUser = ranking[index + 1];
              this.nextRankingUser = nextUser;
            }
          });
      }
    },
    cloneLoad() {
      this.$store.commit('LoadsStore/currentLoadEditing', null);
      this.$store.dispatch('LoadsStore/currentLoadEditing', this.load);
      this.$store.dispatch('LoadsStore/loadId', this.load.id);
      this.$store.dispatch('LoadsStore/loadById', this.load.id);
      this.$router.push('/planning/newLoad');
    },
    createTemplate(id) {
      if (!this.isTemplate(id)) {
        this.loadTemplateId = id;
        this.showTemplateNameModal = true;
      }
    },
    deleteLoad() {
      this.isDeleteLoad = true;
      this.$emit('showModal', this.isDeleteLoad);
    },
    async download(load, mediaObjectId, mediaObjectType) {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: mediaObjectId,
        class: mediaObjectType,
      });

      const url = URL.createObjectURL(response);

      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `${
          load.type === 'PICKUP_INITIAL' || load.type === 'PICKUP' ? 'BOL-' : 'POD-'
        }#${load.sequence}-${mediaObjectId}`;
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    },
    filteredDeliveriesCompleted(load): any {
      const filteredObjects = load.filter(
        (obj) => (obj.type === 'DELIVERY_FINAL' || obj.type === 'DELIVERY') && obj.doneAt,
      ).length;
      return filteredObjects;
    },
    filteredPickupsCompleted(load): any {
      const filteredObjects = load.filter(
        (obj) => (obj.type === 'PICKUP_INITIAL' || obj.type === 'PICKUP') && obj.doneAt,
      ).length;
      return filteredObjects;
    },
    getLastDocumentUploaded(index): any {
      const biggestObject = this.orderedStops[index].mediaObjects.reduce((acc, cur) => {
        return cur.id > acc.id ? cur : acc;
      });

      return biggestObject;
    },
    goToDetail(id) {
      this.isShowOptions = false;
      this.$router.push(
        this.dayDetail && this.dayDetail !== null
          ? '/planning/service/' + id + '/day/' + this.day
          : '/planning/service/' + id,
      );
    },
    isTemplate(id) {
      return this.newLoadTemplates.find((template) => template.load.id === id);
    },
    loadPrevData() {
      //The inf of a load present in the card data
      localStorage.setItem('loadDetailPreviousData', JSON.stringify(this.load));
    },
    modifyLoad() {
      if (this.isModify) {
        this.showEdit = true;
        this.modalVisible = true;
        this.showVisible = true;
      }
    },
    async openChat(event, load) {
      event.stopPropagation();
      if (load.id !== this.loadId || !this.isOpenChat) {
        this.$store.commit('GlobalStore/setOpenChat', false);
        const member = this.load?.chat?.chatMembers.find(
          (member) => member.user.id === this.currentUser?.id,
        );
        if (member) {
          member.readed = true;
        } else {
          if (load?.chat?.id) {
            await this.addMemberToChatLoad(load.chat.id);
          }
        }
        setTimeout(() => {
          this.$store.commit('LoadsStore/loadId', load.id);
          this.$store.commit('ChatsStore/setChatId', load.chat ? load.chat.id : null);
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    openChatQuoting(event, load, chatMember) {
      event.stopPropagation();
      if (load.id !== this.loadId || !this.isOpenChat) {
        this.$store.commit('GlobalStore/setOpenChat', false);
        const id = chatMember.user.id;
        const findChat = load.quotingChats.find((chat) =>
          chat.chatMembers.some((member) => member.user.id === id),
        );
        const member = findChat.chatMembers.find(
          (member) => member.user.id === this.currentUser?.id,
        );
        if (member) member.readed = true;
        setTimeout(() => {
          this.$store.commit('LoadsStore/loadId', load.id);
          // this.loads.splice(this.index, 1, load);
          this.$store.commit('ChatsStore/setUserForChat', chatMember);
          this.$store.commit('ChatsStore/setChatId', findChat ? findChat.id : null);
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    routerToDetail() {
      this.loadPrevData();

      if (this.isSuperAdminRoute) {
        if (this.dayDetail && this.dayDetail !== null) {
          this.$router.push({ name: 'dayDetailAdminId', params: { id: this.load.id } });
        } else {
          this.$router.push({ name: 'loadDetailAdmin', params: { id: this.load.id } });
        }
      } else {
        if (this.dayDetail && this.dayDetail !== null) {
          this.$router.push({ name: 'dayDetailId', params: { id: this.load.id } });
        } else if (this.$route.name === 'LaneDetail') {
          this.$router.push({ name: 'laneService', params: { id: this.load.id } });
        } else if (this.$route.name === 'RFP-laneDetail') {
          this.$router.push({ name: 'RFP-laneService', params: { id: this.load.id } });
        } else if (this.$route.name === 'RFP-laneDay') {
          this.$router.push({ name: 'RFP-dayDetailId', params: { id: this.load.id } });
        } else {
          this.$store.commit('GlobalStore/setSectionFromPlanification', true);
          this.$router.push({
            name: 'planificationDetail',
            params: { id: this.load.id, direct: 'true' },
          });
        }
      }
    },
    showHideModal() {
      if (this.dayDetail && this.dayDetail !== null) {
        this.showVisible = false;
      } else {
        this.modalVisible = false;
        this.showVisible = false;
      }
    },
    submitCancelLoad(confirm: boolean) {
      if (confirm) {
        this.showCancel = false;
        this.isShowOptions = false;
        this.$store
          .dispatch('LoadsStore/cancelLoad', this.load.id)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('message-load-cancelled'));

            if (this.filters !== undefined) {
              var query = this.filters;
            } else {
              query = { page: 1 };
            }
            this.updateTotalStatus();
            this.$store.dispatch('LoadsStore/loads', query).then(() => {
              // this.closeModal();
            });
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });
      } else {
        this.showCancel = false;
        // this.isShowOptions = false;
      }
    },
    submitDeleteLoad(confirm: boolean) {
      if (confirm) {
        this.isDeleteLoad = false;
        this.isShowOptions = false;
        this.$store
          .dispatch('LoadsStore/deleteLoad', this.load.id)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('message-load-deleted'));

            if (this.filters !== undefined) {
              var query = this.filters;
            } else {
              query = { page: 1 };
            }
            this.$store.dispatch('LoadsStore/loadsTotals', this.filters);

            this.$store.dispatch('LoadsStore/loads', query).then();
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });
      } else {
        this.isDeleteLoad = false;
      }
    },
    submitModifyLoad(data: any) {
      if (!data) {
        this.showEdit = false;
      } else {
        this.$store.dispatch('LoadsStore/modifyLoad', data).then((res) => {
          this.showEdit = false;
          this.$store
            .dispatch('LoadsStore/loadById', this.load.id)
            .then(() => {
              // const query = this.filters;
              if (this.filters !== undefined) {
                var query = this.filters;
              } else {
                query = { page: 1 };
              }
              this.$store.dispatch('LoadsStore/loads', query).then(() => {
                BaseComponent.methods?.showToastSuccess(this.$t('message-load-modified'));
                this.showEdit = false;
              });
            })
            .catch((err) => {
              BaseComponent.methods?.showToastError(err?.response?.data?.detail);
              this.showEdit = false;
            });
        });
      }
    },
    updateTotalStatus() {
      this.$store.dispatch('LoadsStore/loadsTotals', this.filters);
    },
  },
});
