
import { defineComponent, computed, reactive } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useStore } from 'vuex';
import PlanCarrierSubmitBidModal from '@/components/carrier/_quoting/PlanCarrierSubmitBidModal.vue';
import PlanFormattedNumber from '@/components/labels/PlanFormattedNumber.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'PlanCarrierLoadBid',
  components: { PlanIcon, PlanFormattedNumber, PlanCarrierSubmitBidModal },
  setup() {
    const store = useStore();

    const data = reactive({
      showSubmitBidModal: false,
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const userBid = computed(() => {
      return store.getters['CarrierStore/getUserBid'];
    });

    return {
      loadData,
      userBid,
    };
  },
});
