import { initialState } from '@/services/loads/_store/index';

export default {
  setBidTotalsCrossCountry(state: any, payload: any) {
    state.bidTotalsCrossCountry = payload;
  },
  setCurrentLegIndex(state: any, payload: any) {
    state.currentLegIndex = payload;
  },
  setLoadDataContent(state: any, payload: any) {
    state.loadDataContent = payload;
  },
};
