
import { defineComponent, computed, reactive, toRefs, ref } from 'vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import { useStore } from 'vuex';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import PlanThumbnail from '@/components/forms/PlanThumbnail.vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { fi } from 'date-fns/locale';

interface MediaObject {
  id: number;
  type: string;
  name: string;
  entityClass: string;
}

interface PlanCarrierLoadAcceptationCarrierPackageForm {
  key: number;
  minDate: string;
  loadingCargoInsuranceFile: boolean;
  loadingLiabilityInsuranceFile: boolean;
  loadingCifFile: boolean;
  loadingSTC: boolean;
  loadingMcNumberFile: boolean;
  carrierPackageForm: {
    cargo: {
      id: number;
      number: string;
      expirationDate: string;
      limitAmount: null | string;
      mediaObject: null | MediaObject;
    };
    liabilityInsurance: {
      id: number;
      number: string;
      expirationDate: string;
      limitAmount: null | string;
      mediaObject: null | MediaObject;
    };
    cif: {
      number: string;
      mediaObject: null | MediaObject;
    };
    mcNumber: {
      number: string;
      mediaObject: null | MediaObject;
    };
    stc: {
      number: string;
      mediaObject: null | MediaObject;
    };
  };
}

export default defineComponent({
  name: 'PlanCarrierLoadAcceptationCarrierPackageForm',
  computed: {
    fi() {
      return fi
    }
  },
  components: { PlanThumbnail, PlanInputFile, PlanInputCalendar, PlanInputNumber, PlanInputText },
  setup() {
    const store = useStore();
    const translate = useI18n()

    const cargoInsuranceFiles = ref<File[]>([]);
    const liabilityInsuranceFiles = ref<File[]>([]);
    const cifFiles = ref<File[]>([]);
    const mcNumberFiles = ref<File[]>([]);
    const Files = ref<File[]>([]);
    const stcFiles = ref<File[]>([]);

    const data = reactive<PlanCarrierLoadAcceptationCarrierPackageForm>({
      key: 1,
      loadingCargoInsuranceFile: false,
      loadingLiabilityInsuranceFile: false,
      loadingCifFile: false,
      loadingSTC: false,
      loadingMcNumberFile: false,
      minDate: moment().format('DD-MM-YYYY'),
      carrierPackageForm: {
        cargo: {
          id: -1,
          number: '',
          expirationDate: '',
          limitAmount: null,
          mediaObject: null,
        },
        liabilityInsurance: {
          id: -1,
          number: '',
          expirationDate: '',
          limitAmount: null,
          mediaObject: null,
        },
        cif: {
          number: '',
          mediaObject: null,
        },
        mcNumber: {
          number: '',
          mediaObject: null,
        },
        stc: {
          number: '',
          mediaObject: null,
        },
      },
    });

    const actualRegion = computed(() => {
      return store.getters['UserStore/getRegion']
    })

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const changeCargoExpirationDate = (newDate: string) => {
      data.carrierPackageForm.cargo.expirationDate = moment(newDate, 'DD-MM-YYYY').format(
        'YYYY-MM-DD',
      );
      updateStore();
    };

    const changeCargoInsuranceFiles = (files: File[]) => {
      liabilityInsuranceFiles.value = files;
      if (data.carrierPackageForm.cargo.id !== -1 && data.carrierPackageForm.cargo.mediaObject) {
        postCargoUpdateMediaObjectInsurance();
      } else if (
        data.carrierPackageForm.cargo.id !== -1 &&
        !data.carrierPackageForm.cargo.mediaObject
      ) {
        postCargoMediaObjectInsurance();
      } else if (data.carrierPackageForm.cargo.id === -1) {
        store.commit('CarrierStore/setCargoInsuranceMediaObject', files[0]);
      }
    };

    const changeLiabilityInsuranceFiles = (files: File[]) => {
      liabilityInsuranceFiles.value = files;
      if (
        data.carrierPackageForm.liabilityInsurance.id !== -1 &&
        data.carrierPackageForm.liabilityInsurance.mediaObject
      ) {
        postLiabilityUpdateMediaObjectInsurance();
      } else if (
        data.carrierPackageForm.liabilityInsurance.id !== -1 &&
        !data.carrierPackageForm.liabilityInsurance.mediaObject
      ) {
        postLiabilityMediaObjectInsurance();
      } else if (data.carrierPackageForm.liabilityInsurance.id === -1) {
        store.commit('CarrierStore/setLiabilityInsuranceMediaObject', files[0]);
      }
    };

    const fieldNames = computed(() => {
      const region = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'usa' : 'eu'
      let dotNumber = translate.t(region === 'usa' ? 'fields.dot-number-usa' : 'fields.dot-number-eu')
      let mcNumber = translate.t(region === 'usa' ? 'fields.mc-number-usa' : 'fields.mc-number-eu')
      let fein = translate.t(region === 'usa' ? 'fields.fein-usa' : 'fields.fein-eu')

      return {
        dotNumber: dotNumber,
        mcNumber: mcNumber,
        fein: fein,
        sctNumber: translate.t('fields.sct-number')
      }
    })

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const changeCifFiles = async (files: File[]) => {
      cifFiles.value = files;
      data.loadingCifFile = true;
      if (data.carrierPackageForm.cif.mediaObject === null) {
        await store
          .dispatch('CompanyStore/postMediaObjectCompany', {
            entityId: currentUser.value.company.id,
            file: cifFiles.value[0],
            type: 'W9',
          })
          .then((response) => {
            data.carrierPackageForm.cif.mediaObject = response;
            data.key += 1;
            updateStore();
          })
          .finally(() => {
            data.loadingCifFile = false;
          });
      } else {
        await store
          .dispatch('CompanyStore/postUpdateMediaObjectCompany', {
            id: data.carrierPackageForm.cif.mediaObject?.id,
            file: cifFiles.value[0],
          })
          .then((response) => {
            data.carrierPackageForm.cif.mediaObject = response;
            data.key += 1;
            updateStore();
          })
          .finally(() => {
            data.loadingCifFile = false;
          });
      }
    };

    const changeLiabilityInsuranceExpirationDate = (newDate: string) => {
      data.carrierPackageForm.liabilityInsurance.expirationDate = moment(
        newDate,
        'DD-MM-YYYY',
      ).format('YYYY-MM-DD');
      updateStore();
    };

    const changeMcNumberFile = async (files: File[]) => {
      mcNumberFiles.value = files;
      data.loadingMcNumberFile = true;
      if (data.carrierPackageForm.mcNumber.mediaObject === null) {
        await store
          .dispatch('CompanyStore/postMediaObjectCompany', {
            entityId: currentUser.value.company.id,
            file: mcNumberFiles.value[0],
            type: 'DOT_MC_NUMBER',
          })
          .then((response) => {
            data.carrierPackageForm.mcNumber.mediaObject = response;
            data.key += 1;
            updateStore();
          })
          .finally(() => {
            data.loadingMcNumberFile = false;
          });
      } else {
        await store
          .dispatch('CompanyStore/postUpdateMediaObjectCompany', {
            id: data.carrierPackageForm.mcNumber.mediaObject?.id,
            file: mcNumberFiles.value[0],
          })
          .then((response) => {
            data.carrierPackageForm.mcNumber.mediaObject = response;
            data.key += 1;
            updateStore();
          })
          .finally(() => {
            data.loadingMcNumberFile = false;
          });
      }
    };

    const changeSTC = async (files: File[]) => {
      stcFiles.value = files;
      data.loadingSTC = true;

      if (data.carrierPackageForm.stc.mediaObject === null) {
        await store
          .dispatch('CompanyStore/postMediaObjectCompany', {
            entityId: currentUser.value.company.id,
            file: stcFiles.value[0],
            type: 'SCT',
          })
          .then((response) => {
            data.carrierPackageForm.stc.mediaObject = response;
            data.key += 1;
            updateStore();
          })
          .finally(() => {
            data.loadingSTC = false;
          });
      } else {
        await store
          .dispatch('CompanyStore/postUpdateMediaObjectCompany', {
            id: data.carrierPackageForm.stc.mediaObject?.id,
            file: stcFiles.value[0],
          })
          .then((response) => {
            data.carrierPackageForm.stc.mediaObject = response;
            data.key += 1;
            updateStore();
          })
          .finally(() => {
            data.loadingSTC = false;
          });
      }
    };

    const myDataForm = computed(() => {
      return store.getters['CarrierStore/getMyDataForm'];
    });

    const postCargoMediaObjectInsurance = async () => {
      data.loadingCargoInsuranceFile = true;
      const mediaObjectId = data.carrierPackageForm.cargo.id;
      if (mediaObjectId !== undefined) {
        await store
          .dispatch('ShipmentStore/postMediaObjectInsurance', {
            entityId: mediaObjectId,
            file: cargoInsuranceFiles.value[0],
          })
          .then((response) => {
            data.carrierPackageForm.cargo.mediaObject = response;
            updateStore();
          })
          .finally(() => {
            data.loadingCargoInsuranceFile = false;
          });
      }
    };

    const postLiabilityMediaObjectInsurance = async () => {
      data.loadingLiabilityInsuranceFile = true;
      const mediaObjectId = data.carrierPackageForm.liabilityInsurance.id;
      if (mediaObjectId !== undefined) {
        await store
          .dispatch('ShipmentStore/postMediaObjectInsurance', {
            entityId: mediaObjectId,
            file: liabilityInsuranceFiles.value[0],
          })
          .then((response) => {
            data.carrierPackageForm.liabilityInsurance.mediaObject = response;
            updateStore();
          })
          .finally(() => {
            data.loadingLiabilityInsuranceFile = false;
          });
      }
    };

    const postCargoUpdateMediaObjectInsurance = async () => {
      data.loadingCargoInsuranceFile = true;
      const mediaObjectId = data.carrierPackageForm.cargo.mediaObject?.id;
      if (mediaObjectId !== undefined) {
        await store
          .dispatch('ShipmentStore/postUpdateMediaObjectInsurance', {
            id: mediaObjectId,
            file: cargoInsuranceFiles.value[0],
          })
          .then((response) => {
            data.carrierPackageForm.cargo.mediaObject = response;
            updateStore();
          })
          .finally(() => {
            data.loadingCargoInsuranceFile = false;
          });
      }
    };

    const postLiabilityUpdateMediaObjectInsurance = async () => {
      data.loadingLiabilityInsuranceFile = true;
      const mediaObjectId = data.carrierPackageForm.liabilityInsurance.mediaObject?.id;
      if (mediaObjectId !== undefined) {
        await store
          .dispatch('ShipmentStore/postUpdateMediaObjectInsurance', {
            id: mediaObjectId,
            file: liabilityInsuranceFiles.value[0],
          })
          .then((response) => {
            data.carrierPackageForm.liabilityInsurance.mediaObject = response;
            updateStore();
          })
          .finally(() => {
            data.loadingLiabilityInsuranceFile = false;
          });
      }
    };

    const formattedInsurancesDate = (date) => {
      if (date) {
        return moment(date).format('DD-MM-YYYY');
      }
    };

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const updateStore = () => {
      data.key += 1;
      store.commit('CarrierStore/setCarrierPackageForm', {
        cargo: {
          id: data.carrierPackageForm.cargo.id,
          number: data.carrierPackageForm.cargo.number,
          expirationDate: data.carrierPackageForm.cargo.expirationDate,
          limitAmount: data.carrierPackageForm.cargo.limitAmount,
          mediaObject: data.carrierPackageForm.cargo.mediaObject,
        },
        liabilityInsurance: {
          id: data.carrierPackageForm.liabilityInsurance.id,
          number: data.carrierPackageForm.liabilityInsurance.number,
          expirationDate: data.carrierPackageForm.liabilityInsurance.expirationDate,
          limitAmount: data.carrierPackageForm.liabilityInsurance.limitAmount,
          mediaObject: data.carrierPackageForm.liabilityInsurance.mediaObject,
        },
        cif: {
          number: data.carrierPackageForm.cif.number,
          mediaObject: data.carrierPackageForm.cif.mediaObject,
        },
        mcNumber: {
          number: data.carrierPackageForm.mcNumber.number,
          mediaObject: data.carrierPackageForm.mcNumber.mediaObject,
        },
        stc: {
          number: data.carrierPackageForm.stc.number,
          mediaObject: data.carrierPackageForm.stc.mediaObject,
        },
      });
    };

    data.carrierPackageForm = store.getters['CarrierStore/getCarrierPackageForm'];

    return {
      ...toRefs(data),
      actualRegion,
      actualWidth,
      changeCargoExpirationDate,
      changeCargoInsuranceFiles,
      changeLiabilityInsuranceFiles,
      changeCifFiles,
      changeSTC,
      changeLiabilityInsuranceExpirationDate,
      changeMcNumberFile,
      fieldNames,
      formattedInsurancesDate,
      loadData,
      myDataForm,
      updateStore,
    };
  },
});
