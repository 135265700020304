import { defineComponent } from 'vue';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentArrowUpIcon,
  EllipsisHorizontalIcon,
  PaperClipIcon,
  RocketLaunchIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import { ExclamationTriangleIcon, StarIcon } from '@heroicons/vue/24/solid';
// Components
import PlanInputText from '@/components/forms/PlanInputText.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
import SpinBlack from '@/components/spin/AnimateBlackSpin.vue';
// Resources
import { localAssets } from '@/resources/assets/assets';
// Services
import store from '@/services/store';
// Utils
import {
  dateFormatLocale,
  dateTimeTZ,
  dateTimeMDH,
  formatDate,
  formatDateString,
  formatDateStringReverse,
  formatDateTime,
  getDayAfter,
  getDayBefore,
  getTimeDifference,
} from '@/utils/formatDates';
import { locationString, locationObject } from '@/utils/formatLocation';
import {
  formatMiles,
  formatPrice,
  formatPriceDecimals,
  formatString,
  formatTemperature,
  formatValoration,
  formatWeight,
  numberSuffixAndColor,
} from '@/utils/formatString';
import { formatTime } from '@/utils/formatTime';
import { getUserDefaultImage } from '@/utils/userImageDefault';

// Define a super class component
export default defineComponent({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    DocumentArrowUpIcon,
    EllipsisHorizontalIcon,
    ExclamationTriangleIcon,
    PaperClipIcon,
    PlanInputText,
    RocketLaunchIcon,
    StarIcon,
    Spin,
    SpinBlack,
    XCircleIcon,
    XMarkIcon,
  },
  computed: {
    currency(): string {
      // $ | £ | €
      return store.getters['CountryStore/getCurrencySymbol'];
    },
    currentCountry(): any {
      return store.getters['CountryStore/getCountry'];
    },
    currentCountryDistanceUnit(): any {
      // Km | Mi
      return store.getters['CountryStore/getMeasurementSystemDistanceUnit'];
    },
    currentCountryDistanceUnitIntl(): any {
      // kilometer | miles
      return store.getters['CountryStore/getMeasurementSystemDistanceUnitIntl'];
    },
    currentCountryTemperatureUnit(): any {
      // C | F
      return store.getters['CountryStore/getMeasurementSystemTemperatureUnit'];
    },
    currentCountryTemperatureUnitIntl(): any {
      // degree | celsius
      return store.getters['CountryStore/getMeasurementSystemTemperatureUnitIntl'];
    },
    currentCountryWeightUnit(): any {
      let weightToDisplay = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'lb' : 'kg'
      if (store.getters['LoadsStore/currentLoad'].type === 'MEX_DOMESTIC') weightToDisplay = 'kg'
      // Kg | Lb
      return weightToDisplay;
    },
    currentCountryWeightUnitIntl(): any {
      // kilogram | pound
      return store.getters['CountryStore/getMeasurementSystemWeightUnitIntl'];
    },
    getAsset() {
      return localAssets;
    },
    getLocale(): string {
      return store.getters['CountryStore/getCountryDefaultLanguageLocale'];
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
  },
  methods: {
    dateFormatLocale,
    dateTimeTZ,
    dateTimeMDH,
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    formatDate,
    formatDateString,
    formatDateStringReverse,
    formatDateTime,
    formatMiles,
    formatPrice,
    formatPriceDecimals,
    formatString,
    formatTemperature,
    formatTime,
    formatValoration,
    formatWeight,
    getDayAfter,
    getDayBefore,
    getTimeDifference,
    getUserDefaultImage,
    locationString,
    locationObject,
    numberSuffixAndColor,
    showToastError(message, callbackEnd?) {
      return store
        .dispatch('UserStore/updateToast', {
          text: message,
          type: 'error',
          visible: true,
        })
        .then(() => {
          if (callbackEnd) {
            callbackEnd();
          }
        });
    },
    showToastSuccess(message, callbackEnd?) {
      return store
        .dispatch('UserStore/updateToast', {
          text: message,
          type: 'success',
          visible: true,
        })
        .then(() => {
          if (callbackEnd) {
            callbackEnd();
          }
        });
    },
  },
});
