
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import moment from 'moment-timezone';
import BaseComponent from '@/base/BaseComponent';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/utils/formatDates';
import { formatMiles, formatWeight } from '@/utils/formatString';

interface uploadInvoice {
  billId: string,
  concept: string,
  files: FileList | null,
  showConfirmation: boolean,
  showLoading: boolean,
  showModal: boolean
}

export default defineComponent({
  name: 'UploadInvoice',
  components: { PlanIcon, PlanInputText, PlanButton, PlanDefaultModal },
  setup () {
    const store = useStore()
    const translate = useI18n()

    const data = reactive<uploadInvoice>({
      billId: '',
      concept: '',
      files: null,
      showConfirmation: false,
      showLoading: false,
      showModal: false
    })

    const addSpecialRequirement = (specialRequirements: string, newSpecialRequirement: string) => {
      if (specialRequirements.length > 0) {
        specialRequirements += ', ' + newSpecialRequirement
      } else {
        specialRequirements += newSpecialRequirement
      }

      return specialRequirements
    }

    const currentLoad = computed(() => {
      const load = store.getters['LoadsStore/currentLoad']
      const identifier = load?.identifier
      const pickUp = load?.stops[0]?.location?.city + ', ' + load?.stops[0]?.location?.state?.iso + ' ' + load?.stops[0]?.location?.state?.country?.iso
      const pickUpDate = formatDate(moment(load?.stops[0]?.executionDate))
      const deliveryIndex = load?.stops?.length - 1
      const delivery = load?.stops[deliveryIndex]?.location?.city + ', ' + load?.stops[deliveryIndex]?.location?.state?.iso + ' ' + load?.stops[deliveryIndex]?.location?.state?.country?.iso
      const deliveryDate = formatDate(moment(load?.stops[deliveryIndex]?.executionDate))
      const distance = formatMiles(load?.shipment?.totalDistance).toString() ;
      const trailer = load?.trailerType?.description

      let commodityMasters = ''
      load.commodityMasters?.forEach((commodityMaster, index) => {
        if (index !== 0) commodityMasters += ', '
        commodityMasters += commodityMaster.commodityName + ' ' + formatWeight(commodityMaster.weight)
      })

      const driver = (load?.shipment?.driver?.name !== undefined) ? load?.shipment?.driver?.name + ', ' : ''
      let specialRequirements = ''
      if (load?.specialRequirement.TWICCard) specialRequirements = addSpecialRequirement(specialRequirements, 'TWICC Card')
      if (load?.specialRequirement.bondedPermission) specialRequirements = addSpecialRequirement(specialRequirements, translate.t('bondedPermission'))
      if (load?.specialRequirement.hazardousLoad) specialRequirements = addSpecialRequirement(specialRequirements, translate.t('hazardousLoad'))
      if (load?.specialRequirement.specialPermissions) specialRequirements = addSpecialRequirement(specialRequirements, load?.specialRequirement.specialPermissionsDescription)
      if (load?.specialRequirement.teamDriver) specialRequirements = addSpecialRequirement(specialRequirements, translate.t('teamDriver'))
      if (load?.specialRequirement.tir) specialRequirements = addSpecialRequirement(specialRequirements, translate.t('tir'))
      if (load?.specialRequirement.tsa) specialRequirements = addSpecialRequirement(specialRequirements, translate.t('tsa'))
      if (load?.specialRequirement.oversize) specialRequirements = addSpecialRequirement(specialRequirements,  translate.t('oversize'))
      if (load?.specialRequirement.overweight) specialRequirements = addSpecialRequirement(specialRequirements, translate.t('overweight'))

      return {
        identifier: identifier,
        pickUp: pickUp,
        pickUpDate: pickUpDate,
        delivery: delivery,
        deliveryDate: deliveryDate,
        distance: distance,
        trailer: trailer,
        commodityMasters: commodityMasters,
        driverAndSpecialRequirements: driver + specialRequirements
      }
    })

    const totals = computed(() => {
      const load = store.getters['LoadsStore/currentLoad']
      const shipmentFees = store.getters['LoadsStore/getShipmentFees']
      const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];

      let currency = store.getters['CountryStore/getCountryCurrency'];
      currency = currency || 'USD';

      const loadTotal = load?.shipment?.assignedCompany?.price
      let additionalFeesTotal = 0
      const additionalFees: Array<string> = []

      shipmentFees.forEach(function(additionalFee: any) {
        if (additionalFee.status === 'STATUS_APPROVED') {
          additionalFeesTotal += additionalFee.value
          additionalFees.push(additionalFee.feeExplanation.name + ': ' + new Intl.NumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: 2 }).format(additionalFee.value) )
        }
      })

      const total = loadTotal + additionalFeesTotal

      return {
        cleanTotal: total,
        total: new Intl.NumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: 2 }).format(total),
        loadTotal: new Intl.NumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: 2 }).format(loadTotal),
        additionalFees: additionalFees
      }
    })

    const addMemberToChatLoad = async (chatId: number) => {
      await store.dispatch('ChatsStore/addMemberToChatLoad', chatId);
    }

    const changeStatusModal = () => {
      data.showModal = !data.showModal
    }

    const getInvoiceDocument = () => {
      let uploadInvoiceDocumentInput = document.getElementById('upload-invoice-document')
      if (uploadInvoiceDocumentInput) uploadInvoiceDocumentInput.click()
    }

    const getShipmentFees = async () => {
      const load = store.getters['LoadsStore/currentLoad']
      await store.dispatch('LoadsStore/getShipmentFees', load?.shipment.id)
    }

    const handleFileChange = (event) => {
      const maxFileSize = 10 * 1024 * 1024
      data.files = event.target.files[0]
      const file = event.target.files[0]
      if (file && file.size > maxFileSize) {
        store.dispatch('UserStore/updateToast', {
            text: translate.t('max-size-toast-message'),
            type: 'error',
            visible: true,
          })
        data.files = null
        }
    }

    const openChat = async () => {
      const load = store.getters['LoadsStore/currentLoad']
      const currentUser = store.getters['UserStore/getCurrentUser']
      data.showConfirmation = false
      data.showModal = false
      store.commit('GlobalStore/setOpenChat', true);
      const member = load?.chat?.chatMembers.find(
        (member) => member.user.id === currentUser?.id,
      );
      if (member) {
        member.readed = true;
      } else {
        if (load?.chat?.id) {
          await addMemberToChatLoad(load.chat.id);
        }
      }
      store.commit('LoadsStore/loadId', load.id);
      store.commit(
        'ChatsStore/setChatId',
        load.chat ? load.chat.id : null,
      );
      store.commit('GlobalStore/setOpenChat', true);
    }

    const postUploadInvoice = () => {
      data.showModal = false
      data.showConfirmation = true
    }

    const removeFiles = () => {
      data.files = null
    }

    const uploadInvoice = () => {
      const load = store.getters['LoadsStore/currentLoad']

      const payload = {
        number: data.billId,
        concept: data.concept,
        amount: totals.value.cleanTotal,
        shipmentId: load?.shipment.id,
      }
      data.showLoading = true
      data.showModal = false
      store.dispatch('ShipmentStore/postInvoiceFTL', payload)
        .then((response) => {
          uploadDocument(response.id);
        })
    }

    const uploadDocument = async (id) => {
     await store.dispatch('ShipmentStore/postMediaObjectInvoice', {
          entityId: id,
          file: data.files,
        })
        .then(() => {
          data.showModal = false
          data.showLoading = false
          data.showConfirmation = true
          store.dispatch('LoadsStore/loadById', store.getters['LoadsStore/currentLoad'].id);
        });
    }

    getShipmentFees()

    return {
      ...toRefs(data),
      currentLoad,
      totals,
      changeStatusModal,
      getInvoiceDocument,
      handleFileChange,
      openChat,
      postUploadInvoice,
      removeFiles,
      uploadInvoice
    }
  }
})
