import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-end items-center plan-gap-md" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-end items-center plan-gap-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_input_checkbox, {
      onChange: _ctx.changeMultipleLoads,
      label: _ctx.isActive ? _ctx.$t('multiple') : _ctx.$t('multiple-load')
    }, null, 8, ["onChange", "label"]),
    (_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_plan_button_icon, {
            onOnClick: _ctx.subtractLoads,
            disabled: _ctx.loads === 2,
            icon: "minus-circle",
            type: "transparent-gray-800"
          }, null, 8, ["onOnClick", "disabled"]),
          _createVNode(_component_plan_input_number, {
            onChange: _ctx.changeLoadsQuantity,
            "model-value": _ctx.loads,
            "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loads) = $event)),
            style: {"min-width":"50px","max-width":"50px"},
            "hide-validations": ""
          }, null, 8, ["onChange", "model-value"]),
          _createVNode(_component_plan_button_icon, {
            onOnClick: _ctx.addLoads,
            icon: "plus-circle",
            type: "transparent-gray-800"
          }, null, 8, ["onOnClick"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('loads')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}