import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_input_text = _resolveComponent("plan-input-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start' : 'justify-start items-end'])
    }, [
      _createVNode(_component_plan_select, {
        onChange: _ctx.changeSelectedTrailer,
        value: _ctx.selectedTrailer,
        items: _ctx.trailerTypes,
        label: _ctx.$t('vehicle-type'),
        placeholder: _ctx.$t('select'),
        mandatory: ""
      }, null, 8, ["onChange", "value", "items", "label", "placeholder"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start' : 'justify-start items-end'])
    }, [
      _createVNode(_component_plan_input_text, {
        "model-value": _ctx.licensePlate,
        "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.licensePlate) = $event)),
        label: _ctx.$t('license-plate'),
        onChange: _ctx.debouncedUpdateDriver,
        mandatory: ""
      }, null, 8, ["model-value", "label", "onChange"]),
      _createVNode(_component_plan_input_text, {
        "model-value": _ctx.trailerPlate,
        "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.trailerPlate) = $event)),
        label: _ctx.$t('trailer'),
        onChange: _ctx.debouncedUpdateDriver,
        mandatory: ""
      }, null, 8, ["model-value", "label", "onChange"])
    ], 2)
  ]))
}