import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-screen h-screen flex justify-center items-center"
}
const _hoisted_2 = { class: "font-bold text-white" }
const _hoisted_3 = {
  key: 1,
  class: "w-full flex flex-col plan-gap-md"
}
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "font-bold plan-t-h4" }
const _hoisted_6 = { class: "plan-t-prim-100" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "flex justify-end items-center plan-gap-sm"
}
const _hoisted_9 = { class: "plan-t-prim-100" }
const _hoisted_10 = {
  key: 0,
  class: "w-full flex flex-col justify-center items-center plan-gap-sm",
  style: {"max-width":"600px"}
}
const _hoisted_11 = {
  key: 0,
  class: "text-center plan-t-prim-100"
}
const _hoisted_12 = {
  key: 1,
  class: "w-full flex flex-col justify-center items-center plan-gap-sm",
  style: {"max-width":"600px"}
}
const _hoisted_13 = { class: "text-center plan-t-prim-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalBasic = _resolveComponent("ModalBasic")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_carrier_rfp_quote_header = _resolveComponent("plan-carrier-rfp-quote-header")!
  const _component_plan_carrier_rfp_quote_sub_header = _resolveComponent("plan-carrier-rfp-quote-sub-header")!
  const _component_plan_carrier_load_header_notification = _resolveComponent("plan-carrier-load-header-notification")!
  const _component_plan_carrier_rfp_quoting_per_lane = _resolveComponent("plan-carrier-rfp-quoting-per-lane")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_import_quoting_modal = _resolveComponent("plan-carrier-import-quoting-modal")!
  const _component_plan_default_carrier_view = _resolveComponent("plan-default-carrier-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ModalBasic, {
      buttons: [
      {
        text: _ctx.$t('continue'),
        type: 'corp',
        callback: () => (_ctx.showModalSuccess = false),
      },
    ],
      icSuccess: true,
      noMargin: true,
      open: _ctx.showModalSuccess,
      textKeys: {
      body: '',
      header: _ctx.$t('message-quotes-sent-correctly'),
      message: '',
    }
    }, null, 8, ["buttons", "open", "textKeys"]),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_plan_icon, {
            name: "spinner",
            size: "40",
            "icon-color": "#151830",
            "is-svg": "",
            rotate: ""
          })
        ]))
      : (_openBlock(), _createBlock(_component_plan_default_carrier_view, {
          key: 1,
          "show-footer": ""
        }, {
          "header-content": _withCtx(() => [
            _createVNode(_component_plan_carrier_rfp_quote_header)
          ]),
          "subheader-content": _withCtx(() => [
            _createVNode(_component_plan_carrier_rfp_quote_sub_header),
            (_ctx.totalQuotes > 0)
              ? (_openBlock(), _createBlock(_component_plan_carrier_load_header_notification, {
                  key: 0,
                  type: "prim"
                }, {
                  container: _withCtx(() => [
                    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('lane-quoting-info')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          content: _withCtx(() => [
            (_ctx.loadingTable)
              ? (_openBlock(), _createBlock(_component_plan_icon, {
                  key: 0,
                  name: "spinner",
                  size: "40",
                  "icon-color": "#151830",
                  "is-svg": "",
                  rotate: ""
                }))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('request-for-quote')), 1),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('enter-valuation-and-capacity')), 1)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLanesAndQuotesForCarrier, (item, index) => {
                    return (_openBlock(), _createBlock(_component_plan_carrier_rfp_quoting_per_lane, {
                      onUpdateLanesQuotation: _ctx.handleUpdateQuotation,
                      onHandleDeleteQuote: _ctx.handleDeleteQuote,
                      onDeleteFromArray: _ctx.deleteFromArray,
                      onHandleConfirmLane: _ctx.confirmLane,
                      onlyDetails: _ctx.onlyDetails,
                      item: item,
                      index: index,
                      key: index,
                      "show-remove": !_ctx.onlyDetails,
                      "position-pre-assigned": _ctx.getPositionPreAssigned(item)
                    }, null, 8, ["onUpdateLanesQuotation", "onHandleDeleteQuote", "onDeleteFromArray", "onHandleConfirmLane", "onlyDetails", "item", "index", "show-remove", "position-pre-assigned"]))
                  }), 128)),
                  (_ctx.totalPages > 1)
                    ? (_openBlock(), _createElementBlock("hr", _hoisted_7))
                    : _createCommentVNode("", true),
                  (_ctx.totalPages > 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_plan_button_icon, {
                          onOnClick: _ctx.previousPage,
                          disabled: _ctx.currentPage === 1,
                          icon: "chevron-left",
                          type: "transparent-gray-800"
                        }, null, 8, ["onOnClick", "disabled"]),
                        _createElementVNode("span", _hoisted_9, _toDisplayString(`${_ctx.$t('page')} ${_ctx.currentPage} ${_ctx.$t('of')} ${_ctx.totalPages}`), 1),
                        _createVNode(_component_plan_button_icon, {
                          onOnClick: _ctx.nextPage,
                          disabled: _ctx.currentPage === _ctx.totalPages,
                          icon: "chevron-right",
                          type: "transparent-gray-800"
                        }, null, 8, ["onOnClick", "disabled"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
          ]),
          footer: _withCtx(() => [
            (_ctx.totalQuotes > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  (_ctx.quotationDate)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t('quotations-sent-on-date', { date: _ctx.formatDate(_ctx.quotationDate) })), 1))
                    : _createCommentVNode("", true),
                  (_ctx.onlyDetails)
                    ? (_openBlock(), _createBlock(_component_plan_button, {
                        key: 1,
                        onOnClick: _ctx.changeMode,
                        type: "success",
                        label: _ctx.$t('update-quotes'),
                        class: "w-full"
                      }, null, 8, ["onOnClick", "label"]))
                    : _createCommentVNode("", true),
                  (_ctx.onlyDetails)
                    ? (_openBlock(), _createBlock(_component_plan_carrier_import_quoting_modal, {
                        key: 2,
                        onUpdate: _ctx.updateRfpData
                      }, null, 8, ["onUpdate"]))
                    : _createCommentVNode("", true),
                  (!_ctx.onlyDetails && _ctx.quotesArray.length > 0)
                    ? (_openBlock(), _createBlock(_component_plan_button, {
                        key: 3,
                        onOnClick: _ctx.sendQuotation,
                        type: "success",
                        loading: _ctx.isSendingQuotations,
                        label: 
            _ctx.$t('send-lane-quotation', {
              length: _ctx.quotesArray.length,
            })
          ,
                        class: "w-full"
                      }, null, 8, ["onOnClick", "loading", "label"]))
                    : _createCommentVNode("", true),
                  (!_ctx.onlyDetails)
                    ? (_openBlock(), _createBlock(_component_plan_button, {
                        key: 4,
                        onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onlyDetails = true)),
                        type: "cancel",
                        label: "Cancelar",
                        class: "w-full"
                      }))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('lanes-quoted', {
              lanes: _ctx.totalQuotes,
              totals: _ctx.getLanesAndQuotesForCarrier.length,
            })), 1),
                  (_ctx.quotesArray.length > 0)
                    ? (_openBlock(), _createBlock(_component_plan_button, {
                        key: 0,
                        onOnClick: _ctx.sendQuotation,
                        type: "success",
                        loading: _ctx.isSendingQuotations,
                        label: 
            _ctx.$t('send-lane-quotation', {
              length: _ctx.quotesArray.length,
            })
          ,
                        class: "w-full"
                      }, null, 8, ["onOnClick", "loading", "label"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_plan_carrier_import_quoting_modal, { onUpdate: _ctx.updateRfpData }, null, 8, ["onUpdate"])
                ]))
          ]),
          _: 1
        }))
  ], 64))
}