
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import SkeletonHeaderLaneDetails from '@/components/skeletons/HeaderLaneDetailsSkeleton.vue';
import SkeletonLaneList from '@/components/skeletons/LaneListSkeleton.vue';
// Modules
import EndQuotationModal from '@/modules/rfp/_components/modals/EndQuotationModal.vue';
import HeaderLaneDetailsActions from '@/modules/rfp/_components/HeaderLaneDetailsActions.vue';
import RankingRFPModal from '@/modules/rfp/_components/modals/RankingRFPModal.vue';
import RequestQuotingView from '@/modules/rfp/_components/modals/RequestQuotingModal.vue';
import SetMaxResponseTimeModal from '@/modules/rfp/_components/modals/SetMaxResponseTimeModal.vue';
import SetQuotationTimeModal from '@/modules/rfp/_components/modals/SetQuotationTimeModal.vue';
import TableLaneDetailsRight from '@/modules/rfp/_components/TableLaneDetailsRight.vue';
// Styles
import HorizontalScroll from '@/resources/assets/styles/animation/HorizontalScroll.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';

export default defineComponent({
  name: 'LanesList',
  components: {
    EndQuotationModal,
    HeaderLaneDetailsActions,
    RankingRFPModal,
    RequestQuotingView,
    SetMaxResponseTimeModal,
    SetQuotationTimeModal,
    SkeletonHeaderLaneDetails,
    SkeletonLaneList,
    TableLaneDetailsRight,
  },
  mixins: [BaseComponent, CountryMixins, HorizontalScroll],
  props: {
    id: String,
    hash: {
      type: String,
      default: null,
    },
    code: String,
    laneListObtained: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      carriersQuote: [],
      currentLaneRanking: {},
      currentWeek: 0,
      fetchDataOnHover: false,
      isLoading: false,
      maxTotalWeeks: 0,
      projectDataMatch: false,
      showQuotationTimeModal: false,
      showMaxResponseTimeModal: false,
      showRequestQuotingModal: false,
      showQuotationModal: false,
      showRankingModal: false,
      currentWeekStart: '',
      currentWeekEnd: '',
      isCurrentWeek: false,
      completedPercentage: 0,
      unassignedPercentage: 0,
      unplannedPercentage: 0,
    };
  },
  created() {
    if(!this.$store.getters['LoadsStore/packageTypes']?.FTL?.length){
      this.$store.dispatch('LoadsStore/packageTypes');
    }
    this.$store.commit('RFPStore/setCurrentQuotesPlanned', []);
    this.projectDataMatch =
      this.currentProject &&
      this.currentProject.project &&
      +this.$route.params.projectId === this.currentProject.project.id;
    this.$store
      .dispatch('RFPStore/getProjectInfoByWeek', {
        id: +this.$route.params.projectId,
        week: 0,
      })
      .then((response) => {
        // this.currentWeek = response.currentWeek;
        this.maxTotalWeeks = response.totalWeeks;
        this.currentWeekStart = response.weekStart;
        this.currentWeekEnd = response.weekEnd;
        this.isCurrentWeek = response.isCurrentWeek;
        this.completedPercentage = response.CompletedPercentage;
        this.unassignedPercentage = response.UnassignedPercentage;
        this.unplannedPercentage = response.UnplannedPercentage;
      });
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },

    currentProject(): any {
      return this.$store.getters['RFPStore/getCurrentProject'];
    },
    editingLanes(): any {
      return this.$store.getters['LanesStore/editingLanes'];
    },
    lanesList(): any {
      return this.$store.getters['RFPStore/getLanes'];
    },
    lanesListModified(): any {
      if (this.currentWeek === 0) {
        return this.$store.getters['RFPStore/getLanes'];
      } else {
        const changeLanes = this.$store.getters['RFPStore/getLanesInformationPerWeek'];

        const lanesList = this.deepClone(this.$store.getters['RFPStore/getLanes']);

        const modifiedArray = lanesList.map((item) => {
          const { id, computed } = item;
          const matchingLane = changeLanes.byLanes.find((lane) => lane.laneId === id);

          if (matchingLane) {
            computed.loads = { ...computed.loads, ...matchingLane };
          }

          return item;
        });

        return modifiedArray;
      }
    },
    project(): any {
      if (this.lanesList.project) {
        return this.lanesList.project;
      } else {
        return null;
      }
    },
    projectDataLoaded(): any {
      return this.projectDataMatch;
    },
  },
  methods: {
    getPackageType (id) {
      let result = ''
      this.$store.getters['LoadsStore/packageTypes']?.FTL?.forEach((packageType) => {
        if (packageType.id === id) result = this.$t('types.' + packageType.fullName)
      })
      return result
    },
    callbackFetchLanesDetail() {
      const projectId = this.$route.params.projectId;

      this.$store.dispatch('RFPStore/getProjectById', {
        projectId: projectId,
        page: 1,
      });

      this.$store.dispatch('RFPStore/getLanesByProjectId', {
        projectId: projectId,
        page: 1
      });
    },
    callbackNextQuotationStep(carrierList) {
      this.carriersQuote = carrierList;
      this.callbackToggleRequestQuotingModal();
      this.callbackToggleQuotationTimeModal();
    },
    callbackSendAutomaticNotification() {
      this.isLoading = true;
      const projectId = this.$route.params.projectId;
      const body = {};
      this.$store
        .dispatch('RFPStore/postProjectRFPRequestAllQuotesConfirmation', {
          projectId,
          body,
        })
        .then(() => {
          this.callbackToggleEndQuotationModal();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    callbackToggleEndQuotationModal() {
      this.showQuotationModal = !this.showQuotationModal;
    },
    callbackToggleMaxResponseModal() {
      this.showMaxResponseTimeModal = !this.showMaxResponseTimeModal;
    },
    callbackToggleQuotationTimeModal() {
      this.showQuotationTimeModal = !this.showQuotationTimeModal;
    },
    callbackToggleRequestQuotingModal() {
      this.showRequestQuotingModal = !this.showRequestQuotingModal;
    },
    callbackToggleResponseTimeModal() {
      this.showMaxResponseTimeModal = !this.showMaxResponseTimeModal;
    },
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    editCurrentLanes() {
      this.$store.commit('RFPStore/setCurrentEditingLanes', this.lanesList);
      const projectId = this.$route.params.projectId;

      this.$router.push({
        name: 'addLanesRFP',
        params: { projectId },
        query: { loadCreationMode: 'Editing' },
      });
    },
    getDateEnd(lane) {
      return this.formatDate(
        new Date(
          lane.loadTemplate?.stops?.find((stop) => stop.type === 'DELIVERY_FINAL').executionDate,
        ),
      );
    },
    getDateStart(lane) {
      return this.formatDate(
        new Date(
          lane.loadTemplate?.stops?.find((stop) => stop.type === 'PICKUP_INITIAL').executionDate,
        ),
      );
    },
    getTemperatures(lane) {
      const minTemp = lane.loadTemplate.minTemp;
      const maxTemp = lane.loadTemplate.maxTemp;

      if (!isNaN(maxTemp) && minTemp !== maxTemp) {
        return `(${this.formatTemperature(minTemp)} , ${this.formatTemperature(maxTemp)})`;
      } else {
        return `(${this.formatTemperature(minTemp)})`;
      }
    },
    loadsFrequencyPeriod(frequencyValue, frequencyPeriod) {
      let translation = '';
      switch (frequencyPeriod) {
        case 'WEEK':
          translation = this.$t('frequency-week-n', { value: frequencyValue });
          break;
        case 'MONTH':
          translation = this.$t('frequency-month-n', { value: frequencyValue });
          break;
        case 'TOTAL':
          translation = this.$t('frequency-total-n', { value: frequencyValue });
          break;
      }

      return translation;
    },
    openRankingModal(lane) {
      this.currentLaneRanking = lane;
      this.$store.commit('RFPStore/setCurrentLane', lane);

      this.showRankingModal = !this.showRankingModal;
    },
    setupLanesInfo() {
      this.$store
        .dispatch('RFPStore/getProjectInfoByWeek', {
          id: +this.$route.params.projectId,
          week: this.currentWeek,
        })
        .then((response) => {
          this.currentWeekStart = response.weekStart;
          this.currentWeekEnd = response.weekEnd;
          this.isCurrentWeek = response.isCurrentWeek;
          this.completedPercentage = response.CompletedPercentage;
          this.unassignedPercentage = response.UnassignedPercentage;
          this.unplannedPercentage = response.UnplannedPercentage;
        });
    },
    subtractOneFromCurrentWeek() {
      this.currentWeek -= 1;
    },
    sumOneFromCurrentWeek() {
      this.currentWeek += 1;
    },
    translateTrailer(trailerTypeName){
      const clearName = trailerTypeName.replace(/[^\wáéíóú]/g, '');
      return this.$t(`trailer-types-eu.${clearName}`)
    },
  },
  watch: {
    currentProject() {
      this.projectDataMatch = +this.$route.params.projectId === this.currentProject.project.id;
    },
    currentWeek: function (newDestiny, oldDestiny) {
      this.setupLanesInfo();
    },
  },
});
