
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Modules
import CarrierValoration from '@/modules/planning/loadDetail/finished/CarrierValoration.vue';
import CustomAgentBanner from '@/modules/planning/loadDetail/_components/CustomAgentBanner.vue';
import LoadDetails from '@/modules/planning/loadDetail/_components/LoadDetails.vue';
import LoadDetailHeaderContentButtons from '@/components/loads/detail/LoadDetailHeaderContentButtons.vue';

export default defineComponent({
  name: 'ShipperLoadDetailFinished',
  components: {
    CarrierValoration,
    CustomAgentBanner,
    LoadDetails,
    LoadDetailHeaderContentButtons,
  },
  mixins: [BaseComponent],
  computed: {
    currentLoad(): any {
      let load = this.$store.getters['LoadsStore/currentLoad'];
      if (load?.legs) {
        load.legs = load?.legs?.sort((a, b) => a.legOrder - b.legOrder);
      }
      return load?.legs?.length > 0 ? load.legs[this.currentLegIndex] : load;
    },
    currentLegIndex(): any {
      return this.$store.getters['LoadDetailStore/getCurrentLegIndex'];
    },
    isCrossBorderLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad']?.legs?.length > 0;
    },
    headerContentLeftButtons(): any {
      let headers = [
        { title: 'LEG 1 - MX Domestic', subtitle: '-', value: '0' },
        { title: 'LEG 2 - Transfer MX to USA', subtitle: '-', value: '1' },
        { title: 'LEG 3 - USA Domestic', subtitle: '-', value: '2' },
      ];
      const load = this.$store.getters['LoadsStore/currentLoad'];
      load.legs.forEach((leg, index) => {
        headers[index].subtitle = leg?.shipment?.finishedDate
          ? this.dateTimeTZ(leg?.shipment?.finishedDate)
          : this.$t('pending');
      });
      return headers;
    },
  },
  methods: {
    handleReturnSelected() {
      if (this.currentLoad.status !== 'QUOTING' && this.currentLoad.shipment.assignedCompany) {
        return 'assignment';
      } else if (
        this.currentLoad.status === 'FINISHED' ||
        this.currentLoad.status === 'IN_PROGRESS' ||
        this.currentLoad.status === 'ASSIGNED'
      ) {
        return 'course';
      }

      if (this.currentLoad.status === 'FINISHED') {
        return 'finished';
      } else {
        return 'quoting';
      }
    },
    handleLegChange(index) {
      setTimeout(() => {
        console.log('legChange: ', this.currentLoad.id);
        this.$store.dispatch('LoadsStore/getDocsList', this.currentLoad.id);
      }, 100);
      this.$store.commit('LoadDetailStore/setCurrentLegIndex', Number(index));
      this.$emit('change', this.handleReturnSelected());
    },
  },
});
