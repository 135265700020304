import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center items plan-gap-sm" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "flex justify-between" }
const _hoisted_5 = { class: "flex flex-col gap-2" }
const _hoisted_6 = { class: "px-2 text-center" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_loading_full_screen = _resolveComponent("plan-carrier-loading-full-screen")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!
  const _component_plan_default_carrier_view = _resolveComponent("plan-default-carrier-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loadingLoad)
      ? (_openBlock(), _createBlock(_component_plan_carrier_loading_full_screen, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.loadingLoad && _ctx.loadData !== null)
      ? (_openBlock(), _createBlock(_component_plan_default_carrier_view, {
          key: 1,
          sidebar: ""
        }, {
          content: _withCtx(() => [
            _createVNode(_component_plan_default_modal, {
              "hide-close-button": "",
              isCarrierView: "",
              size: 'sm-auto',
              title: _ctx.$t('processes-documentation-border-crossing', { identifier: _ctx.loadData.identifier })
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('ccp-document')), 1),
                    (_ctx.loadingDocs === 'BOL')
                      ? (_openBlock(), _createBlock(_component_plan_icon, {
                          key: 0,
                          name: 'spinner',
                          rotate: _ctx.loadingDocs === 'BOL',
                          "icon-color": "#0D0E10",
                          "is-svg": ""
                        }, null, 8, ["rotate"]))
                      : _createCommentVNode("", true),
                    (_ctx.loadingDocs !== 'BOL')
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.download('BOL'))),
                          class: "cursor-pointer"
                        }, _toDisplayString(_ctx.$t('download')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('packing-list')), 1),
                    (_ctx.loadingDocs === 'PACKAGING_LIST')
                      ? (_openBlock(), _createBlock(_component_plan_icon, {
                          key: 0,
                          name: 'spinner',
                          rotate: _ctx.loadingDocs === 'PACKAGING_LIST',
                          "icon-color": "#0D0E10",
                          "is-svg": ""
                        }, null, 8, ["rotate"]))
                      : _createCommentVNode("", true),
                    (_ctx.loadingDocs !== 'PACKAGING_LIST')
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.download('PACKAGING_LIST'))),
                          class: "cursor-pointer"
                        }, _toDisplayString(_ctx.$t('download')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('load-invoice')), 1),
                    (_ctx.loadingDocs === 'INVOICE')
                      ? (_openBlock(), _createBlock(_component_plan_icon, {
                          key: 0,
                          name: 'spinner',
                          rotate: _ctx.loadingDocs === 'INVOICE',
                          "icon-color": "#0D0E10",
                          "is-svg": ""
                        }, null, 8, ["rotate"]))
                      : _createCommentVNode("", true),
                    (_ctx.loadingDocs !== 'INVOICE')
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.download('INVOICE'))),
                          class: "cursor-pointer"
                        }, _toDisplayString(_ctx.$t('download')), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('as-soon-as-you-have-pediment')), 1),
                  _createElementVNode("label", {
                    for: "file-upload",
                    class: _normalizeClass(["plan-button-primary plan-button-md justify-center w-full", { disabled: _ctx.uploadingDoc }])
                  }, [
                    (_ctx.uploadingDoc)
                      ? (_openBlock(), _createBlock(_component_plan_icon, {
                          key: 0,
                          name: 'spinner',
                          rotate: _ctx.uploadingDoc,
                          "is-svg": ""
                        }, null, 8, ["rotate"]))
                      : _createCommentVNode("", true),
                    (!_ctx.uploadingDoc)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('upload-pediment')), 1))
                      : _createCommentVNode("", true)
                  ], 2),
                  _createElementVNode("input", {
                    id: "file-upload",
                    type: "file",
                    class: "hidden",
                    disabled: _ctx.uploadingDoc,
                    onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.changeFiles && _ctx.changeFiles(...args)))
                  }, null, 40, _hoisted_8)
                ])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}