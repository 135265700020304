import actions from '@/services/newFtlLoad/_store/actions';
import getters from '@/services/newFtlLoad/_store/getters';
import mutations from '@/services/newFtlLoad/_store/mutations';

export const initialState = {
  type: 'REGULAR',
  multipleLoads: 1,
  poNumber: '',
  specialRequirements: {
    teamDriver: false,
    bondedPermission: false,
    tsaCard: false,
    twicCard: false,
    tirCable: false,
    hazardousLoad: false,
    specialPermits: false,
    specialPermitsValue: '',
  },
  pickup: null,
  delivery: null,
  trailerData: {
    id: -1,
    min: null,
    max: null,
    trailerId: -1
  },
  commodities: [],
  commodityObservations: '',
  stops: [
    {
      type: 'PICKUP_INITIAL',
      executionDate: '',
      location: {},
      portStop: false,
      airportStop: false,
      observations: '',
      deliveryStop: null,
      lumperFeePrice: 0,
      multiStopIdentifierArray: [],
      lumperFee: false,
      multiStopIdentifier: '',
      executionWindowEndTime: null,
      executionWindowStartTime: null,
      companyName: '',
      address: '',
      phoneNumber: '',
      phonePrefix: '',
      defaultCountrySelected: {},
      poNumber: '',
      contact: '',
      warehouseTemplate: null,
    },
    {
      type: 'DELIVERY_FINAL',
      executionDate: '',
      location: {},
      portStop: false,
      airportStop: false,
      multiStopIdentifier: '',
      observations: '',
      lumperFeePrice: 0,
      multiStopIdentifierArray: [],
      lumperFee: false,
      executionWindowEndTime: null,
      executionWindowStartTime: null,
      companyName: '',
      deliveryStop: null,
      address: '',
      phoneNumber: '',
      phonePrefix: '',
      defaultCountrySelected: {},
      poNumber: '',
      contact: '',
      warehouseTemplate: null,
    },
  ],
  additionalStops: [],
  files: null,
  freightInfo: null,
  customers: [],
  letterOfInstructions: null,
  directAssign: null,
  templateData: null
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
