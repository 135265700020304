
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanSelectSearch from '@/components/forms/PlanSelectSearch.vue';
import SidebarUserButton from '@/components/sidebar/SidebarUserButton.vue';
import { setLocale } from '@vee-validate/i18n';
import { useRouter } from 'vue-router';
import i18n from '@/resources/locales';
import * as Routing from '@/router/Routing';
// Utils
import { timezones } from '@/services/timezones/index';


interface Language {
  type: string;
  value: string;
}

interface sidebaUser {
  availableLanguages: Array<Language>;
  isContentOpen: boolean;
  loadingLogo: boolean;
  logo: string;
  selectedLanguage: string;
  timeZone: string;
}
export default defineComponent({
  name: 'SidebarUser',
  components: { SidebarUserButton, PlanIcon, PlanSelectSearch },
  emits: ['closeSidebarContentMobile'],
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    const collapsed = computed(() => {
      return store.getters['UserStore/getCollapsedSidebar'];
    });

    const data = reactive<sidebaUser>({
      availableLanguages: [
        { type: 'en', value: 'EN' },
        { type: 'es-ES', value: 'ES (España)' },
        { type: 'es-MX', value: 'ES (Mexico)' },
      ],
      isContentOpen: false,
      loadingLogo: false,
      logo: '',
      selectedLanguage: i18n.global.locale.value,
      timeZone: '',
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const isPROD = computed(() => {
      return process.env.VUE_APP_URL.includes('api.planimatik.com');
    });

    const isShipperViewer = computed(() => {
      return store.getters['UserStore/getIsShipperViewer'];
    });

    const isSuperShipper = computed(() => {
      return currentUser.value?.roles?.some((role) => role === 'ROLE_SUPER_SHIPPER');
    });

    const isSuperAdmin = computed(() => {
      return currentUser.value?.roles?.includes('ROLE_SUPER_ADMIN');
    });

    const isCarrierBroker = computed(() => {
      return (
        currentUser.value?.roles?.some((item) => item.includes('CARRIER')) ||
        currentUser.value?.roles?.some((item) => item.includes('BROKER'))
      );
    });

    const timezonesFormated = computed(() => {
      return timezones.map((time) => {
        return {
          label: time.label,
          value: time.tzCode,
        };
      });
    });

    const user = computed(() => {
      return {
        name: currentUser?.value
          ? currentUser.value?.name?.length > 20
            ? currentUser.value?.name?.slice(0, 20) + '..'
            : currentUser.value?.name
          : '',
        email: currentUser?.value
          ? currentUser.value?.email?.length > 27
            ? currentUser.value?.email?.slice(0, 27) + '..'
            : currentUser.value?.email
          : '',
      };
    });

    const changeLanguage = () => {
      i18n.global.locale.value = data.selectedLanguage as 'en' | 'es-ES';
      localStorage.setItem('userLocale', data.selectedLanguage);

      setLocale(data.selectedLanguage);
      localStorage.setItem('userLocale', data.selectedLanguage);
      setLocale(data.selectedLanguage);
      emit('closeSidebarContentMobile');
    };

    const getCompany = async () => {
      if (!currentUser.value.company?.id) {
        return;
      }

      data.loadingLogo = true;

      // TODO Refactor this just added new product presentation

      setTimeout(async () => {
        await store.dispatch('CompanyStore/getCompany', currentUser.value.company?.id);
        if (store.getters['CompanyStore/getCompany'].mediaObjects.length > 0) {
          const result = await store.dispatch('ShipmentStore/thumbnailSmall', {
            id: store.getters['CompanyStore/getCompany'].mediaObjects[0]?.id,
            class: store.getters['CompanyStore/getCompany'].mediaObjects[0]?.entityClass,
          });
          data.logo = URL.createObjectURL(result);
        }
        data.loadingLogo = false;
      }, 3000);
    };

    const goTo = (route: string) => {
      if (route === 'shipperProfile') Routing.openShipperProfile();
      if (route === 'teamView') Routing.openTeam();
      if (route === 'paymentsMethods') Routing.openPaymentMethods();
      if (route === 'payments') Routing.openPayments();
      emit('closeSidebarContentMobile');
    };

    const logout = () => {
      store.dispatch('PusherStore/disconnectPusher');
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('welcomeScreenShown');
      router.push('/signIn');
    };

    const timezoneOnChange = (value) => {
      data.timeZone = value;
      store.dispatch('UserStore/putUser', {
        id: currentUser.value.id,
        user: { ...currentUser.value, timeZone: data.timeZone },
      });
    };

    const toggleEmailConfigModal = () => {
      store.commit('GlobalStore/setEmailConfigModal', true);
    };

    getCompany();

    /** Watchers */
    watch(
      () => currentUser.value?.company?.id,
      (newValue) => {
        getCompany();
      },
    );

    const dtf = new Intl.DateTimeFormat();
    if (currentUser.value.id) {
      data.timeZone = currentUser.value?.timeZone ?? dtf.resolvedOptions().timeZone;
    }

    return {
      ...toRefs(data),
      currentUser,
      collapsed,
      isPROD,
      isShipperViewer,
      isSuperAdmin,
      isSuperShipper,
      isCarrierBroker,
      timezones,
      timezonesFormated,
      user,
      changeLanguage,
      goTo,
      logout,
      timezoneOnChange,
      toggleEmailConfigModal,
    };
  },
});
