<template>
  <div class="flex flex-col plan-gap-sm">
    <div class="flex flex-col text-lg plan-gap-md">
      <p class="text-black font-bold">{{ headerText }}</p>
      <div class="flex flex-row justify-center plan-gap-md">
        <plan-button
          type="warning-outline-black"
          font-size="18"
          class="w-40"
          :label="$t('decline')"
          :loading="loadingButtonDecline"
          @onClick="handleDeclineLoad"
        />
        <plan-button
          type="primary-dark"
          font-size="18"
          class="w-40"
          :label="$t('accept')"
          :loading="loadingButtonAccept"
          @onClick="handleAcceptLoad"
        />
      </div>
    </div>
    <p class="text-black text-sm font-semibold text-center">
      {{ deadlineText }}: <span class="font-bold">{{ deadlineTime }}</span>
    </p>
  </div>
</template>

<script>
import PlanButton from '@/components/buttons/PlanButton.vue';
import { defineComponent, reactive, toRefs, computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'CarrierRFPConfirmation',
  emit: ['fetchConfirmationRFP', 'fetch'],
  props: {
    headerText: {
      type: String,
      required: true,
    },
    deadlineText: {
      type: String,
      required: true,
    },
    deadlineTime: {
      type: String,
      required: true,
    },
    currentStatus: {
      type: Boolean,
      required: true,
    },
    currentAssignConfirmation: {
      type: Object,
      required: true,
    },
  },
  components: {
    PlanButton,
  },
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      loadingButtonDecline: false,
      loadingButtonAccept: false,
    });

    const isStatusInvalid = computed(() => {
      const validStatuses = ['ACCEPTING', 'REFUSED', 'CONFIRM_EXPIRED', 'QUOTED'];
      return validStatuses.includes(props.currentAssignConfirmation?.quote?.quoteRequest?.status);
    });

    const handleDeclineLoad = () => {
      if (isStatusInvalid.value) {
        store.dispatch('RFPStore/postCarrierRefuse', props?.currentAssignConfirmation.quote.id);
      }
      data.loadingButtonDecline = true;

      store
        .dispatch('RFPStore/postAssignDeclineRFP', props?.currentAssignConfirmation.id)
        .then(() => {
          data.loadingButtonDecline = false;
          emit('fetchConfirmationRFP');
        });
    };
    const handleAcceptLoad = () => {
      if (isStatusInvalid.value) {
        store.dispatch('RFPStore/postCarrierConfirm', props?.currentAssignConfirmation.quote.id);
      }
      data.loadingButtonAccept = true;
      store
        .dispatch('RFPStore/postAssignConfirmationRFP', props?.currentAssignConfirmation.id)
        .then(() => {
          data.loadingButtonAccept = false;
          emit('fetch');
        });
    };
    return {
      ...toRefs(data),
      handleDeclineLoad,
      handleAcceptLoad,
    };
  },
});
</script>
