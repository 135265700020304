
import { defineComponent, reactive, toRefs, computed, watch, ref } from 'vue';

import { Form, Field, ErrorMessage } from 'vee-validate';
import { useStore } from 'vuex';
// Base
import BaseComponent from '@/base/BaseComponent';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';

import { useI18n } from 'vue-i18n';
interface formData {
  addDriver: boolean;
  driverSelected: any | null;
  driver: any;
  idToDelete: any;
  showDelete: any;
  addVehicle: boolean;
  trailer: any;
  vehiclesArray: any;
  vehicleSelected: any;
}

export default defineComponent({
  name: 'ContactForm',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  emits: ['update'],
  mixins: [BaseComponent, CountryMixins],

  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const user = computed(() => {
      return store.getters['UserStore/getUser'];
    });

    const drivers = computed(() => {
      return store.getters['CompanyStore/getDriversList'];
    });

    const vehicles = computed(() => {
      return store.getters['CompanyStore/getVehiclesList'];
    });

    const trailerTypes = computed(() => {
      return store.getters['LoadsStore/getTrailerTypes'];
    });

    const currentCompany = computed(() => {
      return store.getters['CompanyStore/getCompany'];
    });

    const data = reactive<formData>({
      driverSelected: null,
      addDriver: false,
      addVehicle: false,
      idToDelete: null,
      vehicleSelected: null,
      showDelete: false,
      trailer: {
        trailerType: { id: null },
        trailerPlate: '',
        licensePlate: null as any,
      },
      vehiclesArray: [],
      driver: {
        name: null,
        surname: null,
        drivingLicense: null,
      },
    });

    const createDriver = () => {
      store
        .dispatch('CompanyStore/postDriver', {
          ...data.driver,
          company: { id: currentCompany.value.id },
        })
        .then(() => {
          data.driver = { name: null, surname: null, drivingLicense: null };
          BaseComponent.methods?.showToastSuccess(translate.t('created-successfully'));
          data.addDriver = false;
          store.dispatch('CompanyStore/driversList', currentCompany.value.id);
        });
    };

    const createVehicle = () => {
      store
        .dispatch('CompanyStore/postVehicle', {
          ...data.trailer,
          company: { id: user.value.company.id },
        })
        .then(() => {
          data.trailer = { trailerType: { id: null }, trailerPlate: '', licensePlate: null as any };
          BaseComponent.methods?.showToastSuccess(translate.t('created-successfully'));
          data.addVehicle = false;
          store.dispatch('CompanyStore/vehiclesList', user.value.company.id);
          data.vehiclesArray = vehicles.value;
        });
    };

    const openModalDelete = (id) => {
      data.idToDelete = id;
      data.showDelete = true;
    };

    const editDriver = (driver) => {
      store
        .dispatch('CompanyStore/putDriver', {
          id: driver.id,
          params: {
            name: driver.name,
            surname: driver.surname,
            drivingLicense: driver.drivingLicense,
          },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(translate.t('updated-correctly'));
          data.addDriver = false;
          store.dispatch('CompanyStore/driversList', currentCompany.value.id);
        });
    };

    const deleteVehicle = () => {
      store
        .dispatch('CompanyStore/deleteVehicles', {
          id: data.vehicleSelected,
        })

        .then(() => {
          store.dispatch('CompanyStore/vehiclesList', user.value.company.id);
          BaseComponent.methods?.showToastSuccess(translate.t('message-updated-successfully'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    };

    const updateData = () => {
      const vehicle = vehicles.value.find((v) => v.id === data.vehicleSelected);
      const newTrailer = trailerTypes.value.find((v) => v.id === vehicle.trailerType.id);

      store
        .dispatch('CompanyStore/putVehicle', {
          id: data.vehicleSelected,
          trailerType: newTrailer,
          trailerPlate: vehicle.trailerPlate,
          licensePlate: vehicle.licensePlate,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(translate.t('message-updated-successfully'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    };

    /** Watchers */
    watch(
      () => data.addDriver,
      (newValue) => {
        if (newValue) {
          data.driver = {
            name: null,
              surname: null,
              drivingLicense: null,
          };
        }
      }
    );

    watch(
      () => data.addVehicle,
      (newValue) => {
        if (newValue) {
          data.trailer = {
            trailerType: { id: null },
            trailerPlate: '',
              licensePlate: null as any,
          };
        }
      }
    );

    return {
      ...toRefs(data),
      user,
      drivers,
      vehicles,
      trailerTypes,
      currentCompany,
      deleteVehicle,
      openModalDelete,
      createDriver,
      createVehicle,
      editDriver,
      updateData,
    };
  },
});
