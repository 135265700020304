import DriverTrackingAuth from '@/modules/DriverTracking/_views/DriverTrackingAuth.vue';
import DriverTrackingNotifyPosition from '@/modules/DriverTracking/_views/DriverTrackingNotifyPosition.vue';
import DriverRouteStopUpdate from '@/modules/DriverTracking/_views/DriverRouteStopUpdate.vue';

const DriverTracking = [
  {
    path: '/driver/tracking/:id/:hash/:code',
    name: 'DriverTracking',
    component: DriverTrackingAuth,
    props: true,
  },
  {
    path: '/driver/tracking/:id/notify-position',
    name: 'DriverTrackingNotifyPosition',
    component: DriverTrackingNotifyPosition,
    props: true,
  },
  {
    path: '/driver/tracking/:id/stop-update',
    name: 'DriverRouteStopUpdate',
    component: DriverRouteStopUpdate,
    props: true,
  },
];

export default DriverTracking;
