import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-lg plan-rounded-xs plan-p-md plan-gap-md bg-white" }
const _hoisted_2 = { class: "w-full flex flex-col plan-gap-md" }
const _hoisted_3 = { class: "flex justify-start items-center plan-gap-md" }
const _hoisted_4 = { class: "font-bold plan-t-h2 plan-t-prim-100" }
const _hoisted_5 = { class: "font-bold plan-t-h2" }
const _hoisted_6 = { class: "font-bold plan-t-h2 plan-t-prim-100" }
const _hoisted_7 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_8 = { class: "flex flex-col plan-gap-sm" }
const _hoisted_9 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_10 = { class: "font-bold" }
const _hoisted_11 = { class: "plan-t-prim-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_load_form_select_commodity = _resolveComponent("plan-load-form-select-commodity")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeView('shipment'))),
          class: "flex justify-center items-center plan-gap-sm cursor-pointer"
        }, [
          _createElementVNode("p", _hoisted_4, "1. " + _toDisplayString(_ctx.$t('shipment-data')), 1),
          _createVNode(_component_plan_icon, {
            name: "fill-circle-check",
            "icon-color": "#8A8B97",
            "is-svg": ""
          })
        ]),
        _createElementVNode("p", _hoisted_5, "2. " + _toDisplayString(_ctx.$t('freight-info')), 1),
        _createElementVNode("p", _hoisted_6, "3. " + _toDisplayString(_ctx.$t('quote')), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_plan_icon, {
          name: _ctx.totals.isComplete ? 'fill-circle-check':'fill-circle-close',
          "icon-color": _ctx.totals.isComplete ? '#00DB50':'#EF4444',
          "is-svg": ""
        }, null, 8, ["name", "icon-color"]),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.totals.isComplete ? 'plan-t-green-500':'plan-t-red-500')
        }, _toDisplayString(_ctx.$t('freight-info-message')), 3)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loadData.loads, (stop, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: stop,
            class: "rounded w-full border plan-border-prim-50 plan-p-md flex flex-col plan-gap-sm"
          }, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("p", _hoisted_10, _toDisplayString(`${_ctx.$t(stop.type.toLowerCase())} - #${index + 1} ${stop.location}`), 1),
              _createElementVNode("p", _hoisted_11, _toDisplayString(stop.date), 1)
            ]),
            _createVNode(_component_plan_load_form_select_commodity, {
              onChange: _ctx.updateStopCommodities,
              index: index,
              deliveries: _ctx.deliveries,
              possibleCommodities: _ctx.commodities[index],
              possibleDeliveries: _ctx.possibleDeliveries
            }, null, 8, ["onChange", "index", "deliveries", "possibleCommodities", "possibleDeliveries"])
          ]))
        }), 128))
      ])
    ])
  ]))
}