import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }
const _hoisted_2 = { class: "flex justify-center items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_warehouse_form = _resolveComponent("warehouse-form")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading && !_ctx.showConfirmation)
      ? (_openBlock(), _createBlock(_component_plan_default_modal, {
          key: 0,
          onClose: _ctx.close,
          title: _ctx.$t('add-warehouse'),
          size: "sm-auto",
          width: "467px",
          "without-sidebar": "",
          "hide-close-button": ""
        }, {
          content: _withCtx(() => [
            _createVNode(_component_warehouse_form, {
              "default-zip": _ctx.defaultZip,
              "default-country": _ctx.defaultCountry,
              "default-prefix": _ctx.defaultPrefix,
              "default-zipcode-id": _ctx.defaultZipcodeId,
              "has-default-location": _ctx.defaultZip.length > 0 && _ctx.defaultCountry.length > 0,
              "only-country": _ctx.onlyCountry,
              "exclude-country": _ctx.excludeCountry,
              onUpdate: _ctx.updateData
            }, null, 8, ["default-zip", "default-country", "default-prefix", "default-zipcode-id", "has-default-location", "only-country", "exclude-country", "onUpdate"])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.close,
                disabled: _ctx.loading,
                type: "primary-transparent",
                label: _ctx.$t('cancel')
              }, null, 8, ["onOnClick", "disabled", "label"]),
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.postWarehouse,
                disabled: !_ctx.isFormComplete || _ctx.loading,
                type: "primary",
                label: _ctx.$t('save')
              }, null, 8, ["onOnClick", "disabled", "label"])
            ])
          ]),
          _: 1
        }, 8, ["onClose", "title"]))
      : _createCommentVNode("", true)
  ]))
}