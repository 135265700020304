
import { defineComponent, computed, reactive, toRefs } from 'vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import { useStore } from 'vuex';
import PlanFormattedNumber from '@/components/labels/PlanFormattedNumber.vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment/moment';

interface planCarrierLoadDetails {
  documentsForDownload: Array<any>;
}

export default defineComponent({
  name: 'PlanCarrierSimpleLoadDetails',
  components: { PlanButtonIcon, PlanAvatar, PlanFormattedNumber, PlanIcon },
  setup() {
    const store = useStore();
    const translate = useI18n();

    const data = reactive<planCarrierLoadDetails>({
      documentsForDownload: [],
    });

    const additionalDocuments = computed(() => {
      let result: Array<any> = [];
      if (store.getters['CarrierStore/getLoadData'].mediaObjects) {
        store.getters['CarrierStore/getLoadData'].mediaObjects.forEach((mediaObject: any) => {
          if (mediaObject.type === 'LOAD' && mediaObject.entityClass === 'LOAD')
            result.push(mediaObject);
        });
      }
      return result;
    });

    const downloadDocument = async (mediaObject: any) => {
      data.documentsForDownload.push(mediaObject.id);

      await store
        .dispatch('ShipmentStore/getDownloadFileBlob', {
          id: mediaObject.id,
          class: mediaObject.entityClass,
        })
        .then((response) => {
          let file = document.createElement('a');
          file.href = URL.createObjectURL(response);
          file.download = `${mediaObject.name}`;
          file.click();
        })
        .finally(() => {
          data.documentsForDownload.splice(data.documentsForDownload.indexOf(mediaObject.id), 1);
        });

      // let file = document.createElement('a');
      // file.href = URL.createObjectURL(response);
      // file.download = `${mediaObject.name}`;
      // file.click();
    };

    const loadDetailElementWidth = computed(() => {
      const actualWidth = store.getters['CarrierStore/getActualWidth'];
      if (actualWidth <= 640) {
        return 'width: 240px;';
      } else if (actualWidth > 640 && actualWidth <= 1536) {
        return 'width: 240px;';
      } else {
        return 'width: 280px;';
      }
    });

    const loadDetails = computed(() => {
      const formatDate =
        store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'MMM DD, YYYY' : 'DD MMM, YYYY';
      const pickup = store.getters['CarrierStore/getLoadData'].stops[0];
      const delivery =
        store.getters['CarrierStore/getLoadData'].stops[
          store.getters['CarrierStore/getLoadData'].stops.length - 1
        ];
      const pickupLocation = `${pickup.location?.city}, ${pickup.location?.state?.iso} ${pickup.location?.state?.country?.iso}`;
      const deliveryLocation = `${pickup.location?.city}, ${pickup.location?.state?.iso} ${pickup.location?.state?.country?.iso}`;
      const pickupDate = moment(
        store.getters['CarrierStore/getLoadData'].stops[0].executionDate,
      ).format(formatDate);
      const deliveryDate = moment(
        store.getters['CarrierStore/getLoadData'].stops[
          store.getters['CarrierStore/getLoadData'].stops.length - 1
        ].executionDate,
      ).format(formatDate);
      let commodities = {
        label: store.getters['CarrierStore/getLoadData'].stops[0].commodities[0].commodityName,
        value: store.getters['CarrierStore/getLoadData'].stops[0].commodities[0].weight,
      };

      return {
        identifier: store.getters['CarrierStore/getLoadData'].identifier,
        poNumber:
          store.getters['CarrierStore/getLoadData']?.poNumber?.length > 0
            ? store.getters['CarrierStore/getLoadData']?.poNumber
            : '-',
        distance: store.getters['CarrierStore/getLoadData'].totalDistance,
        trailer: store.getters['CarrierStore/getLoadData'].trailerType.description,
        minTemperature: store.getters['CarrierStore/getLoadData'].minTemp,
        maxTemperature: store.getters['CarrierStore/getLoadData'].maxTemp,
        specialPermissions: store.getters['CarrierStore/getLoadData'].specialRequirement
          .specialPermissions
          ? store.getters['CarrierStore/getLoadData'].specialRequirement
              .specialPermissionsDescription
          : '',
        pickupDate: pickupDate,
        pickupLocation: pickupLocation,
        deliveryDate: deliveryDate,
        deliveryLocation: deliveryLocation,
        commodities: commodities,
      };
    });

    const openChat = () => {
      store.commit('LoadsStore/loadId', store.getters['CarrierStore/getLoadData'].id);
      store.commit('ChatsStore/setChatId', 9317);
      store.commit('GlobalStore/setOpenChat', true);
    };

    const shipperOwner = computed(() => {
      return {
        user: store.getters['CarrierStore/getLoadData'].shipperOwner.name,
        company: store.getters['CarrierStore/getLoadData'].shipperOwner.surname,
      };
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const specialRequirements = computed(() => {
      let result: Array<any> = [];
      const specialRequirementElements =
        store.getters['CarrierStore/getLoadData'].specialRequirement;
      if (specialRequirementElements.TWICCard) result.push(translate.t('twic-card'));
      if (specialRequirementElements.bondedPermission)
        result.push(translate.t('bonded-permission'));
      if (specialRequirementElements.hazardousLoad) result.push(translate.t('hazardous-load'));
      if (specialRequirementElements.teamDriver) result.push(translate.t('team-driver'));
      if (specialRequirementElements.tir) result.push(translate.t('tir'));
      if (specialRequirementElements.tsa) result.push(translate.t('tsa'));
      if (specialRequirementElements.oversize) result.push(translate.t('oversize'));
      if (specialRequirementElements.overweight) result.push(translate.t('overweight'));

      if (result.length > 1) {
        return result.slice(0, -1).join(', ') + ` ${translate.t('and')} ` + result.slice(-1);
      } else {
        return result.join('');
      }
    });

    return {
      ...toRefs(data),
      additionalDocuments,
      downloadDocument,
      loadDetailElementWidth,
      loadDetails,
      loadData,
      openChat,
      shipperOwner,
      specialRequirements,
    };
  },
});
