<template>
  <div
    class="relative bg-white xl:ml-90 flex min-h-screen flex-col justify-center overflow-y-auto py-6 sm:py-12"
  >
    <AddCardModalVue
      v-if="showCardModal"
      :planSelected="planSelected"
      @close="showCardModal = !showCardModal"
    />
    <!-- GO BACK BUTTON -->
    <div
      v-if="!isResume"
      class="w-full px-10 bg-white flex-col justify-center items-start gap-8 inline-flex"
    >
      <div
        @click="this.$router.go(-1)"
        class="justify-start items-center gap-1 cursor-pointer inline-flex"
      >
        <div class="w-6 h-6 justify-center items-center gap-3.5 flex">
          <img
            alt="Refused"
            class="h-8 w-8"
            :src="getAsset.icBackButton"
          />
        </div>
        <div class="text-slate-900 text-base font-semibold font-['Nunito']">
          {{ $t('back') }}
        </div>
      </div>
    </div>
    <div class="flex w-full justify-center">
      <div class="w-full flex-col justify-start items-center gap-2 inline-flex">
        <div
          v-if="!isResume"
          class="text-center text-gray-600 text-sm font-normal font-['Nunito']"
        >
          {{ $t('plans') }}
        </div>
        <div class="self-stretch text-center text-zinc-800 text-4xl font-bold font-['Nunito']">
          {{ $t('plans-title') }}
        </div>
        <div
          v-if="!isResume"
          class="text-center text-gray-600 text-2xl font-normal font-['Nunito']"
        >
          {{ $t('plans-info') }}
        </div>
      </div>
    </div>
    <!-- PLANS -->
    <div class="flex w-full h-dvh justify-center mt-20">
      <div class="w-full h-dvh flex-col justify-center items-center gap-2.5 flex">
        <div class="w-full lg:flex max-w-full px-8 py-0 h-dvh gap-3">
          <div
            class="w-full flex flex-wrap justify-center items-center"
            style="column-gap: 25px"
          >
            <!-- BASIC -->
            <CardComponentPlan
              :showSpinner="showSpinner && type === 'BASIC'"
              v-if="false"
              :title="$t('plan-basic')"
              :unitAmount="basicPlan?.unitAmount"
              type="BASIC"
              :subscribeBasicPlan="subscribeBasicPlan"
            />
            <!-- BASIC PLUS -->
            <CardComponentPlan
              :showSpinner="showSpinner && type === 'BASIC_PLUS'"
              :title="$t('plan-basic-plus')"
              :unitAmount="basicPlanPlus?.unitAmount"
              type="BASIC_PLUS"
              :subscribeBasicPlan="subscribeBasicPlan"
            />
            <!-- PREMIUM -->
            <CardComponentPlan
              :showSpinner="showSpinner && type === 'PREMIUM'"
              :title="$t('plan-premium')"
              :unitAmount="premiumPlan?.unitAmount"
              type="PREMIUM"
              :subscribeBasicPlan="subscribePremiumPlan"
            />
            <!-- ENTERPRISE -->
            <CardComponentPlan
              :title="$t('plan-enterprise')"
              type="ENTERPRISE"
              :subscribeBasicPlan="null"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import AddCardModalVue from '@/components/modals/AddCardModal.vue';
import CardComponentPlan from '@/components/card/CardComponentPlan.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins';
export default {
  components: { AddCardModalVue, CardComponentPlan },
  mixins: [BaseComponent, CountryMixins],
  props: {
    isResume: Boolean,
  },
  data() {
    return {
      basicPlan: {},
      basicPlanPlus: {},
      loading: false,
      lineItems: [
        {
          price: '',
          quantity: 1,
        },
      ],
      planSelected: '',
      premiumPlan: {},
      publishableKey: process.env.VUE_APP_STRIPE_KEY,
      type: '',
      showStripe: false,
      showSpinner: false,
      showCardModal: false,
      sessionId: '',
    };
  },
  async beforeCreate() {
    let code = this.$route.params.code;
    let hash = this.$route.params.hash;

    localStorage.setItem('hash', hash);

    if (hash && code) {
      await this.$store
        .dispatch('UserStore/hashValidation', {
          hashId: localStorage.hash,
          secureCode: code,
        })
        .then(async () => {
          this.$store.dispatch('PusherStore/initializePusher');
          await this.fetchAllInfo();
          this.$router.push({ name: 'plans' });
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.title);
          this.$store.commit('UserStore/setShowHash', true);
        });
    }
  },

  mounted() {
    this.fetchAllInfo();
  },

  computed: {
    isPROD() {
      return process.env.VUE_APP_URL.includes('api.planimatik.com');
    },
    currentUser() {
      return this.$store.getters['UserStore/getCurrentUser'];
    },
    paymentMethod() {
      return this.$store.getters['PaymentStore/getPaymentMethod'];
    },
  },
  watch: {
    currentUser: {
      handler(newValue) {
        if (newValue.company?.accessPlanType === 'SUBSCRIPTION_NOT_PAID') {
          this.$store.commit('DemoStore/setIsWelcomeOpen', true);
        }
      },
      deep: true,
    },
  },
  methods: {
    fetchAllInfo() {
      this.$store.dispatch('PaymentStore/subcriptionPrice').then((response) => {
        this.basicPlan = response.find((plan) => plan.planType === 'BASIC');
        this.basicPlanPlus = response.find((plan) => plan.planType === 'BASIC_PLUS');
        this.premiumPlan = response.find((plan) => plan.planType === 'PREMIUM');
      });
    },
    async resetToken() {
      const refreshToken = localStorage.refresh_token;
      const refresh = await this.$store.dispatch('AuthStore/refreshToken', refreshToken);
    },
    getConfigStripeURL() {
      this.$store.dispatch('PaymentStore/portalSession').then((response) => {
        window.location.href = response.url;
      });
    },
    getSession(product) {
      this.$store.dispatch('PaymentStore/session', { price: product }).then((response) => {
        window.location.href = response.url;
      });
    },
    async subscribeBasicPlan(typePlan) {
      if (this.paymentMethod.length > 0) {
        this.showSpinner = true;
        this.type = typePlan;

        const subscriptionType =
          this.currentUser?.company?.accessPlanType === 'TRIAL' ||
          this.currentUser?.company?.accessPlanType === 'TRIAL_EXPIRED' ||
          this.currentUser?.company?.accessPlanType === 'CANCELLATION_ENDED' ||
          this.currentUser?.company?.accessPlanType === 'SUBSCRIPTION_NOT_PAID' ||
          this.currentUser?.company?.accessPlanType === 'SUBSCRIPTION_TERMINATED'
            ? 'PaymentStore/subscription'
            : 'PaymentStore/upgradeSubscription';
        try {
          await this.$store.dispatch(subscriptionType, { type: typePlan });
          BaseComponent.methods?.showToastSuccess(
            this.$t('subscription-done', { plan: this.$t('basic') }),
          );
          this.showSpinner = false;
          setTimeout(() => {
            this.resetToken();
          }, 1000);
        } catch (error) {
          BaseComponent.methods?.showToastError(error?.response?.data?.detail);
        } finally {
          this.showSpinner = false;
        }
      } else {
        this.planSelected = 'BASIC';
        this.showCardModal = true;
      }
    },

    async subscribePremiumPlan(typePlan) {
      // IF THE USER DONT HAVE PAYMENT METHOD JUST DO THE POST ELSE OPEN MODAL OF PLANS

      this.showSpinner = true;
      this.type = typePlan;

      if (this.paymentMethod.length > 0) {
        // IF IS BASIC IS AN UPGRADE
        const subscriptionType =
          this.currentUser?.company?.accessPlanType === 'TRIAL' ||
          this.currentUser?.company?.accessPlanType === 'TRIAL_EXPIRED' ||
          this.currentUser?.company?.accessPlanType === 'CANCELLATION_ENDED' ||
          this.currentUser?.company?.accessPlanType === 'SUBSCRIPTION_NOT_PAID' ||
          this.currentUser?.company?.accessPlanType === 'SUBSCRIPTION_TERMINATED'
            ? 'PaymentStore/subscription'
            : 'PaymentStore/upgradeSubscription';
        this.showPremiumSpinner = true;
        try {
          await this.$store.dispatch(subscriptionType, { type: 'PREMIUM' });
          BaseComponent.methods?.showToastSuccess(
            this.$t('subscription-done', { plan: this.$t('premium') }),
          );
          this.showSpinner = false;
          setTimeout(() => {
            this.resetToken();
          }, 1000);
        } catch (error) {
          BaseComponent.methods?.showToastError(error?.response?.data?.detail);
        } finally {
          this.showSpinner = false;
        }
      } else {
        this.planSelected = 'PREMIUM';
        this.showCardModal = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
