import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1803d9bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 transition-opacity",
  style: {"z-index":"60"}
}
const _hoisted_2 = { class: "bg-bg rounded-lg w-full max-w-md md:h-auto mx-auto my-auto md:mt-10 overflow-hidden" }
const _hoisted_3 = { class: "w-full h-60 pt-6 bg-white rounded-lg flex-col justify-start items-start inline-flex" }
const _hoisted_4 = { class: "self-stretch h-52 px-6 pt-2 pb-8 flex-col justify-start items-start gap-4 flex" }
const _hoisted_5 = { class: "self-stretch text-center text-slate-900 text-2xl font-semibold font-['Nunito']" }
const _hoisted_6 = { class: "self-stretch text-center text-slate-900 text-base font-semibold font-['Nunito']" }
const _hoisted_7 = { class: "self-stretch pt-6 justify-start items-center gap-4 inline-flex" }
const _hoisted_8 = { class: "text-red-600 text-base font-semibold font-['Nunito']" }
const _hoisted_9 = { class: "text-white text-base font-semibold font-['Nunito']" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('modal-direct-assign.title')), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('modal-direct-assign.detail', { docName: _ctx.isEuropeCompany() ? _ctx.$t('bol-eu') : _ctx.$t('bol-usa') })), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
              class: "grow shrink basis-0 h-10 p-2.5 rounded-lg border border-red-600 justify-center items-center gap-2.5 flex cursor-pointer"
            }, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('modal-direct-assign.cancel')), 1)
            ]),
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('accept'), _ctx.$emit('close'))),
              class: "grow shrink basis-0 h-10 p-2.5 bg-red-600 rounded-lg justify-center items-center gap-2.5 flex cursor-pointer"
            }, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('modal-direct-assign.accept')), 1)
            ])
          ])
        ])
      ])
    ])
  ]))
}