import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center items-center gap-1 rounded-xl border-gray-400 border p-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanIcon = _resolveComponent("PlanIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["w-4 h-4 rounded-full border", _ctx.light === 'red' ? _ctx.getLightStyle() : 'border-gray-400'])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["w-4 h-4 rounded-full border", _ctx.light === 'yellow' ? _ctx.getLightStyle() : 'border-gray-400'])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["w-4 h-4 rounded-full border", _ctx.light === 'green' ? _ctx.getLightStyle() : 'border-gray-400'])
      }, null, 2)
    ]),
    (_ctx.withText)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(["flex text-center font-bold rounded-full py-1 px-2 mt-2 w-[200px]", _ctx.getTextStyle()])
        }, [
          (_ctx.light === 'green')
            ? (_openBlock(), _createBlock(_component_PlanIcon, {
                key: 0,
                name: "check",
                size: "32",
                "is-svg": "",
                "icon-color": "#00A63D"
              }))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.getText())), 1)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}