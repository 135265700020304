import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col" }
const _hoisted_2 = { class: "w-full flex justify-start items-end plan-gap-sm" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "plan-form-label" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = {
  class: "flex justify-center items-end",
  style: {"gap":"0"}
}
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "plan-t-xs" }
const _hoisted_9 = {
  class: "flex justify-center items-center",
  style: {"height":"80px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_input_number_formatted = _resolveComponent("plan-input-number-formatted")!
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex justify-start items-end plan-gap-sm", _ctx.actualWidth < 750 ? 'flex-wrap':''])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_plan_input_text, {
        "model-value": _ctx.commodityDescription,
        "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.commodityDescription) = $event)),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showValidation.description = true)),
        onChange: _ctx.update,
        label: _ctx.$t('commodity'),
        placeholder: _ctx.$t('enter-your-commodity'),
        mandatory: ""
      }, null, 8, ["model-value", "onChange", "label", "placeholder"]),
      _createElementVNode("p", {
        class: _normalizeClass(["plan-t-xxs text-right truncate select-none", _ctx.showValidation.description && _ctx.commodityDescription.length === 0 ? 'plan-t-red-500':'plan-t-white'])
      }, _toDisplayString(_ctx.$t('required-field')), 3)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('weight')) + " *", 1),
        _createVNode(_component_plan_input_number_formatted, {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showValidation.weight = true)),
          "model-value": _ctx.weight,
          "onUpdate:model-value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.weight) = $event)),
          placeholder: "0",
          unit: "weight",
          "only-country": _ctx.onlyCountry
        }, null, 8, ["model-value", "only-country"]),
        _createElementVNode("p", {
          class: _normalizeClass(["plan-t-xxs text-right truncate select-none", _ctx.showValidation.weight && (_ctx.weight === null || _ctx.weight === 0 || _ctx.weight === '') ? 'plan-t-red-500':'plan-t-white'])
        }, _toDisplayString(_ctx.$t('required-field')), 3)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('units')) + " *", 1),
            _withDirectives(_createElementVNode("input", {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showValidation.units = true)),
              onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.units) = $event)),
              type: "number",
              placeholder: "0",
              class: "plan-form",
              style: {"max-width":"50px","min-width":"50px","border-top-right-radius":"0","border-bottom-right-radius":"0"}
            }, null, 544), [
              [_vModelText, _ctx.units]
            ])
          ]),
          _createVNode(_component_plan_select, {
            label: _ctx.$t('type'),
            onChange: _ctx.changePackageType,
            value: _ctx.type,
            items: _ctx.types,
            placeholder: "Pallets",
            style: {"min-width":"100px"},
            "hide-left": "",
            mandatory: ""
          }, null, 8, ["label", "onChange", "value", "items"])
        ]),
        _createElementVNode("p", {
          class: _normalizeClass(["plan-t-xxs text-right truncate select-none", _ctx.showValidation.units && (_ctx.units === null || _ctx.units === 0 || _ctx.units === '' ) ? 'plan-t-red-500':'plan-t-white'])
        }, _toDisplayString(_ctx.$t('required-field')), 3)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_plan_button_icon, {
          onOnClick: _ctx.remove,
          disabled: !_ctx.commodity.removable,
          icon: "trash",
          type: "transparent-gray-800"
        }, null, 8, ["onOnClick", "disabled"])
      ])
    ])
  ], 2))
}