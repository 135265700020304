
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  watch,
  toRefs,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
// Base
import BaseComponent from '@/base/BaseComponent';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';

export default defineComponent({
  name: 'PlanCommodities',
  components: {
    PlanInputText,
    PlanInputNumber,
    PlanSelectSimple,
  },
  mixins: [BaseComponent],
  emits: ['changeDataForm'],
  props: {
    setModify: { type: Boolean, default: false },
    load: { type: Object, default: null },
  },
  setup(props, { emit }) {
    /** Global */
    const store = useStore();
    const { t, locale } = useI18n();
    const load = ref(props.load);
    const setModify = ref(props.setModify);

    interface multiCommodityItem {
      dataCommodities: Array<any>;
      showPackageType: boolean;
    }

    const data = reactive<multiCommodityItem>({
      dataCommodities: [
        {
          id: null,
          nPallets: null,
          packageType: { id: 1 },
          weight: null,
          commodityName: '',
        },
      ],
      showPackageType: true,
    });

    /** Lifecycle */
    onBeforeMount(() => {
      store.dispatch('LoadsStore/packageTypes').then(() => {
        if (!props.load?.commodityMasters) {
          data.dataCommodities[0] = {
            ...data.dataCommodities[0],
            packageType: { id: dataCommodityUnitTypes.value[0].id },
          };
        }
        if (props.setModify && props.load?.commodityMasters && dataCommodityUnitTypes.value) {
          data.dataCommodities = props.load?.commodityMasters;
          data.dataCommodities.map((commodity, index) => {
            if (!commodity.packageType) {
              data.dataCommodities[index] = {
                ...commodity,
                packageType: {
                  id: dataCommodityUnitTypes.value[0].id,
                },
              };
            }
          });
        }
      });
    });

    /** Computed */
    const dataCommodityUnitTypes = computed(() => {
      return store.getters['LoadsStore/packageTypes'] &&
        store.getters['LoadsStore/packageTypes'].FTL
        ? store.getters['LoadsStore/packageTypes'].FTL.map((type) => {
            return {
              id: type.id,
              label: t(`types.${type.fullName}`),
              sequence: type.sequence,
              value: type.id.toString(),
            };
          })
        : '';
    });

    /** Methods */
    const setDataCommodities = () => {
      props?.load?.commodityMasters ? (data.dataCommodities = props.load.commodityMasters) : '';
      data.dataCommodities.forEach((commodity: any, index) => {
        if (!commodity.packageType?.id) {
          data.dataCommodities[index] = Object.assign(
            { packageType: { id: dataCommodityUnitTypes.value[0].id } },
            data.dataCommodities[index],
          );
        }
      });
    };

    const onChangeDataForm = () => {
      emit('changeDataForm', data.dataCommodities);
    };

    const onClickAddMoreFreight = () => {
      data.dataCommodities.push({
        id: null,
        nPallets: null,
        packageType: { id: dataCommodityUnitTypes?.value[0]?.id || 1 },
        weight: null,
        commodityName: '',
      });
    };

    const onClickDeleteCommodity = (index) => {
      data.dataCommodities.splice(index, 1);
      onChangeDataForm();
    };

    if (setModify.value || props.setModify) {
      setDataCommodities();
    }

    /** Watchers */
    watch(
      () => props.setModify,
      (newValue) => {
        if (newValue) {
          setDataCommodities();
        }
      },
    );

    watch(
      () => props.load,
      (newValue) => {
        if (props.setModify) {
          setDataCommodities();
        }
      },
    );

    return {
      ...toRefs(data),
      dataCommodityUnitTypes,
      setDataCommodities,
      onChangeDataForm,
      onClickAddMoreFreight,
      onClickDeleteCommodity,
    };
  },
});
