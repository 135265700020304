
import PlanDefaultView from '@/components/defaults/PlanDefaultView.vue';
import { defineComponent, reactive, toRefs, computed, ref } from 'vue';
import { ActionContext, useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import PlansNewLoadHeader from './_components/PlansNewLoadHeader.vue';
import PlanStepsTab from './_components/PlanStepsTab.vue';
import CrossBorderData from './steps/CrossBorderData.vue';
import PlanQuoteStep from '@/modules/planning/newLoad/steps/PlanQuoteStep.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useRouter } from 'vue-router';

interface newLoad {
  deleteItem: any;
  currentView: string;
  isValid: boolean;
  creatingLaod: boolean;
  defaultBillObject: object;
}

export default defineComponent({
  name: 'NewLoadCrossBorder',
  components: {
    PlanDefaultView,
    PlansNewLoadHeader,
    CrossBorderData,
    PlanStepsTab,
    PlanQuoteStep,
    PlanButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();

    const data = reactive<newLoad>({
      deleteItem: null,
      currentView: 'shipment',
      isValid: false,
      creatingLaod: false,
      defaultBillObject: {
        name: '',
        email: '',
        taxId: '',
        country: '',
        state: '',
        city: '',
        address: '',
        zip: '',
      },
    });

    const getLoadToSend = computed(() => {
      return store.getters['CrossBorderStore/getLoadToSend'];
    });

    const tabs = computed(() => {
      let tabs = [
        { label: t('shipment-data'), value: 'shipment', isCheck: data.isValid },
        { label: t('quote'), value: 'quote' },
      ];
      return tabs;
    });

    const selectedContactsLegs = computed(() => {
      return store.getters['CrossBorderStore/getSelectedContactsLegs'];
    });

    const additionalDocuments = computed(() => {
      return store.getters['LoadsStore/getLoadAditionalDocuments'];
    });

    const dataCustomersSelected = computed(() => {
      return store.getters['CustomerStore/getCustomersSelectedData'];
    });

    const changeView = (view) => {
      data.currentView = view;
    };

    const handleChangeView = (view) => {
      if (view === 'quote') {
        validate();
      } else {
        changeView(view);
      }
    };

    const handleSubmit = () => {
      if (data.currentView === 'shipment') {
        validate();
      } else {
        validateQuote();
      }
    };

    const validate = async () => {
      data.isValid = false;
      // Update second leg pickup initial location
      store.commit('CrossBorderStore/updateLegStopFromLoad', {
        key: 'location',
        value: getLoadToSend.value.legs[0].stops[1].location,
        legIndex: 1,
        stopIndex: 0,
      });
      // Update third leg pickup initial location
      store.commit('CrossBorderStore/updateLegStopFromLoad', {
        key: 'location',
        value: getLoadToSend.value.legs[1].stops[1].location,
        legIndex: 2,
        stopIndex: 0,
      });
      data.isValid = await store.dispatch('CrossBorderStore/validateLoad');
      if (data.isValid) {
        changeView('quote');
      }
    };

    const validateQuote = async () => {
      let isInvalid = false;
      if (selectedContactsLegs.value[0].length === 0) {
        isInvalid = true;
      }
      selectedContactsLegs.value.forEach((leg) => {
        if (leg.length === 0) {
          isInvalid = true;
        }
      });
      if (isInvalid) {
        store.dispatch('UserStore/updateToast', {
          text: t('required-one-carrier-leg'),
          type: 'error',
          visible: true,
        });
      } else {
        createLoadAndBatch();
      }
    };

    const checkBillingLegArrayEmpty = (billingAddress) => {
      for (const key in billingAddress) {
        if (billingAddress[key] !== '') {
          return false;
        }
      }
      return true;
    };

    const copyStopData = (sourceStop, targetStop) => {
      targetStop.companyName = sourceStop.companyName;
      targetStop.location.address = sourceStop.location.address;
      targetStop.lumperFee = sourceStop.lumperFee;
      targetStop.lumperFeePrice = sourceStop.lumperFeePrice;
      targetStop.observations = sourceStop.observations;
      targetStop.poNumber = sourceStop.poNumber;
      targetStop.phoneNumber = sourceStop.phoneNumber;
      targetStop.phonePrefix = sourceStop.phonePrefix;
      targetStop.contact = sourceStop.contact;
      targetStop.executionWindowStartTime = sourceStop.executionWindowStartTime;
      targetStop.executionWindowEndTime = sourceStop.executionWindowEndTime;
    };

    const billingAddress = computed(() => {
      return store.state.UserStore.crossBorderBilling;
    });

    const isBillingCompanyEmpty = computed(() => {
      return billingAddress.value.every((address) => {
        return Object.values(address).every((value) => value === '');
      });
    });

    const createLoadAndBatch = () => {
      data.creatingLaod = true;
      formatLoadToSend();

      // HANDLE SETUP LEGS FOR COMPLETED WAREHOUSES
      const legs = getLoadToSend.value.legs;
      copyStopData(legs[0].stops[1], legs[1].stops[0]);
      copyStopData(legs[1].stops[1], legs[2].stops[0]);

      store
        .dispatch('LoadsStore/createLoad', { load: getLoadToSend.value, isMultiple: false })
        .then(async (response) => {
          if (!isBillingCompanyEmpty.value) {
            for (let index = 0; index < billingAddress.value.length; index++) {
              if (!checkBillingLegArrayEmpty(billingAddress.value[index])) {
                await store
                  .dispatch('LoadsStore/putBillContactInfo', {
                    id: response.id,
                    params: billingAddress.value[index],
                  })
                  .then(() => {
                    store.state.UserStore.crossBorderBilling[index] = data.defaultBillObject;
                    store.dispatch('UserStore/showToast', t('email-sent'));
                  })
                  .catch((err) => {
                    store.dispatch('UserStore/showToast', t(err?.response?.data?.detail));
                  });
              }
            }
          }

          if (additionalDocuments.value.length > 0) {
            await Promise.all(
              additionalDocuments.value.map(async (file) => {
                await store.dispatch('LoadsStore/postMediaObjectLoad', {
                  id: response.id,
                  file: file.value,
                  type: file.type,
                });
              }),
            );
          }
          response.legs.forEach((leg, index) => {
            store.dispatch('LoadsStore/shipmentRequestBatch', {
              companies: selectedContactsLegs.value[index],
              shipments: [{ id: leg.shipment.id }],
            });
            router.push('/planning');
          });
          store.dispatch('UserStore/updateToast', {
            text: t('load-created-correctly'),
            type: 'success',
            visible: true,
          });
        })
        .catch((error) => {
          store.dispatch('UserStore/updateToast', {
            text: error?.response?.data?.detail,
            type: 'error',
            visible: true,
          });
        })
        .finally(() => {
          data.creatingLaod = false;
          // RESET STORES
          store.commit('CrossBorderStore/resetLoadToSend');
          store.commit('CrossBorderStore/setSelectedContactsLegs', [[], [], []]);
          store.commit('LoadsStore/setLoadAditionalDocuments', []);
        });
    };

    const formatLoadToSend = () => {
      updatePopertyFromLoad(dataCustomersSelected.value, 'customers');
      updatePopertyFromLoad(getLoadToSend.value.commodityMasters[0], 'commodityMaster');
      getLoadToSend.value.legs.forEach((leg, index) => {
        updateLegLoad(getLoadToSend.value.trailerType, 'trailerType', index);
        updateLegLoad(getLoadToSend.value.commodityMasters[0], 'commodityMaster', index);
        updateLegLoad(getLoadToSend.value.commodityMasters, 'commodityMasters', index);
        leg.stops.forEach((stop, stopIndex) => {
          updateLegStop(getLoadToSend.value.commodityMasters, 'commodities', stopIndex, index);
        });
      });
      // Set values of porperties stops
      updatePopertyFromLoad(
        [getLoadToSend.value.legs[0].stops[0], getLoadToSend.value.legs[2].stops[1]],
        'stops',
      );
    };

    const updatePopertyFromLoad = (value: any, key: string) => {
      store.commit('CrossBorderStore/updatePopertyFromLoad', {
        key: key,
        value: value,
      });
    };

    const updateLegLoad = (value: any, key: string, index: number) => {
      store.commit('CrossBorderStore/updateLegFromLoad', {
        key: key,
        value: value,
        index: index,
      });
    };

    const updateLegStop = (value: any, key: string, stopIndex: number, legIndex: number) => {
      store.commit('CrossBorderStore/updateLegStopFromLoad', {
        key: key,
        value: value,
        legIndex: legIndex,
        stopIndex: stopIndex,
      });
    };

    return {
      ...toRefs(data),
      tabs,
      handleChangeView,
      handleSubmit,
      checkBillingLegArrayEmpty,
      billingAddress,
      isBillingCompanyEmpty,
      selectedContactsLegs,
      validate,
    };
  },
});
