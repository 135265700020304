
import { defineComponent, reactive, toRefs, computed, onMounted, watch } from 'vue';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

interface carrierBase {
    load: any;
    loadingLoad: boolean;
}

export default defineComponent({
    name: 'NavCrossBorderLoads',
    components: {

    },
    props: {
        showCheck: { type: String, required: true, default: null },
        currentView: { type: String, required: true, default: null },
        currentStatus: { type: String, required: true, default: null },
        rateConfirmation: { type: Boolean, required: true, default: null },


    },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const translate = useI18n();


        const data = reactive({
            status: 'notInWarehouse',
            file: null as any,
            incidenceObject: {
                incidence: '',
                file: null as any,
                incidenceId: null,
            },
            isCrossBorderLoad: true,
            placeId: '',
            observations: '',
            currentLocation: '',
            loadingPostNotifyPosition: false,
            loadingPosition: false,
        });

        const currentLoad = computed(() => {
            return store.getters['LoadsStore/currentLoad'];
        })

        const currentLegIndex = computed(() => {
            return store.getters['LoadDetailStore/getCurrentLegIndex'];
        })


        return {
            ...toRefs(data),
            currentLoad,
            currentLegIndex,

        };
    },
});
