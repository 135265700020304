
import { defineComponent, reactive, toRefs, computed, onMounted } from 'vue';

import PlanButton from '@/components/buttons/PlanButton.vue';

import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

interface carrierBase {
  load: any;
  loadingLoad: boolean;
}

export default defineComponent({
  name: 'DriverFooter',
  emits: ['updateStatus'],
  components: {
    PlanButton,
  },
  props: {
    status: { type: String, required: true, default: null },
    file: { type: File as any, required: true, default: null },
    id: { type: String, required: false, default: '' },
    incidenceObject: {
      type: Object as any,
      required: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const translate = useI18n();

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const currentStopTracking = computed(() => {
      return store.getters['DriverTrackingStore/currentStopTracking'];
    });

    const data = reactive({
      activeTab: 'tracking',
      loadingDocuments: false,
      loadingIncidence: false,
      loadingDepart: false,
    });

    const currentUser = computed(() => {
      return store.getters['UserStore/getCurrentUser'];
    });

    const updateArrivalLate = () => {
      store.dispatch('LoadsStore/putStopTruckNear', currentStopTracking.value.id).then(() => {
        router.push({ name: 'DriverTrackingNotifyPosition', params: { id: route.params.id } });
      });
    };

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const uploadDocuments = async () => {
      data.loadingDocuments = true;
      await store
        .dispatch('ShipmentStore/postMediaObjectStops', {
          entityId: currentStopTracking.value.id,
          file: props.file,
        })
        .then(async () => {
          data.loadingDocuments = false;
          await store.dispatch(
            'CarrierStore/getLoadInfoForCarrier',
            store.getters['CarrierStore/getLoadData'].id,
          );
          await store.dispatch('UserStore/updateToast', {
            text: translate.t('documents-uploaded-done'),
            type: 'success',
            visible: true,
          });

          emit('updateStatus', 'notifyIncidence');
        });
    };

    const sendIncidence = async () => {
      let incidenceId = null;
      data.loadingIncidence = true;
      await store
        .dispatch('UserStore/postIncidence', {
          description: props.incidenceObject.incidence,
          stop: { id: currentStopTracking.value.id },
        })
        .then(async (response) => {
          incidenceId = response.id;
          store.dispatch('UserStore/updateToast', {
            text: translate.t('incidence-correctly-sent'),
            type: 'success',
            visible: true,
          });

          if (props.incidenceObject.file.length > 0 && props.incidenceObject.file !== null) {
            await store.dispatch('UserStore/postIncidenceFile', {
              entityId: incidenceId,
              file: props.incidenceObject.file[0],
            });
          }
          await store.dispatch(
            'CarrierStore/getLoadInfoForCarrier',
            store.getters['CarrierStore/getLoadData'].id,
          );
          data.loadingIncidence = false;

          emit('updateStatus', 'warehouseOutput');
        });
    };

    const isDriver = computed(() => {
      return store.getters['CarrierStore/getIsDriver'];
    });

    const handleArrivalStatus = () => {
      store
        .dispatch('DriverTrackingStore/setStopArrivalByDriver', currentStopTracking.value.id)
        .then(() => {
          emit('updateStatus', 'startMarking');
        });
    };

    const handleStartMarking = () => {
      store
        .dispatch('DriverTrackingStore/setStopStartByDriver', currentStopTracking.value.id)
        .then(() => {
          emit('updateStatus', 'endMarking');
        });
    };

    const handleEndMarking = () => {
      store
        .dispatch('DriverTrackingStore/setStopFinishByDriver', currentStopTracking.value.id)
        .then(() => {
          emit('updateStatus', 'uploadDocuments');
        });
    };

    const handleStopWarehouseDepart = () => {
      data.loadingDepart = true;
      store
        .dispatch('LoadsStore/putStopTruckDeparted', currentStopTracking.value.id)
        .then(() => {
          store
            .dispatch(
              'CarrierStore/getLoadInfoForCarrier',
              store.getters['CarrierStore/getLoadData'].id,
            )
            .then((response) => {
              const allStopsDeparted = loadData.value.stops.every(
                (stop) => stop.mediaObjects.length > 0 && stop.status === 'DEPARTED',
              );

              if (allStopsDeparted) {
                emit('updateStatus', 'finished');
              } else {
                const firstStopWithEmptyMediaObjects = store.getters[
                  'CarrierStore/getLoadData'
                ].stops.find(
                  (stop) =>
                    !stop.mediaObjects ||
                    stop.mediaObjects.length === 0 ||
                    stop.status !== 'DEPARTED',
                );
                store.commit(
                  'DriverTrackingStore/setCurrentStopTracking',
                  firstStopWithEmptyMediaObjects,
                );
                router.push({
                  name: 'DriverTrackingNotifyPosition',
                  params: { id: route.params.id },
                });
              }
            });
        })
        .finally(() => {
          data.loadingDepart = false;
        });
    };

    return {
      ...toRefs(data),
      actualWidth,
      currentStopTracking,
      isDriver,
      loadData,
      handleArrivalStatus,
      handleEndMarking,
      sendIncidence,
      uploadDocuments,
      updateArrivalLate,
      handleStartMarking,
      handleStopWarehouseDepart,
    };
  },
});
