
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanInputLoadLocation from '@/components/forms/PlanInputLoadLocation.vue';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import moment from 'moment'
export default defineComponent({
  name: 'PlanInstantQuoteForm',
  components: { PlanButtonIcon, PlanIcon, PlanTextButton, PlanButton, PlanInputText, PlanSelect, PlanInputCalendar, PlanInputLoadLocation },
  props: {
    onlyDetails: { type: Boolean, required: false, default: false }
  },
  emits: ['updateQuote'],
  setup (props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const data = reactive({
      commodity: '',
      isUpdating: true,
      today: moment().format('DD-MM-YYYY'),
      minDate: 'mm-dd-yyyy',
      maxDate: 'mm-dd-yyyy',
      type: '',
      units: null,
      vehicle: '',
      weight: null,
      calendarKey: 0,
      deliveryPostalCode: '',
      pickupPostalCode: ''
    })

    if (props.onlyDetails) data.isUpdating = false

    const activeForm = computed(() => {
      const region = store.getters['UserStore/getRegion']
      const activeFormData = store.getters['InstantQuoteStore/getActiveForm']
      let pickUpData = activeFormData.pickup.location
      let deliverData = activeFormData.delivery.location
      const typeObject = types.value.find(type => type.value === store.getters['InstantQuoteStore/getActiveForm'].packageType)
      const trailerObject = vehicles.value.find(type => type.value.toString() === store.getters['InstantQuoteStore/getActiveForm'].trailerId)


      return {
        pickup: pickUpData,
        pickupDate: moment(activeFormData.pickupDate, "DD-MM-YYYY").format("MMM DD, YYYY"),
        delivery: deliverData,
        deliveryDate: moment(activeFormData.deliveryDate, "DD-MM-YYYY").format("MMM DD, YYYY"),
        trailer: trailerObject?.label,
        units: `${activeFormData.units} ${typeObject.label}`,
        weight: `${activeFormData.weight} ${region === 'NORTH_AMERICA' ? 'lb':'kg'}`
      }
    })

    if (store.getters['InstantQuoteStore/getActiveForm']) {
      data.pickupPostalCode = store.getters['InstantQuoteStore/getActiveForm'].pickup.postal_code
      data.deliveryPostalCode = store.getters['InstantQuoteStore/getActiveForm'].delivery.postal_code
      data.minDate = store.getters['InstantQuoteStore/getActiveForm'].pickupDate
      data.maxDate = store.getters['InstantQuoteStore/getActiveForm'].deliveryDate
      data.commodity = store.getters['InstantQuoteStore/getActiveForm'].commodity
      data.weight = store.getters['InstantQuoteStore/getActiveForm'].weight
      data.units = store.getters['InstantQuoteStore/getActiveForm'].units
      data.type = store.getters['InstantQuoteStore/getActiveForm'].packageType
      data.vehicle = store.getters['InstantQuoteStore/getActiveForm'].trailerId.toString()
    }

    const changeCommodity = () => {
      store.commit('InstantQuoteStore/setCommodity', data.commodity)
    }

    const changeMinDate = (newDate: string) => {
      store.commit('InstantQuoteStore/setPickupDate', newDate)
      const newDateMoment = moment(newDate, "MM-DD-YYYY")
      const maxDateMoment = moment(data.maxDate, "MM-DD-YYYY")

      if (newDateMoment.isAfter(maxDateMoment)) {
        data.minDate = data.maxDate
      } else {
        data.minDate = newDate
      }
      data.calendarKey += 1
    }

    const changeMaxDate = (newDate: string) => {
      store.commit('InstantQuoteStore/setDeliveryDate', newDate)
      if (new Date(newDate) < new Date(data.minDate)) {
        data.maxDate = data.minDate
        return
      }
      data.maxDate = newDate
    }

    const changePackageType = (packageValue: any) => {
      store.commit('InstantQuoteStore/setPackageType', packageValue)
    }

    const changeTrailerType = (trailerValue: any) => {
      store.commit('InstantQuoteStore/setTrailer', trailerValue)
    }

    const changeUnits = () => {
      store.commit('InstantQuoteStore/setUnits', data.units)
    }

    const changeWeight = () => {
      store.commit('InstantQuoteStore/setWeight', data.weight)
    }

    const cleanInstantQuote = () => {
      store.commit('InstantQuoteStore/cleanStore')
      router.push({ name: 'InstantQuote' })
    }

    const goToInstantQuote = () => {
      router.push({ name: 'InstantQuote' })
    }

    const goToInstantQuoteDetails = () => {
      router.push({ name: 'InstantQuoteDetails' })
    }

    const isInstantQuoteFormValidated = computed(() => {
      return store.getters['InstantQuoteStore/getIsInstantQuoteFormValidated']
    })

    const instantQuoteForm = computed(() => {
      return store.getters['InstantQuoteStore/getInstantQuoteFormValues']
    })

    const saveActualForm = () => {
      store.commit('InstantQuoteStore/setActiveForm', store.getters['InstantQuoteStore/getInstantQuoteFormValues'])
      if (route.name === 'InstantQuoteDetails') {
        emit('updateQuote')
        data.isUpdating = false
      } else {
        router.push({ name: 'InstantQuoteDetails' })
      }
    }

    const types = computed(() => {
      let result: Array<any> = []
      store.getters['LoadsStore/packageTypes'].FTL?.forEach((packageType: any) => {
        if (data.type === '') data.type = packageType.id.toString()
        result.push({
          label: packageType.fullName,
          value: packageType.id.toString()
        })
      })

      return result
    })

    const updateDeliveryData = (newDelivery: any) => {
      store.commit('InstantQuoteStore/setDelivery', newDelivery)
    }

    const updatePickupData = (newPickup: any) => {
      store.commit('InstantQuoteStore/setPickup', newPickup)
    }

    const vehicles = computed(() => {
      let result: Array<any> = []
      store.getters['LoadsStore/getTrailerTypes'].forEach((vehicle: any) => {
        result.push({
          label: vehicle.description,
          value: vehicle.id.toString()
        })
      })
      return result
    })

    store.dispatch('LoadsStore/packageTypes')
    if (data.type === '') changePackageType(types.value[0].value)

    return {
      ...toRefs(data),
      activeForm,
      changeCommodity,
      changeMinDate,
      changeMaxDate,
      changePackageType,
      changeTrailerType,
      changeUnits,
      changeWeight,
      cleanInstantQuote,
      goToInstantQuote,
      goToInstantQuoteDetails,
      isInstantQuoteFormValidated,
      instantQuoteForm,
      saveActualForm,
      types,
      updateDeliveryData,
      updatePickupData,
      vehicles
    }
  }
})
