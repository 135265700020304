
import { defineComponent } from 'vue';
import { StarIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
import PlanAvatar from '@/components/PlanAvatar.vue';

export default defineComponent({
  name: 'CarrierCompanyIcons',
  components: {
    PlanAvatar,
    StarIcon,
  },
  mixins: [BaseComponent],
  props: {
    loadId: Number,
  },
  data() {
    return {
      //   isModifying: false,
    };
  },
  computed: {
    currentLoad(): any {
      const load = this.$store.getters['LoadsStore/currentLoad'];
      return load?.legs?.length > 0 ? load.legs[this.currentLegIndex] : load;
    },
    currentLegIndex(): any {
      return this.$store.getters['LoadDetailStore/getCurrentLegIndex'];
    },
    // currentStatus(): any {
    //   return this.currentLoad ? this.currentLoad?.status : null;
    // },
    // isModify(): boolean {
    //   return this.currentStatus === 'CONFIRMED';
    // },
    // isConsigneeFilled() {
    //   return this.currentLoad.isConsigneeFilled;
    // },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
  },
  methods: {
    // modifyConsignee() {
    //   if (this.isModify) {
    //     this.isModifying = true;
    //   }
    // },
    // closeModifyConsignee() {
    //   this.isModifying = false;
    // },
    openChat() {
      const initialLoad = this.$store.getters['LoadsStore/currentLoad'];
      this.$store.commit('GlobalStore/setOpenChat', true);
      this.$store.commit('LoadsStore/loadId', initialLoad.id);
      this.$store.commit(
        'ChatsStore/setUserForChat',
        this.currentLoad?.shipment?.assignedCompany?.company,
      );
      this.$store.commit(
        'ChatsStore/setChatId',
        this.currentLoad.chat ? this.currentLoad.chat.id : null,
      );
    },
  },
});
