import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col plan-gap-sm" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "plan-t-xs plan-t-prim-500 font-semibold" }
const _hoisted_6 = { class: "plan-t-xxs plan-t-prim-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.possibleDeliveries.length === 1)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('deliver-in')) + " " + _toDisplayString(_ctx.possibleDeliveries[0].location), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_plan_select, {
      onChange: _ctx.addDelivery,
      label: _ctx.$t('select-possible-deliveries'),
      placeholder: _ctx.$t('select'),
      value: _ctx.selectedDelivery,
      items: _ctx.deliveries,
      "equal-size": ""
    }, null, 8, ["onChange", "label", "placeholder", "value", "items"]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedDeliveriesToShow, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item,
          class: "plan-b-sec-50 plan-rounded-sm plan-pt-xs plan-pb-xs plan-pl-sm plan-pr-sm flex justify-start items-center plan-gap-sm"
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(item.location), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(item.date), 1)
          ]),
          _createVNode(_component_plan_icon, {
            onClick: ($event: any) => (_ctx.removeSelected(item)),
            name: "close",
            "icon-color": "black",
            "is-svg": "",
            class: "cursor-pointer"
          }, null, 8, ["onClick"])
        ]))
      }), 128))
    ])
  ]))
}