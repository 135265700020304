import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-bold truncate" }
const _hoisted_2 = { class: "font-bold truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_avatar = _resolveComponent("plan-avatar")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex plan-gap-sm", 
      _ctx.actualWidth <= 640 ? 'flex-col justify-start items-end' : 'justify-between items-center'
    ])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center", 
        _ctx.actualWidth <= 640 ? 'w-full plan-gap-sm justify-between' : 'plan-gap-md justify-start'
      ])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col", _ctx.actualWidth <= 640 ? 'w-2/5' : 'w-full'])
      }, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.stopsData.pickup), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.stopsData.pickupDate), 1)
      ], 2),
      _createVNode(_component_plan_icon, {
        name: "plan-arrow-right",
        "icon-color": "#151830",
        "is-svg": ""
      }),
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col w-full", _ctx.actualWidth <= 640 ? 'w-2/5 justify-end items-end' : 'w-full'])
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.stopsData.delivery), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.stopsData.deliveryDate), 1)
      ], 2)
    ], 2),
    (!_ctx.isDriver)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["flex items-center plan-gap-sm", _ctx.actualWidth <= 640 ? 'justify-end' : 'justify-center'])
        }, [
          _createVNode(_component_plan_avatar, {
            "user-name": _ctx.shipperOwner.company,
            description: _ctx.shipperOwner.user
          }, null, 8, ["user-name", "description"]),
          _createVNode(_component_plan_button_icon, {
            onOnClick: _ctx.openChat,
            icon: "chat",
            type: "transparent-gray-800",
            withNotification: !_ctx.isRead
          }, null, 8, ["onOnClick", "withNotification"])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}