import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/services/store';

// Modules
import AuthRouter from '@/modules/auth/_router';
import CarrierRouter from '@/modules/carrier/_router';
import CarrierViewsRouter from '@/modules/carrierViews/_router';
import DemoRouter from '@/modules/demo/_router';
import NotFoundView from '@/modules/carrier/notFound/NotFoundView.vue';
import PlanningRouter from '@/modules/planning/_router';
import SuperAdminRouter from '@/modules/superAdmin/_router';
import UiComponents from '@/components/uiComponents/UiComponents.vue';
import ShipperAuthentication from '@/modules/rfp/ShipperAuthentication.vue';
import SharedDocuments from '@/modules/downloadDocuments/SharedDocuments.vue';
import DriverTracking from '@/modules/DriverTracking/_router';
import CustomAgent from '@/modules/customAgent/_router';

import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

const beforeEnterUiComponents = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const showUiComponents = process.env.VUE_APP_SHOW_UI_COMPONENTS;
  if (showUiComponents === 'true') {
    next();
  } else {
    next({ name: 'planification' });
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'planification' },
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'notFoundView' },
  },
  {
    path: '/planning/:catchAll(.*)',
    redirect: { name: 'planification' },
  },
  {
    path: '/carrier/:catchAll(.*)',
    redirect: { name: 'notFoundView' },
  },
  {
    path: '/shipper/:catchAll(.*)',
    redirect: { name: 'notFoundView' },
  },
  {
    path: '/sharedDocuments/:code/',
    name: 'sharedDocuments',
    component: SharedDocuments,
    props: true,
  },
  {
    path: '/shipperAuthentification/:id/:hash/:code',
    name: 'ShipperAuth',
    component: ShipperAuthentication,
    props: true,
    beforeEnter: async (to, from, next) => {
      await next();
    },
  },
  {
    path: '/404',
    name: 'notFoundView',
    component: NotFoundView,
  },

  {
    path: '/ui-components',
    name: 'UiComponents',
    component: UiComponents,
    beforeEnter: beforeEnterUiComponents,
    meta: {
      activeButton: 'ui',
    },
  },
  ...AuthRouter,
  ...DemoRouter,
  ...SuperAdminRouter,
  ...PlanningRouter,
  ...CarrierRouter,
  ...CarrierViewsRouter,
  ...DriverTracking,
  ...CustomAgent,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Try to auto login for check the token
router.beforeEach(async (to, from, next) => {
  const isSigned = await store.dispatch('AuthStore/autoLogin');
  const isSuplanting = store.getters['UserStore/getImpersonatedUserId'];
  const currentUser = store.getters['UserStore/getCurrentUser'];

  const accessPlan = await getAccessPlanCurrentUser();
  const premiumUser = accessPlan === 'PREMIUM' || accessPlan === 'TRIAL' || accessPlan === 'CUSTOM';
  if (!premiumUser && to.path.includes('RFQ')) {
    return next({ name: 'planning' });
  }

  if (currentUser?.company?.type === 'ADMIN') {
    if (isSuplanting === '') {
      if (
        PlanningRouter.some((route) => to.path.startsWith(route.path)) ||
        CarrierRouter.some((route) => to.path.startsWith(route.path))
      ) {
        next({ name: 'shippersList' });
      } else {
        next();
      }
    }
  }

  // CHECK if the user is a viewer
  const viewerForbiddenPaths = ['messages', 'newLoad', 'contacts'];
  const customForbiddenPaths = ['payments', 'paymentsMethods', 'plans'];
  const isViewer = await isViewerUser();

  if (isViewer && viewerForbiddenPaths.some((str) => to.path.includes(str))) {
    return next({ name: 'planning' });
  }

  // TODO REFACTOR to TYPEFILE

  if (
    to.name !== 'signin' &&
    !to.path.includes('activate-account') &&
    !to.path.includes('request-demo') &&
    !to.path.includes('password-reset') &&
    !to.path.includes('carrier') &&
    !to.path.includes('driver') &&
    !to.path.includes('carrier-view') &&
    !isSigned &&
    !to.path.includes('shipper') &&
    to.name !== 'plansCode' &&
    to.name !== 'planificationDetailToken' &&
    to.name !== 'planificationDetailTokenHash' &&
    to.name !== 'ShipperAuth' &&
    to.name !== 'sharedDocuments' &&
    to.name !== 'CustomAgentCCP' &&
    to.name !== 'lanesRFQIdCode' &&
    to.name !== 'startFreeTrial'
  ) {
    next({ name: 'signin' });
  }
  // Control on User Trial Expired

  console.log(to.name);
  console.log(to.path);

  if (
    isSigned &&
    (accessPlan === 'TRIAL_EXPIRED' ||
      accessPlan === 'SUBSCRIPTION_TERMINATED' ||
      accessPlan === 'SUBSCRIPTION_NOT_PAID') &&
    to.name !== 'payments' &&
    to.name !== 'plans' &&
    to.name !== 'paymentsMethods'
  ) {
    return next({ name: 'plans' });
  }

  if (accessPlan === 'CUSTOM' && customForbiddenPaths.some((str) => to.path.includes(str))) {
    return next({ name: 'planning' });
  }

  // if the user is not authenticated, `next` is called twice
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  next();
});

async function getAccessPlanCurrentUser() {
  if (localStorage.token && Object.keys(store.getters['UserStore/getCurrentUser']).length === 0) {
    await store.dispatch('UserStore/currentUser');
  }
  const currentUser = store.getters['UserStore/getCurrentUser'];
  return currentUser?.company?.accessPlanType ?? null;
}

async function isViewerUser() {
  if (localStorage.token && Object.keys(store.getters['UserStore/getCurrentUser']).length === 0) {
    await store.dispatch('UserStore/currentUser');
  }
  const currentUser = store.getters['UserStore/getCurrentUser'];
  return currentUser?.roles?.includes('ROLE_SHIPPER_VIEWER');
}

export default router;
