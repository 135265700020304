import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row gap-x-2 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanInputMultiSelect = _resolveComponent("PlanInputMultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PlanInputMultiSelect, {
      label: 'permits',
      items: _ctx.permitsByRegion,
      onChange: _ctx.changeSelectedPermits
    }, null, 8, ["items", "onChange"])
  ]))
}