
import { defineComponent, computed, reactive, toRefs, watch } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import { useStore } from 'vuex';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonGroup from '@/components/buttons/PlanButtonGroup.vue';
import i18n from '@/resources/locales';
import { setLocale } from '@vee-validate/i18n';
import { timezones } from '@/services/timezones/index';
import PlanSelectSearch from '@/components/forms/PlanSelectSearch.vue';
import PlanMailNotificationsConfigurationModal from '@/components/modals/PlanMailNotificationsConfigurationModal.vue';
import SidebarUserButton from '@/components/sidebar/SidebarUserButton.vue';

export default defineComponent({
  name: 'CarrierSidebar',
  components: {
    SidebarUserButton,
    PlanMailNotificationsConfigurationModal,
    PlanSelectSearch,
    PlanButton,
    PlanAvatar,
    PlanIcon,
  },
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      showEmailConfiguration: false,
      selectedLanguage: i18n.global.locale.value,
      timeZone: '',
    });

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const actualUser = computed(() => {
      return store.getters['UserStore/getCurrentUser'];
    });

    const changeLanguage = (type: any) => {
      data.selectedLanguage = type;
      i18n.global.locale.value = data.selectedLanguage;
      localStorage.setItem('userLocale', data.selectedLanguage);
      setLocale(data.selectedLanguage);
    };

    const currentUser = computed(() => {
      return store.getters['UserStore/getCurrentUser'];
    });

    const timezonesFormatted = computed(() => {
      return timezones.map((time) => {
        return {
          label: time.label,
          value: time.tzCode,
        };
      });
    });

    const timezoneOnChange = (value) => {
      data.timeZone = value;
      store.dispatch('UserStore/putUser', {
        id: currentUser.value.id,
        user: { ...currentUser.value, timeZone: data.timeZone },
      });
    };

    const dtf = new Intl.DateTimeFormat();
    if (currentUser.value.id) {
      data.timeZone = currentUser.value?.timeZone ?? dtf.resolvedOptions().timeZone;
    }

    watch(
      () => store.getters['CarrierStore/getShowEmailNotifications'],
      (newValue, oldValue) => {
        if (store.getters['CarrierStore/getShowEmailNotifications']) {
          data.showEmailConfiguration = true;
          store.commit('CarrierStore/setShowEmailNotifications', false);
        }
      },
    );

    if (store.getters['CarrierStore/getShowEmailNotifications']) {
      data.showEmailConfiguration = true;
      store.commit('CarrierStore/setShowEmailNotifications', false);
    }

    return {
      ...toRefs(data),
      actualWidth,
      actualUser,
      changeLanguage,
      timezonesFormatted,
      timezoneOnChange,
    };
  },
});
