<template>
  <p class="font-bold text-black">{{ text }}</p>
  <plan-button
    type="primary-dark"
    font-size="18"
    :label="buttonText"
    @onClick="$emit('clickhandler')"
  />
</template>

<script>
import PlanButton from '@/components/buttons/PlanButton.vue';
import { defineComponent, reactive, toRefs, computed, onBeforeMount } from 'vue';

export default defineComponent({
  name: 'CarrierGeneralNotification',
  emit:['clickhandler'],
  props: {
    text: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  components: {
    PlanButton,
  },
});
</script>
