
import { defineComponent, ref, computed, reactive } from 'vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PlanUploadFile',
  emits: ['change'],
  components: {
    PlanInputFile,
  },
  props: {
    isModify: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();
    const isDragging = ref(false);
    const data = reactive({
      additionalDocuments: null as any,
      FILES: null as any,
      mediaObjectUrls: null as any,
    });

    const docsList = computed(() => {
      let response = [] as any;
      const docs = store.getters['LoadsStore/getDocsList'];
      for (const key in docs) {
        if (Object.hasOwnProperty.call(docs, key)) {
          response.push({
            documents: docs[key],
            key: key,
            title: translate.t(key),
          });
        }
      }
      return response;
    });

    const extraDocs = computed(() => {
      let docs: any = [];
      docsList.value.forEach((d) => {
        if (d.key === 'load_docs') {
          const documents = deepClone(d.documents);
          documents.forEach((item, i) => {
            if (item.type === 'LOAD') {
              docs.push({ ...item, name: item.displayName });
            }
          });
        }
      });
      return docs;
    });

    const letterOfInstructions = computed(() => {
      let docs: any = [];
      docsList.value.forEach((d) => {
        if (d.key === 'load_docs') {
          const documents = deepClone(d.documents);
          documents.forEach((item, i) => {
            if (item.type === 'LETTER_OF_INSTRUCTIONS') {
              docs.push({ ...item, name: item.displayName });
            }
          });
        }
      });
      return docs;
    });

    const getLoadToSend = computed(() => {
      return store.getters['CrossBorderStore/getLoadToSend'];
    });

    const deepClone = (obj) => {
      return JSON.parse(JSON.stringify(obj));
    };

    const changeFiles = async (newFiles, type) => {
      isDragging.value = true;
      const files = newFiles;
      const filesArray: File[] = Array.from(files);
      const acceptedTypes = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/png',
      ];
      const maxSize = 100 * 1024 * 1024;

      const validFiles = filesArray.filter((file) => {
        if (!acceptedTypes.includes(file.type)) {
          store.dispatch('UserStore/updateToast', {
            text: translate.t('file-not-valid-type', { file: file.name }),
            type: 'error',
            visible: true,
          });
          return false;
        }

        if (file.size > maxSize) {
          store.dispatch('UserStore/updateToast', {
            text: translate.t('file-not-valid-size', { file: file.name }),
            type: 'error',
            visible: true,
          });
          return false;
        }

        return true;
      });
      if (validFiles.length > 0) {
        data.FILES = validFiles;
        data.FILES.forEach((file) => {
          const existingFiles = store.getters['LoadsStore/getLoadAditionalDocuments'];
          const mergedFiles = [...existingFiles, { value: file, type: type }];

          if (props.isModify) {
            store.dispatch('LoadsStore/postMediaObjectLoad', {
              id: getLoadToSend.value.id,
              file: file,
              type: type,
            });
          }
          store.commit('LoadsStore/setLoadAditionalDocuments', mergedFiles);
        });
      }
    };

    const removeFile = async (idDeleteItem) => {
      if (props.isModify && idDeleteItem) {
        store.dispatch('LoadsStore/deleteLoadMediaObject', idDeleteItem);
      }
    };

    return {
      changeFiles,
      docsList,
      extraDocs,
      getLoadToSend,
      letterOfInstructions,
      removeFile,
    };
  },
});
