import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import AuthStore from '@/modules/auth/_store';
import ChatsStore from '@/services/chats/_store';
import CompanyStore from '@/services/company/_store';
import CountryStore from '@/services/country/_store';
import CreditStore from '@/modules/credit/_store';
import CustomerStore from '@/services/customer/_store';
import DemoStore from '@/modules/demo/_store';
import GlobalStore from '@/services/global/_store';
import LanesStore from '@/services/lanes/_store';
import LoadsStore from '@/services/loads/_store';
import DriverTrackingStore from '@/modules/DriverTracking/_store';
import NotificationStore from '@/services/notification/_store';
import QuotationRequestStore from '@/modules/carrier/quotationRequest/_store';
import PusherStore from '@/services/pusher/_store';
import RFPStore from '@/modules/rfp/_store';
import ShipmentStore from '@/services/shipment/_store';
import ShipperDashboardStore from '@/modules/planning/dashboard/_store';
import UserStore from '@/services/user/_store';
import PaymentStore from '@/modules/payments/_store';
import CarrierStore from '@/modules/carrierViews/_store';
import CrossBorderStore from '@/services/crossBorder/_store';
import NewFtlLoad from '@/services/newFtlLoad/_store'
import LoadDetailStore from '@/services/loadDetail/_store';
import InstantQuoteStore from '@/modules/planning/instantQuote/_store'

export default createStore({
  modules: {
    AuthStore,
    CarrierStore,
    ChatsStore,
    CompanyStore,
    CountryStore,
    CreditStore,
    CrossBorderStore,
    CustomerStore,
    DemoStore,
    DriverTrackingStore,
    GlobalStore,
    LanesStore,
    LoadsStore,
    LoadDetailStore,
    NotificationStore,
    PaymentStore,
    QuotationRequestStore,
    PusherStore,
    RFPStore,
    ShipmentStore,
    ShipperDashboardStore,
    UserStore,
    InstantQuoteStore,
    NewFtlLoad
  },
  plugins: [
    createPersistedState({
      paths: ['UserStore', 'LoadsStore', 'CarrierStore', 'NewFtlLoad', 'InstantQuoteStore'],
      key: 'tu-clave-de-almacenamiento',
      storage: window.sessionStorage,
    }),
  ],
});
