import globalAxios from 'axios';

const getCompaniesForInstantQuote = async (
  page: number,
  origin: string,
  destiny: string,
  trailer: string,
  trailerStats: string,
  companyType: string,
  permits: Array<any>,
  lane: boolean
) => {
  let url = `/company/myNetworkWithZIPAndTrailerTypeForInstantQuote?page=${page}&originZIP=${origin}&destinyZIP=${destiny}&trailerTypeStats=${trailerStats}&sortBy=loadsQuantity[DESC]`
  if (companyType.length > 0) url = url + `&type=${companyType}`
  if (trailer !== '-1') url = url + `&trailerType=${trailer}`
  if (permits.length) url = url + `&permits=${permits}`
  if (!lane) url = url + '&3dzip=true'
  const response = await globalAxios.get(url)
  return response.data;
}

const getDatePriceStats = async (
  origin: string,
  destiny: string,
  trailer: string,
  period: string,
  lane: boolean
) => {
  let url = `/shipment/datePriceStats?originZip=${origin}&destinyZip=${destiny}&trailerType=${trailer}&periodType=${period}&onlyRate=${true}`
  if (!lane) url = url + '&3dzip=true'
  const response = await globalAxios.get(url)
  return response.data;
}

const getPricingInfo = async (
  origin: string,
  originCity: string,
  originPlaceId: string,
  destiny: string,
  destinyCity: string,
  destinyPlaceId: string,
  type: string
) => {
  const url = `/pricingInfo?originZip=${origin}&originCity=${encodeURIComponent(originCity)}&originPlaceId=${encodeURIComponent(originPlaceId)}&destinyZip=${destiny}&destinyCity=${encodeURIComponent(destinyCity)}&destinyPlaceId=${encodeURIComponent(destinyPlaceId)}&type=${type}`;
  const response = await globalAxios.get(url)
  return response.data;
}

const getShipmentAverageStats = async (
  origin: string,
  destiny: string,
  trailer: string,
  period: string,
  lane: boolean
) => {
  let url = `/shipment/averageStats?originZip=${origin}&destinyZip=${destiny}&trailerType=${trailer}&periodType=${period}&onlyRate=${true}`
  if (!lane) url = url + '&3dzip=true'
  const response = await globalAxios.get(url);
  return response.data;
}



export default {
  getCompaniesForInstantQuote,
  getDatePriceStats,
  getPricingInfo,
  getShipmentAverageStats,
}