import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded w-full border plan-border-prim-50 plan-p-md flex flex-col plan-gap-md" }
const _hoisted_2 = { class: "w-full font-bold plan-t-xs plan-t-prim-200" }
const _hoisted_3 = { class: "flex flex-wrap justify-start items-start plan-gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_plan_input_text = _resolveComponent("plan-input-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('special-requirements')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_plan_input_checkbox, {
        style: {"min-width":"160px"},
        onChangeWithId: _ctx.changeCheckbox,
        id: 1,
        "pre-value": _ctx.specialRequirements.teamDriver,
        label: _ctx.$t('team-driver')
      }, null, 8, ["onChangeWithId", "pre-value", "label"]),
      (_ctx.actualRegion === 'NORTH_AMERICA')
        ? (_openBlock(), _createBlock(_component_plan_input_checkbox, {
            key: 0,
            style: {"min-width":"160px"},
            onChangeWithId: _ctx.changeCheckbox,
            id: 2,
            "pre-value": _ctx.specialRequirements.bondedPermission,
            label: _ctx.$t('bonded-permission')
          }, null, 8, ["onChangeWithId", "pre-value", "label"]))
        : _createCommentVNode("", true),
      (_ctx.actualRegion === 'NORTH_AMERICA')
        ? (_openBlock(), _createBlock(_component_plan_input_checkbox, {
            key: 1,
            style: {"min-width":"160px"},
            onChangeWithId: _ctx.changeCheckbox,
            id: 3,
            "pre-value": _ctx.specialRequirements.tsaCard,
            label: _ctx.$t('card-tsa')
          }, null, 8, ["onChangeWithId", "pre-value", "label"]))
        : _createCommentVNode("", true),
      (_ctx.actualRegion === 'NORTH_AMERICA')
        ? (_openBlock(), _createBlock(_component_plan_input_checkbox, {
            key: 2,
            style: {"min-width":"160px"},
            onChangeWithId: _ctx.changeCheckbox,
            id: 4,
            "pre-value": _ctx.specialRequirements.twicCard,
            label: _ctx.$t('card-twic')
          }, null, 8, ["onChangeWithId", "pre-value", "label"]))
        : _createCommentVNode("", true),
      _createVNode(_component_plan_input_checkbox, {
        style: {"min-width":"160px"},
        onChangeWithId: _ctx.changeCheckbox,
        id: 5,
        "pre-value": _ctx.specialRequirements.hazardousLoad,
        label: _ctx.$t('hazardous-load')
      }, null, 8, ["onChangeWithId", "pre-value", "label"]),
      (_ctx.actualRegion === 'EUROPE')
        ? (_openBlock(), _createBlock(_component_plan_input_checkbox, {
            key: 3,
            style: {"min-width":"160px"},
            onChangeWithId: _ctx.changeCheckbox,
            id: 7,
            "pre-value": _ctx.specialRequirements.tirCable,
            label: _ctx.$t('permits-contact.hasTirPermit')
          }, null, 8, ["onChangeWithId", "pre-value", "label"]))
        : _createCommentVNode("", true),
      _createVNode(_component_plan_input_checkbox, {
        style: {"min-width":"160px"},
        onChangeWithId: _ctx.changeCheckbox,
        id: 6,
        "pre-value": _ctx.specialRequirements.specialPermits,
        label: _ctx.$t('special-permits')
      }, null, 8, ["onChangeWithId", "pre-value", "label"])
    ]),
    (_ctx.specialRequirements.specialPermits)
      ? (_openBlock(), _createBlock(_component_plan_input_text, {
          key: 0,
          onChange: _ctx.change,
          placeholder: _ctx.$t('special-permits'),
          "model-value": _ctx.specialRequirements.specialPermitsValue,
          "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.specialRequirements.specialPermitsValue) = $event))
        }, null, 8, ["onChange", "placeholder", "model-value"]))
      : _createCommentVNode("", true)
  ]))
}