const localAssets = {
  icAirplane: require('@/resources/assets/icons/airplane.svg'),
  icArrowCalendarLeft: require('@/resources/assets/icons/arrow-calendar-left.svg'),
  icArrowCalendarRight: require('@/resources/assets/icons/arrow-calendar-right.svg'),
  icArrowDown: require('@/resources/assets/icons/arrow-down.svg'),
  icArrowDownChevron: require('@/resources/assets/icons/arrow-down-chevron.svg'),
  icArrowDownDouble: require('@/resources/assets/icons/arrow-down-double.svg'),
  icArrowDownWhite: require('@/resources/assets/icons/arrow-down-white.svg'),
  icArrowLeft: require('@/resources/assets/icons/arrow-left.svg'),
  icArrowNarrowRight: require('@/resources/assets/icons/arrow-narrow-right.svg'),
  icArrowNarrowRightLarge: require('@/resources/assets/icons/arrow-narrow-right-large.svg'),
  icArrowRight: require('@/resources/assets/icons/arrow-right.svg'),
  icArrowUp: require('@/resources/assets/icons/arrow-up.svg'),
  icArrowUpDouble: require('@/resources/assets/icons/arrow-up-double.svg'),
  icBackButton: require('@/resources/assets/icons/back-icon.svg'),
  icBell: require('@/resources/assets/icons/bell.svg'),
  icBellRed: require('@/resources/assets/icons/bell-red.svg'),
  icBlackStart: require('@/resources/assets/icons/black-start.svg'),
  icBrokenTruck: require('@/resources/assets/icons/broken-truck.svg'),
  icBurgerRFP: require('@/resources/assets/icons/burger-rfp.svg'),
  icBurgerRFPWhite: require('@/resources/assets/icons/burger-rfp-white.svg'),
  icCalendar: require('@/resources/assets/icons/calendar.svg'),
  icCalendarNew: require('@/resources/assets/icons/calendar-new.svg'),
  icCalendarNewBlack: require('@/resources/assets/icons/calendar-new-black.svg'),
  icCalendarWhite: require('@/resources/assets/icons/calendar-white.svg'),
  icChat: require('@/resources/assets/icons/chat.svg'),
  icChatNew: require('@/resources/assets/icons/chat-new.svg'),
  icChatWhite: require('@/resources/assets/icons/chat-white.svg'),
  icCheck: require('@/resources/assets/icons/check.svg'),
  icCheckGray: require('@/resources/assets/icons/check-gray.svg'),
  icCheckBlueDark: require('@/resources/assets/icons/check-dark-blue.svg'),
  icCheckCircleBlack: require('@/resources/assets/icons/check-circle-black.svg'),
  icCheckCircleBlackFilled: require('@/resources/assets/icons/check-circle-black-filled.svg'),
  icCheckCircleGreenFilled: require('@/resources/assets/icons/check-circle-green-filled.svg'),
  icClose: require('@/resources/assets/icons/close.svg'),
  icCloseWhite: require('@/resources/assets/icons/close-white.svg'),
  icCrosshair: require('@/resources/assets/icons/crosshair.svg'),
  icDocument: require('@/resources/assets/icons/document.svg'),
  icDotsSettings: require('@/resources/assets/icons/dots-settings.svg'),
  icDoubleCheck: require('@/resources/assets/icons/double-check.svg'),
  icDownloadWhite: require('@/resources/assets/icons/download-white.svg'),
  icEdit: require('@/resources/assets/icons/edit.svg'),
  icExpired: require('@/resources/assets/icons/expired.svg'),
  icInfo: require('@/resources/assets/icons/info.svg'),
  icLocationNew: require('@/resources/assets/icons/location-new.svg'),
  icPayment: require('@/resources/assets/icons/payment.svg'),
  icLanguage: require('@/resources/assets/icons/language.svg'),
  icMasterCard: require('@/resources/assets/icons/mastercard.svg'),
  icMarkerDelivery: require('@/resources/assets/icons/marker-delivery.svg'),
  icMarkerDeliveryFinal: require('@/resources/assets/icons/marker-delivery-final.svg'),
  icMarkerDestine: require('@/resources/assets/icons/marker-destine.svg'),
  icMarkerDestineCircle: require('@/resources/assets/icons/marker-destine-circle.png'),
  icMarkerDriverCircle: require('@/resources/assets/icons/marker-driver-circle.png'),
  icMarkerDriverLocationNew: require('@/resources/assets/icons/marker-driver-location-new.svg'),
  icMarkerLocationNewOrigin: require('@/resources/assets/icons/marker-location-new-origin.svg'),
  icMarkerOrigin: require('@/resources/assets/icons/marker-origin.svg'),
  icMarkerOriginCircle: require('@/resources/assets/icons/marker-origin-circle.png'),
  icMarkerPickup: require('@/resources/assets/icons/marker-pickup.svg'),
  icMarkerPickupInitial: require('@/resources/assets/icons/marker-pickup-initial.svg'),
  icMarkerTruck: require('@/resources/assets/icons/marker-truck.png'),
  icMarkerWaypointCircle: require('@/resources/assets/icons/marker-waypoint-circle.png'),
  icMarkerWaypointCircle2: require('@/resources/assets/icons/marker-waypoint-circle-2.png'),
  icMenuHam: require('@/resources/assets/icons/menu-ham.svg'),
  icMenuHamWhite: require('@/resources/assets/icons/menu-ham-white.svg'),
  icMessages: require('@/resources/assets/icons/messages.svg'),
  icMessagesNew: require('@/resources/assets/icons/messages-new.svg'),
  icNewDestiny: require('@/resources/assets/icons/new-destiny.png'),
  icNotification: require('@/resources/assets/icons/notification.svg'),
  icNewOrigin: require('@/resources/assets/icons/new-origin.png'),
  icParameters: require('@/resources/assets/icons/parameters.svg'),
  icProfile: require('@/resources/assets/icons/profile.svg'),
  icPlanimatikLogoWhite: require('@/resources/assets/icons/planimatik-logo-blue-white.svg'),
  icPlus: require('@/resources/assets/icons/plus.svg'),
  icVisa: require('@/resources/assets/icons/visa.svg'),
  icAmex: require('@/resources/assets/icons/amex.svg'),
  icDiscover: require('@/resources/assets/icons/discover.svg'),
  icQuestion: require('@/resources/assets/icons/visa.svg'),
  icRemove: require('@/resources/assets/icons/remove.svg'),
  icShip: require('@/resources/assets/icons/ship.svg'),
  icSpinner: require('@/resources/assets/icons/spinner.svg'),
  icStar: require('@/resources/assets/icons/star.svg'),
  icStarWhite: require('@/resources/assets/icons/star-white.svg'),
  icTeam: require('@/resources/assets/icons/team.svg'),
  icTrash: require('@/resources/assets/icons/trash.svg'),
  icTrashRed: require('@/resources/assets/icons/trash-red.svg'),
  icTruck: require('@/resources/assets/icons/truck.svg'),
  icUploadWhite: require('@/resources/assets/icons/upload-white.svg'),
  icUser: require('@/resources/assets/icons/user.svg'),
  icImpersonate: require('@/resources/assets/icons/impersonate-user.svg'),
  icEEFlag: require('@/resources/assets/icons/ee-flag.svg'),
  icIEFlag: require('@/resources/assets/icons/ie-flag.svg'),
  icGRFlag: require('@/resources/assets/icons/gr-flag.svg'),
  icHRFlag: require('@/resources/assets/icons/hr-flag.svg'),
  icCYFlag: require('@/resources/assets/icons/cy-flag.svg'),
  icLVFlag: require('@/resources/assets/icons/lv-flag.svg'),
  icGBFlag: require('@/resources/assets/icons/gb-flag.svg'),
  icBGFlag: require('@/resources/assets/icons/bg-flag.svg'),
  icCZFlag: require('@/resources/assets/icons/cz-flag.svg'),
  icDKFlag: require('@/resources/assets/icons/dk-flag.svg'),
  icLTFlag: require('@/resources/assets/icons/lt-flag.svg'),
  icLUFlag: require('@/resources/assets/icons/lu-flag.svg'),
  icHUFlag: require('@/resources/assets/icons/hu-flag.svg'),
  icMTFlag: require('@/resources/assets/icons/mt-flag.svg'),
  icATFlag: require('@/resources/assets/icons/at-flag.svg'),
  icPLFlag: require('@/resources/assets/icons/pl-flag.svg'),
  icSIFlag: require('@/resources/assets/icons/si-flag.svg'),
  icSKFlag: require('@/resources/assets/icons/sk-flag.svg'),
  icFIFlag: require('@/resources/assets/icons/fi-flag.svg'),
  icSEFlag: require('@/resources/assets/icons/se-flag.svg'),
  icCHFlag: require('@/resources/assets/icons/ch-flag.svg'),

  








  icESFlag: require('@/resources/assets/icons/es-flag.svg'),
  icITFlag: require('@/resources/assets/icons/it-flag.svg'),
  icPTFlag: require('@/resources/assets/icons/pt-flag.svg'),
  icDEFlag: require('@/resources/assets/icons/de-flag.svg'),
  icFRFlag: require('@/resources/assets/icons/fr-flag.svg'),
  icUSFlag: require('@/resources/assets/icons/us-flag.svg'),
  icMXFlag: require('@/resources/assets/icons/mx-flag.svg'),
  icCNFlag: require('@/resources/assets/icons/ca-flag.svg'),
  icNLFlag: require('@/resources/assets/icons/nl-flag.svg'),
  icROFlag: require('@/resources/assets/icons/ro-flag.svg'),
  icBEFlag: require('@/resources/assets/icons/be-flag.svg'),
  icUserNew: require('@/resources/assets/icons/user-new.svg'),
  icViewCollapse: require('@/resources/assets/icons/view-collapse.svg'),
  icViewExpand: require('@/resources/assets/icons/view-expand.svg'),
  icXCircle: require('@/resources/assets/icons/x-circle.svg'),
  icXCircleRed: require('@/resources/assets/icons/x-circle-red.svg'),
  icXCircleOutline: require('@/resources/assets/icons/x-circle-outline.svg'),
  icXCircleOutlineFillWhite: require('@/resources/assets/icons/x-circle-outline-fill-white.svg'),
  icXCircleOutlineRed: require('@/resources/assets/icons/x-circle-outline-red.svg'),
  icXCircleOutlineWhite: require('@/resources/assets/icons/x-circle-outline-white.svg'),
  imgCardBg: require('@/resources/assets/img/card-bg.svg'),
  imgLogoV1: require('@/resources/assets/img/logo-v1.png'),
  imgLogoV2: require('@/resources/assets/img/logo-v2.png'),
  imgLogoV3: require('@/resources/assets/img/logo-v3.png'),
  imgtruckLeftDriver: require('@/resources/assets/img/truckLeft.png'),
  imgtruckRightDriver: require('@/resources/assets/img/truckRight.png'),
  imgLogoWhite: require('@/resources/assets/img/logo-white.png'),
  imgUploadFiles: require('@/resources/assets/img/upload-files.png'),
  icSubscription: require('@/resources/assets/icons/subscription.svg'),
  icLogout: require('@/resources/assets/icons/logout.svg'),
};

export { localAssets };
