import moment from 'moment';

export default {
  getBidTotalsCrossCountry(state: any) {
    return state.bidTotalsCrossCountry;
  },
  getCurrentLegIndex(state: any) {
    return state.currentLegIndex;
  },
  getLoadDataContent(state: any) {
    return state.loadDataContent;
  },
};
