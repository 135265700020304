<template>
  <plan-default-modal
    v-if="showModifyModal"
    @close="showModifyModal = false"
    isCarrierView
    :title="$t('modify')"
    size="sm-auto"
  >
    <template v-slot:content>
      <div class="flex flex-col plan-gap-sm p-6">
        <!-- if user selected -->
        <p>{{ $t('rankingModal.modify-price') }}</p>
        <plan-input-number
          v-model:model-value="price"
          :label="$t('rankingModal.select-price')"
          placeholder="0"
        />
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end items-center plan-gap-sm">
        <plan-button
          @onClick="showModifyModal = false"
          type="cancel"
          :label="$t('cancel')"
        />
        <plan-button
          @onClick="handleEmitModifyQuote"
          type="primary"
          :label="$t('send')"
        />
      </div>
    </template>
  </plan-default-modal>

  <div
    style="box-shadow: -8px 0px 6px -5px rgba(0, 0, 0, 0.3)"
    class="overflow-x-auto z-20 h-full w-1/3 bg-white"
  >
    <table class="w-full text-left">
      <thead
        class="h-16 sticky top-0 bg-white z-10 bg-gray-50 border-b border-sky-500 border-opacity-50"
      >
        <tr>
          <th
            scope="col"
            class="min-w-[85px] text-opacity-80 pt-3 align-top truncate text-small text-gray-600 font-semibold whitespace-normal py-2 px-3 text-center lg:text-left"
          >
            <div class="text-black text-opacity-80 font-normal break-after-all">
              {{ $t('rate') }}
            </div>
          </th>
          <th
            scope="col"
            class="min-w-[85px] text-opacity-80 pt-3 align-top truncate text-small text-gray-600 font-semibold whitespace-normal py-2 px-3 text-center lg:text-left"
          >
            <div class="text-black text-opacity-80 font-normal truncate">
              {{ $t('load-assignation') }}
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody class="border-b-2 border-sky-500">
        <tr
          v-for="(element, index) in data"
          class="h-[80px]"
          :key="index"
          :class="{
            'border-sky-500 border-b-2': isLastInGroup(index),
          }"
        >
          <td class="px-5">
            <div
              class="min-w-[110px] text-black text-lg font-medium text-ellipsis overflow-hidden truncate text-center"
            >
              {{ element.quote?.price ? formatPrice(element.quote?.price) : '-' }}
            </div>
            <div class="min-w-[110px] m-auto text-center">
              <!-- BID SAVING -->
              <span
                class="text-xs rounded-3xl px-1 truncate bg-yellow-500 md:text-sm font-normal font-Nunito"
                v-if="
                  element.quote?.price &&
                  ((quotesAverageStats?.rateAverage &&
                    quotesAverageStats?.rateAverage > element.quote?.price) ||
                    (quotesAverageStats?.quoteAverage &&
                      quotesAverageStats?.quoteAverage > element.quote?.price)) &&
                  calculatedSaved(element.quote?.price) > 0
                "
                >{{ calculatedSaved(element.quote?.price) }}% {{ $t('money-saved') }}</span
              >
              <span v-else>
                {{
                  `${formatPrice(
                    +pricePerMile(element.quote.price, currentLane?.loadTemplate?.totalDistance),
                  )}/${userRegion === 'EUROPE' ? 'km' : ' mi'}`
                }}
              </span>
            </div>
          </td>
          <td class="px-5">
            <div
              v-if="element.status"
              class="text-center text-lg font-normal"
              :class="getRankingTextStyle(element.status)"
            >
              {{ $t(enumStatus(element.status).toLowerCase()) }}
            </div>
          </td>
          <td>
            <Popper
              arrow
              hover
              placement="left"
            >
              <plan-button-icon
                @click.stop
                icon="options"
                type="transparent-gray-800"
              />
              <template #content>
                <div class="flex flex-col gap-2">
                  <div @click.stop="handleShowModifyModal(element)">
                    <plan-button
                      type="white"
                      :label="$t('modify')"
                      justify-align="justify-start"
                      size="sm"
                    />
                  </div>
                </div>
              </template>
            </Popper>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Types
import { LoadStatus } from '@/services/loads/LoadsTypes';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';

export default defineComponent({
  name: 'TopOffersRightTable',
  components: {
    PlanButton,
    PlanButtonIcon,
    PlanDefaultModal,
    PlanInputNumber,
  },
  mixins: [BaseComponent],
  emits: [],
  props: {
    data: {
      type: Array,
      required: true,
    },
    isLastInGroup: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showAssignModal: false,
      showModifyModal: false,
      price: null,
      bidIdSelected: null,
      state: '',
    };
  },
  methods: {
    handleEmitModifyQuote() {
      this.$store
        .dispatch('RFPStore/updatePrice', {
          id: this.bidIdSelected,
          price: this.price,
        })
        .then(() => {
          this.showModifyModal = false;
          this.$emit('fetch');
          this.bidIdSelected = null;
        });
    },
    handleShowModifyModal(ranking) {
      this.bidIdSelected = ranking.quote.id;
      this.showModifyModal = true;
    },
    pricePerMile(quote, totalMiles) {
      return (quote / totalMiles).toFixed(2);
    },
    calculatedSaved(bid) {
      let averageToUse = this.quotesAverageStats?.rateAverage
        ? this.quotesAverageStats?.rateAverage
        : this.quotesAverageStats?.quoteAverage;
      const percentageSaved = ((averageToUse - bid) / averageToUse) * 100;
      return percentageSaved.toFixed(2);
    },
    getRankingTextStyle(status) {
      let classText = '';
      switch (status) {
        case LoadStatus.EXPIRED:
          classText = 'text-neutral-400';
          break;
        case LoadStatus.REFUSED:
          classText = 'text-red-600';
          break;
        case LoadStatus.ASSIGNING:
          classText = 'text-sky-500';
          break;
      }
      return classText;
    },
    enumStatus(status) {
      switch (status) {
        case LoadStatus.ASSIGNING:
          return this.$t('loadStatus.assigning');
        case LoadStatus.CONFIRMED:
          return this.$t('loadStatus.confirmed');
        case LoadStatus.DECLINE:
          return this.$t('loadStatus.declined');
        case LoadStatus.EXPIRED:
          return this.$t('loadStatus.expired');
        case LoadStatus.REFUSED:
          return this.$t('loadStatus.refused');
        case LoadStatus.WAITING:
          return this.$t('loadStatus.waiting');
        default:
          return 'Unknown';
      }
    },
  },
  computed: {
    userRegion() {
      return this.currentUser.company?.country?.region?.name;
    },
    quotesAverageStats() {
      return this.$store.getters['LoadsStore/quotesAverageStats'];
    },
    currentLane() {
      return this.$store.getters['RFPStore/getCurrentLane'];
    },
    isSuplanting() {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating() {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser() {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
  },
});
</script>

<style>
.text-small {
  font-size: 0.75rem;
}
</style>
