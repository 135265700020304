import { toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full xl:container flex justify-center md:justify-end items-center py-6 px-10 space-x-6"
}
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col p-6"
}
const _hoisted_3 = { class: "w-full xl:container flex flex-col items-center" }
const _hoisted_4 = { class: "w-full xl:container flex md:justify-end" }
const _hoisted_5 = {
  class: "flex items-center self-center md:mr-8",
  style: {"margin-bottom":"1rem"}
}
const _hoisted_6 = ["checked"]
const _hoisted_7 = {
  for: "link-checkbox",
  class: "ml-2 text-lg font-medium text-gray-900"
}
const _hoisted_8 = { class: "w-full" }
const _hoisted_9 = ["checked"]
const _hoisted_10 = {
  for: "book-now",
  class: "ml-2 text-lg font-medium text-gray-900"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 2,
  class: "flex flex-col mr-10"
}
const _hoisted_13 = { class: "w-full xl:container flex justify-center md:justify-end items-center py-6 space-x-6" }
const _hoisted_14 = {
  key: 0,
  class: "text-xl font-bold opacity-70"
}
const _hoisted_15 = {
  key: 3,
  class: "w-full xl:container flex justify-center md:justify-end items-center py-6 px-10 space-x-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputAmericanFormat = _resolveComponent("InputAmericanFormat")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fixed-bottom-bar bg-blue-100 z-10", {
      'flex justify-between': _ctx.currentView === _ctx.currentViewType.QUOTE,
      'fixed-bottom-bar-collapsed': _ctx.collapsedSidebar,
      'mb-10': _ctx.isSuplanting !== '',
    }])
  }, [
    (_ctx.currentView === _ctx.currentViewType.DATA || _ctx.currentView === _ctx.currentViewType.FREIGHT_INFO)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("a", {
            id: "directAssign",
            class: _normalizeClass(["cursor-pointer flex px-4 md:px-8 py-2 rounded-md text-black border border-corp text-center hover:opacity-70", { 'cursor-not-allowed pointer-events-none': _ctx.isAssigning }]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickAssignCarrier && _ctx.onClickAssignCarrier(...args)))
          }, _toDisplayString(_ctx.isAssigning ? `${_ctx.$t('creating')}...` : _ctx.$t('assign-carrier')), 3), [
            [_vShow, 
          !_ctx.isAdditionalStops || (_ctx.currentView === _ctx.currentViewType.FREIGHT_INFO && _ctx.isAdditionalStops)
        ]
          ]),
          _createElementVNode("a", {
            id: "continueButtonToQuote",
            class: _normalizeClass(["bg-confirm cursor-pointer flex px-4 md:px-8 py-2 rounded-md text-white border border-confirm text-center font-bold hover:opacity-70", { 'cursor-not-allowed pointer-events-none hover:bg-green-300': _ctx.isCreating }]),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickContinue && _ctx.onClickContinue(...args)))
          }, _toDisplayString(_ctx.isCreating ? `${_ctx.$t('creating')}...` : _ctx.$t('continue')), 3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.currentView === _ctx.currentViewType.QUOTE)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _withDirectives(_createElementVNode("input", {
                  id: "link-checkbox",
                  type: "checkbox",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isCheckedShowQuotes) = $event)),
                  checked: _ctx.isCheckedShowQuotes,
                  value: "",
                  class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                }, null, 8, _hoisted_6), [
                  [_vModelCheckbox, _ctx.isCheckedShowQuotes]
                ]),
                _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('show-bids-carrier')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                id: "book-now",
                type: "checkbox",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isBookNow) = $event)),
                checked: _ctx.isBookNow,
                value: "",
                class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
              }, null, 8, _hoisted_9), [
                [_vModelCheckbox, _ctx.isBookNow]
              ]),
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('book-now')), 1)
            ])
          ]),
          (_ctx.isBookNow)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_Form, { ref: "form" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Field, {
                      name: "BookNow",
                      rules: "required|integer|min_value:1|max_value:2147483647",
                      modelValue: _ctx.bookNow,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.bookNow) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_InputAmericanFormat, {
                          modelValue: _ctx.bookNow,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.bookNow) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(_component_ErrorMessage, {
                      name: "BookNow",
                      class: "text-rose-500"
                    })
                  ]),
                  _: 1
                }, 512)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.currentView === _ctx.currentViewType.QUOTE)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            (_ctx.selectedContacts.length > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t('request-quote-number-carriers', { count: _ctx.selectedContacts.length })), 1))
              : _createCommentVNode("", true),
            _createElementVNode("a", {
              id: "continueButtonSendQuotes",
              class: _normalizeClass(["bg-confirm cursor-pointer flex px-4 md:px-8 py-2 rounded-md text-white border border-confirm text-center font-bold hover:opacity-70", {
            'disable-button': _ctx.selectedContacts.length === 0,
            'pointer-events-none': _ctx.isSendingQuotes || _ctx.petitionSent,
          }]),
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onClickContinueQuote && _ctx.onClickContinueQuote(...args)))
            }, _toDisplayString(_ctx.isSendingQuotes || _ctx.petitionSent ? _ctx.$t('continue-action') : _ctx.$t('continue')), 3)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.currentView === _ctx.currentViewType.CARRIER)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("a", {
            class: _normalizeClass(["bg-confirm cursor-pointer flex px-4 md:px-8 py-2 rounded-md text-white border border-confirm text-center font-bold hover:opacity-70", { 'cursor-not-allowed pointer-events-none': _ctx.isSendingDirectAssign }]),
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onClickSendDirectAssign && _ctx.onClickSendDirectAssign(...args)))
          }, _toDisplayString(_ctx.isSendingDirectAssign ? _ctx.$t('continue-action') : _ctx.$t('continue')), 3)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}