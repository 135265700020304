
import { computed, defineComponent, onBeforeMount, reactive, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import FileContainer from '@/modules/planning/contacts/ContactsImport/ImportFileContainer.vue';
import ContactsTable from '@/modules/planning/contacts/ContactsImport/ContactsTable.vue';
import store from '@/services/store';
import PlanDefaultView from '@/components/defaults/PlanDefaultView.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useI18n } from 'vue-i18n';
import * as Routing from '@/router/Routing';
import PlanDefaultConfirmationModal from '@/components/defaults/PlanDefaultConfirmationModal.vue';

export default defineComponent({
  name: 'ImportBase',
  components: {
    PlanDefaultConfirmationModal,
    PlanButton,
    PlanTextButton,
    PlanDefaultView,
    FileContainer,
    ContactsTable,
  },
  props: { projectId: Number },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const translate = useI18n();

    const data = reactive({
      loadingImportSuccessContacts: false,
      loadingPostAll: false,
      quantities: {
        success: 0,
        warning: 0,
        update: 0,
      },
      showDiscardModal: false,
      showOmitModal: false,
    });

    onBeforeMount(() => {
      store.commit('UserStore/setCurrentContacts', []);
    });

    const currentContacts = computed(() => {
      return store.getters['UserStore/getCurrentContacts'];
    });

    const cleanCurrentContacts = () => {
      store.commit('UserStore/setCurrentContacts', []);
    };

    const importContacts = () => {
      if (data.quantities.warning === 0) {
        sendContacts();
      } else {
        data.showOmitModal = true;
      }
    };

    const omitInvalidsContacts = async () => {
      data.showOmitModal = false;
      data.loadingImportSuccessContacts = true;
      let result: Array<any> = [];
      if (currentContacts.value) {
        currentContacts.value.forEach((contact: any, index: number) => {
          if (contact.validation.passed) {
            result.push(Object.assign({}, contact));
          } else {
            if (contact.validation.errorCodes.includes(8)) {
              result.push(Object.assign({}, contact));
            }
          }
        });
      }

      await store.dispatch('UserStore/createContactsFromJson', result).then((response) => {
        if (response === 'async') {
          store.commit('UserStore/setImportingContacts', true);
          router.push('/planning/contacts');
        } else {
          store.dispatch('UserStore/updateToast', {
            text: translate.t('created-successfully'),
            type: 'success',
            visible: true,
          });
          router.push('/planning/contacts');
        }
      });

      data.loadingImportSuccessContacts = false;
    };

    const sendContacts = async () => {
      data.loadingPostAll = true;
      await store
        .dispatch('UserStore/createContactsFromJson', currentContacts.value)
        .then((response) => {
          if (response !== 'async') {
            store.dispatch('UserStore/updateToast', {
              text: translate.t('created-successfully'),
              type: 'success',
              visible: true,
            });
            router.push('/planning/contacts');
          } else {
            store.commit('UserStore/setImportingContacts', true);
            router.push('/planning/contacts');
          }
        });
      data.loadingPostAll = false;
    };

    const updateQuantities = (quantities: any) => {
      data.quantities = quantities;
    };

    return {
      ...toRefs(data),
      cleanCurrentContacts,
      currentContacts,
      importContacts,
      omitInvalidsContacts,
      Routing,
      sendContacts,
      updateQuantities,
    };
  },
});
