
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierLoadHeader from '@/components/carrier/PlanCarrierLoadHeader.vue';
import PlanCarrierLoadSubHeader from '@/components/carrier/PlanCarrierLoadSubHeader.vue';
import PlanCarrierLoadHeaderNotification from '@/components/carrier/PlanCarrierLoadHeaderNotification.vue';
import PlanCarrierLoadDetails from '@/components/carrier/loadDetail/PlanCarrierLoadDetails.vue';
import PlanCarrierLoadStopData from '@/components/carrier/loadDetail/PlanCarrierLoadStopData.vue';
import PlanCarrierLoadBid from '@/components/carrier/_quoting/PlanCarrierLoadBid.vue';
import { useStore } from 'vuex';
import PlanCarrierLoadingFullScreen from '@/components/carrier/PlanCarrierLoadingFullScreen.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';

interface carrierBase {
  load: any;
  loadingLoad: boolean;
}

export default defineComponent({
  name: 'CarrierBase',
  components: {
    PlanCarrierLoadingFullScreen,
    PlanCarrierLoadHeaderNotification,
    PlanCarrierLoadStopData,
    PlanCarrierLoadDetails,
    PlanCarrierLoadSubHeader,
    PlanCarrierLoadHeader,
    PlanDefaultCarrierView,
  },
  props: {
    id: { type: String, required: false, default: null },
    hash: { type: String, required: false, default: null },
    code: { type: String, required: false, default: null },
  },
  setup(props) {
    const store = useStore();

    const data = reactive<carrierBase>({
      load: null as any,
      loadingLoad: false,
    });

    const stopsData = computed(() => {
      let result: Array<any> = [];

      loadData.value.stops.forEach((stop: any, index: number) => {
        let toCity = stop.location.city;
        if (index < loadData.value.stops.length - 1) {
          toCity = loadData.value.stops[index + 1].location.city;
        }

        result.push({
          stop: stop,
          toCity: toCity,
        });
      });

      return result;
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const getData = () => {
      data.loadingLoad = true;
      store.dispatch('CarrierStore/getLoadInfoForCarrier', 16133).then((response) => {
        data.loadingLoad = false;
      });
    };

    const loginUser = () => {
      store
        .dispatch('UserStore/hashValidation', {
          hashId: props.hash,
          secureCode: props.code,
        })
        .then(() => {
          getData();
        });
    };

    loginUser();

    return {
      ...toRefs(data),
      stopsData,
      loadData,
    };
  },
});
