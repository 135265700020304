import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c2b0809c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col gap-4 mb-4 transition-all duration-5" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col gap-4 plan-pt-md"
}
const _hoisted_3 = { class: "flex justify-between items-start gap-2" }
const _hoisted_4 = {
  class: "flex justify-start items-center plan-gap-xs box-border flex-wrap",
  style: {"width":"100%","position":"relative","z-index":"9200"}
}
const _hoisted_5 = { class: "flex justify-center items-center plan-gap-sm" }
const _hoisted_6 = {
  key: 0,
  class: "flex flex-col justify-center items-center"
}
const _hoisted_7 = { class: "flex justify-between gap-2 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_filter_pickup_and_delivery = _resolveComponent("plan-filter-pickup-and-delivery")!
  const _component_plan_filter_ltl_ftl_selector = _resolveComponent("plan-filter-ltl-ftl-selector")!
  const _component_plan_filter = _resolveComponent("plan-filter")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_badge = _resolveComponent("plan-badge")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-col",
    style: {"max-width":"1200px","margin":"0 auto"},
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isHover = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHover = false))
  }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          (_ctx.activeQuickFilter === '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_plan_filter_pickup_and_delivery, { onStatus: _ctx.changeFilterStatus }, null, 8, ["onStatus"]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.currentCountry?.iso === 'US')
                      ? (_openBlock(), _createBlock(_component_plan_filter_ltl_ftl_selector, {
                          key: 0,
                          onChange: _ctx.changeFilterStatus
                        }, null, 8, ["onChange"]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planFilters, (planFilter) => {
                      return (_openBlock(), _createBlock(_component_plan_filter, {
                        key: planFilter,
                        onStatus: _ctx.changeFilterStatus,
                        id: planFilter.id,
                        icon: planFilter.icon,
                        title: planFilter.title,
                        placeholder: planFilter.placeholder,
                        "min-input-width": planFilter.min,
                        items: planFilter.items
                      }, null, 8, ["onStatus", "id", "icon", "title", "placeholder", "min-input-width", "items"]))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_plan_button, {
                      onOnClick: _ctx.cleanFilters,
                      "show-icon": "",
                      icon: "reset",
                      "icon-type": "svg",
                      "icon-color": "#0097FF",
                      "icon-size": "16",
                      type: "primary-light",
                      label: _ctx.$t('clear-filters')
                    }, null, 8, ["onOnClick", "label"])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 512), [
          [_vShow, _ctx.showFilters]
        ])
      ]),
      _: 1
    }),
    (!_ctx.showFilters)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (!_ctx.showFilters)
            ? (_openBlock(), _createBlock(_component_plan_button, {
                key: 0,
                onOnClick: _ctx.changeScrollStatus,
                label: _ctx.activeFiltersTitle,
                type: "white"
              }, {
                buttonContentRight: _withCtx(() => [
                  _createVNode(_component_plan_icon, {
                    name: _ctx.showFilters ? 'chevron-up' : 'chevron-down',
                    "is-svg": "",
                    "icon-color": "black",
                    size: "16"
                  }, null, 8, ["name"])
                ]),
                _: 1
              }, 8, ["onOnClick", "label"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            (_ctx.pickUpDates)
              ? (_openBlock(), _createBlock(_component_plan_badge, {
                  key: 0,
                  type: "primary",
                  content: `${_ctx.$t('pickup')}: ${_ctx.formatDate(_ctx.pickUpDates.split('#')[0])} - ${_ctx.formatDate(
            _ctx.pickUpDates.split('#')[1],
          )}`
                }, null, 8, ["content"]))
              : _createCommentVNode("", true),
            (_ctx.deliveryDates)
              ? (_openBlock(), _createBlock(_component_plan_badge, {
                  key: 1,
                  type: "primary",
                  content: `${_ctx.$t('delivery')}: ${_ctx.formatDate(_ctx.deliveryDates.split('#')[0])} - ${_ctx.formatDate(
            _ctx.deliveryDates.split('#')[1],
          )}`
                }, null, 8, ["content"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 32))
}