import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "plan-t-prim-100" }
const _hoisted_3 = { class: "w-full flex flex-col plan-gap-md" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex flex-col"
}
const _hoisted_5 = { class: "plan-t-xs plan-t-prim-100 font-semibold" }
const _hoisted_6 = { class: "flex justify-start items-center plan-gap-sm flex-wrap" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_PlanButton = _resolveComponent("PlanButton")!
  const _component_plan_thumbnail = _resolveComponent("plan-thumbnail")!
  const _component_plan_input_file = _resolveComponent("plan-input-file")!
  const _component_plan_carrier_default_modal = _resolveComponent("plan-carrier-default-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isUploaded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["flex justify-start plan-gap-sm", _ctx.actualWidth <= 640 ? 'flex-col items-start' : 'items-center'])
        }, [
          _createVNode(_component_plan_text_button, {
            onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true)),
            label: _ctx.getLabelsByRegion.uploaded,
            icon: "check",
            "icon-size": "16"
          }, null, 8, ["label"]),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('on-date')) + " " + _toDisplayString(_ctx.$t(_ctx.getFormattedDateHour(_ctx.stop?.mediaObjects[0]?.updatedAt))), 1)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-row plan-gap-sm justify-start items-start", _ctx.actualWidth <= 640 ? 'flex-col' : ''])
    }, [
      (!_ctx.isUploaded && _ctx.isNotification)
        ? (_openBlock(), _createBlock(_component_plan_button, {
            key: 0,
            onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = true)),
            type: "primary",
            label: _ctx.getLabelsByRegion.upload,
            class: _normalizeClass(_ctx.actualWidth <= 640 ? 'w-full' : '')
          }, null, 8, ["label", "class"]))
        : _createCommentVNode("", true),
      (!_ctx.isDriver && !_ctx.isUploaded && _ctx.isNotification && _ctx.loadData?.shipment?.driver?.email)
        ? (_openBlock(), _createBlock(_component_PlanButton, {
            key: 1,
            onOnClick: _ctx.handleShowRequestModal,
            type: "primary",
            label: _ctx.$t('driver-request-data'),
            class: _normalizeClass(_ctx.actualWidth <= 640 ? 'w-full' : '')
          }, null, 8, ["onOnClick", "label", "class"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_plan_carrier_default_modal, {
          key: 1,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModal = false)),
          title: _ctx.titleAndSubtitle.title,
          subtitle: _ctx.titleAndSubtitle.subtitle,
          size: "sm-auto"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.isUploaded)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.stop.mediaObjects.length) + " " + _toDisplayString(_ctx.$t('uploaded-files')) + ": ", 1),
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stop.mediaObjects, (mediaObject) => {
                        return (_openBlock(), _createBlock(_component_plan_thumbnail, {
                          key: mediaObject.id,
                          id: mediaObject.id,
                          "entity-class": mediaObject.entityClass
                        }, null, 8, ["id", "entity-class"]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isUploaded)
                ? (_openBlock(), _createElementBlock("hr", _hoisted_7))
                : _createCommentVNode("", true),
              _createVNode(_component_plan_input_file, {
                onChange: _ctx.changeFiles,
                types: ['application/pdf', 'image/*'],
                maxSize: 10,
                multiple: ""
              }, null, 8, ["onChange"])
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_plan_button, {
                onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModal = false)),
                disabled: _ctx.loading,
                type: "cancel",
                label: _ctx.$t('cancel')
              }, null, 8, ["disabled", "label"]),
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.postUploadCmr,
                type: "primary",
                loading: _ctx.loading,
                label: _ctx.$t('send'),
                disabled: _ctx.files.length === 0,
                style: {"min-width":"100px"}
              }, null, 8, ["onOnClick", "loading", "label", "disabled"])
            ])
          ]),
          _: 1
        }, 8, ["title", "subtitle"]))
      : _createCommentVNode("", true)
  ]))
}