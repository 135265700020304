import loadDetail from '@/services/loadDetail/_api';
// Resources
import i18n from '@/resources/locales';
const $t = i18n.global.t;

export default {
  async getBidTotalsCrossCountry(context: any, data: object) {
    const response = await loadDetail.getBidTotalsCrossCountry(data);
    context.commit('setBidTotalsCrossCountry', response);
    return response;
  },
};
