
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import SkeletonUnreadMessage from '@/components/skeletons/UnreadMessageSkeleton.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'UnreadMessages',
  components: {
    SkeletonUnreadMessage,
    Spin,
    PlanIcon,
  },
  mixins: [BaseComponent],
  data() {
    return {
      isLoading: false,
      page: 1,
      previouslyLoaded: false,
    };
  },
  beforeCreate() {
    this.isLoading = !(this.unreadMessages?.length > 0);
    this.$store
      .dispatch('ChatsStore/getUnreadMessages', { page: 1, maxResults: this.maxResults && 30 })
      .finally(() => {
        this.isLoading = false;
        this.previouslyLoaded = true;
      });
  },
  computed: {
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    hasMoreItems(): any {
      return this.page * this.maxResults <= this.unreadMessages.length;
    },
    maxResults(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']
        ? parseInt(
            this.$store.getters['GlobalStore/getGlobalConfig'].PAGINATION_DEFAULT_MAX_RESULTS,
          )
        : 30;
    },
    unreadMessages(): any {
      return this.$store.state.ChatsStore.unreadMessages;
    },
  },
  methods: {
    fetchUnreadMessages() {
      this.isLoading = true;
      for (let i = 1; i <= this.page; i++) {
        this.$store
          .dispatch('ChatsStore/getUnreadMessages', {
            page: i,
            maxResults: this.maxResults,
          })
          .finally(() => (this.isLoading = false));
      }
    },
    finalDeliveryStop(load: any): any {
      let finalStop = null;
      load.stops?.forEach((stop: any) => {
        if (stop.type === 'DELIVERY_FINAL') {
          finalStop = stop;
        }
      });
      return finalStop;
    },
    getUnreadMessages(pagination: boolean) {
      pagination && ++this.page;
      this.isLoading = true;
      this.$store
        .dispatch('ChatsStore/getUnreadMessages', {
          page: this.page,
          maxResults: this.maxResults,
        })
        .finally(() => (this.isLoading = false));
    },
    initialPickUpStop(load: any): any {
      let initialStop = null;
      load.stops?.forEach((stop: any) => {
        if (stop.type === 'PICKUP_INITIAL') {
          initialStop = stop;
        }
      });
      return initialStop;
    },
    openChat(chat) {
      this.$store.commit('GlobalStore/setOpenChat', true);
      this.$store.commit('ChatsStore/setChatId', chat.id);
      console.log(
        'chat.chatMembers.find((member) => member.users.id !== this.currentUser.id): ',
        chat.chatMembers.find((member) => member.users.id !== this.currentUser.id),
      );
      if (chat?.chatMembers?.length >= 1) {
        this.$store.commit(
          'ChatsStore/setUserForChat',
          chat.chatMembers.find((member) => member.users.id !== this.currentUser.id).users,
        );
      }
      if (chat.load) {
        this.$store.commit('LoadsStore/loadId', chat.load.id);
      }
    },
    setChatToRead(chat) {
      this.$store.dispatch('ChatsStore/setChatToRead', chat.id).then(() => {
        this.fetchUnreadMessages();
        this.$store.dispatch('ChatsStore/getTotalUnreadChats');
        // (document.getElementById('check' + chat.id) as any).checked = false;
      });
    },
  },
});
