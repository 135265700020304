
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
export default defineComponent({
  name: 'PlanLoadDirectAssignFooter',
  components: { PlanButton },
  emits: ['continue'],
  setup (props, {emit}) {
    const store = useStore()

    const directAssignData = computed(() => {
      return store.getters['NewFtlLoad/getDirectAssign']
    })

    const emitContinue = () => { emit('continue') }

    const isDirectAssignValid = computed(() => {
      let price = true
      let company = true
      if (directAssignData.value === null) {
        price = false
        company = false
      } else if (directAssignData.value.price === null) {
        price = false
      } else if (directAssignData.value.price < 0) {
        price = false
      } else if (directAssignData.value.company.id === null) {
        company = false
      }
      return {
        price: price,
        company: company,
        result: price && company
      }
    })

    return {
      emitContinue,
      directAssignData,
      isDirectAssignValid
    }
  }
})
