
import PlanDefaultView from '@/components/defaults/PlanDefaultView.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonGroup from '@/components/buttons/PlanButtonGroup.vue';
import PlanTabs from '@/components/tabs/PlanTabs.vue';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  toRefs,
  computed,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import PlanningFilters from '@/components/filters/PlanningFilters.vue';
import LoadFilterByIdPo from '@/components/filters/LoadFilterByIdPo.vue';
import LoadCard from '@/components/loads/card/LoadCard.vue';
import PlanLoading from '@/components/loadings/PlanLoading.vue';
import { useI18n } from 'vue-i18n';
import SortLoadsBy from '@/components/loads/SortLoadsBy.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import LoadHeaderButtons from '@/components/loads/LoadHeaderButtons.vue';
import LoadDetail from '@/components/loads/detail/LoadDetail.vue';
import PlanLoadsOrderFilter from '@/components/filters/PlanLoadsOrderFilter.vue';
import { useRoute, useRouter } from 'vue-router';
import PlanDefaultDeleteModal from '@/components/defaults/PlanDefaultDeleteModal.vue';
import PlanDefaultConfirmationModal from '@/components/defaults/PlanDefaultConfirmationModal.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import ShipperLoadDetailContent from '@/modules/planning/loadDetail/ShipperLoadDetailContent.vue';
import PlanCalendar from '@/components/forms/PlanCalendar.vue';
import PlanBadge from '@/components/badges/PlanBadge.vue';
import LoadsCalendar from '@/components/loads/LoadsCalendar.vue';
import moment from 'moment';
import { debounce } from 'lodash';
import QuickFilters from '@/components/filters/QuickFilters.vue';
import PlanFilter from '@/components/filters/PlanFilter.vue';
import i18n from '@/resources/locales';
import { onBeforeMount } from 'vue';

export default defineComponent({
  name: 'PlanificationView',
  components: {
    PlanFilter,
    QuickFilters,
    LoadsCalendar,
    ShipperLoadDetailContent,
    PlanInputText,
    PlanDefaultModal,
    PlanDefaultConfirmationModal,
    PlanDefaultDeleteModal,
    PlanLoadsOrderFilter,
    LoadHeaderButtons,
    PlanLoading,
    LoadCard,
    LoadFilterByIdPo,
    PlanningFilters,
    PlanDefaultView,
    PlanButton,
    PlanTabs,
  },
  props: {
    id: { type: String, required: false, default: '' },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const translate = useI18n();

    const data = reactive({
      activeCollection: '',
      activeDelivery: '',
      appliedFilters: [],
      displayMode: 'cards',
      firstLoading: false,
      isEditable: false,
      loads: [],
      loadId: '',
      loadsTotals: {
        TOTAL: 0,
        FINISHED: 0,
        QUOTING: 0,
        CONFIRMED: 0,
        IN_PROGRESS: 0,
        ASSIGNED: 0,
        CANCELED: 0,
      },
      loadingLoads: false,
      loadingCancel: false,
      loadingDelete: false,
      loadingNewPageOfLoads: false,
      loadingTemplate: false,
      minimize: false,
      page: 1,
      showLoadDetails: false,
      selectedTotalTab: { index: 0, loadParamFilter: '' },
      showModalCancel: -1,
      showModalDelete: -1,
      showModalTemplate: -1,
      showModalUpdate: -1,
      templateName: '',
      totalDates: [],
    });

    onBeforeMount(() => {
      store.commit('LoadsStore/setLoadsInfo', []);
    });

    const activeQuickFilter = computed(() => {
      return store.getters['LoadsStore/getSelectedQuickFilter'];
    });

    const loadsInfo = computed(() => {
      return store.getters['LoadsStore/getLoadsInfo'];
    });

    const maxDateFilter = computed(() => {
      const maxFromFilter =
        store.getters['LoadsStore/getAppliedFiltersQuery'].pickupInitialDateBefore;
      if (maxFromFilter) {
        return maxFromFilter;
      }
      return null;
    });

    const minDateFilter = computed(() => {
      const minFromFilter =
        store.getters['LoadsStore/getAppliedFiltersQuery'].pickupInitialDateAfter;
      if (minFromFilter) {
        return minFromFilter;
      }
      return null;
    });

    const totalTabs = computed(() => {
      return [
        { label: translate.t('total'), quantity: data.loadsTotals.TOTAL },
        { label: translate.t('quoting'), quantity: data.loadsTotals.QUOTING },
        { label: translate.t('confirmed'), quantity: data.loadsTotals.CONFIRMED },
        { label: translate.t('assigned'), quantity: data.loadsTotals.ASSIGNED },
        { label: translate.t('in_progress'), quantity: data.loadsTotals.IN_PROGRESS },
        { label: translate.t('finished'), quantity: data.loadsTotals.FINISHED },
        { label: translate.t('canceled'), quantity: data.loadsTotals.CANCELED },
      ];
    });

    const totalToShow = computed(() => {
      return totalTabs.value[data.selectedTotalTab.index].quantity;
    });

    const applyFilters = () => {
      data.loads = [];
      data.page = 1;
      getLoadsInfo();
    };

    const debouncedApplyFilters = debounce(applyFilters, 500);

    const applyFilterByIdPo = () => {
      debouncedApplyFilters();
    };

    const cancelLoad = () => {
      data.loadingCancel = true;
      store.dispatch('LoadsStore/cancelLoad', data.showModalCancel).then(() => {
        getLoadsInfo();
        data.showModalCancel = -1;
        data.loadingCancel = false;
        store.dispatch('UserStore/showToast', translate.t('success-canceled-load'));
      });
    };

    const changeDisplayMode = (mode: string) => {
      store.commit('LoadsStore/setPlanningType', mode);
      data.minimize = mode === 'calendar';
      data.displayMode = mode;
    };

    const changeTab = (indexTab: number) => {
      data.loads = [];
      data.page = 1;
      data.firstLoading = false;
      data.selectedTotalTab.index = indexTab;
      if (indexTab === 0) data.selectedTotalTab.loadParamFilter = '';
      if (indexTab === 1) data.selectedTotalTab.loadParamFilter = 'QUOTING';
      if (indexTab === 2) data.selectedTotalTab.loadParamFilter = 'CONFIRMED';
      if (indexTab === 3) data.selectedTotalTab.loadParamFilter = 'ASSIGNED';
      if (indexTab === 4) data.selectedTotalTab.loadParamFilter = 'IN_PROGRESS';
      if (indexTab === 5) data.selectedTotalTab.loadParamFilter = 'FINISHED';
      if (indexTab === 6) data.selectedTotalTab.loadParamFilter = 'CANCELED';
      store.commit('LoadsStore/applyFilter', {
        id: 'STATUS',
        value: data.selectedTotalTab.loadParamFilter,
        loadFilterParam: data.selectedTotalTab.loadParamFilter,
      });
      getLoadsInfo();
    };

    const createTemplate = () => {
      data.loadingTemplate = true;
      store
        .dispatch('LoadsStore/postSaveLoads', {
          load: { id: data.showModalTemplate },
          user: { id: store.getters['UserStore/getCurrentUser'].id },
          templateName: data.templateName,
        })
        .then(() => {
          data.loadingTemplate = false;
          store.dispatch('UserStore/showToast', translate.t('message-load-template-created'));
          data.templateName = '';
          data.showModalTemplate = -1;
        });
    };

    const currentUserLocale = computed(() => {
      return i18n.global.locale.value;
    });

    const deleteLoad = () => {
      data.loadingDelete = true;
      store.dispatch('LoadsStore/deleteLoad', data.showModalDelete).then(() => {
        getLoadsInfo();
        data.showModalDelete = -1;
        data.loadingDelete = false;
        store.dispatch('UserStore/showToast', translate.t('success-delete-load'));
      });
    };

    const getLoadsInfo = async () => {
      await store.dispatch('LoadsStore/getTotalClaims');
      let query = store.getters['LoadsStore/getAppliedFiltersQuery'];

      query.page = data.page;
      query.maxResults = 10;
      query.infoType = ['LOADS', 'TOTALS', 'TOTALS_DAY_WEEK', 'TOTALS_DATES'];
      if (data.page === 1) {
        store.commit('LoadsStore/setLoadsInfo', []);
      }

      data.loadingLoads = true;

      await store
        .dispatch('LoadsStore/getLoadsInfo', query)
        .then((response) => {
          if (data.page === 1) {
            data.loads = response.data.loads;
          } else {
            data.loads = data.loads.concat(response.data.loads);
          }

          data.loadsTotals.TOTAL = 0;
          data.loadsTotals.FINISHED = 0;
          data.loadsTotals.QUOTING = 0;
          data.loadsTotals.CONFIRMED = 0;
          data.loadsTotals.IN_PROGRESS = 0;
          data.loadsTotals.ASSIGNED = 0;
          data.loadsTotals.CANCELED = 0;

          response.data.totals.forEach(function (total: any) {
            if (total.status === 'TOTAL') data.loadsTotals.TOTAL = total.total;
            if (total.status === 'FINISHED') data.loadsTotals.FINISHED = total.total;
            if (total.status === 'QUOTING') data.loadsTotals.QUOTING = total.total;
            if (total.status === 'CONFIRMED') data.loadsTotals.CONFIRMED = total.total;
            if (total.status === 'IN_PROGRESS') data.loadsTotals.IN_PROGRESS = total.total;
            if (total.status === 'ASSIGNED') data.loadsTotals.ASSIGNED = total.total;
            if (total.status === 'CANCELED') data.loadsTotals.CANCELED = total.total;
          });

          data.totalDates = response.data.totalsDates;
          data.firstLoading = true;
        })
        .catch((err) => {
          data.loadingLoads = false;
          data.loadingNewPageOfLoads = false;
        });

      data.loadingLoads = false;
      data.loadingNewPageOfLoads = false;
    };

    const goToDay = (date: any) => {
      let query = store.getters['LoadsStore/getAppliedFiltersQuery'];

      router.push(
        '/planning/day/' + date.split('-')[2] + '-' + date.split('-')[1] + '-' + date.split('-')[0],
      );
    };

    const hideShipperLoadDetailContent = () => {
      data.showModalUpdate = -1;
      data.loadId = '';
      router.push({ name: 'planning', params: { id: null } });
    }

    const loadMoreLoads = () => {
      data.loadingNewPageOfLoads = true;
      data.page += 1;
      getLoadsInfo();
    };

    const onOptionsClick = (params: any) => {
      if (params.type === 'cancel') {
        data.showModalCancel = params.id;
      } else if (params.type === 'delete') {
        data.showModalDelete = params.id;
      } else if (params.type === 'clone') {
        store.commit('LoadsStore/currentLoadEditing', null);
        store.dispatch('LoadsStore/currentLoadEditing', params.load);
        store.dispatch('LoadsStore/loadId', params.id);
        store.dispatch('LoadsStore/loadById', params.id);
        router.push('/planning/newLoad');
      } else if (params.type === 'createTemplate') {
        data.showModalTemplate = params.id;
        data.templateName = '';
      } else if (params.type === 'modify') {
        data.loadId = params.id;
        data.showModalUpdate = params.id;
        data.isEditable = true;
      }
    };

    const routerToDetail = (loadId: any) => {
      store.commit('LoadsStore/setTemporalLoadId', loadId);

      if (route.fullPath.includes('superadmin')) {
        router.push({ name: 'loadDetailAdmin', params: { id: loadId } });
      } else {
        if (route.name === 'LaneDetail') {
          router.push({ name: 'laneService', params: { id: loadId } });
        } else if (route.name === 'RFP-laneDetail') {
          router.push({ name: 'RFP-laneService', params: { id: loadId } });
        } else if (route.name === 'RFP-laneDay') {
          router.push({ name: 'RFP-dayDetailId', params: { id: loadId } });
        } else {
          store.commit('GlobalStore/setSectionFromPlanification', true);
          router.push({
            name: 'planificationDetail',
            params: { id: loadId, direct: 'true' },
          });
        }
      }
    };

    const showLoadDetails = (id) => {
      data.loadId = id;
      data.isEditable = false;
      router.push({ name: 'planificationDetail', params: { id: id } });
    };
    getLoadsInfo();

    const tabTotalsMap = {
      QUOTING: 1,
      CONFIRMED: 2,
      ASSIGNED: 3,
      IN_PROGRESS: 4,
      FINISHED: 5,
      CANCELED: 6,
    };

    const selectedTotalKey =
      tabTotalsMap[store.getters['LoadsStore/getAppliedFilters']['STATUS']?.value];

    data.selectedTotalTab = {
      index: selectedTotalKey || 0,
      loadParamFilter: store.getters['LoadsStore/getAppliedFilters']['STATUS']?.value || '',
    };

    if (props.id.length > 0) {
      data.loadId = props.id + '';
      data.isEditable = false;
    }

    data.displayMode = store.getters['LoadsStore/getPlanningType'];

    watch(
      () => route.params.id,
      (newId) => {
        if (newId) {
          data.loadId = newId + '';
          data.isEditable = false;
        }
      },
      { immediate: true },
    );

    return {
      ...toRefs(data),
      activeQuickFilter,
      currentUserLocale,
      loadsInfo,
      maxDateFilter,
      minDateFilter,
      totalTabs,
      totalToShow,
      applyFilters,
      applyFilterByIdPo,
      cancelLoad,
      changeDisplayMode,
      changeTab,
      createTemplate,
      deleteLoad,
      goToDay,
      hideShipperLoadDetailContent,
      loadMoreLoads,
      showLoadDetails,
      onOptionsClick,
      routerToDetail,
    };
  },
});
