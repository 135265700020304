import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex justify-between items-start plan-gap-sm plan-pt-sm plan-pb-sm" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "flex justify-start items-center plan-gap-sm font-bold plan-t-prim-500 plan-t-h4" }
const _hoisted_4 = { class: "truncate" }
const _hoisted_5 = { class: "font-normal plan-t-prim-100 plan-t-xs" }
const _hoisted_6 = { class: "truncate" }
const _hoisted_7 = { class: "font-normal plan-t-prim-100 plan-t-xs" }
const _hoisted_8 = { class: "flex justify-start items-center plan-gap-md plan-t-xs" }
const _hoisted_9 = { class: "flex justify-start items-center plan-gap-xs plan-t-prim-100" }
const _hoisted_10 = { class: "truncate" }
const _hoisted_11 = { class: "flex justify-start items-center plan-gap-xs plan-t-prim-100" }
const _hoisted_12 = { class: "truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.loadData.pickup) + " ", 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.loadData.pickupDate), 1)
        ]),
        _createVNode(_component_plan_icon, {
          name: "plan-arrow-right",
          "icon-color": "#151830",
          "is-svg": ""
        }),
        _createElementVNode("p", _hoisted_6, [
          _createTextVNode(_toDisplayString(_ctx.loadData.delivery) + " ", 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.loadData.deliveryDate), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_plan_icon, {
            name: "icInRoute",
            "icon-color": "#8A8B97",
            size: "15",
            "is-svg": ""
          }),
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.loadData.trailer), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_plan_icon, {
            name: "package",
            "icon-color": "#8A8B97",
            size: "15",
            "is-svg": ""
          }),
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.loadData.commodities), 1)
        ])
      ])
    ]),
    _createVNode(_component_plan_text_button, {
      onOnClick: _ctx.update,
      label: _ctx.$t('update')
    }, null, 8, ["onOnClick", "label"])
  ]))
}