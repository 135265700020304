import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full rounded border plan-border-prim-50 plan-p-md plan-gap-md flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "plan-t-xs font-bold plan-t-prim-200"
}
const _hoisted_3 = {
  key: 1,
  class: "flex justify-start items-center plan-gap-lg"
}
const _hoisted_4 = { class: "plan-t-xs font-semibold plan-t-prim-200 plan-rounded-xs plan-b-gray-100 plan-pt-xs plan-pb-xs plan-pr-sm plan-pl-sm" }
const _hoisted_5 = { class: "w-full flex flex-col" }
const _hoisted_6 = {
  key: 0,
  class: "plan-t-xxs plan-t-red-500 text-right"
}
const _hoisted_7 = {
  class: "flex flex-col",
  style: {"min-width":"200px"}
}
const _hoisted_8 = {
  key: 0,
  class: "plan-t-xxs plan-t-red-500 text-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_radio = _resolveComponent("plan-input-radio")!
  const _component_plan_input_load_location = _resolveComponent("plan-input-load-location")!
  const _component_plan_input_calendar = _resolveComponent("plan-input-calendar")!
  const _component_warehouse_in_stop_data = _resolveComponent("warehouse-in-stop-data")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type === 'PICKUP_INITIAL' || _ctx.type === 'DELIVERY_FINAL')
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.typeToShow), 1))
      : _createCommentVNode("", true),
    (_ctx.type === 'PICKUP' || _ctx.type === 'DELIVERY')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, "Parada " + _toDisplayString(_ctx.index), 1),
          _createVNode(_component_plan_input_radio, {
            onChange: _ctx.changeOption,
            "selected-option": _ctx.type.toLowerCase(),
            options: _ctx.stopOptions
          }, null, 8, ["onChange", "selected-option", "options"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex justify-between items-start plan-gap-sm", _ctx.actualWidth < _ctx.minWidth ? 'flex-col':''])
    }, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createBlock(_component_plan_input_load_location, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showValidations.location = true)),
          onGetLocationFormated: _ctx.changeStopData,
          class: "w-full",
          "pre-location": _ctx.templateData.location,
          location: _ctx.templateData.location,
          key: _ctx.planLoadKey,
          "exclude-country": _ctx.excludeCountry,
          country: _ctx.onlyCountry,
          "block-country": _ctx.onlyCountry.length > 0
        }, null, 8, ["onGetLocationFormated", "pre-location", "location", "exclude-country", "country", "block-country"])),
        (_ctx.showValidations.location && !_ctx.isValidatedForm.location)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('required-field')), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_plan_input_calendar, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showValidations.date = true)),
          onChange: _ctx.changeStopDate,
          defaultDateLabel: _ctx.stopDate,
          min: _ctx.minDate,
          max: _ctx.maxDate,
          label: _ctx.$t('date'),
          "selected-in-bold": false,
          "hide-validation": "",
          "hide-close": ""
        }, null, 8, ["onChange", "defaultDateLabel", "min", "max", "label"]),
        (_ctx.showValidations.date && !_ctx.isValidatedForm.date)
          ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('required-field')), 1))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _createVNode(_component_warehouse_in_stop_data, {
      onUpdate: _ctx.updateWarehouse,
      onPortOrAirport: _ctx.updatePortOrAirport,
      onUpdateSelectedAppointment: _ctx.updateSelectedAppointment,
      onUpdateSelectedTime: _ctx.updateSelectedTime,
      onUpdateObservations: _ctx.updateObservations,
      "warehouse-data": _ctx.warehouseData,
      "warehouse-id": _ctx.templateData.warehouse,
      "default-zipcode-id": _ctx.stopData?.postal_code,
      "default-prefix": _ctx.selectedCountryData?.phonePrefix,
      "default-country-id": _ctx.selectedCountryData?.id,
      "default-country": _ctx.selectedCountryData?.iso,
      zip: _ctx.stopData?.postal_code,
      onlyCountry: _ctx.onlyCountry,
      excludeCountry: _ctx.excludeCountry,
      style: {"margin-top":"-8px"}
    }, null, 8, ["onUpdate", "onPortOrAirport", "onUpdateSelectedAppointment", "onUpdateSelectedTime", "onUpdateObservations", "warehouse-data", "warehouse-id", "default-zipcode-id", "default-prefix", "default-country-id", "default-country", "zip", "onlyCountry", "excludeCountry"])
  ]))
}