
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import PlanInputMultiSelect from '@/components/forms/PlanInputMultiSelect.vue';

export default defineComponent({
  name: 'SpecialRequirementsFilter',
  components: { PlanInputMultiSelect },
  emits: ['change'],
  setup(props, { emit }) {
    const store = useStore();
    const data = reactive({
      permits:  [
        {
          value: 'hasTransportAndDeliveryOfAlcoholicBeveragesPermit',
          translation: 'permits-contact.hasTransportAndDeliveryOfAlcoholicBeveragesPermit',
          region:['usa']
        },
        {
          value: 'hasHazardousMaterialPermit',
          translation: 'permits-contact.hasHazardousMaterialPermit',
          region:['eu', 'usa']
        },
        {
          value: 'hasTWICCard',
          translation: 'permits-contact.hasTWICCard',
          region:['usa']
        },
        {
          value: 'hasTSA',
          translation: 'permits-contact.hasTSA',
          region:['usa']
        },
        {
          value: 'hasOverweightLoadPermit',
          translation: 'permits-contact.hasOverweightLoadPermit',
          region:['eu', 'usa' ]
        },
        {
          value: 'hasTeamDriverPermit',
          translation: 'permits-contact.hasTeamDriverPermit',
          region:['eu', 'usa']
        },
        {
          value: 'hasBondPermit',
          translation: 'permits-contact.hasBondPermit',
          region:['usa']
        },
        {
          value: 'hasTirPermit',
          translation: 'permits-contact.hasTirPermit',
          region:['eu']
        },
        {
          value: 'hasMoveFullTruckPermit',
          translation: 'permits-contact.hasMoveFullTruckPermit',
          region:['eu', 'usa']
        },
        {
          value: 'hasMovePartialPermit',
          translation: 'permits-contact.hasMovePartialPermit',
          region:['eu', 'usa']
        },
        {
          value: 'hasOversizeLoadPermit',
          translation: 'permits-contact.hasOversizeLoadPermit',
          region:['eu','usa']
        },
      ]
    });

    const changeSelectedPermits = (permitsSelected) => {
      emit('change', permitsSelected);
    };

    const countriesList = computed(() => {
      return store.getters['CountryStore/getCountries'];
    });

    const company = computed((): any => {
      return store.getters['CompanyStore/getCompany'];
    });

    const isEuropeCompany = computed((): any => {
      const country = countriesList.value.find(
        (country) => company.value?.country?.id === country?.id,
      );

      return country?.region?.name === 'EUROPE';
    });

    const permitsByRegion = computed(() => {
      const region = isEuropeCompany.value ? 'eu': 'usa';
      return data.permits.filter(permit => permit.region.includes(region));
    });

    return {
      ...toRefs(data),
      changeSelectedPermits,
      permitsByRegion,
    };
  },
});
