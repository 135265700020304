
import { defineComponent } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useI18n } from 'vue-i18n';

// Mixins
import CountryMixins from '@/services/country/_mixins/index';
export default defineComponent({
  name: 'LoadCardFooterData',
  mixins: [CountryMixins],
  components: { PlanIcon },
  props: {
    distance: { type: String, required: true, default: '' },
    vehicle: { type: String, required: true, default: '' },
    goodsType: { type: String, required: true, default: '' },
    specialRequirements: { type: String, required: true, default: '' },
  },
  setup(props, { emit }) {
    const translate = useI18n();

    const translateTrailer = (trailerTypeName) => {
      const clearName = trailerTypeName.replace(/[^\wáéíóú]/g, '');
      return translate.t(`trailer-types-eu.${clearName}`)
    };
    return {
      translateTrailer,
    };
  },
})
