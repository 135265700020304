
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanCarrierTrackingGoogleMap from '@/components/carrier/_assigned/PlanCarrierTrackingGoogleMap.vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanCarrierTrackingHistory from '@/components/carrier/_assigned/PlanCarrierTrackingHistory.vue';
import UpdatePositionModal from '@/modules/carrier/quotationRequest/_componentes/UpdatePositionModal.vue';

interface planCarrierTrackingMap {
  initialLocation: {
    lat: number;
    lon: number;
    zip: string;
    country: string;
  };
  loadingInitialLocation: boolean;
  placeId: string;
}
export default defineComponent({
  name: 'PlanCarrierTrackingMap',
  components: { PlanCarrierTrackingHistory, PlanIcon, PlanCarrierTrackingGoogleMap },
  props: {
    centerInPickUp: { type: Boolean, required: false, default: false }
  },
  setup() {
    const isMobile = computed(() => {
      return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    })

    const store = useStore();

    const data = reactive<planCarrierTrackingMap>({
      initialLocation: {
        lat: 0,
        lon: 0,
        zip: '',
        country: '',
      },
      loadingInitialLocation: false,
      placeId: '',
    });

    const getLatitudeLongitude = (position: GeolocationPosition) => {
      data.initialLocation.lat = position.coords.latitude
      data.initialLocation.lon = position.coords.longitude
      getLocationsByGoogle()
    };

    const getLocation = () => {
      if (isMobile.value && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(getLatitudeLongitude, showError)
      }
    };

    const getLocationsByGoogle = () => {
      store
        .dispatch('LoadsStore/getCoordinatesGoogle', {
          lat: data.initialLocation.lat,
          lng: data.initialLocation.lon,
        })
        .then((response) => {
          if (response.data.length > 0) {
            data.placeId = response.data[0]['place_id'];
            response.data[0]['address_components'].forEach((addressComponent: any) => {
              if (addressComponent.types.includes('postal_code')) {
                data.initialLocation.zip = addressComponent['short_name'];
              }
              if (addressComponent.types.includes('country')) {
                data.initialLocation.country = addressComponent['short_name'];
              }
            });
          }
        })
        .finally(() => {
          data.loadingInitialLocation = false;
        });
    };

    const showError = (error: GeolocationPositionError) => {
      let errorMessage = '';
      switch (error.code) {
        case error.PERMISSION_DENIED:
          errorMessage = 'error.PERMISSION_DENIED';
          break;
        case error.POSITION_UNAVAILABLE:
          errorMessage = 'error.POSITION_UNAVAILABLE';
          break;
        case error.TIMEOUT:
          errorMessage = 'error.TIMEOUT';
          break;
      }
    };

    getLocation()

    return {
      ...toRefs(data),
    };
  },
});
