
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
//Utils
import { formatDateStringReverse } from '@/utils/formatDates';

interface Insurance {
  number: string;
  limit: number;
  date: Date;
  file: File;
}

export default defineComponent({
  name: 'ServiceInsurances',
  components: {
    CheckCircleIcon,
    DatePickerComponent,
    ErrorMessage,
    Field,
    Form,
    InputAmericanFormat,
    Spin,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  props: {
    type: String,
    shippmentId: String,
  },
  data() {
    return {
      addDriver: false,
      addVehicle: false,
      allowed: true,
      allEndpointsCompleted: false,
      cargoInsurance: {} as Insurance,
      cargoInsuranceId: 0,
      cargoInsuranceFile: {} as File,
      cargoUploadedURL: '',
      carrierPackage: {} as File,
      currentDate: new Date(),
      errorOnSubmit: false,
      dotUploadURL: '',
      DOTMCInsurance: {} as Insurance,
      DOTMCInsuranceId: 0,
      DOTMCInsuranceFile: {} as File,
      isSending: false,
      liabilityUploadedURL: '',
      liabilityInsurance: {} as Insurance,
      liabilityInsuranceId: 0,
      liabilityInsuranceFile: {} as File,
      validating: false,
      W9Insurance: {} as Insurance,
      W9InsuranceId: 0,
      W9InsuranceFile: {} as File,
      W9UploadedURL: '',
    };
  },
  async created() {
    await this.setInsurances();
    this.allEndpointsCompleted = true;
  },
  computed: {
    cargoExpirationDate(): any {
      if (this.getCargoInsurance) {
        return new Date(this.getCargoInsurance.expirationDate);
      }
      return null;
    },
    currentCompany(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    DOTMCExpirationDate(): any {
      if (this.getDOTMCInsurance) {
        return new Date(this.getDOTMCInsurance.expirationDate);
      }
      return null;
    },
    getCargoInsurance(): any {
      if (this.currentCompany && this.currentCompany.insurances) {
        return this.currentCompany.insurances.find((insurance) => insurance.type === 'CARGO');
      }
      return undefined;
    },
    getDOTMCInsurance(): any {
      if (this.currentCompany && this.currentCompany.insurances) {
        return this.currentCompany.mediaObjects.find((media) => media.type === 'DOT_MC_NUMBER');
      }
      return undefined;
    },
    getLiabilityInsurance(): any {
      if (this.currentCompany && this.currentCompany.insurances) {
        return this.currentCompany.insurances.find((insurance) => insurance.type === 'LIABILITY');
      }
      return undefined;
    },
    getW9Insurance(): any {
      if (this.currentCompany && this.currentCompany.insurances) {
        return this.currentCompany.mediaObjects.find((media) => media.type === 'W9');
      }
      return undefined;
    },
    insuranceValidation(): any {
      const obj = this.shipment;
      return obj.shipmentAcceptCompleted;
    },
    liabilityExpirationDate(): any {
      if (this.getLiabilityInsurance) {
        return new Date(this.getLiabilityInsurance.expirationDate);
      }
      return null;
    },
    selectedOption(): string {
      return this.currentCompany.type;
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
    W9ExpirationDate(): any {
      if (this.getW9Insurance) {
        return new Date(this.getW9Insurance.expirationDate);
      }
      return null;
    },
  },
  methods: {
    async getFile(media: any): Promise<File> {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFile', {
        id: media.id,
        class: 'INSURANCE',
      });
      return new File([response], media.name, { type: media.mimeType });
    },
    async getFileCompany(media: any): Promise<File> {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFile', {
        id: media.id,
        class: 'COMPANY',
      });
      return new File([response], media.name, { type: media.mimeType });
    },
    handleChange(type: string) {
      switch (type) {
        case 'cargoInsuranceFile':
          this.cargoInsurance.file = (this.$refs.cargoInsuranceFile as any).files[0];
          break;
        case 'liabilityInsuranceFile':
          this.liabilityInsurance.file = (this.$refs.liabilityInsuranceFile as any).files[0];
          break;
        case 'W9':
          this.W9Insurance.file = (this.$refs.W9InsuranceFile as any).files[0];
          break;
        case 'DOTMC':
          this.DOTMCInsurance.file = (this.$refs.DOTMCInsuranceFile as any).files[0];
          break;
        case 'carrierPackage':
          this.carrierPackage = (this.$refs.carrierPackage as any).files[0];
          break;
      }
    },
    omitInsuranceStep() {
      this.$emit('nextStep', 'DriverVehicle');
    },
    async postInsurance(type: string) {
      let insurance = {} as any;
      switch (type) {
        case 'CARGO':
          insurance = this.cargoInsurance;
          break;
        case 'LIABILITY':
          insurance = this.liabilityInsurance;
          break;
      }
      await this.$store
        .dispatch('ShipmentStore/postInsurances', {
          type: type,
          number: insurance.number,
          limitAmount: insurance.limit ? Number(insurance.limit) : undefined,
          expirationDate: formatDateStringReverse(insurance.date),
          company: { id: this.currentCompany.id },
        })
        .then((response) => {
          switch (type) {
            case 'CARGO':
              this.cargoInsuranceId = response.id;
              break;
            case 'LIABILITY':
              this.liabilityInsuranceId = response.id;
              break;
          }
        })
        .catch((err) => {
          this.errorOnSubmit = true;
        });
    },
    async postMediaObjectCompany(type: string) {
      let insurance = {} as any;
      switch (type) {
        case 'W9':
          insurance = this.W9Insurance;
          break;
        case 'DOT_MC_NUMBER':
          insurance = this.DOTMCInsurance;
          break;
      }
      if (insurance.file) {
        await this.$store
          .dispatch('CompanyStore/postMediaObjectCompany', {
            entityId: this.currentCompany.id,
            file: insurance.file,
            type: type,
          })
          .catch((err) => {
            err.response.data.violations;
            this.errorOnSubmit = true;
          });
      }
    },
    async postMediaObjectInsurance(type: string) {
      let insurance = {} as any;
      let idGetInsurance = null;
      let idInsurance = null as any;
      switch (type) {
        case 'CARGO':
          insurance = this.cargoInsurance;
          idInsurance = this.cargoInsuranceId;
          if (this.getCargoInsurance !== undefined) {
            idGetInsurance = this.getCargoInsurance.id;
          }
          break;
        case 'LIABILITY':
          insurance = this.liabilityInsurance;
          idInsurance = this.liabilityInsuranceId;
          if (this.getLiabilityInsurance !== undefined) {
            idGetInsurance = this.getLiabilityInsurance.id;
          }
          break;
      }
      await this.$store
        .dispatch('ShipmentStore/postMediaObjectInsurance', {
          entityId: idGetInsurance ? idGetInsurance : idInsurance,
          file: insurance.file,
        })
        .catch((err) => {
          err.response.data.violations;
          this.errorOnSubmit = true;
        });
    },
    async postUpdateMediaObjectCompany(type: string) {
      let insurance = {} as any;
      let mediaId = 0 as any;
      switch (type) {
        case 'W9':
          insurance = this.W9Insurance;
          mediaId = this.getW9Insurance.id;
          break;
        case 'DOT_MC_NUMBER':
          insurance = this.DOTMCInsurance;
          mediaId = this.getDOTMCInsurance.id;
          break;
      }
      if (mediaId) {
        await this.$store
          .dispatch('CompanyStore/postUpdateMediaObjectCompany', {
            id: mediaId,
            file: insurance.file,
          })
          .catch((err) => {
            err.response.data.violations;
            this.errorOnSubmit = true;
          });
      }
    },
    async postUpdateMediaObjectInsurance(type: string) {
      let insurance = {} as any;
      let mediaId = 0 as any;
      switch (type) {
        case 'CARGO':
          insurance = this.cargoInsurance;
          mediaId = this.getCargoInsurance.mediaObject.id;
          break;
        case 'LIABILITY':
          insurance = this.liabilityInsurance;
          mediaId = this.getLiabilityInsurance.mediaObject.id;
          break;
      }
      await this.$store
        .dispatch('ShipmentStore/postUpdateMediaObjectInsurance', {
          id: mediaId,
          file: insurance.file,
        })
        .catch((err) => {
          err.response.data.violations;
          this.errorOnSubmit = true;
        });
    },
    async putInsurance(type: string) {
      let insurance = {} as any;
      let idGetInsurance = null;
      switch (type) {
        case 'CARGO':
          insurance = this.cargoInsurance;
          if (this.getCargoInsurance !== undefined) {
            idGetInsurance = this.getCargoInsurance.id;
          }
          break;
        case 'LIABILITY':
          insurance = this.liabilityInsurance;
          if (this.getLiabilityInsurance !== undefined) {
            idGetInsurance = this.getLiabilityInsurance.id;
          }
          break;
      }

      const regex = /\//g;
      let matches = insurance.date.toString().match(regex);
      let insuranceExpiration =
        matches !== null ? insurance.date.replace(/\//g, '-') : insurance.date;

      await this.$store
        .dispatch('ShipmentStore/putInsurances', {
          id: idGetInsurance,
          body: {
            number: insurance.number,
            limitAmount: Number(insurance.limit),
            expirationDate: formatDateStringReverse(insuranceExpiration),
          },
        })
        .catch((err) => {
          err.response.data.violations;
          this.errorOnSubmit = true;
        });
    },
    async setInsurances() {
      const promises: Promise<any>[] = [];
      if (this.getCargoInsurance && this.selectedOption === 'CARRIER') {
        if (
          this.cargoUploadedURL === '' &&
          this.getCargoInsurance.mediaObject &&
          this.getCargoInsurance !== undefined &&
          this.getCargoInsurance !== null
        ) {
          const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
            id: this.getCargoInsurance?.mediaObject?.id,
            class: this.getCargoInsurance?.mediaObject?.entityClass,
          });
          this.cargoUploadedURL = URL.createObjectURL(result);
        }
        this.cargoInsurance.number = this.getCargoInsurance.number;
        this.cargoInsurance.limit = this.getCargoInsurance.limitAmount;
        this.cargoInsurance.date = this.getCargoInsurance.expirationDate.replace(/-/g, '/');
        const media = this.getCargoInsurance.mediaObject;
        if (media && !this.cargoInsurance.file) {
          const file = await this.getFile(media);
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          (this.$refs.cargoInsuranceFile as any).files = dataTransfer?.files ?? null;
          this.cargoInsurance.file = file;
          this.cargoInsuranceFile = file;
        }

        promises.push(
          this.$store.dispatch('ShipmentStore/thumbnailBig', {
            id: this.getCargoInsurance?.mediaObject?.id,
            class: this.getCargoInsurance?.mediaObject?.entityClass,
          }),
        );
      }
      if (this.getLiabilityInsurance) {
        if (
          this.liabilityUploadedURL === '' &&
          this.getLiabilityInsurance.mediaObject &&
          this.getLiabilityInsurance !== undefined &&
          this.getLiabilityInsurance !== null
        ) {
          const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
            id: this.getLiabilityInsurance?.mediaObject?.id,
            class: this.getLiabilityInsurance?.mediaObject?.entityClass,
          });
          this.liabilityUploadedURL = URL.createObjectURL(result);
        }
        this.liabilityInsurance.number = this.getLiabilityInsurance.number;
        this.liabilityInsurance.limit = this.getLiabilityInsurance.limitAmount;
        this.liabilityInsurance.date = this.getLiabilityInsurance.expirationDate.replace(/-/g, '/');
        const media = this.getLiabilityInsurance.mediaObject;
        if (media && !this.liabilityInsurance.file) {
          const file = await this.getFile(media);
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          (this.$refs.liabilityInsuranceFile as any).files = dataTransfer.files;
          this.liabilityInsurance.file = file;
          this.liabilityInsuranceFile = file;
        }

        promises.push(
          this.$store.dispatch('ShipmentStore/thumbnailBig', {
            id: this.getLiabilityInsurance?.mediaObject?.id,
            class: this.getLiabilityInsurance?.mediaObject?.entityClass,
          }),
        );
      }
      if (this.getW9Insurance) {
        const media = this.getW9Insurance;
        if (
          this.getW9Insurance &&
          this.getW9Insurance !== undefined &&
          this.getW9Insurance !== null
        ) {
          const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
            id: this.getW9Insurance?.id,
            class: this.getW9Insurance?.entityClass,
          });
          this.W9UploadedURL = URL.createObjectURL(result);
        }
        if (media && !this.W9Insurance.file) {
          const file = await this.getFileCompany(media);
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          (this.$refs.W9InsuranceFile as any).files = dataTransfer.files;
          this.W9Insurance.file = file;
          this.W9InsuranceFile = file;
        }
        promises.push(
          this.$store.dispatch('ShipmentStore/thumbnailBig', {
            id: this.getW9Insurance?.id,
            class: this.getW9Insurance?.entityClass,
          }),
        );
      }
      if (this.getDOTMCInsurance) {
        const media = this.getDOTMCInsurance;
        if (
          this.getDOTMCInsurance &&
          this.getDOTMCInsurance !== undefined &&
          this.getDOTMCInsurance !== null
        ) {
          const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
            id: this.getDOTMCInsurance?.id,
            class: this.getDOTMCInsurance?.entityClass,
          });
          this.dotUploadURL = URL.createObjectURL(result);
        }
        if (media && !this.DOTMCInsurance.file) {
          const file = await this.getFileCompany(media);
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          (this.$refs.DOTMCInsuranceFile as any).files = dataTransfer.files;
          this.DOTMCInsurance.file = file;
          this.DOTMCInsuranceFile = file;
        }
        promises.push(
          this.$store.dispatch('ShipmentStore/thumbnailBig', {
            id: this.getDOTMCInsurance?.id,
            class: this.getDOTMCInsurance?.entityClass,
          }),
        );
      }

      await Promise.all(promises);
    },
    async validateForm() {
      if (this.isSending) return;
      this.validating = true;
      this.isSending = true;
      const validation = await (this.$refs.form as any).validate();
      let validDates = true;

      if (this.selectedOption === 'BROKER') {
        this.cargoInsurance.date = this.liabilityInsurance.date;
        this.cargoInsurance.file = this.liabilityInsurance.file;
      }

      if (
        !this.cargoInsurance.date ||
        !this.liabilityInsurance.date ||
        !this.cargoInsurance.file ||
        !this.liabilityInsurance.file
      ) {
        validDates = false;
      }
      if (!validation.valid || !validDates) {
        this.isSending = false;
        return;
      }

      if (this.getCargoInsurance && this.selectedOption === 'CARRIER') {
        await this.putInsurance('CARGO');
      } else if (this.selectedOption === 'CARRIER') {
        await this.postInsurance('CARGO');
      }

      if (this.getLiabilityInsurance) {
        await this.putInsurance('LIABILITY');
      } else {
        await this.postInsurance('LIABILITY');
      }

      if (this.selectedOption === 'CARRIER') {
        if (this.cargoInsurance.file !== this.cargoInsuranceFile) {
          if (this.getCargoInsurance !== undefined) {
            if (this.getCargoInsurance.mediaObject) {
              await this.postUpdateMediaObjectInsurance('CARGO');
            } else {
              await this.postMediaObjectInsurance('CARGO');
            }
          } else {
            await this.postMediaObjectInsurance('CARGO');
          }
        }
      }

      if (this.liabilityInsurance.file !== this.liabilityInsuranceFile) {
        if (this.getLiabilityInsurance !== undefined) {
          if (this.getLiabilityInsurance.mediaObject) {
            await this.postUpdateMediaObjectInsurance('LIABILITY');
          } else {
            await this.postMediaObjectInsurance('LIABILITY');
          }
        } else {
          await this.postMediaObjectInsurance('LIABILITY');
        }
      }

      if (this.W9Insurance.file !== this.W9InsuranceFile) {
        if (this.getW9Insurance !== undefined) {
          if (this.getW9Insurance) {
            await this.postUpdateMediaObjectCompany('W9');
          } else {
            await this.postMediaObjectCompany('W9');
          }
        } else {
          await this.postMediaObjectCompany('W9');
        }
      }

      if (this.DOTMCInsurance.file !== this.DOTMCInsuranceFile) {
        if (this.getDOTMCInsurance !== undefined) {
          if (this.getDOTMCInsurance) {
            await this.postUpdateMediaObjectCompany('DOT_MC_NUMBER');
          } else {
            await this.postMediaObjectCompany('DOT_MC_NUMBER');
          }
        } else {
          await this.postMediaObjectCompany('DOT_MC_NUMBER');
        }
      }

      if (this.type === 'UpdateInsurances') {
        this.isSending = false;
        BaseComponent.methods?.showToastSuccess(this.$t('insurances-updated'));
        this.$emit('closeModal');
      } else {
        this.isSending = false;
        this.$emit('nextStep', 'DriverVehicle');
      }
      await this.$store.dispatch('CompanyStore/getCompany', this.currentUser?.company?.id);
    },
    async viewDocumentUploaded(id, entityClass) {
      const result = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: id,
        class: entityClass,
      });

      const url = URL.createObjectURL(result);
      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-${''}`;
        const blob = new Blob([result], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    },
  },
  watch: {
    getCargoInsurance() {
      this.setInsurances();
    },
    getLiabilityInsurance() {
      this.setInsurances();
    },
  },
});
