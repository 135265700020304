
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import { useStore } from 'vuex';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import PlanInputNumberFormatted from '@/components/forms/PlanInputNumberFormatted.vue';

interface planLoadFormCommodityData {
  commodityDescription: string;
  weight: null | number;
  units: null | number;
  type: string;
  types: Array<any>,
  showValidation: {
    description: boolean,
    weight: boolean,
    units: boolean
  }
}
export default defineComponent({
  name: 'PlanLoadFormCommodityData',
  components: { PlanInputNumberFormatted, PlanButtonIcon, PlanSelect, PlanInputText },
  props: {
    commodity: { type: Object, required: true },
    index: { type: Number, required: true, default: -1 },
    onlyCountry: { type: String, required: false, default: '' }
  },
  emits: ['change', 'remove'],
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive<planLoadFormCommodityData>({
      commodityDescription: props.commodity.commodityDescription,
      weight: props.commodity.weight,
      units: props.commodity.units,
      type: props.commodity.type,
      types: [],
      showValidation: {
        description: false,
        weight: false,
        units: false
      }
    })

    const actualWidth = computed(() => {
      return store.getters['UserStore/getActualWidth']
    })

    const changePackageType = (packageTypeId: string) => {
      data.type = packageTypeId
      update()
    }

    const changeWeight = () => {
      if (data.weight) if (data.weight === null || data.weight < 0) data.weight = null;
      update();
    };

    const getTypes = () => {
      let result: Array<any> = [];
      store.getters['LoadsStore/packageTypes'].FTL?.forEach((packageType: any) => {
        if (data.type === '' || data.type === '-1') changePackageType(packageType.id.toString())

        result.push({
          label: packageType.fullName,
          value: packageType.id.toString(),
        });
      });

      data.types = result;
    }

    const remove = () => {
      emit('remove', props.index);
    };

    // const types = computed(() => {
    //   let result: Array<any> = [];
    //   store.getters['LoadsStore/packageTypes'].FTL?.forEach((packageType: any) => {
    //     if (data.type === '') data.type = packageType.id.toString();
    //
    //     result.push({
    //       label: packageType.fullName,
    //       value: packageType.id.toString(),
    //     });
    //   });
    //
    //   return result;
    // });

    const update = () => {
      emit('change', {
        index: props.index,
        id: data.type,
        units: data.units,
        type: data.type,
        weight: data.weight,
        commodityDescription: data.commodityDescription,
      });
    };

    const weightType = computed(() => {
      if (props.onlyCountry === 'mx') return 'kgs'
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'lbs':'kgs'
    })

    getTypes()

    return {
      ...toRefs(data),
      actualWidth,
      changePackageType,
      changeWeight,
      remove,
      update,
      weightType
    };
  },
});
